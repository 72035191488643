import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicGroup {
    cleoClinicGroup {
      uid
      name
      clinics {
        name
        domain
      }
      clinicianCount
      billingSettings
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinicGroup',
  keepPreviousData: true,
  select: ({ cleoClinicGroup }) => cleoClinicGroup,
});
