import React from 'react';
import { Box, theme } from '@fivehealth/botero';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const MarkdownWrapper = styled(Box)`
  a {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }
`;

const regex = /\(([^)]+)\)/;

export default ({ children, ...props }) => {
  let content = children;
  const emptyLinkRegex = /\[([^\]]+)\]\(-\)/g;
  if (typeof children === 'string') {
    content = children.replace(emptyLinkRegex, '');
  }

  return (
    <MarkdownWrapper>
      <ReactMarkdown
        mt="0px"
        mb="0px"
        {...props}
        transformLinkUri={(url) => {
          if (decodeURI(url?.substring(0, 3000)).match(regex)?.length > 0) {
            return decodeURI(url).match(regex)[1].replace(',', '');
          }
          return url.replace(',', '');
        }}
        linkTarget="_blank"
      >
        {content}
      </ReactMarkdown>
    </MarkdownWrapper>
  );
};
