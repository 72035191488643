import React, { useState } from 'react';
import {
  Box,
  H2,
  Body,
  DangerButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  Label,
  useApi,
  Select,
  Text,
} from '@fivehealth/botero';
import _ from 'lodash';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const DischargeModal = ({
  closeModal,
  isSubmitting,
  onSubmit,
  user,
  clinicians,
}) => {
  const [selectedClinician, setSelectedClinician] = useState();
  const { t } = useTranslation();
  const {
    queries: { usePatientForms },
  } = useApi({ queries: ['usePatientForms'] });

  const dot = '.';
  const history = useHistory();

  const { data: patientFormsData = [] } = usePatientForms({
    variables: { clinicianIcUid: [user.uid], clinicianAlerteeUid: [user.uid] },
  });

  const patients = _.chain(patientFormsData)
    .get('pages', [])
    .flatMap((page) => page)
    .map((patientForm) => patientForm.patient)
    .uniqBy('uid')
    .value();

  const clinicianOpts = clinicians.map(({ name, uid }) => ({
    label: name,
    value: uid,
  }));

  const onChangeClinician = (val) => {
    setSelectedClinician(val);
  };

  const onDischargeSubmit = () => {
    let clinicianReassignment = {};
    if (patients.length > 0) {
      clinicianReassignment = {
        reassignedClinician: {
          uid: selectedClinician.value,
          name: selectedClinician.label,
        },
      };
    }
    onSubmit({
      clinician: user,
      ...(clinicianReassignment.reassignedClinician
        ? clinicianReassignment
        : {}),
    });
  };
  const onNavigate = (path) => {
    closeModal();
    history.push(path);
  };

  const dangerButtonLabel =
    patients.length > 0
      ? t('Reassign and deactivate user')
      : t('Deactivate user');

  const disableSubmit =
    patients.length === 0 ? isSubmitting : isSubmitting || !selectedClinician;

  return (
    <Box
      p={1}
      pb={[0, 0, '24px']}
      style={{ boxSizing: 'border-box' }}
      width={['100%', 720]}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <H2>
          {t('Deactivate')} {user.name}?
        </H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Body mt={6}>
        {t('What happens when an account is deactivated?')}
        <ul>
          <li>
            <Text>
              {t(
                'The user will no longer be able to sign in to the Dashboard.'
              )}
            </Text>
          </li>
          {patients.length > 0 && (
            <li>
              <Text>
                <span style={{ fontWeight: 600 }}>{patients.length}</span>{' '}
                {t('patients assigned to the user will need to be reassigned.')}
              </Text>
            </li>
          )}
          <li>
            <Text>
              {t(
                "The user's comments will still be accessible in the Dashboard."
              )}
            </Text>
          </li>
        </ul>
      </Body>
      {patients.length > 0 && (
        <Flex flexDirection="column" mt={4}>
          <Label fontSize={13} color="darkestShade" fontWeight={600} mb={1}>
            {t('Reassign all patients to')}
          </Label>
          <Select
            name="clinician"
            value={selectedClinician}
            options={clinicianOpts}
            onChange={onChangeClinician}
            maxMenuHeight={180}
          />
          <Text
            fontSize="14px"
            color="#697481"
            fontWeight="400"
            mt={1}
            lineHeight="24px"
            letterSpacing="-0.08px"
          >
            {t('You can individually reassign patients in')}{' '}
            <Box
              onClick={() => onNavigate('/patient-list')}
              style={{ color: '#256bf6', display: 'inline', cursor: 'pointer' }}
            >
              {t('Patient List')}
            </Box>
            {`${dot} `}
            {t(
              'Apply a filter for the Clinical User’s name to view all patients they are assigned to'
            )}
            {dot}
          </Text>
        </Flex>
      )}
      <Flex
        mt={6}
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent={['', 'right']}
      >
        <SecondaryOutlinedButton
          mr={[0, 3]}
          mb={[2, 0]}
          p="0px 24px"
          width={['100%', 94]}
          onClick={closeModal}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <DangerButton
          disabled={disableSubmit}
          onClick={onDischargeSubmit}
          minWidth={['100%', patients.length > 0 ? 236 : 150]}
        >
          {isSubmitting ? t('Submitting...') : dangerButtonLabel}
        </DangerButton>
      </Flex>
    </Box>
  );
};

export default DischargeModal;
