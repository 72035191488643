import React, { useMemo } from 'react';
import { chain, concat, get, isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Badge, Flex, TextLink, H4 } from '@fivehealth/botero';

import EVENTS from 'constants/events';
import { useModal } from 'context/ModalContext';
import useCaregiverUsers from 'hooks/useCaregiverUsers';

import {
  BoxDivider,
  BoxPairLabelValues,
  BoxPairLayout,
  BoxPairLeftPanel,
  BoxPairRightPanel,
} from 'components/Layout';
import ActionDropdownWithPermission from 'components/ActionDropdown/ActionDropdownWithPermission';
import { checkIsMyPatient, checkPermissions } from 'AppUtils';
import BotCryingAvatar from '../../../assets/crying-avatar.svg';

import {
  getBoxPairLayoutData,
  onDischargePatientForm,
  onOpenEditUserModal,
  onOpenSendMessageTplModal,
  BoxAccordionSection,
  BoxContentReminder,
  BoxActionMenuSection,
  BoxContentAlerThresholds,
  getAlertThresholds,
  getReminders,
  onOpenEditAlertThresholdModal,
  hasPermission,
  onOpenDeleteReminderModal,
  onOpenReminderModal,
  getTriggerText,
} from '../shared';

const ClinicalSettings = ({
  loading,
  clinician,
  patientForms = [],
  onEditThreshold,
  onToggleReminder,
  clinic,
  refetchPatientForm,
  onSendMessageTemplate,
  onSubmitDischargePatientForm,
  isSubmitting,
  isMobile,
  showEditReminder,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const { caregiverRoleUid } = useCaregiverUsers({
    variables: {},
  });

  const hasReminderPermission = hasPermission(clinician, 'mutate_reminders');
  const hasAlertsPermission = hasPermission(clinician, 'mutate_alertrules');
  const hasEditUserPermission = useMemo(() => {
    const permitted = hasPermission(clinician, 'mutate_all_patientforms');
    if (permitted) {
      return true;
    }
    const form = get(patientForms, '[0]');
    return (
      checkPermissions(clinician, [
        'mutate_ic_patientforms',
        'mutate_alertee_patientforms',
      ]) && checkIsMyPatient(clinician, form)
    );
  }, [clinician, patientForms]);

  // IMPT: Construct the main data structure for box pair layout use in clinical settings contents.
  const boxPairLayoutData = getBoxPairLayoutData(
    t,
    caregiverRoleUid,
    patientForms,
    (form) => get(form, 'monitoringForm.effectiveName')
  );

  const clinicalSettingsFormsData = {
    active: boxPairLayoutData.filter((o) => !o.data?.isDischarged),
    discharged: boxPairLayoutData.filter((o) => o.data?.isDischarged),
  };

  const getFormActionItems = (data) => [
    {
      label: t('Edit clinical users'),
      action: () => {
        onOpenEditUserModal(
          openModal,
          closeModal,
          data,
          clinic,
          refetchPatientForm,
          'clinicalUsers',
          isMobile
        );
      },
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_CLINICAL_USER}-${data?.uid}`,
      },
      permission: hasEditUserPermission,
    },
    {
      label: t('Activate/pause reminders'),
      action: () => {
        onOpenReminderModal(
          openModal,
          closeModal,
          data,
          refetchPatientForm,
          true,
          t
        );
      },
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_ACTIVATE_PAUSE_REMINDER}-${data.uid}`,
      },
      permission: hasEditUserPermission,
    },
    {
      label: t('Send message'),
      action: () => {
        onOpenSendMessageTplModal(
          openModal,
          closeModal,
          data,
          onSendMessageTemplate,
          true
        );
      },
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_SEND_TPL_MSG}-${data.uid}`,
      },
      permission: hasEditUserPermission,
    },
    {
      label: !data.isDischarged ? t('Discharge patient') : t('Enroll patient'),
      action: () => {
        onDischargePatientForm(
          openModal,
          closeModal,
          data,
          onSubmitDischargePatientForm,
          isSubmitting,
          null,
          data.isDischarged
        );
      },
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_DISCHARGE_PATIENT}-${data.uid}`,
      },
      permission: hasEditUserPermission,
      color: !data.isDischarged ? 'danger' : 'fullShade',
    },
  ];
  const isGlobalReminder = (data) =>
    !chain(data?.patientForm?.monitoringForm?.monitoringFormset?.reminders)
      .find({ uid: data.reminderUid })
      .isEmpty()
      .value();

  const getReminderActionItems = (data) => [
    {
      label: t('Edit time & frequency'),
      action: () =>
        showEditReminder({
          ...data,
          selectedTab: 0,
        }),
      permission: hasReminderPermission,
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_FREQ_REMINDER}-${data.reminderUid}`,
      },
    },
    {
      label: t('Edit duration'),
      action: () =>
        showEditReminder({
          ...data,
          selectedTab: 1,
        }),
      permission: hasReminderPermission,
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_DURATION_REMINDER}-${data.reminderUid}`,
      },
    },
    {
      label: t('Delete reminder'),
      action: () => {
        if (data.isForClinic && isGlobalReminder(data)) {
          onOpenReminderModal(
            openModal,
            closeModal,
            data.patientForm,
            refetchPatientForm,
            false,
            t
          );
        } else {
          onOpenDeleteReminderModal(
            openModal,
            closeModal,
            data,
            onToggleReminder,
            isSubmitting,
            refetchPatientForm
          );
        }
      },
      permission: hasReminderPermission,
      color: 'danger',
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_DELETE_REMINDER}-${data.reminderUid}`,
      },
    },
  ];

  const getAlertThresholdsActionItems = (data) => [
    {
      label: t('Edit alert threshold'),
      action: () => {
        onOpenEditAlertThresholdModal(
          openModal,
          closeModal,
          data,
          onEditThreshold,
          isSubmitting
        );
      },
      permission: hasAlertsPermission,
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_ALERT_THRESHOLD}-${data.uid}`,
      },
    },
  ];

  const getReminderActionsMenu = (form) => [
    {
      label: t('Activate/pause reminders'),
      action: () => {
        onOpenReminderModal(
          openModal,
          closeModal,
          form,
          refetchPatientForm,
          true,
          t
        );
      },
      logEventProps: {
        page: 'Clinical Settings',
        subSource: 'Active',
        eventName: `${EVENTS.VIEW_CLINICAL_SETTINGS_EDIT_ACTIVATE_PAUSE_REMINDER}-${form.uid}`,
      },
      permission: hasEditUserPermission,
    },
  ];
  const getAccordionSections = (setting, form, index) => [
    {
      uid: `accordion-sections-reminders-key-${form.uid}`, // NOTE: Use as a unique key for the accordion widget
      name: (
        <Flex alignItems="center">
          {t('Reminders')}
          <Badge type={form.doNotRemind ? 'alert' : 'resolve'}>
            <span>{form.doNotRemind ? 'Paused' : 'Active'}</span>
          </Badge>
        </Flex>
      ),
      isOpen: true,
      section: 'reminders',
      renderSectionRight: () => (
        <ActionDropdownWithPermission
          items={getReminderActionsMenu(form)}
          permission={hasPermission}
        />
      ),
      renderSection: () => {
        // TODO: Need to fix and sort the reminders according to the last updated or created
        const reminders = chain(setting.reminders.data)
          .filter((f) => f.patientForm.uid === form.uid)
          .value();

        return reminders
          .filter((m) => {
            return (
              (isUndefined(m.settings.schedule?.action?.event_condition) ||
                isUndefined(m.settings.schedule?.action?.program_condition)) &&
              !isNull(
                getTriggerText(
                  get(m, 'settings.schedule.start_trigger'),
                  concat(
                    get(m, 'patientForm.metadataEntries', []),
                    get(m, 'patientForm.patient.metadataEntries', [])
                  )
                )
              ) &&
              !isNull(
                getTriggerText(
                  get(m, 'settings.schedule.end_trigger'),
                  concat(
                    get(m, 'patientForm.metadataEntries', []),
                    get(m, 'patientForm.patient.metadataEntries', [])
                  )
                )
              )
            );
          })
          .map((m, reminderIndex) => {
            return (
              <BoxActionMenuSection
                title={
                  <Flex alignItems="center">
                    {m?.effectiveName ||
                      `${t('Reminder')} ${reminderIndex + 1}`}
                    {isGlobalReminder(m) && (
                      <Badge type="default" fontSize={12}>
                        <span>{t('Default')}</span>
                      </Badge>
                    )}
                  </Flex>
                }
                permission={hasReminderPermission}
                actionItems={getReminderActionItems(m)}
                outerIndex={index}
                innerIndex={reminderIndex}
                isMobile={isMobile}
                key={`reminders-box-with-actionmenu-${index}-${reminderIndex}-${m.patientForm.uid}`}
                boxProps={{ marginBottom: reminders.length <= 2 ? 1 : 2 }}
              >
                <BoxContentReminder t={t} data={m} />
              </BoxActionMenuSection>
            );
          });
      },
      renderSectionFooter: () =>
        hasReminderPermission && (
          <TextLink
            onClick={() =>
              showEditReminder({
                patientForm: form,
                selectedTab: 0,
                isCreate: true,
              })
            }
            icon={faPlus}
            text={t('Add reminder')}
            mt={2}
          />
        ),
    },
    {
      uid: `accordion-sections-alerts-key-${form.uid}`, // NOTE: Use as a unique key for the accordion widget
      name: t('Alert thresholds'),
      section: 'alerts',
      isOpen: true,
      renderSection: () => {
        const alertThresholds = setting.alertThresholds.data.filter(
          (f) => f.patientForm.uid === form.uid
        );
        return alertThresholds.map((m, alertIndex) => (
          <BoxActionMenuSection
            permission={hasAlertsPermission}
            title={m.clinical_parameter}
            actionItems={getAlertThresholdsActionItems(m)}
            outerIndex={index}
            innerIndex={alertIndex}
            isMobile={isMobile}
            key={`alerts-box-with-actionmenu-${index}-${alertIndex}-${m.uid}`}
            boxProps={{ marginBottom: alertThresholds.length <= 2 ? 0 : 2 }}
          >
            <BoxContentAlerThresholds t={t} data={m} />
          </BoxActionMenuSection>
        ));
      },
      renderSectionFooter: null, // NOTE: Optional to add a section footer element
    },
  ];

  const clinicalSettings = [
    {
      name: t('Active forms'),
      isSectionOpen: true,
      forms: {
        data: clinicalSettingsFormsData.active,
        actionMenu: getFormActionItems,
      },
      reminders: {
        data: getReminders(clinicalSettingsFormsData.active.map((o) => o.data)),
        actionMenu: getReminderActionItems,
      },
      alertThresholds: {
        data: getAlertThresholds(
          clinicalSettingsFormsData.active.map((o) => o.data)
        ),
        actionMenu: getAlertThresholdsActionItems,
      },
    },
    {
      name: t('Discharged forms'),
      isSectionOpen: true,
      forms: {
        data: clinicalSettingsFormsData.discharged,
        actionMenu: getFormActionItems,
      },
      reminders: {
        data: getReminders(
          clinicalSettingsFormsData.discharged.map((o) => o.data)
        ),
        actionMenu: getReminderActionItems,
      },
      alertThresholds: {
        data: getAlertThresholds(
          clinicalSettingsFormsData.discharged.map((o) => o.data)
        ),
        actionMenu: getAlertThresholdsActionItems,
      },
    },
  ];

  const isClinicalSettingsEmpty = clinicalSettings.every(
    (setting) => setting.forms.data.length === 0
  );

  return (
    <>
      {clinicalSettings.map((setting, settingIndex) => (
        <Box key={`clinical-settings-box-${settingIndex}`}>
          {setting.forms.data && setting.forms.data?.length > 0 && (
            <>
              <BoxPairLayout>
                <BoxPairLeftPanel
                  headerTitle={setting.name}
                  isMobile={isMobile}
                />
                <>
                  {setting.forms.data.map((settingForm, settingFormIndex) => (
                    <BoxPairRightPanel
                      headerRightProps={{ marginTop: ['-18px', 0] }}
                      headerLeft={settingForm.headerLeft}
                      headerRight={
                        <ActionDropdownWithPermission
                          permission={hasEditUserPermission}
                          items={getFormActionItems(settingForm.data)}
                        />
                      }
                      key={`clinical-settings-box-rightpanel-${settingFormIndex}`}
                    >
                      <BoxPairLabelValues data={settingForm.groupPair}>
                        <BoxAccordionSection
                          isSectionOpen={setting.isSectionOpen}
                          sections={getAccordionSections(
                            setting,
                            settingForm.data,
                            settingFormIndex
                          )}
                        />
                      </BoxPairLabelValues>
                    </BoxPairRightPanel>
                  ))}
                </>
              </BoxPairLayout>
              {settingIndex < clinicalSettings.length - 1 && <BoxDivider />}
            </>
          )}
        </Box>
      ))}

      {isClinicalSettingsEmpty && !loading && (
        <Box textAlign="center">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            m={2}
          >
            <Box width={100} height={100} as="img" src={BotCryingAvatar} />
            <Box mt={2}>
              <H4>{t('No clinical settings found.')}</H4>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default ClinicalSettings;
