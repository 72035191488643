import {
  Box,
  ActionMenu,
  ActionMenuText,
  ActionMenuItem,
  FAIcon,
  Flex,
} from '@fivehealth/botero';
import { useRef } from 'react';

import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

const ActionDropdownWithPermission = ({ items, permission }) => {
  const actionMenuRef = useRef();

  const onSetActionMenuRef = (ref) => {
    actionMenuRef.current = ref;
  };

  const handleClick = (o) => {
    actionMenuRef.current.setOpen(false);
    o.action();
  };

  if (!permission || !items) return null;

  return (
    <Box>
      <ActionMenu
        dropdownRef={onSetActionMenuRef}
        label={
          <Flex height={15} alignItems="center" pl={1} pr={1} mr={-1}>
            <FAIcon icon={faEllipsisV} hover={{ opacity: 0.6 }} />
          </Flex>
        }
      >
        {items.map(
          (o, index) =>
            o.permission && (
              <ActionMenuItem
                key={index}
                onClick={() => handleClick(o)}
                logEventProps={o.logEventProps}
                divider={!!o.color}
              >
                <ActionMenuText color={o.color}>{o.label}</ActionMenuText>
              </ActionMenuItem>
            )
        )}
      </ActionMenu>
    </Box>
  );
};

export default ActionDropdownWithPermission;
