import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientCreate($input: CreateCleoPatientInput!) {
    cleoPatientCreate(input: $input) {
      cleoPatient {
        uid
        name
        phone
        identifier
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientCreate',
  allowErrorResponse: true,
});
