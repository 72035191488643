import React from 'react';
import { Text, Box, Flex, theme } from '@fivehealth/botero';
import styled from 'styled-components';
import { parseISO, format, isValid } from 'date-fns';
import { VictoryTooltip, VictoryLabel, createContainer } from 'victory';

const chartColors = {
  primaryBlue: '#256BF6',
  primaryOrange: '#F89A50',
  ...theme.colors,
};

export const DiamondChartIcon = styled(Box)`
  width: 0;
  height: 0;
  border: ${({ size = 6 }) => size}px solid transparent;
  border-bottom-color: ${(props) => props.color || chartColors.danger};
  position: relative;
  top: -${({ size = 6 }) => size}px;
  &:after {
    content: '';
    position: absolute;
    left: -${({ size = 6 }) => size}px;
    top: ${({ size = 6 }) => size}px;
    width: 0;
    height: 0;
    border: ${({ size = 6 }) => size}px solid transparent;
    border-top-color: ${(props) => props.color || chartColors.danger};
  }
`;

export const CircleChartIcon = styled(Box)`
  background-color: ${(props) => props.color || chartColors.primaryBlue};
  height: 10px;
  width: 10px;
  border-radius: 10px;
  top: 3px;
  position: relative;
`;

export const SquareChartIcon = styled(Box)`
  background-color: ${(props) => props.color || chartColors.primaryBlue};
  height: 10px;
  width: 10px;
  top: 3px;
  position: relative;
`;

export const Container = styled(Box)`
  color: ${theme.colors.fullShade};
  margin: 0;
  padding: 0;
  font-size: 10px;
  height: 100%;
  width: 115;
  display: 'flex';
  align-items: center;
  justify-content: 'center';
`;
export const DataContainer = styled(Box)`
  border: 1px solid #d5d9de;
  border-radius: 8px;
  background-color: #ffffff;
  display: 'flex';
  padding: 1em 0.5em 0.5em 1em;
  align-items: center;
  justify-content: center;
`;

export const FlyoutLabel = ({ icon, valueText, description }) => (
  <Flex justifyContent="space-between" mt={1}>
    {icon && <Flex flex={0.12}>{icon}</Flex>}
    <Flex flex={icon ? 0.78 : 1}>
      <Box>
        <Text fontSize={12} fontWeight={500} color="fullShade">
          {valueText}
        </Text>
        {description && (
          <Text mt="4px" fontSize={12} color="darkestShade">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  </Flex>
);

export const FlyOut = ({ x, y, datum }) => (
  <g style={{ pointerEvents: 'none' }}>
    <foreignObject x={x - 40} y={y - 50} width="130" height="100%">
      <Container xmlns="http://www.w3.org/1999/xhtml">
        <DataContainer>
          <Text
            textAlign="center"
            color="darkestShade"
            fontSize={12}
            fontWeight={600}
          >
            {datum.timestamp &&
              format(
                isValid(parseISO(datum.timestamp))
                  ? parseISO(datum.timestamp)
                  : datum.timestamp,
                'MMM d yyyy HH:mm'
              )}
          </Text>
          <FlyoutLabel {...datum} />
        </DataContainer>
      </Container>
    </foreignObject>
  </g>
);

export const ChartLabel = (props) => <VictoryLabel {...props} />;

const ChartTooltip = ({ hideCursorLine, flyoutComponent, ...props }) => (
  <g>
    {props.active && !hideCursorLine && (
      <line
        x1={props.x}
        x2={props.x}
        y1={35}
        y2={465}
        stroke="#A3A9B1"
        strokeWidth="1"
      />
    )}
    <VictoryTooltip
      {...props}
      flyoutWidth={130}
      cornerRadius={5}
      flyoutComponent={flyoutComponent || <FlyOut />}
    />
  </g>
);

export const createContainerComponent = ({ showDataLabels, ...props }) => {
  const containerTypes = [showDataLabels ? 'zoom' : null, 'voronoi'].filter(
    Boolean
  );
  const ChartVoronoiContainer = createContainer(...containerTypes);
  return (
    <ChartVoronoiContainer
      style={{ touchAction: 'auto' }}
      labels={() => (showDataLabels ? undefined : ` `)}
      labelComponent={showDataLabels ? undefined : <ChartTooltip />}
      {...props}
    />
  );
};

export default ChartTooltip;
