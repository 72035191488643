import React, { useRef, useMemo, useEffect, memo } from 'react';
import {
  TableContainer,
  TableHead,
  TableRow as BTableRow,
  TableCell as BTableCell,
  TableHeadCell,
  TableBody,
  Flex,
  Text,
  Box,
  SecondaryOutlinedButton as OutlinedButton,
  theme,
  LogEvent,
} from '@fivehealth/botero';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
} from 'react-table';
import NoResults from 'components/NoResults/NoResults';
import { useSticky } from 'react-table-sticky';
import { useMediaQuery } from 'react-responsive';
import SortArrow from './SortArrow';

export const Styles = styled.div`
  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      font-size: 10px !important;
      ${(props) => props.isMobile && 'padding-top: 0px !important;'}
      ${(props) => props.isMobile && 'padding-bottom: 0px !important;'}
      ${(props) => props.isMobile && 'max-width: 100px !important;'}

      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const LoadMoreButton = styled(OutlinedButton)`
  border-radius: 8px;
  height: 40px;
  z-index: 3;
  position: absolute;
  background: white;
`;

export const HeaderColumn = styled(Flex)`
  cursor: pointer;
  align-items: center;
`;

const Content = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BoxContent = memo((props) => {
  const { children, py, style } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  return (
    <Content
      {...props}
      pl={!isMobile ? '30px' : '0px'}
      py={!isMobile && py}
      style={style}
    >
      {children}
    </Content>
  );
});

export const TableCell = styled(BTableCell)`
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & > div > div > div {
    ${(props) => props.isMobile && 'max-width: 100px !important;'}
    ${(props) => props.isMobile && 'font-size: 12px !important;'}
  }

  ${(props) => props.left && `left: ${props.left};`}
  // ${(props) => props.width && `max-width: ${props.width};`}
  ${(props) => props.isMobile && 'font-size: 12px !important;'}
  ${(props) =>
    props.isMobile &&
    'padding-top: 5px !important; padding-bottom: 5px !important;'}
  ${(props) =>
    props.isMobile &&
    props.sticky &&
    'padding-left: 0px !important; padding-right: 0px !important;'}

  ${(props) =>
    !props.isMobile &&
    !props.sticky &&
    'padding-left: 0px !important; padding-right: 0px !important;'}
  ${(props) =>
    props.isMobile &&
    props.sticky &&
    'display: flex !important; align-items: center !important;'}

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & > div {
    max-width: inherit;
  }
`;

export const TableRowWithSticky = styled(BTableRow)`
  [data-sticky-td] {
    position: sticky;
    overflow: hidden;
    background: white;
    opacity: 1;
    margin-top: 1px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    ${(props) => props.width && `left: ${props.width};`}
  }

  &:first-child {
    background: white;
  }

  [data-sticky-last-left-td] {
    box-shadow: 2px 0px 0px #ccc;
  }

  [data-sticky-first-right-td] {
    box-shadow: -2px 0px 0px #ccc;
  }
`;

export const TableRowWithoutSticky = styled(BTableRow)`
  cursor: ${(props) => props.cursor};
  overflow: hidden;
  tbody > &:hover {
    background: #f4f6f8;
  }
  tbody > & {
    box-shadow: inset 0px 1px 0px #d5d9de;
    &:hover,
    &:hover + tr {
      box-shadow: none;
    }
  }
`;

export const TableHeadCellCustom = styled(TableHeadCell)`
  ${(props) =>
    !props.isMobile &&
    !props.sticky &&
    'padding-left: 30px !important; padding-right: 0px !important;'}
  ${(props) => props.left && `left: ${props.left};`}
  // ${(props) => props.width && `max-width: ${props.width};`}
  ${(props) =>
    props.isMobile && props.sticky && 'display: flex: align-items: center;'}
  & > div > div > div {
    ${(props) => props.isMobile && 'font-size: 12px !important;'}
  }
`;

export const defaultPropGetter = () => ({});

export const defaultHeaderProps = (
  { width, maxWidth, toggleSortBy, isSortedDesc, canSort },
  props
) => ({
  style: {
    width,
    maxWidth,
    ...get(props, 'style', {}),
  },
  onClick: () => (canSort ? toggleSortBy(!isSortedDesc) : null),
  ...props,
});

const renderDefaultTable = ({
  rows,
  prepareRow,
  tableStyle,
  // onFetchNextPage,
  getTableProps,
  getTableBodyProps,
  getHeaderProps,
  getHeaderGroupProps = defaultPropGetter,
  getRowProps,
  getColumnProps,
  getCellProps,
  headerGroups,
  isMobile,
  logEventProps,
  headerCellProps,
  containerStyle = {},
  onRowClick,
  hasStickyColumn,
  combineClinicalParametersData,
}) => {
  const TableRow = hasStickyColumn ? TableRowWithSticky : TableRowWithoutSticky;

  return (
    <Box style={containerStyle}>
      <LogEvent
        logEventProps={logEventProps}
        actionProps={{
          onLoad: { action: 'load' },
          onClick: { action: 'click' },
        }}
        elementType="table"
      >
        <Styles isMobile={isMobile}>
          <TableContainer
            width="100%"
            minWidth={[null, theme.breakpoints[2]]}
            textAlign="left"
            {...getTableProps()}
            style={tableStyle}
          >
            <TableHead id={!combineClinicalParametersData && 'hide-print'}>
              {headerGroups.map((headerGroup, index) => (
                <TableRow
                  isMobile={isMobile}
                  hasStickyColumn={hasStickyColumn}
                  {...headerGroup.getHeaderGroupProps()}
                  {...getHeaderGroupProps()}
                  key={`table_row_${index}`}
                >
                  {headerGroup.headers.map((column, cIndex) => (
                    <TableHeadCellCustom
                      isMobile={isMobile}
                      py={2}
                      {...column.getHeaderProps([
                        defaultHeaderProps(column, getHeaderProps(column)),
                        getColumnProps(column),
                      ])}
                      key={`table_cell_${cIndex}`}
                    >
                      <HeaderColumn {...headerCellProps}>
                        <Box>
                          <Text
                            color="darkestShade"
                            fontWeight={600}
                            fontSize={14}
                            cursor={column.canSort ? 'pointer' : 'default'}
                          >
                            {column.render('Header')}
                          </Text>
                        </Box>
                        {!isEmpty(rows) && column.canSort && (
                          <Box pl="5px">
                            <SortArrow
                              active={column.isSorted && !column.isSortedDesc}
                            />
                            <SortArrow
                              down
                              active={column.isSorted && column.isSortedDesc}
                            />
                          </Box>
                        )}
                      </HeaderColumn>
                    </TableHeadCellCustom>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <TableRow
                    isMobile={isMobile}
                    cursor={onRowClick ? 'pointer' : ''}
                    {...row.getRowProps(getRowProps(row))}
                    height={hasStickyColumn ? 'auto' : '1px'}
                    isSelected={row.isSelected}
                    onClick={() => {
                      row.toggleRowSelected();
                    }}
                    key={`table_row_${index}`}
                    data-testid={`table_row_data_${index}`}
                    id={`table_row_data-${row.original.uid}`}
                  >
                    {row.cells.map((cell, cIndex) => (
                      <TableCell
                        isMobile={isMobile}
                        key={`table_cell_${cIndex}`}
                        id={`uid:${row.original.uid}`}
                        data-testid={`table_cell_data_${cIndex}`}
                        {...cell.getCellProps([
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                        py={0}
                        height="inherit"
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableContainer>
        </Styles>
      </LogEvent>
    </Box>
  );
};

const TableLayout = withTranslation()((props) => {
  const {
    onFetchNextPage,
    rows,
    isFetched,
    isFetchingNextPage,
    hasNextPage,
    loadMoreText,
    renderTable,
    noResultsProps,
    t,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  return (
    <>
      {renderTable
        ? renderTable({ ...props, isMobile })
        : renderDefaultTable({ ...props, isMobile })}
      {isFetched && isEmpty(rows) && <NoResults {...noResultsProps} />}
      {hasNextPage && (
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          mt={5}
          mb={5}
        >
          <LoadMoreButton
            onClick={onFetchNextPage}
            disabled={isFetchingNextPage}
            logEventProps={
              props.logEventProps
                ? {
                    subSource: props.logEventProps.subSource,
                    eventName: 'Load More Results',
                    page: props.logEventProps.page,
                  }
                : {}
            }
          >
            <Text color="darkestShade" fontSize={14} fontWeight={600}>
              {!isFetchingNextPage && (loadMoreText || t('Load more'))}
              {isFetchingNextPage && t('Loading...')}
            </Text>
          </LoadMoreButton>
        </Flex>
      )}
    </>
  );
});
const Table = ({
  columns: tableColumns,
  data: tableData,
  initialSortBy,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  hasNextPage,
  onFetchData,
  onFetchNextPage: fetchNextPage,
  isFetched,
  isFetchingNextPage,
  onRowClick,
  loadMoreText,
  logEventProps,
  renderTable,
  manualSorting,
  combineClinicalParametersData,
  ...props
}) => {
  const [columns, data] = useMemo(
    () => [tableColumns, tableData],
    [tableColumns, tableData]
  );
  const getRowId = (row, relativeIndex) => (row ? row.uid : relativeIndex);
  const pageYOffset = useRef(window.pageYOffset);

  const hasStickyColumn = columns.filter((col) => col.sticky).length > 0;

  let plugins = [useSortBy, usePagination, useRowSelect];

  if (hasStickyColumn) {
    plugins = plugins.concat([useBlockLayout, useSticky]);
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: manualSorting === false ? manualSorting : true,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            ...initialSortBy,
            id: initialSortBy.orderField,
            desc: initialSortBy.orderDesc,
          },
        ],
        pageSize: 5,
        pageIndex: 0,
      },
      getRowId,
      ...props,
    },
    ...plugins
  );
  const {
    state: { sortBy, pageSize, pageIndex },
  } = tableInstance;

  const onFetchNextPage = async () => {
    pageYOffset.current = window.pageYOffset;
    await fetchNextPage();
    window.scroll({ top: pageYOffset.current });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onFetchData({ pageIndex, pageSize, sortBy });
  }, [sortBy, pageIndex, pageSize]);

  return (
    <TableLayout
      {...tableInstance}
      {...{
        combineClinicalParametersData,
        getHeaderProps,
        getColumnProps,
        getRowProps,
        getCellProps,
        hasNextPage,
        logEventProps,
        isFetched,
        isFetchingNextPage,
        onFetchNextPage,
        onRowClick,
        loadMoreText,
        renderTable,
        hasStickyColumn,
      }}
    />
  );
};

export default Table;
