import React, { ReactElement } from 'react';
import { Text, Flex } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

interface RetryViewProps {
  onRefresh?: () => void;
  isLoading?: boolean;
}

export const RetryView = ({
  onRefresh,
  isLoading,
}: RetryViewProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Flex mt={2} width="100%" alignItems="center" flexDirection="column">
      <Text mb={2} color="darkestShade" fontSize={18}>
        {t?.('We are experiencing high traffic. Please try again momentarily.')}
      </Text>
      <Button
        width="fit-content"
        isLoading={isLoading}
        onClick={() => {
          onRefresh?.();
        }}
        variant="outline"
        colorScheme="blackAlpha"
        mt={2}
      >
        {t?.('Try again')}
      </Button>
    </Flex>
  );
};
