import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientFormCount(
    $activated: Boolean
    $patientUids: [String!]
    $nonCompliantSince: DateTime
  ) {
    cleoPatientFormCount(
      activated: $activated
      patientUids: $patientUids
      nonCompliantSince: $nonCompliantSince
    ) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  staleTime: 1000 * 60 * 5,
  cacheTime: 1000 * 60 * 5,
  queryType: 'query',
  baseQueryKey: 'patientFormsCountMonitoring',
  select: (data) => get(data, 'cleoPatientFormCount.totalCount'),
});
