// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const WebMessenger = memo<IconProps>(
  ({ color, width = 16, height = 17, viewBox }) => (
    <Icon>
      <svg
        width={width}
        height={height}
        viewBox={viewBox || '0 0 16 17'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.25C12.375 0.25 15.75 3.46875 15.75 7.78125C15.75 13.25 10.5312 16.3125 5.75 15C5.46875 14.9375 5.4375 15 3.8125 15.7188C3.71875 15.75 3.625 15.75 3.5625 15.75C3.21875 15.75 2.9375 15.5 2.9375 15.1562C2.875 13.7188 2.9375 13.5938 2.6875 13.3438C1.15625 12 0.25 10.0312 0.25 7.78125C0.25 3.46875 3.625 0.25 8 0.25ZM12.6562 6.0625C12.875 5.71875 12.4688 5.3125 12.125 5.5625L9.6875 7.40625C9.59375 7.46875 9.5 7.5 9.40625 7.5C9.28125 7.5 9.1875 7.46875 9.125 7.40625L7.3125 6.0625C7.125 5.90625 6.875 5.8125 6.59375 5.8125C6.1875 5.8125 5.8125 6.03125 5.625 6.375L3.34375 9.96875C3.125 10.3125 3.53125 10.7188 3.875 10.4688L6.3125 8.625C6.40625 8.5625 6.5 8.53125 6.59375 8.53125C6.71875 8.53125 6.8125 8.5625 6.875 8.625L8.6875 9.96875C8.90625 10.125 9.125 10.2188 9.40625 10.2188C9.8125 10.2188 10.1875 10 10.375 9.65625L12.6562 6.0625Z"
          fill={color}
        />
      </svg>
    </Icon>
  )
);

export default WebMessenger;
