import React, { useEffect } from 'react';
import { useAuth, useApi } from '@fivehealth/botero';
import {
  Switch,
  Route as ReactRouterRoute,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dashboard from 'views/Dashboard/Dashboard';
import PatientMonitoring from 'views/PatientMonitoring/PatientMonitoring';
import PatientsList from 'views/PatientsList/PatientsList';
import PatientShow from 'views/PatientsList/PatientShow';
import { BillingDashboard } from 'views/Billing/BillingDashboard';
import ClinicalAdminList from 'views/ClinicalAdmins/ClinicalAdminList';
import { useAppData } from 'context/AppDataContext';
import { isEmpty } from 'lodash';
import CaregiverList from 'views/CaregiverList/CaregiverList';
import AuthCallback from 'views/AuthCallback';
import PatientGroups from 'views/PatientGroups/PatientGroups';
import Appointments from 'views/Appointments/Appointments';

const clinic = new URLSearchParams(document.location.search).get('clinic');
const clientJwt = new URLSearchParams(document.location.search).get('jwt');

const Route = ({ children, ...rest }) => {
  const {
    authState: { authenticated },
  } = useAuth();

  const {
    queries: { usePatientWithSession },
  } = useApi({
    queries: ['usePatientWithSession'],
  });

  const history = useHistory();
  const { i18n, t } = useTranslation();

  const { user } = useAppData();

  const { data: patientInfo } = usePatientWithSession({
    variables: {},
    enabled: !!(authenticated && user?.isPatientFacing),
  });

  useEffect(() => {
    const lang = new URLSearchParams(window.location.search).get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    }

    const isE2E = new URLSearchParams(window.location.search).get('e2e');
    if (isE2E === 'true') {
      history.push(`/login?e2e=true`);
      return;
    }

    if (!authenticated) {
      if (clinic && !clientJwt) {
        window.location.replace(
          `/login/${clinic}?path=${window.location.pathname}`
        );
      }
      history.push(`/login?path=${window.location.pathname}`);
    }
  }, [authenticated, history, i18n]);

  const redirectToPatientScreen = () =>
    patientInfo?.cleoPatient?.uid ? (
      history.push(`/patient-list/${patientInfo?.cleoPatient?.uid}/graphs`)
    ) : (
      <div style={{ textAlign: 'center' }}>
        <h3>{t('Your session is expired, log out and log back in again')}</h3>
      </div>
    );

  const render = () => {
    if (!authenticated) {
      return null;
    }

    if (user?.isPatientFacing) {
      if (isEmpty(patientInfo)) return null;

      return !window.location.pathname.includes('patient-list')
        ? redirectToPatientScreen()
        : children;
    }

    return children;
  };

  return <ReactRouterRoute {...rest} render={render} />;
};

const Routes = () => (
  <Switch>
    <ReactRouterRoute exact path="/auth/callback">
      <AuthCallback />
    </ReactRouterRoute>
    <Route path="/caregiver-list">
      <CaregiverList />
    </Route>
    <Route path="/patient-monitoring/alerts">
      <PatientMonitoring activeTab={2} />
    </Route>
    <Route path="/patient-monitoring/non-compliant">
      <PatientMonitoring activeTab={3} />
    </Route>
    <Route path="/patient-monitoring/unread-comments">
      <PatientMonitoring activeTab={4} />
    </Route>
    <Route path="/patient-monitoring">
      <PatientMonitoring />
    </Route>
    <Route path="/overview">
      <Dashboard />
    </Route>

    <Route path="/patient-groups">
      <PatientGroups />
    </Route>
    <Route path="/billing">
      <BillingDashboard />
    </Route>

    <Route path="/patient-list/:uid/submissions">
      <PatientShow activeTab={1} />
    </Route>
    <Route path="/patient-list/:uid/graphs">
      <PatientShow activeTab={2} />
    </Route>
    <Route path="/patient-list/:uid/clinical-settings">
      <PatientShow activeTab={3} />
    </Route>

    <Route path="/patient-list/:uid/messages">
      <PatientShow activeTab={4} />
    </Route>

    <Route path="/patient-list/:uid/appointments">
      <PatientShow activeTab={5} />
    </Route>

    <Route path="/patient-list/:uid/:formuid/submissions">
      <PatientShow activeTab={1} />
    </Route>
    <Route path="/patient-list/:uid/:formuid/graphs">
      <PatientShow activeTab={2} />
    </Route>
    <Route path="/patient-list/:uid/:formuid/clinical-settings">
      <PatientShow activeTab={3} />
    </Route>

    <Route path="/patient-list/discharged">
      <PatientsList activeTab={1} />
    </Route>

    <Route path="/patient-list/:uid/:formuid">
      <PatientShow />
    </Route>
    <Route path="/patient-list/:uid">
      <PatientShow />
    </Route>
    <Route path="/patient-list">
      <PatientsList />
    </Route>
    <Route path="/clinical-admins">
      <ClinicalAdminList />
    </Route>
    <Route exact path="/appointments">
      <Appointments />
    </Route>

    <Route exact path="/appointments/upcoming">
      <Appointments selectedTab={1} />
    </Route>

    <Route exact path="/appointments/past">
      <Appointments selectedTab={2} />
    </Route>

    <ReactRouterRoute
      exact
      path="/:organization_country/:clinicname?"
      render={({ match }) => (
        <Redirect
          to={`/login/${match.params.organization_country}/${
            match.params.clinicname || ''
          }`}
        />
      )}
    />
    <Route path="/">
      <Dashboard />
    </Route>
  </Switch>
);

export default Routes;
