import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FAIcon, Form, useApi, Body } from '@fivehealth/botero';
import { formatISO } from 'date-fns';
import { usePatientsQuery } from 'hooks';

import {
  faCalendarAlt,
  faChevronDown,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { filter, get, isEmpty, isEqual } from 'lodash';
import Colors from 'constants/colors';
import { InfoText } from 'components/InfoText/InfoText';
import { parse } from 'json5';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import AllGoodAvatar from '../../assets/all-good-avatar.svg';
import { ModalView } from '../../components/ModalView/ModalView';
import { Patient } from './AppointmentDetails';

const MenuItemStyle = {
  maxW: '312px',
  bg: 'transparent !important',
  _hover: {
    backgroundColor: 'transparent',
  },
};

interface EventTypeOption {
  eventType: {
    uid: string;
    name: string;
  };
  effectiveEventUrl: string;
}

interface CalendarOption {
  name: string;
  uid: string;
  calendarUrl: string;
  eventTypes?: EventTypeOption[];
}

export type AddAppointmentViews = 'addPatient' | 'addAppointment' | 'success';
export interface AddAppointmentProps {
  onClose: (patientUid: string) => void;
}

export const AddAppointment = memo<AddAppointmentProps>(({ onClose }) => {
  const [view, setView] = useState<AddAppointmentViews>('addPatient');
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const { t } = useTranslation();
  const patientFormRef = useRef<{
    getFormData: () => Patient;
  }>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appointmentFormRef = useRef<any>();

  const [isPatientFormValid, setIsPatientFormValid] = useState(false);
  const [isAppointmentFormValid, setIsAppointmentFormValid] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const [selectedEventType, setSelectedEventType] = useState<EventTypeOption>();
  const [selectedClinicianCalendar, setSelectedClinicianCalendar] =
    useState<CalendarOption>();

  const [, setAsyncJobData] = useRecoilState(asyncJobState);

  const {
    queries: {
      useClinic,
      usePatientCreate,
      useClinicCalendars,
      useClinicCalendarEventTypes,
    },
  } = useApi({
    queries: [
      'useClinic',
      'usePatientCreate',
      'useClinicCalendars',
      'useClinicCalendarEventTypes',
    ],
  });

  const {
    mutateAsync: createPatient,
    isLoading: isLoadingCreatePatients,
    data,
  } = usePatientCreate();

  const { data: eventTypes } = useClinicCalendarEventTypes({
    enabled: !!selectedClinicianCalendar?.uid,
    variables: {
      clinicCalendarUidIn: [selectedClinicianCalendar?.uid as string],
      eventTypeDeactivatedOnIsnull: true,
    },
  });

  const errorMessage = useMemo(() => {
    let msg = get(data?.errors, '[0].message', '');
    if (!msg) {
      return '';
    }
    msg = parse(msg) as { [key: string]: string[] };
    return Object.values(msg).join(', ');
  }, [data?.errors]);

  const { data: clinic } = useClinic({});
  const {
    loading: loadingPatients,
    reload,
    patientsDropdownOptions,
    searchPatients,
  } = usePatientsQuery(true);

  const patientsOptions = useRef<
    Array<{
      label: string;
      value: Patient;
    }>
  >();

  useEffect(() => {
    patientsOptions.current = patientsDropdownOptions;
  }, [selectedPatient, patientsDropdownOptions]);

  const { data: clinicCalendars } = useClinicCalendars({
    enabled: !!selectedPatient?.id,
  }) as {
    data: CalendarOption[];
    refetch: () => void;
  };

  const clinicCalendarDopdownOptions = useMemo(() => {
    return clinicCalendars
      ?.map(({ name, calendarUrl, uid }: CalendarOption) => {
        if (!calendarUrl) {
          return null;
        }
        return {
          value: {
            name,
            calendarUrl,
            uid,
          },
          label: name,
        };
      })
      .filter(Boolean);
  }, [clinicCalendars]);

  const eventTypesDropdownOptions = useMemo(
    () =>
      eventTypes?.map(
        ({
          effectiveEventUrl,
          eventType: { name, uid },
        }: {
          effectiveEventUrl: string;
          eventType: {
            name: string;
            uid: string;
          };
        }) => ({
          value: {
            uid,
            name,
            effectiveEventUrl,
          },
          label: name,
        })
      ),
    [eventTypes]
  );

  const isLoading = useMemo(() => {
    return isLoadingCreatePatients || loadingPatients;
  }, [isLoadingCreatePatients, loadingPatients]);

  const communicationMethods = useMemo(
    () =>
      filter(
        clinic?.communicationMethods,
        ({ value }) => !isEqual(value, 'email')
      ),
    [clinic?.communicationMethods]
  );

  const patientLevelForm = useMemo(
    () => ({
      id: 'patientInformation',
      fields: [
        {
          id: 'identifier',
          type: 'input',
          label: t('Patient Identifer*'),
          placeholder: '11XXXXXXXX',
          required: true,
          disabled: isLoading,
        },
        {
          id: 'name',
          type: 'input',
          label: t('Patient Name*'),
          placeholder: 'Enter patient name',
          required: true,
          disabled: isLoading,
        },
        {
          id: 'communicationMethod',
          type: 'select',
          label: t('Messaging channel*'),
          required: true,
          options: communicationMethods,
          disabled: isLoading,
        },

        {
          id: 'phone',
          type: 'phone',
          label: t('Phone number*'),
          required: true,
          disabled: isLoading,
        },
        {
          id: 'email',
          type: 'input',
          label: t('Email'),
          placeholder: 'Email address',
          disabled: isLoading,
        },
        {
          id: 'gender',
          type: 'select',
          label: t('Gender'),
          options: [
            { gql: 'gender', label: t('Female'), value: 'Female' },
            { gql: 'gender', label: t('Male'), value: 'Male' },
          ],
          disabled: isLoading,
        },
        {
          id: 'dateOfBirth',
          type: 'date',
          label: t('Date of birth'),
          disabled: isLoading,
        },
      ],
    }),
    [communicationMethods, isLoading, t]
  );

  useEffect(() => {
    if (selectedPatient) {
      appointmentFormRef.current?.setValue('patient', selectedPatient);
    }
  }, [selectedPatient]);

  const appointmentForm = useMemo(
    () => ({
      id: 'appointmentForm',
      fields: [
        patientsOptions.current && {
          id: 'patient',
          type: 'select',
          label: t('Select patient'),
          required: true,
          // value: selectedPatient,
          options: patientsOptions.current,
          disabled: isLoading,
          onInputChange: (value: string) => {
            searchPatients(value);
          },
          callback: (value: Patient) => {
            setSelectedPatient(value);
          },
        },
        clinicCalendarDopdownOptions && {
          id: 'calendar',
          type: 'select',
          label: t('Calendar account'),
          required: true,
          options: clinicCalendarDopdownOptions,
          disabled: isLoading,
          callback: (value: CalendarOption) => {
            setSelectedClinicianCalendar(value);
            setSelectedEventType(undefined);
            appointmentFormRef.current?.resetField('calendarEvent');
          },
        },
        selectedClinicianCalendar && {
          id: 'calendarEvent',
          type: 'select',
          label: t('Calendar event'),
          required: true,
          options: eventTypesDropdownOptions,
          disabled: isLoading,
          callback: (value: EventTypeOption) => {
            setSelectedEventType(value);
          },
        },
      ].filter(Boolean),
    }),
    [
      t,
      isLoading,
      clinicCalendarDopdownOptions,
      selectedClinicianCalendar,
      eventTypesDropdownOptions,
      searchPatients,
      // selectedPatient,
    ]
  );

  const cancelBtn = useMemo(
    () => (
      <Button
        bg="emptyShade"
        borderWidth={1}
        borderColor="mediumShade"
        color={Colors.darkestShade}
        onClick={() => {
          setShowAddAppointmentModal(false);
        }}
      >
        {t('Cancel')}
      </Button>
    ),
    [t]
  );

  const createPatientHandler = useCallback(async () => {
    try {
      const payload = patientFormRef.current?.getFormData();
      let gender = payload?.gender;
      if (gender) {
        gender = gender === 'Female' ? 'F' : 'M';
      }
      const createPatientResult = await createPatient({
        input: {
          ...payload,
          dateOfBirth: payload?.dateOfBirth
            ? formatISO(new Date(payload?.dateOfBirth))
            : undefined,
          communicationMethod: payload?.communicationMethod?.toUpperCase(),
          gender,
        },
      });
      const {
        cleoPatientCreate: { cleoPatient },
      } = createPatientResult;
      if (!isEmpty(cleoPatient)) {
        setView('success');
      }
    } catch (error) {
      // console.log({ error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPatient, patientLevelForm]);

  const addPatientView = useCallback(() => {
    return {
      header: 'New Patient',
      body: (
        <Box>
          <Form
            form={patientLevelForm}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formRef={(ref: any) => {
              if (ref) {
                patientFormRef.current = ref;
                setIsPatientFormValid(ref.isValid());
              }
            }}
            fieldPerRow={isMobile ? 1 : 2}
            titleProps={{
              fontSize: '16px',
            }}
            inputLabelProps={{
              mt: 0,
            }}
            inputLabelTextProps={{
              fontSize: '12px',
            }}
          />
          {errorMessage && (
            <InfoText
              message={errorMessage}
              bg={Colors.danger}
              showIcon={false}
              textColor={Colors.white}
            />
          )}
        </Box>
      ),
      footer: (
        <Flex>
          {cancelBtn}
          <Button
            isDisabled={!isPatientFormValid}
            isLoading={isLoading}
            ml={5}
            colorScheme="brand"
            onClick={createPatientHandler}
          >
            {t('Add Patient')}
          </Button>
        </Flex>
      ),
    };
  }, [
    isLoading,
    cancelBtn,
    createPatientHandler,
    errorMessage,
    isMobile,
    isPatientFormValid,
    patientLevelForm,
    t,
  ]);

  const addPatientSuccessView = useMemo(() => {
    return {
      header: null,
      body: (
        <Flex p={6} flexDirection="column" alignItems="center">
          <Flex justifyContent="center" mb={4}>
            <Box height="120px" as="img" src={AllGoodAvatar} />
          </Flex>

          <Text fontWeight="600" fontSize={16} mb={2}>
            {t(`Patient successfully added!`)}
          </Text>
          <Body mt={1} mb={2} color="darkestShade" fontSize={14} small>
            {moment().format('YYYY-MM-DD HH:mm')}
          </Body>

          <Flex>
            <Button
              bg="emptyShade"
              borderWidth={1}
              borderColor="mediumShade"
              color={Colors.darkestShade}
              onClick={() => {
                setShowAddAppointmentModal(false);
              }}
            >
              {t('Close')}
            </Button>
            <Button
              ml={5}
              colorScheme="brand"
              onClick={() => {
                reload();
                setView('addAppointment');
              }}
            >
              {t('Schedule appointment')}
            </Button>
          </Flex>
        </Flex>
      ),
      footer: null,
    };
  }, [reload, t]);

  const calendarUrl = useMemo(() => {
    const [firstName, ...lastName] = selectedPatient?.name?.split(' ') ?? [];
    const url =
      selectedEventType?.effectiveEventUrl
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${profile[email]}',
          encodeURIComponent(selectedPatient?.email ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[email]}',
          encodeURIComponent(selectedPatient?.email ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[phone]}',
          encodeURIComponent(selectedPatient?.phone ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${profile[phone]}',
          encodeURIComponent(selectedPatient?.phone ?? '')
        )

        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[name]}',
          encodeURIComponent(selectedPatient?.name ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[firstName]}',
          encodeURIComponent(firstName ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[lastName]}',
          encodeURIComponent(lastName.join(' ') ?? '')
        )
        .replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${patient[identifier]}',
          selectedPatient?.identifier ?? ''
        ) || '';

    if (url.includes('botmd.as.me')) {
      const appointmentId = get(selectedEventType, 'metadata.acuity.id', '');
      return appointmentId ? `${url}&appointmentType=${appointmentId}` : url;
    }

    return url;
  }, [selectedPatient, selectedEventType]);

  const addAppointmentView = useMemo(() => {
    return {
      header: t('Schedule appointment'),
      body: (
        <Form
          form={appointmentForm}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formRef={(ref: any) => {
            if (ref) {
              appointmentFormRef.current = ref;
              setIsAppointmentFormValid(ref.isValid() && !!selectedPatient);
            }
          }}
          defaultFormData={{
            patient: selectedPatient,
          }}
          fieldPerRow={1}
          titleProps={{
            fontSize: '16px',
          }}
          inputLabelProps={{
            mt: 0,
          }}
          inputLabelTextProps={{
            fontSize: '12px',
          }}
        />
      ),
      footer: (
        <Flex>
          {cancelBtn}
          <Button
            ml={5}
            isDisabled={!isAppointmentFormValid}
            colorScheme="brand"
            onClick={() => {
              setAsyncJobData(undefined);
              onClose(selectedPatient?.uid || '');
              setShowAddAppointmentModal(false);
              window.open(calendarUrl, '_blank');
            }}
          >
            {t('Select Date & Time')}
          </Button>
        </Flex>
      ),
    };
  }, [
    t,
    appointmentForm,
    selectedPatient,
    cancelBtn,
    isAppointmentFormValid,
    setAsyncJobData,
    onClose,
    calendarUrl,
  ]);

  const selectedView = useMemo(() => {
    switch (view) {
      case 'addPatient':
        return addPatientView();
      case 'addAppointment':
        return addAppointmentView;
      case 'success':
        return addPatientSuccessView;
      default:
        return addPatientView();
    }
  }, [addAppointmentView, addPatientView, addPatientSuccessView, view]);

  return (
    <>
      <Menu>
        <MenuButton
          colorScheme="brand"
          as={Button}
          rightIcon={
            <FAIcon icon={faChevronDown} fontSize="14px" color="emptyShade" />
          }
        >
          <Text>Add Appointment</Text>
        </MenuButton>
        <MenuList>
          <MenuItem {...MenuItemStyle}>
            <Flex
              onClick={() => {
                setView('addPatient');
                setShowAddAppointmentModal(true);
              }}
              p={3}
              _hover={{
                backgroundColor: '#F4F6F8',
                borderRadius: 8,
              }}
            >
              <FAIcon icon={faUserPlus} />
              <Box ml={2} mt={-1}>
                <Text mb={1} fontWeight="500" fontSize={14}>
                  Add Patient
                </Text>
                <Text color="#697481" fontSize={14}>
                  Enroll a new patient and schedule an appointment
                </Text>
              </Box>
            </Flex>
          </MenuItem>
          <MenuItem {...MenuItemStyle}>
            <Flex
              onClick={() => {
                setView('addAppointment');
                setShowAddAppointmentModal(true);
              }}
              p={3}
              _hover={{
                backgroundColor: '#F4F6F8',
                borderRadius: 8,
              }}
            >
              <FAIcon icon={faCalendarAlt} />
              <Box ml={2} mt={-1}>
                <Text mb={1} fontWeight="500" fontSize={14}>
                  Existing patient
                </Text>
                <Text color="#697481" fontSize={14}>
                  Schedule an appointment for an existing patient
                </Text>
              </Box>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>

      {showAddAppointmentModal && (
        <ModalView
          size={isMobile ? 'full' : '2xl'}
          header={selectedView.header}
          body={selectedView.body}
          footer={selectedView.footer}
          onClose={() => {
            setView('addPatient');
            setShowAddAppointmentModal(false);
          }}
        />
      )}
    </>
  );
});
