import { Flex, Box, H2 } from '@fivehealth/botero';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import settingsLoader from '../../assets/settings_loader.json';

// create a component
const LoadingOverlay = ({
  containerProps = {},
  loadingText = 'Please wait one moment...',
  textColor,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      height="inherit"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      {...containerProps}
    >
      <Box>
        <Lottie
          play
          animationData={settingsLoader}
          style={{ width: 100, height: 100 }}
        />
      </Box>
      <Box>
        <H2 color={textColor || 'darkShade'}>{t(loadingText)}</H2>
      </Box>
    </Flex>
  );
};

export default LoadingOverlay;
