import { get, isFunction } from 'lodash';

// Browser-only, requires document node
//
const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);
  // Create a link to download it
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', filename);
  a.click();
};

export const createCSVData = (data) =>
  data
    .map(
      (row) =>
        row
          .map(String) // convert all o String
          .map((v) => v.replaceAll('"', '""')) // escape
          .map((v) => `"${v}"`) // quote
          .join(',') // comma separate
    )
    .join('\r\n');

export const createCSV = (dataArray = [], mapping = {}) => {
  const mappedDataArray = dataArray.flat().map((data) =>
    Object.keys(mapping).reduce(
      (o, key) =>
        Object.assign(o, {
          [key]: isFunction(mapping[key])
            ? mapping[key](data)
            : get(data, mapping[key], ''),
        }),
      {}
    )
  );
  const headers = mappedDataArray[0] ? Object.keys(mappedDataArray[0]) : [];
  return createCSVData([headers, ...mappedDataArray.map(Object.values)]);
};

const exportCSV = (filename, csvData, mapping = {}) => {
  const csv = createCSV(csvData, mapping);
  downloadCSV(csv, filename);
};

export default exportCSV;
