import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  H2,
  Flex,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  useApi,
} from '@fivehealth/botero';
import { map, chain, get, pickBy, some, isEmpty, isEqual } from 'lodash';
import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useForm, useFieldArray } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import { getClinicalDesignationOptions, getFirstAndLastName } from 'AppUtils';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import ClinicalUserForm from './AddClinicalForm';

const AddClinicalUserModal = ({
  closeModal,
  user: clinicalUser,
  currentUser,
  clinicData,
}) => {
  const { onClose } = useDisclosure();

  const [showServerError, setShowServerError] = useState();
  const { t } = useTranslation();
  const {
    queries: {
      useCreateClinician,
      useUpdateClinician,
      useClinicianDesignations,
      useCleoRoles,
    },
  } = useApi({
    queries: [
      'useCreateClinician',
      'useUpdateClinician',
      'useClinicianDesignations',
      'useCleoRoles',
    ],
  });

  const { data: clinicalDesignations } = useClinicianDesignations();
  const { data: clinicianRolesData } = useCleoRoles();

  const clinicalRoles = map(clinicianRolesData, (role) => ({
    label: role.name,
    value: role.uid,
  }));

  const isEditView = clinicalUser && clinicalUser.uid;

  const details = isEditView && getFirstAndLastName(clinicalUser.name);

  const clinicalUserData = useMemo(
    () =>
      isEditView
        ? {
            ...clinicalUser,
            firstName: details?.firstName,
            lastName: details?.lastName,
            communicationMethod: chain(clinicData)
              .get('communicationMethods')
              .find({ gql: clinicalUser.communicationMethod })
              .value(),
            role: {
              label: clinicalUser.role?.name,
              value: clinicalUser.role?.uid,
            },
            designation: clinicalDesignations?.cleoClinicianDesignations
              ? getClinicalDesignationOptions(
                  clinicalDesignations?.cleoClinicianDesignations
                ).find(({ value }) => value === clinicalUser.designation)
              : [],
          }
        : {},
    [
      clinicData,
      clinicalDesignations?.cleoClinicianDesignations,
      clinicalUser,
      details?.firstName,
      details?.lastName,
      isEditView,
    ]
  );

  const { mutateAsync: createClinician, isLoading: isCreating } =
    useCreateClinician({
      variables: {},
    });

  const { mutateAsync: updateClinician, isLoading: isUpdating } =
    useUpdateClinician({
      variables: {},
    });

  const isLoading = isUpdating || isCreating;

  const defaultValues = useMemo(
    () => ({
      clinicalUsers: [isEditView ? clinicalUserData : {}],
    }),
    [clinicalUserData, isEditView]
  );

  const { handleSubmit, control, setError, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (isEditView) {
      reset(defaultValues);
    }
  }, [clinicalDesignations, defaultValues, isEditView, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clinicalUsers',
  });

  const onSubmit = (data) => {
    const clinicalUsers = data.clinicalUsers.map(
      ({ phone, email, ...user }) => {
        const formattedPhone = /^\+/.test(phone) ? phone : `+${phone}`;
        const parsedPhone = parsePhoneNumber(formattedPhone);

        return pickBy({
          uid: isEditView ? clinicalUser.uid : null,
          name: `${get(user, 'firstName', '')} ${get(
            user,
            'lastName',
            ''
          )}`.trim(),
          email: email?.replace(/\s/g, '') ?? '',
          communicationMethod: get(user, 'communicationMethod.gql'),
          designation: get(user, 'designation.value'),
          phone: parsedPhone ? parsedPhone.number : phone,
          role: !isEqual(clinicalUser?.uid, currentUser?.uid)
            ? { uid: user?.role?.value }
            : null,
        });
      }
    );
    const editedClinician = clinicalUsers[0];

    const promise = isEditView
      ? Promise.all([updateClinician({ input: editedClinician })])
      : Promise.all(clinicalUsers.map((input) => createClinician({ input })));

    return promise.then((res) => {
      if (
        some(
          res.map(
            ({ cleoClinicianCreate, cleoClinicianUpdate }) =>
              cleoClinicianCreate || cleoClinicianUpdate
          ),
          (clinician) => isEmpty(clinician)
        )
      ) {
        return setShowServerError(true);
      }
      setShowServerError(false);
      return closeModal();
    });
  };

  return (
    <Modal
      isOpen
      size="3xl"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
        closeModal();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent
          sx={{
            '@media (max-width: 768px)': {
              marginLeft: '20px',
              marginRight: '20px',
            },
          }}
        >
          <ModalHeader pb={0} mt={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <H2 color="fullShade">
                {isEditView ? t('Edit') : t('Add New')} {t('Clinical User')}
              </H2>
              <Box cursor="pointer" onClick={closeModal}>
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>
          </ModalHeader>
          <ModalBody>
            {fields.map((field, index) => (
              <ClinicalUserForm
                clinicData={clinicData}
                key={field.id}
                isLoading={isLoading}
                control={control}
                field={field}
                index={index}
                setError={setError}
                showServerError={showServerError}
                isEditView={isEditView}
                onRemove={remove}
                clinicalRoles={clinicalRoles}
                currentUser={currentUser}
                clinicalUser={clinicalUser}
                formState={formState}
                {...formState}
              />
            ))}
            <Flex
              mt={4}
              alignItems="center"
              cursor="pointer"
              onClick={() => (formState.isValid ? append({}) : null)}
              opacity={formState.isValid ? 1 : 0.5}
            >
              {!isEditView && (
                <>
                  <FAIcon icon={faPlus} fontSize="auto" color="primary" />
                  <H5 ml={1} color="primary">
                    {t('Add another user')}
                  </H5>
                </>
              )}
            </Flex>

            {showServerError && (
              <ErrorBanner
                text={t(
                  'An error has occured. Please check your input and try submitting again.'
                )}
                mt={3}
              />
            )}
          </ModalBody>

          <ModalFooter mb={4}>
            <SecondaryOutlinedButton onClick={closeModal}>
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              ml={3}
              type="submit"
              disabled={!formState.isValid || isLoading}
            >
              {isEditView ? t('Save') : t('Add users')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddClinicalUserModal;
