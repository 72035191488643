import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query getcleoClinicRole {
    cleoClinic {
      caregiverRole {
        uid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'caregiverRole',
  keepPreviousData: false,
  select: ({ cleoClinic }) => cleoClinic?.caregiverRole?.uid,
});
