import React, { useCallback, useMemo } from 'react';
import { Drawer } from '@fivehealth/botero';

const DrawerContext = React.createContext(null);

export const useDrawer = () => {
  const context = React.useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer is outside DrawerProvider');
  }
  return context;
};

const DrawerProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState(null);

  const openDrawer = useCallback((component) => {
    setOpen(true);
    setContent(component);
  }, []);

  const closeDrawer = useCallback(() => {
    setContent(null);
    setOpen(false);
  }, []);

  return useMemo(
    () => (
      <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
        <Drawer open={open} onClose={closeDrawer}>
          {content}
        </Drawer>
        {children}
      </DrawerContext.Provider>
    ),
    [children, closeDrawer, content, open, openDrawer]
  );
};

export default DrawerProvider;
