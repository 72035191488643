import React, { useCallback, useMemo, useState } from 'react';
import { chain, flatMap, includes, some } from 'lodash';
import {
  Box,
  Flex,
  PrimaryButton,
  Select,
  SecondaryOutlinedButton,
  Text,
  TextLink,
  theme,
  useApi,
  Body,
  H4,
  StatusBar,
} from '@fivehealth/botero';

import { customerSupportEmail, separateIntosubList } from 'AppUtils';
import DialogTitle from 'components/Dialog/DialogTitle';

import { BoxPairRightPanel } from 'components/Layout';
import useAlertRemindersData from 'hooks/useAlertRemindersData';
import useCliniciansData from 'hooks/useCliniciansData';
import { useEnrollDischargeAsync } from 'hooks';
import AllGoodAvatar from '../../../assets/all-good-avatar.svg';
import CryingAvatar from '../../../assets/crying-avatar.svg';

const SplitTreshhold = 50;

const AddFormModal = ({
  t,
  onClose,
  patients,
  enrolledMonitoringFormUidsParam,
  isGroup,
  patientGroupUids,
  groupNames,
  filtersApplied,
  filteredPatientsCount,
}) => {
  const [selectedForm, setSelectedForm] = useState();

  const [selectedReminderOption, setSelectedReminderOption] = useState();
  const [selectedAlertee, setSelectedAlertee] = useState();
  const [selectedClinicianInCharge, setSelectedClinicianInCharge] = useState();

  const [showAddClinicalParam, setShowAddClinicalParam] = useState(true);

  const { clinicRemindersSelectOptions } = useAlertRemindersData(true);

  const { cliniciansSelectOptions } = useCliniciansData();

  const [enrollmentResponse, setEnrollmentResponse] = useState({
    hasPreviouslyEnrolled: false,
    successEnrollment: false,
  });

  const {
    queries: { useMonitoringFormsets, usePatientFormEnrollDischarge },
  } = useApi({
    queries: ['useMonitoringFormsets', 'usePatientFormEnrollDischarge'],
  });

  const { dischargeOrEnrollPatients } = useEnrollDischargeAsync();

  const patientNames = useMemo(() => {
    if (!filtersApplied && patients?.length <= 10) {
      return patients?.map((p) => p?.name).join(', ');
    }

    if (filteredPatientsCount && filteredPatientsCount > 0) {
      return `${filteredPatientsCount} patients selected`;
    }

    return `${patients?.length} patients selected`;
  }, [patients, filteredPatientsCount, filtersApplied]);

  const patientUids = patients?.map((p) => p.uid);

  const { mutateAsync: enrollPatientAndMonitoringForm } =
    usePatientFormEnrollDischarge({
      variables: {},
    });

  const { data: monitoringForms, isLoading } = useMonitoringFormsets({
    variables: {
      isEnrollmentForm: false,
    },
  });

  const enrolledMonitoringFormUids = enrolledMonitoringFormUidsParam;

  const monitoringFormsOptions = useMemo(
    () =>
      chain(monitoringForms)
        .filter((form) => !form?.isEnrollmentForm)
        .filter((form) => !includes(enrolledMonitoringFormUids, form?.id))
        .map((form) => ({
          label: form?.label,
          value: form,
        }))
        .value(),
    [enrolledMonitoringFormUids, monitoringForms]
  );

  /*
   *
   */
  const computeResults = (results) => {
    const result = {
      hasPreviouslyEnrolled: false,
      successEnrollment: false,
    };

    result.hasPreviouslyEnrolled = some(results, (r) => r.isPreviouslyEnrolled);

    result.successEnrollment = some(
      results,
      (r) => r.isPreviouslyEnrolled === false
    );

    setEnrollmentResponse(result);
  };

  const handleSubmit = useCallback(async () => {
    const additionalInputs = {};
    if (selectedReminderOption?.value) {
      additionalInputs.reminders = [selectedReminderOption.value];
    }
    if (selectedClinicianInCharge?.value) {
      additionalInputs.clinicianIcs = [selectedClinicianInCharge.value];
    }
    if (selectedAlertee?.value) {
      additionalInputs.clinicianAlertees = [selectedAlertee.value];
    }

    if (filtersApplied) {
      additionalInputs.patientFilter = filtersApplied;
    }

    if (!isGroup) {
      if (patientUids.length > SplitTreshhold) {
        const separatedLists = separateIntosubList(patientUids, SplitTreshhold);

        const calls = [];

        separatedLists.forEach(async (list) => {
          calls.push(
            enrollPatientAndMonitoringForm({
              input: {
                patientUids: list,
                monitoringFormUid: selectedForm?.value?.id,
                operation: 'ENROLL',
                ...additionalInputs,
              },
            }).then(({ cleoPatientFormEnrollDischarge: { results } }) => {
              if (results) {
                const flatMapped = flatMap(
                  results,
                  (r) => r.isPreviouslyEnrolled === false
                );
                return flatMapped?.reduce((acc, curr) => acc && curr);
              }
              return false;
            })
          );
        });

        const enrolledAll = (await Promise.all(calls).then((r) => r))?.reduce(
          (acc, curr) => acc && curr
        );

        if (enrolledAll) {
          setEnrollmentResponse({
            hasPreviouslyEnrolled: false,
            successEnrollment: true,
          });
        } else {
          setEnrollmentResponse({
            hasPreviouslyEnrolled: true,
            successEnrollment: true,
          });
        }
      } else {
        await enrollPatientAndMonitoringForm({
          input: {
            patientUids,
            monitoringFormUid: selectedForm?.value?.id,
            operation: 'ENROLL',
            ...additionalInputs,
          },
        }).then(({ cleoPatientFormEnrollDischarge: { results } }) => {
          computeResults(results || []);
        });
      }
    } else {
      const input = {
        patientGroupUids,
        monitoringFormUid: selectedForm?.value?.id,
        operation: 'ENROLL',
        ...additionalInputs,
      };

      await dischargeOrEnrollPatients(input, 'ENROLL');
      onClose(true);
    }
  }, [
    enrollPatientAndMonitoringForm,
    onClose,
    dischargeOrEnrollPatients,
    patientUids,
    selectedForm?.value?.id,
    isGroup,
    patientGroupUids,
    selectedAlertee,
    selectedClinicianInCharge,
    selectedReminderOption,
    filtersApplied,
  ]);

  const dialogTitle = useMemo(() => {
    if (isGroup) return t('Bulk enrollment into program');

    return patients.length > 1 || filteredPatientsCount > 1
      ? t('Bulk enrollment into a program')
      : t('Enroll patient into a program');
  }, [isGroup, patients, t, filteredPatientsCount]);

  const isSubmitDisabled = useMemo(
    () =>
      isLoading ||
      !selectedForm ||
      !selectedReminderOption ||
      !selectedClinicianInCharge ||
      !selectedAlertee,
    [
      isLoading,
      selectedClinicianInCharge,
      selectedForm,
      selectedReminderOption,
      selectedAlertee,
    ]
  );

  const renderResultMessage = (
    avatar,
    title,
    description,
    contactSupport = false
  ) => (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Box as="img" src={avatar} height={120} width={120} mb={2} alt="Avatar" />
      <Text mt={2} color={theme.colors.fullShade} fontSize={2} fontWeight={600}>
        {t(title)}
      </Text>
      <Text
        mt={2}
        textAlign="center"
        color={theme.colors.darkestShade}
        fontSize={14}
        fontWeight={400}
      >
        {t(description)}
      </Text>
      {contactSupport && (
        <>
          <Text
            mt={1}
            textAlign="center"
            color={theme.colors.darkestShade}
            fontSize={14}
            fontWeight={400}
          >
            {t(
              'Please refresh this page. If the issue persists, please contact '
            )}
          </Text>
          <TextLink
            text={t('customer support')}
            mt={1}
            style={{
              textDecoration: 'underline',
              color: theme.colors.primary,
            }}
            fontSize={16}
            onClick={() => {
              customerSupportEmail();
            }}
          />
        </>
      )}
      <PrimaryButton
        mt={3}
        type="submit"
        disabled={isLoading}
        onClick={() => onClose(true)}
      >
        {t('Close')}
      </PrimaryButton>
    </Flex>
  );

  return (
    <Box
      p={1}
      width={['100%', '100%', 600]}
      style={{ boxSizing: 'border-box' }}
      data-testid="add-form-modal"
    >
      {enrollmentResponse.hasPreviouslyEnrolled &&
        enrollmentResponse.successEnrollment &&
        (patients?.length > 1 || isGroup || filtersApplied) &&
        renderResultMessage(
          AllGoodAvatar,
          'Partially enrolled, duplicate enrollment found and ignored.',
          'Patient(s) enrolled into the selected program.'
        )}

      {enrollmentResponse.hasPreviouslyEnrolled &&
        !enrollmentResponse.successEnrollment &&
        (patients?.length > 1 || isGroup || filtersApplied) &&
        renderResultMessage(
          CryingAvatar,
          'Duplicate Enrollment!',
          'Patients are enrolled with the selected program.'
        )}

      {enrollmentResponse.hasPreviouslyEnrolled &&
        !enrollmentResponse.successEnrollment &&
        patients?.length === 1 &&
        renderResultMessage(
          CryingAvatar,
          'Duplicate Enrollment!',
          'Patient is enrolled with the selected program.'
        )}

      {enrollmentResponse.successEnrollment &&
        !enrollmentResponse.hasPreviouslyEnrolled &&
        renderResultMessage(
          AllGoodAvatar,
          'Enrollment successful!',
          'Default form settings (e.g. reminders, clinical users) have been applied to the patient. You can change them anytime in Patient Profile > Clinical Settings.'
        )}

      {/* FORM ENROLLMENT SELECTION */}
      {!enrollmentResponse.hasPreviouslyEnrolled &&
        !enrollmentResponse.successEnrollment && (
          <>
            <DialogTitle
              label={dialogTitle}
              onClick={() => onClose(false)}
              textProps={{
                fontSize: '24px',
              }}
            />

            <Box mb={1} mt={6}>
              <Text color={theme.colors.fullShade} mr={1} fontWeight={600}>
                {!isGroup &&
                  (patients.length > 1 ? t('Patients') : t('Patient'))}

                {isGroup &&
                  (patientGroupUids.length > 1
                    ? t('Selected groups')
                    : t('Selected group'))}
              </Text>

              <Text mt={2} color="fullShade" fontWeight={400}>
                {!isGroup && <Text>{patientNames}</Text>}
                {isGroup && <Text>{groupNames}</Text>}
              </Text>
            </Box>

            <Box mt={6}>
              <Text
                mb={2}
                color={theme.colors.fullShade}
                fontSize={2}
                fontWeight={600}
              >
                {t('Program')}
              </Text>
              <Text
                mb={1}
                color={theme.colors.darkestShade}
                fontSize="12px"
                fontWeight={600}
              >
                {t('Select program')}
              </Text>
              <Select
                value={selectedForm}
                options={monitoringFormsOptions}
                onChange={(form) => setSelectedForm(form)}
                maxMenuHeight={180}
                menuPlacement="top"
              />
            </Box>

            <Text
              mt={2}
              style={{ cursor: 'pointer' }}
              fontSize={12}
              fontWeight={600}
            >
              {!showAddClinicalParam && (
                <Text
                  color={theme.colors.primary}
                  onClick={() => {
                    setShowAddClinicalParam(true);
                  }}
                >
                  {t('Add clinical settings')}
                </Text>
              )}
              {showAddClinicalParam && (
                <Text
                  color={theme.colors.primary}
                  onClick={() => {
                    setShowAddClinicalParam(false);
                  }}
                >
                  {t('Hide clinical settings')}
                </Text>
              )}
            </Text>
            {showAddClinicalParam && (
              <Box>
                {selectedForm && (
                  <Box mt={2}>
                    <StatusBar style={{ position: 'relative', zIndex: 1 }}>
                      <Body small color="primary">
                        {t('Add the required clinical settings')}
                      </Body>
                    </StatusBar>
                  </Box>
                )}
                <BoxPairRightPanel
                  headerLeft={<H4>{t('Clinical settings')}</H4>}
                  key="clinical-settings-box-rightpanel"
                >
                  {clinicRemindersSelectOptions && (
                    <Box mt={2}>
                      <Text
                        mb={1}
                        color={theme.colors.darkestShade}
                        fontSize="12px"
                        fontWeight={600}
                      >
                        {t('Select Reminder time')}
                      </Text>
                      <Select
                        value={selectedReminderOption}
                        options={[
                          { label: 'No Reminders', value: null },
                        ].concat(clinicRemindersSelectOptions)}
                        onChange={(form) => setSelectedReminderOption(form)}
                        maxMenuHeight={180}
                        menuPlacement="top"
                      />
                    </Box>
                  )}

                  <Box mt={2}>
                    <Text
                      mb={1}
                      color={theme.colors.darkestShade}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      {t('Clinical user(s) in-charge')}
                    </Text>
                    <Select
                      value={selectedClinicianInCharge}
                      options={cliniciansSelectOptions}
                      onChange={(form) => setSelectedClinicianInCharge(form)}
                      maxMenuHeight={180}
                      menuPlacement="top"
                    />
                  </Box>

                  <Box mt={2} pb={2}>
                    <Text
                      mb={1}
                      color={theme.colors.darkestShade}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      {t('Clinical user(s) to alert')}
                    </Text>
                    <Select
                      value={selectedAlertee}
                      options={[{ label: 'No Alerts', value: null }].concat(
                        cliniciansSelectOptions
                      )}
                      onChange={(form) => setSelectedAlertee(form)}
                      maxMenuHeight={180}
                      menuPlacement="top"
                    />
                  </Box>
                </BoxPairRightPanel>
                {/* End clinical settings */}
              </Box>
            )}

            <Flex mt={6} justifyContent="flex-end">
              <SecondaryOutlinedButton
                data-testid="add-form-modal-close"
                onClick={() => onClose(true)}
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                ml={3}
                type="submit"
                disabled={isSubmitDisabled}
                onClick={handleSubmit}
              >
                {!isGroup &&
                  (patients?.length > 1
                    ? t('Enroll Patients')
                    : t('Enroll Patient'))}

                {isGroup &&
                  (patientGroupUids?.length > 1
                    ? t('Enroll Groups')
                    : t('Enroll Group'))}
              </PrimaryButton>
            </Flex>
          </>
        )}
    </Box>
  );
};

export default AddFormModal;
