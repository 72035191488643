import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import id from './id';
import es from './es';

i18n.use(initReactI18next).init({
  ns: ['common', 'clinicalAdmins', 'dashboard', 'patient', 'submissions'],
  defaultNS: 'common',
  fallbackNS: [
    'common',
    'clinicalAdmins',
    'dashboard',
    'patient',
    'submissions',
  ],
  resources: {
    en,
    id,
    es,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
