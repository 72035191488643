/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  FAIcon,
  Flex,
  H4,
  Text,
  Tooltip,
  theme,
  useAuth,
} from '@fivehealth/botero';

import { useModal } from 'context/ModalContext';
import formatPhoneNumber from 'lib/formatPhoneNumber';
import useCaregiverUsers from 'hooks/useCaregiverUsers';
import { checkIsMyPatient, checkPermissions } from 'AppUtils';
import {
  BoxPairLayout,
  BoxPairLeftPanel,
  BoxPairLabelValues,
  BoxDivider,
} from 'components/Layout';
import TextButton from 'components/Text/TextButton';

import moment from 'moment';
import {
  getBoxPairLayoutData,
  onOpenEditUserModal,
  renderPanelHeaderRight,
  getMetadataEntryDisplay,
} from '../shared';

import AddMetadataEntryModal from './AddMetadataEntry.modal';

const CustomBoxPairRightPanel = ({
  headerLeft,
  headerRight,
  isMobile,
  children,
}) => (
  <Box
    mt={[2, 3]}
    border={`1px solid ${theme.colors.mediumShade}`}
    borderRadius={8}
    p={0}
  >
    <Box px={2} pt={2}>
      <Flex mb={[2, 2]} flexDirection={['column', 'row']}>
        <Flex flex={2} fontSize={16} fontWeight={600}>
          {headerLeft?.type ? (
            headerLeft
          ) : (
            <H4 fontSize={16} fontWeight={600}>
              {headerLeft}
            </H4>
          )}
        </Flex>
        <Flex justifyContent="end">{!isMobile && headerRight}</Flex>
      </Flex>
      {children}
    </Box>

    {isMobile && (
      <>
        <Box
          maxHeight={10}
          mt={0}
          borderBottom={`1px solid ${theme.colors.mediumShade}`}
        />

        {headerRight}
      </>
    )}
  </Box>
);

const InfoSection = ({
  patientForm,
  patientForms,
  refetchPatientForm,
  clinic,
  clinician,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const {
    authState: { token },
  } = useAuth();

  const { caregiverRoleUid } = useCaregiverUsers({
    variables: {},
  });

  const patient = useMemo(() => get(patientForm, 'patient', {}), [patientForm]);
  const metadataEntries = useMemo(
    () => get(patient, 'metadataEntries', []),
    [patient]
  );

  const parseProperHeaderCase = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  const onEditPatient = (form, section) => {
    onOpenEditUserModal(
      openModal,
      closeModal,
      form,
      clinic,
      refetchPatientForm,
      section,
      isMobile
    );
  };

  const hasEditPermission = (form) => {
    const hasMutatePatientFormPermission = checkPermissions(clinician, [
      'mutate_all_patientforms',
    ]);

    if (hasMutatePatientFormPermission) {
      return true;
    }

    return (
      checkPermissions(clinician, [
        'mutate_ic_patientforms',
        'mutate_alertee_patientforms',
      ]) && checkIsMyPatient(clinician, form)
    );
  };

  const patientDetailsIinfoDisp = [
    {
      label: t('Patient Name'),
      value: patient?.name,
    },
    {
      label: t('Patient ID'),
      value: patient?.identifier,
    },
    {
      label: t('Phone'),
      value: formatPhoneNumber(patient?.phone) || '-',
    },
    {
      label: t('Email'),
      value: patient?.email || '-',
    },
    {
      label: t('Gender'),
      value: patient?.gender || '-',
    },
    {
      label: t('Age'),
      value: patient?.age || '-',
    },
    {
      label: t('DOB'),
      value: patient?.dateOfBirth
        ? moment(patient?.dateOfBirth).format('YYYY-MM-DD')
        : '-',
    },
    {
      label: t('Messaging channel'),
      value: patient?.communicationMethod,
    },
    ...map(metadataEntries, (entry) => ({
      label: t(entry?.parameter?.name),
      value: getMetadataEntryDisplay(entry, token),
    })),
  ];

  const editPatientDetailsTxt = t('Edit Patient Details').toLowerCase();

  const renderCustomEditBtn = (data, section) =>
    hasEditPermission(data) &&
    renderPanelHeaderRight(
      t,
      'edit',
      isMobile,
      () => {
        onEditPatient(data, section);
      },
      section
    );

  const handleAddParam = () =>
    openModal(
      <AddMetadataEntryModal
        t={t}
        onClose={closeModal}
        onSubmit={async () => {
          await refetchPatientForm();
          closeModal();
        }}
        patientForms={patientForms}
      />,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }
    );

  return (
    <Box mt={[-1, 1]}>
      <Text fontSize={2} fontWeight={400} color={theme.colors.darkestShade}>
        {t("If you don't see the parameter you're looking for, you can ")}
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            borderWidth: '0px',
            padding: '0px',
          }}
          onClick={handleAddParam}
        >
          <TextButton
            fontSize={2}
            fontWeight={400}
            color={theme.colors.primary}
            style={{
              textDecoration: 'underline',
            }}
          >
            {t('add more default parameters here')}
          </TextButton>
        </button>
        {t(' to your patient in Patient or Form information.')}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            borderWidth: '0px',
            padding: '0px',
          }}
        >
          <Tooltip
            tooltip={
              <Text
                fontSize="14px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="left"
                letterSpacing="-0.08px"
                color="white"
              >
                {t(
                  'Default parameters refer to the information we collect during patient enrollment. These information may not be applicable at the point of enrollment and hence not reflected for the patient.'
                )}
              </Text>
            }
            toolTipElementProps={{
              maxWidth: 300,
              px: 2,
              py: 1,
            }}
            toolTipOptions={{
              placement: 'auto',
            }}
          >
            <FAIcon
              icon={faQuestionCircle}
              hover={{ opacity: 0.6 }}
              style={{
                fontSize: 12,
                fontWeight: 900,
                marginLeft: 4,
                color: theme.colors.darkestShade,
              }}
            />
          </Tooltip>
        </button>
      </Text>
      <BoxPairLayout>
        <BoxPairLeftPanel
          headerTitle={t('Patient Information')}
          headerDesc={parseProperHeaderCase(editPatientDetailsTxt)}
          isMobile={isMobile}
        />
        <CustomBoxPairRightPanel
          headerLeft={t('Patient Details')}
          headerRight={renderCustomEditBtn(patientForm, 'patientDetails')}
          isMobile={isMobile}
        >
          <BoxPairLabelValues data={patientDetailsIinfoDisp} />
        </CustomBoxPairRightPanel>
      </BoxPairLayout>

      {patientForms?.length > 0 && (
        <>
          <BoxDivider />

          <BoxPairLayout>
            <BoxPairLeftPanel
              headerTitle={t('Forms')}
              headerDesc={t(
                'Edit forms, clinical users, reminders, caregivers'
              )}
              isHideHeaderDesc={isMobile}
            />
            <>
              {getBoxPairLayoutData(
                t,
                caregiverRoleUid,
                patientForms,
                (form) => get(form, 'monitoringForm.effectiveName'),
                (form) => renderCustomEditBtn(form, 'clinicalUsers'),
                token
              ).map((o, index) => (
                <CustomBoxPairRightPanel
                  headerLeft={o.headerLeft}
                  headerRight={o.headerRight}
                  key={index}
                  isMobile={isMobile}
                >
                  <BoxPairLabelValues data={o.groupPair} />
                </CustomBoxPairRightPanel>
              ))}
            </>
          </BoxPairLayout>
        </>
      )}
    </Box>
  );
};

export default InfoSection;
