/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { get, isEmpty, isEqual, flatMap } from 'lodash';

import {
  Flex,
  Box,
  Text,
  LogEvent,
  TableContainer,
  TableHead,
  TableBody,
  theme,
  SearchInput,
} from '@fivehealth/botero';

// import Config from 'Config';
import { useAppData } from 'context/AppDataContext';
// import { getPatientSubmissions } from 'AppUtils';

import Sort from 'components/Sort/Sort';
import SortArrow from 'components/Table/SortArrow';
import {
  Filters,
  FilterOption,
  FiltersProps,
  FiltersHandles,
} from 'components/Filters/Filters';
import {
  defaultHeaderProps,
  HeaderColumn,
  LoadMoreButton,
  Styles,
  TableCell,
  TableHeadCellCustom,
  TableRowWithoutSticky,
  TableRowWithSticky,
} from 'components/Table/Table';
import { DataType } from 'constants/dataType';
import { EventSubSource } from 'constants/eventSubSource';

import PatientShowSubmissions from 'views/PatientsList/PatientShowSubmissions';

import { RetryView } from 'views/RetryView/RetryView';
import { RetryStatusCodes } from 'constants/retryStatusCodes';
import SubmissionTable from './SubmissionTable';
import { SubmissionBoxWidget } from './Sidepanel';

interface RenderTableProps {
  hasStickyColumn?: boolean;
  onRowClick?: (row: object) => void;
  containerStyle?: object;
  headerCellProps?: object;
  logEventProps?: object;
  headerGroups: Array<{
    getHeaderGroupProps: () => object;
    headers: Array<{
      sticky: string;
      width: string;
      getHeaderProps: (param: object[]) => object;
      render: (key: string) => string;
      isSorted: boolean;
      isSortedDesc: boolean;
      canSort: boolean;
    }>;
  }>;
  getCellProps: (cell: object) => object;
  getColumnProps: (column: object) => object;
  getRowProps: (row: object) => object;
  getHeaderGroupProps: () => object;
  getHeaderProps: (col: object) => object;
  getTableBodyProps: () => object;
  tableStyle: object;
  prepareRow: (row: object) => void;
  rows: Array<{
    getRowProps: (row: object) => object;
    cells: Array<{
      getCellProps: (col: object) => object;
      column: {
        sticky: string;
        width: string;
      };
    }>;
  }>;
  getTableProps: () => object;
}

interface AllSubmissionsSectionProps {
  isPatientProfile?: boolean;
  dataType?: string;
  hideEmptySubmissions?: boolean;
  onShowSubmission?: unknown;
  onShowPatientForm?: unknown;
  openDrawer?: unknown;
  onSendMessage?: unknown;
  onDownloadPatientSubmissions?: unknown;
  // onSetExportMenu: any;
  // patient: any;
  tableSettings?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };
  tableSettingsSinglePatient?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };
  clinic?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };
  onCommentCreated?: unknown;
  isPatientMonitoring?: boolean;
  clinician?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };

  // tab with filter
  t?: (key: string) => string;
  lastUpdated?: {
    current: string;
  };
  patientForms?: Array<{
    [key: string]: string | number | boolean | object | null | undefined;
  }>;
  submissions?: Array<{
    [key: string]: string | number | boolean | object | null | undefined;
  }>;
  requestParams?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
  isRefetching?: boolean;
  isFetched?: boolean;
  isFetchingNextPage?: boolean;
  // showFilters?: boolean;
  dateRangeFilters?: {
    [key: string]: any;
  };
  clinicalUserFilters?: {
    [key: string]: any;
  };
  statusesFilters?: {
    [key: string]: any;
  };
  // statusesFilters: any;
  monitoringFormsetsFilters?: {
    [key: string]: any;
  };
  onSearch?: unknown;
  onRefresh?: () => void;
  onFetchData?: unknown;
  // onFilterOpen?: unknown;
  // onFilterCancel?: unknown;
  onApplyFilters?: (filters: FiltersProps['requestParams']) => void;
  onResetFilters?: () => void;
  totalFetchDataCount?: number;
  searchText?: string;
  setSearchText?: (value: string) => void;
  pageCount?: number;
  statusCode?: number;
}

const AllSubmissionsSection = memo<AllSubmissionsSectionProps>(
  ({
    // props
    dataType,
    // defaultGQLVariable,
    hideEmptySubmissions,
    onShowSubmission,
    onShowPatientForm,
    openDrawer,
    onSendMessage,
    onDownloadPatientSubmissions,
    // onSetExportMenu,
    // patient,
    tableSettings,
    tableSettingsSinglePatient,
    clinic,
    onCommentCreated,
    isPatientMonitoring = false,
    clinician,

    // tab with filter
    t,
    lastUpdated,
    patientForms,
    submissions,
    requestParams,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
    isFetched,
    isFetchingNextPage,
    dateRangeFilters,
    clinicalUserFilters,
    statusesFilters,
    monitoringFormsetsFilters,
    onSearch,
    onRefresh,
    onFetchData,
    onApplyFilters,
    // onResetFilters,
    totalFetchDataCount,
    searchText,
    setSearchText,
    pageCount,
    statusCode,
    isPatientProfile = false,
  }) => {
    const { user } = useAppData();
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const filtersRef = useRef<FiltersHandles>();

    const [submissionsKeyToShow, setSubmissionsKeyToShow] = useState<string>();

    const isSubmissions = isEqual(dataType, DataType.Submission);
    // @ts-ignore
    const isSinglePatientSubmissions = user?.isPatientFacing || isSubmissions;

    const tableData = flatMap(
      isSubmissions ? submissions : patientForms,
      (page) => page || []
    )
      .filter(Boolean)
      .filter((data) => {
        if (submissions) {
          return true;
        }
        // @ts-ignore
        return hideEmptySubmissions ? data.hasSubmission : data;
      });

    const filterOptions = [
      // lastReportFilter,
      clinicalUserFilters,
      statusesFilters,
      dateRangeFilters,
      monitoringFormsetsFilters,
    ].filter(Boolean) as FilterOption[];

    const onShowSubmissionMobile = (uid: string) => {
      if (uid !== submissionsKeyToShow) {
        setSubmissionsKeyToShow(uid);
      } else {
        setSubmissionsKeyToShow('');
      }
    };

    /* @ts-ignore */
    const getLeftSpacing = (index, accessor, widthAccessorKey) => {
      const widths = new Array(index)
        .fill(0)
        .map((_, i) => get(accessor[i], widthAccessorKey))
        .join(' + ');
      return `calc(${widths})`;
    };

    const renderTable = ({
      rows,
      prepareRow,
      tableStyle,
      getTableProps,
      getTableBodyProps,
      getHeaderProps,
      getHeaderGroupProps,
      getRowProps,
      getColumnProps,
      getCellProps,
      headerGroups,
      logEventProps,
      headerCellProps,
      containerStyle = {},
      onRowClick,
      hasStickyColumn = false,
    }: RenderTableProps) => {
      const TableRow = hasStickyColumn
        ? TableRowWithSticky
        : TableRowWithoutSticky;
      return (
        <Box style={containerStyle}>
          <LogEvent
            logEventProps={logEventProps}
            actionProps={{
              onLoad: { action: 'load' },
              onClick: { action: 'click' },
            }}
            elementType="table"
          >
            <Styles isMobile={isMobile}>
              <TableContainer
                width="100%"
                minWidth={[null, theme.breakpoints[2]]}
                textAlign="left"
                {...getTableProps()}
                style={tableStyle}
              >
                <TableHead>
                  {/* @ts-ignore */}
                  {headerGroups.map((headerGroup, index) => (
                    <TableRow
                      isMobile={isMobile}
                      hasStickyColumn={hasStickyColumn}
                      {...headerGroup.getHeaderGroupProps()}
                      {...getHeaderGroupProps?.()}
                      key={`table_row_${index}`}
                    >
                      {/* @ts-ignore */}
                      {headerGroup.headers.map((column, cIndex) => (
                        <TableHeadCellCustom
                          isMobile={isMobile}
                          sticky={column.sticky}
                          left={
                            column.sticky
                              ? [
                                  `${
                                    cIndex === 0
                                      ? 0
                                      : getLeftSpacing(
                                          cIndex,
                                          headerGroup.headers,
                                          'width'
                                        )
                                  } !important`,
                                ]
                              : null
                          }
                          width={[`${column.width} !important`]}
                          py={2}
                          {...column.getHeaderProps([
                            defaultHeaderProps(
                              // @ts-ignore
                              column,
                              getHeaderProps(column)
                            ),
                            getColumnProps(column),
                          ])}
                          key={`table_cell_${cIndex}`}
                        >
                          <HeaderColumn {...headerCellProps}>
                            <Box>
                              <Text
                                color="darkestShade"
                                fontWeight={600}
                                fontSize={14}
                                cursor={column.canSort ? 'pointer' : 'default'}
                              >
                                {column.render('Header')}
                              </Text>
                            </Box>
                            {!isEmpty(rows) && column.canSort && (
                              <Box pl="5px">
                                <SortArrow
                                  down={false}
                                  active={
                                    column.isSorted && !column.isSortedDesc
                                  }
                                />
                                <SortArrow
                                  down
                                  active={
                                    column.isSorted && column.isSortedDesc
                                  }
                                />
                              </Box>
                            )}
                          </HeaderColumn>
                        </TableHeadCellCustom>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {/* @ts-ignore */}
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={`table_${index}`}>
                        <TableRow
                          isMobile={isMobile}
                          cursor={onRowClick ? 'pointer' : ''}
                          {...row.getRowProps(getRowProps(row))}
                          style={{
                            // @ts-ignore
                            ...row.getRowProps(getRowProps(row)).style,
                          }}
                          // @ts-ignore
                          isSelected={row.isSelected}
                          onClick={() => {
                            // @ts-ignore
                            row.toggleRowSelected();
                          }}
                          key={`table_row_${index}`}
                          data-testid={`table_row_data_${index}`}
                          height={hasStickyColumn ? 'auto' : '1px'}
                        >
                          {/* @ts-ignore */}
                          {row.cells.map((cell, cIndex) => (
                            <TableCell
                              index={cIndex}
                              sticky={cell.column.sticky}
                              left={
                                cell.column.sticky
                                  ? [
                                      `${
                                        cIndex === 0
                                          ? 0
                                          : getLeftSpacing(
                                              cIndex,
                                              row.cells,
                                              'column.width'
                                            )
                                      } !important`,
                                    ]
                                  : null
                              }
                              width={[`${cell.column.width} !important`]}
                              isMobile={isMobile}
                              data-testid={`table_cell_data_${cIndex}`}
                              py={2}
                              height="inherit"
                              key={`table_cell_${cIndex}`}
                              {...cell.getCellProps([
                                getColumnProps(cell.column),
                                getCellProps(cell),
                              ])}
                            >
                              {/* @ts-ignore */}
                              {cell.render('Cell')}
                            </TableCell>
                          ))}
                        </TableRow>
                        {/* @ts-ignore */}
                        {isMobile && submissionsKeyToShow === row?.id && (
                          <TableRow>
                            <Box
                              bg="white"
                              width={isMobile ? '39vh' : 'fit-content'}
                              className="patient-submissions"
                            >
                              <PatientShowSubmissions
                                /* @ts-ignore */
                                submissionId={row?.id}
                                /* @ts-ignore */
                                open={submissionsKeyToShow === row?.id}
                                onClose={() => setSubmissionsKeyToShow('')}
                                clinician={clinician}
                              />
                            </Box>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </TableContainer>
            </Styles>
          </LogEvent>
        </Box>
      );
    };

    const onFilterChange = useCallback(
      (filters: FiltersProps['requestParams']) => {
        onApplyFilters?.(filters);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    if (statusCode && RetryStatusCodes.includes(statusCode)) {
      return (
        <RetryView
          onRefresh={onRefresh}
          isLoading={isLoading || isRefetching}
        />
      );
    }

    return (
      <>
        <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
          {isSinglePatientSubmissions ? (
            <Box />
          ) : (
            <SearchInput
              inputStyle={{
                paddingBottom: '6px',
                paddingTop: '8px',
              }}
              hideRefresh={isMobile}
              data-testid="patient_monitoring_all_submittions_search_input"
              placeholder={t?.('Search patients')}
              inputMinWidth={isMobile ? '100%' : '400px'}
              onSearch={onSearch}
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText?.(e.target.value);
              }}
              onRefresh={onRefresh}
              isRefreshing={isLoading || isRefetching}
              lastUpdated={lastUpdated?.current}
              totalCount={totalFetchDataCount}
              pageCount={submissions?.length || pageCount}
              refreshButtonProps={{
                style: {
                  paddingTop: '12px',
                  paddingBottom: '12px',
                },
              }}
            />
          )}

          {!isSinglePatientSubmissions && (
            <Box display={['block', 'block', 'none']}>
              <Sort
                // @ts-ignore
                onChange={(opt) => onFetchData({ sortBy: [opt] })}
                value={{
                  orderField: get(requestParams, 'orderField'),
                  orderDesc: get(requestParams, 'orderDesc'),
                }}
                options={[
                  { title: t?.('Patient name A-Z'), id: 'NAME', desc: false },
                  { title: t?.('Patient name Z-A'), id: 'NAME', desc: true },
                  {
                    title: 'Submitted on (newest to oldest)',
                    id: 'LAST_SUBMITTED_DATE',
                    desc: true,
                  },
                  {
                    title: 'Submitted on (oldest to newest)',
                    id: 'LAST_SUBMITTED_DATE',
                    desc: false,
                  },
                ]}
                selectedOption={undefined}
                title={undefined}
                onClose={undefined}
                onChecked={undefined}
              />
            </Box>
          )}
        </Flex>

        {clinician && !clinician.isCaregiver && !isPatientProfile && (
          <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
            <Filters
              ref={(ref) => {
                if (ref) {
                  filtersRef.current = ref;
                }
              }}
              requestParams={{
                orderField: get(requestParams, 'orderField'),
                orderDesc: get(requestParams, 'orderDesc'),
                activated: true,
              }}
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
          </Box>
        )}

        {isPatientMonitoring && (
          <Box display={['block', 'block', 'none']}>
            {tableData.map((sub) => (
              <SubmissionBoxWidget
                // @ts-ignore
                tableSettings={tableSettings}
                tableSettingsSinglePatient={tableSettingsSinglePatient}
                data={tableData}
                key={
                  // @ts-ignore
                  sub.lastSubmission && !isEmpty(sub.lastSubmission)
                    ? // @ts-ignore
                      sub.lastSubmission.uid
                    : // @ts-ignore
                      sub.uid
                }
                submission={
                  // @ts-ignore
                  !isEmpty(sub.lastSubmission) ? sub.lastSubmission : sub
                }
                // @ts-ignore
                patient={sub.patient}
                cellData={sub}
                variant={
                  // @ts-ignore
                  sub.lastSubmission
                    ? // @ts-ignore
                      sub.lastSubmission.statusColor
                    : // @ts-ignore
                      sub.statusColor
                }
                showSidePanel
                isMobile
                showActionMenu={!isSinglePatientSubmissions}
                onCommentCreated={onCommentCreated}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                onSendMessage={onSendMessage}
                clinician={clinician}
                // @ts-ignore
                showDischargedLabel={!!sub?.patientForm?.isDischarged}
                onDownloadSubmissions={onDownloadPatientSubmissions}
              />
            ))}
            {isEmpty(tableData) && isFetched && (
              <Text fontSize={14} color="darkestShade" textAlign="center">
                {t?.('No data available')}.
              </Text>
            )}
            {hasNextPage && (
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="center"
                mt={3}
                mb={3}
              >
                <LoadMoreButton
                  onClick={fetchNextPage}
                  disabled={isFetchingNextPage}
                  flex={1}
                  style={{ height: 50 }}
                >
                  <Text color="darkestShade" fontSize={16} fontWeight={600}>
                    {!isFetchingNextPage && t?.('Load more')}
                    {isFetchingNextPage && `${t?.('Loading')}...`}
                  </Text>
                </LoadMoreButton>
              </Flex>
            )}
          </Box>
        )}

        {(!isPatientMonitoring || !isMobile) && (
          <Box
            display="block"
            overflow={submissionsKeyToShow ? 'hidden' : 'scroll'}
            className="scrollbar-invisible"
          >
            <SubmissionTable
              clinician={clinician}
              clinic={clinic}
              tableSettings={tableSettings}
              tableSettingsSinglePatient={tableSettingsSinglePatient}
              data={tableData}
              showLoading={isLoading}
              // @ts-ignore
              submissionAccessor={
                isSinglePatientSubmissions ? null : 'lastSubmission'
              }
              isSinglePatientView={isSinglePatientSubmissions}
              onShowSubmission={
                isMobile ? onShowSubmissionMobile : onShowSubmission
              }
              onSendMessage={onSendMessage}
              onShowPatientForm={onShowPatientForm}
              onDownloadSubmissions={onDownloadPatientSubmissions}
              onOpenDrawer={openDrawer}
              onFetchData={onFetchData}
              onFetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetched={isFetched}
              isFetchingNextPage={isFetchingNextPage}
              initialSortBy={{
                orderField: requestParams?.orderField,
                orderDesc: requestParams?.orderDesc,
              }}
              sortMapping={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                PATIENT_NAME: 'NAME',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                SUBMITTED_ON: isSubmissions
                  ? 'SUBMITTED_ON'
                  : 'LAST_SUBMITTED_DATE',
              }}
              renderTable={renderTable}
              showDischargedLabel
              eventSubSource={EventSubSource.All}
            />
          </Box>
        )}
      </>
    );
  }
);

export default React.memo(AllSubmissionsSection);
