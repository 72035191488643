import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissionCount(
    $isAlertsResolved: Boolean
    $isAlertsTriggered: Boolean
    $hasUnseenCommentsAndVisibleToEverybody: Boolean
    $patientFormUids: [String!]
  ) {
    cleoSubmissionCount(
      isAlertsResolved: $isAlertsResolved
      isAlertsTriggered: $isAlertsTriggered
      hasUnseenCommentsAndVisibleToEverybody: $hasUnseenCommentsAndVisibleToEverybody
      patientFormUids: $patientFormUids
    ) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'cleoSubmissionCount',
  select: (data) => get(data, 'cleoSubmissionCount.totalCount', 0),
  keepPreviousData: true,
  cacheTime: 1000 * 60 * 60,
});
