import React from 'react';
import { Box, Flex, Text, FAIcon } from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const DialogTitle = ({ label, onClick, textProps, ...props }) => (
  <Flex justifyContent="space-between" mb={6} {...props}>
    <Text fontWeight={600} fontSize={3} {...textProps}>
      {label}
    </Text>
    <Box cursor="pointer" onClick={onClick}>
      <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
    </Box>
  </Flex>
);

export default DialogTitle;
