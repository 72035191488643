import { useEventEmitter } from 'ahooks';
import Config from 'Config';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';

const initialState = {
  user: null,
  clinic: null,
  clinics: null,
  clinician: null,
  sidebarOpened: false,
  /* eslint-disable no-unused-vars */
  setUser: (value) => {},
  resetUser: () => {},
  setClinic: (value) => {},
  setClinics: (value) => {},
  setClinician: (value) => {},
  toggleSidebar: () => {},
};
const AppContext = createContext(initialState);

export const AppDataProvider = ({ children }) => {
  const event = useEventEmitter();

  const [sidebarOpened, setSidebarOpened] = useState(false);

  const toggleSidebar = useCallback(() => {
    setSidebarOpened((prev) => !prev);
  }, []);

  const [cookies, setCookie] = useCookies([
    Config.patientCookie.name,
    Config.clinicCookie.name,
    Config.clinicianCookie.name,
  ]);

  const [_, setCookieClinic] = useCookies([Config.clinicCookie.name]);

  const [appState, setAppState] = useState({
    ...initialState,
    user: cookies[Config.patientCookie.name],
    clinic: cookies[Config.clinicCookie.name] || {},
    clinician: cookies[Config.clinicianCookie.name] || {},
  });

  const setUser = useCallback(
    (value) => {
      setAppState({ ...appState, user: { ...appState.user, ...value } });
      setCookie(
        Config.patientCookie.name,
        {
          ...value,
        },
        { path: '/' }
      );
    },
    [appState, setCookie]
  );

  const resetUser = useCallback(() => {
    setAppState({ ...appState, user: null });
  }, [appState]);

  const setClinic = useCallback(
    (newClinic) => {
      setCookieClinic(
        Config.clinicCookie.name,
        {
          uid: newClinic.uid,
          domain: newClinic.domain,
          enrollmentForm: newClinic.enrollmentForm,
          logo: newClinic.logo,
          name: newClinic.name,
        },
        { path: '/' }
      );
      setAppState({ ...appState, clinic: newClinic });
    },
    [appState, setCookieClinic]
  );

  const setClinics = useCallback(
    (newClinics) => {
      setAppState({ ...appState, clinics: newClinics });
    },
    [appState]
  );

  const setClinician = useCallback(
    (newClinician) => {
      setCookie(Config.clinicianCookie.name, newClinician, { path: '/' });
      setAppState({ ...appState, clinician: newClinician });
    },
    [appState, setCookie]
  );

  const resetAppState = useCallback(() => {
    setAppState(initialState);
    setCookieClinic([Config.clinicCookie.name]);
  }, [setAppState, setCookieClinic]);

  const getContextValue = useCallback(
    () => ({
      ...appState,
      resetAppState,
      setUser,
      resetUser,
      setClinic,
      setClinics,
      setClinician,
      event,
      toggleSidebar,
      sidebarOpened,
    }),
    [
      appState,
      resetAppState,
      setUser,
      resetUser,
      setClinic,
      setClinics,
      setClinician,
      event,
      toggleSidebar,
      sidebarOpened,
    ]
  );

  return (
    <AppContext.Provider value={getContextValue()}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = () => useContext(AppContext);
