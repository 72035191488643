import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get, orderBy } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicsWithAccess {
    cleoClinics {
      edges {
        node {
          uid
          name
          domain
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'cleoClinicsWithAccess',
  keepPreviousData: true,
  select: (response) =>
    response === null
      ? null
      : orderBy(
          get(response?.cleoClinics, 'edges', []).map(({ node }) => node),
          ['name'],
          ['asc']
        ),
});
