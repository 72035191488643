import { Box, Tooltip, Text } from '@fivehealth/botero';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SendMessageIcon from '../../assets/paper-plane.png';

const SendMessageButton = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  return (
    <Tooltip
      show={show}
      tooltip={<Text color="white">{t('Send Message')}</Text>}
    >
      <Box
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        mr={2}
        cursor="pointer"
        onClick={onClick}
        as="img"
        src={SendMessageIcon}
        height={30}
        width={30}
        alt="send message"
      />
    </Tooltip>
  );
};

export default SendMessageButton;
