import { useApi } from '@fivehealth/botero';
import { chain } from 'lodash';

const useAlertRemindersData = (isForClinicParam = true) => {
  const {
    queries: { useAlertReminders },
  } = useApi({
    queries: ['useAlertReminders'],
  });

  const {
    data: reminderData,
    isLoading: isLoadingReminderOptions,
    fetchNextPage: alertReminderFetchNextPage,
    refretch: reloadReminderData,
  } = useAlertReminders({
    variables: { first: 25, isForClinic: isForClinicParam },
  });

  const clinicRemindersSelectOptions = chain(reminderData)
    .get('pages', [])
    .flatMap((page) => page)
    .filter(({ isForClinic }) => isForClinic)
    .map((clinicReminder) => ({
      ...clinicReminder,
      label: clinicReminder.name,
      value: clinicReminder.uid,
    }))
    .value();

  return {
    reminderData,
    isLoadingReminderOptions,
    alertReminderFetchNextPage,
    clinicRemindersSelectOptions,
    reloadReminderData,
  };
};

export default useAlertRemindersData;
