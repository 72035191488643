import { useApi } from '@fivehealth/botero';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { Patient } from 'views/Appointments/AppointmentDetails';

export interface UsePatientsQueryResult {
  patients: Patient[];
  patientsDropdownOptions: Array<{ label: string; value: Patient }>;
  loading: boolean;
  reload: () => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetched: boolean;
  isRefetching: boolean;
  isFetchingNextPage: boolean;
  searchPatients: (input: string) => void;
}

export const usePatientsQuery = (
  isForDropdown = false
): UsePatientsQueryResult => {
  const [searchInput, setSearchInput] = useState('');
  const {
    queries: { usePatients },
  } = useApi({
    queries: ['usePatients'],
  });

  const {
    data,
    isLoading,
    refetch: reload,
    hasNextPage,
    fetchNextPage,
    isFetched,
    isRefetching,
    isFetchingNextPage,
  } = usePatients({
    variables: {
      activated: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      deactivatedOn_Isnull: true,
      first: 10,
      search: searchInput,
      isForDropdown,
    },
  });

  const searchPatients = (input: string) => {
    setSearchInput(input);
    reload();
  };

  const patients = useMemo(
    () =>
      get(data, 'pages', [])
        .flatMap((page: object) => page || [])
        .map(
          ({
            phone,
            email,
            gender,
            dateOfBirth,
            identifier,
            communicationMethod,
            uid,
            name,
            id,
            createdOn,
          }: Patient) => ({
            phone,
            email,
            gender,
            dateOfBirth,
            identifier,
            communicationMethod,
            uid,
            name,
            id,
            createdOn,
          })
        ),
    [data]
  );

  const patientsDropdownOptions = useMemo(() => {
    return patients.map((patient: Patient) => ({
      label: `${patient.name} (${patient.identifier})`,
      value: patient,
    }));
  }, [patients]);

  return {
    patients,
    patientsDropdownOptions,
    loading: isLoading,
    reload,
    hasNextPage,
    fetchNextPage,
    isFetched,
    isRefetching,
    isFetchingNextPage,
    searchPatients,
  };
};
