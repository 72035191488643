import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientFormsAsPatient {
    cleoPatientForms {
      edges {
        node {
          uid
          patient {
            uid
            name
          }
          monitoringForm {
            clinicalParameters {
              uid
              name
              valueType
              groupName
              unit
            }
          }
          effectiveAlertRules {
            uid
            name
            isForClinic
            parserSettings
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientFormsAsPatient',
  keepPreviousData: true,
  select: ({ cleoPatientForms }) => {
    if (cleoPatientForms) {
      return cleoPatientForms.edges.map(({ node }) => node);
    }
    return null;
  },
});
