import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicalParameters(
    $isUsedForPatientMetadataEntry: Boolean
    $isUsedForPatientFormMetadataEntry: Boolean
  ) {
    cleoClinicalParameters(
      isUsedForPatientMetadataEntry: $isUsedForPatientMetadataEntry
      isUsedForPatientFormMetadataEntry: $isUsedForPatientFormMetadataEntry
    ) {
      edges {
        node {
          uid
          groupName
          nameWithGroup
          name
          valueType
          sortOrder
          usedForPatientMetadataEntry
          usedForPatientFormMetadataEntry
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinicalParameters',
  select: (data) => get(data, 'cleoClinicalParameters.edges'),
  keepPreviousData: true,
});
