import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientGroupDelete($input: DeleteCleoPatientGroupInput!) {
    cleoPatientGroupDelete(input: $input) {
      deleteMutationResult {
        uid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientGroupDelete',
  keepPreviousData: false,
});
