import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  get,
  isEqual,
  filter,
  find,
  map,
  chain,
  values,
  split,
  isUndefined,
  isEmpty,
} from 'lodash';

import {
  Box,
  H2,
  H4,
  H6,
  Flex,
  Form,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  useApi,
  Text,
  CreatableSelect,
  theme,
  H5,
  useAuth,
} from '@fivehealth/botero';

import useCaregiverUsers from 'hooks/useCaregiverUsers';
import usePatientFormsQuery from 'hooks/usePatientFormsQuery';
import {
  getMetadataFiledType,
  getFirstAndLastName,
  hasTimeInString,
} from 'AppUtils';

import TableLoader from 'components/Table/TableLoader';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import AddCaregiverUserModal from 'views/CaregiverList/AddCaregiverUserModal';
import moment from 'moment';
import useCliniciansData from 'hooks/useCliniciansData';

const Label = (props) => <H6 color="darkestShade" {...props} />;

const EditPatientModal = ({
  patientForm,
  closeModal,
  refetchPatientForm,
  clinicData,
  section = undefined,
  isMobile,
}) => {
  const {
    queries: {
      usePatientUpdate,
      useCleoClinicianPatientFormRelationsUpdate,
      useCurrentUser,
      usePatientMetadataEntryUpdate,
      usePatientFormMetadataEntryUpdate,
      useCreateStitchUpload,
    },
  } = useApi({
    queries: [
      'usePatientUpdate',
      'useCleoClinicianPatientFormRelationsUpdate',
      'useCurrentUser',
      'usePatientMetadataEntryUpdate',
      'usePatientFormMetadataEntryUpdate',
      'useCreateStitchUpload',
    ],
  });

  const methods = useForm();
  const patientRef = useRef();
  const patientFormRef = useRef();
  const { t } = useTranslation();
  const { authState } = useAuth();
  const queryClient = useQueryClient();

  const [isValidPatientRef, setIsValidPatientRef] = useState(false);
  const [isValidPatientFormRef, setIsValidPatientFormRef] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showServerError, setShowServerError] = useState();
  const [openCaregiversSelected, setOpenCaregiversSelected] = useState(false);
  const [showAddCaregiverSection, setShowAddCaregiverSection] = useState(false);

  const { patient } = patientForm;

  // To hide email as communication method
  const communicationMethods = useMemo(
    () =>
      filter(
        clinicData?.communicationMethods,
        ({ value }) => !isEqual(value, 'email')
      ),
    [clinicData?.communicationMethods]
  );

  const { cliniciansLoading, cliniciansSelectOptions: clinicians } =
    useCliniciansData();

  const { mutateAsync: updatePatient } = usePatientUpdate({
    variables: {},
  });

  const { mutateAsync: updatePatientMetadataEntries } =
    usePatientMetadataEntryUpdate({
      variables: {},
    });

  const { mutateAsync: updatePatientFormMetadataEntries } =
    usePatientFormMetadataEntryUpdate({
      variables: {},
    });

  const { mutateAsync: createStitchLink } = useCreateStitchUpload({
    variables: {},
  });

  const {
    caregiversSelectOpts = [],
    isRefetching: fetchingCaregivers,
    refetchCaregivers,
    caregiverRoleUid,
  } = useCaregiverUsers({
    variables: {},
  });

  const { mutateAsync: updatePatientRelations } =
    useCleoClinicianPatientFormRelationsUpdate({
      variables: {},
    });

  const { patientsForms } = usePatientFormsQuery();
  const { data: currentUser } = useCurrentUser();

  const [selectedCaregivers, setSelectedCaregivers] = useState([]);

  useEffect(() => {
    setSelectedCaregivers(
      caregiversSelectOpts?.filter(({ value }) =>
        patientForm?.caregivers?.flatMap(({ uid }) => uid).includes(value)
      )
    );
  }, [caregiversSelectOpts, patientForm?.caregivers]);

  const getFieldFromMetadataEntries = useCallback(
    (metadataEntries) =>
      map(metadataEntries, (metadata) => {
        if (
          isEqual(metadata.parameter?.valueType, 'DATETIME') &&
          get(clinicData, 'settings.timezone')
        ) {
          return {
            id: metadata.uid,
            type: getMetadataFiledType(metadata),
            label: metadata?.parameter?.name,
            required: true,
            disabled: isLoading,
            timezone: get(clinicData, 'settings.timezone'),
          };
        }

        return {
          id: metadata.uid,
          type: getMetadataFiledType(metadata),
          label: metadata?.parameter?.name,
          required: true,
          disabled: isLoading,
        };
      }),
    [clinicData, isLoading]
  );

  const details = getFirstAndLastName(patient.name);
  const defaultValues = useMemo(
    () => ({
      ...patient,
      patientName: `${details.firstName} ${details.lastName}`.trim(),
      communicationMethod: find(communicationMethods, {
        gql: patient.communicationMethod,
      })?.value,
      ...chain(patient.metadataEntries)
        .groupBy((metadata) => metadata.uid)
        .mapValues((metadata) => values(metadata[0].value)[0])
        .mapValues((metadata) => {
          const url = get(metadata, 'url');
          if (!isUndefined(url)) {
            return {
              ...metadata,
              url: `${url}?x-session=${authState.token}`,
            };
          }
          return metadata;
        })
        .value(),
    }),
    [
      authState,
      communicationMethods,
      details?.firstName,
      details?.lastName,
      patient,
    ]
  );

  const patientLevelForm = useMemo(
    () => ({
      id: 'patientInformation',
      title: t('Patient Information'),
      fields: [
        {
          id: 'patientName',
          type: 'input',
          label: t('Patient Name'),
          placeholder: 'Enter patient name',
          required: true,
          disabled: isLoading,
        },
        {
          id: 'phone',
          type: 'phone',
          label: t('Phone No.'),
          required: true,
          disabled: isLoading,
        },
        {
          id: 'communicationMethod',
          type: 'select',
          label: t('Alerted Via'),
          required: true,
          options: communicationMethods,
          disabled: isLoading,
        },
        {
          id: 'email',
          type: 'input',
          label: t('Email'),
          placeholder: 'Email address',
          required: false,
          disabled: isLoading,
        },
        {
          id: 'gender',
          type: 'select',
          label: t('Gender'),
          options: [
            { gql: 'gender', label: t('Female'), value: 'Female' },
            { gql: 'gender', label: t('Male'), value: 'Male' },
          ],
          disabled: isLoading,
        },
        {
          id: 'dateOfBirth',
          type: 'date',
          label: t('Date of birth'),
          disabled: isLoading,
          allowPreviousDate: true,
        },
        ...getFieldFromMetadataEntries(patient.metadataEntries),
      ],
    }),
    [
      communicationMethods,
      getFieldFromMetadataEntries,
      isLoading,
      patient.metadataEntries,
      t,
    ]
  );

  const patientFormDefaultValues = useMemo(
    () => ({
      ics: map(get(patientForm, 'ics', []), (ic) => ic.uid),
      alertees: map(get(patientForm, 'alertees', []), (al) => al.uid),
      ...chain(patientForm.metadataEntries)
        .groupBy((metadata) => metadata.uid)
        .mapValues((metadata) => values(metadata[0].value)[0])
        .mapValues((metadata) => {
          const url = get(metadata, 'url');
          if (!isUndefined(url)) {
            return {
              ...metadata,
              url: `${url}?x-session=${authState.token}`,
            };
          }
          return metadata;
        })
        .value(),
    }),
    [patientForm, authState]
  );

  const patientFormLevelForm = {
    id: 'formInformation',
    title: t('Form Information'),
    fields: [
      ...getFieldFromMetadataEntries(patientForm.metadataEntries),
      {
        id: 'ics',
        type: 'multiSelect',
        label: t('Clinical user(s) in-charge'),
        required: false,
        options: clinicians,
        disabled: isLoading,
      },
      {
        id: 'alertees',
        type: 'multiSelect',
        label: t('Clinical user(s) to alert'),
        required: false,
        options: clinicians,
        disabled: isLoading,
      },
    ],
  };

  const onSetPatientRef = (ref) => {
    if (!ref) return;
    patientRef.current = ref;
    setIsValidPatientRef(ref.isValid());
  };

  const onSetPatientFormRef = (ref) => {
    if (!ref) return;
    patientFormRef.current = ref;
    setIsValidPatientFormRef(ref.isValid());
  };

  const isDisabled = useMemo(() => {
    if (!section) {
      return !(isValidPatientFormRef === isValidPatientRef) || isLoading;
    }
    if (section === 'clinicalUsers') {
      return !isValidPatientFormRef || isLoading;
    }
    if (section === 'patientDetails') {
      return !isValidPatientRef || isLoading;
    }
    return true;
  }, [isValidPatientFormRef, isValidPatientRef, isLoading, section]);

  const getValueFromMetadata = (metadata, updatedValue) => {
    switch (metadata?.parameter?.valueType) {
      case 'STRING':
        return {
          string: get(updatedValue, metadata?.uid),
        };

      case 'STRING_ARRAY':
        return {
          string_array: split(get(updatedValue, metadata?.uid, ''), ','),
        };

      case 'NUMBER':
        return {
          number: parseFloat(get(updatedValue, metadata?.uid)),
        };

      case 'DATETIME':
        // backend python does not support ISOString with 'Z'
        return {
          datetime: hasTimeInString(metadata?.extractedForDisplay)
            ? get(updatedValue, metadata?.uid).replace('Z', '+00:00')
            : parseISO(get(updatedValue, metadata?.uid))
                ?.toISOString()
                ?.replace('Z', '+00:00'),
        };

      case 'TIME':
        return {
          time: get(updatedValue, metadata?.uid),
        };

      case 'FILE': {
        const isFile = get(updatedValue, metadata?.uid) instanceof File;

        if (isFile) {
          return createStitchLink({
            input: {
              key: 'cleo',
              mimeType: get(updatedValue, metadata?.uid)?.type,
            },
          }).then(({ stitchCreateUploadUrl }) => {
            const body = new FormData();
            const uploadedFile = get(updatedValue, metadata?.uid);

            map(stitchCreateUploadUrl.fields, (value, key) => {
              body.append(key, value);
            });
            body.append('file', uploadedFile);

            return fetch(stitchCreateUploadUrl.url, {
              method: 'post',
              body,
            }).then(() => ({
              file: {
                url: `stitch://${stitchCreateUploadUrl?.uploadId}`,
                name:
                  uploadedFile?.name.substring(
                    0,
                    uploadedFile?.name.lastIndexOf('.')
                  ) || uploadedFile?.name,
                suffix: uploadedFile?.name.substring(
                  uploadedFile?.name.lastIndexOf('.')
                ),
              },
            }));
          });
        }
        return metadata?.value;
      }

      default:
        return {
          string: get(updatedValue, metadata?.uid),
        };
    }
  };

  const onSubmitPatientForm = async () => {
    const patientFormData = patientFormRef.current.getFormData();
    const caregiverUids = selectedCaregivers.map(({ value }) => value);

    await Promise.all(
      map(patientForm?.metadataEntries, async (metadata) =>
        updatePatientFormMetadataEntries({
          input: {
            uid: metadata.uid,
            value: await getValueFromMetadata(metadata, patientFormData),
          },
        })
      )
    );

    return updatePatientRelations({
      input: {
        patientFormUid: patientForm.uid,
        alerteeUids: get(patientFormData, 'alertees', []),
        icUids: get(patientFormData, 'ics', []),
        caregiverUids,
      },
    });
  };

  const onSubmitPatient = async () => {
    const patientData = patientRef.current.getFormData();
    const caregiverUids = selectedCaregivers.map(({ value }) => value);

    const {
      patientName,
      communicationMethod,
      phone,
      email,
      gender,
      dateOfBirth,
    } = patientData;

    const getGenderGqlProp = () => (gender === 'Male' ? 'M' : 'F');

    const editedPatient = {
      uid: patient?.uid,
      name: `${patientName}`.trim(),
      communicationMethod: find(communicationMethods, {
        value: communicationMethod,
      })?.gql,
      phone,
      email,
      gender: isEmpty(gender) ? undefined : getGenderGqlProp(),
      dateOfBirth: moment(dateOfBirth),
    };

    await Promise.all(
      map(patient?.metadataEntries, async (metadata) =>
        updatePatientMetadataEntries({
          input: {
            uid: metadata?.uid,
            value: await getValueFromMetadata(metadata, patientData),
          },
        })
      )
    );

    if (patientForm?.uid) {
      const patientFormInput = {};
      if (!isEmpty(patientForm?.alertees)) {
        patientFormInput.alerteeUids = map(
          patientForm?.alertees,
          ({ uid }) => uid
        );
      }
      if (!isEmpty(patientForm?.ics)) {
        patientFormInput.icUids = map(patientForm?.ics, ({ uid }) => uid);
      }

      if (!isEmpty(caregiverUids)) {
        patientFormInput.caregiverUids = caregiverUids;
      }

      if (!isEmpty(patientFormInput)) {
        await updatePatientRelations({
          input: {
            patientFormUid: patientForm?.uid,
            ...patientFormInput,
          },
        });
      }
    }

    return updatePatient({ input: editedPatient }).then((res) => {
      const cleoPatient = get(res, 'cleoPatientUpdate.cleoPatient');
      if (!cleoPatient) {
        return setShowServerError(true);
      }
      return null;
    });
  };

  const refreshCache = () => {
    queryClient.invalidateQueries('patient');
    queryClient.invalidateQueries('patients');
    queryClient.invalidateQueries('patientForms');
    if (refetchPatientForm) {
      refetchPatientForm();
    }
    closeModal();
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!section) {
      await onSubmitPatient();
      await onSubmitPatientForm();
      refreshCache();
    }

    if (isEqual(section, 'patientDetails')) {
      await onSubmitPatient();
      refreshCache();
    }

    if (isEqual(section, 'clinicalUsers')) {
      await onSubmitPatientForm();
      refreshCache();
    }
  };

  return (
    <Box
      p={1}
      width={['100%', '100%', 720]}
      style={{ boxSizing: 'border-box' }}
      data-testid={`patient-edit-modal-${section}`}
    >
      {!showAddCaregiverSection && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <H2>
              {section === 'clinicalUsers'
                ? t('Edit Form Information')
                : t('Edit Patient Details')}
            </H2>
            <Box cursor="pointer" onClick={closeModal}>
              <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
            </Box>
          </Flex>
          <Box>
            {showServerError && (
              <ErrorBanner
                mt={2}
                text={t(
                  'An error has occured. Please check your input and try submitting again.'
                )}
              />
            )}

            {!section || section === 'patientDetails' ? (
              <Form
                form={patientLevelForm}
                formRef={onSetPatientRef}
                defaultFormData={defaultValues}
                fieldPerRow={isMobile ? 1 : 2}
                titleProps={{
                  fontSize: '16px',
                  mt: showServerError ? 2 : 6,
                }}
                inputLabelProps={{
                  mt: 0,
                }}
                inputLabelTextProps={{
                  fontSize: '12px',
                }}
              />
            ) : null}
            {!section || section === 'clinicalUsers' ? (
              <>
                {section === 'clinicalUsers' && section && (
                  <>
                    {' '}
                    <H4 mt={3} mb={2}>
                      {t('Monitoring Form')}
                    </H4>
                    <Text mt={2} mb={2}>
                      {patientForm?.monitoringForm?.effectiveName}
                    </Text>
                  </>
                )}
                {cliniciansLoading && <TableLoader />}
                {!cliniciansLoading && (
                  <Form
                    name="clinicians"
                    form={patientFormLevelForm}
                    formRef={onSetPatientFormRef}
                    defaultFormData={patientFormDefaultValues}
                    fieldPerRow={1}
                    titleProps={{
                      fontSize: '16px',
                      mt: 3,
                    }}
                    inputLabelProps={{
                      mt: 0,
                    }}
                    inputLabelTextProps={{
                      fontSize: '12px',
                    }}
                  />
                )}
              </>
            ) : null}

            {caregiverRoleUid && section !== 'patientDetails' && (
              <Box>
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <H4 mt={3} mb={2} mr={2}>
                    {t('Family Member Information')}
                  </H4>

                  <Box
                    cursor="pointer"
                    onClick={() => setShowAddCaregiverSection(true)}
                  >
                    <H5 small mt={3} mb={2} color={theme.colors.primary}>
                      {t('Add Family Member')}
                    </H5>
                  </Box>
                </Flex>

                <Label mb={1}>{t('Family Member(s)')}</Label>
                {caregiversSelectOpts.length > 0 && (
                  <CreatableSelect
                    menuIsOpen={openCaregiversSelected}
                    onFocus={() => setOpenCaregiversSelected(true)}
                    onBlur={() => setOpenCaregiversSelected(false)}
                    value={selectedCaregivers}
                    options={caregiversSelectOpts}
                    onChange={setSelectedCaregivers}
                    isDisabled={fetchingCaregivers}
                    maxMenuHeight={180}
                    placeholder={t('Select Family Member(s)')}
                    isValidNewOption={() => false}
                    isOptionDisabled={({ label }) =>
                      isEqual(label, 'Select Family Member(s)')
                    }
                    menuPlacement="auto"
                  />
                )}
              </Box>
            )}

            {!section && (
              <>
                <H4 mt={3} mb={2}>
                  {t('Monitoring Form')}
                </H4>
                <Text mt={2} mb={2}>
                  {patientForm?.monitoringForm?.effectiveName}
                </Text>
              </>
            )}

            <Flex mt={7} justifyContent="flex-end">
              <SecondaryOutlinedButton
                data-testid={`patient-edit-modal-${section}-close`}
                onClick={closeModal}
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                ml={3}
                type="submit"
                disabled={isDisabled}
                onClick={handleSubmit}
              >
                {t('Save')}
              </PrimaryButton>
            </Flex>
          </Box>
        </>
      )}
      {showAddCaregiverSection && (
        <FormProvider {...methods}>
          <AddCaregiverUserModal
            addCaregiverOnly
            patientsForms={patientsForms}
            caregiverRoleUid={caregiverRoleUid}
            currentUser={currentUser}
            closeModal={async () => {
              await refetchCaregivers();
              setShowAddCaregiverSection(false);
            }}
            onBackPress={() => setShowAddCaregiverSection(false)}
            showBackButton
          />
        </FormProvider>
      )}
    </Box>
  );
};

EditPatientModal.propTypes = {
  section: PropTypes.oneOf([
    'patientDetails',
    'clinicalUsers',
    null,
    undefined,
  ]),
  modalTitle: PropTypes.string,
};

EditPatientModal.defaultProps = {
  section: null,
  modalTitle: '',
};
export default EditPatientModal;
