import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableHeadCell,
  TableBody,
  Box,
  H3,
  H5,
  Flex,
  FAIcon,
  Text,
  theme,
  BoxWidget,
  LogEvent,
} from '@fivehealth/botero';
import _, { isEmpty, camelCase } from 'lodash';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import NoResults from 'components/NoResults/NoResults';
import { useTranslation } from 'react-i18next';
import BotCryingAvatar from '../../assets/crying-avatar.svg';

const DesktopDataTableBody = ({
  tableHeaders,
  rows,
  renderCell,
  noResultProps,
  ...props
}) => {
  const onHandleClick = (e, row) => {
    if (e && e.target.tagName.toLowerCase() === 'a') {
      return;
    }

    if (props.handleRowPressed) {
      props.handleRowPressed(row);
    }
  };
  return (
    <Box {...props} width="100%">
      <TableContainer textAlign="left" width="100%">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableHeadCell key={header.key}>{header.label}</TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={`dashboard-tbl-row-${index}`}>
              {tableHeaders.map((header, idx) => (
                <TableCell
                  cursor="pointer"
                  onClick={(e) => {
                    onHandleClick(e, row);
                  }}
                  borderTop="1px solid #D5D9DE"
                  key={`${header.key}-row-${row.uid}-${idx}`}
                >
                  <LogEvent
                    logEventProps={{
                      subSource: _.get(row, 'patient.name'),
                      eventName: props.logEventProps.rowClickedEvent,
                    }}
                    actionProps={{
                      onClick: { action: 'click' },
                    }}
                    elementType="table row"
                    key={`logevent-row-${idx}`}
                  >
                    <>{renderCell(header, row)}</>
                  </LogEvent>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      {isEmpty(rows) && (
        <NoResults
          {...noResultProps}
          avatarProps={{
            height: 60,
            width: 60,
            src: noResultProps.avatar || BotCryingAvatar,
          }}
        />
      )}
    </Box>
  );
};

const MobileDataTableBody = ({
  tableHeaders,
  rows,
  renderCell,
  handleRowPressed,
  ...props
}) => {
  const { t } = useTranslation();

  if (isEmpty(rows)) {
    return (
      <Text textAlign="center" fontSize={14} mt={8}>
        {t('No data available.')}
      </Text>
    );
  }
  return (
    <Box {...props} pl={0}>
      {rows.map((row, index) => (
        <BoxWidget
          mx={0}
          mb={16}
          borderColor="#D5D9DE"
          cursor="pointer"
          onClick={() => {
            if (handleRowPressed) {
              handleRowPressed(row);
            }
          }}
          variant={row.statusColor}
          key={index}
          titleComponent={<H5>{renderCell(tableHeaders[0], row)}</H5>}
        >
          <Text fontSize={14} mb={1} color={theme.colors.darkestShade}>
            {renderCell(tableHeaders[1], row)}
          </Text>
          <Text fontSize={14}>{renderCell(tableHeaders[2], row)}</Text>
        </BoxWidget>
      ))}
    </Box>
  );
};

const Header = styled(Flex)`
  &:hover {
    .title,
    .icon {
      color: ${theme.colors.primary};
    }
  }
`;

const DataTableHeader = ({ handleTitleClick, title }) => (
  <Header
    onClick={handleTitleClick}
    alignItems="center"
    justifyContent={['space-between', 'initial']}
  >
    <H3 cursor="pointer" className="title">
      {title}
    </H3>
    <Box display={['none', 'none', 'inline']}>
      <FAIcon
        className="icon"
        icon={faArrowRight}
        style={{ height: 14, width: 14, marginLeft: 10 }}
      />
    </Box>
    <Text
      display={['inline', 'inline', 'none']}
      color={theme.colors.primary}
      cursor="pointer"
      fontWeight={500}
    >
      View all
    </Text>
  </Header>
);

const DataTable = (props) => {
  const { title, handleTitleClick } = props;
  return (
    <LogEvent
      logEventProps={props.logEventProps}
      actionProps={{
        onClick: { action: 'click' },
      }}
      elementType="table"
    >
      <Box {...props}>
        {title && (
          <DataTableHeader title={title} handleTitleClick={handleTitleClick} />
        )}
        <Box display={['initial', 'initial', 'none']}>
          <MobileDataTableBody {...props} />
        </Box>
        <Box display={['none', 'none', 'initial']}>
          <DesktopDataTableBody {...props} />
        </Box>
      </Box>
    </LogEvent>
  );
};

DataTable.defaultProps = {
  renderCell: (header, row) => row[camelCase(header.key)],
};

export default DataTable;
