import { useApi } from '@fivehealth/botero';
import { get } from 'lodash';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

const usePatientFormsQuery = () => {
  const [patientsForms, setPatientsForms] = useState([]);

  const {
    queries: { usePatientForms },
  } = useApi({
    queries: ['usePatientForms'],
  });

  const queryClient = useQueryClient();

  const { refetch: refetchPatientForms, loading } = usePatientForms({
    variables: {
      activated: true,
    },
    onSuccess: ({ data }) => {
      setPatientsForms(
        get(data, 'pages', [])
          .flatMap((page) => page || [])
          .filter(Boolean)
      );
    },
  });

  const invalidateQueries = () => queryClient.invalidateQueries('patientForms');

  return {
    patientsForms,
    refetchPatientForms,
    invalidateQueries,
    loading,
  };
};

export default usePatientFormsQuery;
