import { useApi } from '@fivehealth/botero';
import { chain } from 'lodash';

const useCliniciansData = () => {
  const {
    queries: { useClinicians },
  } = useApi({
    queries: ['useClinicians'],
  });

  const { data: cliniciansData, isLoading: cliniciansLoading } = useClinicians({
    variables: { activated: true, first: 100 },
  });

  const cliniciansSelectOptions = chain(cliniciansData)
    .get('pages', [])
    .flatMap((value) => value)
    .orderBy('name')
    .filter(({ isCaregiver }) => !isCaregiver)
    .map(({ name: label, uid: value }) => ({ label, value }))
    .value();

  return {
    cliniciansData,
    cliniciansSelectOptions,
    cliniciansLoading,
  };
};

export default useCliniciansData;
