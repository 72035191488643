// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const Calendar = memo<IconProps>(
  ({ color, width = 15, height = 15, viewBox }) => (
    <Icon>
      <svg
        width={width}
        height={height}
        viewBox={viewBox || '0 0 34 34'}
        fill={color || '#111824'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.62501 9H3.37501C3.15626 9 3.00001 8.84375 3.00001 8.625V7.375C3.00001 7.1875 3.15626 7 3.37501 7H4.62501C4.81251 7 5.00001 7.1875 5.00001 7.375V8.625C5.00001 8.84375 4.81251 9 4.62501 9ZM8.00001 8.625C8.00001 8.84375 7.81251 9 7.62501 9H6.37501C6.15626 9 6.00001 8.84375 6.00001 8.625V7.375C6.00001 7.1875 6.15626 7 6.37501 7H7.62501C7.81251 7 8.00001 7.1875 8.00001 7.375V8.625ZM11 8.625C11 8.84375 10.8125 9 10.625 9H9.37501C9.15626 9 9.00001 8.84375 9.00001 8.625V7.375C9.00001 7.1875 9.15626 7 9.37501 7H10.625C10.8125 7 11 7.1875 11 7.375V8.625ZM8.00001 11.625C8.00001 11.8438 7.81251 12 7.62501 12H6.37501C6.15626 12 6.00001 11.8438 6.00001 11.625V10.375C6.00001 10.1875 6.15626 10 6.37501 10H7.62501C7.81251 10 8.00001 10.1875 8.00001 10.375V11.625ZM5.00001 11.625C5.00001 11.8438 4.81251 12 4.62501 12H3.37501C3.15626 12 3.00001 11.8438 3.00001 11.625V10.375C3.00001 10.1875 3.15626 10 3.37501 10H4.62501C4.81251 10 5.00001 10.1875 5.00001 10.375V11.625ZM11 11.625C11 11.8438 10.8125 12 10.625 12H9.37501C9.15626 12 9.00001 11.8438 9.00001 11.625V10.375C9.00001 10.1875 9.15626 10 9.37501 10H10.625C10.8125 10 11 10.1875 11 10.375V11.625ZM14 3.5V14.5C14 15.3438 13.3125 16 12.5 16H1.50001C0.656256 16 5.72205e-06 15.3438 5.72205e-06 14.5V3.5C5.72205e-06 2.6875 0.656256 2 1.50001 2H3.00001V0.375C3.00001 0.1875 3.15626 0 3.37501 0H4.62501C4.81251 0 5.00001 0.1875 5.00001 0.375V2H9.00001V0.375C9.00001 0.1875 9.15626 0 9.37501 0H10.625C10.8125 0 11 0.1875 11 0.375V2H12.5C13.3125 2 14 2.6875 14 3.5ZM12.5 14.3125V5H1.50001V14.3125C1.50001 14.4375 1.56251 14.5 1.68751 14.5H12.3125C12.4063 14.5 12.5 14.4375 12.5 14.3125Z"
          fill={color || '#111824'}
        />
      </svg>
    </Icon>
  )
);

export default Calendar;
