import React, { useState, useRef } from 'react';
import { map, find, isEqual } from 'lodash';
import {
  ActionMenuText,
  ActionMenuItem,
  ActionMenu,
  Box,
  MultiCalendar,
  FAIcon,
  Flex,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { faChevronDown, faClock } from '@fortawesome/pro-regular-svg-icons';

const PatientChartsDropdown = ({
  options,
  startDate,
  endDate,
  value,
  onChange,
  onChangeAllData,
  onChangeCustomDateRange,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef();
  const [selected, setSelected] = useState(
    find(options, (option) => isEqual(option.value, value))?.label
  );

  const handleOnClick = (option) => {
    setSelected(option.label);
    onChange(option);
    dropdownRef.current.setOpen(false);
  };

  const onSetDropDownRef = (ref) => {
    dropdownRef.current = ref;
  };

  const handleCancel = () => {
    dropdownRef.current.setOpen(false);
  };

  const handleChange = (fromDate, toDate) => {
    onChangeCustomDateRange(fromDate, toDate);
    setSelected('Custom Range');
    dropdownRef.current.setOpen(false);
  };

  const handleAllTime = () => {
    if (onChangeAllData) {
      onChangeAllData();
    }
    setSelected('All-time');
    dropdownRef.current.setOpen(false);
  };

  return (
    <Box>
      <ActionMenu
        pl={isEqual(selected, 'Custom Range') ? 10 : 4}
        dropdownRef={onSetDropDownRef}
        label={
          <SecondaryOutlinedButton
            testid="filter_day_options"
            borderColor="#D5D9DE"
            borderRadius={8}
            minWidth={[0, 0, 124]}
            height={40}
            ml={[1, 1, 0]}
          >
            <Box display={['none', 'none', 'block']}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize={14} fontWeight={500} color="fullShade" mr={1}>
                  {t(`${selected}`)}
                </Text>
                <FAIcon
                  icon={faChevronDown}
                  fontSize={14}
                  color="darkestShade"
                />
              </Flex>
            </Box>

            <Box display={['block', 'block', 'none']}>
              <FAIcon icon={faClock} fontSize={14} color="fullShade" />
            </Box>
          </SecondaryOutlinedButton>
        }
      >
        <Flex>
          <Box width={200} pt={2}>
            {map(options, (option) => (
              <ActionMenuItem
                testid={`${option.label.split(' ').join('_')}_option`}
                key={option.label}
                onClick={() => handleOnClick(option)}
              >
                <ActionMenuText
                  color={
                    isEqual(option.label, selected) ? 'primary' : 'fullShade'
                  }
                >
                  {t(option.label)}
                </ActionMenuText>
              </ActionMenuItem>
            ))}

            <ActionMenuItem
              testid="filter_day_alltime_option"
              key="allTime"
              mb={[2, 2, 0]}
              onClick={handleAllTime}
            >
              <ActionMenuText
                color={isEqual(selected, 'All-time') ? 'primary' : 'fullShade'}
              >
                {t('All-time')}
              </ActionMenuText>
            </ActionMenuItem>

            <Box display={['none', 'none', 'block']}>
              <ActionMenuItem
                testid="filter_day_custom_option"
                minHeight={55}
                key="customRange"
                divider
                onClick={() => setSelected('Custom Range')}
              >
                <ActionMenuText
                  color={
                    isEqual(selected, 'Custom Range') ? 'primary' : 'fullShade'
                  }
                >
                  {t('Custom Range')}
                </ActionMenuText>
              </ActionMenuItem>
            </Box>
          </Box>
          {isEqual(selected, 'Custom Range') && (
            <MultiCalendar
              defaultFromDate={startDate}
              defaultToDate={endDate}
              elevation={0}
              borderLeftStyle="solid"
              borderLeftWidth={1}
              borderLeftColor="#EEEDF0"
              onCancel={handleCancel}
              onChange={handleChange}
            />
          )}
        </Flex>
      </ActionMenu>
    </Box>
  );
};

export default PatientChartsDropdown;
