import { createQuery } from '@fivehealth/botero';
import { gql } from 'graphql-request';
import { buildPaginatedResponse } from 'lib/pagination';
import { get } from 'lodash';

export const GRAPHQL_DOCUMENT = gql`
  query cleoDeliveriesAll(
    $first: Int
    $offset: Int
    $direction: Direction
    $patientUid: String
  ) {
    cleoDeliveries(
      first: $first
      offset: $offset
      direction: $direction
      patientUid: $patientUid
    ) {
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
      edges {
        node {
          uid
          sender {
            id
            isBot
            clinician {
              name
            }
            patient {
              name
            }
          }
          createdOn
          direction
          sender {
            patient {
              uid
            }
            clinician {
              uid
              name
            }
            isBot
            device {
              uid
            }
          }
          message
          recipient {
            patient {
              name
            }
            clinician {
              uid
            }
            isBot
            device {
              uid
              phone
            }
          }
          communicationMethod
          deliveryBy
          status
          metadata
          messageWithoutSubstitution
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientMessages',
  paginate: 'offset',
  filters: {
    directions: {
      id: 'direction',
      title: 'Direction',
      data: [
        {
          id: 'incoming',
          label: 'Incoming',
          toParams: {
            direction: 'INCOMING',
          },
        },
        {
          id: 'outgoing',
          label: 'outgoing',
          toParams: {
            direction: 'OUTGOING',
          },
        },
      ],
    },
  },
  getNextPageParam: ({ cleoDeliveries: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
    if (!hasNextPage) {
      return undefined;
    }
    const currentOffset = allPages.flatMap(({ cleoDeliveries }) =>
      get(cleoDeliveries, 'edges', [])
    ).length;
    return currentOffset;
  },
  select: (data) => buildPaginatedResponse(data, 'cleoDeliveries'),
  keepPreviousData: true,
});
