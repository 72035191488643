import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
  useApi,
  Box,
  BoxWidget,
  H2,
  Flex,
  Form,
  SecondaryOutlinedButton,
  PrimaryButton,
  FAIcon,
  Text,
} from '@fivehealth/botero';
import { format } from 'date-fns';
import Select from 'components/Select/Select';
import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import { get, map, isEmpty, isNull, chain, flatMap, isEqual } from 'lodash';
import formatPhoneNumber from 'lib/formatPhoneNumber';
import { primaryColor, primaryColorOpacity8 } from 'components/Charts/theme';
import messageSending from '../../assets/message-sending.gif';
import messageComplete from '../../assets/message-sending-complete.gif';
import BotCryingAvatar from '../../assets/crying-avatar.svg';

// import LoadingOverlay from 'components/LoadingOverlay';

const PatientDetailRow = ({ label, value }) => (
  <Flex mb={1}>
    <Text fontWeight={500} color="darkestShade" minWidth={125} mr={1}>
      {label}
    </Text>
    <Text color="fullShade" fontWeight={400}>
      {value}{' '}
    </Text>
  </Flex>
);

const MessageTemplateModal = withTranslation()(
  ({
    closeModal,
    onSubmit,
    patientForm,
    t,
    selectedPatients,
    isGroup,
    selectedGroups,
    isPatientsView = false,
    isSelectAll,
    selectAllCount,
  }) => {
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
    const messageCreatedAt = useRef();
    const formRef = useRef();

    const [selectedProgram, setSelectedProgram] = useState();

    const [selectedPatientEvent, setSelectedPatientEvent] = useState();

    const patientHasEnrolledForms = useMemo(() => {
      if (selectedPatients?.length === 1) {
        return selectedPatients[0]?.enrolledMonitoringForms?.length > 0;
      }
      if (isPatientsView)
        return patientForm?.patient?.enrolledMonitoringForms?.length > 0;
      if (selectedPatients?.length > 1) return false;
      if (isGroup) return false;
      return !isEmpty(patientForm?.monitoringForm);
    }, [
      isPatientsView,
      patientForm?.monitoringForm,
      patientForm?.patient?.enrolledMonitoringForms,
      isGroup,
      selectedPatients,
    ]);

    const {
      queries: {
        usePatientMessageTemplates,
        useCreateStitchUpload,
        useMonitoringFormsets,
        usePatientEvents,
      },
    } = useApi({
      queries: [
        'usePatientMessageTemplates',
        'useCreateStitchUpload',
        'useMonitoringFormsets',
        'usePatientEvents',
      ],
    });

    const { data: monitoringForms } = useMonitoringFormsets({
      enabled: isGroup || selectedPatients?.length > 1,
      variables: {
        isEnrollmentForm: false,
      },
    });

    const { data: messageTemplateData } = usePatientMessageTemplates({
      variables: {
        communicationMethods: isEqual(
          patientForm?.patient?.communicationMethod,
          'SMS'
        )
          ? 'ANY'
          : patientForm?.patient?.communicationMethod,
      },
    });

    const { mutateAsync: createStitchLink } = useCreateStitchUpload({
      variables: {},
    });

    const messageTemplatesRequiresNoForm = useMemo(
      () =>
        get(messageTemplateData, 'pages', [])
          .flatMap((page) => page)
          .filter(
            (item) =>
              flatMap(flatMap(item?.templates).map(({ messages }) => messages))
                .length > 0
          )
          .filter((item) => {
            if (isGroup || selectedPatients?.length > 0)
              return !item.requirePatientForm && !item.requirePatientEvent;
            return !item.requirePatientForm;
          })
          .map((template) => ({
            ...template,
            label: template.name,
            value: template.uid,
          })),
      [isGroup, messageTemplateData, selectedPatients?.length]
    );

    const messageTemplatesRequiresForm = useMemo(
      () =>
        get(messageTemplateData, 'pages', [])
          .flatMap((page) => page)
          .filter(
            (item) =>
              flatMap(flatMap(item?.templates).map(({ messages }) => messages))
                .length > 0
          )
          .filter((item) => item.requirePatientForm)
          .map((template) => ({
            ...template,
            label: template.name,
            value: template.uid,
          })),
      [messageTemplateData]
    );

    const programOptions = useMemo(() => {
      if (isGroup || selectedPatients?.length > 1) {
        return monitoringForms
          ?.map((mf) => ({
            ...mf,
            uid: mf.id,
            value: mf.id,
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label));
      }

      return patientForm?.patient?.enrolledMonitoringForms
        ?.map((tpl) => ({
          ...tpl,
          label: tpl.effectiveName,
          value: tpl.uid,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label));
    }, [
      patientForm?.patient?.enrolledMonitoringForms,
      monitoringForms,
      isGroup,
      selectedPatients,
    ]);

    useEffect(() => {
      if (programOptions && programOptions.length === 1 && !selectedProgram) {
        setSelectedProgram(programOptions[0]);
      }
    }, [programOptions, selectedProgram]);

    const messageTemplates = useMemo(
      () =>
        (patientHasEnrolledForms
          ? messageTemplatesRequiresForm.concat(messageTemplatesRequiresNoForm)
          : messageTemplatesRequiresNoForm
        )?.sort((a, b) => a.label.localeCompare(b.label)),
      [
        messageTemplatesRequiresForm,
        messageTemplatesRequiresNoForm,
        patientHasEnrolledForms,
      ]
    );

    const templateRequiresForm = useMemo(
      () => selectedTemplate?.requirePatientForm,
      [selectedTemplate]
    );

    const templateRequiresEvent = useMemo(
      () => selectedTemplate?.requirePatientEvent,
      [selectedTemplate]
    );

    const { data: patientEventsData } = usePatientEvents({
      enabled:
        isPatientsView &&
        !!templateRequiresEvent &&
        !isEmpty(patientForm?.patient),
      variables: {
        patientUidIn: [patientForm?.patient?.uid],
      },
    });

    const patientEvents = useMemo(() => {
      if (patientEventsData?.length > 0) {
        return patientEventsData?.map(({ event, uid }) => ({
          ...event,
          label: `${event.name}`,
          value: uid,
        }));
      }
      return [];
    }, [patientEventsData]);

    const onTryAgain = () => {
      setIsSubmissionComplete(false);
      setIsSubmitting(false);
      messageCreatedAt.current = undefined;
    };

    const onSetFormRef = (ref) => {
      formRef.current = ref;
      setIsValid(ref.isValid());
    };

    const userVariables = useMemo(
      () => selectedTemplate?.userVariables || [],
      [selectedTemplate]
    );

    const sendPayload = async (payload) => {
      try {
        const messageCreateResp = await onSubmit(payload);

        const asyncJob = get(
          messageCreateResp,
          'cleoMessageTemplateDeliverToPatientAsync.asyncJob',
          null
        );

        if (asyncJob && asyncJob?.status !== 'FAILED') {
          setIsSubmissionComplete(true);
          return new Promise((resolve) => resolve(true));
        }
        setIsSubmitting(false);
        return new Promise((resolve) => resolve(false));
      } catch (e) {
        setIsSubmitting(false);
        return new Promise((resolve) => resolve(false));
      }
    };

    const onHandleSend = async (variables = {}) => {
      setIsSubmitting(true);
      setIsSubmissionComplete(false);

      const payload = {
        patientForm,
        template: selectedTemplate,
        userVariables: variables,
      };

      /* When is patient list and template required form, attach program uid */
      if ((isPatientsView || isGroup) && templateRequiresForm) {
        payload.monitoringForm = selectedProgram;
      }

      if (isPatientsView && templateRequiresEvent) {
        payload.patientEventUid = selectedPatientEvent.value;
      }

      let isDelivered;

      try {
        if (selectedPatients?.length) {
          payload.patientUids = selectedPatients?.map((p) => p.id);
        } else {
          payload.patientUids = [patientForm?.patient?.uid];
        }

        isDelivered = await sendPayload(payload);
        if (isDelivered) {
          messageCreatedAt.current = new Date();
          closeModal(true);
        }
        setIsSubmissionComplete(true);

        setIsSubmitting(false);
        await new Promise((resolve) => resolve(true));
      } finally {
        setIsSubmitting(false);
      }
    };

    const onHandleSubmit = async () => {
      if (!isEmpty(userVariables)) {
        const formData = formRef.current.getFormData();

        if (!isNull(formData)) {
          setIsSubmitting(true);
          setIsSubmissionComplete(false);
          await Promise.all(
            chain(userVariables)
              .filter((variable) => !isEmpty(get(formData, variable.name, '')))
              .map((variable) => {
                switch (variable.type) {
                  case 'file':
                    return createStitchLink({
                      input: {
                        key: 'cleo',
                        mimeType: get(formData, variable.name)?.type,
                      },
                    }).then(({ stitchCreateUploadUrl }) => {
                      const body = new FormData();

                      map(stitchCreateUploadUrl.fields, (value, key) => {
                        body.append(key, value);
                      });
                      body.append('file', get(formData, variable.name));

                      return fetch(stitchCreateUploadUrl.url, {
                        method: 'post',
                        body,
                      }).then(() => ({
                        name: variable.name,
                        value: stitchCreateUploadUrl.uploadId,
                      }));
                    });

                  case 'datetime':
                    // backend python does not support ISOString with 'Z'
                    return {
                      name: variable.name,
                      value: get(formData, variable.name, '').replace(
                        'Z',
                        '+00:00'
                      ),
                    };

                  default:
                    return {
                      name: variable.name,
                      value: get(formData, variable.name, ''),
                    };
                }
              })
              .value()
          ).then((variables) => onHandleSend(variables));
        }
      } else {
        onHandleSend();
      }
    };

    const getFormFieldType = (type) => {
      switch (type) {
        case 'string':
          return 'input';
        case 'url':
          return 'input';
        default:
          return type;
      }
    };

    const form = {
      id: 'customizeTemplate',
      title: 'Customize Template',
      fields: map(userVariables, (variable) => ({
        id: variable.name,
        type: getFormFieldType(variable.type),
        label: variable.description,
        labelRight: variable.required ? '' : `(${t('Optional')})`,
        placeholder: `${t('Enter')} ${variable.description} ${t('here')} ...`,
        limit: variable.max_length || null,
        required: variable.required || false,
      })),
    };

    const selectionLabel = useMemo(() => {
      if (isSelectAll) return `${t('Selected patient(s)')}: ${selectAllCount}`;
      if (isGroup) {
        return `${t('Selected group(s)')}:`;
      }

      if (selectedPatients?.length === 1) return `${t('Selected patient')}:`;

      if (selectedPatients?.length > 1) return `${t('Selected patient(s)')}:`;

      return '';
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatients, patientForm, isGroup]);

    const selectionValue = useMemo(() => {
      if (isGroup) {
        return selectedGroups;
      }

      if (selectedPatients) {
        return selectedPatients?.length <= 10
          ? selectedPatients.map((p) => p?.original?.name).join(', ')
          : selectedPatients.length;
      }

      return '';

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatients, patientForm, isGroup]);

    const isActive = useMemo(() => {
      if (!selectedTemplate) return false;

      if (templateRequiresEvent && !selectedPatientEvent) return false;

      if (templateRequiresForm && userVariables.length > 0)
        return (
          selectedTemplate &&
          (selectedProgram || patientForm?.monitoringForm) &&
          isValid
        );

      if (templateRequiresForm && userVariables.length === 0)
        return (
          selectedTemplate && (selectedProgram || patientForm?.monitoringForm)
        );

      if (!templateRequiresForm && userVariables.length > 0)
        return selectedTemplate && isValid;

      if (!templateRequiresForm && userVariables.length === 0)
        return selectedTemplate;

      return selectedTemplate;
    }, [
      selectedTemplate,
      templateRequiresEvent,
      selectedPatientEvent,
      templateRequiresForm,
      userVariables.length,
      selectedProgram,
      patientForm?.monitoringForm,
      isValid,
    ]);

    return (
      <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 720]}>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <H2>
            {((selectedPatients?.length > 1 && !isGroup) || isSelectAll) &&
              `${t('Send message to patients')}`}
            {selectedPatients?.length === 1 &&
              !isGroup &&
              `${t('Send message to patient')}`}
            {!selectedPatients &&
              !isGroup &&
              !isSelectAll &&
              patientForm &&
              `${t('Send message to patient')}`}
            {isGroup && `${t('Send message to group')}`}
          </H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>

        {isSubmitting || isSubmissionComplete ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Flex alignItems="center" justifyContent="center">
              {(isSubmitting ||
                (isSubmissionComplete && messageCreatedAt.current)) && (
                <img
                  src={isSubmitting ? messageSending : messageComplete}
                  alt="Sending message"
                  width={230}
                  height="auto"
                />
              )}
              {isSubmissionComplete && !messageCreatedAt.current && (
                <Box
                  as="img"
                  src={BotCryingAvatar}
                  height={120}
                  width={120}
                  mt={2}
                  alt="Bot Avatar"
                />
              )}
            </Flex>
            {(isSubmitting || messageCreatedAt.current) && (
              <Text color="darkestShade" fontSize={16} fontWeight={600}>
                {messageCreatedAt.current
                  ? t('Sending message in progress...')
                  : t('Sending message to patient')}
              </Text>
            )}
            {!isSubmitting && !messageCreatedAt.current && (
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                mt={3}
              >
                <Text color="darkestShade" fontSize={16} fontWeight={600}>
                  {t('Error: Delivery failure')}
                </Text>
                <Text color="darkestShade" fontSize={14} mt={2}>
                  {t(
                    'Message not sent. Please double-check the patient details and try again.'
                  )}
                </Text>
              </Flex>
            )}
            {isSubmissionComplete && messageCreatedAt.current && (
              <>
                <Text color="darkestShade" fontSize={14} mt={2}>
                  {format(messageCreatedAt.current, 'dd MMM yyyy HH:mm')}
                </Text>
                <PrimaryButton
                  mt={4}
                  height={48}
                  width={101}
                  onClick={() => closeModal(true)}
                >
                  {t('Close')}
                </PrimaryButton>
              </>
            )}
            {isSubmissionComplete && !messageCreatedAt.current && (
              <Flex mt={3}>
                <SecondaryOutlinedButton onClick={() => closeModal(true)}>
                  {t('Cancel')}
                </SecondaryOutlinedButton>
                <PrimaryButton ml={2} onClick={onTryAgain}>
                  {t('Try again')}
                </PrimaryButton>
              </Flex>
            )}
          </Flex>
        ) : (
          <Box>
            {(selectedPatients || isGroup) && (
              <PatientDetailRow label={selectionLabel} value={selectionValue} />
            )}

            {!isEmpty(patientForm) && (
              <Box>
                <PatientDetailRow
                  label={t('Patient name')}
                  value={patientForm.patient.name}
                />
                <PatientDetailRow
                  label={t('Phone number')}
                  value={formatPhoneNumber(patientForm.patient.phone)}
                />
                <PatientDetailRow
                  label={t('Message via')}
                  value={patientForm.patient.communicationMethod}
                />
                {patientForm?.monitoringForm && (
                  <PatientDetailRow
                    label={t('Patient Form')}
                    value={patientForm.monitoringForm.effectiveName}
                  />
                )}
                <PatientDetailRow
                  label={t('Status')}
                  value={
                    patientForm.patient.deactivatedOn
                      ? t('Discharged')
                      : t('Active')
                  }
                />
              </Box>
            )}

            <Box mt={4}>
              <Select
                label={t('Select message')}
                value={selectedTemplate}
                options={messageTemplates}
                onChange={setSelectedTemplate}
                menuPlacement="top"
                maxMenuHeight={180}
              />
            </Box>

            {templateRequiresForm &&
              (isPatientsView || isGroup || selectedPatients?.length > 0) && (
                <Box mt={4}>
                  <Select
                    label={t('Program')}
                    value={selectedProgram}
                    options={programOptions}
                    onChange={setSelectedProgram}
                    menuPlacement="top"
                    maxMenuHeight={180}
                  />
                  <Flex
                    style={{
                      borderRadius: 8,
                    }}
                    p={1}
                    bg={primaryColorOpacity8}
                  >
                    <FAIcon
                      style={{ color: primaryColor, marginRight: 8 }}
                      icon={faExclamationTriangle}
                      hover={{ opacity: 0.6 }}
                    />
                    <Text style={{ color: primaryColor, fontSize: 14 }}>
                      Message will be sent ONLY to patients enrolled in the
                      selected program.
                    </Text>
                  </Flex>
                </Box>
              )}

            {templateRequiresEvent && isPatientsView && (
              <Box mt={4}>
                <Select
                  label={t('Select appointment')}
                  value={selectedPatientEvent}
                  options={patientEvents}
                  onChange={setSelectedPatientEvent}
                  menuPlacement="top"
                  maxMenuHeight={180}
                />
                <Flex
                  style={{
                    borderRadius: 8,
                  }}
                  p={1}
                  bg={primaryColorOpacity8}
                >
                  <FAIcon
                    style={{ color: primaryColor, marginRight: 8 }}
                    icon={faExclamationTriangle}
                    hover={{ opacity: 0.6 }}
                  />
                  <Text style={{ color: primaryColor, fontSize: 14 }}>
                    Please select the appointment you would like to send a
                    reminder for.
                  </Text>
                </Flex>
              </Box>
            )}

            {selectedTemplate && !isEmpty(userVariables) && (
              <BoxWidget
                variant="outlined"
                borderColor="mediumShade"
                bodyProps={{ p: 2, pt: 0 }}
                mx={0}
                maxWidth="100%"
              >
                <Form
                  formRef={onSetFormRef}
                  form={form}
                  titleProps={{
                    fontSize: '16px',
                  }}
                  inputLabelProps={{
                    mt: 0,
                  }}
                  inputLabelTextProps={{
                    fontSize: '12px',
                  }}
                />
              </BoxWidget>
            )}

            <Flex mt={6} justifyContent="flex-end">
              <SecondaryOutlinedButton onClick={() => closeModal(true)}>
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                ml={2}
                onClick={onHandleSubmit}
                disabled={!isActive}
              >
                {t('Send now')}
              </PrimaryButton>
            </Flex>
          </Box>
        )}
      </Box>
    );
  }
);

export default MessageTemplateModal;
