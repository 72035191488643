/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissionComments(
    $isUnread: Boolean
    $visibleTo: SubmissionCommentVisibleToEnum
  ) {
    cleoSubmissionComments(isUnread: $isUnread, visibleTo: $visibleTo) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'submissionsUnreadCommentCount',
  select: (data) => get(data, 'cleoSubmissionComments.totalCount', 0),
});
