import React, { useState } from 'react';
import _, { isEmpty } from 'lodash';
import {
  Box,
  Flex,
  Checkbox,
  DangerButton,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import Config from 'Config';

import { usePatientsBulkUploadAsync } from 'hooks';
import BotSearchAvatar from '../../assets/bot-search-avatar.svg';
import BotCryingAvatar from '../../assets/crying-avatar.svg';

const PatientsBulkUploadConfirmation = ({
  stitchUploadId,
  monitoringForm,
  reminder,
  action,
  label,
  onBackPress,
  closeModal,
  onComplete,
  onCancel,
  duplicateEntries = [],
}) => {
  const { t } = useTranslation();
  const [acknowledge, setAcknowledge] = useState(false);
  const [view, setView] = useState(action);

  const { bulkUploadPatients } = usePatientsBulkUploadAsync();

  const handleClose = () => {
    closeModal();
    onBackPress();
  };

  const handleConfirmation = async () => {
    setView('pending');

    try {
      const input = {
        uploadId: stitchUploadId,
        preview: false,
      };

      if (monitoringForm) {
        input.forceDeliverMessage = Config.IS_PRODUCTION();
        input.overrides = {
          monitoringForms: monitoringForm.map((form) => form.value.id),
        };
      }

      if (reminder?.value) {
        input.overrides = {
          ...input.overrides,
          reminders: [reminder.value],
        };
      }

      const asyncJob = await bulkUploadPatients(input);

      if (!isEmpty(asyncJob.status) && asyncJob.status === 'SUCCESS') {
        setView('completed');
      }
      onComplete();
    } catch (error) {
      setView('error');
    }
  };

  return (
    <Box>
      {_.isEqual(view, 'confirmation') && (
        <Box>
          <Text fontWeight="bold" fontSize={3}>
            {t('Confirm import')}
          </Text>

          {duplicateEntries.length > 0 && (
            <Text color="danger" mb={2} mt={3} fontSize={2}>
              {t(
                `${
                  duplicateEntries.length
                } duplicate entries found at row numbers: ${duplicateEntries
                  .map((entry) => entry.rowNo)
                  .join(', ')}`
              )}
            </Text>
          )}

          <Text mt={3} fontSize={2}>
            {t('Are you sure that you want to import these patients?')}
          </Text>

          <Flex
            mt={2}
            p={1}
            alignItems="center"
            justifyContent="left"
            bg="#E0513820"
            borderRadius={8}
          >
            <Checkbox
              color="danger"
              label="I acknowledge that this operation cannot be undone."
              labelProps={{
                fontSize: 1,
              }}
              textProps={{
                color: 'danger',
              }}
              width={18}
              onChange={(e) => setAcknowledge(e.target.checked)}
            />
          </Flex>
          <Flex mt={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton mr={2} onClick={() => closeModal()}>
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton disabled={!acknowledge} onClick={handleConfirmation}>
              {t('Confirm')}
            </PrimaryButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'pending') && (
        <Box>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotSearchAvatar} />
          </Flex>
          <Text textAlign="center" m={3}>
            {t('Applying changes')} ...
          </Text>
          <Text textAlign="center" m={3}>
            {t('This may take a few minutes, you can close this window.')}
          </Text>
          <ProgressBar ml={10} mr={10} />

          <Flex justifyContent="center" alignItems="center" m={2}>
            <SecondaryOutlinedButton mt={4} onClick={onComplete}>
              {t('Close')}
            </SecondaryOutlinedButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'discard') && (
        <Box>
          <Text fontWeight="bold" fontSize={3}>
            {`Cancel ${_.startCase(label)}`}
          </Text>
          <Text mt={3} fontSize={2}>
            {t(
              'Are you sure that you want to discard changes? These changes will not be saved.'
            )}
          </Text>
          <Flex mt={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton mr={2} onClick={handleClose}>
              {t('Return to preview')}
            </SecondaryOutlinedButton>
            <DangerButton onClick={onCancel}>
              {t('Discard and exit')}
            </DangerButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'error') && (
        <Box>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotCryingAvatar} />
          </Flex>
          <Text textAlign="center" fontWeight="bold" fontSize={3}>
            {t('Something went wrong!')}
          </Text>
          <Text textAlign="center" m={3}>
            {t('Please approach botmd for more information.')}
          </Text>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <PrimaryButton borderRadius="8px" m={0} onClick={handleClose}>
              {t('Back to Patient List')}
            </PrimaryButton>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default PatientsBulkUploadConfirmation;
