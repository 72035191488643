import styled from 'styled-components';
import { Text } from '@fivehealth/botero';

const TextButton = styled(Text)`
  &:hover {
    opacity: 0.8;
  }
`;

export default TextButton;
