import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  H4,
  Flex,
  PhoneInputField,
  Select,
  InputField,
  H6,
  Text,
  useApi,
  FAIcon,
  CreatableSelect,
} from '@fivehealth/botero';
import { isEmpty, get, isEqual } from 'lodash';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const Label = (props) => <H6 pb="4px" color="darkestShade" {...props} />;

const InputWithLabel = withTranslation()(
  ({ errors, errorMessage, label, name, t, value, ...props }) => {
    const [active, setActive] = useState(false);
    return (
      <Flex flexDirection="column" flex={0.48}>
        <Label>{t(label)}</Label>
        <InputField
          width="auto"
          maxWidth="auto"
          name={name}
          value={value || ''}
          {...props}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          style={{
            outline: 'none',
            borderColor: active ? '#256BF6' : '#D5D7DE',
          }}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          message={errorMessage}
          render={({ message }) => (
            <Text color="danger" fontSize={12} mt={1} ml={1}>
              {t(message)}
            </Text>
          )}
        />
      </Flex>
    );
  }
);

const SelectWithLabel = withTranslation()(
  ({ errors, errorMessage, label, name, children, t, ...props }) => (
    <Flex flexDirection="column" flex={0.48}>
      <Label>{t(label)}</Label>
      <Select defaultValue="SMS" name={name} {...props}>
        {children}
      </Select>
      <ErrorMessage
        errors={errors}
        name={name}
        message={errorMessage}
        render={({ message }) => (
          <Text color="danger" fontSize={12} mt={1} ml={1}>
            {t(message)}
          </Text>
        )}
      />
    </Flex>
  )
);

export const communicationMethodOptions = [
  { label: 'Bot MD App', value: 'BOTMD' },
  { label: 'SMS', value: 'SMS' },
];

export const defaultRoleOptions = [
  { label: 'Owner', value: 'OWNER' },
  { label: 'Editor', value: 'EDITOR' },
  { label: 'Member', value: 'MEMBER' },
  { label: 'Viewer', value: 'VIEWER' },
];

const controllerKey = 'caregiverUsers';

const CaregiverUserForm = ({
  control,
  errors,
  index,
  isEditView,
  isLoading,
  onRemove,
  setError,
  patientsOpts,
  removedPatients,
  setRemovedPatients,
  addCaregiverOnly,
}) => {
  const element = useRef(null);
  const { t } = useTranslation();
  // const { clinic } = useAppData();
  // console.log({ clinic })
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const {
    queries: { useClinicPublicSettings, useClinic },
  } = useApi({
    queries: ['useClinicPublicSettings', 'useClinic'],
  });

  const { data: clinic } = useClinic();

  const [openPatientsSelect, setOpenPatientsSelect] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);

  // const { data } = useClinicianDesignations();

  const { data: clinicPublicSettings } = useClinicPublicSettings({
    enabled: !isEmpty(clinic) && !!clinic?.domain,
    variables: {
      domain: clinic?.domain,
    },
  });

  const getFormLanguage = () => {
    const result =
      clinicPublicSettings?.cleoClinicPublicSettings?.countryCode || 'SG';
    return !isEditView ? result.toLowerCase() : result;
  };

  const onPatientAdded = (added) => {
    setSelectedPatients(added);

    if (isEditView) {
      const difference = selectedPatients.filter((x) => !added.includes(x));

      let removedList = removedPatients;

      const addedIds = added.map(({ value }) => value);
      if (difference.length > 0) {
        removedList = removedPatients.concat(difference);
      }

      // Make sure that all items in the added list are not in the removed list
      const newRmList = removedList.filter((x) => !addedIds.includes(x.value));
      setRemovedPatients(newRmList);
    }
  };

  useEffect(() => {
    if (element.current && index !== 0)
      element.current.scrollIntoView({ behavior: 'smooth' });
  }, [index]);

  return (
    <Box
      ref={element}
      border="1px solid"
      borderRadius={8}
      mt={4}
      p={3}
      borderColor="#D5D9DE"
    >
      <Flex justifyContent="space-between">
        <H4 ml={isMobile ? '-8px' : '8px'} mb={2} color="fullShade">
          {t('Family Member Information')}
        </H4>
        {index !== 0 && !isEditView && (
          <Box cursor="pointer" onClick={() => onRemove(index)}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        )}
      </Flex>

      <Flex ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Box flex={[1, 0.5]} mb={[2, 0]} mr={[0, 2]}>
          <Controller
            name={`${controllerKey}.${index}.firstName`}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <InputWithLabel
                errors={errors}
                errorMessage={t('First name is required.')}
                label={t('First name')}
                style={{ marginRight: 24 }}
                {...field}
                placeholder={t('First name')}
                disabled={isLoading}
              />
            )}
          />
        </Box>
        <Controller
          name={`${controllerKey}.${index}.lastName`}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithLabel
              label={t('Last name')}
              {...field}
              placeholder={t('Last name')}
              errors={errors}
              errorMessage={t('Last name is required.')}
            />
          )}
        />
      </Flex>

      <Flex mt={2} ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Box flex={[1, 0.5]} mb={[2, 0]} mr={[0, 2]}>
          <Flex flexDirection="column" flex={0.48}>
            <Label>{t('Phone number')}</Label>
            <Controller
              name={`${controllerKey}.${index}.phone`}
              rules={{
                required: true,
              }}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PhoneInputField
                  style={{ width: '100%' }}
                  placeholder={t('Enter phone number')}
                  country={getFormLanguage()}
                  inputStyle={{ width: '100%' }}
                  {...field}
                  onBlur={(e) => {
                    const phoneNum = `+${field.value}`;
                    const parsed = parsePhoneNumber(phoneNum);
                    const isValid = parsed
                      ? isValidPhoneNumber(phoneNum, parsed.countryCode)
                      : false;
                    if (!parsed || !isValid) {
                      setError(field.name, {
                        type: 'manual',
                        message: t('Phone number is invalid.'),
                      });
                    }
                    field.onBlur(e);
                  }}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${controllerKey}.${index}.phone`}
              message={t('Valid phone number required.')}
              render={({ message }) => (
                <Text color="danger" fontSize={12} mt={1} ml={1}>
                  {t(message)}
                </Text>
              )}
            />
          </Flex>
        </Box>
        <Controller
          name={`${controllerKey}.${index}.email`}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithLabel
              label={t('Email')}
              {...field}
              placeholder={t('example@mail.com')}
              errors={errors}
              errorMessage={t('Email is required.')}
            />
          )}
        />
      </Flex>

      <Flex mt={2} ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Controller
          name={`${controllerKey}.${index}.communicationMethod`}
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <SelectWithLabel
              errors={errors}
              errorMessage={t('Alert method is required.')}
              options={get(clinic, 'communicationMethods')}
              label={t('Alerted via')}
              placeholder={t('Select service')}
              maxMenuHeight={180}
              {...field}
            />
          )}
        />
      </Flex>

      {!addCaregiverOnly && (
        <Box mt={3} ml={[-1, 1]} mr={[-1, 1]}>
          <H4 color="fullShade">
            {t('Select patients to assign to this family member')}
          </H4>

          <Flex flexDirection="row" justifyContent="space-between">
            <Label color="darkestShade" mt={2} mb={1}>
              {t('Search and select patients to assign')}
            </Label>

            {/* <Label color="darkShade" mt={2} mb={1}>
              ({t('Optional')})
            </Label> */}
          </Flex>
          <Controller
            name={`${controllerKey}.${index}.patients`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              if (isEditView) {
                setSelectedPatients(field.value || []);
              }

              return (
                <CreatableSelect
                  menuIsOpen={openPatientsSelect}
                  onFocus={() => setOpenPatientsSelect(true)}
                  onBlur={() => setOpenPatientsSelect(false)}
                  value={selectedPatients}
                  options={patientsOpts}
                  onChange={(val) => {
                    onPatientAdded(val);
                    field.onChange(val);
                  }}
                  isDisabled={isLoading}
                  maxMenuHeight={180}
                  isValidNewOption={() => false}
                  placeholder={t('Search and select patients to assign')}
                  isOptionDisabled={({ label }) =>
                    isEqual(label, 'Search and select patients to assign')
                  }
                  menuPlacement="auto"
                />
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(CaregiverUserForm);
