import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoMessageTemplateDeliverToPatient(
    $input: CleoMessageTemplateDeliverToPatientInput!
  ) {
    cleoMessageTemplateDeliverToPatient(input: $input) {
      results {
        patientForm {
          patient {
            uid
            name
          }
          monitoringForm {
            uid
            effectiveName
          }
        }
        messageTemplate {
          communicationMethod
          language
        }
        isDelivered
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientMessageCreate',
  keepPreviousData: false,
});
