import { gql } from 'graphql-request';
import { get, isEmpty } from 'lodash';
import { createQuery } from '@fivehealth/botero';

export const PATIENT_GRAPHQL_DOCUMENT = gql`
  query cleoPatientWithUid($uid: String) {
    cleoPatient(uid: $uid) {
      name
      uid
      identifier
      phone
      email
      gender
      age
      dateOfBirth
      deactivatedOn
      communicationMethod
      metadataEntries {
        uid
        parameter {
          uid
          name
          valueType
        }
        value
        extractedForDisplay
      }
    }
  }
`;

export const onMapNodePatients = (node) => {
  const getGender = () => (node.gender === 'M' ? 'Male' : 'Female');
  const gender = isEmpty(node.gender) ? '' : getGender();

  return {
    ...node,
    gender,
    id: node.uid,
    // variablesAll: {
    //   ...get(node, 'variablesAll', {}),
    //   dischargedMonitoringForms: node.dischargedMonitoringForms ?? [],
    //   monitoringForms: node.enrolledMonitoringForms ?? [],
    // },
  };
};

export const genders = [
  {
    id: 'male',
    label: 'Male',
    toParams: {
      genderIn: ['M'],
    },
  },
  {
    id: 'female',
    label: 'Female',
    toParams: {
      genderIn: ['F'],
    },
  },
];

const usePatient = () => (args) => {
  const patients = createQuery({
    gqlDocument: PATIENT_GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patient',

    select: (data) => onMapNodePatients(get(data, 'cleoPatient', {})),
    keepPreviousData: false,
  });
  return patients(args);
};
// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatient();
