import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicsWithJWT($token: String!, $providerApplicationUid: String!) {
    cleoLoginableClinics(
      token: $token
      providerApplicationUid: $providerApplicationUid
    ) {
      clinics {
        id
        name
        logo
        domain
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'loginableCLinics',
  keepPreviousData: true,
  select: ({ cleoLoginableClinics }) => get(cleoLoginableClinics, 'clinics'),
});
