import { useApi } from '@fivehealth/botero';
import useClinic from 'api/queries/useClinic';
import asyncJobTypes, { AsyncStatus } from 'constants/asyncJobTypes';
import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';

export interface AsyncJob {
  status: AsyncStatus;
  uid: string;
}

export interface AsyncJobData {
  type: string;
  status: string;
  uid: string;
  clinic: string;
  message: string;
  title: string;
  info: string;
  operation?: string;
}

export interface PatientEventFilter {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  calendar_Uid_In?: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  event_EventType_Uid_In?: string[];
  statusIn?: string[];
}

export interface PatientUpdateEventInput {
  confirmed?: boolean;
  asyncJobUid?: string;
  patientShowed?: boolean;
  patientEventUids?: string[];
  patientEventFilter?: PatientEventFilter;
}

interface UseUpdateEventsAsyncResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateEventsStatus: (input: PatientUpdateEventInput) => Promise<AsyncJob>;
}

export const useUpdateEventsAsync = (): UseUpdateEventsAsyncResult => {
  const {
    queries: { usePatientEventUpdateAsync },
  } = useApi({
    queries: ['usePatientEventUpdateAsync'],
  });

  const { data: clinic }: { data: { domain?: string } } = useClinic();

  // eslint-disable-next-line no-unused-vars
  const [_, setAsyncJobData] = useRecoilState<AsyncJobData>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncJobState as any
  );

  const { mutateAsync: updatePatientEvent } = usePatientEventUpdateAsync({
    variables: {},
  });

  const updateEventsStatus = async (input: PatientUpdateEventInput) => {
    const result = await updatePatientEvent({
      input,
    });

    const asyncJob = get(
      result,
      'cleoPatientEventUpdateAsync.asyncJob',
      null
    ) as AsyncJob;

    if (asyncJob && asyncJob?.status !== 'SUCCESS') {
      const { type } = asyncJobTypes.CleoPatientEventsUpdate;
      const info =
        asyncJobTypes.CleoPatientEventsUpdate.infoText[
          asyncJob.status as AsyncStatus
        ];

      const data = {
        type,
        ...info,
        ...asyncJob,
        clinic: clinic?.domain as string,
      } as unknown as AsyncJobData;
      setAsyncJobData(data);
    }

    return asyncJob;
  };

  return {
    updateEventsStatus,
  };
};
