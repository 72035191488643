import { Box, Text } from '@fivehealth/botero';
import TableCellItem from 'components/TableCellItem';
import TableCellWrapper from 'components/TableCellWrapper';
import { withTranslation } from 'react-i18next';

const TableSubSectionHeader = withTranslation()(
  ({ t, showType, title = 'Glucose Reading' }) => (
    <Box py={2} minHeight={80} style={{ boxSizing: 'border-box' }}>
      <TableCellWrapper justifyContent={showType ? 'flex-start' : 'center'}>
        <TableCellItem pl={showType ? 2 : 0}>
          <Text
            fontSize={14}
            fontWeight={600}
            color="darkestShade"
            textAlign={showType ? 'left' : 'center'}
          >
            {t(title)}
          </Text>
          <Box mt="4px">
            <Text
              fontSize={14}
              fontWeight={600}
              color="darkestShade"
              textAlign={showType ? 'left' : 'center'}
            >
              (mmol/L)
            </Text>
          </Box>
        </TableCellItem>
        {showType && (
          <TableCellItem flex="0.5" pl={2}>
            <Text fontSize={14} fontWeight={600} color="darkestShade">
              {t('Type')}
            </Text>
          </TableCellItem>
        )}
      </TableCellWrapper>
    </Box>
  )
);
export default TableSubSectionHeader;
