import {
  Alert,
  AlertDescription,
  CloseButton,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';
import Colors from 'constants/colors';
import { useApi } from '@fivehealth/botero';
import moment from 'moment';
import React, { memo, useEffect, useMemo } from 'react';
import { get, isEmpty } from 'lodash';

interface AppointmentProgressStatusProps {
  onClose: () => void;
  patientUid: string;
}

export const AppointmentProgressStatus = memo<AppointmentProgressStatusProps>(
  ({ onClose, patientUid }) => {
    const {
      queries: { useGetPatientEventsCount },
    } = useApi({
      queries: ['useGetPatientEventsCount'],
    });

    const startTime = useMemo(
      () => moment().toISOString().replace('Z', '+00:00'),
      []
    );

    const { data } = useGetPatientEventsCount({
      enabled: !isEmpty(patientUid) && !isEmpty(startTime),
      refetchInterval: 5000,
      cacheTime: 0,
      staleTime: 0,
      variables: {
        uid: patientUid,
        createdOnGte: startTime,
      },
    });

    const totalCount = useMemo(() => {
      return get(data, 'patientEvents.totalCount', 0);
    }, [data]);

    useEffect(() => {
      if (totalCount > 0) {
        onClose();
      }
    }, [totalCount, onClose]);

    return (
      <Alert borderRadius="8px" colorScheme="lightestShade">
        <Spinner
          mr={4}
          thickness="4px"
          speed="0.8s"
          emptyColor="blue.200"
          color="blue.400"
          size="md"
        />
        <Flex flex={1}>
          <AlertDescription>
            <Flex fontSize={14}>
              <Text color={Colors.darkestShade}>
                Appointment scheduling in progress. To see updated bookings,
                please{' '}
              </Text>
              <Text
                ml={1}
                cursor="pointer"
                onClick={onClose}
                textColor={Colors.primary}
              >
                refresh
              </Text>
            </Flex>
          </AlertDescription>
        </Flex>
        <Flex alignItems="center">
          <Text color={Colors.darkestShade} fontSize="12px">
            {moment().format('DD MMM YYYY, h:mm A')}{' '}
          </Text>
          <CloseButton
            color="gray.500"
            ml={3}
            alignSelf="flex-start"
            right={0}
            onClick={onClose}
          />
        </Flex>
      </Alert>
    );
  }
);
