/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

export const EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT = gql`
  query cleoSubmissionsExport(
    $patientForm_Patient_Uid_In: [String]
    $outputFormat: ExportOutputFormat!
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
  ) {
    cleoSubmissions(
      patientForm_Patient_Uid_In: $patientForm_Patient_Uid_In
      afterDateTime: $afterDateTime
      beforeDateTime: $beforeDateTime
    ) {
      totalCount
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`;

export const EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT_EMAIL = gql`
  query cleoSubmissionsExport(
    $patientForm_Patient_Uid_In: [String]
    $email: String
    $outputFormat: ExportOutputFormat!
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
  ) {
    cleoSubmissions(
      patientForm_Patient_Uid_In: $patientForm_Patient_Uid_In
      afterDateTime: $afterDateTime
      beforeDateTime: $beforeDateTime
    ) {
      totalCount
      exportByEmail(outputFormat: $outputFormat, email: $email)
    }
  }
`;

export default createQuery({
  gqlDocument: EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'submissionsPatientExport',
  select: (data) => get(data, 'cleoSubmissions'),
  keepPreviousData: true,
});
