import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import React, { memo, ReactNode } from 'react';

interface ModalViewProps extends Omit<ModalProps, 'children' | 'isOpen'> {
  header?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
}

export const ModalView = memo<ModalViewProps>(
  ({ header, body, footer, ...rest }) => {
    return (
      <Modal isOpen isCentered {...rest}>
        <ModalOverlay />

        <ModalContent>
          {header && (
            <>
              <ModalHeader>{header}</ModalHeader>
              <ModalCloseButton pt={2} />
              <Divider orientation="horizontal" />
            </>
          )}
          <ModalBody>{body}</ModalBody>
          {footer && (
            <>
              <Divider orientation="horizontal" />
              <ModalFooter>{footer}</ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  }
);
