import { isFunction } from 'lodash';
import React from 'react';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import {
  FAIcon,
  Flex,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero';
import { useModal } from 'context/ModalContext';
import exportToCSV from 'lib/csvExport';
import ExportDataModal from './ExportDataModal';

export const defaultExportDataSettings = (t, patient) => ({
  dataOptions: [
    {
      value: 'patients',
      label: t('All patient data'),
    },
    {
      value: 'submissions',
      label: t('All submission data'),
    },
    {
      value: 'messages',
      label: t('All message logs'),
    },
  ],
  dataFormats: [
    {
      value: 'csv',
      label: t('Export as CSV'),
    },
    {
      value: 'pdf',
      label: t('Export as PDF'),
    },
  ],

  dataOptionsGraph: [
    { label: t('Export graphs'), value: 'graph' },
    { label: t('Export table'), value: 'table' },
  ],

  modalTitle: {
    submissions: t(`Download {name} submissions`).replace(
      '{name}',
      patient?.name ?? ''
    ),
    messages: t(`Download messages`),
    patientList: t('Export patient list'),
    downloadData: t('Download data'),
  },
});

/**
 *
 * @param params contains modal data parameters
 * {
 *       mapping,
 *       fetchData,
 *       downloadUrl,
 *       patient,
 *       onSubmitCallback,
 *       filename,
 *       outputType,
 *       exportDestination,
 *       onComplete,
 *     }
 * @returns {Promise<unknown>}
 */
export const exportDataHandler = async (params) => {
  const { closeModal, sessionToken } = params;
  const handleExportCSV = (file, dt, map, onComplete) => {
    exportToCSV(file, dt, map);
    closeModal();
    if (onComplete) {
      onComplete();
    }
  };

  const getExportData = (fetchFn, cb) => {
    fetchFn().then((res) => {
      const { hasNextPage, data: resData } = res;
      if (hasNextPage) {
        setTimeout(() => getExportData(fetchFn, cb), 50);
        return;
      }
      const results = resData?.pages || resData;
      // const exportData = select ? select(results) : results;
      cb(results);
    });
  };

  return new Promise((resolve) => {
    const {
      mapping,
      fetchData,
      downloadUrl,
      onSubmitCallback,
      filename: exportFileName,
      onComplete,
    } = params;
    if (onSubmitCallback && isFunction(onSubmitCallback)) {
      onSubmitCallback(params);
      closeModal();
      return;
    }

    if (downloadUrl) {
      // eslint-disable-next-line
      window.location.href = downloadUrl.replace('${session}', sessionToken);
      closeModal();
      return;
    }

    getExportData(fetchData, (res) => {
      const csvData = res;
      resolve();
      setTimeout(() => {
        handleExportCSV(
          exportFileName || params?.filename,
          csvData,
          mapping,
          closeModal,
          onComplete
        );
      }, 750);
    });
  });
};

/**
 * Open export data modal for data types ofpatient submissions, submissions,
 * graphs/tables and messages .
 * @param openModal
 * @param closeModal
 * @param modalParams
 * @param dataFormats
 * @param dataType
 * @param props
 * @returns {*}
 */
export const openExportDataModal = ({
  openModal,
  closeModal,
  modalParams,
  dataFormats,
  dataType,
  ...props
}) => {
  const {
    title,
    text,
    showBody,
    showExportDataFormats,
    showExportDestination,
    prompt = true,
    defaultDestination,
    showEmailDestination,
    showDownloadDestination,
  } = modalParams;

  return openModal(
    <ExportDataModal
      title={title}
      text={text}
      closeModal={closeModal}
      data={modalParams}
      onSubmit={exportDataHandler}
      prompt={prompt}
      showBody={showBody}
      showExportDataFormats={showExportDataFormats}
      showExportDestination={showExportDestination}
      dataFormats={dataFormats}
      dataType={dataType}
      defaultDestination={defaultDestination}
      showEmailDestination={showEmailDestination}
      showDownloadDestination={showDownloadDestination}
      {...props}
    />,
    {
      ...(modalParams.styleOverrides || {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }),
    }
  );
};

/**
 * Export data component with default  button trigger element.
 * @param modalParams
 * @param triggerElementLabel
 * @param dataType
 * @param dataFormats
 * @param renderTriggerElement
 * @param dataOptions
 * @param isDisabled
 * @param props
 * @returns {*}
 * @constructor
 */
const ExportData = ({
  modalParams,
  triggerElementLabel = 'Download data',
  dataType,
  dataFormats,
  renderTriggerElement = null,
  dataOptions = null,
  isDisabled,
  ...props
}) => {
  const { openModal, closeModal } = useModal();

  const handleTriggerElementDefault = () => {
    const exportParams = {
      openModal,
      closeModal,
      modalParams,
      dataFormats,
      dataOptions,
      dataType,
      ...props,
    };
    openExportDataModal(exportParams);
  };

  const renderTriggerElementDefault = () => (
    <SecondaryOutlinedButton
      borderColor="#A3A9B1"
      borderRadius={8}
      pt={2}
      pb={2}
      mt={[2, 0, 0]}
      height={40}
      onClick={handleTriggerElementDefault}
      disabled={isDisabled}
      data-testid="export_data_trigger_element_btn_default"
      id="export_data_trigger_element_btn_default"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={14} fontWeight={600} color="darkestShade" mr={1}>
          {triggerElementLabel}
        </Text>
        <FAIcon fontSize={16} icon={faArrowToBottom} />
      </Flex>
    </SecondaryOutlinedButton>
  );

  return renderTriggerElement
    ? renderTriggerElement()
    : renderTriggerElementDefault();
};

export default ExportData;
