import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { formatISO, sub } from 'date-fns';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Body,
  H1,
  Tabs,
  Tab,
  TabPanel,
  useApi,
  Badge,
  useAuth,
} from '@fivehealth/botero';

import Config from 'Config';

import { DataType } from 'constants';
import EVENTS from 'constants/events';
import { useModal } from 'context/ModalContext';

import { getPatientSubmissions } from 'AppUtils';
import TabWithFilter from 'components/Tab/TabWithFilter';
import useMonitoringFormsData from 'hooks/useMonitoringFormsData';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import moment from 'moment';
import { useDebounce } from 'ahooks';
import {
  defaultExportDataSettings,
  openExportDataModal,
} from '../../components/ExportData';

import SidePanel from './Sidepanel';
import AlertSection from './AlertSection';
import NonCompliantSection from './NonCompliantSection';
import UnreadCommentSection from './UnreadCommentSection';
import AllSubmissionsSection from './AllSubmissionsSection';

import { useAppData } from '../../context/AppDataContext';
import MessageTemplateModal from '../PatientsList/PatientMessageTemplate.modal';
import { EXPORT_DATA_TYPE } from '../../constants';
import Forms from './Forms';

/* eslint no-template-curly-in-string: 0 */
const PatientMonitoring = ({ activeTab: defaultActiveTab = 0 }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();
  const { monitoringForms } = useMonitoringFormsData(null, {});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState();
  const [selectedSubmission, setSelectedSubmission] = useState();
  const [sidePanelPatientFormUid, setSidePanelPatientFormUid] = useState();

  const tabRef = useRef();

  const startDateRef = useRef(
    moment(moment().format('YYYY-MM-DDTHH:00:00')).toDate()
  );
  const nonComplaintSince = useRef(
    formatISO(sub(startDateRef?.current, { days: 1 }))
  );

  const unreadCommentsTabRef = useRef(null);
  const nonCompliantTabRef = useRef(null);
  const alertsTabRef = useRef(null);
  const submissionsTabRef = useRef(null);
  const allTabRef = useRef(null);
  const { client } = useApi();

  const { authState } = useAuth();

  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];

  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const { user } = useAppData();

  const {
    queries: {
      useSubmissionsCount,
      usePatientFormsCount,
      useClinic,
      useCurrentUser,
      useSubmissionLastSeenUpdateAll,
      useSubmissionsExportedFormats,
      useTriggeredAlertsResolveAll,
      useSubmissionsUnresolvedCount,
    },
  } = useApi({
    queries: [
      'useSubmissionsCount',
      'usePatientFormsCount',
      'useClinic',
      'useCurrentUser',
      'useSubmissionLastSeenUpdateAll',
      'useSubmissionsExportedFormats',
      'useTriggeredAlertsResolveAll',
      'useSubmissionsUnresolvedCount',
    ],
  });

  const { data: clinician } = useCurrentUser({
    enabled: !user?.isPatientFacing,
  });

  const { data: clinic } = useClinic({
    enabled: authState?.authenticated,
    staleTime: Infinity,
  });

  // NB: There are reasons for the debounce. Mitigating the issue of multiple requests being sent on the backend causing 504s
  const { data: alertsCount, refetch: refetchAlertCount } =
    useSubmissionsUnresolvedCount({});

  const [enableNonCGql, setEnableNonCGql] = useState(false);

  const enableNonCGqlDebounce = useDebounce(enableNonCGql, {
    wait: 1000,
  });

  useEffect(() => {
    if (!enableNonCGqlDebounce) setEnableNonCGql(true);
  }, [enableNonCGqlDebounce]);

  const [enableAllGql, setEnableAllGql] = useState(false);

  const enableAllGqlDebounce = useDebounce(enableAllGql, {
    wait: 3000,
  });

  useEffect(() => {
    if (!enableAllGqlDebounce) setEnableAllGql(true);
  }, [enableAllGqlDebounce]);

  const {
    data: allActivePatientFormsCount,
    refetch: refetchAllActivePatientFormsCount,
  } = usePatientFormsCount({
    enabled: enableAllGqlDebounce,
    variables: { activated: true },
  });

  const {
    data: nonCompliantPatientCount,
    refetch: refetchNonCompliancePatientCount,
  } = usePatientFormsCount({
    enabled: enableNonCGqlDebounce,
    variables: {
      activated: true,
      nonCompliantSince: formatISO(sub(startDateRef?.current, { days: 1 })),
    },
  });

  const { data: unreadCommentsCount, refetch: refetchUnreadCommentsCount } =
    useSubmissionsCount({
      variables: {
        hasUnseenCommentsAndVisibleToEverybody: true,
      },
    });
  const { data: submissionsCount, refetch: refetchSubmissionsCount } =
    useSubmissionsCount({
      variables: {
        // orderField: 'SUBMITTED_ON',
        // orderDesc: true,
      },
    });

  const { mutateAsync: markAllCommentRead } = useSubmissionLastSeenUpdateAll({
    variables: {},
    onSuccess: refetchUnreadCommentsCount,
  });

  const { data: exportDataFormatsSubmissions } = useSubmissionsExportedFormats({
    enabled: true,
  });

  const { mutateAsync: updateTriggeredAlertsResolveAll, isLoading } =
    useTriggeredAlertsResolveAll({
      variables: {},
    });

  useEffect(() => {
    if (isMobile) {
      const transitionDelay = (refObj) => {
        setTimeout(() => {
          refObj.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }, 1000);
      };
      if (unreadCommentsTabRef && activeTab === 3) {
        transitionDelay(unreadCommentsTabRef);
      } else if (nonCompliantTabRef && activeTab === 2) {
        transitionDelay(nonCompliantTabRef);
      } else if (alertsTabRef && activeTab === 1) {
        transitionDelay(alertsTabRef);
      } else if (submissionsTabRef && activeTab === 5) {
        transitionDelay(submissionsTabRef);
      } else if (allTabRef && activeTab === 0) {
        transitionDelay(allTabRef);
      }
    }
  }, [activeTab, isMobile]);

  const onChangeTab = (index) => {
    const mapping = {
      0: '/patient-monitoring',
      2: '/patient-monitoring/alerts',
      3: '/patient-monitoring/non-compliant',
      4: '/patient-monitoring/unread-comments',
    };
    switch (index) {
      case 0:
      case 3: {
        queryClient.invalidateQueries('patients');
        queryClient.invalidateQueries('patientForms');
        break;
      }
      case 1:
      case 2:
      case 4:
        queryClient.invalidateQueries('submissions');
        break;

      default: {
        break;
      }
    }

    history.push(mapping[index] || '/patient-monitoring');
    setActiveTab(index);
  };

  const onSetTabRef = (ref) => {
    tabRef.current = ref;
  };

  const onMarkAllCommentsRead = async () => {
    markAllCommentRead({ input: {} });

    // NOTE: Delays the the invalidation of submission to rerender the table properly
    setTimeout(() => {
      queryClient.invalidateQueries('submissions');
      queryClient.invalidateQueries('submissionsCount');
    }, 1000);
  };

  const onMarkAllAsResolved = async (message) => {
    const param = {
      input: {
        resolvingCommentContent: message,
      },
    };
    await updateTriggeredAlertsResolveAll(param);
    setTimeout(() => {
      queryClient.invalidateQueries('submissions');
      queryClient.invalidateQueries('submissionsCount');
    }, 500);
  };

  const onShowSubmission = (uid, cellData) => {
    if (cellData || uid) {
      if (uid) {
        setSelectedSubmissionId(uid);
      }

      if (cellData) {
        setSelectedSubmission(cellData);
      }

      if (cellData && cellData?.patientForm) {
        // cellData is submission type
        setSidePanelPatientFormUid(cellData?.patientForm?.uid);
      } else if (cellData && cellData?.uid) {
        // cellData is patientForm type
        setSidePanelPatientFormUid(cellData?.uid);
      }

      return setDrawerOpen(true);
    }
    return null;
  };

  const onShowPatientForm = (patientForm, path = '') => {
    if (!patientForm || !patientForm?.uid) {
      return;
    }
    history.push(`/patient-list/${patientForm?.patient?.uid}/${path}`);
  };

  const { sendMessage } = useSendMessageAsync();

  const onSubmitMessageCreate = async ({
    template,
    patientForm,
    userVariables,
    patientEventUid,
  }) => {
    const templateId = get(template, 'uid');
    const payload = {
      patientFormUids: [patientForm?.uid],
      messageTemplateSetUid: templateId,
      userVariables,
    };

    if (patientEventUid) {
      payload.patientEventUid = patientEventUid;
    }

    const result = await sendMessage(payload);
    return result;
  };

  const onSendMessage = (patientForm) => {
    openModal(
      <MessageTemplateModal
        patientForm={patientForm}
        closeModal={closeModal}
        onSubmit={onSubmitMessageCreate}
        isPatientsView
      />,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }
    );
  };

  const invokePatientSubmissionExport = async ({
    patient,
    outputType,
    exportDestination,
  }) => {
    const { cleoSubmissions } = await getPatientSubmissions(
      queryClient,
      client,
      [patient?.uid],
      exportDestination?.email,
      outputType
    );

    if (!exportDestination?.email) {
      window.open(
        cleoSubmissions?.exportByUrl?.replace('${session}', token),
        '_blank'
      );
    }
  };

  const onDownloadPatientSubmissions = (patient) => {
    if (!patient || !patient?.uid) {
      return;
    }
    const exportModalTitle = defaultExportDataSettings(
      t,
      patient?.patient
    ).modalTitle;

    openExportDataModal({
      modalParams: {
        title: exportModalTitle.submissions,
        showBody: true,
        showExportDataFormats: true,
        showExportDestination: true,
        patient: patient?.patient,
        onSubmitCallback: invokePatientSubmissionExport,
      },
      dataType: EXPORT_DATA_TYPE.submissions,
      dataFormats: exportDataFormatsSubmissions,
      openModal,
      closeModal,
      logEventProps: {
        page: 'Patient Monitoring',
        eventName: `${EVENTS.EXPORT_PATIENT_MONITORING} - Download submission for ${patient?.patient?.name}`,
      },
    });
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedSubmissionId(null);
    setSelectedSubmission(null);
  };

  const renderBadge = (text, tabName) => (
    <Badge
      fontWeight={600}
      fontSize={12}
      color="fullShade"
      ml={1}
      borderRadius={8}
      backgroundColor="#E8EAED"
      height={24}
      alignItems="center"
      justifyContent="center"
      data-testid={`tab_badge_count:${tabName}:${text}`}
    >
      {text?.toLocaleString()}
    </Badge>
  );

  return (
    <Box py={4}>
      <H1 data-testid="patient_monitoring">{t('Patient Monitoring')}</H1>
      <Body pt={2}>
        {t(
          'View all your patient submissions, alerts and non-compliant patients. Click a row to see more details.'
        )}
      </Body>
      <Box mt={4}>
        <Tabs
          panelProps={{ mt: 0 }}
          activeTabIndex={activeTab}
          onChange={onChangeTab}
          headerProps={{
            overflowX: 'auto',
            width: '100%',
            className: 'scrollbar-invisible',
            style: {
              whiteSpace: 'nowrap',
            },
          }}
        >
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              eventName: EVENTS.LIST_ALL_ACTIVE_PATIENTS,
            }}
            data-testid="patient_monitoring_tab_all"
            style={{ flexShrink: 0 }}
          >
            <div ref={allTabRef}>
              {t(clinic?.allPatientFormsTableSettings?.title || 'All')}
              {renderBadge(
                allActivePatientFormsCount,
                'patient_monitoring_tab_all'
              )}
            </div>
          </Tab>
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              subSource: 'Monitoring all submissions',
            }}
            data-testid="patient_monitoring_all_submissions"
            style={{ flexShrink: 0 }}
          >
            <div ref={submissionsTabRef}>
              {t('Submissions')}
              {renderBadge(submissionsCount, 'patient_monitoring_submissions')}
            </div>
          </Tab>
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              eventName: EVENTS.LIST_UNRESOLVED_ALERTS,
              subSource: 'Patient Monitoring',
            }}
            data-testid="patient_monitoring_unresolved_alerts"
            style={{ flexShrink: 0 }}
          >
            <div ref={alertsTabRef}>
              {t(
                clinic?.unresolvedAlertsPatientFormsTableSettings?.title ||
                  'Unresolved Alerts'
              )}{' '}
              {renderBadge(alertsCount, 'patient_monitoring_unresolved_alerts')}
            </div>
          </Tab>
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              eventName: EVENTS.LIST_NON_COMPLIANT_PATIENTS,
            }}
            data-testid="patient_monitoring_non_compliant"
            style={{ flexShrink: 0 }}
          >
            <div ref={nonCompliantTabRef}>
              <span>
                {t(
                  clinic?.nonCompliantPatientFormsTableSettings?.title ||
                    'Non-Compliant'
                )}
              </span>
              {renderBadge(
                nonCompliantPatientCount,
                'patient_monitoring_non_compliant'
              )}
            </div>
          </Tab>
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              eventName: EVENTS.LIST_UNREAD_COMMENTS_PATIENTS,
            }}
            data-testid="patient_monitoring_unread_comments"
            style={{ flexShrink: 0 }}
          >
            <div ref={unreadCommentsTabRef}>
              <span data-testid="patient_monitoring_unread_comments_tab_title">
                {t(
                  clinic?.settings?.unread_comments_patient_forms_table_settings
                    ?.title || 'Unread comments'
                )}
              </span>
              {renderBadge(
                unreadCommentsCount,
                'patient_monitoring_unread_comments'
              )}
            </div>
          </Tab>
          <Tab
            logEventProps={{
              page: 'Patient Monitoring',
              subSource: 'Monitoring forms',
            }}
            data-testid="patient_monitoring_forms"
            style={{ flexShrink: 0 }}
          >
            <div ref={alertsTabRef}>
              {t('Forms')}
              {renderBadge(monitoringForms?.length, 'patient_monitoring_forms')}
            </div>
          </Tab>

          <TabPanel>
            {activeTab === 0 && (
              <TabWithFilter
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.PatientForm}
                defaultGQLVariable={{
                  activated: true,
                  first: 10,
                }}
                isPatientMonitoring
                onCommentCreated={tabRef.current?.onRefresh}
                onDownloadPatientSubmissions={onDownloadPatientSubmissions}
                onRefresh={refetchAllActivePatientFormsCount}
                onSendMessage={onSendMessage}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                openDrawer={() => setDrawerOpen(true)}
                tableSettings={clinic?.allPatientFormsTableSettings}
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
                tabRef={onSetTabRef}
              >
                <AllSubmissionsSection />
              </TabWithFilter>
            )}
          </TabPanel>
          <TabPanel>
            {activeTab === 1 && (
              <TabWithFilter
                alertsCount={alertsCount}
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.SubmissionUnresolved}
                defaultGQLVariable={{
                  orderField: 'SUBMITTED_ON',
                  orderDesc: true,
                  // patientformdeactivateonisnull: true,
                }}
                onCommentCreated={tabRef.current?.onRefresh}
                onDownloadPatientSubmissions={onDownloadPatientSubmissions}
                onMarkAllAsResolved={onMarkAllAsResolved}
                onRefresh={refetchSubmissionsCount}
                onSendMessage={onSendMessage}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                openDrawer={() => setDrawerOpen(true)}
                tableSettings={
                  clinic?.unresolvedAlertsPatientFormsTableSettings
                }
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
                tabRef={onSetTabRef}
              >
                <AlertSection />
              </TabWithFilter>
            )}
          </TabPanel>
          <TabPanel>
            {activeTab === 2 && (
              <TabWithFilter
                alertsCount={alertsCount}
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.SubmissionUnresolved}
                defaultGQLVariable={{
                  isAlertsResolved: false,
                  orderField: 'SUBMITTED_ON',
                }}
                isLoading={isLoading} // Redundant ?
                onCommentCreated={tabRef.current?.onRefresh}
                onDownloadPatientSubmissions={onDownloadPatientSubmissions}
                onMarkAllAsResolved={onMarkAllAsResolved}
                onRefresh={refetchAlertCount}
                onSendMessage={onSendMessage}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                openDrawer={() => setDrawerOpen(true)}
                tableSettings={
                  clinic?.unresolvedAlertsPatientFormsTableSettings
                }
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
                tabRef={onSetTabRef}
              >
                <AlertSection />
              </TabWithFilter>
            )}
          </TabPanel>
          <TabPanel>
            {activeTab === 3 && (
              <TabWithFilter
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.PatientForm}
                defaultGQLVariable={{
                  activated: true,
                  nonCompliantSince: nonComplaintSince.current,
                }}
                onCommentCreated={tabRef.current?.onRefresh}
                onDownloadPatientSubmissions={onDownloadPatientSubmissions}
                onRefresh={refetchNonCompliancePatientCount}
                onSendMessage={onSendMessage}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                openDrawer={() => setDrawerOpen(true)}
                tableSettings={clinic?.nonCompliantPatientFormsTableSettings}
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
                tabRef={onSetTabRef}
              >
                <NonCompliantSection />
              </TabWithFilter>
            )}
          </TabPanel>
          <TabPanel>
            {activeTab === 4 && (
              <TabWithFilter
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.Submission}
                defaultGQLVariable={{
                  hasUnseenCommentsAndVisibleToEverybody: true,
                  // NOTE: CARE-1364: Commented out below as we dont need this params as per requirement
                  // hasUnseenComments: true,
                  // hasCommentVisibleTo: COMMENT_VISIBILITY.public,
                }}
                onCommentCreated={tabRef.current?.onRefresh}
                onDownloadPatientSubmissions={onDownloadPatientSubmissions}
                onMarkAllCommentsRead={onMarkAllCommentsRead}
                onRefresh={refetchUnreadCommentsCount}
                onSendMessage={onSendMessage}
                onShowPatientForm={onShowPatientForm}
                onShowSubmission={onShowSubmission}
                openDrawer={() => setDrawerOpen(true)}
                tableSettings={
                  clinic?.settings
                    ?.unread_comments_patient_forms_table_settings ||
                  clinic?.unresolvedAlertsPatientFormsTableSettings
                }
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
                tabRef={onSetTabRef}
                unreadCommentsCount={unreadCommentsCount}
              >
                <UnreadCommentSection />
              </TabWithFilter>
            )}
          </TabPanel>
          <TabPanel>
            <Forms />
          </TabPanel>
        </Tabs>
      </Box>
      {drawerOpen && (
        <SidePanel
          key={selectedSubmissionId}
          submissionUid={selectedSubmissionId}
          selectedSubmission={selectedSubmission}
          patientFormUid={sidePanelPatientFormUid}
          open={drawerOpen}
          onShowPatientForm={onShowPatientForm}
          onClose={onCloseDrawer}
          onSendMessage={onSendMessage}
          clinician={clinician}
          onRefresh={tabRef.current?.onRefresh}
        />
      )}
    </Box>
  );
};

export default PatientMonitoring;
