import React, { useState } from 'react';
import { format, parseISO, compareDesc } from 'date-fns';
import { get, orderBy, toLower } from 'lodash';
import i18n from 'i18n';
import { getChartLegendOptions, tableConfigUtils } from 'AppUtils';
import { Text, Flex, Box, H2, theme } from '@fivehealth/botero';
import Table from 'components/Table/Table';
import ChartDropdown from 'components/Charts/ChartDropdown';
import { useTranslation } from 'react-i18next';
import { ChartTypes } from '../../../constants';
import { ChartDataAverages, filterChartData } from './PatientCharts';

const DateCell = ({ value }) => (
  <Text fontWeight={600} color="fullShade" fontSize={14}>
    {format(value, 'dd MMM yyyy HH:mm')}
  </Text>
);

const ValueCell = ({ value }) => (
  <Box>
    <Text fonSize={14} color="fullShade" fontWeight={500}>
      {value}
    </Text>
  </Box>
);

const PatientTables = ({
  clinic,
  currentDateRange,
  prevPeriodData,
  nextPeriodData,
  grandAverageData,
  chartSetting,
  parsedChartData,
}) => {
  const onFetchData = () => {};
  const { t } = useTranslation();

  const combineClinicalParametersData =
    chartSetting?.combineClinicalParametersData || false;
  const [chartFilters, setChartFilters] = useState({});

  const chartLegendOptions = getChartLegendOptions(chartSetting, t);

  let defaultColumns = [
    {
      id: 'timestamp',
      Header: t('Date'),
      accessor: 'timestamp',
      Cell: DateCell,
      width: '20%',
      disableSortBy: true,
    },
    {
      id: 'value',
      Header: `${t(chartSetting?.title)} (${chartSetting?.unit})`,
      accessor: 'value',
      disableSortBy: true,
      Cell: ValueCell,
      width: '80%',
    },
  ];

  if (chartSetting?.tableViewColumns) {
    chartSetting?.tableViewColumns.forEach((configColumn) => {
      /**
       * Needed to pass them here to access it via parent.
       * was losing them data when passing via props on the component level.
       */
      configColumn.chartSetting = chartSetting;
      configColumn.t = t;
      return configColumn;
    });

    defaultColumns = [
      ...tableConfigUtils.mapCellRenderToConfig(
        {
          columns: chartSetting?.tableViewColumns,
        },
        clinic,
        t
        // 'YYYY-MM-DD'
      ),
    ];
  }

  if (toLower(chartSetting?.key) === 'bg') {
    /* eslint no-param-reassign: 0 */
    parsedChartData = filterChartData(parsedChartData, get(chartFilters, 'bg'));
  }

  let parsedTableData = [];

  if (toLower(chartSetting?.key) === 'blood pressure') {
    const [systolicChartData, diastolicChartData] = orderBy(
      parsedChartData,
      (data) => data.clinicalParameter.sortOrder,
      ['asc']
    );

    parsedTableData = get(systolicChartData, 'submissionTimes', [])
      .map((ts, i) => ({
        uid: `submission-${i}`,
        value: get(systolicChartData, `submissionValues[${i}]`),
        timestamp: parseISO(ts),
        siblingValue: get(diastolicChartData, `submissionValues[${i}]`),
      }))
      .sort((a, b) => compareDesc(a.timestamp, b.timestamp));
  } else if (toLower(chartSetting?.key) === 'bg') {
    parsedTableData = parsedChartData
      .flatMap((chartData) =>
        get(chartData, 'submissionTimes', []).map((ts, i) => ({
          uid: `submission-${i}`,
          timestamp: parseISO(ts),
          value: get(chartData, `submissionValues[${i}]`),
          timeTaken: get(chartData, `values[${i}].label`),
          data: chartData,
        }))
      )
      .sort((a, b) => compareDesc(a.timestamp, b.timestamp));
  } else {
    parsedTableData = parsedChartData
      .flatMap((chartData) =>
        get(chartData, 'submissionTimes', []).map((ts, i) => ({
          uid: `submission-${i}`,
          timestamp: parseISO(ts),
          value: get(chartData, `submissionValues[${i}]`),
        }))
      )
      .sort((a, b) => compareDesc(a.timestamp, b.timestamp));
  }

  const onSetChartFilter = (chartKey, filter) => {
    setChartFilters({
      ...chartFilters,
      [chartKey]: {
        ...get(chartFilters, chartKey, {}),
        ...filter,
      },
    });
  };

  return (
    <Box>
      {chartSetting?.chartType === ChartTypes.SingleLine && (
        <Box>
          <Flex justifyContent="space-between" mt={4} alignItems="center">
            <H2 fontSize={18}>{i18n.t(chartSetting?.title)}</H2>
            <Box display={['none', 'initial']}>{/* <FilterButton /> */}</Box>
          </Flex>

          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting?.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />
          <Table
            data={parsedTableData}
            columns={defaultColumns}
            noResultsProps={{ description: '' }}
            headerCellProps={{ style: { display: 'block' } }}
            onFetchData={() => {}}
            initialSortBy={{
              id: 'timestamp',
              desc: true,
            }}
            containerStyle={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
            tableStyle={{ borderCollapse: 'collapse' }}
          />
        </Box>
      )}
      {chartSetting.chartType === ChartTypes.Scatter && (
        <Box
          mt={8}
          pt={4}
          style={{ borderTop: `1px solid ${theme.colors.mediumShade}` }}
        >
          <Flex justifyContent="space-between" mt={4} alignItems="center">
            <H2 fontSize={18}>{t(chartSetting?.title)}</H2>
            <Box display={['none', 'none', 'initial']}>
              {combineClinicalParametersData && (
                <ChartDropdown
                  defaultLabel={t('Filters')}
                  onChange={(val, label) =>
                    onSetChartFilter(toLower(chartSetting?.key), {
                      [label]: val[label],
                    })
                  }
                  optionsTitle={t('Data labels')}
                  checkboxes={[
                    combineClinicalParametersData && {
                      label: t('Readings'),
                      options: chartLegendOptions.map(
                        ({ id, label, icon }) => ({
                          id,
                          checked: !!get(
                            chartFilters,
                            `${toLower(chartSetting?.key)}.${id}`,
                            false
                          ),
                          // TODO: Refactor this to avoid react/no-unstable-nested-components error
                          /* eslint-disable react/no-unstable-nested-components */
                          label: () => (
                            <Flex>
                              {icon}
                              <Text ml={1} fontSize={14}>
                                {t(label)}
                              </Text>
                            </Flex>
                          ),
                        })
                      ),
                    },
                  ].filter(Boolean)}
                />
              )}
            </Box>
          </Flex>
          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting?.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />
          <Table
            combineClinicalParametersData={combineClinicalParametersData}
            manualSorting={chartSetting?.manualSorting}
            data={parsedTableData}
            columns={defaultColumns}
            headerCellProps={{}}
            onFetchData={onFetchData}
            initialSortBy={{
              id: 'date',
              desc: true,
            }}
            containerStyle={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
            tableStyle={{ borderCollapse: 'collapse' }}
            noResultsProps={{ description: '' }}
            getHeaderProps={
              !combineClinicalParametersData
                ? () => ({})
                : (props = {}) => ({
                    ...props,
                    opacity: 1,
                    padding: 0,
                    bg: props.bgColor,
                    width: /placeholder/i.test(props?.originalId)
                      ? '50px !important'
                      : props.width,
                  })
            }
            getHeaderGroupProps={
              !combineClinicalParametersData
                ? () => ({})
                : (props = {}) => ({
                    ...props,
                    style: {
                      borderTop: `1px solid ${theme.colors.mediumShade}`,
                    },
                  })
            }
            getColumnProps={
              !combineClinicalParametersData
                ? () => ({})
                : (props) => ({
                    ...props,
                    style: {
                      height: 80,
                      boxSizing: 'border-box',
                      backgroundColor: props.bgColor,
                      ...(props.style || {}),
                    },
                  })
            }
          />
        </Box>
      )}

      {chartSetting?.chartType === ChartTypes.MultiLine && (
        <Box
          mt={8}
          pt={4}
          style={{ borderTop: `1px solid ${theme.colors.mediumShade}` }}
        >
          <Flex justifyContent="space-between" mt={4} alignItems="center">
            <H2 fontSize={18}>{t(chartSetting?.title)}</H2>
            <Box display={['none', 'initial']}>{/* FilterButton */}</Box>
          </Flex>
          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting?.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />
          <Table
            data={parsedTableData}
            columns={defaultColumns}
            noResultsProps={{ description: '' }}
            headerCellProps={{ style: { display: 'block' } }}
            onFetchData={() => {}}
            initialSortBy={{
              id: 'date',
              desc: true,
            }}
            containerStyle={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
            tableStyle={{ borderCollapse: 'collapse' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default PatientTables;
