import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientEvents(
    $patientUidIn: [String]
    $createdOnGte: DateTime
    $isUpcoming: Boolean
    $sortBy: PatientEventOrderBy
    $first: Int
  ) {
    cleoPatientEvents(
      sortBy: $sortBy
      sortDesc: false
      patient_Uid_In: $patientUidIn
      createdOn_Gte: $createdOnGte
      isUpcoming: $isUpcoming
      first: $first
    ) {
      totalCount
      edges {
        node {
          uid
          eventUrl
          cancelUrl
          rescheduleUrl
          status
          patient {
            clinic {
              name
            }
          }
          event {
            uid
            name
            eventType {
              uid
              name
            }
          }
          startOn
          isConfirmed
          metadataEntries {
            uid
            parameter {
              uid
              name
              valueType
            }
            value
            extractedForDisplay
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientEvents',
  keepPreviousData: true,
  select: ({ cleoPatientEvents }) =>
    cleoPatientEvents?.edges?.map(({ node }) => node),
});
