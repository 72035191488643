/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Box, Flex, Text } from '@chakra-ui/react';
import { FilterNew } from 'components/Filter/FilterNew';
import { GqlQueryParam } from 'components/Tab/TabWithFilter';
import Colors from 'constants/colors';
import { List } from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from './FilterButton';

export interface FilterOptionData {
  id: string;
  label: string;
  toParams: {
    [key: string]:
      | string
      | number
      | boolean
      | string[]
      | number[]
      | boolean[]
      | List<any>;
  };
}

export interface FilterOption {
  id: string;
  title: string;
  multiSelect?: boolean;
  data: FilterOptionData[];
}

export interface FiltersProps {
  filterOptions: FilterOption[];
  onFilterChange: (v: any) => void;
  requestParams?: {
    [key: string]: string | number | boolean | object | null | undefined;
  };
}

export interface FiltersHandles {
  clearFilters: () => void;
  getFilters: () => GqlQueryParam;
  getFiltersCount: () => number;
}

export const Filters = forwardRef<FiltersHandles, FiltersProps>(
  ({ filterOptions, requestParams, onFilterChange }, ref) => {
    const [filters, setFilters] = useState<GqlQueryParam>({});

    const filtersRef = useRef({});

    const { t } = useTranslation();

    const [advancedFiltersCount, _setAdvancedFiltersCount] = useState(0);

    useEffect(() => {
      onFilterChange(filters);
    }, [filters, onFilterChange]);

    const filtersCount = useCallback(() => {
      let count = 0;
      Object.keys(filtersRef.current).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        count += filtersRef.current?.[key]?.getSelectedFiltersCount() ?? 0;
      });
      return count;
    }, []);

    const clearAllFilters = () => {
      if (filtersCount() === 0) return;

      Object.keys(filtersRef.current).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filtersRef.current?.[key]?.resetFilters();
      });
      setFilters({});
      onFilterChange({});

      // Updated with botero-ts version of clear all filters
      /* const resettedFilters = { ...filters };
      Object.keys(filtersRef.current).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filtersRef.current?.[key]?.resetFilters();
      });
      const additionalFiltersKeys = new Set();
      filterOptions.forEach((filterOption) => {
        const gqlKey = Object.keys(
          filterOption?.data?.[0]?.toParams || {}
        )[0] as string;
        if (!additionalFiltersKeys.has(gqlKey)) {
          additionalFiltersKeys.add(gqlKey);
        }
      });
      Array.from(additionalFiltersKeys).forEach((k) => {
        delete resettedFilters[k as string];
      });
      setFilters(resettedFilters);
      onFilterChange(resettedFilters); */
    };

    useImperativeHandle(ref, () => ({
      clearFilters: clearAllFilters,
      getFilters: () => {
        return filters;
      },
      getFiltersCount: () => {
        return filtersCount();
      },
    }));

    return (
      <Flex alignItems="center">
        {filterOptions.map((filterOption) => {
          return (
            <Box key={filterOption.id}>
              <FilterNew
                filters={filters}
                searchEnabled={filterOption?.data?.length > 10}
                ref={(el) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  filtersRef.current[filterOption.id] = el;
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                activeFilters={requestParams}
                filterOption={filterOption}
                onSave={(nextFilterState: any) => {
                  setFilters(nextFilterState);
                }}
              />
            </Box>
          );
        })}

        <Box
          cursor="pointer"
          borderLeftWidth={2}
          pl={3}
          borderLeftColor={Colors.mediumShade}
        >
          <FilterButton
            label={t('Advanced')}
            textStyle={{ color: Colors.mediumShade }}
            onClick={() => {
              // eslint-disable-next-line no-alert
              alert('Advanced filters coming soon...');
            }}
            badgeCount={advancedFiltersCount}
          />
        </Box>

        <Box cursor="pointer" onClick={clearAllFilters}>
          <Text color={Colors.primary} fontWeight="600" fontSize={14}>
            {t('Clear All')}
          </Text>
        </Box>
      </Flex>
    );
  }
);
