import {
  Box,
  BoxWidget,
  Text,
  theme,
  DataTable,
  Body,
} from '@fivehealth/botero';
import TableLoader from 'components/Table/TableLoader';
import useMonitoringFormsData from 'hooks/useMonitoringFormsData';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const MobileCountView = ({ label, data }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Body fontSize={14}>{label}</Body>
      <Text fontSize={14} color={theme.colors.primary}>
        {data}
      </Text>
    </Box>
  );
};

const Forms = () => {
  const { monitoringForms } = useMonitoringFormsData([], {});
  const { t } = useTranslation();

  const tableData = _.sortBy(monitoringForms, 'label').map((d) => {
    return {
      ...d,
      uid: d.id,
    };
  });

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'label',
        Header: t('Form Name'),
        accessor: 'label',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => {
          return (
            <Box onClick={() => openLink(row.original.url)}>
              <Text style={{ cursor: 'pointer' }} color="primary">
                {row.original.label}
              </Text>
            </Box>
          );
        },
        disableSortBy: true,
      },
      {
        id: 'patientFormCount',
        Header: t('Patient Forms'),
        accessor: 'patientFormCount',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => {
          return <Text color="primary">{row.original.patientFormCount}</Text>;
        },
        disableSortBy: true,
      },
      {
        id: 'patientFormWithSubmissionCount',
        Header: t('Patient Forms with Submission'),
        accessor: 'patientFormWithSubmissionCount',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => {
          return (
            <Text color="primary">
              {row.original.patientFormWithSubmissionCount}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        id: 'patientFormWithoutSubmissionCount',
        Header: t('Patient Forms without Submission'),
        accessor: 'patientFormWithoutSubmissionCount',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => {
          return (
            <Text color="primary">
              {row.original.patientFormWithoutSubmissionCount}
            </Text>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [t]);

  const renderMobileTable = ({ data }) => {
    return (
      <BoxWidget
        onClick={() => openLink(data.url)}
        variant="outlined"
        mx={0}
        mb={16}
        borderColor="#D5D9DE"
        cursor="pointer"
      >
        <Text fontSize={16} mb={1} color={theme.colors.primary}>
          {data?.label}
        </Text>
        <MobileCountView
          label={t('Patients Enrolled')}
          data={data?.patientFormCount}
        />
        <MobileCountView
          label={t('Patients with Submissions')}
          data={data?.patientFormWithSubmissionCount}
        />
        <MobileCountView
          label={t('Patients without Submissions')}
          data={data?.patientFormWithoutSubmissionCount}
        />
      </BoxWidget>
    );
  };

  return (
    <>
      {!monitoringForms && <TableLoader />}
      {monitoringForms &&
        isMobile &&
        monitoringForms.map((form) => renderMobileTable({ data: form }))}

      {monitoringForms && !isMobile && (
        <DataTable
          data-testid="patient-monitoring-forms-table"
          columns={columns}
          data={tableData}
          initialSortBy={{
            id: 'label',
            desc: true,
          }}
        />
      )}
    </>
  );
};

export default Forms;
