import React, { useState } from 'react';
import {
  get,
  isEmpty,
  chain,
  mapValues,
  uniq,
  isEqual,
  merge,
  omit,
  isNumber,
} from 'lodash';
import { useMediaQuery } from 'react-responsive';

import {
  Box,
  Flex,
  H2,
  FAIcon,
  H4,
  H6,
  Body,
  Select,
  PrimaryButton,
  SecondaryOutlinedButton,
  InputField,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { useTranslation } from 'react-i18next';

const AlertColumn = ({ children, isMobile, ...props }) => {
  if (isMobile) {
    return <Box {...props}>{children}</Box>;
  }

  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      {children}
    </Flex>
  );
};

const EditAlertThresholdModal = ({ data, closeModal, onSubmit }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  // value is reserved for react-select component
  // effective value will the actual value used by gql query
  const thresholdlabelOptions = {
    equals: {
      label: t('Equals'),
      value: 'equals',
      effectiveValue: 'equals',
    },
    max: {
      label: t('Greater than'),
      value: 'max',
      effectiveValue: 'max',
    },
    inclusive_max: {
      label: t('Greater than or equal'),
      value: '>=max',
      effectiveValue: 'max',
      inclusive_max: true,
    },
    min: {
      label: t('Lesser than'),
      value: 'min',
      effectiveValue: 'min',
    },
    inclusive_min: {
      label: t('Lesser than or equal to'),
      value: '<=min',
      effectiveValue: 'min',
      inclusive_min: true,
    },
  };
  const isStringValue =
    get(data, 'equals.value', '') ||
    get(data, 'equals.value', '').toLowerCase().length > 0;

  const [thresholds, setThresholds] = useState(
    isStringValue
      ? {
          threshold1: {
            label: thresholdlabelOptions.equals,
            value: get(data, 'equals.value'),
          },
        }
      : {
          threshold1: {
            label: data.inclusive_max
              ? thresholdlabelOptions.inclusive_max
              : thresholdlabelOptions.max,
            value: `${data.max || ''}`,
          },
          threshold2: {
            label: data.inclusive_min
              ? thresholdlabelOptions.inclusive_min
              : thresholdlabelOptions.min,
            value: `${data.min || ''}`,
          },
        }
  );
  const [submitting, setSubmitting] = useState(false);
  const thresholdKeys = Object.keys(thresholds);
  const validateRequiredInput = thresholdKeys.some(
    (key) => !isEmpty(thresholds[key].value)
  );

  const boolSelectOptions = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
  ];

  const validateUniqueInput =
    uniq(thresholdKeys.map((key) => thresholds[key].label.value)).length ===
    thresholdKeys.length;

  const valid =
    !isEmpty(thresholdKeys) && validateRequiredInput && validateUniqueInput;

  const onChangeThreshold = (key, inputType, val) =>
    setThresholds(
      mapValues(thresholds, (v, k) => {
        if (isEqual(k, key)) {
          return {
            ...v,
            [inputType]: val,
          };
        }
        return v;
      })
    );

  const getThresholdValue = (label, value) => {
    if (isStringValue) {
      return { value };
    }

    // equal require a different format
    if (isEqual('equals', label)) {
      return { value: parseFloat(value) || null };
    }

    return parseFloat(value) || null;
  };

  const onHandleSubmit = async () => {
    if (submitting) {
      return;
    }

    // extract threshold value from state to gql
    const mappedThresholds = merge(
      ...chain(thresholds)
        .values()
        .filter(({ value }) => !isEmpty(value))
        .map(({ label, value }) => ({
          [label.effectiveValue]: getThresholdValue(
            label.effectiveValue,
            value
          ),
          ...omit(label, ['label', 'value', 'effectiveValue']), // additional key from threshold (eg. inclusive_max, inclusive_min)
        }))
        .value(),
      { clinical_parameter: data.clinical_parameter }
    );
    try {
      setSubmitting(true);
      await onSubmit(
        {
          ...mappedThresholds,
          parserIndex: data?.parserSettingsParam?.parserIndex, // NOTE: Use as a flag index indicator for parser settings
        },
        data
      ).finally(closeModal);
    } finally {
      // setSubmitting(false);
    }
  };

  const getSelectWidth = () => {
    if (isMobile) {
      return '100%';
    }
    if (isStringValue) {
      return '40%';
    }
    return '320px';
  };

  return (
    <Box p={1} style={{ boxSizing: 'border-box' }} width={[320, 640]}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>
          {t('Edit')} {data.clinical_parameter} {t('Alert')}
        </H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <H4 mt={6} mb={3}>
        {t('Alert Threshold')}
      </H4>
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <H6 mr="4px" color="darkestShade">
            {t('Alert')}
          </H6>
          <StatusIcon status="warning" fontSize="12px" />
        </Flex>
        <Body color="darkShade" extraSmall>
          ({t('Required')})
        </Body>
      </Flex>
      <AlertColumn isMobile={isMobile}>
        <Select
          width={getSelectWidth()}
          value={thresholds.threshold1.label}
          options={chain(thresholdlabelOptions)
            .values()
            .filter(
              // TODO: temporary fix to disable equal for numbers
              (option) =>
                !(!isStringValue && isEqual(option.effectiveValue, 'equals'))
            )
            .filter(
              // TODO: temporary fix to disable changing or alert type from range to bool
              (option) =>
                isStringValue
                  ? isEqual(option.effectiveValue, 'equals')
                  : !isEqual(
                      option.effectiveValue,
                      get(thresholds, 'threshold2.label.effectiveValue')
                    )
            )
            .value()}
          onChange={(val) => onChangeThreshold('threshold1', 'label', val)}
        />
        {isStringValue && isNumber(thresholds?.threshold1?.value) ? (
          <Select
            width={isStringValue ? '55%' : '320px'}
            value={boolSelectOptions.find(({ value }) =>
              new RegExp(thresholds.threshold1.value, 'i').test(value)
            )}
            options={boolSelectOptions}
            onChange={({ value }) =>
              onChangeThreshold('threshold1', 'value', value)
            }
          />
        ) : (
          <InputField
            mt={[1, 0]}
            ml={[0, 1]}
            width={['100%', 350]}
            value={thresholds.threshold1.value}
            onChange={(e) =>
              onChangeThreshold('threshold1', 'value', e.target.value)
            }
          />
        )}
      </AlertColumn>
      {!isStringValue && (
        <AlertColumn isMobile={isMobile} mt={2}>
          <Select
            width={isMobile ? '100%' : '320px'}
            value={thresholds.threshold2.label}
            options={chain(thresholdlabelOptions)
              .values()
              .filter(
                // TODO: temporary fix to disable equal for numbers
                (option) =>
                  !(!isStringValue && isEqual(option.effectiveValue, 'equals'))
              )
              .filter(
                // TODO: temporary fix to disable changing or alert type from range to bool
                (option) =>
                  isStringValue
                    ? isEqual(option.effectiveValue, 'equals')
                    : !isEqual(
                        option.effectiveValue,
                        get(thresholds, 'threshold1.label.effectiveValue')
                      )
              )
              .value()}
            onChange={(val) => onChangeThreshold('threshold2', 'label', val)}
          />
          <InputField
            mt={[1, 0]}
            ml={[0, 1]}
            width={['100%', 350]}
            value={thresholds.threshold2.value}
            onChange={(e) =>
              onChangeThreshold('threshold2', 'value', e.target.value)
            }
          />
        </AlertColumn>
      )}
      <Flex mt={6} justifyContent="flex-end">
        <SecondaryOutlinedButton onClick={closeModal}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          ml={2}
          onClick={onHandleSubmit}
          disabled={!valid || submitting}
        >
          {submitting ? `${t('Saving')}...` : t('Save')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default EditAlertThresholdModal;
