import { useState, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

/* Detecting Print View is tricky, we can use a combination of matchMedia for webkit based browser
and 'before/after print' for IE 11/Edge. */

export default ({ height, width, containerStyles = {} }) => {
  const [isPrintView, setIsPrintView] = useState(false);
  const matches = useMediaQuery({ query: 'print' });
  const showPrintView = matches || isPrintView;

  useLayoutEffect(() => {
    const beforePrint = (e) => {
      e.preventDefault();
      setIsPrintView(true);
    };
    const afterPrint = (e) => {
      e.preventDefault();
      setIsPrintView(false);
    };
    window.addEventListener('beforeprint;', beforePrint);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  }, [setIsPrintView]);

  const defaultStyles = { height, width, containerStyles };
  const printViewStyles = {
    ...defaultStyles,
    containerStyles: { ...containerStyles, width: '100%' },
    width: 800,
    height: 500,
  };
  return {
    isPrintView: showPrintView,
    styles: showPrintView ? printViewStyles : defaultStyles,
  };
};
