import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { VictoryTooltip } from 'victory';

import { theme, Flex, Box, Text } from '@fivehealth/botero';
import { DiamondChartIcon, CircleChartIcon } from './ChartTooltip';

const Container = styled('div')`
  color: ${theme.colors.fullShade};
  margin: 0;
  padding: 0;
  font-size: 10px;
  height: 100%;
  width: 115;
  display: 'flex';
  align-items: center;
  justify-content: 'center';
`;

const DataContainer = styled(Box)`
  border: 1px solid #d5d9de;
  border-radius: 8px;
  background-color: #ffffff;
  display: 'flex';
  align-items: center;
  justify-content: center;
`;

const FlyoutLabel = ({ icon, valueText, description }) => (
  <Flex justifyContent="space-between" mt={1}>
    {icon && <Flex flex={0.12}>{icon}</Flex>}
    <Flex flex={icon ? 0.78 : 1}>
      <Box>
        <Text fontSize={12} fontWeight={500} color="fullShade">
          {valueText}
        </Text>
        {description && (
          <Text mt="4px" fontSize={12} color="darkestShade">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  </Flex>
);

const FlyOut = ({ x, y, datum, width, screenWidth }) =>
  datum.timestamp ? (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject
        x={screenWidth - x > width ? x + 10 : x - 140}
        y={y - 50}
        width="160"
        height="100%"
      >
        <Container xmlns="http://www.w3.org/1999/xhtml">
          <DataContainer p={2}>
            <Text
              textAlign="left"
              color="darkestShade"
              fontSize={12}
              fontWeight={600}
            >
              {format(datum.timestamp, 'MMM d yyyy HH:mm')}
            </Text>
            {!datum.sibling && <FlyoutLabel {...datum} />}
            {datum.sibling && datum.symbol === 'diamond' && (
              <>
                <FlyoutLabel icon={<CircleChartIcon />} {...datum.sibling} />
                <FlyoutLabel icon={<DiamondChartIcon />} {...datum} />
              </>
            )}

            {datum.sibling && datum.symbol === 'circle' && (
              <>
                <FlyoutLabel icon={<CircleChartIcon />} {...datum} />
                <FlyoutLabel icon={<DiamondChartIcon />} {...datum.sibling} />
              </>
            )}
          </DataContainer>
        </Container>
      </foreignObject>
    </g>
  ) : null;

const CustomTooltip = (props) => (
  <g>
    <line
      x1={props.x}
      x2={props.x}
      y1={50}
      y2={450}
      stroke="#A3A9B1"
      strokeWidth="1"
    />
    <VictoryTooltip
      {...props}
      flyoutWidth={115}
      cornerRadius={5}
      flyoutComponent={<FlyOut screenWidth={props.width} />}
    />
  </g>
);

export { CustomTooltip, FlyOut };
