/* eslint-disable no-template-curly-in-string */

export const ACTIVE_PATIENTS_CONFIG = {
  title: 'Active Patients',
  columns: [
    {
      id: 'NAME',
      data: {
        valueTemplate: '<b>${profile[name]}</b><br />${patient[identifier]}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Patient',
      component: 'Text',
    },
    {
      id: 'ENROLLMENT_DATE',
      data: { valueTemplate: '${createdOn}' },
      style: { fontSize: '14px', padding: 0 },
      header: 'Created On',
    },
    {
      data: {
        valueTemplate: '<b>${profile[phone]}</b><br />${profile[email]}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Contact Details',
      disableSortBy: true,
    },
    {
      component: 'Boolean',
      data: {
        valueTemplate: '<b>${isDeviceCreated}</b>',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Device Created',
      disableSortBy: true,
    },
    {
      data: {
        valueTemplate: '<b>${gender}</b><br />${dateOfBirth}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Gender/DOB',
      disableSortBy: true,
    },
    {
      data: {
        valueTemplate: '${monitoringForms}',
        hrefTemplate: '${url}#/homegpasia/${profile[uid]}',
      },

      style: { fontSize: '14px', padding: 0 },
      header: 'Active Programs',
      component: 'MonitoringFormsArray',
      disableSortBy: true,
    },
    {
      data: {
        valueTemplate: '${dischargedMonitoringForms}',
        hrefTemplate: '${url}',
      },

      style: { fontSize: '14px', padding: 0 },
      header: 'Removed Programs',
      component: 'MonitoringFormsArray',
      disableSortBy: true,
    },
  ],
  orderDesc: false,
  defaultSortField: 'NAME',
};

export const DISCHARGE_PATIENTS_CONFIG = {
  title: 'Discharged Patients',
  columns: [
    {
      id: 'NAME',
      data: {
        valueTemplate: '<b>${profile[name]}</b><br />${patient[identifier]}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Patient',
      component: 'Text',
    },
    {
      data: {
        valueTemplate: '<b>${profile[phone]}</b><br />${profile[email]}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Contact Details',
      disableSortBy: true,
    },
    {
      component: 'Boolean',
      data: {
        valueTemplate: '<b>${profile[isDeviceCreated]}</b>',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Device Created',
      disableSortBy: true,
    },
    {
      data: {
        valueTemplate: '<b>${gender}</b><br />${dateOfBirth}',
      },
      style: { fontSize: '14px', padding: 0 },
      header: 'Gender/DOB',
      disableSortBy: true,
    },
    {
      data: {
        valueTemplate: '${dischargedMonitoringForms}',
        hrefTemplate: '${url}',
      },

      style: { fontSize: '14px', padding: 0 },
      header: 'Removed Programs',
      component: 'MonitoringFormsArray',
      disableSortBy: true,
    },
    {
      id: 'ENROLLMENT_DATE',
      data: { valueTemplate: '${createdOn}' },
      style: { fontSize: '14px', padding: 0 },
      header: 'Enrollment Date',
    },
    {
      id: 'DEACTIVATED_ON',
      data: { valueTemplate: '${deactivatedOn}' },
      style: { fontSize: '14px', padding: 0 },
      header: 'Deactivated Date',
      disableSortBy: true,
    },
  ],
  orderDesc: false,
  defaultSortField: 'NAME',
};
