// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const Person = memo<IconProps>(({ color, width = 8, height = 15 }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66992 5.01562C7.38086 5.39844 7.90039 6.19141 7.90039 7.06641V9.5C7.90039 10.2383 7.46289 10.8398 6.86133 11.1133V13C6.86133 13.9844 6.06836 14.75 5.11133 14.75H3.68945C2.70508 14.75 1.93945 13.9844 1.93945 13V11.1133C1.31055 10.8398 0.900391 10.2383 0.900391 9.5V7.06641C0.900391 6.19141 1.39258 5.39844 2.10352 5.01562C1.83008 4.57812 1.66602 4.05859 1.66602 3.48438C1.66602 1.98047 2.86914 0.75 4.40039 0.75C5.9043 0.75 7.13477 1.98047 7.13477 3.48438C7.13477 4.05859 6.94336 4.57812 6.66992 5.01562ZM4.40039 2.0625C3.60742 2.0625 2.97852 2.71875 2.97852 3.48438C2.97852 4.27734 3.60742 4.90625 4.40039 4.90625C5.16602 4.90625 5.82227 4.27734 5.82227 3.48438C5.82227 2.71875 5.16602 2.0625 4.40039 2.0625ZM6.58789 9.5V7.06641C6.58789 6.51953 6.15039 6 5.4668 6C4.7832 6.30078 3.99023 6.30078 3.30664 6C2.62305 6 2.21289 6.54688 2.21289 7.06641V9.5C2.21289 9.74609 2.4043 9.9375 2.65039 9.9375H3.25195V13C3.25195 13.2461 3.44336 13.4375 3.68945 13.4375H5.11133C5.33008 13.4375 5.54883 13.2461 5.54883 13V9.9375H6.15039C6.36914 9.9375 6.58789 9.74609 6.58789 9.5Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default Person;
