import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { theme, FAIcon } from '@fivehealth/botero';

const UnreadCommentIconIndicator = (props) => (
  <FAIcon
    icon={faCircle}
    style={{
      width: '9px',
      height: '9px',
      borderRadius: 7,
      right: -5,
      color: theme.colors.primary,
      border: '2px solid white',
      position: 'absolute',
    }}
    {...props}
  />
);

export default UnreadCommentIconIndicator;
