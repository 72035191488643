/* eslint-disable import/prefer-default-export */
import { isJson } from 'AppUtils';
import { atom } from 'recoil';
import { StateKey } from 'states';

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue && isJson(savedValue)) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, old, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const asyncJobState = atom({
  key: StateKey.BulkImportState,
  default: undefined,
  effects: [
    localStorageEffect(
      `asyncJobState-${sessionStorage.getItem('clinicDomain')}`
    ),
  ],
});
