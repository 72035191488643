import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  H4,
  Flex,
  PhoneInputField,
  Select,
  InputField,
  H6,
  Text,
  useApi,
  FAIcon,
  theme,
} from '@fivehealth/botero';
import { filter, get, isEmpty, isEqual } from 'lodash';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { getClinicalDesignationOptions } from 'AppUtils';
import { useAppData } from 'context/AppDataContext';

const Label = (props) => <H6 pb="4px" color="darkestShade" {...props} />;

const InputWithLabel = withTranslation()(
  ({ errors, errorMessage, label, name, t, value, ...props }) => {
    const hasError = !isEmpty(get(errors, name, ''));
    const [active, setActive] = useState(false);

    const borderColor = useMemo(() => {
      if (hasError) return theme.colors.danger;
      if (active) return theme.colors.primary;
      return '#D5D7DE';
    }, [active, hasError]);

    return (
      <Flex flexDirection="column" flex={0.48}>
        <Label>{t(label)}</Label>
        <InputField
          width="auto"
          maxWidth="auto"
          name={name}
          value={value || ''}
          {...props}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          style={{
            outline: 'none',
            borderColor,
          }}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          message={errorMessage}
          render={({ message }) => (
            <Text color="danger" fontSize={12} mt={1} ml={1}>
              {t(message)}
            </Text>
          )}
        />
      </Flex>
    );
  }
);

const SelectWithLabel = withTranslation()(
  ({ errors, errorMessage, label, name, children, t, ...props }) => (
    <Flex flexDirection="column" flex={0.48}>
      <Label>{t(label)}</Label>
      <Select defaultValue="SMS" name={name} maxMenuHeight={180} {...props}>
        {children}
      </Select>
      <ErrorMessage
        errors={errors}
        name={name}
        message={errorMessage}
        render={({ message }) => (
          <Text color="danger" fontSize={12} mt={1} ml={1}>
            {t(message)}
          </Text>
        )}
      />
    </Flex>
  )
);

export const defaultRoleOptions = [
  { label: 'Owner', value: 'OWNER' },
  { label: 'Editor', value: 'EDITOR' },
  { label: 'Member', value: 'MEMBER' },
  { label: 'Viewer', value: 'VIEWER' },
];

const ClinicalUserForm = ({
  control,
  index,
  isEditView,
  isLoading,
  onRemove,
  setError,
  clinicData,
  clinicalRoles,
  currentUser,
  clinicalUser,
  formState,
}) => {
  const errors = useMemo(() => formState.errors, [formState.errors]);

  const element = useRef(null);
  const { t } = useTranslation();
  const { clinic } = useAppData();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const {
    queries: { useClinicianDesignations, useClinicPublicSettings },
  } = useApi({
    queries: ['useClinicianDesignations', 'useClinicPublicSettings'],
  });

  const { data } = useClinicianDesignations();

  const { data: clinicPublicSettings } = useClinicPublicSettings({
    enabled: !isEmpty(clinic) && !!clinic?.domain,
    variables: {
      domain: clinic?.domain,
    },
  });

  const getFormLanguage = () => {
    const result =
      clinicPublicSettings?.cleoClinicPublicSettings?.countryCode || 'SG';
    return !isEditView ? result.toLowerCase() : result;
  };

  // To hide email as communication method
  const communicationMethodsOptions = useMemo(
    () =>
      filter(
        get(clinicData, 'communicationMethods', []),
        ({ value }) => !isEqual(value, 'email')
      ),
    [clinicData]
  );

  useEffect(() => {
    if (element.current && index !== 0)
      element.current.scrollIntoView({ behavior: 'smooth' });
  }, [index]);

  return (
    <Box
      ref={element}
      border="1px solid"
      borderRadius={8}
      mt={4}
      p={3}
      borderColor="#D5D9DE"
    >
      <Flex justifyContent="space-between">
        <H4 ml={isMobile ? '-8px' : '8px'} mb={2} color="fullShade">
          {t('User Information')}
        </H4>
        {index !== 0 && !isEditView && (
          <Box cursor="pointer" onClick={() => onRemove(index)}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        )}
      </Flex>

      <Flex ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Box flex={[1, 0.5]} mb={[2, 0]} mr={[0, 2]}>
          <Controller
            name={`clinicalUsers.${index}.firstName`}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <InputWithLabel
                errors={errors}
                errorMessage={t('First name is required.')}
                label={t('First name')}
                style={{ marginRight: 24 }}
                {...field}
                placeholder={t('First name')}
                disabled={isLoading}
              />
            )}
          />
        </Box>
        <Controller
          name={`clinicalUsers.[${index}].lastName`}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithLabel
              label={t('Last name')}
              {...field}
              placeholder={t('Last name')}
              errors={errors}
              errorMessage={t('Last name is required.')}
            />
          )}
        />
      </Flex>

      <Flex mt={2} ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Box flex={[1, 0.5]} mb={[2, 0]} mr={[0, 2]}>
          <Flex flexDirection="column" flex={0.48}>
            <Label>{t('Phone number')}</Label>
            <Controller
              name={`clinicalUsers.[${index}].phone`}
              rules={{
                required: true,
              }}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PhoneInputField
                  style={{ width: '100%' }}
                  placeholder={t('Enter phone number')}
                  country={getFormLanguage()}
                  inputStyle={{ width: '100%' }}
                  {...field}
                  onBlur={(e) => {
                    const phoneNum = `+${field.value}`;
                    const parsed = parsePhoneNumber(phoneNum);
                    const isValid = parsed
                      ? isValidPhoneNumber(phoneNum, parsed.countryCode)
                      : false;
                    if (!parsed || !isValid) {
                      setError(field.name, {
                        type: 'manual',
                        message: t('Phone number is invalid.'),
                      });
                    }
                    field.onBlur(e);
                  }}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`clinicalUsers.[${index}].phone`}
              message={t('Valid phone number required.')}
              render={({ message }) => (
                <Text color="danger" fontSize={12} mt={1} ml={1}>
                  {t(message)}
                </Text>
              )}
            />
          </Flex>
        </Box>

        <Controller
          name={`clinicalUsers.[${index}].email`}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithLabel
              label={t('Email')}
              {...field}
              placeholder="example@mail.com"
              errors={errors}
              errorMessage={t('Email is required.')}
            />
          )}
        />
      </Flex>

      <Flex mt={2} ml={[-1, 1]} flexDirection={['column', 'row']}>
        <Box flex={[1, 0.5]} mb={[2, 0]} mr={[0, 2]}>
          <Controller
            name={`clinicalUsers.[${index}].communicationMethod`}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectWithLabel
                errors={errors}
                errorMessage={t('Alert method is required.')}
                options={communicationMethodsOptions}
                label={t('Alerted via')}
                placeholder={t('Select service')}
                {...field}
              />
            )}
          />
        </Box>

        <Controller
          name={`clinicalUsers.[${index}].designation`}
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <SelectWithLabel
              errors={errors}
              errorMessage={t('Desgination is required.')}
              options={getClinicalDesignationOptions(
                data?.cleoClinicianDesignations
              )}
              label={t('Clinical Designation')}
              placeholder={t('Enter clinical designation')}
              {...field}
            />
          )}
        />
      </Flex>

      <Box mt={2} ml={[-1, 1]}>
        <H4 ml={isMobile && '-8px'} mb={2} color="fullShade">
          {t('Account Settings')}
        </H4>
        <Flex mt={2} flexDirection={['column', 'row']}>
          <Controller
            name={`clinicalUsers.[${index}].role`}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectWithLabel
                errors={errors}
                errorMessage={t('Role is required.')}
                options={clinicalRoles}
                label={t('Account Type')}
                placeholder={t('Select Account Type')}
                isDisabled={isEqual(clinicalUser?.uid, currentUser?.uid)}
                {...field}
              />
            )}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default React.memo(ClinicalUserForm);
