import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { cleoPatientFormFragment } from './usePatientForms';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientFormUpdate($input: UpdateCleoPatientFormInput!) {
    cleoPatientFormUpdate(input: $input) {
      cleoPatientForm {
        ${cleoPatientFormFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientForm',
  keepPreviousData: false,
});
