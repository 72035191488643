import React from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  faEdit,
  faEllipsisV,
  faChevronDown,
  faTimes,
  faRepeat,
  faCalendarAlt,
  faExclamationTriangle,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
} from '@fortawesome/pro-regular-svg-icons';

import {
  chain,
  get,
  has,
  isEmpty,
  map,
  max,
  union,
  flatMap,
  isUndefined,
  isEqual,
  concat,
} from 'lodash';

import {
  Badge,
  Body,
  FAIcon,
  Flex,
  SecondaryOutlinedButton,
  Text,
  theme,
  AccordionWidget,
  Box,
  H2,
  DangerButton,
  TextLink,
} from '@fivehealth/botero';

import { checkPermissions } from 'AppUtils';
import { BoxDivider } from 'components/Layout';
import ActionDropdownWithPermission from 'components/ActionDropdown/ActionDropdownWithPermission';

import EditPatientModal from '../PatientEdit.modal';
import ReminderModal from '../PatientReminder.modal';
import MessageTemplateModal from '../PatientMessageTemplate.modal';
import DischargeEnrollModal from '../PatientDischargeEnroll.modal';
import EditAlertThresholdModal from '../Thresholds/EditAlertThreshold.modal';

const ModalStyle = {
  padding: 24,
  overflow: 'scroll',
};

/**
 * Check for permission
 * @param clinician
 * @param keyValue
 * @keyValue key valu string
 * @returns {*}
 */
export const hasPermission = (clinician, keyValue) =>
  checkPermissions(clinician, [keyValue]);

/**
 * Render panel of the left side header element.
 * @param data - Patient form data object or null/undefined.
 * @param title - Main string title.
 * @param isHideBadge - Show or hide badge or not.
 * @returns {JSX.Element}
 */
export const renderPanelHeaderLeft = (data, title, isHideBadge = true) => {
  const form = data;
  const isValidForm = form && !isEmpty(form);

  const status = {
    color: theme.colors.darkestShade,
    bg: theme.colors.lightShade,
    text: '',
  };
  if (isValidForm) {
    const { isActivated, isDischarged } = form;

    if (isDischarged) {
      status.text = 'Discharged';
    } else if (isActivated) {
      status.text = 'Active';
      status.color = 'success';
      status.bg = '#DCF7E8';
    }
  }
  return (
    <Flex width={['100%', 'auto']} justifyContent="space-between">
      <Text
        fontSize={16}
        fontWeight={600}
        data-testid="box-pair-right-panel-header-left-element"
      >
        {title}
      </Text>
      {!isHideBadge && (
        <Badge
          fontWeight={500}
          fontSize={12}
          color={status?.color}
          ml={1}
          borderRadius={6}
          bg={status?.bg}
          height={24}
          alignItems="center"
          justifyContent="center"
          type={status?.type}
          style={{ marginTop: '-4px' }}
        >
          {status?.text}
        </Badge>
      )}
    </Flex>
  );
};

/**
 * Render panel of the right side header element.
 * @param1 elementType - string value either 'text', 'edit', 'ellipsis'
 * @param2 isMobile - boolean
 * @param3 onClickAction - function
 * @param4 sectionId - string
 * @returns {JSX.Element}
 */
export const renderPanelHeaderRight = (
  t,
  elementType,
  isMobile,
  onClickAction,
  index
) => {
  const editElement = () => (
    <Box data-testid={`box-panel-header-edit-${index}`}>
      {isMobile ? (
        <Flex justifyContent="end" height={56}>
          <SecondaryOutlinedButton
            onClick={() => onClickAction()}
            border={0}
            borderRadius={0}
            endIcon={
              <FAIcon icon={faEdit} fontSize="14px" color="darkestShade" />
            }
          >
            <Body
              color="darkestShade"
              fontWeight="500"
              fontSize={14}
              fontFamily="Inter"
              small
            >
              {t('Edit')}
            </Body>
          </SecondaryOutlinedButton>
        </Flex>
      ) : (
        <Body>
          <Text
            color={theme.colors.primary}
            fontWeight={500}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClickAction();
            }}
          >
            {t('Edit')}
          </Text>
        </Body>
      )}
    </Box>
  );

  const textElement = () => (
    <Body
      color="darkestShade"
      fontWeight="500"
      fontSize={14}
      fontFamily="Inter"
      small
    >
      {t('Edit')}
    </Body>
  );

  const ellipsisElement = () => (
    <Body>
      <FAIcon
        icon={faEllipsisV}
        fontSize="14px"
        color="darkestShade"
        onClick={() => onClickAction()}
      />
    </Body>
  );

  return (
    <>
      {elementType === 'edit' && editElement()}
      {elementType === 'text' && textElement()}
      {elementType === 'ellipsis' && ellipsisElement()}
    </>
  );
};

/**
 * Create a TextLink for file type metadataEntry.
 * @param1 metadataEntry
 * @returns {JSX.Element}
 */
export const getMetadataEntryDisplay = (metadataEntry, token) => {
  if (isEqual(metadataEntry?.parameter?.valueType, 'FILE')) {
    return (
      <Flex justifyContent="right">
        <TextLink
          fontSize={14}
          onClick={() => {
            if (!metadataEntry?.value?.file?.url?.includes('memory://')) {
              window.open(
                `${metadataEntry?.value?.file?.url}?x-session=${token}`,
                '_blank',
                'noopener,noreferrer'
              );
            } else {
              window.open(metadataEntry?.extractedForDisplay, '_blank');
            }
          }}
          text={metadataEntry?.value?.file?.name}
        />
      </Flex>
    );
  }
  return metadataEntry?.extractedForDisplay;
};

/**
 * Create a data map for the clinical user(s) use in the box pair layout panels.
 * @param1 patientForms
 * @param3 headerLeftCallback - Callback function to render jsx element or a plain text in the LEFT part of the header.
 * @param2 headerRightCallback - Callback function to render jsx element or a plain text in the RIGHT part of the header.
 * @returns {Array}
 */
export const getBoxPairLayoutData = (
  t,
  caregiverRoleUid,
  patientForms,
  headerLeftCallback = () => {},
  headerRightCallback = () => {},
  token
) =>
  map(patientForms, (form, index) => {
    return {
      headerLeft: renderPanelHeaderLeft(
        form,
        headerLeftCallback && typeof headerLeftCallback === 'function'
          ? headerLeftCallback(form, index)
          : headerLeftCallback
      ),
      headerRight:
        headerRightCallback && typeof headerRightCallback === 'function'
          ? headerRightCallback(form, index)
          : headerRightCallback,
      groupPair: [
        ...map(form.metadataEntries, (entry) => {
          return {
            label: t(entry?.parameter?.name),
            value: getMetadataEntryDisplay(entry, token),
          };
        }),
        {
          label: t('Enrollment Date'),
          value: form
            ? format(parseISO(get(form, 'createdOn')), 'dd MMM yyyy HH:mm')
            : '',
        },
        form.deactivatedOn
          ? {
              label: t('Discharged Date'),
              value: form
                ? format(
                    parseISO(get(form, 'deactivatedOn')),
                    'dd MMM yyyy HH:mm'
                  )
                : '',
            }
          : null,
        {
          label: t('Clinical user(s) in-charge'),
          value: [...get(form, 'ics', []).map(({ name }) => name)],
        },
        {
          label: t('Clinical user(s) to alert'),
          value: [...get(form, 'alertees', []).map(({ name }) => `${name}`)],
        },
        caregiverRoleUid
          ? {
              label: t('Family Member(s)'),
              value: [
                ...get(form, 'caregivers', []).map(({ name }) => `${name}`),
              ],
            }
          : {},
      ].filter(Boolean),
      data: form,
    };
  });

/**
 * Open dialog for clinical user and patient details section.
 * @param openModal
 * @param closeModal
 * @param form
 * @param clinic
 * @param refetchPatientForm
 * @param section
 * @param isMobile
 */
export const onOpenEditUserModal = (
  openModal,
  closeModal,
  form,
  clinic,
  refetchPatientForm,
  section,
  isMobile
) => {
  openModal(
    <EditPatientModal
      patientForm={form}
      closeModal={closeModal}
      clinicData={clinic}
      refetchPatientForm={refetchPatientForm}
      section={section}
      isMobile={isMobile}
    />,
    {
      style: ModalStyle,
    }
  );
};

/**
 * Open dialog for activate/pause reminder
 * @param openModal
 * @param closeModal
 * @param form
 * @param refetchPatientForm
 * @param isEdit
 * @param t
 */
export const onOpenReminderModal = (
  openModal,
  closeModal,
  form,
  refetchPatientForm,
  isEdit,
  t
) => {
  openModal(
    <ReminderModal
      patientForm={form}
      closeModal={closeModal}
      refetchPatientForm={refetchPatientForm}
      isEdit={isEdit}
      t={t}
    />,
    {
      style: ModalStyle,
    }
  );
};

/**
 * Open send message template modal.
 * @param openModal
 * @param closeModal
 * @param patientForm
 * @param onSubmitMessageCreate
 */
export const onOpenSendMessageTplModal = (
  openModal,
  closeModal,
  patientForm,
  onSubmitMessageCreate,
  isPatientsView = false
) => {
  openModal(
    <MessageTemplateModal
      patientForm={patientForm}
      closeModal={closeModal}
      onSubmit={onSubmitMessageCreate}
      isPatientsView={isPatientsView}
    />,
    {
      style: ModalStyle,
    }
  );
};

/**
 * Open dialog for discharge patient form.
 * @param openModal - open modal function <br/>
 * @param closeModal - close modal function <br/>
 * @param data - object patient form to discharge <br/>
 * @param onSubmitDischargePatientForm - submit function to discharge patient <br/>
 * @param isSubmitting - boolean flag for submission <br/>
 * @param onSuccess - optional call back function default is null <br/>
 * @param enrolled - optional boolean default is false<br/>
 */
export const onDischargePatientForm = (
  openModal,
  closeModal,
  data,
  onSubmitDischargePatientForm,
  isSubmitting,
  onSuccess = null,
  enrolled = false
) => {
  openModal(
    <DischargeEnrollModal
      enroll={enrolled}
      patientForm={data}
      closeModal={closeModal}
      onSubmit={onSubmitDischargePatientForm}
      isSubmitting={isSubmitting}
      onSuccess={onSuccess}
    />,
    {
      style: ModalStyle,
    }
  );
};

/**
 * Delete reminder dialog component.
 * @param data
 * @param onClose
 * @param onSubmit
 * @param isSubmitting
 * @param refetchPatientForm
 * @returns {JSX.Element}
 * @constructor
 */
export const DeleteReminderDialog = ({
  data,
  onClose,
  onSubmit,
  isSubmitting,
  refetchPatientForm,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{t('Delete reminder')}</H2>
        <Box cursor="pointer" onClick={onClose}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Body mt="30px" fontSize="16px" lineHeight="24px">
        {t('Are you sure you want to stop sending reminder')}{' '}
        <b>{`“${data.name}”`}</b> {t('repeating')}
        <b>{` “${data.reminderName}”`}</b>?
      </Body>
      <Flex mt="30px" justifyContent="flex-end">
        <SecondaryOutlinedButton
          mr={3}
          onClick={onClose}
          disabled={isSubmitting}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <DangerButton
          onClick={() => {
            onSubmit(data);
            if (refetchPatientForm) {
              refetchPatientForm();
            }
            onClose();
          }}
          disabled={isSubmitting}
        >
          {t('Delete Reminder')}
        </DangerButton>
      </Flex>
    </>
  );
};

/**
 * Open delete remindal dialog modal.
 * @param openModal
 * @param closeModal
 * @param data
 * @param onSubmit
 * @param isSubmitting
 * @param refetchPatientForm
 */
export const onOpenDeleteReminderModal = (
  openModal,
  closeModal,
  data,
  onSubmit,
  isSubmitting,
  refetchPatientForm
) => {
  openModal(
    <DeleteReminderDialog
      data={data}
      onClose={closeModal}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      refetchPatientForm={refetchPatientForm}
    />,
    {
      style: { width: 600 },
    }
  );
};

/**
 * DEPRRECATED: Open edit alert threshold modal.
 * @param openModal
 * @param closeModal
 * @param patientForm
 * @param onSubmit
 * @param isSubmitting
 */
export const onOpenEditAlertThresholdModal = (
  openModal,
  closeModal,
  patientForm,
  onSubmit,
  isSubmitting
) => {
  openModal(
    <EditAlertThresholdModal
      data={patientForm}
      closeModal={closeModal}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    />,
    {
      style: ModalStyle,
    }
  );
};

/**
 * Uppercase first letter.
 * @param str
 * @returns {string}
 */
export const upperCaseFirstLetter = (str) =>
  str.toString().charAt(0).toUpperCase() + str.toString().slice(1);

const pluralize = (numVal, pluralTxt) =>
  Number(numVal) > 1 ? `${pluralTxt}s` : pluralTxt;

const getBeforeAfterText = (trigger) => {
  if (trigger?.is_after === false) {
    return 'before';
  }
  return 'after';
};

export const getTriggerText = (trigger, metadataEntries = []) => {
  if (isEqual(trigger?.event, 'exact')) {
    if (trigger?.time_of_day) {
      return `${format(parseISO(trigger?.exact_trigger_on), 'dd MMM yyyy')} @ ${
        trigger?.time_of_day
      }`;
    }
    return format(parseISO(trigger?.exact_trigger_on), 'dd MMM yyyy, HH:mm');
  }

  if (!has(trigger, 'duration')) {
    return `Upon ${trigger?.event}`;
  }

  let event = trigger?.event || '';
  if (event === 'clinical_parameter') {
    event = metadataEntries.find(
      (o) => o?.parameter?.uid === trigger?.clinical_parameter_uid
    )?.parameter?.name;
  }

  if (!event) {
    return null;
  }

  return `${trigger?.duration || ''} ${pluralize(
    trigger?.duration || '',
    trigger?.unit || ''
  )} ${getBeforeAfterText(trigger)} ${event || ''} ${
    trigger?.time_of_day ? `@ ${trigger?.time_of_day}` : ''
  }`;
};

/**
 * Get reminder settings  (frequency and enrollment text) content for frontend presentation.
 * @param t
 * @param data
 * @returns {{frequency: string, enrollment: string}}
 */
export const getFrontendReminderSettings = (t, data) => {
  const recurringFreq = get(data, 'settings.schedule.recurring_frequencies');
  const startTrigger = get(data, 'settings.schedule.start_trigger');
  const endTrigger = get(data, 'settings.schedule.end_trigger');
  const metadataEntries = concat(
    get(data, 'patientForm.metadataEntries', []),
    get(data, 'patientForm.patient.metadataEntries', [])
  );

  let frequencyTxt;
  let enrollmentTxt;

  const freq = {
    unit: [],
    every: [],
    days: [],
    time: [],
  };

  if (recurringFreq && recurringFreq?.length > 0) {
    recurringFreq?.forEach((o) => {
      freq.unit.push(o.unit);
      if (o?.day_of_week) {
        freq.days.push(
          o?.day_of_week ? upperCaseFirstLetter(o?.day_of_week) : ''
        );
      }
      if (o?.day_of_month) {
        freq.days.push(o?.day_of_month);
      }

      freq.time.push(o.time_of_day);
      freq.every.push(o.every);
    });

    const unitTxt = freq?.unit?.length > 0 ? union(freq.unit).join(', ') : '';
    const everyTxt = max(union(freq.every)) === 1 ? '' : max(union(freq.every));
    const daysTxt =
      isEmpty(freq?.days) || (freq?.days?.length === 1 && !freq.days[0])
        ? ''
        : `${union(freq.days).join(', ')},`;
    const timeTxt =
      freq?.time?.length > 0
        ? union(freq.time).join(', ').replaceAll(':', '.')
        : '';
    frequencyTxt = `${t('Every')} ${everyTxt} ${pluralize(
      everyTxt,
      unitTxt
    )}, ${daysTxt} ${timeTxt}`;
  } else {
    frequencyTxt = `${t('One-time')}, ${getTriggerText(
      startTrigger,
      metadataEntries
    )}`;
  }

  const count = get(recurringFreq, '[0].count');
  if (startTrigger && !isUndefined(count)) {
    const startTriggerText = getTriggerText(startTrigger, metadataEntries);
    enrollmentTxt = `${startTriggerText} (${count} ${t(
      pluralize(count, 'reminder')
    )})`;
  } else if (
    isEqual(startTrigger?.event, 'exact') ||
    isEqual(endTrigger?.event, 'exact')
  ) {
    enrollmentTxt = t('Not applicable');
  } else {
    const startTriggerText = startTrigger
      ? getTriggerText(startTrigger, metadataEntries)
      : getTriggerText({ event: 'enrollment' });

    const endTriggerText = endTrigger
      ? getTriggerText(endTrigger, metadataEntries)
      : getTriggerText({ event: 'discharge' });
    enrollmentTxt = `${startTriggerText} - ${endTriggerText}`;
  }

  return {
    frequency: frequencyTxt,
    enrollment: enrollmentTxt,
  };
};

/**
 * Reminder component.
 * @param t
 * @param data
 * @param iconProps
 * @param textProps
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxContentReminder = ({
  t,
  data,
  iconProps,
  textProps,
  ...props
}) => {
  const settings = getFrontendReminderSettings(t, data);

  const renderSettings = (icon, value) => (
    <Flex>
      <FAIcon
        fontSize="14px"
        fontWeight={400}
        icon={icon}
        color="darkestShade"
        style={{ paddingRight: 10, marginTop: 2 }}
        {...iconProps}
      />
      {value || t('Not applicable')}
    </Flex>
  );
  return (
    <Box fontSize="14px" fontWeight={400} pb={1} {...props}>
      <Text p={1} {...textProps}>
        {renderSettings(faRepeat, settings.frequency)}
      </Text>

      <Text p={1} {...textProps}>
        {renderSettings(faCalendarAlt, settings.enrollment)}
      </Text>
    </Box>
  );
};

const ValText = ({ value, unit, operator }) => (
  <Flex
    style={{ fontSize: 14, fontWeight: 400 }}
    flexDirection="end"
    alignItems="end"
    textAlign="end"
  >
    {value && (
      <>
        <Text width={10}>{operator}</Text>
        <Text minWidth={30}>
          {upperCaseFirstLetter(value?.toString()?.toLowerCase())}
        </Text>
      </>
    )}
    <Text pl="4px">{unit}</Text>
  </Flex>
);

/**
 * Box content component for alert thresholds.
 * @param t
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxContentAlerThresholds = ({ t, data }) => (
  <Box fontSize="14px" fontWeight={400} pb={0}>
    <Flex mb={[1, 1]} justifyContent="space-between">
      <Flex>
        <Text p="5px" width={200} color={theme.colors.darkestShade}>
          {t('Alert')}
          <FAIcon
            fontSize="12px"
            fontWeight={400}
            icon={faExclamationTriangle}
            style={{ color: theme.colors.danger, paddingLeft: 10 }}
          />
        </Text>
      </Flex>
      <Flex textAlign="end" width={[90, 200]} justifyContent="end">
        <Box>
          {data?.min && (
            <ValText
              value={data.min}
              unit={data?.unit}
              operator={
                data?.inclusive_min ? (
                  <FAIcon
                    fontSize="12px"
                    fontWeight={400}
                    icon={faLessThanEqual}
                    color="darkestShade"
                    style={{ paddingRight: 10 }}
                  />
                ) : (
                  <FAIcon
                    fontSize="12px"
                    fontWeight={400}
                    icon={faLessThan}
                    color="darkestShade"
                    style={{ paddingRight: 10 }}
                  />
                )
              }
            />
          )}

          {data?.max && (
            <ValText
              value={data.max}
              unit={data?.unit}
              operator={
                data?.inclusive_max ? (
                  <FAIcon
                    fontSize="12px"
                    fontWeight={400}
                    icon={faGreaterThanEqual}
                    color="darkestShade"
                    style={{ paddingRight: 10 }}
                  />
                ) : (
                  <FAIcon
                    fontSize="12px"
                    fontWeight={400}
                    icon={faGreaterThan}
                    color="darkestShade"
                    style={{ paddingRight: 10 }}
                  />
                )
              }
            />
          )}

          {data.equals && (
            <ValText
              value={upperCaseFirstLetter(
                data?.equals ? data?.equals?.value : ''
              )}
              unit={data?.unit}
              operator=""
            />
          )}
        </Box>
      </Flex>
    </Flex>
  </Box>
);

/**
 * Box section with action menu.
 * @param title
 * @param actionItems
 * @param index
 * @param isMobile
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxActionMenuSection = ({
  title,
  actionItems,
  outerIndex,
  innerIndex,
  isMobile,
  children,
  boxProps,
  permission,
}) => (
  <Box
    border="1px solid #D5D7DE"
    marginRight={outerIndex + (1 % 2) || isMobile ? 0 : 2}
    marginBottom={2}
    borderRadius={8}
    width={['100%', 333]}
    data-testid={`box-actonmenut-section-${outerIndex}-${innerIndex}`}
    {...boxProps}
  >
    <Flex justifyContent="space-between" px="12px" pt="12px" pb="8px">
      <Box style={{ fontSize: '14px', fontWeight: 500, color: '#111824' }}>
        {title}
      </Box>
      <Box>
        <ActionDropdownWithPermission
          items={actionItems}
          permission={permission}
        />
      </Box>
    </Flex>
    <BoxDivider paddingTop={0} />
    <Box px={1} pt={1} pb={0}>
      {children}
    </Box>
  </Box>
);

/**
 * Render accodion box component.
 * @param data
 * @returns {JSX.Element}
 */
export const BoxAccordionSection = ({ sections, isSectionOpen }) => {
  const modSections = [...sections].map((o) =>
    o.isOpen === isSectionOpen ? { ...o } : { ...o, isOpen: isSectionOpen }
  );
  const hasDataBodyContent = modSections.body && modSections.body.length > 0;
  return (
    <AccordionWidget
      data={modSections}
      width="100%"
      renderHeader={({ name, isOpen, renderSectionRight }) => (
        <Flex
          style={{ borderTop: '1px solid #D5D7DE', marginTop: 24 }}
          bg="white"
          justifyContent="space-between"
          pt={2}
        >
          <Box style={{ fontSize: '14px', fontWeight: 500 }}>{name}</Box>
          <Flex alignItems="center" mr={1}>
            <Body mr={renderSectionRight ? 3 : 0}>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  transform: `rotate(${isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
            {renderSectionRight && renderSectionRight()}
          </Flex>
        </Flex>
      )}
      renderBody={({ renderSection, renderSectionFooter }, index) => (
        <>
          <Flex
            justifyContent="space-between"
            fontSize={14}
            fontWeight={400}
            paddingTop={renderSection().length > 0 ? 2 : null}
            maxWidth="100%"
            style={{ flexFlow: 'wrap' }}
            data-testid={`box-accordion-section-rendered-body-${index}`}
          >
            {renderSection()}
          </Flex>
          {renderSectionFooter && renderSectionFooter()}
        </>
      )}
      containerProps={{ border: 0, borderRadius: 0 }}
      headerProps={{
        border: 1,
        borderTop: 1,
        borderRadius: 0,
        width: '100%',
        backgroundColor: 'white',
        marginBottom: '-4px',
      }}
      bodyProps={{
        borderRadius: 0,
        backgroundColor: 'white',
        minHeight: hasDataBodyContent ? 170 : null,
      }}
      alternateBgColor={false}
    />
  );
};

/**
 * Get active or discharged reminders.
 * @param data
 * @returns {{discharge: (function(): *), active: (function(): *)}}
 */
export const getReminders = (data) =>
  flatMap(data, (form) =>
    form.effectiveReminders.map((reminder) => ({
      ...reminder,
      uid: `${get(form, 'uid')}-${reminder.uid}`,
      name: `${get(form, 'monitoringForm.effectiveName')}`,
      reminderUid: reminder.uid,
      reminderName: reminder.name,
      patientForm: form,
    }))
  );

/**
 * Get active or discharged VALID alert thresholds from a patient forms data.
 * @param data
 * @returns {{discharge: (function(): *), active: (function(): *)}}
 */
export const getAlertThresholds = (data) =>
  data.flatMap((form) => {
    const effectiveAlertRules = get(form, 'effectiveAlertRules', []);
    // TODO: Always use 'effectiveAlertRules', Check if we need to mapped and flatten all parser settings into one list.
    const allParserSettings = effectiveAlertRules
      .map((rule) => rule?.parserSettings)
      .map((o) =>
        Object.values(o)
          .map((x) => Object.values(x))
          .flat(2)
      )
      .flat(1)
      .map((o, i) => ({
        ...o,
        parserIndex: i, // NOTE: Use as a temporary index indicator flag for parser settings
      }));

    const mFormClinicalParams = get(form, 'monitoringForm.clinicalParameters');
    const filteredParserSettings = allParserSettings
      .map((m) => {
        const res = mFormClinicalParams.find(
          (f) => f.name.toLowerCase() === m.clinical_parameter?.toLowerCase()
        );
        if (res)
          return {
            ...m,
            parameterData: res,
          };
        return {
          ...m,
        };
      })
      .filter((filteredSetting) => filteredSetting.parameterData);

    // NOTE: Returns a only VALID alerts thresholds by matching/combining clinical parameters and effective alert parse settings.
    return allParserSettings
      .map((threshold, i) => {
        const clinicalParameter = filteredParserSettings.find(
          (o) =>
            o.parameterData.name.toLowerCase() ===
            threshold.clinical_parameter.toLowerCase()
        );

        return {
          name: `${get(form, 'monitoringForm.effectiveName')}`,
          uid: `${get(form, 'monitoringForm.effectiveName')}_${
            threshold.clinical_parameter
          }_${i}`,
          rule: chain(form).get('effectiveAlertRules', []).first().value(),
          // TODO: In the future we dont need to use the multiple alerts
          // rules: get(form, 'effectiveAlertRules', {}),
          ...threshold,
          patientForm: form,
          parserSettingsParam: clinicalParameter,
          unit: clinicalParameter?.parameterData?.unit,
        };
      })
      .filter((filteredThresholds) => filteredThresholds.parserSettingsParam);
  });

/**
 * Content reminder list component.
 * @param t
 * @param reminders
 * @param description
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxContentReminderList = ({ t, reminders, description }) => (
  <>
    {description && (
      <Box pb={4} pt={2}>
        {description}
      </Box>
    )}
    {reminders.map((reminder, index) => {
      return (
        <Box
          borderRadius={8}
          border="1px solid #D5D7DE"
          p={1}
          key={`box-content-reminder-list-${index}`}
          mb={reminders?.length === 1 ? 0 : 2}
        >
          <Text
            p="5px"
            fontSize="14px"
            fontWeight={500}
            color={theme.colors.fullShade}
          >
            {reminder?.effectiveName || `${t('Reminder')} ${index + 1}`}
          </Text>
          <BoxContentReminder
            t={t}
            width="auto"
            data={reminder}
            iconProps={{
              style: {
                paddingRight: 10,
                color: theme.colors.fullShade,
              },
            }}
            textProps={{
              color: theme.colors.fullShade,
            }}
          />
        </Box>
      );
    })}
  </>
);

/**
 * Open reminder preview dialog.
 * @param t
 * @param openModal
 * @param closeModal
 * @param reminders
 * @param description
 */
export const onOpenReminderPrevDialog = (
  t,
  openModal,
  closeModal,
  reminders,
  description
) => {
  const reminderList = [...reminders];
  openModal(
    <BoxContentReminderList
      t={t}
      reminders={reminderList}
      description={description}
    />,
    {
      title: `Current ${pluralize(reminderList?.length, 'reminder')}`,
      type: 'confirmation',
      footerOverrides: {
        labels: ['Close'],
        actions: [
          () => {
            closeModal();
          },
        ],
      },
    }
  );
};
