const EVENTS = {
  EXPORT_PATIENT_GROUP_LIST_DATA: 'Export patient group list data',
  PATIENT_GROUP_DOWNLOAD_MESSAGE_LOGS: 'Group message logs download',
  PATIENT_GROUP_DISCHARGE_PROGRAMS: 'Group discharge programs',
  PATIENT_GROUP_DELETE: 'Group deletion',
  PATIENT_GROUP_ENROL_TO_PROGRAM: 'Group enrollment',
  PATIENT_GROUP_EDIT_MEMBERS: 'Edit group members',
  REACTIVATE: 'REACTIVATE',
  CREATE_GROUP: 'Create group',
  CANCELED_CREATE_GROUP: 'Cancel Create group',
  PATIENT_CAREGIVERS: 'Patient Caregivers',
  ADD_CAREGIVER: 'Add Caregiver',
  ADD_NEW_PATIENT: 'Add New Patient',
  CREATE_PATIENT_GROUP: 'Create patient group',
  PATIENT_GROUP_VIEW_MEMBERS: 'View patient group members',
  PATIENT_GROUP_SEND_MESSAGE: 'Send message patient group members',
  SEARCH_PATIENTS: 'Search Patients',
  LIST_ALL_ACTIVE_PATIENTS: 'List All Active Patients',
  LIST_ALL_UNREAD_COMMENTS: 'List All Unread Comments',
  LIST_UNRESOLVED_ALERTS: 'List Unresolved Alerts',
  LIST_NON_COMPLIANT_PATIENTS: 'List Non Compliant Patients',
  LIST_UNREAD_COMMENTS_PATIENTS: 'List Unread Comments Patients',
  SHOW_PATIENT_PROFILE: 'Show Patient Profile',
  DOWNLOAD_PATIENT_SUBMISSIONS: 'Download Patient Submissions',
  CLICK_SEND_MESSAGE: 'Click Send Message',
  DISCHARGE_PATIENT: 'Discharge Patient',
  ENROLL_PATIENT: 'Enroll Patient',
  VIEW_GRAPHS: 'View Graphs',
  VIEW_PATIENT_FORM: 'View Patient Form',
  EDIT_PATIENT_PROFILE: 'Edit Patient Profile',
  LIST_OVERVIEW: 'List Overview',
  LIST_PATIENT_MONITORING: 'List Patient Monitoring',
  LIST_PATIENT_GROUPS: 'List Patient Groups',
  LIST_PATIENT_LIST: 'List Patient List',
  LIST_CLINICAL_ADMIN: 'List Clinical Admin',
  LIST_BILLING: 'List Billing',
  READ_HELP_GUIDE: 'Read Help Guide',
  OPEN_CONTACT_US: 'Open Contact Us',
  LIST_CLINICAL_ALERTS: 'List Clinical Alerts',
  ADD_CLINICAL_ADMIN: 'Add Clinical Admin',
  EXPORT_USER_LIST: 'Export User List',
  EXPORT_PATIENT_LIST: 'Export Patient List',
  EXPORT_PATIENT_MONITORING: 'Export Patient Monitoring',
  REFRESH_RESULTS: 'Refresh Results',
  APPLY_FILTERS: 'Apply Filters',
  RESET_FILTERS: 'Reset Filters',
  LIST_PATIENT_SUBMISSIONS: 'List Patient Submissions',
  VIEW_CLINICAL_SETTINGS: 'View Clinical Settings',
  VIEW_CLINICAL_SETTINGS_EDIT_CLINICAL_USER:
    'View Clinical Settings - Edit Clinical User',
  VIEW_CLINICAL_SETTINGS_EDIT_ACTIVATE_PAUSE_REMINDER:
    'View Clinical Settings - Activate/Pause Reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_SEND_TPL_MSG:
    'View Clinical Settings - Send template message',
  VIEW_CLINICAL_SETTINGS_DISCHARGE_PATIENT:
    'View Clinical Settings - Discharge patient',
  VIEW_CLINICAL_SETTINGS_EDIT_REMINDER:
    'View Clinical Settings - Edit reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_FREQ_REMINDER:
    'View Clinical Settings - Edit reminder frequency',
  VIEW_CLINICAL_SETTINGS_EDIT_DURATION_REMINDER:
    'View Clinical Settings - Edit reminder duration',
  VIEW_CLINICAL_SETTINGS_EDIT_DELETE_REMINDER:
    'View Clinical Settings - Delete reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_ALERT_THRESHOLD:
    'View Clinical Settings - Edit alert threshold',

  // clinical admin
  NO_ACTION: 'No Action',
  EDIT_CLINICAL_ADMIN_PROFILE: 'Edit Clinical Admin Profile',
  DEACTIVATE_CLINICAL_ADMIN_PROFILE: 'Deactivate Clinical Admin Profile',
  REACTIVAE_CLINICAL_ADMIN_PROFILE: 'Reactivate Clinical Admin Profile',

  // caregiver
  EDIT_CAREGIVER_PROFILE: 'Edit Caregiver Profile',
  DEACTIVATE_CAREGIVER_PROFILE: 'Deactivate Caregiver Profile',
  REACTIVAE_CAREGIVER_PROFILE: 'Reactivate Caregiver Profile',
};

export default EVENTS;
