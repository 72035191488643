import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_CLINIC_PUBLIC_SETTINGS = gql`
  query cleoClinicPublicSettings($domain: String!) {
    cleoClinicPublicSettings(domain: $domain) {
      name
      logo
      timezone
      countryCode
      language
      patientDashboardVerification
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_CLINIC_PUBLIC_SETTINGS,
  queryType: 'query',
  baseQueryKey: 'cleoClinicPublicSettings',
  keepPreviousData: false,
});
