import React, { useState } from 'react';
import { Flex, Text, Tooltip } from '@fivehealth/botero';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { get } from 'lodash';
import TableCell from './TableCell';

const STATUS_DESCRIPTIONS = {
  danger: 'Alert (resolved)',
  warning: 'Alert (unresolved)',
  stable: 'Normal',
};

const StatusCell = (props) => {
  const { cell, isSinglePatientView } = props;

  const [show, setShow] = useState(false);
  const hasSubmission = get(cell, 'row.original.hasSubmission');

  const status = hasSubmission
    ? cell.row.original.lastSubmission.status
    : cell.row.original.status;

  if (!status) {
    return <Text pl="8px">-</Text>;
  }
  return (
    <TableCell
      style={{
        paddingLeft: '15px',
      }}
      {...props}
    >
      <Tooltip
        show={show}
        tooltip={<Text color="white">{STATUS_DESCRIPTIONS[status]}</Text>}
      >
        <Flex
          justifyContent="left"
          cursor={isSinglePatientView ? 'pointer' : null}
          data-testid={`table_cell_status-${cell.row.id}-${status}`}
        >
          <StatusIcon
            status={status}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          />
        </Flex>
      </Tooltip>
    </TableCell>
  );
};

export default StatusCell;
