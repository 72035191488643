// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const Cancel = memo<IconProps>(({ color, width = 15, height = 15 }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40039 0.96875C11.1191 0.96875 14.1816 4.03125 14.1816 7.75C14.1816 11.4961 11.1191 14.5312 7.40039 14.5312C3.6543 14.5312 0.619141 11.4961 0.619141 7.75C0.619141 4.03125 3.6543 0.96875 7.40039 0.96875ZM11.2559 3.89453C9.25977 1.89844 6.14258 1.78906 4.00977 3.45703L11.6934 11.1406C13.3613 9.00781 13.252 5.89062 11.2559 3.89453ZM3.51758 11.6328C5.51367 13.6289 8.63086 13.7383 10.7637 12.0703L3.08008 4.38672C1.41211 6.51953 1.52148 9.63672 3.51758 11.6328Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default Cancel;
