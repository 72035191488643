import React from 'react';
import { VictoryLabel } from 'victory';
import { Flex, Text } from '@fivehealth/botero';

export const DataLabel = (props) => {
  const x = props.scale.x(props.x);
  const y = props.scale.y(props.y);
  return <VictoryLabel {...props} x={x} y={y} />;
};

export const OverlayLabel = ({ visible, ...props }) => (
  <VictoryLabel {...props} style={{ fill: props.datum.fill }} />
);

const Legend = ({ values = [], width }) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    width={width || ['100%', '50%']}
    mt={3}
    ml={2}
  >
    {values.filter(Boolean).map(({ icon, label }) => (
      <Flex key={label} mr={[1, 6]}>
        {icon}
        <Text ml={2} fontSize={[11, 13]} fontWeight={500} color="darkestShade">
          {label}
        </Text>
      </Flex>
    ))}
  </Flex>
);

export default Legend;
