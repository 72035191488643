import React from 'react';
import { useTranslation } from 'react-i18next';
import { endOfDay, formatISO, startOfDay, sub, add } from 'date-fns';
import {
  chain,
  get,
  find,
  first,
  values,
  map,
  max,
  maxBy,
  mean,
  min,
  minBy,
  toLower,
  isEmpty,
  isUndefined,
} from 'lodash';

import { useApi, Box } from '@fivehealth/botero';
import { getChartLegendOptions, isDate } from 'AppUtils';

import useClinicalParametersQuery from 'hooks/useClinicalParametersQuery';
import PatientCharts from './PatientCharts';
import Tables from './PatientTables';

const Charts = ({
  showDivider = true,
  patientForms,
  isPatientFacing,
  clinicAsPatient,
  clinic,
  showTableView,
  currentDateRange,
  dateRangeStartDate,
  dateRangeEndDate,
  grandAverageStartDate,
  grandAverageEndDate,
  queriesEnabled,
  chartSetting,
  canNavigateNext,
}) => {
  const { t } = useTranslation();
  const patientFormsClinicalParams = chain(patientForms)
    .flatMap('monitoringForm.clinicalParameters')
    .map((cl) => {
      const { name, uid } = cl;
      return { name, uid };
    })
    .value();

  const chartClinicalParamsExistInPatientFormsClinicalParams =
    chartSetting.clinicalParameters.every((cp) => {
      return patientFormsClinicalParams.some(
        (pfcp) => pfcp.name === cp || pfcp.uid === cp
      );
    });

  const {
    queries: {
      usePatientChart,
      usePatientChartByPatient,
      usePatientChartGrandAverage,
    },
  } = useApi({
    queries: [
      'usePatientChart',
      'usePatientChartByPatient',
      'usePatientChartGrandAverage',
    ],
  });
  const chartLegendOptions = getChartLegendOptions(chartSetting, t);

  const isStartDateValid = isDate(dateRangeStartDate);
  const isEndDateValid = isDate(dateRangeEndDate);

  const { getClinicalParamUidsByNameWithGroups } =
    useClinicalParametersQuery(true);

  const clinicalParamUids = getClinicalParamUidsByNameWithGroups(
    chartSetting.clinicalParameters
  );

  const { data: cleoChartData } = usePatientChart({
    enabled:
      !isPatientFacing &&
      queriesEnabled &&
      isStartDateValid &&
      chartClinicalParamsExistInPatientFormsClinicalParams,
    variables: {
      patientFormUidIn: map(patientForms, ({ uid }) => uid),
      after: formatISO(!isStartDateValid ? new Date() : dateRangeStartDate),
      before: formatISO(!isEndDateValid ? new Date() : dateRangeEndDate),
      parameterIn: clinicalParamUids,
    },
  });

  const { data: cleoChartDataPatient } = usePatientChartByPatient({
    enabled:
      isPatientFacing &&
      queriesEnabled &&
      isStartDateValid &&
      chartClinicalParamsExistInPatientFormsClinicalParams,
    variables: {
      after: formatISO(!isStartDateValid ? new Date() : dateRangeStartDate),
      before: formatISO(!isEndDateValid ? new Date() : dateRangeEndDate),
    },
  });

  const { data: cleoGrandAverageData } = usePatientChartGrandAverage({
    enabled:
      queriesEnabled && chartClinicalParamsExistInPatientFormsClinicalParams,
    staleTime: Infinity,
    variables: {
      patientFormUidIn: map(patientForms, ({ uid }) => uid),
      after: formatISO(grandAverageStartDate?.current),
      before: formatISO(grandAverageEndDate?.current),
    },
  });

  const effectiveAlertRules = chain(patientForms)
    .flatMap((form) => get(form, 'effectiveAlertRules', []))
    .value();

  const patientChartData = get(
    isPatientFacing ? cleoChartDataPatient : cleoChartData,
    'statistics',
    []
  );

  const chartLabelsTxn = Object.values(chartSetting?.legend ?? {})
    ?.flatMap(({ labelTxn }) => labelTxn)
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...curr,
      }),
      []
    );

  const getChartDataByType = (type, chartData = []) =>
    (chartData || []).filter(({ clinicalParameter }) =>
      get(clinicalParameter, 'name', '').match(new RegExp(type, 'i'))
    );

  const grandAverageData = get(cleoGrandAverageData, 'statistics', []).filter(
    (data) => clinicalParamUids.includes(data?.clinicalParameter?.uid)
  );

  let parsedChartData = [];

  if (patientChartData?.length > 0) {
    /* eslint-disable no-plusplus */
    for (let i = 0; i < clinicalParamUids.length; i++) {
      const clinicalParameter = clinicalParamUids[i];

      if (toLower(chartSetting.key) === 'bg' && i === 1) {
        const timeTakeSearchType = toLower(
          patientChartData
            ?.map(({ clinicalParameter: cp }) => cp)
            .find((cp) => cp.uid === clinicalParameter)?.name
        );

        const timeTakenData = getChartDataByType(
          timeTakeSearchType,
          patientChartData
        );
        parsedChartData = parsedChartData.map((data) => ({
          ...data,
          numbers: get(data, 'values', []),
          values: get(data, 'values', []).map((value, valueIdx) => {
            const label = get(
              timeTakenData[0].variables,
              `submission_value[${clinicalParameter}]`
            )[valueIdx];

            return {
              value,
              label: chartLabelsTxn[label] || (label ?? 'N/A'),
            };
          }),
          submissionValues: get(data, 'values', []).map((value, valueIdx) => {
            const label = get(
              timeTakenData[0].variables,
              `submission_value[${clinicalParameter}]`
            )[valueIdx];
            return {
              value,
              label: chartLabelsTxn[label] || (label ?? 'N/A'),
            };
          }),
        }));
        break;
      }

      const serieConfig = chartSetting?.series[i];

      const { color, symbol } = get(serieConfig, 'symbolStyle', {});

      const chartData = patientChartData.find(
        (cd) => cd?.clinicalParameter?.uid === clinicalParameter
      );

      if (chartData) {
        const patientSubmissionValues = chartData?.numbers;

        const patientSubmittionTimes = chartData.submissionTimes;

        const maxVal = max(patientSubmissionValues);

        const minVal = min(patientSubmissionValues);

        parsedChartData.push({
          clinicalParameter: chartData?.clinicalParameter,
          average: mean(patientSubmissionValues.map((n) => parseFloat(n))),
          count: patientSubmissionValues.length,
          min: parseFloat(minVal),
          max: parseFloat(maxVal),
          lineStyle: serieConfig?.lineStyle,
          submissionTimes: patientSubmittionTimes,
          submissionValues: patientSubmissionValues,
          values: patientSubmissionValues.map((n) => parseFloat(n)),
          symbol,
          theme: { color },
          description: serieConfig?.title,
        });
      }
    }
  }

  const { data: nextPeriodData } = usePatientChart({
    enabled:
      queriesEnabled && chartClinicalParamsExistInPatientFormsClinicalParams,
    variables: {
      patientFormUidIn: map(patientForms, ({ uid }) => uid),
      after: !isStartDateValid
        ? formatISO(new Date())
        : formatISO(
            currentDateRange > 1
              ? startOfDay(
                  add(dateRangeStartDate, { days: currentDateRange + 1 })
                )
              : startOfDay(
                  add(dateRangeStartDate, {
                    days: 1,
                  })
                )
          ),
      before: !isEndDateValid
        ? formatISO(new Date())
        : formatISO(
            currentDateRange > 1
              ? endOfDay(add(dateRangeEndDate, { days: currentDateRange + 1 }))
              : endOfDay(
                  add(dateRangeStartDate, {
                    days: 1,
                  })
                )
          ),
      parameterIn: clinicalParamUids,
    },
  });

  const nextPeriodChartData = get(nextPeriodData, 'statistics', []);

  const { data: prevPeriodData } = usePatientChart({
    enabled:
      queriesEnabled && chartClinicalParamsExistInPatientFormsClinicalParams,
    variables: {
      patientFormUidIn: map(patientForms, ({ uid }) => uid),
      after: formatISO(
        !isStartDateValid
          ? new Date()
          : startOfDay(
              sub(dateRangeStartDate, {
                days: currentDateRange > 1 ? currentDateRange + 1 : 1,
              })
            )
      ),
      before: formatISO(
        !isEndDateValid
          ? new Date()
          : endOfDay(
              sub(dateRangeEndDate, {
                days: currentDateRange > 1 ? currentDateRange + 1 : 1,
              })
            )
      ),
      parameterIn: clinicalParamUids,
    },
  });

  if (!chartClinicalParamsExistInPatientFormsClinicalParams) {
    return null;
  }

  const prevPeriodChartData = get(prevPeriodData, 'statistics', []);

  const getThresholdValue = (clinicalParameter, thresholdId) => {
    // To aggregate and get the most constraint threshold
    //
    // example:
    //   alertRule1 - HR between 60 - 100
    //   alertRule2 - HR between 50 - 90
    //
    //   finalRule - HR between 60 - 90
    //
    const thresholds = chain(effectiveAlertRules)
      .flatMapDeep(({ parserSettings }) => values(parserSettings))
      .groupBy((threshold) => threshold.clinical_parameter)
      .values()
      .map((alertRules) => {
        let alertRule = first(alertRules);

        const minValues = chain(alertRules)
          .map((rule) => get(rule, 'min'))
          .filter((v) => !isUndefined(v))
          .value();

        const maxValues = chain(alertRules)
          .map((rule) => get(rule, 'max'))
          .filter((v) => !isUndefined(v))
          .value();

        if (!isEmpty(minValues)) {
          alertRule = {
            ...alertRule,
            min: max(minValues),
          };
        }

        if (!isEmpty(maxValues)) {
          alertRule = {
            ...alertRule,
            max: min(maxValues),
          };
        }

        return {
          ...alertRule,
          clinical_parameter: toLower(alertRule.clinical_parameter),
        };
      })
      .value();

    let rule = find(thresholds, {
      clinical_parameter: toLower(clinicalParameter),
    });

    // Serge TODO: Can we get thresholds without the name in it.
    const bpThresholds = thresholds.filter(
      ({ clinical_parameter: ruleClinicalParameter }) =>
        ruleClinicalParameter.match(/blood pressure/i)
    );

    if (clinicalParameter === 'blood pressure' && thresholdId === 'min') {
      rule = minBy(bpThresholds, 'min');
    }

    if (clinicalParameter === 'blood pressure' && thresholdId === 'max') {
      rule = maxBy(bpThresholds, 'max');
    }

    if (rule) {
      return rule[thresholdId];
    }
    return null;
  };

  if (!dateRangeEndDate) return null;

  return (
    <>
      {!showTableView && (
        <PatientCharts
          showDivider={showDivider}
          clinic={isPatientFacing ? clinicAsPatient : clinic}
          getThresholdValue={getThresholdValue}
          currentDateRange={currentDateRange}
          startDate={dateRangeStartDate}
          endDate={dateRangeEndDate}
          chartSetting={chartSetting}
          chartData={patientChartData}
          parsedChartData={parsedChartData}
          prevPeriodData={getChartDataByType(
            chartSetting?.key,
            prevPeriodChartData
          )}
          nextPeriodData={
            canNavigateNext
              ? getChartDataByType(chartSetting?.key, nextPeriodChartData)
              : []
          }
          grandAverageData={getChartDataByType(
            chartSetting?.key,
            grandAverageData
          )}
        />
      )}
      {showTableView && (
        <Box testid="graphs_table_view" mt={3}>
          <Tables
            clinic={isPatientFacing ? clinicAsPatient : clinic}
            currentDateRange={currentDateRange}
            chartSetting={chartSetting}
            parsedChartData={parsedChartData}
            chartLegendOptions={chartLegendOptions}
            prevPeriodData={getChartDataByType(
              chartSetting?.key,
              prevPeriodChartData
            )}
            nextPeriodData={getChartDataByType(
              chartSetting?.key,
              nextPeriodChartData
            )}
            grandAverageData={getChartDataByType(
              chartSetting?.key,
              grandAverageData
            )}
          />
        </Box>
      )}
    </>
  );
};

export default Charts;
