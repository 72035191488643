// import liraries
import {
  Box,
  Flex,
  H1,
  Body,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  DataTable,
  Text,
  ActionMenuItem,
  ActionMenuText,
  ActionMenu,
  useApi,
  DangerButton,
  H2,
  SearchInput,
} from '@fivehealth/botero';
import qs from 'qs';

import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import EVENTS from 'constants/events';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionDropdown from 'components/ActionDropdown/ActionDropdown';
import { useMediaQuery } from 'react-responsive';
import { EventPage, Colors, DataType } from 'constants';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import {
  faChevronDown,
  faCog,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { clone, flatMap, get, isEmpty } from 'lodash';
import moment from 'moment';
import { useModal } from 'context/ModalContext';
import { FormProvider, useForm } from 'react-hook-form';
import MessageTemplateModal from 'views/PatientsList/PatientMessageTemplate.modal';
import AddFormModal from 'views/PatientsList/PatientShow/AddForm.modal';
import { PATIENT_GROUP_SELECTION_OPTION } from 'Config';
import { useAppData } from 'context/AppDataContext';
import SendMessageButton from 'components/SendMessageButton/SendMessageButton';
import TableLoader from 'components/Table/TableLoader';
import TabWithFilter from 'components/Tab/TabWithFilter';
// import { openExportDataModal } from 'components/ExportData';
// import useDeliveriesExportedFormats from 'api/queries/useDeliveriesExportedFormats';
// import { getMessageDeliveriesExport } from 'AppUtils';
// import { useQueryClient } from 'react-query';
// import { useCookies } from 'react-cookie';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import { Filters } from 'components/Filters/Filters';
import CreateGroup from './CreateGroup';
import EditGroupInfo from './EditGroupInfo';
import EditGroupMembers from './EditGroupMembers';

const PatientGroups = memo((props) => {
  const {
    searchText,
    setSearchText,
    onSearch,
    patientGroups: groups,
    onRefresh: refetchPatientGroups,
    isLoading: loadingPatientGroups,
    showLoadMoreButton = true,
    patientGroupsDateRangeFilters: dateRangeFilters,
    groupTypeFilters,
    onApplyFilters,
    onResetFilters,
    clinicalUserFilters,
  } = props;

  const { t } = useTranslation();

  const {
    queries: { usePatientGroupDelete, usePatientGroupsUserCreated },
  } = useApi({
    queries: ['usePatientGroupDelete', 'usePatientGroupsUserCreated'],
  });

  const { mutateAsync: deleteGroup } = usePatientGroupDelete({
    variables: {},
  });

  const { data: patientGroupsUserCreated } = usePatientGroupsUserCreated({
    enabled: true,
  });

  const { sendMessage } = useSendMessageAsync();

  const [createGroup, setCreateGroup] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const [enableRowSelect, setEnableRowSelect] = useState(false);

  const toggleAllPageRowsSelectedRef = useRef();
  const toggleAllRowsSelectedRef = useRef();
  const dropdownRef = useRef();
  const { openModal, closeModal } = useModal();
  const actionMenuOpened = useRef(false);
  const [requesting, setRequesting] = useState(false);
  const [rowsSelectedCount, setRowsSelectedCount] = useState(0);
  const [removedSelection, setRemovedSelection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectionOptionChosen, setSelectionOptionChosen] = useState();

  const { event, sidebarOpened } = useAppData();

  const [autoResetSelectedRows, setAutoResetSelectedRows] = useState(false);

  const [editMembers, setEditMembers] = useState();
  const [viewMode, setViewMode] = useState(true);

  const selectedRows = useRef([]);

  const filterOptions = useMemo(() => {
    const alteredClinicalUserFilters = {
      ...clinicalUserFilters,
      title: t('Created by'),
    };
    return [
      dateRangeFilters,
      groupTypeFilters,
      alteredClinicalUserFilters,
    ].filter(Boolean);
  }, [dateRangeFilters, groupTypeFilters, clinicalUserFilters, t]);

  // const { client } = useApi();
  // const queryClient = useQueryClient();
  // const [cookies] = useCookies([Config.cookie.name]);
  // const token = cookies && cookies[Config.cookie.name];

  const [selectedGroupToEdit, setSelectedGroupToEdit] = useState();

  /* const { data: deliveriesExportedFormats } = useDeliveriesExportedFormats({
    enabled: true,
  }); */

  const totalUserCreatedGroupsCount = useMemo(
    () => patientGroupsUserCreated?.totalCount ?? 0,
    [patientGroupsUserCreated?.totalCount]
  );

  const totalCount = useMemo(
    () => props?.totalFetchDataCount,
    [props.totalFetchDataCount]
  );

  const userCreatedGroups = useMemo(
    () => flatMap(patientGroupsUserCreated?.groups, ({ node }) => node) ?? [],
    [patientGroupsUserCreated?.groups]
  );

  const { control } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const onEditGroupInfo = useCallback(() => {
    openModal(
      <FormProvider {...{ control }}>
        <EditGroupInfo
          closeModal={() => {
            setSelectedGroupToEdit();
            closeModal();
            refetchPatientGroups();
          }}
          selectedGroup={selectedGroupToEdit}
        />
      </FormProvider>,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
        overlayProps: {
          onClick: (e) => {
            e.preventDefault();
          },
        },
      }
    );
  }, [
    control,
    openModal,
    selectedGroupToEdit,
    closeModal,
    refetchPatientGroups,
  ]);

  const cancelSelectedRows = useCallback(
    (deselect = false) => {
      setSelectionOptionChosen();
      setAutoResetSelectedRows(true);
      setRowsSelectedCount(0);
      toggleAllRowsSelectedRef.current(false);
      toggleAllPageRowsSelectedRef.current(false);
      selectedRows.current = [];
      if (!deselect) {
        setEnableRowSelect(false);
        onSearch('');
      }
    },
    [onSearch]
  );

  const selectAllPagesRows = useCallback(() => {
    selectedRows.current = clone(userCreatedGroups);
    setAutoResetSelectedRows(false);
    setEnableRowSelect(true);
    setRowsSelectedCount(totalUserCreatedGroupsCount);
    setSelectionOptionChosen(PATIENT_GROUP_SELECTION_OPTION.ALL_ROWS);
    setTimeout(() => {
      toggleAllPageRowsSelectedRef.current(true);
    }, 200);
  }, [userCreatedGroups, totalUserCreatedGroupsCount]);

  // const selectAllVisibleRows = useCallback(() => {
  //   selectedRows.current = clone(groups);
  //   setAutoResetSelectedRows(false);
  //   setEnableRowSelect(true);
  //   setRowsSelectedCount(groups.filter((g) => !g.disableCheckbox).length);
  //   setSelectionOptionChosen(PATIENT_GROUP_SELECTION_OPTION.VISIBLE_ROWS);
  //   setTimeout(() => {
  //     toggleAllRowsSelectedRef.current(true);
  //   }, 200);
  // }, [groups]);

  const triggerCustomSelect = useCallback(() => {
    setAutoResetSelectedRows(false);
    setSelectionOptionChosen(PATIENT_GROUP_SELECTION_OPTION.CUSTOM);
    setEnableRowSelect(true);
    setRowsSelectedCount(0);
    setTimeout(() => {
      toggleAllRowsSelectedRef.current(false);
    }, 200);
  }, []);

  const isDynamicGroup = (row) => row.original?.isSystemGroup;

  const actions = useMemo(
    () => [
      {
        id: 'selectAll',
        label: t('Select all groups'),
        onClick: () => {
          selectAllPagesRows();
        },
        enable: !searchText,
      },
      {
        id: 'customSelections',
        divider: 'true',
        label: t('Custom selection'),
        onClick: () => {
          triggerCustomSelect();
        },
      },
    ],
    [t, selectAllPagesRows, triggerCustomSelect, searchText]
  );

  const onSubmitMessageCreate = useCallback(
    async ({ template, userVariables, monitoringForm, patientEventUid }) => {
      const templateId = get(template, 'uid');

      const patientGroupUids = selectedRows.current.map(
        ({ original }) => original.uid
      );

      const payload = {
        patientGroupUids,
        messageTemplateSetUid: templateId,
        monitoringFormUid: monitoringForm?.uid,
        userVariables,
      };

      if (patientEventUid) {
        payload.patientEventUid = patientEventUid;
      }

      setEnableRowSelect(false);

      const result = await sendMessage(payload);
      return result;
    },
    [sendMessage]
  );

  const onSendBulkMessage = useCallback(() => {
    const names = selectedRows.current
      .map(({ original }) => original.name)
      .join(', ');

    openModal(
      <MessageTemplateModal
        isGroup
        selectedGroups={names}
        closeModal={() => {
          closeModal();
          selectedRows.current = [];
        }}
        onSubmit={onSubmitMessageCreate}
      />,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }
    );
  }, [closeModal, onSubmitMessageCreate, openModal]);

  const onAddFormBulk = useCallback(async () => {
    const names = selectedRows.current
      .map(({ original }) => original.name)
      .join(', ');

    const uids = selectedRows.current.map(({ original }) => original.uid);

    openModal(
      <AddFormModal
        t={t}
        isGroup
        groupNames={names}
        patientGroupUids={uids}
        onClose={() => {
          setEnableRowSelect(false);
          closeModal();
        }}
        enrolledMonitoringFormUidsParam={[]}
      />,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }
    );
  }, [openModal, t, closeModal]);

  const onDeleteGroup = useCallback(async () => {
    const selectedGroups = selectedRows.current;

    setRequesting(true);

    const calls = [];

    selectedGroups.forEach(async (group) => {
      calls.push(
        deleteGroup({
          input: {
            uid: group.uid ?? group.id,
          },
        })
      );
    });

    await Promise.all(calls);

    await refetchPatientGroups();

    setEnableRowSelect(false);
    setRequesting(false);
  }, [deleteGroup, refetchPatientGroups]);

  const onDeleteGroupConfirm = useCallback(() => {
    openModal(
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <H2>{t('Are you sure')}?</H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>
        <Text fontWeight={400} fontSize={2} mb={4}>
          {t(
            `Are you sure you want to delete the selected group(s). This action cannot be undone.`
          )}
        </Text>
        <Flex justifyContent="end">
          <SecondaryOutlinedButton
            mr={2}
            onClick={() => {
              closeModal();
              selectedRows.current = [];
            }}
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <DangerButton
            onClick={() => {
              closeModal();
              onDeleteGroup();
            }}
          >
            {t('Yes, Delete')}
          </DangerButton>
        </Flex>
      </Box>,

      {
        style: {
          width: isMobile ? '100%' : '30%',
        },
      }
    );
  }, [closeModal, openModal, t, isMobile, onDeleteGroup]);

  /* const invokeMessageDeliveriesExport = useCallback(
    async ({ outputType, exportDestination, group }) => {
      const { cleoDeliveries } = await getMessageDeliveriesExport(
        queryClient,
        client,
        exportDestination?.email,
        outputType,
        {
          patientGroupUidIn: [group?.uid],
        }
      );
      if (!exportDestination?.email) {
        window.open(
          // eslint-disable-next-line no-template-curly-in-string
          cleoDeliveries?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    },
    [client, queryClient, token]
  ); */

  /* const onDownloadMessageLogs = useCallback(
    async (row) => {
      const group = row.original;

      openExportDataModal({
        modalParams: {
          title: `Download message logs for ${group?.name}`,
          showBody: true,
          showExportDataFormats: true,
          showExportDestination: true,
          patient: group,
          onSubmitCallback: (param) => {
            invokeMessageDeliveriesExport({ ...param, group });
          },
        },
        dataType: EXPORT_DATA_TYPE.messages,
        dataFormats: deliveriesExportedFormats,
        openModal,
        closeModal,
        logEventProps: {
          page: 'Patient groups',
          eventName: `${EVENTS.PATIENT_GROUP_DOWNLOAD_MESSAGE_LOGS} - Download message logs for ${group?.name}`,
        },
      });
    },
    [
      closeModal,
      deliveriesExportedFormats,
      invokeMessageDeliveriesExport,
      openModal,
    ]
  ); */

  const ActionsCell = useCallback(
    ({ cell }) => (
      <Flex
        alignItems="center"
        justifyContent="end"
        height={[56, 56, null]}
        pl={[2, 2, null]}
        pr={[2, 2, null]}
      >
        {!isMobile && (
          <ActionDropdown
            testid="patient_group_action_send_message_menu"
            label={
              <SendMessageButton
                onClick={() => {
                  selectedRows.current = [cell.row];
                  setEnableRowSelect(false);
                  onSendBulkMessage();
                }}
              />
            }
            dropdownOptions={[]}
          />
        )}

        <ActionDropdown
          onOpenChange={(open) => {
            if (open) {
              actionMenuOpened.current = open;
            } else {
              setTimeout(() => {
                actionMenuOpened.current = open;
              }, 1500);
            }
          }}
          testid="patient_group_action_menu"
          label={
            isMobile && (
              <Flex>
                <Box>
                  <FAIcon icon={faEllipsisV} />
                </Box>
                <Text ml={2}>{t('More')}</Text>
              </Flex>
            )
          }
          dropdownOptions={[
            !isDynamicGroup(cell.row) && {
              key: 'groups_action_menu_view_members',
              testid: 'groups_action_menu_view_members',
              label: t('View members'),
              logEventProps: {
                page: EventPage.patientGroups,
                eventName: EVENTS.PATIENT_GROUP_VIEW_MEMBERS,
              },
              onClick: () => {
                setEditMembers(cell.row?.original);
              },
            },
            !isDynamicGroup(cell.row) && {
              key: 'groups_action_menu_edit_info',
              testid: 'groups_action_menu_edit_info',
              label: t('Edit group details'),
              logEventProps: {
                page: EventPage.patientGroups,
              },
              onClick: () => {
                setSelectedGroupToEdit(cell.row?.original);
              },
            },
            !isDynamicGroup(cell.row) && {
              key: 'groups_action_menu_edit_members',
              testid: 'groups_action_menu_edit_members',
              label: t('Add/Remove members'),
              logEventProps: {
                page: EventPage.patientGroups,
                eventName: EVENTS.PATIENT_GROUP_EDIT_MEMBERS,
              },
              onClick: () => {
                setViewMode(false);
                setEditMembers(cell.row?.original);
              },
            },
            {
              key: 'groups_action_menu_enrol_into_program',
              testid: 'groups_action_menu_enrol_into_program',
              label: t('Add program'),
              logEventProps: {
                page: EventPage.patientGroups,
                eventName: EVENTS.PATIENT_GROUP_ENROL_TO_PROGRAM,
              },
              onClick: () => {
                selectedRows.current = [cell.row];
                setEnableRowSelect(false);
                onAddFormBulk();
              },
            },
            {
              key: 'groups_action_menu_send_message',
              testid: 'groups_action_menu_send_message',
              label: t('Send message'),
              logEventProps: {
                page: EventPage.patientGroups,
                eventName: EVENTS.PATIENT_GROUP_SEND_MESSAGE,
              },
              onClick: () => {
                selectedRows.current = [cell.row];
                setEnableRowSelect(false);
                onSendBulkMessage();
              },
            },
            !isDynamicGroup(cell.row) && {
              key: 'groups_action_menu_delete_group',
              testid: 'groups_action_menu_delete_group',
              divider: true,
              label: t('Delete group'),
              logEventProps: {
                page: EventPage.patientGroups,
                eventName: EVENTS.PATIENT_GROUP_DELETE,
              },
              onClick: () => {
                selectedRows.current = [cell.row];
                setEnableRowSelect(false);
                onDeleteGroupConfirm();
              },
              labelProps: {
                color: 'danger',
              },
            },
          ].filter(Boolean)}
        />
      </Flex>
    ),
    [isMobile, t, onSendBulkMessage, onAddFormBulk, onDeleteGroupConfirm]
  );

  const renderGroupName = ({ row }) => row.original.name;

  const renderGroupCreator = ({ row }) =>
    isDynamicGroup(row) ? (
      <Text>System generated</Text>
    ) : (
      <Text>{row.original?.createdBy?.name ?? 'Unknown'}</Text>
    );

  const renderGroupDescription = ({ row }) => row.original.description;

  const renderMemberCount = ({ row }) => (
    <Body pl={5}>
      <Text>{row.original?.patientCount ?? 'N/A'}</Text>
    </Body>
  );

  const renderCreatedInfo = ({ row }) =>
    moment(row.original.createdOn).format('DD MMM YYYY HH:mm');

  const columns = useMemo(
    () => [
      {
        id: 'NAME',
        accessor: 'name',
        Header: t('Group name'),
        Cell: renderGroupName,
      },
      {
        id: 'description',
        Cell: renderGroupDescription,
        Header: t('Description'),
        disableSortBy: true,
      },
      {
        id: 'PATIENT_COUNT',
        accessor: 'patientCount',
        Cell: renderMemberCount,
        Header: t('Active patients'),
      },
      {
        id: 'CREATED_BY',
        accessor: 'createdBy.name',
        disableSortBy: true,
        Cell: renderGroupCreator,
        Header: t('Created by'),
      },
      {
        id: 'CREATED_ON',
        accessor: 'createdOn',
        Cell: renderCreatedInfo,
        Header: t('Created on'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ActionsCell, enableRowSelect, t]
  );

  if (
    !enableRowSelect &&
    columns.filter((c) => c.id === 'actions').length === 0
  ) {
    columns.push({
      id: 'actions',
      Cell: (p) => ActionsCell(p),
      Header: '',
      disableSortBy: true,
    });
  }

  const renderRowSelectInfo = useCallback(
    ({
      selectedFlatRows,
      toggleAllRowsSelected,
      toggleAllPageRowsSelected,
    }) => {
      toggleAllRowsSelectedRef.current = toggleAllRowsSelected;
      toggleAllPageRowsSelectedRef.current = toggleAllPageRowsSelected;

      // There should not be a lot groups that will need to do this.
      // selectedRows.current = selectedFlatRows;
      // setRowsSelectedCount(selectedFlatRows.length);

      return (
        <Flex
          mr={3}
          style={{
            borderRadius: 5,
            position: 'fixed',
            bottom: '24px',
            width: sidebarOpened ? '80%' : '91%',
          }}
          p={2}
          justifyContent="space-between"
          alignItems="center"
          bg="lightBlue"
        >
          <Flex alignItems="center">
            <Text color="primary" fontWeight="bold" mr={1}>
              {rowsSelectedCount} {t('selected')}
            </Text>

            <SecondaryOutlinedButton
              onClick={() => cancelSelectedRows(true)}
              mr={2}
              bg="white"
            >
              {t('Deselect all')}
            </SecondaryOutlinedButton>
          </Flex>

          <Flex alignItems="center">
            <SecondaryOutlinedButton
              onClick={() => cancelSelectedRows()}
              mr={2}
              bg="white"
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>

            <Box>
              <ActionMenu
                minWidth={150}
                dropdownRef={(r) => {
                  dropdownRef.current = r;
                }}
                label={
                  <SecondaryOutlinedButton
                    hover="green"
                    selectedPatients={selectedFlatRows.length > 0}
                    borderColor="transparent"
                    bgHovered={
                      rowsSelectedCount > 0 ? 'primary' : Colors.lightBlue
                    }
                    bg={rowsSelectedCount > 0 ? 'primary' : Colors.lightBlue}
                    borderRadius={8}
                    endIcon={
                      <FAIcon
                        icon={faChevronDown}
                        fontSize="14px"
                        style={{ color: 'white' }}
                      />
                    }
                  >
                    <Body
                      width="100%"
                      color="white"
                      fontFamily="Inter"
                      small
                      medium
                    >
                      {t('Bulk actions')}
                    </Body>
                  </SecondaryOutlinedButton>
                }
              >
                {rowsSelectedCount === 0 && (
                  <ActionMenuItem>
                    <ActionMenuText>{t('No rows selected')}</ActionMenuText>
                  </ActionMenuItem>
                )}
                {rowsSelectedCount > 0 && (
                  <>
                    {/* <ActionMenuItem onClick={onSendBulkMessage}>
                        <ActionMenuText>{t('Send message')}</ActionMenuText>
                      </ActionMenuItem>

                      <ActionMenuItem onClick={onAddFormBulk}>
                        <ActionMenuText>
                          {t('Enroll Into program')}
                        </ActionMenuText>
                      </ActionMenuItem> */}

                    {/* <ActionMenuItem onClick={confirmDischargeGroup}>
                        <ActionMenuText>
                          {t('Discharge from programs')}
                        </ActionMenuText>
                      </ActionMenuItem>

                      <ActionMenuItem divider onClick={confirmDeactivateGroup}>
                        <ActionMenuText color={Colors.red}>
                          {t('Deactivate')}
                        </ActionMenuText>
                      </ActionMenuItem> */}

                    <ActionMenuItem onClick={onDeleteGroupConfirm}>
                      <ActionMenuText color={Colors.red}>
                        {t('Delete selected groups')}
                      </ActionMenuText>
                    </ActionMenuItem>

                    {/* <ActionMenuItem onClick={() => console.log('object')}>
                    <ActionMenuText>{t('Duplicate')}</ActionMenuText>
                  </ActionMenuItem>

                  <ActionMenuItem onClick={() => console.log('object')}>
                    <ActionMenuText>{t('Download data')}</ActionMenuText>
                  </ActionMenuItem>

                  <ActionMenuItem onClick={() => console.log('object')}>
                    <ActionMenuText>
                      {t('Merge to create a new group')}
                    </ActionMenuText>
                  </ActionMenuItem>

                  */}
                  </>
                )}
              </ActionMenu>
            </Box>
          </Flex>
        </Flex>
      );
    },
    [
      rowsSelectedCount,
      onDeleteGroupConfirm,
      t,
      sidebarOpened,
      cancelSelectedRows,
    ]
  );

  const handleTableRowClick = (row) => {
    if (enableRowSelect && row?.original?.disableCheckbox) return;
    if (!actionMenuOpened.current) {
      setAutoResetSelectedRows(false);
      if (!enableRowSelect) {
        setEditMembers(row?.original);
      }
    } else {
      return;
    }

    if (autoResetSelectedRows) {
      setAutoResetSelectedRows(false);
    }

    const index = removedSelection
      ? removedSelection.findIndex(
          (selectedRow) => selectedRow.uid === row.original.uid
        )
      : -1;

    const selectedRowIndex = selectedRows.current
      ? selectedRows.current.findIndex(
          (selectedRow) => selectedRow.uid === row.original.uid
        )
      : -1;

    if (row.isSelected) {
      setRemovedSelection([...removedSelection, row]);
      setRowsSelectedCount(rowsSelectedCount - 1);

      selectedRows.current.splice(selectedRowIndex, 1);
      selectedRows.current = clone(selectedRows.current);
    } else {
      removedSelection.splice(index, 1);
      setRemovedSelection([...removedSelection]);
      setRowsSelectedCount(rowsSelectedCount + 1);

      selectedRows.current = clone([...selectedRows.current, row]);
    }

    row.toggleRowSelected();
  };

  const onCancelGroupSelection = () => {
    setViewMode(false);
    setEnableRowSelect(false);
  };

  const toggleGroupCreationView = () => {
    setCreateGroup(!createGroup);
    // Set order field to enrollment date
    if (!createGroup) {
      let requestParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      requestParams = { ...requestParams, orderField: 'ENROLLMENT_DATE' };
      const queryParams = qs.stringify(requestParams, {
        encodeValuesOnly: true,
      });
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${queryParams}`
      );
    }
    setEnableRowSelect(false);
    setTimeout(() => {
      refetchPatientGroups();
    }, 500);
  };

  const hideGroupList = useMemo(
    () => createGroup || editMembers,
    [createGroup, editMembers]
  );

  const resetToGroupsView = () => {
    setEditMembers(false);
    setSelectedGroupToEdit(false);
    setCreateGroup(false);
    setViewMode(true);
    setRowsSelectedCount(0);
    selectedRows.current = [];
  };

  event.useSubscription((val) => {
    if (val === 'patient-groups') {
      resetToGroupsView();
    }
  });

  const hasCreatedGroups = useMemo(
    () => groups.filter((g) => !g.isSystemGroup).length > 0,
    [groups]
  );

  const onFilterChange = useCallback((filters) => {
    onApplyFilters(filters);
    if (isEmpty(filters)) {
      onResetFilters?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box py={4}>
      {selectedGroupToEdit && onEditGroupInfo()}
      {!hideGroupList && (
        <>
          <Flex justifyContent="space-between">
            <Box>
              <H1 data-testid="patient_list_header_title_page">
                {t('Patient Groups')}
              </H1>
              <Body pt={2}>
                {t(
                  'Create patient groups to perform bulk actions (e.g. send broadcast message)'
                )}
              </Body>
            </Box>

            <Box display={['none', 'initial', 'initial']}>
              <Flex pt={[2, 0]} pl={2}>
                <PrimaryButton
                  disabled={enableRowSelect}
                  onClick={toggleGroupCreationView}
                  logEventProps={{
                    page: 'Patient groups',
                    eventName: EVENTS.CREATE_PATIENT_GROUP,
                  }}
                  borderRadius={8}
                  pt={2}
                  pb={2}
                  height={40}
                  mb={[1, 0]}
                  minWidth={150}
                  data-testid="patient_list_create_group_btn"
                >
                  {t('Create group')}
                </PrimaryButton>
              </Flex>
            </Box>
          </Flex>

          <Flex pt={4} pb={3} justifyContent="space-between">
            <SearchInput
              inputStyle={{
                paddingBottom: '6px',
                paddingTop: '8px',
              }}
              t={t}
              hideRefresh={isMobile}
              placeholder={t('Search by group name & description')}
              inputMinWidth={isMobile ? '100%' : '400px'}
              onSearch={onSearch}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onRefresh={props.onRefresh}
              isRefreshing={props.isRefetching}
              lastUpdated={props.lastUpdated.current}
              totalCount={totalCount}
              pageCount={groups?.length || props.pageCount}
              refreshButtonProps={{
                style: {
                  paddingTop: '12px',
                  paddingBottom: '12px',
                },
              }}
            />

            {hasCreatedGroups && !isMobile && (
              <DropdownMenu
                marginLeft={-81}
                fontWeight="500"
                label={
                  <SecondaryOutlinedButton
                    onClick={() => {}}
                    startIcon={
                      <FAIcon icon={faCog} fontSize={14} color="darkestShade" />
                    }
                    borderRadius="8px"
                  >
                    <Body
                      fontSize="14px"
                      fontFamily="Inter,sans-serif"
                      fontWeight="500"
                    >
                      {t('Actions')}
                    </Body>
                  </SecondaryOutlinedButton>
                }
                actions={actions}
                width="200px"
              />
            )}
          </Flex>

          <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
            <Filters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
          </Box>

          <Box pb={10} overflow="scroll" className="scrollbar-invisible">
            {(loadingPatientGroups || requesting) && <TableLoader />}
            <Box
              style={{
                display: loadingPatientGroups || requesting ? 'none' : 'block',
              }}
            >
              <DataTable
                mb={4}
                show="false"
                data={groups}
                initialSortBy={{
                  orderField: 'NAME',
                  orderDesc: 'desc',
                }}
                hideHeaderSelectionCheckBox
                {...props}
                onFetchNextPage={props.fetchNextPage}
                showLoadMore={
                  showLoadMoreButton &&
                  selectionOptionChosen !==
                    PATIENT_GROUP_SELECTION_OPTION.ALL_ROWS
                }
                showRowSelectedBar={enableRowSelect}
                renderRowSelectInfo={renderRowSelectInfo}
                onRowClick={handleTableRowClick}
                {...{ enableRowSelect }}
                autoResetSelectedRows={autoResetSelectedRows}
                columns={columns}
                noResultsProps={{
                  avatarProps: { height: '200px', width: '200px' },
                }}
              />
            </Box>
          </Box>
        </>
      )}

      {/* Create Group */}
      {createGroup && (
        <CreateGroup
          onCancel={onCancelGroupSelection}
          toggleView={toggleGroupCreationView}
        />
      )}

      {/* Edit group members */}
      {editMembers && (
        <EditGroupMembers
          toggleEditMode={() => setViewMode(!viewMode)}
          viewMode={viewMode}
          toggleView={() => {
            setEditMembers();
            setViewMode(true);
            setTimeout(() => {
              refetchPatientGroups();
            }, 500);
          }}
          selectedGroup={editMembers}
        />
      )}
    </Box>
  );
});

export default () => (
  <TabWithFilter
    defaultGQLVariable={{
      orderField: 'CREATED_ON',
      orderDesc: true,
      first: 10,
    }}
    dataType={DataType.PatientGroup}
  >
    <PatientGroups />
  </TabWithFilter>
);
