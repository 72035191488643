import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import ContentLoader from 'react-content-loader';
import {
  chain,
  get,
  find,
  filter,
  first,
  includes,
  isEmpty,
  isEqual,
  map,
  split,
  toLower,
  updateWith,
} from 'lodash';
import {
  Box,
  FAIcon,
  Flex,
  Form,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  theme,
  useApi,
} from '@fivehealth/botero';

import { customerSupportEmail } from 'AppUtils';
import DialogTitle from 'components/Dialog/DialogTitle';
import TextButton from 'components/Text/TextButton';

const MetadataLoader = (props) => (
  <Box mt={6}>
    <ContentLoader
      speed={2}
      width="100%"
      height={120}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100" height="15" />
      <rect x="0" y="20" rx="3" ry="3" width="500" height="30" />
      <rect x="0" y="70" rx="3" ry="3" width="100" height="15" />
      <rect x="0" y="90" rx="3" ry="3" width="500" height="30" />
    </ContentLoader>
  </Box>
);

const isPatientMetadataCreated = (param, patientForms) =>
  !chain(patientForms)
    .first()
    .get('patient.metadataEntries', [])
    .find({ parameter: { uid: param?.uid } })
    .isEmpty()
    .value();

const isPatientFormMetadataCreated = (param, patientForms) =>
  chain(patientForms)
    .map(({ metadataEntries }) =>
      find(metadataEntries, { parameter: { uid: param?.uid } })
    )
    .every()
    .value();

const ClinicalParameterBox = ({
  t,
  formRef,
  setFormRef,
  onChange,
  parameter,
  patientForms,
  clinicalParameterOptions,
}) => {
  const stateRef = useRef();
  const formCallbackRef = useRef();
  const [formfields, setFormFields] = useState({});
  const [defaultFormData, setDefaultFormData] = useState({});

  stateRef.current = formfields;
  formCallbackRef.current = formRef;

  const getFormFieldType = (type) => {
    switch (type) {
      case 'STRING':
      case 'STRING_ARRAY':
        return 'input';
      case 'URL':
        return 'input';
      default:
        return toLower(type);
    }
  };

  const clinicalParamCallback = useCallback(
    (selectedOption) => {
      let displayOptions = [];
      const isPatientMetadataCreatable =
        selectedOption?.usedForPatientMetadataEntry &&
        !isPatientMetadataCreated(selectedOption, patientForms);

      const isPatientFormMetadataCreatable =
        selectedOption?.usedForPatientFormMetadataEntry &&
        !isPatientFormMetadataCreated(selectedOption, patientForms);

      if (isPatientMetadataCreatable) {
        displayOptions = [
          ...displayOptions,
          {
            id: 'patientDetails',
            label: 'Patient Details',
            value: 'patientDetails',
          },
        ];
      }

      if (isPatientFormMetadataCreatable) {
        displayOptions = [
          ...displayOptions,
          ...chain(patientForms)
            .filter(
              ({ metadataEntries }) =>
                !find(metadataEntries, {
                  parameter: { uid: selectedOption?.uid },
                })
            )
            .map((patientForm) => ({
              id: patientForm?.uid,
              label: patientForm?.monitoringForm?.effectiveName,
              value: patientForm,
            }))
            .value(),
        ];
      }

      if (formCallbackRef.current) {
        formCallbackRef.current.resetField('clinicalParamValue');
      }

      if (isPatientMetadataCreatable && !isPatientFormMetadataCreatable) {
        if (formCallbackRef.current) {
          formCallbackRef.current.setValue('displayIn', ['patientDetails']);
        }
        setDefaultFormData({
          displayIn: ['patientDetails'],
        });
      } else {
        if (formCallbackRef.current) {
          formCallbackRef.current.resetField('displayIn');
        }
        setDefaultFormData({});
      }

      setFormFields({
        ...formfields,
        ...updateWith(
          stateRef.current,
          'fields',
          (fields) => [
            fields[0],
            {
              ...fields[1],
              type: getFormFieldType(selectedOption?.valueType),
            },
            {
              ...fields[2],
              disabled:
                isPatientMetadataCreatable && !isPatientFormMetadataCreatable,
              options: displayOptions,
            },
          ],
          Object
        ),
      });

      if (onChange) {
        onChange({});
      }
    },
    [formfields, onChange, patientForms]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isEmpty(parameter)) {
      setFormFields({
        ...formfields,
        ...updateWith(
          stateRef.current,
          'fields',
          (fields) => [
            {
              ...fields[0],
              options: clinicalParameterOptions,
            },
            fields[1],
            fields[2],
          ],
          Object
        ),
      });
    }
  }, [parameter, clinicalParameterOptions]);

  useEffect(() => {
    if (isEmpty(formfields) && !isEmpty(clinicalParameterOptions)) {
      setFormFields({
        fields: [
          {
            id: 'clinicalParam',
            type: 'select',
            label: t('Clinical parameter'),
            options: clinicalParameterOptions,
            placeholder: t('Type to start searching'),
            required: true,
            callback: clinicalParamCallback,
          },
          {
            id: `clinicalParamValue`,
            type: 'input',
            label: t('Input value'),
            placeholder: t('Type here'),
            required: true,
            popperPlacement: 'bottom',
          },
          {
            id: `displayIn`,
            type: 'multiSelect',
            label: t('Display in'),
            options: [],
            disabled: false,
            required: true,
          },
        ],
      });
    }
  }, [clinicalParamCallback, clinicalParameterOptions, formfields, t]);

  return (
    <Box
      p={isEmpty(clinicalParameterOptions) ? 3 : 1}
      px={2}
      mt={2}
      borderWidth={1}
      borderRadius={8}
      borderStyle="solid"
      borderColor={theme.colors.mediumShade}
    >
      {isEmpty(clinicalParameterOptions) && (
        <>
          <Text
            fontSize={2}
            fontWeight={400}
            mb={2}
            textAlign="center"
            color={theme.colors.darkestShade}
          >
            {t('No available clinical parameter found.')}
          </Text>
          <Text
            fontSize={2}
            fontWeight={400}
            textAlign="center"
            color={theme.colors.darkestShade}
          >
            {t('Please contact ')}
            <button
              type="button"
              style={{
                backgroundColor: 'transparent',
                borderWidth: '0px',
                padding: '0px',
              }}
              onClick={customerSupportEmail}
            >
              <TextButton
                fontSize={2}
                fontWeight={400}
                color={theme.colors.primary}
                style={{
                  textDecoration: 'underline',
                }}
              >
                {t('customer support')}
              </TextButton>
            </button>
            {t(' for adding new clinical parameter.')}
          </Text>
        </>
      )}

      {!isEmpty(clinicalParameterOptions) && (
        <Form
          form={formfields}
          formRef={setFormRef}
          onChange={onChange}
          defaultFormData={defaultFormData}
          formSpacing={false}
          titleProps={{
            fontSize: '16px',
          }}
          inputLabelProps={{
            mt: 0,
          }}
          inputLabelTextProps={{
            fontSize: '12px',
          }}
        />
      )}
    </Box>
  );
};

const AddMetadataEntryModal = ({ t, patientForms, onClose, onSubmit }) => {
  const formRef = useRef([]);
  const [isValid, setIsValid] = useState(false);
  const [clinicalParameters, setClinicalParameters] = useState([{}]);

  const {
    queries: {
      useCreateStitchUpload,
      useClinicalParameters,
      usePatientMetadataEntryCreate,
      usePatientFormMetadataEntryCreate,
    },
  } = useApi({
    queries: [
      'useCreateStitchUpload',
      'useClinicalParameters',
      'usePatientMetadataEntryCreate',
      'usePatientFormMetadataEntryCreate',
    ],
  });

  const { mutateAsync: createStitchLink } = useCreateStitchUpload({
    variables: {},
  });

  const {
    mutateAsync: createPatientMetadata,
    isLoading: isCreatingPatientMetadata,
  } = usePatientMetadataEntryCreate({
    variables: {},
  });

  const {
    mutateAsync: createPatientFormMetadata,
    isLoading: isCreatingPatientFormMetadata,
  } = usePatientFormMetadataEntryCreate({
    variables: {},
  });

  const {
    data: clinicalParametersData,
    isLoading: isClinicalParametersLoading,
  } = useClinicalParameters({
    variables: {},
  });

  const clinicalParameterOptions = chain(clinicalParametersData)
    .flatMap(({ node }) => node)
    .filter((param) => {
      if (
        !param?.usedForPatientMetadataEntry &&
        !param?.usedForPatientFormMetadataEntry
      ) {
        return false;
      }

      if (
        param?.usedForPatientMetadataEntry &&
        !param?.usedForPatientFormMetadataEntry
      ) {
        return !isPatientMetadataCreated(param, patientForms);
      }

      if (
        !param?.usedForPatientMetadataEntry &&
        param?.usedForPatientFormMetadataEntry
      ) {
        return !isPatientFormMetadataCreated(param, patientForms);
      }

      return (
        !isPatientMetadataCreated(param, patientForms) ||
        !isPatientFormMetadataCreated(param, patientForms)
      );
    })
    .map((param) => ({
      ...param,
      label: param?.name,
      value: param,
    }))
    .value();

  const onSetFormRefs = (ref, index) => {
    formRef.current[index] = ref;
  };

  const onAddParam = () => {
    setClinicalParameters([...clinicalParameters, {}]);
    setIsValid(false);
  };

  const handleOnSubmit = async () => {
    if (isValid) {
      setIsValid(false);

      await Promise.all(
        map(clinicalParameters, (param) =>
          Promise.all(
            map(param?.displayIn, async (display) => {
              let paramValue = param?.value;

              if (
                isEqual(param?.valueType, 'FILE') &&
                param?.value instanceof File
              ) {
                paramValue = await createStitchLink({
                  input: {
                    key: 'cleo',
                    mimeType: paramValue?.type,
                  },
                }).then(({ stitchCreateUploadUrl }) => {
                  const body = new FormData();

                  map(stitchCreateUploadUrl.fields, (value, key) => {
                    body.append(key, value);
                  });
                  body.append('file', paramValue);

                  return fetch(stitchCreateUploadUrl.url, {
                    method: 'post',
                    body,
                  }).then(() => ({
                    url: `stitch://${stitchCreateUploadUrl?.uploadId}`,
                    name:
                      paramValue?.name.substring(
                        0,
                        paramValue?.name.lastIndexOf('.')
                      ) || paramValue?.name,
                    suffix: paramValue?.name.substring(
                      paramValue?.name.lastIndexOf('.')
                    ),
                  }));
                });
              }

              if (isEqual(param?.valueType, 'DATETIME')) {
                // backend python does not support ISOString with 'Z'
                paramValue = paramValue.replace('Z', '+00:00');
              }

              if (isEqual(param?.valueType, 'NUMBER')) {
                paramValue = parseFloat(paramValue);
              }

              if (isEqual(param?.valueType, 'STRING_ARRAY')) {
                paramValue = split(paramValue, ',');
              }

              if (isEqual(display, 'patientDetails')) {
                return createPatientMetadata({
                  input: {
                    parameter: { uid: param?.uid },
                    patient: { uid: first(patientForms)?.patient?.uid },
                    value: {
                      [toLower(param?.valueType)]: paramValue,
                    },
                  },
                });
              }

              return createPatientFormMetadata({
                input: {
                  parameter: { uid: param?.uid },
                  patientForm: { uid: display?.uid },
                  value: {
                    [toLower(param?.valueType)]: paramValue,
                  },
                },
              });
            })
          )
        )
      );

      if (onSubmit) {
        onSubmit(clinicalParameters);
      }
    }
  };

  const handleParamOnChange = (value, paramIndex) => {
    setClinicalParameters(
      map(clinicalParameters, (param, index) => {
        if (isEqual(index, paramIndex)) {
          return {
            ...value?.clinicalParam,
            value: value?.clinicalParamValue,
            displayIn: value?.displayIn,
          };
        }
        return param;
      })
    );

    if (get(value, 'clinicalParamValue')) {
      setIsValid(
        chain(formRef.current)
          .map((form) => form.isValid())
          .every()
          .value()
      );
    } else {
      setIsValid(false);
    }
  };

  return (
    <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 640]}>
      <DialogTitle
        label={t('Add parameters to display')}
        onClick={onClose}
        textProps={{
          fontSize: '24px',
        }}
      />
      <Text fontWeight={400} fontSize={2} mb={4}>
        {t('Set up clinical parameters you would like to display.')}
      </Text>

      {isClinicalParametersLoading ? (
        <MetadataLoader />
      ) : (
        map(clinicalParameters, (param, index) => (
          <ClinicalParameterBox
            key={index}
            formRef={get(formRef.current, `[${index}]`, null)}
            setFormRef={(ref) => onSetFormRefs(ref, index)}
            parameter={param}
            t={t}
            patientForms={patientForms}
            onChange={(v) => handleParamOnChange(v, index)}
            clinicalParameterOptions={filter(
              clinicalParameterOptions,
              (option) =>
                isEqual(option?.uid, param?.uid) ||
                !includes(
                  map(clinicalParameters, (cParam) => cParam?.uid),
                  option.uid
                )
            )}
          />
        ))
      )}

      <Flex
        mt={4}
        mb={2}
        p={1}
        alignItems="center"
        cursor="pointer"
        onClick={
          isValid && clinicalParameterOptions.length > clinicalParameters.length
            ? onAddParam
            : null
        }
        opacity={
          isValid && clinicalParameterOptions.length > clinicalParameters.length
            ? 1
            : 0.5
        }
      >
        <FAIcon icon={faPlus} fontSize={14} color="primary" />
        <TextButton
          ml={1}
          fontSize="14px"
          fontWeight={500}
          color={theme.colors.primary}
        >
          {t('Add parameter')}
        </TextButton>
      </Flex>

      <Flex justifyContent="right">
        <Flex>
          <SecondaryOutlinedButton mt={2} mr={2} onClick={onClose}>
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            mt={2}
            disabled={
              !isValid ||
              isCreatingPatientMetadata ||
              isCreatingPatientFormMetadata
            }
            onClick={handleOnSubmit}
          >
            {t('Add')}
          </PrimaryButton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AddMetadataEntryModal;
