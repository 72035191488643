import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

import { cleoAlertReminderFragment } from './useAlertReminders';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoReminderUpdate($input: UpdateCleoReminderInput!) {
    cleoReminderUpdate(input: $input) {
      cleoReminder {
        ${cleoAlertReminderFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'alertReminder',
  keepPreviousData: true,
});
