import React, { useState } from 'react';
import {
  get,
  isEmpty,
  startCase,
  includes,
  pickBy,
  keys,
  toLower,
  orderBy,
} from 'lodash';
import { Flex, Box, H2, theme, Text } from '@fivehealth/botero';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ChartLegend from 'components/Charts/ChartLegend';
import ChartHeaderStat from 'components/Charts/ChartHeaderStat';
import ScatterChart from 'components/Charts/ScatterChart';
import MultipleLineChart from 'components/Charts/MultipleLineChart';
import SingleLineChart from 'components/Charts/SingleLineChart';
import ChartDropdown from 'components/Charts/ChartDropdown';

import {
  SquareChartIcon,
  DiamondChartIcon,
  CircleChartIcon,
} from 'components/Charts/ChartTooltip';
import {
  getChartIcon,
  getChartLegendMapping,
  getChartLegendOptions,
} from 'AppUtils';
import useClinicalParametersQuery from 'hooks/useClinicalParametersQuery';
import { ChartTypes } from '../../../constants';

const toFixed = (num, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return num.toString().match(re)[0];
};

export const chartColors = {
  primaryBlue: '#256BF6',
  primaryOrange: '#F89A50',
  ...theme.colors,
};

export const alexandraChartMapping = {
  beforeMeal: {
    id: 'beforeMeal',
    symbol: 'square',
    symbolComponent: SquareChartIcon,
    color: chartColors.primaryOrange,
    description: 'Before meal',
  },
  '2HoursAfterMeal': {
    id: '2HoursAfterMeal',
    symbol: 'diamond',
    symbolComponent: DiamondChartIcon,
    color: chartColors.danger,
    description: '2h after meal',
  },
  beforeBedtime: {
    id: 'beforeBedtime',
    symbol: 'circle',
    symbolComponent: CircleChartIcon,
    color: chartColors.primaryBlue,
    description: 'Before Bedtime',
  },
};

export const dateRangeOptions = [
  { label: 'Last 1 day', value: 1, timeRangeLabel: 'day' },
  { label: 'Last 7 days', value: 6, timeRangeLabel: 'week' },
  { label: 'Last 30 days', value: 29, timeRangeLabel: 'month' },
];

const getTimeRangeLabel = (numOfDays) => {
  const dateRange = dateRangeOptions.find(({ value }) => value === numOfDays);
  if (dateRange) {
    return dateRange.timeRangeLabel;
  }
  return '';
};

export const filterChartData = (data, chartFilters) => {
  const selectedFilters = Object.keys(chartFilters || {})
    .filter(
      (filterId) => filterId !== 'showDataLabels' && chartFilters[filterId]
    )
    .map(startCase);

  if (isEmpty(selectedFilters)) {
    return data;
  }

  const [chartData] = data;

  const filteredIndexes = keys(
    pickBy(get(chartData, 'values', []), ({ label }) =>
      includes(selectedFilters, label)
    )
  );

  const filteredChartData = {
    ...chartData,
    values: get(chartData, 'values', []).filter((_, i) =>
      includes(filteredIndexes, `${i}`)
    ),
    submissionValues: get(chartData, 'submissionValues', []).filter((_, i) =>
      includes(filteredIndexes, `${i}`)
    ),
    numbers: get(chartData, 'numbers', []).filter((_, i) =>
      includes(filteredIndexes, `${i}`)
    ),
    submissionTimes: get(chartData, 'submissionTimes', []).filter((_, i) =>
      includes(filteredIndexes, `${i}`)
    ),
  };
  return [filteredChartData];
};

export const ChartDataAverages = ({
  type,
  fixedDecimal,
  dateRange,
  unit,
  previous: previousData = [],
  current: currentData = [],
  next: nextData = [],
  grandAverageData,
}) => {
  const { t } = useTranslation();
  const timeRangeLabel = getTimeRangeLabel(dateRange);

  const formatValue = (val) => {
    if (!val) {
      return null;
    }
    if (fixedDecimal) {
      return toFixed(val, fixedDecimal);
    }
    return parseInt(val, 10);
  };

  let current;
  let previous;
  let next;
  let grandAverage = formatValue(get(grandAverageData, '[0].average'));

  if (type !== 'bp') {
    current = get(currentData, '[0].average')
      ? formatValue(get(currentData, '[0].average'))
      : null;
    previous = get(previousData, '[0].average')
      ? formatValue(get(previousData, '[0].average'))
      : null;
    next = get(nextData, '[0].average')
      ? formatValue(get(nextData, '[0].average'))
      : null;
  }

  // TODO: Ask Bin what about looking for systolic and diastolic in the name, should it be removed too.
  if (type === 'blood pressure') {
    const cData = orderBy(
      currentData,
      (data) => data.clinicalParameter.sortOrder,
      ['asc']
    );

    const pData = orderBy(
      previousData,
      (data) => data.clinicalParameter.sortOrder,
      ['asc']
    );

    const nData = orderBy(
      nextData,
      (data) => data.clinicalParameter.sortOrder,
      ['asc']
    );

    const [
      currSystolic,
      currDiastolic,
      prevSystolic,
      prevDiastolic,
      nextSystolic,
      nextDiastolic,
    ] = [...cData, ...pData, ...nData];

    current = isEmpty(currentData)
      ? null
      : `${formatValue(get(currSystolic, 'average', ''), 2)}/${formatValue(
          get(currDiastolic, 'average', '')
        )}`;
    previous = isEmpty(previousData)
      ? null
      : `${formatValue(get(prevSystolic, 'average', ''), 2)}/${formatValue(
          get(prevDiastolic, 'average', '')
        )}`;
    next = isEmpty(nextData)
      ? null
      : `${formatValue(get(nextSystolic, 'average', ''), 2)}/${formatValue(
          get(nextDiastolic, 'average', '')
        )}`;
    grandAverage = grandAverageData
      .sort((a, b) => {
        const sortOrderA = a?.clinicalParameter?.sortOrder;
        const sortOrderB = b?.clinicalParameter?.sortOrder;

        return sortOrderA - sortOrderB;
      })
      .map(({ average }) => formatValue(average))
      .join('/');
  }

  return (
    <Flex
      justifyContent="space-between"
      my={4}
      overflow="auto"
      className="scrollbar-invisible"
      style={{
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
      }}
    >
      <ChartHeaderStat
        unit={unit}
        subtitle={t('Grand average')}
        value={grandAverage}
      />
      <ChartHeaderStat
        value={previous}
        unit={unit}
        subtitle={t(`Previous ${timeRangeLabel}`)}
      />
      <ChartHeaderStat
        value={current}
        unit={unit}
        subtitle={t(`Current ${timeRangeLabel}`)}
      />
      <ChartHeaderStat
        value={next}
        unit={unit}
        subtitle={t(`Next ${timeRangeLabel}`)}
      />
    </Flex>
  );
};

const PatientCharts = ({
  getThresholdValue,
  currentDateRange,
  startDate,
  endDate,
  prevPeriodData,
  nextPeriodData,
  grandAverageData,
  parsedChartData,
  chartSetting,
  showDivider = false,
}) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const combineClinicalParametersData =
    chartSetting?.combineClinicalParametersData || false;

  const chartLegendMapping = getChartLegendMapping(chartSetting, t);

  const chartLegendOptions = getChartLegendOptions(chartSetting, t);

  const [chartFilters, setChartFilters] = useState({});
  const defaultFilterOptions = [
    { label: t('Show data labels'), id: 'showDataLabels' },
  ];

  const onSetChartFilter = (chartKey, filter) => {
    setChartFilters({
      ...chartFilters,
      [chartKey]: {
        ...get(chartFilters, chartKey, {}),
        ...filter,
      },
    });
  };

  const mapFilterOptions = (chartKey) =>
    defaultFilterOptions.map((opt) => ({
      ...opt,
      checked: !!get(chartFilters, `${chartKey}.${opt.id}`),
    }));

  const canShowDataLabels = (chartKey) =>
    !!get(chartFilters, `${chartKey}.showDataLabels`, false);

  if (toLower(chartSetting.key) === 'bg') {
    /* eslint no-param-reassign: 0 */
    parsedChartData = filterChartData(parsedChartData, get(chartFilters, 'bg'));
  }

  const getChartDataBasedOnClinicalParam = (clinicalParamUid) => {
    if (isEmpty(parsedChartData)) return null;
    const data = parsedChartData?.find(
      (d) => d?.clinicalParameter?.uid === clinicalParamUid
    );
    if (!data) return null;

    const chartModel = {
      ...data,
      submissionTimes: data?.submissionTimes,
      submissionValues: combineClinicalParametersData
        ? data?.numbers
        : data?.values,
    };
    return chartModel;
  };

  const { getClinicalParamUidsByNameWithGroups } =
    useClinicalParametersQuery(true);

  const clinicalParamUids = getClinicalParamUidsByNameWithGroups(
    chartSetting.clinicalParameters
  );

  return (
    <Box testid="graphs_view" id="chartsContainer">
      {chartSetting.chartType === ChartTypes.Scatter && (
        <Box
          className="graph"
          mb={showDivider > 0 ? 5 : 0}
          pb={4}
          style={
            showDivider
              ? { borderBottom: `1px solid ${theme.colors.mediumShade}` }
              : {}
          }
        >
          <Flex justifyContent="space-between" alignItems="center" my={[3, 4]}>
            <H2 fontSize={18}>{t(chartSetting?.title)}</H2>
            <Box
              testid="chart_filter_btn_scatter"
              display={['none', 'initial']}
            >
              <ChartDropdown
                defaultLabel="Filter"
                onChange={(val, label) =>
                  onSetChartFilter('bg', {
                    [label]: val[label],
                  })
                }
                optionsTitle="Data labels"
                checkboxes={[
                  combineClinicalParametersData && {
                    label: t('Readings'),
                    options: chartLegendOptions.map(({ id, label, icon }) => ({
                      id,
                      checked: !!get(
                        chartFilters,
                        `${toLower(chartSetting?.key)}.${id}`,
                        false
                      ),
                      // TODO: Refactor this to avoid react/no-unstable-nested-components error
                      /* eslint-disable react/no-unstable-nested-components */
                      label: () => (
                        <Flex key={label}>
                          {icon}
                          <Text ml={1} fontSize={14}>
                            {t(label)}
                          </Text>
                        </Flex>
                      ),
                    })),
                  },
                  {
                    label: t('Data labels'),
                    options: mapFilterOptions(toLower(chartSetting?.key)),
                  },
                ].filter(Boolean)}
              />
            </Box>
          </Flex>
          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting?.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />

          {clinicalParamUids.map((clinicalParameterUid, i) => {
            const graphData =
              getChartDataBasedOnClinicalParam(clinicalParameterUid);

            if (combineClinicalParametersData && i === 1) return null;

            return (
              <Box key={i}>
                <Box
                  key={`${i}_mobile`}
                  display={['block', 'block', 'none']}
                  height={425}
                >
                  <ScatterChart
                    chartSetting={chartSetting}
                    data={parsedChartData}
                    graphData={graphData}
                    serieConfig={chartSetting?.series[i]}
                    startDate={startDate}
                    endDate={endDate}
                    minimumLineY={getThresholdValue(
                      toLower(chartSetting?.key),
                      'min'
                    )}
                    maxLineY={getThresholdValue(
                      toLower(chartSetting?.key),
                      'max'
                    )}
                    minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
                    maxYvalue={get(chartSetting, 'yAxis.domain[1]', 40)}
                    containerStyles={{ width: isMobile ? 380 : '100%' }}
                    height={420}
                    width={isMobile ? 460 : 660}
                    numberOfDays={7}
                    combineClinicalParametersData={
                      combineClinicalParametersData
                    }
                    legend={
                      combineClinicalParametersData ? chartLegendMapping : null
                    }
                    showDataLabels={canShowDataLabels(
                      toLower(chartSetting?.key)
                    )}
                    unit={chartSetting?.unit}
                  />
                </Box>
                <Box
                  key={`${i}_desktop`}
                  display={['none', 'none', 'block']}
                  height={500}
                  width="100%"
                >
                  <ScatterChart
                    chartSetting={chartSetting}
                    data={parsedChartData}
                    graphData={graphData}
                    serieConfig={chartSetting.series[i]}
                    startDate={startDate}
                    endDate={endDate}
                    minimumLineY={getThresholdValue(
                      toLower(chartSetting?.key),
                      'min'
                    )}
                    maxLineY={getThresholdValue(
                      toLower(chartSetting?.key),
                      'max'
                    )}
                    minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
                    maxYvalue={get(chartSetting, 'yAxis.domain[1]', 40)}
                    height={500}
                    numberOfDays={7}
                    combineClinicalParametersData={
                      combineClinicalParametersData
                    }
                    legend={
                      combineClinicalParametersData ? chartLegendMapping : null
                    }
                    showDataLabels={canShowDataLabels(
                      toLower(chartSetting?.key)
                    )}
                    unit={chartSetting?.unit}
                  />
                </Box>
              </Box>
            );
          })}

          {combineClinicalParametersData && (
            <ChartLegend
              values={Object.values(chartLegendMapping).map(
                ({
                  symbol,
                  symbolComponent: IconComponent,
                  description: label,
                  color,
                }) => ({
                  icon: (
                    <IconComponent
                      key={label}
                      color={color}
                      top={symbol === 'diamond' ? '-4px !important' : null}
                    />
                  ),
                  label: t(label),
                })
              )}
            />
          )}
        </Box>
      )}

      {chartSetting?.chartType === ChartTypes.MultiLine && (
        <Box
          className="graph"
          mb={showDivider > 0 ? 5 : 0}
          pb={4}
          style={
            showDivider
              ? { borderBottom: `1px solid ${theme.colors.mediumShade}` }
              : {}
          }
        >
          <Flex justifyContent="space-between" alignItems="center">
            <H2 fontSize={18}>{t(chartSetting?.title)}</H2>
            <Box
              testid="chart_filter_btn_multiLine"
              display={['none', 'initial']}
            >
              <ChartDropdown
                defaultLabel="Filter"
                onChange={(val, label) =>
                  onSetChartFilter(toLower(chartSetting?.key), {
                    [label]: val[label],
                  })
                }
                optionsTitle={t('Data labels')}
                checkboxes={[
                  {
                    label: t('Data labels'),
                    options: mapFilterOptions(toLower(chartSetting?.key)),
                  },
                ]}
              />
            </Box>
          </Flex>
          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />

          <Box display={['block', 'block', 'none']} height={425}>
            <MultipleLineChart
              chartSetting={chartSetting}
              data={parsedChartData}
              startDate={startDate}
              endDate={endDate}
              minimumLineY={getThresholdValue(
                toLower(chartSetting?.key),
                'min'
              )}
              maxLineY={getThresholdValue(toLower(chartSetting?.key), 'max')}
              minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
              maxYvalue={get(chartSetting, 'yAxis.domain[1]', 200)}
              containerStyles={{ width: isMobile ? 380 : '100%' }}
              height={420}
              width={isMobile ? 460 : 660}
              numberOfDays={7}
              showDataLabels={canShowDataLabels(toLower(chartSetting?.key))}
            />
          </Box>
          <Box display={['none', 'none', 'block']} height={500} width="100%">
            <MultipleLineChart
              chartSetting={chartSetting}
              minimumLineY={getThresholdValue(
                toLower(chartSetting?.key),
                'min'
              )}
              maxLineY={getThresholdValue(toLower(chartSetting?.key), 'max')}
              minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
              maxYvalue={get(chartSetting, 'yAxis.domain[1]', 200)}
              data={parsedChartData}
              startDate={startDate}
              endDate={endDate}
              numberOfDays={7}
              showDataLabels={canShowDataLabels(toLower(chartSetting?.key))}
            />
          </Box>

          <ChartLegend
            values={chartSetting.series.map((serie, i) => {
              const IconComponent = getChartIcon(serie?.symbolStyle?.symbol);
              const iconColor = serie?.symbolStyle?.color;
              const label = serie?.symbolStyle?.legendLabel;

              return {
                icon: (
                  <IconComponent
                    key={label}
                    color={iconColor}
                    top={i === 0 ? '-4px !important' : 0}
                  />
                ),
                label,
              };
            })}
          />
        </Box>
      )}

      {chartSetting?.chartType === ChartTypes.SingleLine && (
        <Box
          className="graph"
          mb={showDivider > 0 ? 5 : 0}
          pb={4}
          style={
            showDivider
              ? { borderBottom: `1px solid ${theme.colors.mediumShade}` }
              : {}
          }
        >
          <Flex justifyContent="space-between" alignItems="center">
            <H2 fontSize={18}>{t(chartSetting?.subtitle)}</H2>
            <Box
              testid="chart_filter_btn_singleLine"
              display={['none', 'none', 'initial']}
            >
              <ChartDropdown
                defaultLabel="Filter"
                onChange={(val, label) =>
                  onSetChartFilter(toLower(chartSetting?.key), {
                    [label]: val[label],
                  })
                }
                optionsTitle={t('Data labels')}
                checkboxes={[
                  {
                    label: t('Data labels'),
                    options: mapFilterOptions(toLower(chartSetting?.key)),
                  },
                ]}
              />
            </Box>
          </Flex>

          <ChartDataAverages
            type={toLower(chartSetting?.key)}
            unit={chartSetting?.unit}
            fixedDecimal={chartSetting?.decimalPlaces ?? 0}
            dateRange={currentDateRange}
            current={parsedChartData}
            previous={prevPeriodData}
            next={nextPeriodData}
            grandAverageData={grandAverageData}
          />
          <Box display={['block', 'block', 'none']} height={425}>
            <SingleLineChart
              chartSetting={chartSetting}
              unit={chartSetting?.unit}
              data={parsedChartData}
              startDate={startDate}
              endDate={endDate}
              numberOfDays={7}
              containerStyles={{ width: isMobile ? 380 : '100%' }}
              height={420}
              width={460}
              minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
              maxYvalue={get(chartSetting, 'yAxis.domain[1]', 200)}
              minimumLineY={getThresholdValue(
                toLower(chartSetting?.key),
                'min'
              )}
              maxLineY={getThresholdValue(toLower(chartSetting?.key), 'max')}
              showDataLabels={canShowDataLabels(toLower(chartSetting?.key))}
            />
          </Box>
          <Box display={['none', 'none', 'block']} height={500} width="100%">
            <SingleLineChart
              chartSetting={chartSetting}
              unit={chartSetting.unit}
              data={parsedChartData}
              startDate={startDate}
              endDate={endDate}
              minYvalue={get(chartSetting, 'yAxis.domain[0]', 0)}
              maxYvalue={get(chartSetting, 'yAxis.domain[1]', 200)}
              minimumLineY={getThresholdValue(
                toLower(chartSetting?.key),
                'min'
              )}
              maxLineY={getThresholdValue(toLower(chartSetting?.key), 'max')}
              showDataLabels={canShowDataLabels(toLower(chartSetting?.key))}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PatientCharts;
