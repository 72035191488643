import { Flex } from '@fivehealth/botero';
import React from 'react';
import Lottie from 'react-lottie-player';
import WalkingAvocado from '../../assets/walking-avocado.json';

export default () => (
  <Flex flex={1} width="100%" justifyContent="center" alignItems="center">
    <Lottie
      style={{
        width: 400,
      }}
      play
      animationData={WalkingAvocado}
    />
  </Flex>
);
