import React, { useState } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import {
  Box,
  BoxWidget,
  Flex,
  H2,
  SecondaryOutlinedButton,
  PrimaryButton,
  FAIcon,
  Toggle,
  Text,
  useApi,
} from '@fivehealth/botero';

const ReminderModal = ({
  patientForm,
  closeModal,
  refetchPatientForm,
  isEdit,
  t,
}) => {
  const [selected, setSelected] = useState(patientForm.doNotRemind || false);

  const {
    queries: { usePatientFormUpdate },
  } = useApi({
    queries: ['usePatientFormUpdate'],
  });

  const { isLoading, mutateAsync: updatePatientForm } = usePatientFormUpdate({
    variables: {},
    onSuccess: () => {
      if (refetchPatientForm) {
        refetchPatientForm();
      }
    },
  });

  const handleChange = (enabled) => {
    setSelected(enabled);
  };

  const handleSave = async () => {
    await updatePatientForm({
      input: {
        uid: patientForm.uid,
        doNotRemind: selected,
      },
    });
    closeModal();
  };

  return (
    <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 640]}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <H2>
          {isEdit
            ? t('Edit Reminders status')
            : t('Default reminders cannot be deleted')}
        </H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      {!isEdit && (
        <>
          <Text fontSize={16} fontWeight={400} color="fullShade" mb={3}>
            {t('Default reminders cannot be deleted.')}
          </Text>
          <Text fontSize={16} fontWeight={400} color="fullShade">
            {t('Would you like to pause reminders instead?')}
          </Text>
        </>
      )}
      <BoxWidget
        mt={isEdit ? 0 : 6}
        variant="outlined"
        borderColor="mediumShade"
        bodyProps={{ p: 2, pt: 0 }}
        mx={0}
        maxWidth="100%"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontSize={14} fontWeight={400} color="fullShade">
              {t('Pause reminders')}
            </Text>
            <Text mt={1} fontSize={12} fontWeight={500} color="darkestShade">
              {t(
                'The patient will stop receiving all reminders for this form. You can change this anytime.'
              )}
            </Text>
          </Box>
          <Box mt={1}>
            <Toggle enabled={selected} onChange={handleChange} />
          </Box>
        </Flex>
      </BoxWidget>
      <Box
        mt={4}
        width="100%"
        borderBottomWidth={1}
        borderBottomColor="mediumShade"
        borderBottomStyle="solid"
      />
      <Flex mt={4} justifyContent="flex-end">
        <SecondaryOutlinedButton onClick={closeModal}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          ml={3}
          type="submit"
          onClick={handleSave}
          disabled={isLoading}
        >
          {t('Save')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default ReminderModal;
