/* eslint-disable @typescript-eslint/naming-convention */
export const DataType = {
  PatientForm: 'patientForm',
  Submission: 'submission',
  Clinician: 'clinician',
  Patient: 'patient',
  PatientGroup: 'patientGroup',
  SubmissionUnresolved: 'submissionUnresolved',
  Null: null,
};
