import React, { useEffect, useState } from 'react';
import {
  Box,
  H2,
  PrimaryButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  Select,
  Label,
} from '@fivehealth/botero';
import { isAndroid } from 'react-device-detect';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import gbFlag from '../../assets/gb.svg';
import idFlag from '../../assets/id.svg';
import esFlag from '../../assets/es.svg';

const getFlagProps = (flag) => ({
  backgroundImage: `url(${flag})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '24px 104px',
  backgroundPosition: 'center',
  content: '" "',
  display: flag ? 'inline-block' : 'none',
  marginRight: 8,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '2px',
  height: 20,
  width: 23,
});

const flag = (countryFlag) => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    ...getFlagProps(countryFlag),
  },
});

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    minWidth: '100%',
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    minHeight: 45,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: (provided) => ({ ...provided, marginRight: -12 }),
  option: (provided, { data }) => ({
    ...provided,
    cursor: 'pointer',
    display: 'inline-block',
    paddingLeft: '50px',
    ':before': {
      position: 'absolute',
      left: '5px',
      ...getFlagProps(data.flag),
    },
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...flag(data.flag),
  }),
  menuPortal: (base) => ({ ...base, fontSize: 14, zIndex: 9999 }),
};

const ChangeClinicianLanguage = ({
  closeModal,
  isUpdating,
  onSubmit,
  isMobile,
  lang,
}) => {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    if (lang) {
      setSelectedLanguage(lang);
    }
  }, [lang]);

  const languageOptions = [
    {
      value: 'en',
      flag: gbFlag,
      label: t('English'),
    },
    {
      value: 'es',
      flag: esFlag,
      label: t('Spanish'),
    },
    {
      value: 'id',
      flag: idFlag,
      label: t('Bahasa Indonesia'),
    },
  ];

  const getInitSelectedLang = () =>
    languageOptions.find((o) => o.value === lang);

  return (
    <Box p={1}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{t('Change language')}</H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>

      <Flex mt={3} flexWrap="wrap">
        <Label fontSize={12} color="darkestShade" fontWeight={600} mb={1}>
          {t('Choose your dashboard language')}
        </Label>
        <Select
          options={languageOptions}
          styles={customStyles}
          defaultValue={getInitSelectedLang()}
          onChange={(e) => {
            setSelectedLanguage(e.value);
          }}
          menuPlacement="auto"
          maxMenuHeight={isAndroid ? 150 : 180}
          menuShouldBlockScroll={!isAndroid}
          menuPortalTarget={
            isAndroid ? null : document.getElementById('modalContainer')
          }
        />
      </Flex>
      <Flex
        mt={4}
        display={isMobile ? 'block !important' : 'flex'}
        justifyContent={isMobile ? 'unset' : 'flex-end'}
        alignItems="center"
      >
        <SecondaryOutlinedButton
          width={isMobile ? '100%' : 'unset'}
          mr={3}
          mb={[1, 0, 0]}
          onClick={closeModal}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>

        <PrimaryButton
          mb={['10px', 0]}
          width={isMobile ? '100%' : 'unset'}
          disabled={isUpdating}
          onClick={() => onSubmit('Language', selectedLanguage)}
        >
          {isUpdating ? t('Saving...') : t('Save')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default ChangeClinicianLanguage;
