/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, ReactNode, useMemo } from 'react';
import { filter as _filter } from 'lodash';

import { Text, H5, Box as BoxBotero } from '@fivehealth/botero';

import { useTranslation } from 'react-i18next';
import { Box, Flex, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { FilterOptionData } from 'components/Filters/Filters';

interface FilterSectionProps {
  filter: any;
  data: any;
  filteredData: any;
  onFilterChange: any;
  showTitle?: boolean;
  selectedOptions: FilterOptionData[];
}

export const FilterSection = memo<FilterSectionProps>(
  ({
    filter,
    data = [],
    filteredData = [],
    onFilterChange,
    showTitle = false,
    selectedOptions,
  }) => {
    const { t } = useTranslation();

    const checkedData = useMemo(() => {
      return data.map((item: any) => ({
        ...item,
        checked: selectedOptions.some(
          (selectedOption) => selectedOption.id === item.id
        ),
      }));
    }, [data, selectedOptions]);

    const renderLabel = ({
      icon,
      label,
    }: {
      icon: ReactNode;
      label: string;
    }) => (
      <Flex alignItems="center">
        <Flex
          height={22}
          width={22}
          mr="6px"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Flex>
        <Text color="fullShade" fontSize={14}>
          {t(label)}
        </Text>
      </Flex>
    );

    return (
      <Box mt={-2} pl={2} pr={2} maxWidth={800}>
        {showTitle && (
          <H5 mb={1} color="darkestShade">
            {t(filter.title)}
          </H5>
        )}
        <BoxBotero
          {...{
            display: 'inline-flex',
            maxHeight: 500,
            maxWidth: 300,
            flexWrap: 'wrap',
            className: 'scrollbar-invisible',
            style: {
              writingMode: 'rl-tb',
              flexFlow: 'wrap',
              overflow: 'scroll',
            },
          }}
        >
          <CheckboxGroup
            value={checkedData
              .filter((item: any) => item.checked)
              .map((item: any) => item.id)}
          >
            <Stack direction="column">
              {filteredData.map(
                (
                  item: {
                    id: string;
                    checked: boolean;
                    label: string;
                    icon: ReactNode;
                  },
                  i: number
                ) => (
                  <Checkbox
                    autoFocus={false}
                    colorScheme="brand"
                    key={`${item.id}-${i}`}
                    name={item.id}
                    value={item.id}
                    isChecked={item.checked}
                    onChange={(e) => {
                      const key = e.target.value as string;

                      onFilterChange(
                        filter,
                        {
                          ...checkedData.reduce((acc: any, curr: any) => {
                            acc[curr.id] = curr.checked;
                            return acc;
                          }, {}),
                          [key]: e.target.checked,
                        },
                        key
                      );
                    }}
                  >
                    {item.icon ? (
                      renderLabel(item)
                    ) : (
                      <Text color="fullShade" fontSize={14}>
                        {t(item.label)}
                      </Text>
                    )}
                  </Checkbox>
                )
              )}
            </Stack>
          </CheckboxGroup>
        </BoxBotero>
      </Box>
    );
  }
);
