import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const patientFragment = `
  name
  uid
  identifier
  phone
  email
  gender
  deactivatedOn
  communicationMethod
  enrolledMonitoringForms {
    uid
    effectiveName
  }
  metadataEntries {
    uid
    parameter {
      uid
      name
      valueType
    }
    value
    extractedForDisplay
  }
`;

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientUpdate($input: UpdateCleoPatientInput!) {
    cleoPatientUpdate(input: $input) {
      cleoPatient {
        ${patientFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientUpdate',
  keepPreviousData: true,
});
