import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientEvents(
    $createdOnGte: DateTime
    $eventStartOnLt: DateTime
    $isUpcoming: Boolean
    $isCanceled: Boolean
    $patientUidIn: [String]
  ) {
    cleoPatientEvents(
      createdOn_Gte: $createdOnGte
      isUpcoming: $isUpcoming
      event_StartOn_Lt: $eventStartOnLt
      isCanceled: $isCanceled
      patient_Uid_In: $patientUidIn
    ) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientEventsGetCount',
  keepPreviousData: true,
  select: ({ cleoPatientEvents }) => cleoPatientEvents?.totalCount,
});
