import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientEventDelete($patientEventUids: [String!]!) {
    cleoPatientEventDelete(input: { patientEventUids: $patientEventUids }) {
      results {
        uid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientEventDelete',
});
