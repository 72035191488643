import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, isEmpty, map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { faCog } from '@fortawesome/pro-regular-svg-icons';

import {
  Flex,
  Box,
  Text,
  SecondaryOutlinedButton,
  Dialog,
  theme,
  PrimaryButton,
  Body,
  SearchInput,
} from '@fivehealth/botero';

import { EventSubSource } from 'constants';
import { checkPermissions } from 'AppUtils';

import Sort from 'components/Sort/Sort';
// import Filter from 'components/Filter/Filter';
import Markdown from 'components/Markdown/Markdown';
import { LoadMoreButton } from 'components/Table/Table';
import SettingsActionMenu from 'components/ActionMenuPopper/SettingsActionMenu';

import { Filters } from 'components/Filters/Filters';
import { RetryStatusCodes } from 'constants/retryStatusCodes';
import { RetryView } from 'views/RetryView/RetryView';
import SubmissionTable from './SubmissionTable';
import { SubmissionBoxWidget } from './Sidepanel';

const UnreadCommentSection = ({
  // props
  openDrawer,
  onShowSubmission,
  onShowPatientForm,
  onSendMessage,
  onCommentCreated,
  onDownloadPatientSubmissions,
  tableSettings,
  tableSettingsSinglePatient,
  clinic,
  clinician,

  // tab with filter
  t,
  lastUpdated,
  submissions,
  requestParams,
  isLoading,
  isRefetching,
  isFetched,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  // showFilters,
  clinicalUserFilters,
  monitoringFormsetsFilters,
  onSearch,
  onRefresh,
  onFetchData,
  // onFilterOpen,
  // onFilterCancel,
  onApplyFilters,
  // onResetFilters,
  onMarkAllCommentsRead,
  unreadCommentsCount,
  // statusesFilters,
  searchText,
  setSearchText,
  totalFetchDataCount,
  pageCount,
  statusCode,
} = {}) => {
  const { state: locationState } = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const isResponsive = useMediaQuery({ query: '(max-width: 1002px)' });
  const [openPrompt, setOpenPrompt] = useState(false);
  const hasPermission = checkPermissions(clinician, ['mutate_clinicians']);

  const tableData = map(submissions, (submission) => {
    const { patient: { deactivatedOn } = {} } = submission;
    return {
      ...submission,
      deactivatedOn,
      ics: submission?.patientForm?.ics,
      alertees: submission?.patientForm?.alertees,
      patientForm: {
        ...submission?.patientForm,
        patient: {
          ...submission?.patient,
          ...submission?.patientForm?.patient,
        },
      },
    };
  });

  const filterOptions = [
    clinicalUserFilters,
    monitoringFormsetsFilters,
    // statusesFilters,
  ].filter(Boolean);

  const settingsMenuOptions = useMemo(
    () => [
      {
        label: 'Mark all as read',
        disabled: unreadCommentsCount <= 0,
        divider: false,
        action: () => {
          setOpenPrompt(true);
        },
      },
    ],
    [unreadCommentsCount]
  );

  useEffect(() => {
    const patientState = get(locationState, 'patient');
    if (patientState) {
      onShowSubmission(patientState.uid, patientState);
    }
  }, [locationState, onShowSubmission]);

  const promptMsg = t(
    'You are about to mark {{unreadCommentsCount}} unread comment{{plural}} as read. This action cannot be undone.',
    { unreadCommentsCount, plural: unreadCommentsCount === 1 ? '' : 's' }
  );

  // NOTE: Not necessary to extenalize this component to simply things out
  const renderMarkAllReadDialogPrompt = () => (
    <Dialog
      open={openPrompt}
      onClose={setOpenPrompt}
      type="prompt"
      title={t('Mark all as read')}
      isLoading={isLoading}
      renderFooter={() => (
        <Flex my={2} justifyContent="flex-end">
          <SecondaryOutlinedButton
            disabled={isLoading}
            onClick={() => {
              setOpenPrompt(false);
            }}
            mr={2}
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            type="submit"
            disabled={isLoading}
            onClick={() => {
              onMarkAllCommentsRead();
              setOpenPrompt(false);
            }}
            style={{ backgroundColor: theme.colors.danger }}
          >
            {t('Confirm')}
          </PrimaryButton>
        </Flex>
      )}
    >
      <Body>
        <Markdown>{promptMsg}</Markdown>
      </Body>
    </Dialog>
  );

  const onFilterChange = useCallback(
    (filters) => {
      onApplyFilters?.(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (statusCode && RetryStatusCodes.includes(statusCode)) {
    return (
      <RetryView onRefresh={onRefresh} isLoading={isLoading || isRefetching} />
    );
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <SearchInput
          inputStyle={{
            paddingBottom: '6px',
            paddingTop: '8px',
          }}
          hideRefresh={isMobile}
          data-testid="patient_monitoring_unresolved_alerts_search_input"
          placeholder={t('Search patients')}
          inputMinWidth={isMobile ? '100%' : '400px'}
          onSearch={onSearch}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onRefresh={onRefresh}
          isRefreshing={isLoading || isRefetching}
          lastUpdated={lastUpdated.current}
          totalCount={totalFetchDataCount}
          pageCount={submissions?.length || pageCount}
          refreshButtonProps={{
            style: {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
          }}
        />

        {clinician && !clinician.isCaregiver && (
          <Box
            display="block"
            mr={[unreadCommentsCount > 0 ? -52 : 'auto', 0]}
            width={[unreadCommentsCount > 0 ? 90 : 'auto', 'inherit']}
          >
            <Flex direction="row" justifyContent="space-between">
              {hasPermission && (
                <>
                  {promptMsg && renderMarkAllReadDialogPrompt()}

                  <SettingsActionMenu
                    options={settingsMenuOptions}
                    isMobile={isResponsive}
                    buttonLabel={t('Actions')}
                    buttonLabelIcon={faCog}
                    buttonProps={{ width: [46, 'auto'] }}
                  />
                </>
              )}

              {/* {!isMobile && (
                <Filter
                  activeFilters={requestParams}
                  minWidth={600}
                  filterOptions={filterOptions}
                  open={showFilters}
                  onOpen={onFilterOpen}
                  onResetFilters={onResetFilters}
                  onSave={onApplyFilters}
                  onCancel={onFilterCancel}
                  showPatientFilter
                />
              )} */}
            </Flex>
          </Box>
        )}

        <Box display={['block', 'block', 'none']}>
          <Sort
            onChange={(opt) => onFetchData({ sortBy: [opt] })}
            value={{
              orderField: requestParams?.orderField,
              orderDesc: requestParams?.orderDesc,
            }}
          />
        </Box>
      </Flex>

      {clinician && !clinician.isCaregiver && (
        <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
          <Filters
            requestParams={requestParams}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        </Box>
      )}

      <Box display={['none', 'none', 'block']}>
        <SubmissionTable
          clinic={clinic}
          clinician={clinician}
          tableSettings={tableSettings}
          tableSettingsSinglePatient={tableSettingsSinglePatient}
          showLoading={isLoading}
          data={tableData}
          onOpenDrawer={openDrawer}
          onShowSubmission={onShowSubmission}
          onShowPatientForm={(submission, path) =>
            onShowPatientForm(submission?.patientForm, path)
          }
          onSendMessage={(submission) => onSendMessage(submission?.patientForm)}
          onFetchData={onFetchData}
          onDownloadSubmissions={onDownloadPatientSubmissions}
          showDischargedLabel
          onFetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetched={isFetched}
          isFetchingNextPage={isFetchingNextPage}
          sortMapping={{
            SUBMITTED_ON: 'SUBMITTED_ON',
          }}
          initialSortBy={{
            orderField: requestParams?.orderField,
            orderDesc: requestParams?.orderDesc,
          }}
          eventSubSource={EventSubSource.Unread}
        />
      </Box>
      <Box display={['block', 'block', 'none']}>
        {tableData.map((submission) => (
          <SubmissionBoxWidget
            clinician={clinician}
            isMobile
            key={submission?.uid}
            patient={submission?.patient}
            cellData={submission}
            submission={submission}
            variant={submission?.statusColor}
            onCommentCreated={onCommentCreated}
            onShowPatientForm={(subParam, path) =>
              onShowPatientForm(subParam?.patientForm, path)
            }
            onShowSubmission={onShowSubmission}
            onSendMessage={onSendMessage}
            showSidePanel
            showActionMenu
            showDischargedLabel={!!submission?.patientForm?.isDischarged}
            onDownloadSubmissions={onDownloadPatientSubmissions}
          />
        ))}
        {isEmpty(tableData) && isFetched && (
          <Text fontSize={14} color="darkestShade" textAlign="center">
            {t('No data available')}.
          </Text>
        )}
        {hasNextPage && (
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            mt={3}
            mb={3}
          >
            <LoadMoreButton
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
              flex={1}
              style={{ height: 50 }}
            >
              <Text color="darkestShade" fontSize={16} fontWeight={600}>
                {!isFetchingNextPage && t('Load more')}
                {isFetchingNextPage && `${t('Loading')}...`}
              </Text>
            </LoadMoreButton>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default React.memo(UnreadCommentSection);
