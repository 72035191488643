import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_ENROLL_DISCHARGE = gql`
  mutation cleoPatientFormEnrollDischargeAsync(
    $input: CleoPatientFormEnrollDischargeAsyncInput!
  ) {
    cleoPatientFormEnrollDischargeAsync(input: $input) {
      asyncJob {
        uid
        status
        isReady
      }
      results {
        patientForm {
          uid
        }
        isPreviouslyEnrolled
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_ENROLL_DISCHARGE,
  queryType: 'mutation',
  baseQueryKey: 'patientFormEnrollDischargeAsync',
  keepPreviousData: false,
});
