import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT = gql`
  mutation heimdallAuthorizationFlowSessionExchange(
    $heimdallAuthorizationFlowInput: HeimdallAuthorizationFlowInput!
  ) {
    heimdallAuthorizationFlow(input: $heimdallAuthorizationFlowInput) {
      session {
        uid
        scopes
        expiresOn
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  keepPreviousData: true,
  baseQueryKey: 'heimdallAuthorizationFlowSessionExchange',
});
