import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const cleoAlertRuleFragment = `
  uid
  name
  parserImportPath
  parserSettings
  isForClinic
`;

const GRAPHQL_DOCUMENT = gql`
  mutation cleoAlertRuleCreate($input: CreateCleoAlertRuleInput!) {
    cleoAlertRuleCreate(input: $input) {
      cleoAlertRule {
        ${cleoAlertRuleFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'alertRule',
  keepPreviousData: true,
});
