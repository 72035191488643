import React from 'react';
import {
  ActionMenu,
  ActionMenuItem,
  Box,
  Flex,
  Text,
  SecondaryOutlinedButton,
  FAIcon,
  Checkbox,
  CheckboxGroup,
} from '@fivehealth/botero';
import { isEmpty, truncate, isFunction } from 'lodash';
import { faSlidersH, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const ChartDropdown = ({
  value,
  onClick,
  open,
  options = [],
  checkboxes = [],
  onChange,
  defaultLabel,
  label: dropdownLabel,
}) => {
  const selectedOption = options.find((opt) => opt.value === value) || {};
  const { t } = useTranslation();

  return (
    <ActionMenu
      open={open}
      label={
        dropdownLabel || (
          <SecondaryOutlinedButton
            borderColor="#D5D9DE"
            borderRadius={8}
            minWidth={124}
            p={0}
            height={40}
            onClick={onClick}
            startIcon={
              <FAIcon icon={faSlidersH} fontSize="14px" color="darkestShade" />
            }
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize={14} fontWeight={500} color="fullShade" mr={1}>
                {t(defaultLabel || t(`${selectedOption.label}`))}
              </Text>
              <FAIcon
                icon={faChevronDown}
                fontSize="14px"
                color="darkestShade"
              />
            </Flex>
          </SecondaryOutlinedButton>
        )
      }
    >
      <Box>
        {!isEmpty(options) &&
          options
            .filter(({ label }) => label)
            .map(({ label, disabled, ...itemData }, index) => (
              <ActionMenuItem
                key={index}
                onClick={() =>
                  disabled ? null : onChange({ label, ...itemData })
                }
                style={{ pointer: disabled ? 'default' : 'pointer' }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ opacity: disabled ? 0.4 : 1 }}
                >
                  <Text fontSize={14} color="fullShade">
                    {t(label)}
                  </Text>
                </Flex>
              </ActionMenuItem>
            ))}
        <Box px={1} py={2}>
          {!isEmpty(checkboxes) &&
            checkboxes.map(({ label, options: checkboxOptions }, index) => (
              <Box key={index} mb={2}>
                <Text
                  fontSize={14}
                  color="darkestShade"
                  fontWeight={600}
                  px={3}
                  minHeight={30}
                >
                  {label}
                </Text>
                <CheckboxGroup onChange={onChange}>
                  {checkboxOptions.map((item) => (
                    <Checkbox
                      key={item.id}
                      name={item.id}
                      checked={item.checked}
                      label={
                        isFunction(item.label)
                          ? item.label()
                          : truncate(item.key ? t(item.key) : t(item.label))
                      }
                      textProps={{ fontSize: 14, flex: 1 }}
                      color="primary"
                    />
                  ))}
                </CheckboxGroup>
              </Box>
            ))}
        </Box>
      </Box>
    </ActionMenu>
  );
};

export default ChartDropdown;
