import { useApi } from '@fivehealth/botero';
import { chain, includes } from 'lodash';
import { useMemo } from 'react';

const useMonitoringFormsData = (
  excludeMonitoringFormUids = [],
  variables = {
    isEnrollmentForm: false,
  }
) => {
  const {
    queries: { useMonitoringFormsets },
  } = useApi({
    queries: ['useMonitoringFormsets'],
  });

  const { data: monitoringForms, isLoading } = useMonitoringFormsets({
    variables,
  });

  const monitoringFormsSelectOptions = useMemo(
    () =>
      chain(monitoringForms)
        .filter((form) => !form?.isEnrollmentForm)
        .filter((form) => !includes(excludeMonitoringFormUids, form?.id))
        .map((form) => ({
          label: form?.label,
          value: form,
        }))
        .value(),
    [excludeMonitoringFormUids, monitoringForms]
  );

  return {
    monitoringForms,
    monitoringFormsSelectOptions,
    isLoading,
  };
};

export default useMonitoringFormsData;
