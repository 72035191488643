import React, { useCallback, useEffect } from 'react';
import { chain, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FormProvider, useForm } from 'react-hook-form';
import { faEllipsisV, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Flex,
  useApi,
  Body,
  Text,
  theme,
  FAIcon,
  PrimaryButton,
  H1,
  SearchInput,
} from '@fivehealth/botero';

import EVENTS from 'constants/events';
import { EventPage, EventSubSource } from 'constants';
import { useModal } from 'context/ModalContext';

// import Filter from 'components/Filter/Filter';
import TableLoader from 'components/Table/TableLoader';
import MobileTable from 'components/Table/MobileTable';
import { ContactsCell } from 'components/Table/TableCell';
import Table, { BoxContent } from 'components/Table/Table';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ActionDropdown from 'components/ActionDropdown/ActionDropdown';

import { RoleCell } from 'views/ClinicalAdmins/ClinicalAdminTable';
import LoadingSpinner from 'components/LoadingOverlay/LoadingSpinner';
import { Filters } from 'components/Filters/Filters';
import AddCaregiverUserModal from './AddCaregiverUserModal';
import DischargeCaregiverModal from './DischargeCaregiverModal';

const statuses = [
  {
    id: 'active',
    label: 'Active',
    toParams: {
      activated: true,
    },
  },
  {
    id: 'deactivated',
    label: 'Deactivated',
    toParams: {
      activated: false,
    },
  },
];

const ActionsCell = ({
  cell,
  isMobile,
  t,
  onEditCaregiverUser,
  onEnrollCaregiver,
  onDischargeCaregiver,
}) => {
  const dropdownOptions = [
    {
      key: 'caregiver_action_menu_edit_profile',
      testid: 'caregiver_action_menu_edit_profile',
      label: t('Edit profile'),
      visible: onEditCaregiverUser,
      logEventProps: {
        page: EventPage.Caregiver,
        subSource: EventSubSource.Caregiver,
        eventName: EVENTS.EDIT_CAREGIVER_PROFILE,
      },
      onClick: () => onEditCaregiverUser(cell.row.original),
    },
    {
      key: 'caregiver_action_menu_deactivate_profile',
      testid: 'caregiver_action_menu_deactivate_profile',
      label: t('Deactivate profile'),
      visible: onDischargeCaregiver && !cell.row.original?.deactivatedOn,
      logEventProps: {
        page: EventPage.Caregiver,
        subSource: EventSubSource.Caregiver,
        eventName: EVENTS.DEACTIVATE_CAREGIVER_PROFILE,
      },
      minHeight: 55,
      divider: true,
      labelProps: {
        color: 'danger',
      },
      onClick: () => onDischargeCaregiver(cell.row.original),
    },
    {
      key: 'caregiver_action_menu_reactivate_profile',
      testid: 'caregiver_action_menu_reactivate_profile',
      label: t('Reactivate Profile'),
      visible: onEnrollCaregiver && cell.row.original?.deactivatedOn,
      logEventProps: {
        page: EventPage.Caregiver,
        subSource: EventSubSource.Caregiver,
        eventName: EVENTS.REACTIVAE_CAREGIVER_PROFILE,
      },
      minHeight: 55,
      divider: true,
      labelProps: {
        color: 'fullShade',
      },
      onClick: () => onEnrollCaregiver(cell.row.original),
    },
  ];

  return (
    <Flex
      justifyContent="right"
      alignItems="center"
      height={[56, 56, null]}
      pl={[2, 2, null]}
      pr={[2, 2, null]}
      style={
        isMobile
          ? {
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottomWidth: 0,
              borderTopWidth: 1,
              borderColor: theme.colors.mediumShade,
              borderStyle: 'solid',
            }
          : {}
      }
    >
      <ActionDropdown
        testid="caregiver_action_menu"
        label={
          isMobile && (
            <Flex>
              <Box>
                <FAIcon icon={faEllipsisV} hover={{ opacity: 0.6 }} />
              </Box>
              <Text ml={2}>{t('More')}</Text>
            </Flex>
          )
        }
        dropdownOptions={dropdownOptions}
      />
    </Flex>
  );
};

const Caregiver = ({
  // tab with filter
  t,
  lastUpdated,
  clinicians,
  requestParams,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isRefetching,
  isFetchingNextPage,
  // showFilters,
  onSearch,
  onRefresh,
  onFetchData,
  // onFilterOpen,
  // onFilterCancel,
  onApplyFilters,
  onResetFilters,
  isFetched,
  searchText,
  setSearchText,
  totalFetchDataCount,
  pageCount,
}) => {
  const methods = useForm();
  const history = useHistory();
  const { openModal, closeModal } = useModal();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const {
    queries: {
      useClinic,
      useCaregiverRole,
      useCurrentUser,
      useClinicianDischarge,
      useUpdateClinician,
    },
  } = useApi({
    queries: [
      'useClinic',
      'useCaregiverRole',
      'useCurrentUser',
      'useClinicianDischarge',
      'useUpdateClinician',
    ],
  });

  const { data: clinic } = useClinic();

  const { data: currentUser } = useCurrentUser();

  const { data: caregiverRoleUid, isLoading: loadingCaregiverRole } =
    useCaregiverRole();

  const { isLoading: isDischargeLoading, mutateAsync: dischargeClinician } =
    useClinicianDischarge({
      variables: {},
      onSuccess: onRefresh,
    });

  const { isLoading: isEnrollmentLoading, mutateAsync: enrollClinician } =
    useUpdateClinician({
      variables: {},
      onSuccess: onRefresh,
    });

  useEffect(() => {
    if (!caregiverRoleUid && !loadingCaregiverRole) {
      history.push('/');
    }
  }, [loadingCaregiverRole, caregiverRoleUid, history]);

  const buildFilters = () => {
    const mappedStatuses = statuses.map((status) => ({
      ...status,
      label: t(status.label),
    }));

    return [{ id: 'status', title: 'Status', data: mappedStatuses }];
  };

  const filterOptions = buildFilters().filter(Boolean);

  const onEnrollCaregiver = (caregiver) =>
    enrollClinician({
      input: { uid: caregiver.uid, deactivatedOn: null },
    }).then(() => onRefresh());

  const onDischargeCaregiver = (caregiver) => {
    openModal(
      <FormProvider {...methods}>
        <DischargeCaregiverModal
          caregiverName={caregiver.name}
          closeModal={closeModal}
          onDischargeSubmit={() =>
            dischargeClinician({ input: { uid: caregiver.uid } }).then(() =>
              closeModal()
            )
          }
          isSubmitting={isDischargeLoading}
        />
      </FormProvider>,
      {
        style: {
          padding: 24,
          overflow: 'visible',
        },
      }
    );
  };

  const renderStatus = (rowData) => {
    const status = rowData?.deactivatedOn ? 'Deactivated' : 'Active';
    return (
      <BoxContent style={{ width: 'fit-content' }}>
        <StatusBadge
          text={status}
          color={rowData?.deactivatedOn ? 'darkestShade' : 'success'}
        />
      </BoxContent>
    );
  };

  const getCommunicationMethodLabel = (key) =>
    clinic?.communicationMethods?.find(({ gql }) => gql === key)?.label || '-';

  /* eslint-disable react/no-unstable-nested-components */
  const renderCommunicationMethod = (rowData) => (
    <BoxContent>
      <Body small>
        {getCommunicationMethodLabel(rowData.communicationMethod)}
      </Body>
    </BoxContent>
  );

  const RenderPatientsAssigned = ({ row: { original: rowData } }) => {
    const patientsNames = chain(rowData)
      .get('caregiverRelations', [])
      .map(({ patientForm }) => patientForm?.patient?.name)
      .join(', ')
      .value();
    /* eslint-disable react/no-unstable-nested-components */
    return (
      <BoxContent pr={2}>
        <Body small width="80%">
          {patientsNames || '-'}
        </Body>
      </BoxContent>
    );
  };

  const columns = [
    {
      id: 'NAME',
      accessor: 'name',
      Header: t('Family Member'),
      Cell: ({ value }, index) => <RoleCell key={index} value={value || '-'} />,
    },
    {
      Header: t('Contact details'),
      // accessor: 'email',
      disableSortBy: true,
      Cell: ContactsCell,
    },
    {
      Header: t('Assigned Patients'),
      disableSortBy: true,
      Cell: RenderPatientsAssigned,
    },
    {
      id: 'COMMUNICATION_METHOD',
      accessor: 'communicationMethod',
      Header: t('Alerted via'),
      disableSortBy: true,
      Cell: ({ row: { original: rowData } }) =>
        renderCommunicationMethod(rowData),
    },
    {
      id: 'STATUS',
      accessor: 'deactivatedOn',
      Header: t('Status'),
      Cell: ({ row: { original: rowData } }) => renderStatus(rowData),
    },
    {
      id: 'actions',
      Header: '',
      disableSortBy: true,
      width: isMobile ? '150px' : '40px',
      Cell: ActionsCell,
    },
  ];

  const closeCaregiverAddEditModal = async () => {
    closeModal();
    await onRefresh();
  };

  const onAddCaregiver = () => {
    openModal(
      <FormProvider {...methods}>
        <AddCaregiverUserModal
          caregiverRoleUid={caregiverRoleUid}
          closeModal={closeCaregiverAddEditModal}
          currentUser={currentUser}
        />
      </FormProvider>,
      {
        style: {
          width: isMobile ? '100%' : '50%',
          overflowY: 'visible',
        },
      }
    );
  };

  const onEditCaregiverUser = (caregiverUser) => {
    openModal(
      <FormProvider {...methods}>
        <AddCaregiverUserModal
          caregiverRoleUid={caregiverRoleUid}
          user={caregiverUser}
          currentUser={currentUser}
          closeModal={closeCaregiverAddEditModal}
        />
      </FormProvider>,
      {
        style: {
          width: isMobile ? '100%' : '50%',
          overflowY: 'visible',
        },
      }
    );
  };

  const onFilterChange = useCallback(
    (filters) => {
      onApplyFilters?.(filters);
      if (isEmpty(filters)) {
        onResetFilters?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box py={4}>
      {(isEnrollmentLoading || isDischargeLoading) && (
        <LoadingSpinner textColor="fullShade" />
      )}
      <Flex pb={3} justifyContent="space-between">
        <Box>
          <H1>{t('Family Members')}</H1>
          <Body pt={2}>
            {t("View and manage all your patient's family members.")}
          </Body>
        </Box>

        <Box>
          <Flex pt={[2, 0]} pl={2}>
            <PrimaryButton
              onClick={onAddCaregiver}
              display={['none', 'initial', 'initial']}
              logEventProps={{
                page: 'Patient Caregivers',
                eventName: EVENTS.ADD_CAREGIVER,
              }}
              borderRadius={8}
              pt={2}
              pb={2}
              height={40}
              mb={[1, 0]}
              data-testid="caregiver_list_add_new_btn"
            >
              {t('Add Family Member')}
            </PrimaryButton>

            <PrimaryButton
              onClick={onAddCaregiver}
              display={['initial', 'none', 'none']}
              logEventProps={{
                page: 'Patient Caregivers',
                eventName: EVENTS.ADD_CAREGIVER,
              }}
              mr={[0, 3]}
              borderRadius={8}
              pb={2}
              height={40}
              mb={[1, 0]}
              data-testid="caregiver_list_add_new_mobile_btn"
            >
              <FAIcon color="emptyShade" icon={faPlus} />
            </PrimaryButton>
          </Flex>
        </Box>
      </Flex>

      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <SearchInput
          inputStyle={{
            paddingBottom: '6px',
            paddingTop: '8px',
          }}
          hideRefresh={isMobile}
          placeholder={t('Search family member by name')}
          inputMinWidth={isMobile ? '100%' : '400px'}
          onSearch={onSearch}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onRefresh={onRefresh}
          isRefreshing={isRefetching}
          lastUpdated={lastUpdated.current}
          totalCount={totalFetchDataCount}
          pageCount={clinicians?.length || pageCount}
          refreshButtonProps={{
            style: {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
          }}
        />

        {/* <Box display={['none', 'none', 'block']}>
          <Filter
            activeFilters={requestParams}
            filterOptions={filterOptions}
            open={showFilters}
            onOpen={onFilterOpen}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            showPatientFilter={false}
          />
        </Box> */}
      </Flex>

      <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
        <Filters
          requestParams={requestParams}
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
        />
      </Box>

      {(isLoading || !clinic) && <TableLoader />}
      {!isLoading && clinic && (
        <Table
          t={t}
          isFetched={isFetched}
          isMobile={isMobile}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onFetchData={onFetchData}
          data={clinicians}
          currentUser={currentUser}
          columns={columns}
          renderTable={isMobile ? MobileTable : null}
          initialSortBy={requestParams}
          onEditCaregiverUser={onEditCaregiverUser}
          onEnrollCaregiver={onEnrollCaregiver}
          onDischargeCaregiver={onDischargeCaregiver}
        />
      )}
    </Box>
  );
};

export default Caregiver;
