import parsePhoneNumber from 'libphonenumber-js';

const formatPhoneNumber = (value) => {
  if (!value) {
    return '';
  }
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber.formatInternational();
};

export default formatPhoneNumber;
