import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  chain,
  get,
  isEmpty,
  isEqual,
  isUndefined,
  pickBy,
  toLower,
  toUpper,
} from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  useApi,
  SecondaryOutlinedButton,
  FAIcon,
  Body,
  Dialog,
  Text,
  DangerButton,
  Box,
  SearchInput,
  Select,
  H6,
} from '@fivehealth/botero';

import { PATIENT_LIST_SELECTION_OPTION } from 'Config';

import EVENTS from 'constants/events';

import { ActionMenuType, EventSubSource, EventPage } from 'constants';

import { checkIsMyPatient, checkPermissions } from 'AppUtils';

// import SearchBar from 'components/Table/SearchBar';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DialogTitle from 'components/Dialog/DialogTitle';

import { faCog, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { H2 } from '@fivehealth/botero/build/components/Text/Text';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '@fivehealth/botero/build/components/Button/PrimaryButton';
import { useForm } from 'react-hook-form';
import GroupInfoInputs from 'views/PatientGroups/GroupInfoInputs';
import CreateGroupSuccessModal from 'views/PatientGroups/GroupCreationSuccessModal';
import useMonitoringFormsData from 'hooks/useMonitoringFormsData';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import { useEnrollDischargeAsync } from 'hooks';
import { Filters } from 'components/Filters/Filters';
import { RetryStatusCodes } from 'constants/retryStatusCodes';
import { RetryView } from 'views/RetryView/RetryView';
import { useModal } from '../../context/ModalContext';

import PatientFormTable from './PatientFormTable';
import AddFormModal from './PatientShow/AddForm.modal';
import MessageTemplateModal from './PatientMessageTemplate.modal';
import DischargeFormModal from './PatientShow/DischargeForm.modal';

const AllPatientsSection = forwardRef(
  (
    {
      // props
      openDrawer,
      deactivated,
      onEditPatientForm,
      // onSendMessage,
      onShowPatientForm,
      clinic,
      showFilterBtn = true,

      // tab with filter
      t,
      lastUpdated,
      patients,
      requestParams,
      fetchNextPage,
      hasNextPage,
      isLoading,
      isRefetching,
      isFetched,
      isFetchingNextPage,
      clinicalUserFilters,
      genderFilters,
      devicesFilters,
      dateRangeFilters,
      monitoringFormsetsFilters,
      onSearch,
      onRefresh,
      onFetchData,
      onApplyFilters,
      onResetFilters,
      totalCount,
      totalFetchDataCount,
      pageCount,

      // search
      searchText,
      setSearchText,

      // bulk actions
      enableRowSelect,
      setEnableRowSelect,
      autoResetSelectedRows,
      setAutoResetSelectedRows,
      onSavePatientsGroup = () => {},
      onRowSelectionCallback,
      onCancelCallback = () => {},

      // patient group
      patientGroupSelection = false,
      isViewMode,
      statusCode,
    },
    ref
  ) => {
    const history = useHistory();
    const filtersRef = useRef();

    const { openModal, closeModal: closeModalHookCall } = useModal();

    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [groupSelectedPatients, setGroupSelectedPatients] = useState([]);

    const [selectionOptionChosen, setSelectionOptionChosen] = useState();
    const [removedSelection, setRemovedSelection] = useState([]);

    const [requesting, setRequesting] = useState(false);

    const programDischargeDefaultSelection = useMemo(
      () => ({
        label: t('All programs'),
        value: null,
      }),
      [t]
    );

    const [selectedForm, setSelectedForm] = useState();

    const [showDischargeModal, setShowDischargeModal] = useState({
      visible: false,
      rows: [],
    });

    const {
      monitoringFormsSelectOptions,
      isLoading: isLoadingMonitoringForms,
    } = useMonitoringFormsData();

    const mfOptions = useMemo(
      () => [programDischargeDefaultSelection, ...monitoringFormsSelectOptions],
      [monitoringFormsSelectOptions, programDischargeDefaultSelection]
    );

    const patientFormTableRef = useRef();

    const communicationMethodsFilters = useMemo(
      () => ({
        id: 'communicationMethods',
        title: t('Messaging platform'),
        multiSelect: true,
        data: chain(clinic)
          .get('communicationMethods')
          .map((method) => {
            if (method.value === 'email' || method.value === 'botmd')
              return null;
            return {
              id: method.value,
              label: t(method.label),
              toParams: {
                communicationMethod: [toUpper(method.value)],
              },
            };
          })
          .filter(Boolean)
          .value(),
      }),
      [clinic, t]
    );

    const filterOptions = [
      monitoringFormsetsFilters,
      dateRangeFilters,
      communicationMethodsFilters,
      devicesFilters,
      clinicalUserFilters,
      genderFilters,
    ].filter(Boolean);

    const {
      queries: {
        useCurrentUser,
        usePatientFormEnrollDischarge,
        usePatientUpdate,
        usePatientGroupsCreate,
      },
    } = useApi({
      queries: [
        'useCurrentUser',
        'usePatientFormEnrollDischarge',
        'usePatientUpdate',
        'usePatientGroupsCreate',
      ],
    });

    const { dischargeOrEnrollPatients } = useEnrollDischargeAsync();

    const { mutateAsync: createPatientGroup } = usePatientGroupsCreate({
      variables: {},
    });

    const { sendMessage } = useSendMessageAsync();

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const [groupDescription, setGroupDescription] = useState('');
    const [groupName, setGroupName] = useState('');

    const { control } = useForm({
      mode: 'onChange',
      defaultValues: {
        name: '',
        description: '',
      },
    });

    const { data: clinician } = useCurrentUser();

    const { mutateAsync: updatePatient } = usePatientUpdate({
      variables: {},
    });

    const {
      // isLoading: isDischargePatientLoading,
      mutateAsync: enrollOrDischargePatientForm,
    } = usePatientFormEnrollDischarge({
      variables: {},
      onSuccess: onRefresh,
    });

    // const onSubmitDischargePatientForm = useCallback(
    //   (patientForm) => {
    //     console.log({ patientForm });
    //     enrollOrDischargePatientForm({
    //       input: {
    //         patientUids: [patientForm?.patient?.uid],
    //         operation: 'DISCHARGE',
    //       },
    //     }).then(() => onRefresh().then(() => closeModal()));
    //   },
    //   [closeModal, enrollOrDischargePatientForm, onRefresh]
    // );

    const getAppliedFilters = useCallback(() => {
      if (selectionOptionChosen !== PATIENT_LIST_SELECTION_OPTION.ALL_ROWS)
        return null;

      let filtersApplied = filtersRef.current.getFilters();

      delete filtersApplied.orderField;
      delete filtersApplied.orderDesc;
      delete filtersApplied.first;

      filtersApplied.createdOn_Gte = filtersApplied.enrolledAfter;
      delete filtersApplied.enrolledAfter;

      filtersApplied.communicationMethod_In =
        filtersApplied.communicationMethod?.map((cm) => toLower(cm));
      delete filtersApplied.communicationMethod;

      filtersApplied.gender_In = filtersApplied.genderIn;
      delete filtersApplied.genderIn;

      filtersApplied.enrolledMonitoringForm_In =
        filtersApplied.enrolledMonitoringFormIn;
      delete filtersApplied.enrolledMonitoringFormIn;

      filtersApplied.patientForms_ClinicianRelations_Clinician_Uid_In =
        filtersApplied.clinicianIcUid;
      delete filtersApplied.clinicianIcUid;

      // Remove keys with empty values
      filtersApplied = pickBy(filtersApplied, (v) => !!v);
      filtersApplied.deactivatedOn_Isnull = !deactivated;

      return filtersApplied;
    }, [deactivated, selectionOptionChosen]);

    useImperativeHandle(ref, () => ({
      async getSelectedPatients() {
        if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
          return {
            patients: [],
            patientFilter: getAppliedFilters(),
            count: totalFetchDataCount,
          };
        }
        const patientsSelected =
          patientFormTableRef.current.getSelectedPatients();
        return {
          patients: patientsSelected,
          patientFilter: null,
          count: patientsSelected.length,
        };
      },
    }));

    const closeModal = useCallback(
      (resetSelectionOption = true) => {
        closeModalHookCall();
        if (resetSelectionOption) {
          setSearchText('');
          setSelectionOptionChosen(undefined);
        }
      },
      [closeModalHookCall, setSearchText]
    );

    const onSubmitDeactivatePatientForm = useCallback(
      (patientForm) => {
        setRequesting(true);
        closeModal();
        enrollOrDischargePatientForm({
          input: {
            patientUids: [patientForm?.patient?.uid],
            deactivatePatient: true,
            operation: 'DISCHARGE',
          },
        }).then(() =>
          onRefresh().then(() => {
            setRequesting(false);
            setEnableRowSelect(false);
          })
        );
      },
      [closeModal, enrollOrDischargePatientForm, onRefresh, setEnableRowSelect]
    );

    // const onDischargePatientForm = useCallback(
    //   (patientForm) =>
    //     onDischargePatientFormCallback(
    //       openModal,
    //       closeModal,
    //       patientForm,
    //       onSubmitDischargePatientForm,
    //       isDischargePatientLoading,
    //       onRefresh
    //     ),
    //   [
    //     closeModal,
    //     isDischargePatientLoading,
    //     onRefresh,
    //     onSubmitDischargePatientForm,
    //     openModal,
    //   ]
    // );

    const onReactivatePatients = useCallback(
      (selectedPatients) => {
        const patientUids = selectedPatients
          .map((p) => p.id)
          .filter(
            (id) => !removedSelection.find((rm) => rm.original.uid === id)
          );

        return openModal(
          <Box>
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
              <H2>
                {t('Activate')} {t('patients')}?
              </H2>
              <Box
                cursor="pointer"
                onClick={() => {
                  closeModal(false);
                }}
              >
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>
            <Box>
              <Text mb={2}>
                {t('What happens when patients are activated?')}
              </Text>
              <ul>
                <li>{t('They will need to be re-enrolled into a program.')}</li>
                <li>
                  {t(
                    'The patients can start receiving monitoring & reminder messages again.'
                  )}
                </li>
              </ul>
            </Box>
            <Flex justifyContent="end">
              <SecondaryOutlinedButton mr={2} onClick={closeModal}>
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                onClick={() => {
                  closeModal();
                  setRequesting(true);

                  enrollOrDischargePatientForm({
                    input: {
                      patientUids,
                      operation: 'ENROLL',
                    },
                  }).then(() =>
                    onRefresh().then(() => {
                      setRequesting(false);
                      setEnableRowSelect(false);
                    })
                  );
                }}
              >
                {t('Activate')}
              </PrimaryButton>
            </Flex>
          </Box>,
          {
            style: {
              width: isMobile ? '100%' : '50%',
            },
          }
        );
      },
      [
        closeModal,
        openModal,
        t,
        isMobile,
        onRefresh,
        // updatePatient,
        removedSelection,
        enrollOrDischargePatientForm,
        setEnableRowSelect,
      ]
    );

    const onReactivatePatient = useCallback(
      (patientForm) =>
        openModal(
          <Box>
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
              <H2>
                {t('Activate')} {patientForm.patient.name}?
              </H2>
              <Box
                cursor="pointer"
                onClick={() => {
                  closeModal(false);
                }}
              >
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>
            <Box>
              <Text mb={2}>
                {t('What happens when a patient is activated?')}
              </Text>
              <ul>
                <li>
                  {t('The patient will need to be re-enrolled into programs.')}
                </li>
                <li>
                  {t(
                    'The patient can start receiving monitoring & reminder messages again.'
                  )}
                </li>
              </ul>
            </Box>
            <Flex justifyContent="end">
              <SecondaryOutlinedButton mr={2} onClick={closeModal}>
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                onClick={() => {
                  closeModal();
                  setRequesting(true);
                  updatePatient({
                    input: {
                      uid: patientForm?.patient?.uid,
                      deactivatedOn: null,
                    },
                  }).then(() => {
                    setRequesting(false);
                    onRefresh();
                  });
                }}
              >
                {t('Activate')}
              </PrimaryButton>
            </Flex>
          </Box>,
          {
            style: {
              width: isMobile ? '100%' : '50%',
            },
          }
        ),
      [closeModal, openModal, t, isMobile, onRefresh, updatePatient]
    );

    const onDeactivatePatientForm = useCallback(
      (patientForm) => {
        openModal(
          <Box>
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
              <H2>
                {t('Deactivate')} {patientForm.patient.name}
              </H2>
              <Box cursor="pointer" onClick={() => closeModal(false)}>
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>
            <Box>
              <Text mb={2}>
                {t('What happens when a patient is deactivated?')}
              </Text>
              <ul>
                <li>
                  <Text>
                    {t(
                      'If the patient is enrolled in a program, he/she will receive a message informing them that they have been discharged.'
                    )}
                  </Text>
                </li>
                <li>
                  <Text>
                    {t('The patient will no longer receive reminders')}
                  </Text>
                </li>
                <li>
                  <Text>
                    {t(
                      'The patient’s graphs and submissions will still be available for download under Patient List'
                    )}
                  </Text>
                </li>
              </ul>
            </Box>
            <Flex justifyContent="end">
              <SecondaryOutlinedButton mr={2} onClick={closeModal}>
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <DangerButton
                onClick={() => {
                  onSubmitDeactivatePatientForm(patientForm);
                }}
              >
                {t('Deactivate')}
              </DangerButton>
            </Flex>
          </Box>,
          {
            style: {
              width: isMobile ? '100%' : '50%',
            },
          }
        );
      },
      [closeModal, onSubmitDeactivatePatientForm, openModal, isMobile, t]
    );

    const showAction = useCallback(
      (form) => {
        const hasMutatePatientFormPermission = !chain(clinician)
          .get('role.permissions')
          .intersection(['mutate_all_patientforms'])
          .isEmpty()
          .value();

        if (hasMutatePatientFormPermission) {
          return true;
        }

        return (
          checkPermissions(clinician, [
            'mutate_ic_patientforms',
            'mutate_alertee_patientforms',
          ]) && checkIsMyPatient(clinician, form)
        );
      },
      [clinician]
    );

    const onCloseAddForm = useCallback(
      async (updated = false) => {
        closeModal(updated);
        if (updated) {
          setEnableRowSelect(false);
          await onRefresh();
          patientFormTableRef?.current?.resetAllPagesSelectedRows();
        }
      },
      [closeModal, setEnableRowSelect, onRefresh]
    );

    const onDischargeForm = useCallback(
      ({ patient }) => {
        openModal(
          <DischargeFormModal
            t={t}
            onClose={onCloseAddForm}
            patients={[patient]}
            enrolledMonitoringFormUidsParam={patient.variablesAll?.monitoringForms.map(
              (mnf) => mnf.uid
            )}
          />,
          {
            padding: 24,
            overflow: 'scroll',
          }
        );
      },
      [onCloseAddForm, openModal, t]
    );

    const onAddForm = useCallback(
      ({ patient }) => {
        openModal(
          <AddFormModal
            t={t}
            onClose={onCloseAddForm}
            patients={[patient]}
            enrolledMonitoringFormUidsParam={patient.variablesAll?.monitoringForms.map(
              (mnf) => mnf.uid
            )}
          />,
          {
            style: {
              padding: 24,
              overflow: 'scroll',
            },
          }
        );
      },
      [onCloseAddForm, openModal, t]
    );

    const onSubmitMessageCreate = useCallback(
      async ({
        template,
        patientForm,
        userVariables,
        patientUids,
        monitoringForm,
        patientEventUid,
      }) => {
        const templateId = get(template, 'uid');

        const patientFilter = getAppliedFilters();

        const uids = patientUids;

        const payload = {
          messageTemplateSetUid: templateId,
          monitoringFormUid: monitoringForm?.uid,
          userVariables,
        };

        // if patientEvent is set then we need to send the patientEventUid
        if (patientEventUid) {
          payload.patientEventUid = patientEventUid;
        }

        if (patientFilter) {
          payload.patientFilter = patientFilter;
        }

        if (!patientFilter) {
          payload.patientUids = !isEmpty(uids)
            ? uids
            : [patientForm?.patient?.uid];
        }

        try {
          const result = await sendMessage(payload);

          return result;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log({ error });
          return null;
        }
      },
      [sendMessage, getAppliedFilters]
    );

    const onSendMessage = useCallback(
      async (patient, selectedPatients) => {
        let patientsPayload = selectedPatients;

        if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
          patientsPayload = [];
        }

        openModal(
          <MessageTemplateModal
            isSelectAll={
              selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS
            }
            selectAllCount={totalFetchDataCount}
            isPatientsView={!(patientsPayload?.length > 1)}
            patientForm={patient ? { patient } : {}}
            selectedPatients={patientsPayload}
            closeModal={onCloseAddForm}
            onSubmit={onSubmitMessageCreate}
          />,
          {
            style: {
              padding: 24,
              overflow: 'scroll',
            },
          }
        );
      },
      [
        selectionOptionChosen,
        openModal,
        totalFetchDataCount,
        onCloseAddForm,
        onSubmitMessageCreate,
      ]
    );

    const closeGroupModal = () => {
      closeModal(true);
      setGroupSelectedPatients([]);
      setShowCreateGroupModal(false);
      setGroupName('');
      setGroupDescription('');
      setEnableRowSelect(false);
    };

    const onSaveGroup = async () => {
      try {
        setRequesting(true);
        const selectedPatientUids = groupSelectedPatients.map((p) => p.id);

        const filters = getAppliedFilters();

        const input = {
          name: groupName,
          description: groupDescription,
          addPatients: selectedPatientUids,
        };
        // if filters are applied, add them to the input, only happens on Select All
        if (filters) {
          input.patientFilter = filters;
        }

        await createPatientGroup({
          input,
        }).then((r) => r);

        closeGroupModal();

        openModal(
          <CreateGroupSuccessModal
            membersCount={
              selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS
                ? totalFetchDataCount
                : selectedPatientUids?.length
            }
            primaryButtonLabel="View groups"
            secondaryButtonLabel="Close"
            closeModal={closeGroupModal}
            onViewGroup={() => {
              closeGroupModal();
              history.push('/patient-groups');
            }}
            groupName={groupName}
          />,
          {
            style: {
              width: isMobile ? '100%' : '50%',
              overflowY: 'visible',
            },
          }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setRequesting(false);
      }
    };

    const onCreateGroup = async (rows) => {
      let patientsData = rows
        .map(({ original }) => original)
        .filter(
          (ar) => !removedSelection.find((rm) => rm.original.uid === ar.uid)
        );

      if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
        /* const {
          cleoPatients: { edges },
        } = await getAllPatients(client, !deactivated, totalCount).then(
          (r) => r
        );

        const patientsNode = map(edges ?? [], ({ node }) => node);

        const mappedPatients = map(patientsNode ?? [], onMapNodePatients);

        patientsData = mappedPatients.filter(
          (ar) => !removedSelection.find((rm) => rm.original.uid === ar.uid)
        ); */
        patientsData = [];
      }

      setGroupSelectedPatients(patientsData);

      setShowCreateGroupModal(true);
    };

    const onAddFormBulk = useCallback(
      async (rows) => {
        let patientsData = rows
          .map(({ original }) => original)
          .filter(
            (ar) => !removedSelection.find((rm) => rm.original.uid === ar.uid)
          );

        if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
          patientsData = [];
        }

        // TODO: we do want to show all monitoring forms to allow other users to be selected for one
        const patientsMonitoringFormUid = []; /* map(patientsData)
        .flatMap((pd) => pd.variablesAll?.monitoringForms)
        .map((mf) => mf.uid); */

        const filters = getAppliedFilters();
        openModal(
          <AddFormModal
            t={t}
            filtersApplied={filters}
            filteredPatientsCount={totalFetchDataCount}
            onClose={onCloseAddForm}
            patients={patientsData}
            enrolledMonitoringFormUidsParam={patientsMonitoringFormUid}
          />,
          {
            style: {
              padding: 24,
              overflow: 'scroll',
            },
          }
        );
      },
      [
        selectionOptionChosen,
        getAppliedFilters,
        openModal,
        t,
        totalFetchDataCount,
        onCloseAddForm,
        removedSelection,
      ]
    );

    const getActionMenu = useCallback(
      (menuType, patientForm) => {
        const hasMonitoringForm = !isEmpty(
          patientForm?.patient?.enrolledMonitoringForms.concat(
            patientForm?.patient?.dischargedMonitoringForms
          )
        );
        if (isEqual(menuType, ActionMenuType.Active)) {
          return [
            !isMobile && {
              key: 'patient_list_action_menu_view_profile',
              testid: 'patient_list_action_menu_view_profile',
              label: t('View profile'),
              visible: onShowPatientForm,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.VIEW_PATIENT_FORM,
              },
              onClick: () => onShowPatientForm(patientForm),
            },
            {
              key: 'patient_list_action_menu_view_edit_profile',
              testid: 'patient_list_action_menu_view_edit_profile',
              label: t('Edit profile'),
              visible: showAction(patientForm) && onEditPatientForm,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.EDIT_PATIENT_PROFILE,
              },
              onClick: () => onEditPatientForm(patientForm, onRefresh),
            },
            hasMonitoringForm && {
              key: 'patient_list_action_menu',
              testid: 'patient_list_action_menu-clinical_settings',
              label: t('View clinical settings'),
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.VIEW_PATIENT_FORM,
              },
              onClick: () =>
                history.push(
                  `/patient-list/${patientForm.patient.uid}/clinical-settings`
                ),
            },
            hasMonitoringForm && {
              key: 'patient_list_action_menu_view_submissions',
              testid: 'patient_list_action_menu_view_submissions',
              label: t('View submissions'),
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.LIST_PATIENT_SUBMISSIONS,
              },
              onClick: () =>
                history.push(
                  `/patient-list/${patientForm.patient.uid}/submissions`
                ),
            },
            hasMonitoringForm && {
              key: 'patient_list_action_menu_view_graphs',
              testid: 'patient_list_action_menu_view_graphs',
              label: t('View graphs'),
              visible: onShowPatientForm,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.VIEW_GRAPHS,
              },
              onClick: () => onShowPatientForm(patientForm, 'graphs'),
            },
            {
              key: 'patient_list_action_menu_view_messages',
              testid: 'patient_list_action_menu_view_messages',
              label: t('Messages logs'),
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.LIST_PATIENT_SUBMISSIONS,
              },
              onClick: () =>
                history.push(
                  `/patient-list/${patientForm.patient.uid}/messages`
                ),
            },
            {
              key: 'patient_list_action_menu_add_form',
              testid: 'patient_list_action_menu_add_form',
              label: t('Add program'),
              visible: true,
              separate: true,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.CLICK_SEND_MESSAGE,
              },
              onClick: () => onAddForm(patientForm),
            },
            {
              key: 'patient_list_action_menu_send_message',
              testid: 'patient_list_action_menu_send_message',
              label: t('Send message'),
              visible: onSendMessage && clinician?.isCaregiver === false,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.CLICK_SEND_MESSAGE,
              },
              onClick: () => onSendMessage(patientForm?.patient),
            },
            patientForm?.patient?.enrolledMonitoringForms.length > 0 && {
              key: 'patient_list_action_menu_discharge_form',
              testid: 'patient_list_action_menu_discharge_form',
              label: t('Remove program'),
              labelProps: {
                color: 'danger',
              },
              visible: true,
              separate: true,
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.CLICK_SEND_MESSAGE,
              },
              onClick: () => onDischargeForm(patientForm),
            },

            // {
            //   key: 'patient_list_action_menu_download_submissions',
            //   testid: 'patient_list_action_menu_download_submissions',
            //   label: t('Download data'),
            //   visible: onDownloadPatientFormSubmissions,
            //   logEventProps: {
            //     page: EventPage.PatientList,
            //     subSource: EventSubSource.Discharge,
            //     eventName: EVENTS.DOWNLOAD_PATIENT_SUBMISSIONS,
            //   },
            //   onClick: () => onDownloadPatientFormSubmissions(patientForm),
            // },
            // {
            //   key: 'patient_list_action_menu_view_clinical_settings',
            //   testid: 'patient_list_action_menu_view_clinical_settings',
            //   label: t('View clinical settings'),
            //   visible: onShowPatientForm,
            //   logEventProps: {
            //     page: EventPage.PatientList,
            //     subSource: EventSubSource.Discharge,
            //     eventName: EVENTS.EDIT_PATIENT_PROFILE,
            //   },
            //   onClick: () => onShowPatientForm(patientForm, 'clinical-settings'),
            // },
            // {
            //   key: 'patient_list_action_menu_download_submissions',
            //   testid: 'patient_list_action_menu_download_submissions',
            //   label: t('Download data'),
            //   visible: onDownloadPatientFormSubmissions,
            //   logEventProps: {
            //     page: EventPage.PatientList,
            //     subSource: EventSubSource.Discharge,
            //     eventName: EVENTS.DOWNLOAD_PATIENT_SUBMISSIONS,
            //   },
            //   onClick: () => onDownloadPatientFormSubmissions(patientForm),
            // },
            {
              key: 'patient_list_action_menu_discharge_patient',
              testid: 'patient_list_action_menu_discharge_patient',
              label: t('Deactivate Patient'),
              visible: showAction(patientForm),
              logEventProps: {
                page: EventPage.PatientList,
                subSource: EventSubSource.Discharge,
                eventName: EVENTS.DISCHARGE_PATIENT,
              },
              onClick: () => onDeactivatePatientForm(patientForm),
              minHeight: 55,
              divider: true,
              labelProps: {
                color: 'danger',
              },
            },
          ].filter(Boolean);
        }

        return [
          {
            key: 'patient_list_action_menu_view_profile',
            testid: 'patient_list_action_menu_view_profile',
            label: t('View profile'),
            visible: onShowPatientForm,
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.VIEW_PATIENT_FORM,
            },
            onClick: () => onShowPatientForm(patientForm),
          },
          hasMonitoringForm && {
            key: 'patient_list_action_menu',
            testid: 'patient_list_action_menu-clinical_settings',
            label: t('View clinical settings'),
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.VIEW_PATIENT_FORM,
            },
            onClick: () =>
              history.push(
                `/patient-list/${patientForm.patient.uid}/clinical-settings`
              ),
          },
          hasMonitoringForm && {
            key: 'patient_list_action_menu',
            testid: 'patient_list_action_menu',
            label: t('View submissions'),
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.LIST_PATIENT_SUBMISSIONS,
            },
            onClick: () =>
              history.push(
                `/patient-list/${patientForm.patient.uid}/submissions`
              ),
          },
          hasMonitoringForm && {
            key: 'patient_list_action_menu_view_graphs',
            testid: 'patient_list_action_menu_view_graphs',
            label: t('View graphs'),
            visible: onShowPatientForm,
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.VIEW_GRAPHS,
            },
            onClick: () => onShowPatientForm(patientForm, 'graphs'),
          },
          {
            key: 'patient_list_action_menu_view_messages',
            testid: 'patient_list_action_menu_view_messages',
            label: t('Messages logs'),
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.LIST_PATIENT_SUBMISSIONS,
            },
            onClick: () =>
              history.push(`/patient-list/${patientForm.patient.uid}/messages`),
          },

          {
            key: 'patient_list_action_menu_reactivate',
            testid: 'patient_list_action_menu_reactivate',
            label: t('Re-activate patient'),
            visible: onShowPatientForm,
            logEventProps: {
              page: EventPage.PatientList,
              subSource: EventSubSource.Discharge,
              eventName: EVENTS.REACTIVATE,
            },
            divider: true,
            onClick: () => onReactivatePatient(patientForm),
          },
        ].filter(Boolean);
      },
      [
        onAddForm,
        onDischargeForm,
        history,
        onDeactivatePatientForm,
        onEditPatientForm,
        onReactivatePatient,
        onRefresh,
        onShowPatientForm,
        showAction,
        t,
        isMobile,
        clinician?.isCaregiver,
        onSendMessage,
      ]
    );

    const onBulkDischarge = useCallback(
      async (rows) => {
        try {
          const patientUids = rows
            .map((row) => row.id)
            .filter(
              (id) => !removedSelection.find((rm) => rm.original.uid === id)
            );

          const input = {
            operation: 'DISCHARGE',
            patientUids,
          };

          if (
            selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS
          ) {
            delete input.patientUids;
          }

          if (selectedForm) {
            input.monitoringFormUid = selectedForm.id;
          }

          const filters = getAppliedFilters();

          if (filters) {
            input.patientFilter = filters;
          }

          dischargeOrEnrollPatients(input, 'DISCHARGE').then(() => {
            patientFormTableRef.current.resetAllPagesSelectedRows();
            setEnableRowSelect(false);
            closeModal();
            setRequesting(false);
          });
        } catch (error) {
          // console.error('error', error);
        }
      },
      [
        dischargeOrEnrollPatients,
        selectionOptionChosen,
        selectedForm,
        getAppliedFilters,
        setEnableRowSelect,
        closeModal,
        removedSelection,
      ]
    );

    const onBulkDeactivate = useCallback(
      async (rows) => {
        const patientUids = rows
          .map((row) => row.id)
          .filter(
            (id) => !removedSelection.find((rm) => rm.original.uid === id)
          );

        const input = {
          patientUids,
          deactivatePatient: true,
          operation: 'DISCHARGE',
        };

        if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
          delete input.patientUids;
        }

        const filters = getAppliedFilters();

        if (filters) {
          input.patientFilter = filters;
        }

        dischargeOrEnrollPatients(input, 'DISCHARGE').then(() => {
          patientFormTableRef.current.resetAllPagesSelectedRows();
          setEnableRowSelect(false);
          closeModal();
          setRequesting(false);
        });
      },
      [
        dischargeOrEnrollPatients,
        selectionOptionChosen,
        getAppliedFilters,
        removedSelection,
        setEnableRowSelect,
        closeModal,
      ]
    );

    const onCloseBulkDischarge = useCallback(
      (reset = false) => {
        closeModal(reset);
        setShowDischargeModal({
          visible: false,
          rows: [],
        });

        setSelectedForm(programDischargeDefaultSelection);
      },
      [closeModal, programDischargeDefaultSelection]
    );

    const confirmBulkDischarge = (rows) => {
      setShowDischargeModal({
        visible: true,
        rows,
      });
    };

    const confirmBulkDeactivate = (rows) =>
      openModal(
        <Dialog open>
          <DialogTitle
            label={t('Are you sure?')}
            onClick={() => {
              closeModal(false);
            }}
          />

          <Text fontWeight={400} fontSize={2} mb={3}>
            {t(
              'All selected patients will be deactivated and also removed from all programs'
            )}
          </Text>

          <Text mb={4}>{t('You can re-activate them at anytime.')}</Text>
          <Flex justifyContent="end">
            <SecondaryOutlinedButton
              mr={2}
              onClick={() => {
                closeModal(false);
              }}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <DangerButton
              onClick={() => {
                setRequesting(true);
                closeModal();
                onBulkDeactivate(rows);
              }}
            >
              {t('Yes, deactivate')}
            </DangerButton>
          </Flex>
        </Dialog>,
        {}
      );

    /* const selectVisibleEnabled = useMemo(() => {
      if (
        isUndefined(selectionOptionChosen) ||
        selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.VISIBLE_ROWS
      ) {
        return true;
      }
      return false;
    }, [selectionOptionChosen]); */

    const customSelectEnabled = useMemo(() => {
      if (
        isUndefined(selectionOptionChosen) ||
        selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.CUSTOM
      ) {
        return true;
      }
      return false;
    }, [selectionOptionChosen]);

    const selectAllEnabled = useMemo(() => {
      /* TODO: Disabled select all button until async is done on the server */
      if (searchText) return false;

      return true;
    }, [searchText]);

    const searchInputDisabled = useMemo(() => {
      if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
        return true;
      }
      return false;
    }, [selectionOptionChosen]);

    const onCancel = useCallback(() => {
      setSelectionOptionChosen(undefined);
      onCancelCallback();
    }, [onCancelCallback]);

    const onFilterChange = useCallback(
      (filters) => {
        onApplyFilters(filters);
        setSelectionOptionChosen(undefined);
        setEnableRowSelect(false);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setEnableRowSelect]
    );

    const actions = [
      // Hidden upon request for now
      // {
      //   id: 'selectVisible',
      //   label: t('Select visible rows'),
      //   onClick: () => {
      //     if (checkMaxSelectionThreshold(patients.length)) {
      //       showMaxSelectionThresholdMessage();
      //       return;
      //     }
      //     setEnableRowSelect(true);
      //     setShowLoadMoreButton(true);
      //     patientFormTableRef.current.resetAllPagesSelectedRows();
      //     setSelectionOptionChosen(PATIENT_LIST_SELECTION_OPTION.VISIBLE_ROWS);
      //     setTimeout(() => {
      //       patientFormTableRef.current.selectAllVisibleRows(patients.length);
      //     }, 200);
      //   },
      //   enable: selectVisibleEnabled,
      // },
      {
        id: 'selectAll',
        label: t('Select all rows'),
        onClick: () => {
          const selectedPatientsCount =
            filtersRef.current?.getFiltersCount() > 0
              ? totalFetchDataCount
              : totalCount;
          setEnableRowSelect(true);
          setShowLoadMoreButton(false);
          patientFormTableRef.current.resetAllPagesSelectedRows();
          setSelectionOptionChosen(PATIENT_LIST_SELECTION_OPTION.ALL_ROWS);
          setTimeout(
            () =>
              patientFormTableRef.current.selectAllPagesRows(
                selectedPatientsCount
              ),
            200
          );
        },
        enable: selectAllEnabled,
      },
      {
        id: 'customSelections',
        divider: 'true',
        label: t('Custom selection'),
        onClick: () => {
          setSelectionOptionChosen(PATIENT_LIST_SELECTION_OPTION.CUSTOM);
          setEnableRowSelect(true);
          setShowLoadMoreButton(true);
          patientFormTableRef.current.resetAllPagesSelectedRows();
        },
        enable: customSelectEnabled,
      },
    ];

    if (statusCode && RetryStatusCodes.includes(statusCode)) {
      return (
        <RetryView
          onRefresh={onRefresh}
          isLoading={isLoading || isRefetching}
        />
      );
    }

    return (
      <>
        <Dialog
          open={showDischargeModal.visible}
          onClose={() => onCloseBulkDischarge(false)}
        >
          <Box p={2}>
            <DialogTitle
              label={t('Are you sure?')}
              onClick={() => onCloseBulkDischarge(false)}
            />

            <Text fontWeight={400} fontSize={2} mb={3}>
              {t(
                'Are you sure you want to remove these users from the program?'
              )}
            </Text>

            <Text>{t('You can re-enroll them anytime.')}</Text>
          </Box>

          {/* Show user monitoring options if they filtered by some */}
          {!isLoadingMonitoringForms && (
            <Box p={2} mb={4}>
              <H6 color="darkestShade" fontSize="14px" mb={2}>
                {t('Select the program you want to remove them from')}
              </H6>
              <Select
                defaultValue={programDischargeDefaultSelection}
                value={selectedForm}
                options={mfOptions}
                onChange={(form) => {
                  setSelectedForm(form.value);
                }}
                placeholder="Select a program"
                maxMenuHeight={180}
                menuPlacement="top"
              />
            </Box>
          )}

          <Flex justifyContent="end">
            <SecondaryOutlinedButton
              mr={2}
              onClick={() => onCloseBulkDischarge(true)}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <DangerButton
              onClick={() => {
                setRequesting(true);
                onCloseBulkDischarge(true);
                onBulkDischarge(showDischargeModal.rows);
              }}
            >
              {t('Yes, remove')}
            </DangerButton>
          </Flex>
        </Dialog>

        <Dialog
          containerProps={{
            style: {
              overflow: 'visible',
            },
          }}
          open={showCreateGroupModal}
          onClose={closeGroupModal}
        >
          <Box>
            <Flex mb={4} justifyContent="space-between" alignItems="center">
              <H2>{t('Create group')}</H2>
              <Box cursor="pointer" onClick={closeGroupModal}>
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>

            <GroupInfoInputs
              width="100%"
              containerStyle={{ marginLeft: '2px' }}
              control={control}
              groupDescription={groupDescription}
              groupName={groupName}
              setGroupDescription={setGroupDescription}
              setGroupName={setGroupName}
            />

            <Flex
              mt={6}
              flexDirection={['column', 'row']}
              alignItems="center"
              justifyContent={['', 'right']}
            >
              <SecondaryOutlinedButton
                mr={[0, 3]}
                mb={[2, 0]}
                p="0px 24px"
                width={['100%', 150]}
                onClick={closeGroupModal}
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                disabled={requesting || !groupName}
                onClick={onSaveGroup}
                width={['100%', 150]}
              >
                {t('Save')}
              </PrimaryButton>
            </Flex>
          </Box>
        </Dialog>

        <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
          <SearchInput
            inputStyle={{
              paddingBottom: '6px',
              paddingTop: '8px',
            }}
            t={t}
            disabled={searchInputDisabled}
            hideRefresh={isMobile}
            placeholder={t('Search patients by name or mobile')}
            inputMinWidth={isMobile ? '100%' : '400px'}
            onSearch={onSearch}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onRefresh={onRefresh}
            isRefreshing={isRefetching}
            lastUpdated={lastUpdated.current}
            totalCount={totalFetchDataCount}
            pageCount={patients?.length || pageCount}
            searchInfoTextContainerStyle={{
              fontWeight: 'bold',
              color: 'red',
            }}
            refreshButtonProps={{
              style: {
                paddingTop: '12px',
                paddingBottom: '12px',
              },
            }}
          />
          {showFilterBtn && (
            <Flex>
              {patients.length > 0 && !isMobile && !isViewMode && (
                <DropdownMenu
                  fontWeight="500"
                  marginLeft={-105}
                  label={
                    <SecondaryOutlinedButton
                      mr={2}
                      onClick={() => {}}
                      startIcon={
                        <FAIcon
                          icon={faCog}
                          fontSize={14}
                          color="darkestShade"
                        />
                      }
                      borderRadius="8px"
                    >
                      <Body
                        fontSize="14px"
                        fontFamily="Inter,sans-serif"
                        fontWeight="500"
                      >
                        {t('Actions')}
                      </Body>
                    </SecondaryOutlinedButton>
                  }
                  actions={actions}
                  width="200px"
                />
              )}
            </Flex>
          )}
        </Flex>

        <Box ml={-1} mt={-2} mb={2} display={['none', 'none', 'block']}>
          <Filters
            ref={filtersRef}
            requestParams={requestParams}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        </Box>

        <Box pb={10}>
          <PatientFormTable
            ref={patientFormTableRef}
            deactivated={deactivated}
            clinic={clinic}
            data={patients}
            showLoading={isLoading || requesting}
            onOpenDrawer={openDrawer}
            onFetchData={onFetchData}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetched={isFetched}
            isFetchingNextPage={isFetchingNextPage}
            onShowPatientForm={onShowPatientForm}
            initialSortBy={{
              orderField: requestParams?.orderField,
              orderDesc: requestParams?.orderDesc,
            }}
            onCreateGroup={onCreateGroup}
            onSendMessage={(patient, selectedPatients) =>
              onSendMessage(patient, selectedPatients)
            }
            onAddFormBulk={onAddFormBulk}
            onBulkDischarge={confirmBulkDischarge}
            onBulkDeactivate={confirmBulkDeactivate}
            onBulkActivate={onReactivatePatients}
            getActionMenu={getActionMenu}
            selectionOptionChosen={selectionOptionChosen}
            onRowSelectionCallback={onRowSelectionCallback}
            searchText={searchText}
            onCancelCallback={onCancel}
            {...{
              enableRowSelect,
              setEnableRowSelect,
              autoResetSelectedRows,
              setAutoResetSelectedRows,
              totalCount,
              removedSelection,
              setRemovedSelection,
              patientGroupSelection,
              onSavePatientsGroup,
              showLoadMoreButton,
              setShowLoadMoreButton,
              onResetFilters,
              onSearch,
              filtersRef,
            }}
          />
        </Box>
      </>
    );
  }
);

export default React.memo(AllPatientsSection);
