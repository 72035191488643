import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientEventUpdate($input: CleoPatientEventUpdateInput!) {
    cleoPatientEventUpdate(input: $input) {
      results {
        status
        isConfirmed
        confirmedOn
        isPatientShowed
        patientShowedOn
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientEventUpdate',
});
