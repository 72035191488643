import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { flatMap } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query getCareGivers(
    $roleUid: String
    $clinicianNameContains: String
    $orderField: ClinicianOrderBy
    $orderDesc: Boolean
    $activated: Boolean
  ) {
    cleoClinicians(
      role_Uid: $roleUid
      name_Icontains: $clinicianNameContains
      sortBy: $orderField
      sortDesc: $orderDesc
      deactivatedOn_Isnull: $activated
    ) {
      edges {
        node {
          uid
          name
          phone
          email
          communicationMethod
          isActivated
          deactivatedOn
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'caregivers',
  keepPreviousData: true,
  select: ({ cleoClinicians }) => flatMap(cleoClinicians?.edges, (n) => n.node),
});
