import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HEIMDALL_JWT = gql`
  mutation heimdallAuthorizationFlowJWT(
    $input: HeimdallAuthorizationFlowInput!
  ) {
    heimdallAuthorizationFlow(input: $input) {
      session {
        uid
        scopes
        expiresOn
        user {
          uid
        }
      }
      redirectTo
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HEIMDALL_JWT,
  queryType: 'mutation',
  baseQueryKey: 'heimdallAuthorizationFlowJWT',
});
