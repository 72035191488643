import React from 'react';
import { Body } from '@fivehealth/botero';
import { withTranslation } from 'react-i18next';

export default withTranslation()(({ t, text, color = 'success' }) => (
  <Body
    small
    medium
    as="span"
    bg={color === 'success' ? '#DCF7E8' : 'lightestShade'}
    color={color}
    p={1}
    py="4px"
    borderRadius={6}
    fontSize={14}
  >
    {t(text)}
  </Body>
));
