// import liraries
import { Box, H4, Body, Input } from '@fivehealth/botero';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// create a component
const GroupInfoInputs = ({
  control,
  setGroupName,
  groupName,
  groupDescription,
  setGroupDescription,
  width,
  containerStyle = {},
}) => {
  const { t } = useTranslation();

  return (
    <Box width={width} style={containerStyle}>
      <Box mb={4}>
        <H4 color="fullShade">{t('Group name')}</H4>
        <Body medium color="darkestShade">
          {t(
            'Create a descriptive name that will be used for internal reference '
          )}
        </Body>
        <Controller
          control={control}
          name="Group name"
          render={({ field: { onChange } }) => (
            <Input
              name="name"
              label={t('Group name')}
              fontSize={12}
              fontWeight={500}
              value={groupName}
              inputProps={{
                maxLength: 50,
              }}
              placeholder={t('Enter group name')}
              onChange={({ target }) => {
                setGroupName(target.value);
                onChange(target.value);
              }}
            />
          )}
        />
      </Box>

      <Box>
        <H4 color="fullShade">{t('Group description')}</H4>
        <Controller
          control={control}
          name="Group Description"
          render={({ field: { onChange } }) => (
            <Input
              as="textarea"
              name="description"
              label={t('Group description')}
              fontSize={12}
              fontWeight={500}
              inputProps={{
                maxLength: 100,
              }}
              value={groupDescription}
              labelRight={`(${t('Optional')})`}
              placeholder={t('Enter group description')}
              onChange={({ target }) => {
                setGroupDescription(target.value);
                onChange(target.value);
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

// make this component available to the app
export default GroupInfoInputs;
