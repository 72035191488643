/* eslint import/prefer-default-export: 0 */
import { get } from 'lodash';

/* Ensures unique graph doc ids across an array of pages
 */
export const buildPaginatedResponse = (
  data,
  documentName,
  mapNode,
  params = {}
) => {
  const uniqueIds = [];
  const res = {
    ...data,
    statusCode: get(data, `pages[0].statusCode`),
    totalCount: get(data, `pages[0].${documentName}.totalCount`),
    pageInfo: get(data, `pages[0].${documentName}.pageInfo`, {}),
    pages: get(data, 'pages', []).map((page) => {
      const graphResponse = page?.[documentName];
      if (!graphResponse || !Array.isArray(graphResponse.edges)) {
        return [];
      }
      const { edges } = graphResponse;
      return edges
        .filter(({ node } = {}) => {
          if (!node || !node.uid || uniqueIds.includes(node.uid)) {
            return false;
          }
          uniqueIds.push(node.uid);
          return true;
        })
        .map(({ node }) => (mapNode ? mapNode(node, params) : node));
    }),
  };
  return res;
};
