import { useApi } from '@fivehealth/botero';
import { useEffect, useMemo, useState } from 'react';

const DEFAULT_SORT = {
  orderField: 'NAME',
  orderDesc: false,
};
const useCaregiverUsers = ({ variables = {} }) => {
  const [caregivers, setCaregivers] = useState([]);

  const {
    queries: { useCaregiverRole, useCaregivers },
  } = useApi({
    queries: ['useCaregiverRole', 'useCaregivers'],
  });
  const { data: caregiverRoleUid } = useCaregiverRole();

  const {
    data,
    isLoading: isLoadingCaregivers,
    refetch: refetchCaregivers,
    isRefetching,
  } = useCaregivers(
    !caregiverRoleUid
      ? {}
      : {
          enabled: !!caregiverRoleUid,
          variables: {
            roleUid: caregiverRoleUid,
            ...DEFAULT_SORT,
            ...variables,
          },
        },
    [caregiverRoleUid]
  );

  useEffect(() => {
    setCaregivers(data);
  }, [data]);

  const caregiversSelectOpts = useMemo(
    () =>
      !caregivers
        ? []
        : caregivers
            .filter((cg) => cg.isActivated)
            .map(({ name, uid }) => ({
              label: name,
              value: uid,
            })),
    [caregivers]
  );

  return {
    caregiverRoleUid,
    isLoadingCaregivers,
    caregivers,
    caregiversSelectOpts,
    refetchCaregivers,
    isRefetching,
  };
};

export default useCaregiverUsers;
