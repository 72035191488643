import React, { useState } from 'react';
import { chain, toUpper, uniqBy } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, withTranslation } from 'react-i18next';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import {
  Body,
  Box,
  FAIcon,
  Flex,
  Text,
  theme,
  H4,
  H5,
} from '@fivehealth/botero';

import EVENTS from 'constants/events';
import { EventPage, EventSubSource } from 'constants';
import { ModalView } from 'components/ModalView/ModalView';
import ActionDropdown from 'components/ActionDropdown/ActionDropdown';
import TableLoader from 'components/Table/TableLoader';
import MobileTable from 'components/Table/MobileTable';
import { ProfileCell } from 'components/Table/TableCell';
import Table, { BoxContent } from 'components/Table/Table';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { useHistory } from 'react-router-dom';

import { Button } from '@chakra-ui/react';
import BotCryingAvatar from '../../assets/crying-avatar.svg';

const statuses = [
  {
    id: 'active',
    label: 'Active',
    toParams: {
      activated: true,
    },
  },
  {
    id: 'deactivated',
    label: 'Deactivated',
    toParams: {
      activated: false,
    },
  },
];

export const buildFilters = (filters, translate, clinicData) => {
  const defaultTranslateFn = (text) => text;
  const t = translate || defaultTranslateFn;
  const mappedStatuses = statuses.map((status) => ({
    ...status,
    label: t(status.label),
  }));

  return [
    {
      id: 'clinical_designation',
      title: t('Clinical Designation'),
      data: uniqBy([...filters], (e) => e.id),
      singleSelect: true,
    },
    {
      id: 'communicationMethod',
      title: t('Alerted via'),
      data: chain(clinicData)
        .get('communicationMethods')
        .map((method) => ({
          ...method,
          id: method.value,
          label: t(method.label),
          toParams: {
            communicationMethod: toUpper(method.value),
          },
        }))
        .value(),
    },
    { id: 'status', title: 'Status', data: mappedStatuses },
  ];
};

const DesignationCell = withTranslation()(({ value: designation, t }) => (
  <BoxContent py={2}>
    <Body small>{t(designation)}</Body>
  </BoxContent>
));

export const RoleCell = withTranslation()(({ value: role, t }) => (
  <BoxContent py={2}>
    <Body small>{t(role)}</Body>
  </BoxContent>
));

const AlertTypeCell = ({ value: alertType, clinicData }) => (
  <BoxContent py={2}>
    <Body small>
      {chain(clinicData)
        .get('communicationMethods')
        .find({ gql: alertType })
        .get('label')
        .value()}
    </Body>
  </BoxContent>
);

const StatusCell = ({ value: deactivatedOn }) => {
  const status = deactivatedOn ? 'Deactivated' : 'Active';
  return (
    <BoxContent width="fit-content !important">
      <StatusBadge
        text={status}
        color={deactivatedOn ? 'darkestShade' : 'success'}
      />
    </BoxContent>
  );
};

const ActionsCell = ({
  cell,
  currentUser,
  hasPermission,
  isMobile,
  t,
  onDischargeUser,
  onEditUser,
  onEnrollUser,
  hasInsufficientLicenses,
  setModalOpen,
}) => {
  const isCurrentUser = currentUser?.uid === cell.row.original?.uid;
  const isActive = !cell.row.original.deactivatedOn;
  // passed into ActionDropdown
  const getDropdownOptions = () => {
    if (!hasPermission && !isCurrentUser) {
      return [
        {
          key: 'clinician_admin_action_menu_no_action',
          testid: 'clinician_admin_action_menu_no_action',
          label: t('No Action'),
          logEventProps: {
            page: EventPage.ClinicalAdmin,
            subSource: EventSubSource.ClinicalAdmin,
            eventName: EVENTS.NO_ACTION,
          },
          labelProps: {
            color: 'darkShade',
          },
        },
      ];
    }

    return [
      {
        key: 'clinician_admin_action_menu_edit_profile',
        testid: 'clinician_admin_action_menu_edit_profile',
        label: t('Edit profile'),
        visible: onEditUser,
        logEventProps: {
          page: EventPage.ClinicalAdmin,
          subSource: EventSubSource.ClinicalAdmin,
          eventName: EVENTS.EDIT_CLINICAL_ADMIN_PROFILE,
        },
        onClick: () => onEditUser(cell.row.original),
      },
      {
        key: 'clinician_admin_action_menu_deactivate_profile',
        testid: 'clinician_admin_action_menu_deactivate_profile',
        label: t('Deactivate user'),
        visible: isActive && hasPermission && !isCurrentUser,
        logEventProps: {
          page: EventPage.ClinicalAdmin,
          subSource: EventSubSource.ClinicalAdmin,
          eventName: EVENTS.DEACTIVATE_CLINICAL_ADMIN_PROFILE,
        },
        minHeight: 55,
        divider: true,
        labelProps: {
          color: 'danger',
        },
        onClick: () => onDischargeUser(cell.row.original),
      },
      {
        key: 'clinician_admin_action_menu_reactivate_profile',
        testid: 'clinician_admin_action_menu_reactivate_profile',
        label: t('Reactivate Profile'),
        visible: !isActive && hasPermission && !isCurrentUser,
        logEventProps: {
          page: EventPage.ClinicalAdmin,
          subSource: EventSubSource.ClinicalAdmin,
          eventName: EVENTS.REACTIVAE_CLINICAL_ADMIN_PROFILE,
        },
        minHeight: 55,
        divider: true,
        labelProps: {
          color: 'fullShade',
        },
        onClick: () => {
          if (hasInsufficientLicenses) {
            setModalOpen(true);
          } else {
            onEnrollUser(cell.row.original);
          }
        },
      },
    ];
  };

  return (
    <Flex
      justifyContent="right"
      alignItems="center"
      height={[56, 56, null]}
      pl={[2, 2, null]}
      pr={[2, 2, null]}
      style={
        isMobile
          ? {
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottomWidth: 0,
              borderTopWidth: 1,
              borderColor: theme.colors.mediumShade,
              borderStyle: 'solid',
            }
          : {}
      }
    >
      <ActionDropdown
        testid="clinical_admin_action_menu"
        label={
          isMobile && (
            <Flex>
              <Box>
                <FAIcon icon={faEllipsisV} hover={{ opacity: 0.6 }} />
              </Box>
              <Text ml={2}>{t('More')}</Text>
            </Flex>
          )
        }
        dropdownOptions={getDropdownOptions()}
      />
    </Flex>
  );
};

const ClinicalUsers = ({
  currentUser,
  hasPermission,
  clinicData,
  data,
  hasNextPage,
  isFetchingNextPage,
  onFetchNextPage,
  onFetchData,
  onEditUser,
  onDischargeUser,
  onEnrollUser,
  onOpenDrawer,
  showLoading,
  isFetched,
  hasInsufficientLicenses,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' });
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  // console.log("ClinicalAdminTable", hasInsufficientLicenses);
  const columns = [
    {
      id: 'NAME',
      accessor: 'name',
      Header: t('Clinical user'),
      Cell: ProfileCell,
    },
    {
      id: 'EMAIL',
      disableSortBy: true,
      accessor: 'email',
      Header: t('Email'),
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ value }, index) => <RoleCell key={index} value={value || '-'} />,
    },
    {
      id: 'DESIGNATION',
      accessor: 'designation',
      Header: t('Clinical Designation'),
      Cell: DesignationCell,
    },
    {
      id: 'COMMUNICATION_METHOD',
      accessor: 'communicationMethod',
      Header: t('Alerted via'),
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ value }, index) => (
        <AlertTypeCell key={index} value={value} clinicData={clinicData} />
      ),
    },
    {
      accessor: 'role',
      Header: t('Account Type'),
      disableSortBy: true,
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ value }, index) => (
        <RoleCell key={index} value={value?.name || '-'} />
      ),
    },
    {
      id: 'STATUS',
      accessor: 'deactivatedOn',
      Header: t('Status'),
      Cell: StatusCell,
    },
    {
      id: 'actions',
      Header: '',
      Cell: ActionsCell,
      disableSortBy: true,
      width: isMobile ? '150px' : '40px',
    },
  ];

  const props = {
    columns,
    data,
    hasNextPage,
    hasPermission,
    initialSortBy: {
      id: 'NAME',
      desc: false,
    },
    isFetchingNextPage,
    isMobile,
    t,
    onFetchNextPage,
    onFetchData,
    onOpenDrawer,
    onEditUser,
    onDischargeUser,
    onEnrollUser,
    isFetched,
    hasInsufficientLicenses,
    setModalOpen,
  };

  if (showLoading) {
    return <TableLoader />;
  }

  return (
    <Box overflow="scroll" className="scrollbar-invisible">
      <Table
        currentUser={currentUser}
        renderTable={isMobile ? MobileTable : null}
        {...props}
      />
      {isModalOpen && (
        <ModalView
          body={
            <Flex
              gap="24px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              margin="8px"
            >
              <Flex mb="2">
                <Box
                  as="img"
                  src={BotCryingAvatar}
                  height={160}
                  width={160}
                  alt="Bot Avatar"
                />
              </Flex>
              <Box width="576px" height="80px" gap="4px">
                <H4
                  textAlign="center"
                  color="#111824"
                  fontWeight="600"
                  fontSize="16px"
                >
                  User cannot be reactivated - no licenses left!
                </H4>
                <Body
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="24px"
                  letterSpacing="0px"
                  fontFamily="Inter"
                  p={1}
                >
                  <Text color="#697481">View details in Billing Module.</Text>
                  <Text color="#697481">
                    Please contact{' '}
                    <a
                      href="mailto:sales@botmd.io"
                      style={{ color: '#256BF6' }}
                    >
                      sales@botmd.io
                    </a>{' '}
                    to purchase more licenses.
                  </Text>
                </Body>
              </Box>
            </Flex>
          }
          footer={
            <Flex>
              <Button
                backgroundColor="#FFFFFF"
                variant="outline"
                borderColor="#697481"
                borderRadius="8px"
                border="1px"
                marginRight="16px"
                onClick={() => setModalOpen(false)}
              >
                <H5 color="#697481">Back to Clinical Admin</H5>
              </Button>
              <Button
                backgroundColor="#256BF6"
                borderRadius="8px"
                marginRight="16px"
                onClick={() => history.push('/billing')}
              >
                <H5 color="#FFFFFF" fontWeight="600">
                  View Billing Module
                </H5>
              </Button>
            </Flex>
          }
          closeOnOverlayClick={false}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default ClinicalUsers;
