import React from 'react';

import { Flex, H6, Select } from '@fivehealth/botero';

export const Label = (props) => (
  <H6 pb="16px" fontSize="16px" color="FullShade" {...props} />
);

const SelectWithLabel = ({
  label,
  children,
  elementName,
  isDisabled,
  ...props
}) => (
  <Flex
    flexDirection="column"
    pt="40px"
    flex="0 0 50%"
    mt={[1, 0]}
    data-testid="export_data_modal_select_with_lbl_flex"
  >
    <Label
      data-testid="export_data_modal_select_with_lbl_txt"
      id="export_data_modal_select_with_lbl_txt_id"
      style={{ fontSize: 12, color: 'gray' }}
      my={0}
      py="4px"
    >
      {label}
    </Label>
    <Select
      isFocused
      data-testid="export_data_modal_select_with_lbl_select"
      id={`export_data_modal_select_with_lbl_select-${elementName}`}
      isDisabled={isDisabled}
      {...props}
    />
  </Flex>
);

export default React.memo(SelectWithLabel);
