import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const MESSAGE_TEMPLATE_SEND_GRAPHQL_DOCUMENT = gql`
  mutation cleoMessageTemplateDeliverToPatientAsync(
    $input: CleoMessageTemplateDeliverToPatientAsyncInput!
  ) {
    cleoMessageTemplateDeliverToPatientAsync(input: $input) {
      asyncJob {
        uid
        status
        isReady
      }
    }
  }
`;

export default createQuery({
  gqlDocument: MESSAGE_TEMPLATE_SEND_GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientMessageCreateAsyncStatus',
  keepPreviousData: false,
});
