import { Box, Text, Flex } from '@fivehealth/botero';
import { getChartLegendMapping } from 'AppUtils';
import TableCellItem from 'components/TableCellItem';
import TableCellWrapper from 'components/TableCellWrapper';
import { format } from 'date-fns';
import { camelCase, get } from 'lodash';

const TableCellLabel = ({ text, icon }) => (
  <Flex>
    {icon}
    <Box ml={1}>
      <Text fontSize={14} fontWeight={600} color="darkestShade">
        {text}
      </Text>
    </Box>
  </Flex>
);

const BGTableCell = ({ period: periodLabel, cell, chartSetting, t }) => {
  const original = get(cell, 'row.original', {});
  const {
    value: { value },
    timestamp,
  } = original;
  const submissionHour = parseInt(format(timestamp, 'H'), 10);
  let showValue = false;
  const key = camelCase(original.timeTaken);
  const period = camelCase(periodLabel);

  // 00:00 to 10:59
  if (/beforeMeal/i.test(period) || /morning/i.test(period)) {
    showValue = submissionHour >= 0 && submissionHour < 11;
  }

  // 11:00 to 15:59
  if (/2HoursAfterMeal/i.test(period) || /afternoon/i.test(period)) {
    showValue = submissionHour >= 11 && submissionHour < 16;
  }

  // 16:00 to 23:59
  if (/beforeBedtime/i.test(period) || /evening/i.test(period)) {
    showValue = submissionHour >= 16 && submissionHour < 24;
  }

  if (!showValue) {
    return null;
  }
  const chartLegendMapping = getChartLegendMapping(chartSetting, t);
  const IconComponent = get(chartLegendMapping, `${key}`).symbolComponent;
  const IconColor = get(chartLegendMapping, `${key}`).color;

  return (
    <TableCellWrapper>
      <Box width={30} pl={2}>
        <Text fontSize={14} fontWeight={600} color="fullShade">
          {value}
        </Text>
      </Box>
      <TableCellItem flex={1}>
        <TableCellLabel
          text={original.timeTaken}
          icon={IconComponent ? <IconComponent color={IconColor} /> : null}
        />
      </TableCellItem>
    </TableCellWrapper>
  );
};
export default BGTableCell;
