// import liraries
import { tweakPageName } from 'AppUtils';
import EVENTS from 'constants/events';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  H1,
  PrimaryButton,
  H5,
  useApi,
  FAIcon,
} from '@fivehealth/botero';
import { useForm } from 'react-hook-form';
import PatientList from 'views/PatientsList/PatientsList';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useModal } from 'context/ModalContext';
import { useMediaQuery } from 'react-responsive';
import GroupInfoInputs from './GroupInfoInputs';
import CreateGroupSuccessModal from './GroupCreationSuccessModal';

const CreateGroup = ({ toggleView, onCancel }) => {
  const [step, setStep] = useState(1);

  const { t } = useTranslation();

  const {
    queries: { usePatientGroupsCreate },
  } = useApi({
    queries: ['usePatientGroupsCreate'],
  });

  const { mutateAsync: createPatientGroup } = usePatientGroupsCreate({
    variables: {},
  });

  const { control } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const [groupName, setGroupName] = useState();
  const [groupDescription, setGroupDescription] = useState();
  // const [savedEnabled, setSavedEnabled] = useState(false);
  const { openModal, closeModal } = useModal();
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' });

  const patientListRef = useRef();

  const onNextStep = (index) => {
    setStep(index);
  };

  const step1 = useMemo(
    () => (
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Box>
            <H1>Step 1: {t('Name your group')}</H1>
          </Box>
          <Box>
            <PrimaryButton
              logEventProps={{
                page: tweakPageName(window.location.pathname.split('/')[1]),
                subSource: tweakPageName(
                  window.location.pathname.split('/')[1]
                ),
                eventName: EVENTS.CREATE_GROUP,
              }}
              ml={2}
              disabled={!groupName}
              onClick={() => {
                onNextStep(step + 1);
              }}
            >
              {t('Next: Add patients')}
            </PrimaryButton>
          </Box>
        </Flex>
        <GroupInfoInputs
          width={['100%', '50%']}
          control={control}
          groupDescription={groupDescription}
          groupName={groupName}
          setGroupDescription={setGroupDescription}
          setGroupName={setGroupName}
        />
      </Box>
    ),
    [
      step,
      control,
      groupName,
      groupDescription,
      setGroupDescription,
      setGroupName,
      t,
    ]
  );

  // const onRowSelectionCallback = useCallback(async (list) => {
  //   setSavedEnabled(list.length > 0);
  // }, []);

  const onSaveGroup = useCallback(async () => {
    const {
      patients: selectedPatiens,
      patientFilter,
      count,
    } = await patientListRef.current.getSelectedPatients().then((r) => r);

    const addPatients = selectedPatiens.map((p) => p.id);

    const input = {
      name: groupName,
      description: groupDescription,
      addPatients,
    };

    if (patientFilter) {
      input.patientFilter = patientFilter;
    }

    await createPatientGroup({
      input,
    });
    openModal(
      <CreateGroupSuccessModal
        membersCount={count}
        closeModal={closeModal}
        groupName={groupName}
      />,
      {
        style: {
          width: isMobile ? '100%' : '50%',
          maxWidth: '720px',
          overflowY: 'visible',
        },
      }
    );
    toggleView();
  }, [
    toggleView,
    createPatientGroup,
    groupDescription,
    groupName,
    openModal,
    isMobile,
    closeModal,
  ]);

  const step2 = useMemo(
    () => (
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Box>
            <H1>Step 2: {t('Add patients')}</H1>
          </Box>
          <Flex />
        </Flex>

        <PatientList
          ref={patientListRef}
          patientGroupSelection
          onSavePatientsGroup={onSaveGroup}
          onCancelCallback={onCancel}
          // onRowSelectionCallback={onRowSelectionCallback}
        />
      </Box>
    ),
    [t, onSaveGroup, onCancel]
  );

  const onBackPress = () => {
    if (step === 1) {
      toggleView();
    }

    if (step === 2) {
      setStep(1);
    }
  };

  return (
    <Box>
      <Flex cursor="pointer" onClick={onBackPress} alignItems="center" pb={2}>
        <Box cursor="pointer" mr="5px" fontSize={12}>
          <FAIcon fontSize={12} icon={faChevronLeft} />
        </Box>
        <H5 color="darkestShade">{t('Back')}</H5>
      </Flex>
      {step === 1 && step1}
      {step === 2 && step2}
    </Box>
  );
};

export default CreateGroup;
