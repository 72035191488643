// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const ChevronDown = memo<IconProps>(({ color, width = 15, height = 5 }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.24805 0H8.27539C8.76758 0 9.01367 0.601562 8.6582 0.957031L5.1582 4.45703C4.93945 4.67578 4.58398 4.67578 4.36523 4.45703L0.865234 0.957031C0.509766 0.601562 0.755859 0 1.24805 0Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default ChevronDown;
