import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Flex, H5, Body, Badge, Text } from '@fivehealth/botero';

const AvatarPlaceholder = React.memo(({ name, color }) => {
  const fullName = name ? name?.split(' ') : null;
  const initials = fullName
    ? (fullName.shift() || '').charAt(0) + (fullName.pop() || '').charAt(0)
    : '';
  const avatarColors = ['#256BF6', '#27AE60', '#F3AD3D', '#E05138'];
  const randomColor =
    avatarColors[Math.floor(Math.random() * avatarColors.length)];

  return (
    <Flex
      mr={1}
      bg={color || randomColor}
      borderRadius="50%"
      width={40}
      height={40}
      justifyContent="center"
      alignItems="center"
    >
      <H5 color="white">{initials?.toUpperCase()}</H5>
    </Flex>
  );
});

const UserInfo = withTranslation()(
  ({
    resolved,
    name,
    designation,
    postedOn,
    avatar,
    avatarColor,
    comment,
    visibleTo,
    submissionUID,
    t,
  }) => (
    <Flex justifyContent="space-between" alignItems="flex-start" mb="8px">
      <Flex alignItems="flex-start" width="100%">
        {avatar ? (
          <Box
            mr={1}
            as="img"
            src={avatar}
            borderRadius="50%"
            width={40}
            height={40}
          />
        ) : (
          <AvatarPlaceholder name={name} color={avatarColor} />
        )}
        <Box width="calc(100% - 48px)">
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <H5
              width={[
                resolved || visibleTo !== 'ICS_AND_ALERTEES' ? 100 : 200,
                resolved || visibleTo !== 'ICS_AND_ALERTEES' ? 165 : 'auto',
              ]}
              flex={['auto', 'none']}
              style={{ overflowWrap: 'break-word' }}
              mb={1}
            >
              {name}
            </H5>
            <Flex
              mb={0}
              flexDirection={['column', 'row']}
              flexWrap="wrap"
              justifyContent={['space-between', 'end']}
              alignItems="end"
              width={[100, 300]}
            >
              {resolved && (
                <Text mb="5px" ml="8px">
                  <Badge type="resolve">
                    <span>{t('Resolved')}</span>
                  </Badge>
                </Text>
              )}
              {visibleTo !== 'ICS_AND_ALERTEES' && (
                <Text mb="5px">
                  <Badge
                    backgroundColor="#FEF7ED"
                    color="#F3AD3D"
                    data-testid={`comment_submission_badge:${submissionUID}:${visibleTo}`}
                  >
                    <span>{t('Public')}</span>
                  </Badge>
                </Text>
              )}
            </Flex>
          </Flex>
          <Body color="darkestShade" small>
            {designation || '--'}
          </Body>
          <Body small mt="8px" style={{ overflowWrap: 'break-word' }}>
            {typeof comment === 'string' ||
            typeof comment === 'number' ||
            comment === ''
              ? comment
              : '...'}
          </Body>
          <Body color="darkestShade" fontSize="12px" mt="8px">
            {postedOn}
          </Body>
        </Box>
      </Flex>
    </Flex>
  )
);

const Comment = ({
  resolved,
  name,
  postedOn,
  comment,
  avatar,
  avatarColor,
  isLastComment,
  designation,
  visibleTo,
  submissionUID,
  t,
}) => (
  <Box
    mt="16px"
    borderBottom={isLastComment ? '0px' : '1px solid #D5D7DE'}
    pb="8px"
  >
    <UserInfo
      name={name}
      avatar={avatar}
      avatarColor={avatarColor}
      postedOn={postedOn}
      resolved={resolved}
      comment={comment}
      designation={designation}
      visibleTo={visibleTo}
      submissionUID={submissionUID}
      t={t}
    />
  </Box>
);

export default React.memo(Comment);
