// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const QuestionMarkSquare = memo<IconProps>(
  ({ color, width = 14, height = 13 }) => (
    <Icon>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1504 1.9375V11.5625C13.1504 12.3008 12.5488 12.875 11.8379 12.875H2.21289C1.47461 12.875 0.900391 12.3008 0.900391 11.5625V1.9375C0.900391 1.22656 1.47461 0.625 2.21289 0.625H11.8379C12.5488 0.625 13.1504 1.22656 13.1504 1.9375ZM11.8379 11.3984V2.10156C11.8379 2.01953 11.7559 1.9375 11.6738 1.9375H2.37695C2.26758 1.9375 2.21289 2.01953 2.21289 2.10156V11.3984C2.21289 11.5078 2.26758 11.5625 2.37695 11.5625H11.6738C11.7559 11.5625 11.8379 11.5078 11.8379 11.3984ZM9.95117 5.24609C9.95117 7.07812 7.95508 7.10547 7.95508 7.78906V7.95312C7.95508 8.14453 7.81836 8.28125 7.62695 8.28125H6.39648C6.20508 8.28125 6.06836 8.14453 6.06836 7.95312V7.73438C6.06836 6.75 6.80664 6.36719 7.35352 6.03906C7.8457 5.76562 8.14648 5.60156 8.14648 5.24609C8.14648 4.75391 7.54492 4.45312 7.05273 4.45312C6.42383 4.45312 6.12305 4.75391 5.71289 5.27344C5.60352 5.41016 5.38477 5.4375 5.24805 5.32812L4.48242 4.75391C4.3457 4.64453 4.31836 4.45312 4.42773 4.31641C5.05664 3.35938 5.87695 2.8125 7.16211 2.8125C8.50195 2.8125 9.95117 3.87891 9.95117 5.24609ZM8.17383 9.8125C8.17383 10.4688 7.6543 10.9609 7.02539 10.9609C6.36914 10.9609 5.87695 10.4688 5.87695 9.8125C5.87695 9.18359 6.36914 8.66406 7.02539 8.66406C7.6543 8.66406 8.17383 9.18359 8.17383 9.8125Z"
          fill={color}
        />
      </svg>
    </Icon>
  )
);

export default QuestionMarkSquare;
