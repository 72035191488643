import React, { useState, useRef } from 'react';
import { format, formatISO, isValid, setHours, setMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Prompt } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';

import {
  chain,
  find,
  findIndex,
  flatMap,
  filter,
  get,
  map,
  omit,
  omitBy,
  first,
  last,
  includes,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
} from 'lodash';

import {
  AccordionWidget,
  Body,
  Box,
  DangerButton,
  Dialog,
  FAIcon,
  Flex,
  H1,
  PrimaryButton,
  SecondaryOutlinedButton,
  Tab,
  Tabs,
  TabPanel,
  Text,
  theme,
  useApi,
  TextLink,
} from '@fivehealth/botero';

import DialogTitle from 'components/Dialog/DialogTitle';
import { BoxPairLayout, BoxPairRightPanel } from 'components/Layout';
import { BoxContentReminder, onOpenReminderPrevDialog } from '../shared';

import ReminderFrequency from './ReminderFrequency';
import ReminderDuration from './ReminderDuration';
import {
  TextButton,
  isRecurring,
  ReminderBox,
  getSeconds,
  isValidReminder,
} from './utils';
import { useModal } from '../../../context/ModalContext';
import BotCryingAvatar from '../../../assets/crying-avatar.svg';
import { customerSupportEmail } from '../../../AppUtils';

const SideTab = ({ children, active = false, ...props }) => {
  const [hover, setHover] = useState(false);
  return (
    <Box
      bg={hover || active ? theme.colors.lightestShade : 'transparent'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {children}
    </Box>
  );
};

const EditReminderLayout = ({
  t,
  tab,
  isMobile,
  reminder,
  formState,
  patientForm,
  setFormState,
  openInvalidDateDialog,
  openInvalidMultipleDateDialog,
}) => (
  <>
    {isEqual(tab, 'frequency') && (
      <ReminderFrequency
        t={t}
        isMobile={isMobile}
        reminder={reminder}
        formState={formState}
        patientForm={patientForm}
        setFormState={setFormState}
        openInvalidDateDialog={openInvalidDateDialog}
        openInvalidMultipleDateDialog={openInvalidMultipleDateDialog}
      />
    )}

    {isEqual(tab, 'duration') && (
      <ReminderDuration
        t={t}
        isMobile={isMobile}
        reminder={reminder}
        formState={formState}
        setFormState={setFormState}
      />
    )}
  </>
);

const isEdited = (formState) =>
  get(formState, 'frequency.edit', false) ||
  get(formState, 'duration.edit', false);

const DialogDescription = ({ t }) => (
  <Flex flexDirection={['column', 'row']}>
    <Text fontSize="14px" fontWeight={400} color={theme.colors.fullShade}>
      Please ensure that the
    </Text>
    <Text
      fontSize="14px"
      fontWeight={700}
      ml="2px"
      color={theme.colors.fullShade}
    >
      {t('reminders start & end dates do not overlap with each other.')}
    </Text>
  </Flex>
);

const ReminderForm = ({
  t,
  tab,
  viewState,
  reminders,
  formState,
  setFormState,
  setReminders,
  openModal,
  closeModal,
  openInvalidDateDialog,
  openInvalidMultipleDateDialog,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const accordionRef = useRef([]);
  const isCreateView = get(viewState, 'isCreate');
  const onSetAccordionRef = (ref, index) => {
    accordionRef.current[index] = ref;
  };

  const onClickDialog = () => {
    onOpenReminderPrevDialog(
      t,
      openModal,
      closeModal,
      filter(reminders, (reminder) => !reminder.isNewReminder),
      <DialogDescription t={t} />
    );
  };

  const renderEditViewLeftPanel = () => (
    <>
      {map(reminders, (reminder, index) => (
        <SideTab
          key={index}
          p={1}
          pb={0}
          m={1}
          mt={0}
          active={reminder.active}
          onClick={() => setReminders(reminder, index)}
        >
          <ReminderBox
            t={t}
            active={reminder.active}
            label={reminder?.effectiveName || `Reminder ${index + 1}`}
            reminder={reminder}
          />
        </SideTab>
      ))}
    </>
  );

  const renderCreateViewLeftPanel = () => (
    <>
      <Text
        p="5px"
        fontSize="16px"
        fontWeight={600}
        color={theme.colors.fullShade}
      >
        {t('Create reminder')}
      </Text>
      <Text
        mt={[2, 0]}
        p="5px"
        fontSize="14px"
        fontWeight={400}
        color={theme.colors.darkestShade}
      >
        {t('Select a global default or create a custom reminder.')}
      </Text>

      <Flex>
        <Text
          mt={1}
          p="5px"
          fontSize="14px"
          fontWeight={400}
          color={theme.colors.darkestShade}
        >
          {t('View current reminders')}
        </Text>
        <TextLink
          text={t('here')}
          fontSize="14px"
          fontWeight={400}
          mt={1}
          style={{
            textDecoration: 'underline',
            color: theme.colors.primary,
          }}
          onClick={() => {
            onClickDialog();
          }}
        />
        <Text fontSize="14px" fontWeight={400} mt="12px">
          .
        </Text>
      </Flex>
    </>
  );

  const renderMobileEditViewLeftPanel = () => {
    const reminderList = isCreateView
      ? [find(reminders, { active: true })]
      : reminders;

    return (
      <>
        {map(reminderList, (reminder, index) => {
          return (
            <AccordionWidget
              key={index}
              width="100%"
              renderHeader={({ name, isOpen }) =>
                isCreateView && index === 0 ? (
                  <Box p={2}>{renderCreateViewLeftPanel()}</Box>
                ) : (
                  <Flex
                    p={2}
                    justifyContent="space-between"
                    onClick={() => {
                      if (!accordionRef.current[index].open) {
                        map(
                          accordionRef.current,
                          ({ onChange }, accordionIndex) => {
                            if (!isEqual(accordionIndex, index)) {
                              onChange(false);
                            }
                          }
                        );
                        setReminders(reminder, index);
                      }
                    }}
                  >
                    <Box>
                      <Box style={{ fontSize: '14px', fontWeight: 500 }} mb={2}>
                        {name}
                      </Box>
                      <BoxContentReminder
                        t={t}
                        width="250px"
                        data={reminder}
                        pb={0}
                      />
                    </Box>

                    <FAIcon
                      icon={faChevronDown}
                      style={{
                        height: 16,
                        transform: `rotate(${isOpen ? '180' : '0'}deg)`,
                      }}
                    />
                  </Flex>
                )
              }
              renderBody={({ data }) => (
                <Box
                  m={2}
                  borderTop="1px solid #D5D7DE"
                  border={
                    isCreateView
                      ? `1px solid ${theme.colors.mediumShade}`
                      : null
                  }
                  borderRadius={isCreateView ? 8 : null}
                  p={isCreateView ? 2 : 0}
                  pt={2}
                >
                  {isCreateView && (
                    <Text
                      color={theme.colors.fullShade}
                      fontSize={15}
                      fontWeight={600}
                      mb={3}
                    >
                      {t('New reminder')}
                    </Text>
                  )}
                  <EditReminderLayout
                    t={t}
                    tab={tab}
                    isMobile={isMobile}
                    reminder={data}
                    formState={formState}
                    patientForm={viewState.patientForm}
                    setFormState={setFormState}
                    openInvalidDateDialog={openInvalidDateDialog}
                    openInvalidMultipleDateDialog={
                      openInvalidMultipleDateDialog
                    }
                  />
                </Box>
              )}
              containerProps={{
                mb: 2,
                borderWidth: 1,
                borderColor: '#ccc',
              }}
              headerProps={{
                minHeight: 16,
                backgroundColor: 'white',
              }}
              bodyProps={{
                backgroundColor: 'white',
                fixedHeight: '100%',
              }}
              accordionProps={{
                accordionRef: (ref) => onSetAccordionRef(ref, index),
              }}
              data={[
                {
                  uid: `edit-reminders-key-${index}`, // NOTE: Use as a unique key for the accordion widget
                  name: `${t('Reminders')} ${index + 1}`,
                  isOpen: isCreateView ? true : reminder?.active,
                  data: reminder,
                },
              ]}
            />
          );
        })}
      </>
    );
  };

  return (
    <Box mt={2}>
      {isMobile ? (
        renderMobileEditViewLeftPanel()
      ) : (
        <BoxPairLayout boxLeftProps={{ pt: 4 }}>
          <Box>
            {!isCreateView
              ? renderEditViewLeftPanel()
              : renderCreateViewLeftPanel()}
          </Box>

          <BoxPairRightPanel
            headerLeft={
              isCreateView
                ? t('New reminder')
                : reminders[
                    findIndex(reminders, {
                      active: true,
                    })
                  ]?.effectiveName ||
                  `Reminder ${
                    findIndex(reminders, {
                      active: true,
                    }) + 1
                  }`
            }
          >
            <EditReminderLayout
              t={t}
              tab={tab}
              isMobile={isMobile}
              reminder={find(reminders, { active: true })}
              formState={formState}
              patientForm={viewState.patientForm}
              setFormState={setFormState}
              openInvalidDateDialog={openInvalidDateDialog}
              openInvalidMultipleDateDialog={openInvalidMultipleDateDialog}
            />
          </BoxPairRightPanel>
        </BoxPairLayout>
      )}
    </Box>
  );
};

const getFollowUpReminder = (reminder, formState) => {
  const { settings } = reminder;
  const followUpReminder = get(formState, 'frequency.followUpReminder');

  if (!isUndefined(followUpReminder)) {
    return map(
      filter(followUpReminder, (value) => !isUndefined(value) && value > 0),
      (value) => value
    );
  }

  return get(settings, 'follow_up_intervals', []);
};

const getRecentSubmission = (reminder, formState) => {
  const { settings } = reminder;
  const preSubmission = get(
    formState,
    'frequency.preSubmissionComplianceDuration'
  );

  const postSubmission = get(
    formState,
    'frequency.postSubmissionComplianceDuration'
  );

  if (!isUndefined(preSubmission) && !isUndefined(postSubmission)) {
    return {
      before: getSeconds(
        preSubmission,
        formState.frequency?.preSubmissionComplianceUnit?.value
      ),
      after: getSeconds(
        postSubmission,
        formState.frequency?.postSubmissionComplianceUnit?.value
      ),
    };
  }

  return get(settings, 'recent_submission');
};

const getRecurringFrequencies = (formState) => {
  const times = get(formState.frequency, 'reminderFrequencyTimes');
  const unit = get(formState.frequency, 'reminderFrequencyUnit.value');
  const every = get(formState.frequency, 'reminderFrequencyInterval');

  if (!isUndefined(times) && !isUndefined(unit) && !isUndefined(every)) {
    if (isEqual(unit, 'day')) {
      return flatMap(times, (time) => ({
        unit,
        every: parseInt(every, 10),
        time_of_day: time.value,
      }));
    }

    if (isEqual(unit, 'week')) {
      return flatMap(times, (time) =>
        map(formState.frequency.reminderFrequencyDaysOfWeek, (day) => ({
          unit,
          every: parseInt(every, 10),
          day_of_week: day.id,
          time_of_day: time.value,
        }))
      );
    }

    if (isEqual(unit, 'month')) {
      return flatMap(times, (time) =>
        map(formState.frequency.reminderFrequencyDaysOfMonth, (day) => ({
          unit,
          every: parseInt(every, 10),
          day_of_month: day.value,
          time_of_day: time.value,
        }))
      );
    }
  }

  return {};
};

const getTrigger = (event, duration = 1, unit = 'minute', formState = {}) => {
  if (includes(['immediateEnrollment', 'immediateDischarge'], event)) {
    return {
      event: isEqual(event, 'immediateEnrollment') ? 'enrollment' : 'discharge',
    };
  }

  const trigger = {
    event,
    unit,
    duration: parseInt(duration, 10),
  };

  const reminderTime = chain(formState)
    .get('frequency.sendReminderTime')
    .first()
    .get('value')
    .value();

  if (reminderTime && unit !== 'minute' && unit !== 'hour') {
    trigger.time_of_day = reminderTime;
  }

  return trigger;
};

const getOneTimeTrigger = (reminder, formState) => {
  const { schedule } = reminder.settings || {};
  const triggerEvent = get(formState, 'frequency.triggerEvent.value');

  if (!isUndefined(triggerEvent)) {
    return getTrigger(
      triggerEvent,
      parseInt(formState.frequency.reminderOneTimeDuration, 10),
      formState.frequency.reminderOneTimeUnit?.value,
      formState
    );
  }

  return get(schedule, 'start_trigger');
};

const getAction = (reminder, formState) => {
  const messageTemplate = get(formState, 'frequency.messageTemplate');
  if (messageTemplate) {
    return {
      type: 'send_template',
      send_template: {
        purpose: 'user_defined',
        template_set: messageTemplate.uid,
      },
    };
  }

  const reminderAction = get(reminder, 'settings.schedule.action');
  if (reminderAction) {
    return reminderAction;
  }

  return {
    send_reminder: {},
    type: 'send_reminder',
  };
};

const getSchedule = (reminder, formState, selectedTab) => {
  let { schedule } = reminder?.settings || {};

  // Frequency Tab
  if (isEqual(selectedTab, 0)) {
    const reminderType = get(formState.frequency, 'reminderType.key');

    const isRecurringReminder = isUndefined(reminderType)
      ? isRecurring(reminder)
      : isEqual(reminderType, 'recurring');

    if (isRecurringReminder) {
      let recurringFreq = getRecurringFrequencies(formState);

      const count = get(schedule, 'recurring_frequencies[0].count');
      if (!isUndefined(count)) {
        recurringFreq = map(recurringFreq, (freq) => ({
          ...freq,
          count,
        }));
      }

      if (!isEmpty(recurringFreq)) {
        schedule = {
          ...schedule,
          recurring_frequencies: recurringFreq,
        };
      }
    } else {
      const triggerEvent = get(formState.frequency, 'triggerEvent.value');
      if (isEqual(triggerEvent, 'datetime')) {
        let reminderDate = get(formState, 'frequency.sendReminderDate');
        if (reminderDate) {
          const reminderTime = chain(formState)
            .get('frequency.sendReminderTime')
            .first()
            .get('value')
            .split(':')
            .value();

          reminderDate = setHours(reminderDate, first(reminderTime));
          reminderDate = setMinutes(reminderDate, last(reminderTime));

          schedule = {
            start_trigger: {
              event: 'exact',
              exact_trigger_on: formatISO(reminderDate),
            },
          };
        }
      } else {
        schedule = {
          ...omit(schedule, ['recurring_frequencies', 'end_trigger']),
          start_trigger: getOneTimeTrigger(reminder, formState),
        };
      }
    }
  }

  // Duration Tab
  if (isEqual(selectedTab, 1)) {
    const startEvent = get(formState.duration, 'reminderStart.value');
    if (!isUndefined(startEvent)) {
      if (!isEqual(startEvent, 'noStart')) {
        schedule = {
          ...schedule,
          start_trigger: getTrigger(
            formState.duration.reminderStart.value,
            formState.duration.reminderStartDuration,
            formState.duration.reminderStartUnit.value
          ),
        };
      } else {
        schedule = omit(schedule, 'start_trigger');
      }
    }

    const endEvent = get(formState.duration, 'reminderEnd.value');
    if (!isUndefined(endEvent)) {
      if (isEqual(endEvent, 'count')) {
        schedule = {
          ...omit(schedule, 'end_trigger'),
          recurring_frequencies: map(
            schedule.recurring_frequencies,
            (freq) => ({
              ...freq,
              count: parseInt(formState.duration.reminderRecurringLimit, 10),
            })
          ),
        };
      } else if (!isEqual(endEvent, 'noEnd')) {
        schedule = {
          ...schedule,
          end_trigger: getTrigger(
            formState.duration.reminderEnd.value,
            formState.duration.reminderEndDuration,
            formState.duration.reminderEndUnit.value
          ),
          recurring_frequencies: map(schedule.recurring_frequencies, (freq) =>
            omit(freq, 'count')
          ),
        };
      } else {
        schedule = {
          ...omit(schedule, 'end_trigger'),
          recurring_frequencies: map(schedule.recurring_frequencies, (freq) =>
            omit(freq, 'count')
          ),
        };
      }
    }
  }

  return {
    ...schedule,
    action: getAction(reminder, formState),
  };
};

export const getReminderFromFormState = (reminder, formState, activeTab) => {
  // frequency tab
  if (
    isEqual(activeTab, 0) &&
    !isUndefined(formState.frequency?.selectReminder)
  ) {
    if (isEqual(formState.frequency.selectReminder.value, 'customize')) {
      return {
        ...reminder,
        toCreate: reminder.toCreate || reminder.isForClinic,
        isEdited: reminder.isEdited || isEdited(formState),
        settings: omitBy(
          {
            ...(reminder.settings || {}),
            follow_up_intervals: getFollowUpReminder(reminder, formState),
            recent_submission: getRecentSubmission(reminder, formState),
            schedule: getSchedule(reminder, formState, activeTab),
          },
          isEmpty || isUndefined || isNull
        ),
      };
    }

    const globalReminder = omit(formState.frequency.selectReminder, [
      'label',
      'value',
    ]);

    if (!isEqual(globalReminder?.uid, reminder?.uid)) {
      return {
        ...globalReminder,
        isNewReminder: reminder.isNewReminder,
        isEdited: reminder.isEdited || isEdited(formState),
        toCreate: false,
        active: reminder.active,
      };
    }

    return reminder;
  }

  // duration tab
  if (isEqual(activeTab, 1)) {
    const hasEdited = get(formState, 'duration.edit', false);
    if (hasEdited) {
      return {
        ...reminder,
        toCreate: reminder.toCreate || reminder.isForClinic,
        isEdited: hasEdited,
        settings: omitBy(
          {
            ...(reminder.settings || {}),
            schedule: getSchedule(reminder, formState, activeTab),
          },
          isEmpty || isUndefined || isNull
        ),
      };
    }
  }

  return reminder;
};

const Reminders = ({ viewState, showPatientProfile }) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openConfimationDialog, setOpenConfirmationDialog] = useState(false);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [openInvalidDateDialog, setOpenInvalidDateDialog] = useState(false);
  const [openInvalidMultipleDateDialog, setOpenInvalidMultipleDateDialog] =
    useState(false);
  const [openInvalidRecurringDialog, setOpenInvalidRecurringDialog] =
    useState(false);

  // default frequency tab
  const [activeTab, setActiveTab] = useState(get(viewState, 'selectedTab', 0));

  const [isCreateReminder] = useState(get(viewState, 'isCreate', false));

  const { effectiveReminders } = viewState?.patientForm || {};

  const activeReminder = find(effectiveReminders, {
    uid: viewState.reminderUid,
  });

  const [formState, setFormState] = useState({});
  const [reminders, setReminders] = useState(
    chain(effectiveReminders)
      .map((reminder) => ({
        ...reminder,
        toCreate: false,
        active: isCreateReminder
          ? false
          : isEqual(reminder.uid, activeReminder.uid),
      }))
      .union(
        isCreateReminder
          ? [
              {
                isNewReminder: true,
                toCreate: true,
                active: true,
                settings: {
                  schedule: {
                    action: {
                      send_reminder: {},
                      type: 'send_reminder',
                    },
                  },
                },
              },
            ]
          : []
      )
      .map((reminder) => {
        // backend requires default start and end trigger to be set for recurring reminder
        if (isRecurring(reminder)) {
          return {
            ...reminder,
            settings: {
              ...reminder?.settings,
              schedule: {
                ...reminder?.settings?.schedule,
                start_trigger:
                  reminder?.settings?.schedule?.start_trigger ||
                  getTrigger('immediateEnrollment'),
                end_trigger:
                  reminder?.settings?.schedule?.end_trigger ||
                  getTrigger('immediateDischarge'),
              },
            },
          };
        }
        return reminder;
      })
      .value()
  );

  const onInvalidateQueries = ({ queryClient }) => {
    if (!queryClient) {
      return;
    }
    queryClient.invalidateQueries('alertRules').then(() => {
      queryClient.invalidateQueries('alertReminder');
      queryClient.invalidateQueries('patient');
      queryClient.invalidateQueries('patientForms');
    });
  };

  const {
    queries: {
      useAlertReminderUpdate,
      useAlertReminderCreate,
      usePatientFormUpdate,
    },
  } = useApi({
    queries: [
      'useAlertReminderUpdate',
      'useAlertReminderCreate',
      'usePatientFormUpdate',
    ],
  });

  const { isLoading: isUpdateReminderLoading, mutateAsync: updateReminder } =
    useAlertReminderUpdate({
      variables: {},
      onSuccess: onInvalidateQueries,
    });

  const {
    isLoading: isUpdatePatientFormLoading,
    mutateAsync: updatePatientForm,
  } = usePatientFormUpdate({
    variables: {},
    onSuccess: onInvalidateQueries,
  });

  const { isLoading: isCreateReminderLoading, mutateAsync: createReminder } =
    useAlertReminderCreate({
      variables: {},
      onSuccess: onInvalidateQueries,
    });

  const onSave = () => {
    return Promise.all(
      chain(reminders)
        .map(async (reminder) => {
          // update active reminder
          const newReminder = reminder.active
            ? getReminderFromFormState(reminder, formState, activeTab)
            : reminder;

          // User select clinic reminder
          if (!newReminder.toCreate && newReminder.isForClinic) {
            return {
              uid: newReminder.uid,
            };
          }

          // reminder not edited
          if (!get(newReminder, 'isEdited', false)) {
            return newReminder;
          }

          if (!newReminder.toCreate) {
            // existing reminder is a custom reminder
            return updateReminder({
              input: {
                uid: newReminder.uid,
                settings: omitBy(
                  newReminder.settings,
                  isEmpty || isUndefined || isNull
                ),
              },
            }).then(
              ({ cleoReminderUpdate }) => cleoReminderUpdate.cleoReminder
            );
          }

          // create new reminder
          const reminderToCreate = {
            name: `Reminder - created ${format(
              new Date(),
              'MM-dd-yyyy HH:mm'
            )}`,
            isForClinic: false,
            settings: omitBy(
              newReminder.settings,
              isEmpty || isUndefined || isNull
            ),
          };
          return createReminder({ input: reminderToCreate }).then(
            ({ cleoReminderCreate }) => cleoReminderCreate.cleoReminder
          );
        })
        .value()
    )
      .then(async (results) => {
        await updatePatientForm({
          input: {
            uid: viewState.patientForm.uid,
            reminders: map(results, ({ uid }) => ({ uid })),
            doNotRemind: false,
          },
        });
        showPatientProfile();
      })
      .catch(() => setOpenErrorDialog(true));
  };

  const onCancel = () => {
    if (isEdited(formState)) {
      setOpenSaveDialog(!openSaveDialog);
    } else {
      showPatientProfile();
    }
  };

  const onChangeReminders = (reminder, index) => {
    const newReminders = map(reminders, (r, i) => {
      const selected = isEqual(r.uid, reminder.uid) && isEqual(i, index);
      if (r.active) {
        return {
          ...getReminderFromFormState(r, formState, activeTab),
          active: selected,
        };
      }

      return {
        ...r,
        active: selected,
      };
    });
    setReminders(newReminders);
  };

  const onChangeTab = (tabIndex) => {
    const activeReminderIndex = findIndex(reminders, { active: true });
    onChangeReminders(reminders[activeReminderIndex], activeReminderIndex);

    const tab = isEqual(tabIndex, 0) ? 'frequency' : 'duration';
    setFormState(omit(formState, [tab]));

    setActiveTab(tabIndex);
  };

  const onPreviewChanges = () => {
    const reminderType = get(formState?.frequency, 'reminderType.key');
    if (
      isEqual(reminderType, 'recurring') &&
      isEmpty(get(formState?.frequency, 'reminderFrequencyTimes', []))
    ) {
      return setOpenInvalidRecurringDialog(!openInvalidRecurringDialog);
    }

    const triggerEvent = get(formState?.frequency, 'triggerEvent.value');
    if (
      isEqual(triggerEvent, 'datetime') &&
      (!isValid(get(formState?.frequency, 'sendReminderDate')) ||
        isEmpty(get(formState?.frequency, 'sendReminderTime')))
    ) {
      return setOpenInvalidDateDialog(!openInvalidDateDialog);
    }

    const isValidReminders = chain(reminders)
      .map((reminder) =>
        reminder.active
          ? getReminderFromFormState(reminder, formState, activeTab)
          : reminder
      )
      .every((reminder) => isValidReminder(reminder))
      .value();

    if (isValidReminders) {
      return setOpenPreviewDialog(!openPreviewDialog);
    }

    return setOpenValidationDialog(!openValidationDialog);
  };

  return (
    <Box py={4} mb={[4, 8]}>
      <TextButton
        id="backBtn"
        alignItems="center"
        mb={1}
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={onCancel}
      >
        <FAIcon
          icon={faChevronLeft}
          color={theme.colors.darkestShade}
          fontWeight="900"
          style={{ fontSize: 12, fontWeight: 900, marginRight: 4 }}
        />
        <Text fontSize={13} fontWeight="900" color={theme.colors.darkestShade}>
          {t('Back to Clinical Settings')}
        </Text>
      </TextButton>

      <Flex testid="create_reminder_container" justifyContent="space-between">
        <Box>
          <H1>
            {isCreateReminder ? t('Create reminder') : t('Edit reminders')}
          </H1>
          <Body pt={2}>
            {isCreateReminder
              ? t('Set up a new reminder for this patient')
              : t(
                  'Edit your patient reminder frequencies and the duration in which they will receive reminders.'
                )}
          </Body>
        </Box>

        <Box display={['none', 'initial', 'initial']}>
          <Flex pt={[2, 0]} pl={2}>
            <PrimaryButton
              testid="create_edit_reminder_save_btn"
              mr={[0, 3]}
              borderRadius={8}
              pt={2}
              pb={2}
              height={40}
              mb={[1, 0]}
              onClick={onPreviewChanges}
              disabled={
                isUpdateReminderLoading ||
                isUpdatePatientFormLoading ||
                isCreateReminderLoading ||
                !isEdited(formState)
              }
            >
              {t('Save')}
            </PrimaryButton>

            <SecondaryOutlinedButton
              testid="create_edit_reminder_cancel_btn"
              borderColor="#A3A9B1"
              borderRadius={8}
              pt={2}
              pb={2}
              height={40}
              onClick={onCancel}
              disabled={
                isUpdateReminderLoading ||
                isUpdatePatientFormLoading ||
                isCreateReminderLoading
              }
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
          </Flex>
        </Box>
      </Flex>

      <Box mt={4}>
        <Tabs
          activeTabIndex={activeTab}
          onChange={onChangeTab}
          headerProps={{ borderBottom: '1px solid #ccc' }}
        >
          <Tab>
            <Flex>{t('Frequency')}</Flex>
          </Tab>
          <Tab>
            <Flex>{t('Duration')}</Flex>
          </Tab>
          <TabPanel py={[0, 3]}>
            <ReminderForm
              t={t}
              tab="frequency"
              viewState={viewState}
              reminders={reminders}
              formState={formState}
              setFormState={setFormState}
              setReminders={onChangeReminders}
              openModal={openModal}
              closeModal={closeModal}
              openInvalidDateDialog={() => setOpenInvalidDateDialog(true)}
              openInvalidMultipleDateDialog={() =>
                setOpenInvalidMultipleDateDialog(true)
              }
            />
          </TabPanel>
          <TabPanel py={[0, 3]}>
            <ReminderForm
              t={t}
              tab="duration"
              viewState={viewState}
              reminders={reminders}
              formState={formState}
              setFormState={setFormState}
              setReminders={onChangeReminders}
              openModal={openModal}
              closeModal={closeModal}
            />
          </TabPanel>
        </Tabs>
      </Box>

      <Box display={['initial', 'none', 'none']}>
        <Flex pt={[2, 0]} pl={2} mb={8} justifyContent="right">
          <SecondaryOutlinedButton
            borderColor="#A3A9B1"
            borderRadius={8}
            pt={2}
            mr={2}
            pb={2}
            height={40}
            onClick={onCancel}
            disabled={
              isUpdateReminderLoading ||
              isUpdatePatientFormLoading ||
              isCreateReminderLoading
            }
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius={8}
            pt={2}
            pb={2}
            height={40}
            mb={[1, 0]}
            onClick={onPreviewChanges}
            disabled={
              isUpdateReminderLoading ||
              isUpdatePatientFormLoading ||
              isCreateReminderLoading ||
              !isEdited(formState)
            }
          >
            {t('Save')}
          </PrimaryButton>
        </Flex>
      </Box>

      <Prompt
        when={isEdited(formState)}
        message="Are you sure you want to leave Create/Edit Reminders page without saving your changes?"
      />

      <Dialog open={openPreviewDialog} onClose={setOpenPreviewDialog}>
        <DialogTitle
          label={t('Save changes?')}
          onClick={() => setOpenPreviewDialog(!openPreviewDialog)}
        />
        <Text mb={4}>
          <DialogDescription t={t} />
        </Text>
        <Box mb={4}>
          {chain(reminders)
            .map((reminder) =>
              reminder.active
                ? getReminderFromFormState(reminder, formState, activeTab)
                : reminder
            )
            .map((reminder, index) => {
              return (
                <ReminderBox
                  key={index}
                  t={t}
                  active={false}
                  label={
                    reminder.isNewReminder
                      ? t('New reminder')
                      : (reminder?.effectiveName ?? reminder?.name) ||
                        `${t('Reminder')} ${index + 1}`
                  }
                  reminder={reminder}
                  border="1px solid #D5D7DE"
                  borderRadius="16px"
                  mb={2}
                  p={1}
                />
              );
            })
            .value()}
        </Box>
        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mr={2}
            onClick={() => {
              setOpenPreviewDialog(!openPreviewDialog);
            }}
          >
            {t('Back to Edit')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            onClick={() => {
              setOpenPreviewDialog(!openPreviewDialog);
              onSave();
            }}
          >
            {t('Save')}
          </PrimaryButton>
        </Flex>
      </Dialog>

      <Dialog open={openConfimationDialog} onClose={setOpenConfirmationDialog}>
        <DialogTitle
          label={t('Are you sure?')}
          onClick={() => setOpenConfirmationDialog(!openConfimationDialog)}
        />
        <Text fontWeight={400} fontSize={2} mb={2}>
          {t(
            'Note that there will be no reminders attached to this form if this reminder is deleted. The patient will remain enrolled in this form, but will not receive any reminders.'
          )}
        </Text>

        <Text fontWeight={400} fontSize={2} mb={4}>
          {t('Do you wish to proceed?')}
        </Text>
        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mr={2}
            onClick={() => setOpenConfirmationDialog(!openConfimationDialog)}
          >
            {t('Discard')}
          </SecondaryOutlinedButton>
          <DangerButton>{t('Delete reminder')}</DangerButton>
        </Flex>
      </Dialog>

      <Dialog open={openSaveDialog} onClose={setOpenSaveDialog}>
        <Box width="400px">
          <DialogTitle
            label={t('Discard unsaved changes?')}
            onClick={() => setOpenSaveDialog(!openSaveDialog)}
          />
          <Text fontWeight={400} fontSize={2} mb={4}>
            {t('Your changes have not been saved.')}
          </Text>
          <Flex justifyContent="right">
            {/* <PrimaryButton
            onClick={() => {
              setOpenSaveDialog(!openSaveDialog);
              onPreviewChanges();
            }}
          >
            {t('Save')}
          </PrimaryButton> */}
            <SecondaryOutlinedButton
              mr={2}
              onClick={() => setOpenSaveDialog(false)}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <DangerButton
              mr={2}
              bg={theme.colors.danger}
              color={theme.colors.emptyShade}
              onClick={() => {
                setOpenSaveDialog(!openSaveDialog);
                showPatientProfile();
              }}
            >
              {t('Discard')}
            </DangerButton>
          </Flex>
        </Box>
      </Dialog>

      <Dialog open={openValidationDialog} onClose={setOpenValidationDialog}>
        <DialogTitle
          label={t('Validation Error')}
          onClick={() => setOpenValidationDialog(!openValidationDialog)}
        />
        <Text fontWeight={400} fontSize={2} mb={2}>
          {t('There is conflict in start or end trigger.')}
        </Text>

        <ul>
          <li>
            <Text fontWeight={400} fontSize={2} mb={2}>
              {t(
                'End trigger should not be the same or happen before start trigger.'
              )}
            </Text>
          </li>
          <li>
            <Text fontWeight={400} fontSize={2} mb={2}>
              {t('Both start and end trigger should not have duration with 0.')}
            </Text>
          </li>
        </ul>

        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mt={2}
            mr={2}
            onClick={() => setOpenValidationDialog(!openValidationDialog)}
          >
            {t('Back')}
          </SecondaryOutlinedButton>
        </Flex>
      </Dialog>

      <Dialog
        open={openInvalidMultipleDateDialog}
        onClose={setOpenInvalidMultipleDateDialog}
      >
        <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 360]}>
          <DialogTitle
            label={t('Validation Error')}
            onClick={() =>
              setOpenInvalidMultipleDateDialog(!openInvalidMultipleDateDialog)
            }
          />
          <Text fontWeight={400} fontSize={2} mb={2}>
            {t('Only one date/time is allowed.')}
          </Text>

          <Flex justifyContent="right">
            <SecondaryOutlinedButton
              mt={2}
              mr={2}
              onClick={() =>
                setOpenInvalidMultipleDateDialog(!openInvalidMultipleDateDialog)
              }
            >
              {t('Back')}
            </SecondaryOutlinedButton>
          </Flex>
        </Box>
      </Dialog>

      <Dialog open={openInvalidDateDialog} onClose={setOpenInvalidDateDialog}>
        <DialogTitle
          label={t('Validation Error')}
          onClick={() => setOpenInvalidDateDialog(!openInvalidDateDialog)}
        />
        <Text fontWeight={400} fontSize={2} mb={2}>
          {t(
            'Date/Time selected is invalid. Please select a date/time in the future.'
          )}
        </Text>

        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mt={2}
            mr={2}
            onClick={() => setOpenInvalidDateDialog(!openInvalidDateDialog)}
          >
            {t('Back')}
          </SecondaryOutlinedButton>
        </Flex>
      </Dialog>

      <Dialog
        open={openInvalidRecurringDialog}
        onClose={setOpenInvalidRecurringDialog}
      >
        <DialogTitle
          label={t('Validation Error')}
          onClick={() =>
            setOpenInvalidRecurringDialog(!openInvalidRecurringDialog)
          }
        />
        <Text fontWeight={400} fontSize={2} mb={2}>
          {t(
            'Recurring time selected is invalid. Please select a time for recurring reminder.'
          )}
        </Text>

        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mt={2}
            mr={2}
            onClick={() =>
              setOpenInvalidRecurringDialog(!openInvalidRecurringDialog)
            }
          >
            {t('Back')}
          </SecondaryOutlinedButton>
        </Flex>
      </Dialog>

      <Dialog open={openErrorDialog} onClose={setOpenErrorDialog}>
        <DialogTitle
          label={t('Something went wrong')}
          onClick={() => setOpenErrorDialog(!openErrorDialog)}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          maxWidth={500}
          mb={1}
        >
          <Box
            as="img"
            src={BotCryingAvatar}
            height={152}
            width={170}
            mb={4}
            alt="Bot Avatar"
          />
          <Text fontSize={2}>{t('Please check reminder settings.')}</Text>
          <Text fontSize={2} mt={1}>
            {t('If the issue persists, please reach out to ')}
          </Text>
          <TextLink
            text={t('customer support')}
            mt={1}
            style={{
              textDecoration: 'underline',
              color: theme.colors.primary,
            }}
            fontSize={16}
            onClick={() => {
              customerSupportEmail();
            }}
          />
        </Flex>

        <Flex justifyContent="right">
          <SecondaryOutlinedButton
            mt={2}
            mr={2}
            onClick={() => setOpenErrorDialog(!openErrorDialog)}
          >
            {t('Back')}
          </SecondaryOutlinedButton>
        </Flex>
      </Dialog>
    </Box>
  );
};

export default Reminders;
