import { Box, Flex, Text } from '@fivehealth/botero';
import { withTranslation } from 'react-i18next';

const TableSectionHeader = withTranslation()(({ t, title, subtitle }) => (
  <Box>
    <Flex flexDirection="column" alignItems="center" p={2}>
      <Text fontSize={16} fontWeight={600} color="fullShade">
        {t(title)}
      </Text>
      <Text fontSize={14} color="darkestShade" mt={1}>
        {t(subtitle)}
      </Text>
    </Flex>
  </Box>
));
export default TableSectionHeader;
