import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import { FAIcon, Text, useApi, useAuth, theme } from '@fivehealth/botero';
import { faTimes, faUserAlt } from '@fortawesome/pro-regular-svg-icons';

import { capitalize, map, toLower } from 'lodash';
import moment from 'moment';

import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getMetadataFiledType, getStatusColor } from 'AppUtils';
import Calendar from 'components/icons/Calendar';
import Colors from '../../constants/colors';

export type Metadata = {
  uid: string;
  parameter: {
    name: string;
  };
  value: {
    file: {
      url: string;
    };
  };
  extractedForDisplay: string;
};

export type Patient = {
  identifier: string;
  name: string;
  phone: string;
  uid: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  clinic?: {
    name: string;
  };
  communicationMethod?: string;
  id?: string;
  createdOn?: string;
};

export type Entry = {
  type?: string;
  value: string | (() => void);
  label: string;
};

export type Event = {
  uid: string;
  name: string;
  startOn: string;
  status: string;
  patient?: Patient;
  createdOn?: string;
  eventUrl?: string;
};

export type Appointment = {
  uid: string;
  name: string;
  startOn: string;
  status: string;
  event?: Event;
  eventUrl?: string;
  cancelUrl?: string;
  rescheduleUrl?: string;
  patient: Patient;
  createdOn: string;
  confirmedOn?: string;
  patientShowedOn?: string;
  isConfirmed?: boolean;
  isPatientShowed?: boolean;
};

export type EventLog = {
  datetime: string;
  clinician: {
    name: string;
  };
  status: string;
};

export interface AppointmentDetailsProps extends Appointment {
  onClose: () => void;
  renderStatus: (status: string, isSmall: boolean) => JSX.Element;
  eventLogs?: EventLog[];
  metadataEntries?: Metadata[];
  refreshEvents: () => void;
}

export const AppointmentDetails = memo<AppointmentDetailsProps>(
  ({
    uid,
    patient,
    event,
    rescheduleUrl,
    onClose,
    status = '',
    renderStatus,
    eventLogs = [],
    metadataEntries,
    refreshEvents,
  }) => {
    const { t } = useTranslation();

    const statusColors = getStatusColor(toLower(status), t);

    const { authState } = useAuth();

    const {
      queries: { usePatientEvents, usePatientEventDelete },
    } = useApi({
      queries: ['usePatientEvents', 'usePatientEventDelete'],
    });

    // const createdOnGte = useMemo(
    //   () => moment().toISOString().replace('Z', '+00:00'),
    //   []
    // );

    const { data: upcomingAppointments } = usePatientEvents({
      enabled: !!patient?.uid,
      variables: {
        isUpcoming: true,
        patientUidIn: [patient?.uid],
      },
    });

    const isCancelled = useMemo(
      () => toLower(status) === 'cancelled',
      [status]
    );

    const isPast = useMemo(() => toLower(status) === 'past', [status]);

    const { mutateAsync: cancelAppointment } = usePatientEventDelete({});

    const renderValue = (entry: Entry) => {
      if (entry.type === 'link' || entry.type === 'file') {
        return (
          <Button
            as="a"
            href={entry.value as string}
            target="_blank"
            fontSize={14}
            color={Colors.primary}
            variant="link"
            textDecoration="underline"
          >
            {t(`${entry.label} link`)}
          </Button>
        );
      }

      if (entry.type === 'button') {
        return (
          <Button
            onClick={entry.value as () => void}
            fontSize={14}
            color={Colors.primary}
            variant="link"
          >
            {t(`${entry.label} appointment`)}
          </Button>
        );
      }

      if (entry.type === 'link') {
        return (
          <Button as="a" fontSize={14} color={Colors.primary} variant="link">
            {t(`${entry.label} link`)}
          </Button>
        );
      }

      return (
        <Text fontSize="14px" fontWeight="600">
          {entry.value}
        </Text>
      );
    };

    const renderEntries = (entries: Entry[]) => (
      <Box
        overflow="hidden"
        borderRadius={8}
        border={`1px solid ${Colors.mediumShade}`}
        mb={4}
      >
        {entries.map((entry: Entry, index: number) => (
          <Flex
            p={2}
            bg={index % 2 === 0 ? Colors.lightestShade : 'white'}
            key={index.toString()}
            justifyContent="space-between"
            alignItems="center"
            borderBottomWidth={index === entries.length - 1 ? 0 : 1}
            borderBottomColor={`${Colors.mediumShade}`}
          >
            <Text color={Colors.darkestShade} fontSize="14px" fontWeight="600">
              {entry.label}
            </Text>

            {entry.label === 'Status'
              ? renderStatus(entry.value as string, false)
              : renderValue(entry)}
          </Flex>
        ))}
      </Box>
    );

    const gotoProfile = () => {
      window.location.href = `/patient-list/${patient?.uid}`;
    };

    const gotoAppointments = () => {
      window.location.href = `/patient-list/${patient?.uid}/appointments`;
    };

    const upcomingAppointmentsList = upcomingAppointments?.map(
      (ap: Appointment) => [
        {
          label: t('Appointment date'),
          value: moment(ap?.startOn).format('DD MMM YYYY') ?? 'N/A',
        },
        {
          label: t('Appointment time'),
          value: moment(ap?.startOn).format('HH:mm') ?? 'N/A',
        },
        {
          label: t('Event name'),
          value: ap?.event?.name?.split(':')?.[0] ?? 'N/A',
        },
        {
          label: t('Clinic'),
          value: ap?.patient?.clinic?.name ?? 'N/A',
        },
        {
          label: t('Status'),
          value: ap?.status ?? 'N/A',
        },
      ]
    );

    const onCancel = async () => {
      /* const { cleoPatientEventDelete } = */ await cancelAppointment({
        patientEventUids: [uid],
      });

      refreshEvents();
      onClose();
    };

    const getFieldFromMetadataEntries = useCallback(
      () =>
        map(metadataEntries, (metadata) => ({
          id: metadata.uid,
          type: getMetadataFiledType(metadata),
          label: metadata?.parameter?.name,
          value: metadata?.value?.file?.url
            ? `${metadata?.value?.file?.url}?x-session=${authState.token}`
            : metadata?.extractedForDisplay,
        })),
      [authState.token, metadataEntries]
    );

    const renderEventStatus = (ev: EventLog) => {
      switch (toLower(ev?.status)) {
        case 'scheduled':
          return t(
            `Appointment scheduled for ${moment(event?.startOn).format(
              'DD MMM YYYY, HH:mm'
            )}`
          );
        case 'rescheduled':
          return t(
            `Appointment rescheduled to ${moment(event?.startOn).format(
              'DD MMM YYYY, HH:mm'
            )}`
          );
        default:
          return capitalize(ev?.status.split('_').join(' '));
      }
    };

    return (
      <Box pt={6} pl={4} pr={4}>
        <Flex mb={5} justifyContent="space-between">
          <Flex flexDirection="column">
            <Text mt={-1} fontSize="24px" fontWeight="600">
              {patient?.name}
            </Text>
            <Text color="darkestShade" fontSize="14px" fontWeight="400">
              {patient?.phone}
            </Text>
          </Flex>
          <Flex alignItems="center" alignSelf="flex-start">
            <Box cursor="pointer" onClick={gotoAppointments}>
              <Calendar
                width={50}
                height={50}
                color={theme.colors.darkestShade}
                viewBox="0 0 32 34"
              />
            </Box>
            <FAIcon
              onClick={gotoProfile}
              icon={faUserAlt}
              cursor="pointer"
              style={{
                color: theme.colors.darkestShade,
                marginLeft: '20px',
                fontSize: '16px',
              }}
            />
            <FAIcon
              cursor="pointer"
              onClick={onClose}
              icon={faTimes}
              style={{
                color: theme.colors.darkShade,
                marginLeft: '20px',
                fontSize: '16px',
              }}
              ml={2}
            />
          </Flex>
        </Flex>

        {renderEntries([
          { label: 'Identifier', value: patient?.identifier },
          { label: 'Email', value: patient?.email ?? 'N/A' },
          { label: 'Gender', value: patient?.gender ?? 'N/A' },
          {
            label: 'DoB',
            value: patient?.dateOfBirth
              ? moment(patient?.dateOfBirth).format('YYYY-MM-DD')
              : 'N/A',
          },
        ] as Entry[])}

        <Box>
          <Text mt={4} mb={4} fontSize="18px" fontWeight="600">
            Appointment details
          </Text>

          <Accordion defaultIndex={[0]} allowToggle>
            <AccordionItem
              borderRadius={8}
              borderTopWidth={5}
              borderTopColor={statusColors.color}
              borderLeft={`1px solid ${Colors.mediumShade}`}
              borderRight={`1px solid ${Colors.mediumShade}`}
            >
              <AccordionButton
                mt={2}
                _focus={{
                  boxShadow: 'none',
                }}
                _hover={{
                  background: 'transparent',
                }}
              >
                <Flex flex={1} alignItems="center">
                  <Text
                    fontWeight="600"
                    fontSize={14}
                    flex="1"
                    textAlign="left"
                  >
                    {moment(event?.startOn).format('DD MMM YYYY, HH:mm')}
                  </Text>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Flex mt={4} mb={5}>
                  {renderStatus(status, false)}
                </Flex>
                {renderEntries(
                  [
                    ...getFieldFromMetadataEntries(),
                    {
                      label: t('Appointment date'),
                      value:
                        moment(event?.startOn).format('DD MMM YYYY') ?? 'N/A',
                    },
                    {
                      label: t('Appointment time'),
                      value: moment(event?.startOn).format('HH:mm') ?? 'N/A',
                    },
                    {
                      label: t('Event name'),
                      value: event?.name?.split(':')?.[0] ?? 'N/A',
                    },
                    {
                      label: t('Clinic'),
                      value: patient?.clinic?.name ?? 'N/A',
                    },
                    { label: t('Status'), value: status ?? 'N/A' },
                    {
                      label: t('Created on'),
                      value:
                        moment(event?.createdOn).format('DD MMM YYYY, HH:mm') ??
                        'N/A',
                    },
                    !isCancelled &&
                      !isPast && {
                        label: t('Reschedule'),
                        type: 'link',
                        value: rescheduleUrl,
                      },
                    !isCancelled &&
                      !isPast && {
                        label: t('Cancel'),
                        type: 'button',
                        value: onCancel,
                      },
                  ].filter(Boolean) as Entry[]
                )}

                {eventLogs?.length > 0 && (
                  <Accordion defaultIndex={[0]} allowToggle mt={6} pb={4}>
                    <AccordionItem
                      borderRadius={8}
                      border={`1px solid ${Colors.mediumShade}`}
                    >
                      <h2>
                        <AccordionButton
                          _focus={{
                            boxShadow: 'none',
                          }}
                          _hover={{
                            background: 'transparent',
                          }}
                        >
                          <Flex my="16px" flex={1} alignItems="center">
                            <Text
                              fontWeight="600"
                              fontSize={14}
                              textAlign="left"
                            >
                              {t('Event logs')}
                            </Text>
                            <Flex
                              minWidth="24px"
                              height="24px"
                              alignItems="center"
                            >
                              <Badge
                                borderRadius={8}
                                alignItems="center"
                                p="8px"
                                ml={2}
                                mr={2}
                              >
                                {eventLogs.length}
                              </Badge>
                            </Flex>
                          </Flex>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {eventLogs.map((ev: EventLog) => (
                          <Box
                            key={ev?.datetime}
                            borderRadius={8}
                            border={`1px solid ${Colors.mediumShade}`}
                            p={2}
                            mb={3}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Flex flex={4}>
                                <Text
                                  color={Colors.darkestShade}
                                  fontSize="14px"
                                >
                                  {t('Event')}
                                </Text>
                              </Flex>
                              <Flex flex={6} ml={4}>
                                <Text
                                  color={Colors.darkestShade}
                                  fontSize="14px"
                                >
                                  {t('Action by')}
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex justifyContent="space-between">
                              <Flex flex={4}>
                                <Text
                                  color={Colors.fullShade}
                                  fontSize="14px"
                                  fontWeight="600"
                                >
                                  {renderEventStatus(ev)}
                                </Text>
                              </Flex>

                              <Flex flex={6} ml={4}>
                                <Text
                                  color={Colors.fullShade}
                                  fontWeight="600"
                                  fontSize="14px"
                                >
                                  {ev?.clinician?.name
                                    ? `${ev?.clinician?.name}, `
                                    : `${patient?.name || 'BotMD'}, `}

                                  {moment(ev.datetime).format(
                                    'DD MMM YYYY, HH:mm'
                                  )}
                                </Text>
                              </Flex>
                            </Flex>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        {upcomingAppointmentsList?.length > 0 && (
          <Box mt={4}>
            <Flex flex={1} alignItems="center">
              <Flex flex={1} alignItems="center">
                <Text
                  mt={2}
                  mb={4}
                  fontWeight="600"
                  fontSize={18}
                  flex="1"
                  textAlign="left"
                >
                  {t('Upcoming appointments')}

                  <Badge p="8px" borderRadius={8} ml={2} mr={2}>
                    {upcomingAppointmentsList.length}
                  </Badge>
                </Text>
              </Flex>
              <Box
                onClick={() => {
                  window.location.href = `/patient-list/${patient?.uid}/appointments`;
                }}
              >
                <Text
                  cursor="pointer"
                  mt={2}
                  mb={4}
                  color="primary"
                  fontWeight="600"
                  fontSize={14}
                >
                  {t('View all')}
                </Text>
              </Box>
            </Flex>
            {upcomingAppointmentsList.map((apEntry: Entry[], index: number) => {
              const apStatus = toLower(
                (apEntry.find((e: Entry) => e.label === 'Status')
                  ?.value as string) ?? ''
              );
              apEntry.pop();
              const apEntryStatusColors = getStatusColor(apStatus, t);
              return (
                <Accordion
                  key={index.toString()}
                  mb={5}
                  defaultIndex={[0]}
                  allowToggle
                >
                  <AccordionItem
                    borderRadius={8}
                    borderTopWidth={5}
                    borderTopColor={apEntryStatusColors.color}
                    borderLeft={`1px solid ${Colors.mediumShade}`}
                    borderRight={`1px solid ${Colors.mediumShade}`}
                  >
                    <AccordionButton
                      mt={2}
                      _focus={{
                        boxShadow: 'none',
                      }}
                      _hover={{
                        background: 'transparent',
                      }}
                    >
                      <Flex flex={1} alignItems="center">
                        <Text
                          fontWeight="600"
                          fontSize={14}
                          flex={1}
                          textAlign="left"
                        >
                          {
                            apEntry.find(
                              (e: Entry) => e.label === 'Appointment date'
                            )?.value
                          }
                          ,{' '}
                          {
                            apEntry.find(
                              (e: Entry) => e.label === 'Appointment time'
                            )?.value
                          }
                        </Text>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Flex mt={4} mb={5}>
                        {renderStatus(apStatus, false)}
                      </Flex>
                      <Box mb={2}>{renderEntries(apEntry)}</Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </Box>
        )}
      </Box>
    );
  }
);
