import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientGroupCreate($input: CreateCleoPatientGroupInput!) {
    cleoPatientGroupCreate(input: $input) {
      cleoPatientGroup {
        uid
        name
        description
        patients {
          edges {
            node {
              uid
              name
            }
          }
        }
      }
    }
  }
`;

const usePatientGroupsCreate = () => (args) => {
  const patientGroupsCreate = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'mutation',
    baseQueryKey: 'patientsGroupsCreate',
    select: ({ cleoPatientGroup }) => cleoPatientGroup,
  });
  return patientGroupsCreate(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientGroupsCreate();
