import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientGroupUpdate($input: UpdateCleoPatientGroupInput!) {
    cleoPatientGroupUpdate(input: $input) {
      cleoPatientGroup {
        uid
        name
        description
        patients {
          edges {
            node {
              uid
              name
            }
          }
        }
      }
    }
  }
`;

const usePatientGroupsUpdate = () => (args) => {
  const patientGroupsUpdate = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'mutation',
    baseQueryKey: 'patientsGroupsUpdate',
    select: ({ cleoPatientGroup }) => cleoPatientGroup,
  });
  return patientGroupsUpdate(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientGroupsUpdate();
