import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { mapSubmission } from './useSubmissions';

export const cleoSubmissionFragment = `
  uid
  submittedOn
  isAlertsTriggered
  isAlertsResolved
  lastSeenByPatientOn
  lastSeenOn
  triggeredAlerts {
    uid
    rule {
      uid
      name
    }
    createdOn
    alertReasons {
      reason
      submissionValue {
        uid
        value
      }
    }
    resolvingComment {
      uid
      clinician {
        uid
        name
        designation
      }
      content
      createdOn
    }
  }
  lastSeens{
    uid
    clinician {
      uid
      name
    }
    seenOn
    id
  }
  comments {
    uid
    clinician {
      name
      designation
    }
    content
    createdOn
    isSeen
    seenByClinicians {
      uid
    }
    taggedClinicians{
      uid
      name
      isCaregiver
    }
    visibleTo
  }
  values {
    uid
    parameter {
      uid
      groupName
      unit
      name
      valueType
      sortOrder
      unit
      numberPrecision
    }
    value
    valueType
    extracted
    extractedForDisplay
  }
  patientForm {
    uid
    isDischarged
    patient {
      uid
      name
      phone
      ics {
        uid
        name
      }
    }
  }
`;

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmission($uid: String) {
    cleoSubmission(uid: $uid) {
      ${cleoSubmissionFragment}
    }
  }
`;

const useSubmission = () => (args) => {
  const submission = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'submission',
    paginate: false,
    keepPreviousData: true,
    select: ({ cleoSubmission }) => mapSubmission(cleoSubmission),
  });

  return submission(args);
};

// TODO: React Hook "useSubmission" cannot be called at the top level.
/* eslint-disable react-hooks/rules-of-hooks */
export default useSubmission();
