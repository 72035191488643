import { createQuery } from '@fivehealth/botero';
import { gql } from 'graphql-request';
import { buildPaginatedResponse } from 'lib/pagination';
import { get } from 'lodash';

export const GRAPHQL_DOCUMENT = gql`
  query cleoPatientPrograms($patientIds: [String]) {
    cleoPatientPrograms(patient_Uid_In: $patientIds) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          createdOn
          isActivated
          isDischarged
          isEnrolled
          isDeactivated
          deactivatedOn
          reminders {
            uid
            name
            effectiveName
          }
          clinicProgram {
            uid
            name
            reminders {
              name
              uid
            }
          }
          effectiveReminders {
            uid
            name
            isForClinic
            settings
            description
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientPrograms',
  paginate: 'offset',
  getNextPageParam: ({ cleoPatientPrograms: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
    if (!hasNextPage) {
      return undefined;
    }
    const currentOffset = allPages.flatMap(({ cleoPatientPrograms }) =>
      get(cleoPatientPrograms, 'edges', [])
    ).length;
    return currentOffset;
  },
  select: (data) => buildPaginatedResponse(data, 'cleoPatientPrograms'),
  keepPreviousData: true,
});
