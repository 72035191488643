import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoRoles {
    cleoRoles {
      edges {
        node {
          uid
          name
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'cleoRoles',
  keepPreviousData: true,
  select: ({ cleoRoles }) =>
    get(cleoRoles, 'edges', []).map(({ node }) => node),
});
