import React from 'react';
import { Box, Flex, Text, FAIcon } from '@fivehealth/botero';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

const ErrorBanner = ({ text, ...props }) => (
  <Box
    bg="rgba(224, 81, 56, 0.10)"
    borderRadius="8px"
    style={{ boxSizing: 'border-box' }}
    py={1}
    px={2}
    minHeight={40}
    width="100%"
    {...props}
  >
    <Flex alignItems="center">
      <Box mr={1}>
        <FAIcon icon={faExclamationTriangle} color="danger" fontSize="15px" />
      </Box>
      <Flex>
        <Text color="danger" fontSize={14}>
          {text}
        </Text>
      </Flex>
    </Flex>
  </Box>
);

export default ErrorBanner;
