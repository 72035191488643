import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

import { cleoAlertRuleFragment } from './useAlertRuleCreate';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoAlertRuleUpdate($input: UpdateCleoAlertRuleInput!) {
    cleoAlertRuleUpdate(input: $input) {
      cleoAlertRule {
        ${cleoAlertRuleFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'alertRule',
  keepPreviousData: true,
});
