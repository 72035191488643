import { BoxContent } from 'components/Table/Table';
import React from 'react';

const TableCell = ({ children, cursor, ...props }) => {
  const { cell, onShowSubmission, style } = props;

  return (
    <BoxContent
      style={style}
      cursor={cursor || 'pointer'}
      onClick={() =>
        onShowSubmission(
          cell.row.original.hasSubmission
            ? cell.row.original.lastSubmission.uid
            : cell.row.original.uid,
          cell.row.original
        )
      }
    >
      {children}
    </BoxContent>
  );
};

export default TableCell;
