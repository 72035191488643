import React, { useState } from 'react';
import {
  Box,
  H2,
  PrimaryButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  theme,
} from '@fivehealth/botero';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import styled, { css } from 'styled-components';

const StyledBorder = styled(Flex)`
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border 0.1s linear;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      border-color: ${theme.colors.primary};
    `}
  &:hover {
    border-color: ${theme.colors.primary};
  }
`;

const AllAvatarImages = [
  { id: 'AVATAR_BLUE_MALE', src: '/avatar_blue_male.svg' },
  { id: 'AVATAR_YELLOW_MALE', src: '/avatar_yellow_male.svg' },
  { id: 'AVATAR_PINK_MALE', src: '/avatar_pink_male.svg' },
  { id: 'AVATAR_GREY_MALE', src: '/avatar_grey_male.svg' },
  { id: 'AVATAR_BLUE_FEMALE', src: '/avatar_blue_female.svg' },
  { id: 'AVATAR_YELLOW_FEMALE', src: '/avatar_yellow_female.svg' },
  { id: 'AVATAR_PINK_FEMALE', src: '/avatar_pink_female.svg' },
  { id: 'AVATAR_GREY_FEMALE', src: '/avatar_grey_female.svg' },
];

const AvatarImage = ({ active, ...props }) => (
  <StyledBorder mx={1} mb={2} active={active} position="relative">
    {active && (
      <Flex
        position="absolute"
        top={1}
        right={-12}
        bg="white"
        borderRadius="50%"
        border="2px solid"
        borderColor="white"
      >
        <FAIcon icon={faCheckCircle} color="primary" />
      </Flex>
    )}
    <Box
      border="4px solid"
      borderColor="white"
      borderRadius="50%"
      as="img"
      {...props}
    />
  </StyledBorder>
);

const ChangeProfilePicture = ({ user, closeModal, isUpdating, onSubmit }) => {
  const { t } = useTranslation();

  // Uppercase to convert into gql enum for Avatar
  const [selectedAvatar, setSelectedAvatar] = useState(
    user ? _.toUpper(user.metadata.avatar) : null
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{t('Change profile picture')}</H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Flex mt={3} mx={-1} flexWrap="wrap">
        {_.map(AllAvatarImages, (avatar, index) => (
          <AvatarImage
            key={index}
            onClick={() => setSelectedAvatar(avatar.id)}
            active={_.isEqual(selectedAvatar, avatar.id)}
            src={avatar.src}
          />
        ))}
      </Flex>
      <Flex mt={4} justifyContent="flex-end" alignItems="center">
        <SecondaryOutlinedButton mr={3} onClick={closeModal}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={isUpdating}
          onClick={() => onSubmit('Avatar', selectedAvatar)}
        >
          {isUpdating ? `${t('Updating')}...` : t('Update avatar')}
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default ChangeProfilePicture;
