import React from 'react';
import { Box, FAIcon } from '@fivehealth/botero';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

export default ({ status, ...props }) => {
  switch (status) {
    case 'warning': {
      return (
        <Box {...props}>
          <FAIcon icon={faExclamationTriangle} color="danger" fontSize={18} />
        </Box>
      );
    }
    case 'danger': {
      return (
        <Box width={12} height={12} borderRadius={6} bg="danger" {...props} />
      );
    }
    case 'outOfRange': {
      return (
        <Box width={12} height={12} borderRadius={6} bg="warning" {...props} />
      );
    }
    case 'stable':
    case 'normal':
    case 'success': {
      return (
        <Box width={12} height={12} borderRadius={6} bg="success" {...props} />
      );
    }
    default: {
      return (
        <Box width={12} height={12} {...props}>
          <FAIcon icon={faExclamationTriangle} color="danger" />
        </Box>
      );
    }
  }
};
