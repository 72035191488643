import { Text } from '@chakra-ui/react';
import { Box, Flex, H4, theme } from '@fivehealth/botero';
import { isArray } from 'lodash';
import React from 'react';

/**
 * Horizontal box divider for the layout.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxDivider = ({ ...props }) => (
  <Box
    borderBottom={`1px solid ${theme.colors.mediumShade}`}
    pt={2}
    {...props}
  />
);

/**
 * Box pair layout.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxPairLayout = ({ children, boxLeftProps }) => (
  <Box mt={[0, 2]} fontSize={14}>
    <Flex mb={[4, 3]} flexDirection={['column', 'row']} fontSize={14}>
      <Flex width={[300, 280]} pt={4} pr={1} pb={2} {...boxLeftProps}>
        {children[0]}
      </Flex>

      <Flex
        flexDirection={['column']}
        minWidth={300}
        maxWidth={720}
        ml={[0, 7]}
        flex={1}
        mt={[0, 0]}
      >
        {children[1] && children[1].length > 0
          ? children[1].map((o) => o)
          : children[1]}
      </Flex>
    </Flex>
  </Box>
);

/**
 * Box pair group layout left panel.
 * @param headerTitle
 * @param headerDesc
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxPairLeftPanel = ({ headerTitle, headerDesc, children }) =>
  !children && (
    <Box>
      <H4 fontSize={16} fontWeight={600}>
        {headerTitle}
      </H4>
      <Text
        fontWeight={400}
        color="darkestShade"
        fontSize={16}
        width={[350, 200]}
        mt="8px"
      >
        {headerDesc}
      </Text>
    </Box>
  );

/**
 * Box pair group layout right panel.
 * @param headerLeft
 * @param headerRight
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxPairRightPanel = ({
  headerLeft,
  headerRight,
  children,
  boxProps,
  headerLeftProps,
  headerRightProps,
}) => (
  <Box
    mt={[2, 3]}
    border={`1px solid ${theme.colors.mediumShade}`}
    borderRadius={8}
    p={0}
    {...boxProps}
  >
    <Box px={2} pt={2}>
      <Flex mb={[2, 2]} flexDirection={['column', 'row']}>
        <Flex flex={2} fontSize={16} fontWeight={600} {...headerLeftProps}>
          {headerLeft?.type ? (
            headerLeft
          ) : (
            <H4 fontSize={16} fontWeight={600}>
              {headerLeft}
            </H4>
          )}
        </Flex>
        <Flex justifyContent="end" {...headerRightProps}>
          {headerRight}
        </Flex>
      </Flex>
      {children}
    </Box>
  </Box>
);

/**
 * Box pair group layout for the content labels and values
 * @param data
 * @param children - Optional component. Default is null.
 * @returns {JSX.Element}
 * @constructor
 */
export const BoxPairLabelValues = ({ data, children = null }) => {
  const renderValues = (rowVal) => {
    const { value } = rowVal;
    return isArray(value) ? (
      <Flex flexDirection={['column']}>
        {value.map((val, ind) => (
          <Text
            key={ind}
            wordBreak="break-word"
            textAlign="end"
            mb={value.length - 1 === ind ? 0 : 1}
          >
            {val}
          </Text>
        ))}
      </Flex>
    ) : (
      <Box>
        <Text wordBreak="break-word" textAlign="end" width={[300, 400]}>
          {value}
        </Text>
      </Box>
    );
  };

  return (
    <>
      {data &&
        data.map((row, index) => (
          <Flex key={`row-${index}`} mb={[2, 2]} flexDirection={['row']}>
            <>
              <Flex flex={2} maxWidth={[180, 400]} justifyContent="start">
                <Text fontWeight={500} color="darkestShade">
                  {row.label}
                </Text>
              </Flex>
              <Flex
                flex={1}
                fontWeight={[500, 400]}
                color="fullShade"
                justifyContent="end"
                maxWidth={[300, 400]}
              >
                {renderValues(row)}
              </Flex>
            </>
          </Flex>
        ))}
      <Box pb={2}>{children}</Box>
    </>
  );
};
