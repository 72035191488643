import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicCalendars($uidIn: [String]) {
    cleoClinicCalendars(eventTypes_Uid_In: $uidIn, deactivatedOn_Isnull: true) {
      edges {
        node {
          uid
          name
          calendarUrl
          eventTypes {
            effectiveEventUrl
            eventType {
              uid
              name
            }
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinicCalendars',
  keepPreviousData: true,
  select: ({ cleoClinicCalendars }) =>
    cleoClinicCalendars?.edges?.map(({ node }) => node),
});
