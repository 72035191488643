import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoClinicianReassignment($input: CleoClinicianReassignmentInput!) {
    cleoClinicianReassignment(input: $input) {
      alerteeRelationCount
      icRelationCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'clinicianReassignments',
  keepPreviousData: true,
});
