// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const Check = memo<IconProps>(({ color, width = 24, height = 24 }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8379 0.625C12.5488 0.625 13.1504 1.22656 13.1504 1.9375V11.5625C13.1504 12.3008 12.5488 12.875 11.8379 12.875H2.21289C1.47461 12.875 0.900391 12.3008 0.900391 11.5625V1.9375C0.900391 1.22656 1.47461 0.625 2.21289 0.625H11.8379ZM11.8379 11.5625V1.9375H2.21289V11.5625H11.8379ZM10.8535 4.97266L6.12305 9.64844C5.98633 9.78516 5.79492 9.78516 5.6582 9.64844L3.16992 7.13281C3.06055 7.02344 3.06055 6.80469 3.16992 6.66797L3.79883 6.06641C3.93555 5.92969 4.12695 5.92969 4.26367 6.06641L5.9043 7.70703L9.75977 3.87891C9.89648 3.74219 10.1152 3.74219 10.2246 3.87891L10.8535 4.50781C10.9629 4.64453 10.9629 4.83594 10.8535 4.97266Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default Check;
