import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
  Box,
  FAIcon,
  InputField,
  useDebounce,
  theme,
  Flex,
  Text,
} from '@fivehealth/botero';
import styled, { keyframes } from 'styled-components';
import { faRedo, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { tweakPageName } from 'AppUtils';
import { useTranslation } from 'react-i18next';
import EVENTS from '../../constants/events';

const RefreshButton = styled(Flex)`
  &:hover {
    opacity: 0.7;
  }
`;
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerIcon = styled(FAIcon)`
  animation: ${(props) => (props.spin ? rotate360 : '')} 1s linear infinite;
  transform: translateZ(0);
`;

// TODO: Move to component folder
const SearchBar = ({
  value: initialValue = '',
  placeholder,
  onTextChange,
  onSearch,
  onRefresh,
  isRefreshing,
  lastUpdated,
  ...props
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [active, setActive] = useState(false);
  const debouncedValue = useDebounce(value, 600);
  const location = useLocation();

  const pageName = location.pathname.split('/')[1];

  const onChange = ({ target }) => {
    setValue(target.value);
    onTextChange(target.value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <Box flex={[0.95, null]}>
      <InputField
        value={value}
        width={['100%', 400]}
        color="fullShade"
        fontSize={[14, 17]}
        logEventProps={{
          page: tweakPageName(pageName),
          eventName: EVENTS.SEARCH_PATIENTS,
          term: value,
        }}
        onChange={onChange}
        startIcon={<FAIcon icon={faSearch} fontSize={16} color="fullShade" />}
        endIcon={
          value && (
            <FAIcon
              icon={faTimes}
              fontSize={14}
              style={{ cursor: 'pointer' }}
              onClick={() => onChange({ target: { value: '' } })}
            />
          )
        }
        style={{
          outline: 'none',
          borderColor: active ? theme.colors.primary : null,
        }}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        placeholder={placeholder || t('Search patients')}
        {...props}
      />
      {onRefresh && (
        <RefreshButton
          logEventProps={{
            page: tweakPageName(pageName),
            eventName: EVENTS.REFRESH_RESULTS,
          }}
          py={2}
          ml="12px"
          onClick={onRefresh}
          cursor="pointer"
        >
          <SpinnerIcon
            spin={isRefreshing}
            icon={faRedo}
            fontSize={14}
            color="darkestShade"
          />
          <Text ml={1} fontSize={14} color="darkestShade">
            {t('Last updated')} {format(lastUpdated, 'dd-MM-yyyy HH:mm:ss')}
          </Text>
        </RefreshButton>
      )}
    </Box>
  );
};
SearchBar.defaultProps = {
  onTextChange: () => {},
};

export default SearchBar;
