import React from 'react';

import { Label, Radio } from '@fivehealth/botero';

const OptionLabel = (props) => (
  <Label pb="10px" fontSize="14px" color="#111824" {...props} />
);

const RadioOption = ({ id, label, value, checked, onChange, disabled }) => (
  <OptionLabel display="flex" color={disabled ? 'gray' : null}>
    <Radio
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      name="download"
      id={id}
      value={value}
    />
    {label}
  </OptionLabel>
);

export default React.memo(RadioOption);
