import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_PATIENT_EVENTS_DELETE = gql`
  mutation cleoPatientEventDeleteAsync(
    $input: CleoPatientEventDeleteAsyncInput!
  ) {
    cleoPatientEventDeleteAsync(input: $input) {
      asyncJob {
        uid
        status
      }
      results {
        uid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_PATIENT_EVENTS_DELETE,
  queryType: 'mutation',
  baseQueryKey: 'patientEventDeleteAsync',
});
