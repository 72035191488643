import React, { useCallback, useEffect, useState } from 'react';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { get, isEmpty, map } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import {
  Flex,
  Box,
  Text,
  Dialog,
  SecondaryOutlinedButton,
  PrimaryButton,
  theme,
  Body,
  InputField,
  SearchInput,
} from '@fivehealth/botero';

import { EventSubSource } from 'constants';
import { checkPermissions } from 'AppUtils';

import Sort from 'components/Sort/Sort';
// import Filter from 'components/Filter/Filter';
import Markdown from 'components/Markdown/Markdown';
import { LoadMoreButton } from 'components/Table/Table';
import SettingsActionMenu from 'components/ActionMenuPopper/SettingsActionMenu';

import { Filters } from 'components/Filters/Filters';
import { RetryStatusCodes } from 'constants/retryStatusCodes';
import { RetryView } from 'views/RetryView/RetryView';
import SubmissionTable from './SubmissionTable';
import { SubmissionBoxWidget } from './Sidepanel';

const AlertSection = ({
  // props
  openDrawer,
  onShowSubmission,
  onShowPatientForm,
  onSendMessage,
  onCommentCreated,
  onDownloadPatientSubmissions,
  tableSettings,
  tableSettingsSinglePatient,
  clinic,
  clinician,

  // tab with filter
  t,
  lastUpdated,
  unresolvedSubmissions: submissions,
  requestParams,
  isLoading,
  isRefetching,
  hasNextPage,
  fetchNextPage,
  isFetched,
  isFetchingNextPage,
  clinicalUserFilters,
  monitoringFormsetsFilters,
  onSearch,
  onRefresh,
  onFetchData,
  // onFilterOpen,
  // onFilterCancel,
  onApplyFilters,
  // onResetFilters,
  onMarkAllAsResolved,
  alertsCount,
  searchText,
  setSearchText,
  totalFetchDataCount,
  pageCount,
  statusCode,
} = {}) => {
  const { state: locationState } = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const isResponsive = useMediaQuery({ query: '(max-width: 1002px)' });
  const [openPrompt, setOpenPrompt] = useState(false);
  const [bulkResolveMessage, setBulkResolveMessage] = useState('');
  const hasPermission = checkPermissions(clinician, ['mutate_clinicians']);

  const tableData = map(submissions, (submission) => {
    const { patient: { deactivatedOn } = {} } = submission;
    return {
      ...submission,
      deactivatedOn,
      ics: submission?.patientForm?.ics,
      alertees: submission?.patientForm?.alertees,
      patientForm: {
        ...submission?.patientForm,
        patient: {
          ...submission?.patient,
          ...submission?.patientForm?.patient,
        },
      },
    };
  });

  const filterOptions = [clinicalUserFilters, monitoringFormsetsFilters].filter(
    Boolean
  );

  // NOTE: for now we can use static menu items
  const settingsMenuOptions = [
    {
      label: 'Mark all as resolved',
      disabled: alertsCount <= 0,
      divider: false,
      action: () => {
        setOpenPrompt(true);
      },
    },
  ];

  useEffect(() => {
    const patientState = get(locationState, 'patient');
    if (patientState) {
      onShowSubmission(patientState?.uid, patientState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);
  const promptMsg = {
    firstParagraph: t('Mark all as resolved dialog message paragraph 1', {
      markAsResolvedCount: alertsCount,
      plural: alertsCount === 1 ? '' : 's',
    }),
    secondParagraph: t(
      'Your name will be attached to all the resolved alerts. Please include a comment below for audit purposes'
    ),
  };

  const onFilterChange = useCallback(
    (filters) => {
      onApplyFilters?.(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (statusCode && RetryStatusCodes.includes(statusCode)) {
    return (
      <RetryView onRefresh={onRefresh} isLoading={isLoading || isRefetching} />
    );
  }

  return (
    <>
      <Dialog
        open={openPrompt}
        onClose={setOpenPrompt}
        type="prompt"
        title={t('Are you sure?')}
        isLoading={isLoading}
        renderFooter={() => (
          <Flex my={2} justifyContent="flex-end">
            <SecondaryOutlinedButton
              disabled={isLoading}
              onClick={() => {
                setOpenPrompt(false);
              }}
              mr={2}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              type="submit"
              disabled={isLoading || bulkResolveMessage === ''}
              onClick={() => {
                onMarkAllAsResolved(bulkResolveMessage);
                setOpenPrompt(false);
              }}
              style={{ backgroundColor: theme.colors.danger }}
            >
              {t('Resolve alerts')}
            </PrimaryButton>
          </Flex>
        )}
      >
        <Body width={[300, 530]}>
          <Markdown>{promptMsg.firstParagraph}</Markdown>
          <Markdown style={{ paddingTop: 14 }}>
            {promptMsg.secondParagraph}.
          </Markdown>
          {clinician && (
            <InputField
              as="textarea"
              rows={2}
              placeholder={t(
                `e.g. Alert resolved, no issues observed by ${
                  clinician?.name?.split(' ')[0]
                }`
              )}
              width="100%"
              disabled={isLoading}
              onChange={({ target }) => {
                setBulkResolveMessage(target?.value);
              }}
            />
          )}
        </Body>
      </Dialog>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <SearchInput
          inputStyle={{
            paddingBottom: '6px',
            paddingTop: '8px',
          }}
          containerProps={{
            flex: [0.95, null],
          }}
          hideRefresh={isMobile}
          data-testid="patient_monitoring_unresolved_alerts_search_input"
          placeholder={t('Search patients')}
          inputMinWidth={isMobile ? '100%' : '400px'}
          onSearch={onSearch}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onRefresh={onRefresh}
          isRefreshing={isLoading || isRefetching}
          lastUpdated={lastUpdated.current}
          totalCount={totalFetchDataCount}
          pageCount={submissions?.length || pageCount}
          refreshButtonProps={{
            style: {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
          }}
        />

        {clinician && !clinician.isCaregiver && (
          <Box
            display="block"
            mr={[alertsCount > 0 ? -52 : 'auto', 0]}
            width={[alertsCount > 0 ? 90 : 'auto', 'inherit']}
          >
            <Flex direction="row" justifyContent="space-between">
              {hasPermission && (
                <SettingsActionMenu
                  options={settingsMenuOptions}
                  isMobile={isResponsive}
                  buttonLabel={t('Actions')}
                  buttonLabelIcon={faCog}
                  buttonProps={{ width: [46, 'auto'] }}
                />
              )}

              {/* {!isMobile && (
                <Filter
                  activeFilters={requestParams}
                  minWidth={600}
                  filterOptions={filterOptions}
                  open={showFilters}
                  onOpen={onFilterOpen}
                  onResetFilters={onResetFilters}
                  onSave={onApplyFilters}
                  onCancel={onFilterCancel}
                  showPatientFilter
                />
              )} */}
            </Flex>
          </Box>
        )}
        <Box display={['block', 'block', 'none']}>
          <Sort
            onChange={(opt) => onFetchData({ sortBy: [opt] })}
            value={{
              orderField: requestParams?.orderField,
              orderDesc: requestParams?.orderDesc,
            }}
          />
        </Box>
      </Flex>

      <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
        <Filters
          requestParams={requestParams}
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
        />
      </Box>

      <Box display={['none', 'none', 'block']}>
        <SubmissionTable
          clinic={clinic}
          clinician={clinician}
          tableSettings={tableSettings}
          tableSettingsSinglePatient={tableSettingsSinglePatient}
          showLoading={isLoading}
          data={tableData}
          onOpenDrawer={openDrawer}
          onShowSubmission={onShowSubmission}
          onShowPatientForm={(submission, path) =>
            onShowPatientForm(submission.patientForm, path)
          }
          onSendMessage={(submission) => onSendMessage(submission?.patientForm)}
          onFetchData={onFetchData}
          onDownloadSubmissions={onDownloadPatientSubmissions}
          showDischargedLabel
          onFetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetched={isFetched}
          isFetchingNextPage={isFetchingNextPage}
          sortMapping={{
            SUBMITTED_ON: 'SUBMITTED_ON',
          }}
          initialSortBy={{
            orderField: requestParams?.orderField,
            orderDesc: requestParams?.orderDesc,
          }}
          eventSubSource={EventSubSource.Alerts}
        />
      </Box>
      <Box display={['block', 'block', 'none']}>
        {tableData.map((submission) => (
          <SubmissionBoxWidget
            clinician={clinician}
            isMobile
            key={submission.uid}
            patient={submission.patient}
            cellData={submission}
            submission={submission}
            variant={submission.statusColor}
            onCommentCreated={onCommentCreated}
            onShowPatientForm={(sub, path) =>
              onShowPatientForm(sub.patientForm, path)
            }
            onShowSubmission={onShowSubmission}
            onSendMessage={onSendMessage}
            showSidePanel
            showActionMenu
            showDischargedLabel={!!submission?.patientForm?.isDischarged}
            onDownloadSubmissions={onDownloadPatientSubmissions}
          />
        ))}
        {isEmpty(tableData) && isFetched && (
          <Text fontSize={14} color="darkestShade" textAlign="center">
            {t('No data available')}.
          </Text>
        )}
        {hasNextPage && (
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            mt={3}
            mb={3}
          >
            <LoadMoreButton
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
              flex={1}
              style={{ height: 50 }}
            >
              <Text color="darkestShade" fontSize={16} fontWeight={600}>
                {!isFetchingNextPage && t('Load more')}
                {isFetchingNextPage && `${t('Loading')}...`}
              </Text>
            </LoadMoreButton>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default React.memo(AlertSection);
