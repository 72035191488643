import { theme } from '@fivehealth/botero';

// eslint-disable-next-line
export const CLINICAL_DESIGNATIONS = [
  'Assistant Nurse Clinician',
  'Associate Consultant',
  'Chief Nurse',
  'Consultant',
  'Medical Officer',
  'House Officer',
  'Registrar',
  'Senior Consultant',
  'Executive',
  'Manager',
  'Nurse Clinician',
  'Nurse Manager',
  'Pharmacist',
  'Principal Pharmacist',
  'Resident Physician',
  'Senior Associate ',
  'Senior Clinical Pharmacist ',
  'Senior Consultant',
  'Senior Executive',
  'Senior Manager',
  'Senior Nurse Clinician',
  'Senior Nurse Manager',
  'Senior Pharmacist',
  'Care Coordinatior ',
  'Senior Care Coordinator',
  'Senior Resident Physician',
  'Senior Staff Nurse',
  'Senior Staff Registrar',
  'Staff Nurse',
];

export const NEW_UPDATE_VIEWED_KEY = 'NEW_UPDATE_VIEWED_KEY';

export const ChartTypes = {
  Scatter: 'scatter',
  SingleLine: 'singleLine',
  MultiLine: 'multiLine',
};

export const CleoAuthE2EInput = {
  providerApplicationUid: 'cleo-e2e',
  providerInput: {
    access_token: process.env.E2E_PHONE,
    token: process.env.JWTE2E,
  },
  applicationInput: {
    domain: 'alexandra',
  },
};

export const DataType = {
  PatientForm: 'patientForm',
  Submission: 'submission',
  Clinician: 'clinician',
  Patient: 'patient',
  PatientGroup: 'patientGroup',
  SubmissionUnresolved: 'submissionUnresolved',
  Null: null,
};

export const COMMENT_VISIBILITY = {
  private: 'ICS_AND_ALERTEES',
  public: 'EVERYBODY',
};

export const EXPORT_DATA_TYPE = {
  submissions: 'submissions',
  graphs: 'graph',
  tables: 'table',
  patients: 'patients',
  messages: 'messages',
};

export const EXPORT_DATA_DESTINATION = {
  download: 'download',
  email: 'email',
};

export const ActionMenuType = {
  Active: 'active',
  Discharge: 'discharge',
};

export const EventPage = {
  PatientList: 'Patient List',
  PatientMonitoring: 'Patient Monitoring',
  ClinicalAdmin: 'Clinical Admin',
  Caregiver: 'Caregiver',
  patientGroups: 'patientGroups',
};

export const EventSubSource = {
  // Patient List
  Active: 'Active',
  Discharge: 'Discharge',

  // Patient Monitoring
  All: 'All',
  Alerts: 'Alerts',
  NonCompliant: 'Non-Compliant',
  Unread: 'Unread',

  // Clinical Admin
  ClinicalAdmin: 'ClinicalAdmin',

  // Caregiver
  Caregiver: 'Caregiver',
};

export const Colors = {
  red: '#E05138',
  lightBlue: '#B3E7FF',
  primary: theme.primary,
  white: 'white',
};
