/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  map,
  get,
  first,
  filter as _filter,
  intersection,
  has,
  mapValues,
  omit,
  keys,
  chain,
  union,
  isEmpty,
  isEqual,
  isArray,
  clone,
  toLower,
  List,
} from 'lodash';

import { H5 } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { tweakPageName } from 'AppUtils';
import EVENTS from 'constants/events';
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { GqlQueryParam } from 'components/Tab/TabWithFilter';
import { FilterButton } from 'components/Filters/FilterButton';
import Colors from 'constants/colors';
import SearchIcon from 'components/icons/SearchIcon';
import { FilterOption, FilterOptionData } from 'components/Filters/Filters';
import { FilterSection } from './FilterSection';

export interface FilterNewProps {
  filters: GqlQueryParam;
  searchEnabled?: boolean;
  onFilterChange?: (filterState: any) => void;
  onResetFilters?: () => void;
  onSave: (filterState: any) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onCancel?: () => void;
  filterOption: FilterOption;
  activeFilters: any;
}

export interface FilterNewHandles {
  getFilters: () => any;
  getSelectedFiltersCount: () => number;
  resetFiltersCount: () => void;
  resetFilters: () => void;
}

export const FilterNew = forwardRef<FilterNewHandles, FilterNewProps>(
  (
    {
      filters,
      searchEnabled = false,
      onFilterChange,
      onResetFilters,
      onSave,
      filterOption,
      activeFilters: defaultFilters,
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { onToggle, onClose, isOpen, onOpen } = useDisclosure();
    const selectedFiltersCount = useRef<number | null | undefined>();
    const location = useLocation();
    const { t } = useTranslation();
    const [filterState, setFilterState] = useState(defaultFilters);

    const [selectedOptions, setSelectedOptions] = useState<FilterOptionData[]>(
      []
    );

    const getOptionParamsKey = (option: any) => {
      if (option?.toParams) {
        return keys(option?.toParams);
      }
      return [];
    };

    const getSelectedFiltersCount = useCallback(
      (nextFilterState: any) => {
        const count = getOptionParamsKey(first(filterOption.data)).flatMap(
          (paramKey) => get(nextFilterState, paramKey, [])
        ).length;
        if (filterOption?.multiSelect) {
          return count;
        }
        // This is to handle the case where the filter is not multi-select but has multiple options inside the toParams
        return count > 0 ? 1 : 0;
      },
      [filterOption]
    );

    const hasFiltersSelected = !chain(
      getOptionParamsKey(first(filterOption.data))
    )
      .flatMap((paramKey) => get(filterState, paramKey, []))
      .isEmpty()
      .value();

    const onHandleSave = useCallback(
      (nextFilterState: any) => {
        onSave(nextFilterState);
        selectedFiltersCount.current = getSelectedFiltersCount(nextFilterState);
      },
      [onSave, getSelectedFiltersCount]
    );

    const onHandleReset = () => {
      setSelectedOptions([]);
      setFilterState({});
      onFilterChange?.({});
      onResetFilters?.();
      const nextFilterState = omit(
        filters,
        chain(filterOption.data).first().get('toParams', {}).keys().value()
      );
      onHandleSave(nextFilterState);
      selectedFiltersCount.current = 0;
    };

    useImperativeHandle(ref, () => ({
      getFilters: () => clone(filterState),
      getSelectedFiltersCount: () => {
        if (!selectedFiltersCount.current) {
          return 0;
        }
        return selectedFiltersCount.current;
      },
      resetFiltersCount: () => {
        selectedFiltersCount.current = undefined;
      },
      resetFilters: () => {
        onHandleReset();
      },
    }));

    const onHandleFilterChange = useCallback(
      (filter: FilterOption, data: any, label: string) => {
        let selectedFilterState: any = {};

        let nextFilterState = {
          ...filters,
          ...filterState,
        };

        const selectedFilters: any = filter?.multiSelect
          ? _filter(filter?.data, ({ id }: { id: string }) =>
              get(data, id, false)
            )
          : _filter(
              filter?.data,
              ({ id }: { id: string }) =>
                isEqual(id, label) && get(data, id, false)
            );

        if (!isEmpty(selectedFilters)) {
          map(selectedFilters, ({ toParams }) =>
            mapValues(toParams, (value, key) => {
              if (has(selectedFilterState, key)) {
                selectedFilterState = {
                  ...selectedFilterState,
                  [key]: union(selectedFilterState[key], value),
                };
              } else {
                selectedFilterState = {
                  ...selectedFilterState,
                  [key]: value,
                };
              }
            })
          );

          if (filterOption.multiSelect) {
            nextFilterState = {
              ...nextFilterState,
              ...selectedFilterState,
            };
          } else {
            nextFilterState = {
              ...defaultFilters,
              ...selectedFilters[0]?.toParams,
            };
          }

          setSelectedOptions(selectedFilters);
        } else {
          // Do not proceed for filter with empty selection
          if (!get(filter, 'allowEmpty', true)) {
            return;
          }
          setSelectedOptions([]);

          nextFilterState = omit(
            nextFilterState,
            chain(filter?.data).first().get('toParams', {}).keys().value()
          );
        }

        setFilterState(nextFilterState);
        if (onFilterChange) {
          onFilterChange({ nextFilterState, filter });
        }
        onHandleSave(nextFilterState);
      },
      [
        filters,
        filterState,
        onFilterChange,
        onHandleSave,
        filterOption.multiSelect,
        defaultFilters,
      ]
    );

    const mapFilterData = useCallback(
      ({ data }: FilterOption) => {
        if (!isArray(data)) {
          return [];
        }

        return data
          .filter((d) => toLower(d.label).includes(toLower(searchTerm)))
          .map((filter) => {
            return {
              ...filter,
              checked: chain(filter?.toParams)
                .mapValues((value, key) => {
                  const currentState = get(filterState, key);
                  return isArray(currentState)
                    ? isEqual(
                        intersection(
                          currentState,
                          value as List<any> | null | undefined
                        ).length,
                        (value as [])?.length
                      )
                    : isEqual(currentState, value);
                })
                .values()
                .every()
                .value(),
            };
          });
      },
      [filterState, searchTerm]
    );

    const onSelectAll = () => {
      let nextFilterState = {
        ...filters,
        ...filterState,
      };

      map(filterOption.data, ({ toParams }) =>
        mapValues(toParams, (value, key) => {
          if (has(nextFilterState, key)) {
            nextFilterState = {
              ...nextFilterState,
              [key]: union(nextFilterState[key], value as any),
            };
          } else {
            nextFilterState = {
              ...nextFilterState,
              [key]: value,
            };
          }
        })
      );
      setSelectedOptions(filterOption.data);
      setFilterState(nextFilterState);
      if (onFilterChange) {
        onFilterChange({ nextFilterState, filter: filterOption });
      }
      onHandleSave(nextFilterState);
    };

    return (
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        variant="responsive"
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <PopoverTrigger>
          <FilterButton
            onClick={onToggle}
            label={filterOption.title}
            badgeCount={selectedFiltersCount.current as number}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex>
                {searchEnabled && (
                  <InputGroup>
                    <InputLeftElement mt={-1} pointerEvents="none">
                      <SearchIcon />
                    </InputLeftElement>
                    <Input
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                      borderRadius={8}
                      size="sm"
                      placeholder={t('Search') as string}
                    />
                  </InputGroup>
                )}
              </Flex>
              <Flex>
                {filterOption.multiSelect && (
                  <H5
                    logEventProps={{
                      page: tweakPageName(
                        location.pathname.split('/')[1] || ''
                      ),
                      subSource: tweakPageName(
                        location.pathname.split('/')[1] || ''
                      ),
                      eventName: EVENTS.RESET_FILTERS,
                    }}
                    ml={2}
                    color={Colors.primary}
                    onClick={onSelectAll}
                    style={{ cursor: 'pointer', textWrap: 'nowrap' }}
                  >
                    {t('Select all')}
                  </H5>
                )}
                <H5
                  logEventProps={{
                    page: tweakPageName(location.pathname.split('/')[1] || ''),
                    subSource: tweakPageName(
                      location.pathname.split('/')[1] || ''
                    ),
                    eventName: EVENTS.RESET_FILTERS,
                  }}
                  ml={2}
                  mr={1}
                  color={hasFiltersSelected ? Colors.primary : '#A3A9B1'}
                  onClick={onHandleReset}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Clear')}
                </H5>
              </Flex>
            </Flex>
          </PopoverHeader>
          <PopoverBody>
            <Flex py={2} pl={1}>
              <FilterSection
                data={filterOption.data}
                key={filterOption.id}
                filter={filterOption}
                filteredData={mapFilterData(filterOption)}
                onFilterChange={onHandleFilterChange}
                selectedOptions={selectedOptions}
              />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);
