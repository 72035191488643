import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import {
  ActionMenu,
  ActionMenuItem,
  Flex,
  Text,
  Box,
  FAIcon,
} from '@fivehealth/botero';
import { get, map } from 'lodash';
import Colors from 'constants/colors';

export interface DropdownAction {
  id: string;
  label: string;
  onClick: (event: unknown) => void;
  enable?: boolean;
  icon?: string;
  divider?: boolean;
  description?: string;
  color?: string;
}

export interface DropdownMenuProps {
  actions: DropdownAction[];
  label: string | React.ReactNode;
  marginLeft?: number | string;
  fontWeight?: number | string;
  ref: object;
  width?: string | number;
  disabled?: boolean;
}

export interface DropdownMenuHandles {
  open: () => void;
  close: () => void;
}

const DropdownMenu = forwardRef<DropdownMenuHandles, DropdownMenuProps>(
  (
    { actions, label, marginLeft = 0, fontWeight = '500', disabled, ...props },
    ref
  ) => {
    const actionMenuRef = useRef<{ setOpen: (val: boolean) => void }>();

    const handleOnClick = (event: unknown, action: DropdownAction) => {
      if (actionMenuRef.current?.setOpen) {
        actionMenuRef.current?.setOpen(false);
      }

      const onClick = get(action, 'onClick');

      if (onClick) {
        return onClick(event);
      }

      return null;
    };

    useImperativeHandle(ref, () => ({
      open() {
        actionMenuRef.current?.setOpen(true);
      },
      close() {
        actionMenuRef.current?.setOpen(false);
      },
    }));

    if (disabled) {
      return (
        <Box overflow="hidden" bg={Colors.mediumShade} borderRadius={8}>
          {label}
        </Box>
      );
    }

    return (
      <ActionMenu
        pb="8px"
        ml={marginLeft}
        dropdownRef={(r: unknown) => {
          actionMenuRef.current = r as { setOpen: (val: boolean) => void };
        }}
        {...props}
        label={label}
      >
        {map(actions, (action, index) => {
          let color = null;

          if (get(action, 'enable', true)) {
            color = get(action, 'color', 'fullShade');
          } else {
            color = 'darkShade';
          }

          return (
            <ActionMenuItem
              isFirst={index === 0}
              isLast={index === actions.length - 1}
              key={action.id}
              divider={get(action, 'divider', false)}
              onClick={(event: unknown) =>
                get(action, 'enable', true)
                  ? handleOnClick(event, action)
                  : null
              }
              {...props}
            >
              <Flex mb={index === actions.length - 1 ? '8px' : '0px'} pt="8px">
                {action.icon && (
                  <Box>
                    <Box width="16px" height="16px">
                      <FAIcon ml="8px" color="darkShade" icon={action.icon} />
                    </Box>
                  </Box>
                )}
                <Box ml={action.icon ? '22px' : '0px'} alignItems="left">
                  <Text
                    fontFamily="Inter, sans-serif"
                    textAlign="left"
                    mt="-4px"
                    fontSize="14px"
                    lineHeight="24px"
                    fontStyle="normal"
                    letterSpacing="-0.08px"
                    fontWeight={fontWeight}
                    color={color}
                  >
                    {action.label}
                  </Text>
                  {action.description && (
                    <Text
                      mt="5px"
                      width="222px"
                      fontFamily="Inter, sans-serif"
                      textAlign="left"
                      fontSize="15px"
                      fontWeight="400"
                      letterSpacing="-0.08px"
                      lineHeight="24px"
                      fontStyle="normal"
                      color="darkestShade"
                    >
                      {action.description}
                    </Text>
                  )}
                </Box>
              </Flex>
            </ActionMenuItem>
          );
        })}
      </ActionMenu>
    );
  }
);

export default DropdownMenu;
