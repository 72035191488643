import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get, isEmpty } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientGroup($uid: String) {
    cleoPatientGroup(uid: $uid) {
      uid
      name
      description
      variablesAll
      patients {
        totalCount
        edges {
          node {
            phone
            email
            identifier
            language
            dateOfBirth
            gender
            communicationMethod
            deactivatedOn
            uid
            name
            id
            createdOn
            metadataEntries {
              uid
              value
            }
            variablesAll
            dischargedMonitoringForms {
              uid
              url
              effectiveName
            }
            enrolledMonitoringForms {
              uid
              url
              effectiveName
            }
          }
        }
      }
    }
  }
`;

export const onMapNodePatients = (node) => {
  const getGender = () => (node.gender === 'M' ? 'Male' : 'Female');
  const gender = isEmpty(node.gender) ? '' : getGender();

  return {
    ...node,
    gender,
    id: node.uid,
    original: {
      name: node.name,
    },
    dischargedMonitoringForms: node.dischargedMonitoringForms ?? [],
    monitoringForms: node.enrolledMonitoringForms ?? [],
    variablesAll: {
      ...get(node, 'variablesAll', {}),
      dischargedMonitoringForms: node.dischargedMonitoringForms ?? [],
      monitoringForms: node.enrolledMonitoringForms ?? [],
    },
  };
};

const usePatientGroups = () => (args) => {
  const patientGroups = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'groupPatients',
    select: (data) => {
      const patients = get(data?.cleoPatientGroup, 'patients.edges', [])
        ?.flatMap(({ node }) => node)
        .map((node) => onMapNodePatients(node));
      return {
        totalCount: get(data?.cleoPatientGroup, 'patients.totalCount', 0),
        patients,
      };
    },
  });
  return patientGroups(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientGroups();
