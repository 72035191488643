import { useApi } from '@fivehealth/botero';
import useClinic from 'api/queries/useClinic';
import asyncJobTypes, { AsyncStatus } from 'constants/asyncJobTypes';
import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import { AsyncJob, AsyncJobData } from './useUpdateEventsAsync';

export interface PatientBulkImportInput {
  asyncJobUid?: string;
  preview: boolean;
  forceDeliverMessage: boolean;
  overrides: {
    [key: string]: string | number | boolean | string[];
  };
}

interface UsePatientsBulkImportAsyncResult {
  bulkUploadPatients: (input: PatientBulkImportInput) => Promise<AsyncJob>;
}

export const usePatientsBulkUploadAsync =
  (): UsePatientsBulkImportAsyncResult => {
    const {
      queries: { usePatientsImportAsync },
    } = useApi({
      queries: ['usePatientsImportAsync'],
    });

    const { data: clinic }: { data: { domain?: string } } = useClinic();

    // eslint-disable-next-line no-unused-vars
    const [_, setAsyncJobData] = useRecoilState<AsyncJobData>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      asyncJobState as any
    );

    const { mutateAsync: updatePatientsAsync } = usePatientsImportAsync({
      variables: {},
    });

    const bulkUploadPatients = async (input: PatientBulkImportInput) => {
      const result = await updatePatientsAsync({
        input,
      });

      const asyncJob = get(
        result,
        'cleoPatientImportAsync.asyncJob',
        null
      ) as AsyncJob;

      if (asyncJob && asyncJob?.status !== 'SUCCESS') {
        const { type } = asyncJobTypes.CleoPatientImport;
        const info =
          asyncJobTypes.CleoPatientImport.infoText[
            asyncJob.status as AsyncStatus
          ];

        const data = {
          type,
          ...info,
          ...asyncJob,
          clinic: clinic?.domain as string,
        } as unknown as AsyncJobData;
        setAsyncJobData(data);
      }

      return asyncJob;
    };

    return {
      bulkUploadPatients,
    };
  };
