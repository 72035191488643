import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicians($activated: Boolean) {
    cleoClinicians(deactivatedOn_Isnull: $activated) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'cliniciansCount',
  select: (data) => data?.cleoClinicians?.totalCount,
});
