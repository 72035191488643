import React, { useState } from 'react';
import { get } from 'lodash';

import Reminders from './Reminders/Reminders';
import PatientProfile from './PatientShow/PatientProfile';

const PatientShow = ({ activeTab: defaultActiveTab = 0 }) => {
  const [view, setView] = useState('profile');
  const [viewState, setViewState] = useState({});

  const setNewView = (key, data) => {
    setViewState({
      ...viewState,
      [key]: data,
    });
    setView(key);
  };

  const showPatientProfile = (data = {}) => setNewView('profile', data);
  const showEditReminder = (data = {}) => setNewView('editReminder', data);

  switch (view) {
    case 'editReminder':
      return (
        <Reminders
          viewState={get(viewState, 'editReminder', {})}
          showPatientProfile={showPatientProfile}
        />
      );

    default:
      return (
        <PatientProfile
          activeTab={defaultActiveTab}
          viewState={get(viewState, 'profile', {})}
          showEditReminder={showEditReminder}
          showPatientProfile={showPatientProfile}
        />
      );
  }
};

export default PatientShow;
