import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_PATIENT_IMPORT_STATUS = gql`
  mutation cleoPatientImportAsync($input: CleoPatientImportAsyncInput!) {
    cleoPatientImportAsync(input: $input) {
      asyncJob {
        uid
        status
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_PATIENT_IMPORT_STATUS,
  queryType: 'mutation',
  baseQueryKey: 'patientsImportAsyncStatus',
  keepPreviousData: false,
});
