import { Box, Divider, Flex } from '@chakra-ui/react';
import { Toggle, H5, Select, H6 } from '@fivehealth/botero';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FollowUpReminderProps {
  onChange: (index: number, option: number) => void;
  defaultValue: number;
  index: number;
}

interface OptionValue {
  label: string;
  value: number;
}

export const FollowUpReminder = memo<FollowUpReminderProps>(
  ({ index, defaultValue, onChange }) => {
    const { t } = useTranslation();

    const [val, setval] = useState(defaultValue);

    const [enabled, setEnabled] = useState<boolean>(!!defaultValue);

    return (
      <Box p={3} borderRadius={8} borderWidth={1} mb={2}>
        <Flex justifyContent="space-between">
          <H5>
            {t('Follow-up reminder #')}
            {index}
          </H5>
          <Toggle
            mb={enabled ? 0 : -1}
            enabled={enabled}
            onChange={(v: boolean) => {
              setEnabled(v);
            }}
          />
        </Flex>
        {enabled && (
          <>
            <Divider h={1} mb={3} />

            <H6 color="darkestShade" mb={1}>
              {t('Follow-up reminder #')}
              {index}
            </H6>

            <Select
              width={['100%', '50%']}
              value={
                val
                  ? {
                      label: `${val / 60 / 60} hour after initial reminder`,
                      value: val,
                    }
                  : undefined
              }
              options={new Array(12).fill(0).map(
                (_, i) =>
                  ({
                    label: `${i + 1} hour after initial reminder`,
                    value: (i + 1) * 60 * 60,
                  } as OptionValue)
              )}
              onChange={(triggerEvent: OptionValue) => {
                setval(triggerEvent.value);
                onChange(index, triggerEvent.value);
              }}
            />
          </>
        )}
      </Box>
    );
  }
);
