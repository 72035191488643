const IS_PRODUCTION = () => {
  if (process.env?.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
};

const REDIRECT_PATH = '/auth/callback';

const GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://hippocrates.production.botmd.io/gql/'
  : 'https://hippocrates.staging.botmd.io/gql/';

export const LOGIN_PROVIDER_UID = 'cleo-auth0';
export const LOGIN_PROVIDER_UID_EMAIL = 'cleo-auth0-email';

const CLEO_SESSION_EXCHANGE_PROVIDER_UID = {
  sg: IS_PRODUCTION()
    ? 'aoghpoHOtqhlzu6RMAOOdpfs4Px83kSh'
    : 'UlNNpOQfyP1kthUcMfm9cvPuaN4uZY3G',
  id: IS_PRODUCTION()
    ? 'VDfNKxp9mXhziCG0OnCtKtfoFbA8bxKo'
    : 'gconZNaPvDeZBj3ozEA3ARegm17McH9r',
};

const AMPLITUDE_KEY = IS_PRODUCTION()
  ? 'efcc7ef3feedf9860ab02e5b679fa72f'
  : 'e9f0f661b5a5ab9cedadfc904d9c2408';

const CLEO_JWT_PROVIDER_UID = {
  sg: IS_PRODUCTION()
    ? 'uSxsQvDhkuWEoUzWlAVsc1nF5UlbFIjI'
    : 'a6b5CdoECONEA7xCvuaogWXmYCzRoMTp',
  id: IS_PRODUCTION()
    ? 'sTmMCUiEYGwwKcadeZl4TaO6BX7pIEdM'
    : 'mMG74eAm5OtecGKqaOpriR59XjTpH1e9',
};

const LOGIN_URL = IS_PRODUCTION()
  ? 'https://login.production.botmd.io'
  : 'https://login.staging.botmd.io';

export const LOGIN_TYPE_SESSION_KEY = 'LOGIN_TYPE_SESSION_KEY';
export const LOGIN_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

export const PATIENT_LIST_SELECTION_OPTION = {
  VISIBLE_ROWS: 'VISIBLE_ROWS',
  ALL_ROWS: 'ALL_ROWS',
  CUSTOM: 'CUSTOM',
};

export const PATIENT_GROUP_SELECTION_OPTION = {
  VISIBLE_ROWS: 'VISIBLE_ROWS',
  ALL_ROWS: 'ALL_ROWS',
  CUSTOM: 'CUSTOM',
};

export const BULK_ACTIONS_SELECTION_OPTION = {
  VISIBLE_ROWS: 'VISIBLE_ROWS',
  ALL_ROWS: 'ALL_ROWS',
  CUSTOM: 'CUSTOM',
};

export default {
  env: process.env.NODE_ENV,
  IS_PRODUCTION,
  clinicianCookie: { name: 'clinician:data', maxAge: 2595599 },
  clinicCookie: { name: 'clinic:data', maxAge: 2595599 },
  cookie: { name: 'careDashboard:session', maxAge: 2595599 }, // 30 days,
  storage: { name: 'careDashboard:workspace' },
  patientCookie: {
    name: 'careDashboardPatientFacing:session',
    maxAge: 2595599,
  },
  AMPLITUDE_KEY,
  LOGIN_PROVIDER_UID,
  CLEO_JWT_PROVIDER_UID,
  CLEO_SESSION_EXCHANGE_PROVIDER_UID,
  GQL_ENDPOINT,
  LOGIN_URL,
  REDIRECT_PATH,
  DOMAIN_CONFIG_STORAGE_KEY: 'domain:config',
};
