import React from 'react';
import { Text, Box, theme } from '@fivehealth/botero';
import styled from 'styled-components';
import { last } from 'lodash';

const MobileTable = ({ rows, prepareRow }) => (
  <Container>
    {rows.map((row, rowIndex) => {
      prepareRow(row);
      return (
        <Row
          key={rowIndex}
          as="table"
          cellPadding="0"
          cellSpacing="0"
          width="100%"
          p={2}
        >
          <Box as="tbody">
            {row.cells.map((cell, index) => {
              let pb = 1;
              const nextCell = row.cells[index + 1];
              const lastCell = last(row.cells);
              const isNextCellLast =
                nextCell && lastCell && nextCell === lastCell;
              // if next cell is last and is actions
              if (isNextCellLast && nextCell.column.id === 'actions') {
                pb = 2;
                // if current is last and is not actions
              } else if (cell === lastCell && cell.column.id !== 'actions') {
                pb = 2;
              }

              if (cell.column.id === 'actions') {
                return (
                  <Box key={index} as="tr">
                    <Box as="td" colSpan="2">
                      {cell.render('Cell')}
                    </Box>
                  </Box>
                );
              }

              return (
                <Box key={index} as="tr">
                  <Box
                    as="td"
                    width={100}
                    pl={2}
                    pt={index === 0 ? 2 : 0}
                    pb={pb}
                    pr={30}
                    style={{ verticalAlign: 'top' }}
                  >
                    <Text
                      fontWeight={600}
                      fontSize={14}
                      color={theme.colors.darkestShade}
                    >
                      {cell.column.Header}
                    </Text>
                  </Box>
                  <Box as="td" pr={2} pt={index === 0 ? 2 : 0} pb={pb}>
                    {cell.render('Cell')}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Row>
      );
    })}
  </Container>
);

const Container = styled(Box)`
  margin-top: ${theme.space[3]}px;
`;

const Row = styled(Box)`
  border: 1px solid ${theme.colors.mediumShade};
  border-radius: ${theme.space[1]}px;
  margin-bottom: ${theme.space[2]}px;
`;

export default MobileTable;
