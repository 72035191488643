/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { filter, isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { Flex, Box, useApi, SearchInput } from '@fivehealth/botero';
import { useMediaQuery } from 'react-responsive';
import { Filters, FiltersProps } from 'components/Filters/Filters';
import { useQueryClient } from 'react-query';
import { LicenseBadge } from 'components/LicenseBadge/LicenseBadge';
import DischargeUserModal from './ClinicalAdminDischarge.modal';
import ClinicalAdminTable, { buildFilters } from './ClinicalAdminTable';

export interface Clinician {
  uid: string;
  isCaregiver: boolean;
  name: string;
  email: string;
  designation: string;
  status: string;
  deactivatedOn: string;
}

// Define the props for this component and make them optional
interface ClinicalAdminsProps {
  cliniciansOnly?: Array<{ uid: string }>;
  currentUser?: object;
  hasPermission?: boolean;
  clinicData?: object;
  closeModal?: () => void;
  openDrawer?: never;
  openModal?: (
    component: React.ReactNode,
    props?: {
      style?: object;
      onClose?: () => void;
    }
  ) => void;
  onEditUser?: never;
  t: (key: string) => string;
  hasInsufficientLicenses?: boolean;
  hasBillingSettings?: boolean;
  maxUsers?: number | undefined;
  clinicianCount: number | undefined;
  lastUpdated?: { current: string };
  clinicians?: Clinician[];
  requestParams?: never;
  fetchNextPage?: never;
  hasNextPage?: boolean;
  isLoading?: boolean;
  isRefetching?: boolean;
  isFetchingNextPage?: boolean;
  // showFilters?: boolean;
  onSearch?: never;
  onRefresh?: () => void;
  onFetchData?: never;
  // onFilterOpen?: never;
  // onFilterCancel?: never;
  onApplyFilters?: (filters: FiltersProps['requestParams']) => void;
  onResetFilters?: () => void;
  isFetched?: boolean;
  searchText?: string;
  setSearchText?: (value: string) => void;
  totalFetchDataCount?: number;
  pageCount?: number;
}

const ClinicalAdmins = memo<ClinicalAdminsProps>(
  ({
    // props
    cliniciansOnly,
    currentUser,
    hasPermission,
    clinicData,
    closeModal,
    openDrawer,
    openModal,
    onEditUser,

    // tab with filter
    t,
    hasInsufficientLicenses,
    hasBillingSettings,
    maxUsers,
    clinicianCount,
    lastUpdated,
    clinicians,
    requestParams,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    // showFilters,
    onSearch,
    onRefresh,
    onFetchData,
    // onFilterOpen,
    // onFilterCancel,
    onApplyFilters,
    onResetFilters,
    isFetched,
    searchText,
    setSearchText,
    totalFetchDataCount,
    pageCount,
  }) => {
    const methods = useForm();
    const [filterOptions, setFilterOptions] = useState([]);
    const tableData = filter(clinicians, ({ isCaregiver }) => !isCaregiver);
    const queryClient = useQueryClient();

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const {
      queries: {
        useClinicianDesignations,
        useClinicianDischarge,
        useClinicianPatientReassign,
        useUpdateClinician,
        useClinicGroup,
        useClinic,
      },
    } = useApi({
      queries: [
        'useClinicianDesignations',
        'useClinicianDischarge',
        'useClinicianPatientReassign',
        'useUpdateClinician',
        'useClinicGroup',
        'useClinic',
      ],
    });

    const {
      data: clinicalDesignations,
      isLoading: isLoadingClinicianDesignations,
    } = useClinicianDesignations();

    const { refetch: refreshClinicData } = useClinic();

    const { refetch: groupRefetch } = useClinicGroup();

    const { mutateAsync: enrollClinician } = useUpdateClinician({
      variables: {},
      onSuccess: () => {
        onRefresh?.();
        refreshClinicData();
        groupRefetch();
      },
    });

    const { isLoading: isDischargeLoading, mutateAsync: dischargeClinician } =
      useClinicianDischarge({
        variables: {},
        onSuccess: () => {
          onRefresh?.();
          refreshClinicData();
          groupRefetch();
        },
      });

    const { isLoading: isReassignLoading, mutateAsync: reassignPatients } =
      useClinicianPatientReassign({
        variables: {},
        onSuccess: onRefresh,
      });

    const createDesignationsFilters = useCallback(
      () =>
        clinicalDesignations?.cleoClinicianDesignations.map(
          (item: { designation: string }) => ({
            id: item.designation.toLowerCase().trim().split(' ').join('_'),
            label: t(item.designation),
            toParams: {
              designation: item.designation,
            },
          })
        ),
      [clinicalDesignations?.cleoClinicianDesignations, t]
    );

    const onSubmitDischargeClinician = useCallback(
      ({
        clinician,
        reassignedClinician,
      }: {
        clinician: Clinician;
        reassignedClinician: Clinician;
      }) =>
        dischargeClinician({ input: { uid: clinician.uid } }).then(() => {
          if (reassignedClinician && reassignedClinician.uid) {
            return reassignPatients({
              input: {
                clinicianAUid: clinician.uid,
                clinicianBUid: reassignedClinician.uid,
              },
            }).then(closeModal);
          }
          // invalide clinicians key
          queryClient.invalidateQueries('clinicians');
          onRefresh?.();
          return closeModal?.();
        }),
      [dischargeClinician, queryClient, onRefresh, closeModal, reassignPatients]
    );

    const onDischargeUser = useCallback(
      (clinicalUser: Clinician) => {
        const clns = cliniciansOnly?.filter(
          ({ uid }) => uid !== clinicalUser.uid
        );
        openModal?.(
          <FormProvider {...methods}>
            <DischargeUserModal
              user={clinicalUser}
              clinicians={clns}
              closeModal={closeModal}
              onSubmit={onSubmitDischargeClinician}
              isSubmitting={isDischargeLoading || isReassignLoading}
            />
          </FormProvider>,
          {
            style: {
              padding: 24,
              overflow: 'scroll',
            },
          }
        );
      },
      [
        closeModal,
        cliniciansOnly,
        isDischargeLoading,
        isReassignLoading,
        methods,
        onSubmitDischargeClinician,
        openModal,
      ]
    );

    const onEnrollUser = useCallback(
      (clinicalUser: Clinician) =>
        enrollClinician({
          input: { uid: clinicalUser.uid, deactivatedOn: null },
        }).then(async () => {
          queryClient.invalidateQueries('clinicians');
          onRefresh?.();
        }),
      [enrollClinician, onRefresh, queryClient]
    );

    useEffect(() => {
      if (clinicalDesignations && clinicData) {
        setFilterOptions(
          // @ts-ignore
          buildFilters(createDesignationsFilters(), t, clinicData).filter(
            Boolean
          )
        );
      }
    }, [clinicalDesignations, clinicData, createDesignationsFilters, t]);

    const onFilterChange = useCallback(
      (filters: FiltersProps['requestParams']) => {
        onApplyFilters?.(filters);
        if (isEmpty(filters)) {
          onResetFilters?.();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    return (
      <>
        <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
          <SearchInput
            inputStyle={{
              paddingBottom: '6px',
              paddingTop: '8px',
            }}
            hideRefresh={isMobile}
            placeholder={t('Search clinician by name')}
            inputMinWidth={isMobile ? '100%' : '400px'}
            onSearch={onSearch}
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchText?.(e.target.value);
            }}
            onRefresh={onRefresh}
            isRefreshing={isRefetching}
            lastUpdated={lastUpdated ? lastUpdated.current : ''}
            totalCount={totalFetchDataCount}
            pageCount={clinicians?.length || pageCount}
            childrenContainerStyleProps={{
              style: {
                marginLeft: '16px',
                display: isMobile && 'none',
              },
            }}
            refreshButtonProps={{
              style: {
                paddingTop: '12px',
                paddingBottom: '12px',
              },
            }}
          >
            {hasBillingSettings && (
              <LicenseBadge
                currentCount={clinicianCount}
                limit={maxUsers}
                parentPage="Clinical Admins"
              />
            )}
          </SearchInput>
        </Flex>

        <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
          <Filters
            requestParams={requestParams}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        </Box>

        <Box mb={8}>
          <ClinicalAdminTable
            currentUser={currentUser}
            hasPermission={hasPermission}
            data={tableData.filter(Boolean)}
            clinicData={clinicData}
            onOpenDrawer={openDrawer}
            onFetchData={onFetchData}
            onEditUser={onEditUser}
            onDischargeUser={onDischargeUser}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onEnrollUser={onEnrollUser}
            showLoading={isLoading || isLoadingClinicianDesignations}
            isFetched={isFetched}
            hasInsufficientLicenses={hasInsufficientLicenses}
          />
        </Box>
      </>
    );
  }
);

export default React.memo(ClinicalAdmins);
