/* eslint-disable @typescript-eslint/naming-convention */
import { extendTheme } from '@chakra-ui/react';
import Colors from 'constants/colors';

export const ChakraTheme = extendTheme({
  colors: {
    brand: {
      50: Colors.primary,
      100: Colors.primary,
      500: Colors.primary,
    },
    lightestShade: {
      50: Colors.lightestShade,
      100: Colors.lightestShade,
      500: Colors.lightestShade,
    },
    darkestShade: {
      50: Colors.darkestShade,
      100: Colors.darkestShade,
      500: Colors.darkestShade,
    },
    emptyShade: {
      50: Colors.emptyShade,
      100: Colors.emptyShade,
      500: Colors.emptyShade,
    },
    mediumShade: {
      50: Colors.mediumShade,
      100: Colors.mediumShade,
      500: Colors.mediumShade,
    },
    primary: {
      50: Colors.primary,
      100: Colors.primary,
      500: Colors.primary,
    },
    success: {
      50: Colors.success,
      100: Colors.success,
      500: Colors.success,
    },
  },
  components: {
    Popover: {
      baseStyle: {
        popper: {
          inset: 'auto !important',
          marginTop: '5px',
          boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
        },
        content: {
          width: 'auto !important',
          maxWidth: '400px !important',
        },
      },
    },
  },
});
