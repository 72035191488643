/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Body,
  Box,
  Flex,
  H1,
  PrimaryButton,
  useApi,
  theme,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';

import EVENTS from 'constants/events';
import { checkPermissions } from 'AppUtils';
import { useModal } from 'context/ModalContext';

import TabWithFilter from 'components/Tab/TabWithFilter';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  ModalFooter,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import Config from 'Config';
import { ModalProps } from 'views/Appointments/Appointments';
import { DataType } from '../../constants';

import ClinicalAdmins from './ClinicalAdmins';
import AddClinicalUserModal from './AddClinicalUserModal';
import AllGoodAvatar from '../../assets/all-good-avatar.svg';

const ClinicalList = memo(() => {
  const { t } = useTranslation();
  const {
    openModal,
    closeModal,
  }: { openModal: () => void; closeModal: () => void } = useModal();
  const methods = useForm();

  const [cookies] = useCookies([Config.cookie.name]);
  const sessionToken = cookies && cookies[Config.cookie.name];

  const [showAddEditClinicianModal, setShowAddEditClinicianModal] =
    useState(false);

  const [selectedClinicalUser, setSelectedClinicalUser] = useState<object>();

  const DEFAULT_PARAMS = {
    orderField: 'NAME',
    orderDesc: false,
    first: 25,
  };

  const {
    queries: {
      useClinic,
      useClinicians,
      useCurrentUser,
      useCliniciansExportedFormats,
      useClinicGroup,
    },
  } = useApi({
    queries: [
      'useClinic',
      'useClinicians',
      'useCurrentUser',
      'useCliniciansExportedFormats',
      'useClinicGroup',
    ],
  });

  const [modalProps, setModalProps] = useState<ModalProps>();
  const { data: cliniciansExportedUrl } = useCliniciansExportedFormats();

  const { data: currentUser } = useCurrentUser();
  const hasPermission = checkPermissions(currentUser, ['mutate_clinicians']);

  const {
    data: clinicianData = {},
    refetch: refetchClinicians,
    isFetched: isCliniciansFetched,
  } = useClinicians({ variables: DEFAULT_PARAMS });

  const { data: clinic } = useClinic();

  const { data: clinicGroup } = useClinicGroup();
  const [hasBillingInfo, setHasBillingInfo] = useState(false);
  const [hasInsufficientLicenses, setHasInsufficientLicenses] = useState(false);
  useEffect(() => {
    if (clinic && clinicGroup) {
      const clinicBillingSettings = clinic?.billingSettings;
      const groupBillingSettings = clinicGroup?.billingSettings;

      // Check if billing settings are available
      const billingSettingsAvailable =
        clinicBillingSettings || groupBillingSettings;
      if (billingSettingsAvailable) {
        setHasBillingInfo(true);
        const maxUsers = clinic?.billingSettings?.maximum_number_of_users;
        let cliniciansCount;
        if (clinic?.isClinicBilling) {
          // if clinic is billing, then cliniciansCount = clinicCliniciansCount
          cliniciansCount = clinic?.clinicianCount;
        } else {
          cliniciansCount = clinicGroup?.clinicianCount;
        }
        setHasInsufficientLicenses(cliniciansCount >= maxUsers);
      } else {
        setHasBillingInfo(false);
        setHasInsufficientLicenses(false);
      }
    }
  }, [clinic, clinicGroup]);

  const disableButtonToolTip =
    t(`No licenses left! You can view details in the Billing module.
 Contact sales@botmd.io to purchase more.`);

  const closeClinicianAddEditModal = async () => {
    await refetchClinicians();
    closeModal();
  };

  const onEditClinicalUser = (clinicalUser: object) => {
    setSelectedClinicalUser(clinicalUser);
  };

  const cliniciansOnly = useMemo(
    () =>
      get(clinicianData, 'pages', [])
        .flatMap((page: unknown) => page || [])
        .filter(
          ({
            deactivatedOn,
            isCaregiver,
          }: {
            deactivatedOn: string;
            isCaregiver: boolean;
          }) => !deactivatedOn && !isCaregiver
        ),
    [clinicianData]
  );

  const confirmCliniciansDownload = useCallback(async () => {
    setModalProps({
      title: t('Export'),
      body: t('Download all your clinical user details as a CSV file.'),
      confirmLabel: 'Download',
      closeLabel: 'Cancel',
      onConfirm: () => {
        setModalProps({
          body: (
            <Flex flexDirection="column" alignItems="center">
              <Flex justifyContent="center" mb={4}>
                <Box height="120px" as="img" src={AllGoodAvatar} />
              </Flex>

              <Text fontWeight="600" fontSize={16} mb={2}>
                {t(`Your file is being downloaded. Please wait...`)}
              </Text>
              <Body mt={1} mb={2} color="darkestShade" fontSize={14} small>
                {moment().format('YYYY-MM-DD HH:MM')}
              </Body>

              <Button
                onClick={() => {
                  setModalProps(undefined);
                }}
                color={theme.colors.emptyShade}
                background={theme.colors.primary}
                colorScheme={theme.colors.primary}
                variant="solid"
              >
                {t('Close')}
              </Button>
            </Flex>
          ),
        });
        window.open(
          // eslint-disable-next-line no-template-curly-in-string
          cliniciansExportedUrl?.replace('${session}', sessionToken),
          '_blank'
        );
      },
      onClose: () => {
        setModalProps(undefined);
      },
    });
  }, [cliniciansExportedUrl, sessionToken, t]);

  return (
    <>
      {(showAddEditClinicianModal || selectedClinicalUser) && (
        <FormProvider {...methods}>
          <AddClinicalUserModal
            user={selectedClinicalUser}
            clinicData={clinic}
            closeModal={() => {
              setShowAddEditClinicianModal(false);
              setSelectedClinicalUser(undefined);
              closeClinicianAddEditModal();
            }}
            currentUser={currentUser}
          />
        </FormProvider>
      )}
      <Box py={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <H1>{t('Clinical Admin')}</H1>
            <Body pt={2}>
              {t('View and manage your clinical users and their permissions')}
            </Body>
          </Box>
          <Box display={['none', 'initial', 'initial']}>
            <Flex flexDirection={['column', 'row']} pl={[2, 0]}>
              {hasPermission &&
                (hasInsufficientLicenses && hasBillingInfo ? (
                  <Tooltip
                    label={disableButtonToolTip}
                    placement="bottom"
                    borderRadius="8px"
                    backgroundColor="#000000CC"
                    width={240}
                    padding="8px 16px 8px 16px"
                    sx={{
                      boxShadow: '0px 2px 2px 0px #98A2B326',
                      color: '#FFFFFF',
                      fontSize: 14,
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    <span>
                      <PrimaryButton
                        logEventProps={{
                          page: 'Clinincal Admin List',
                          eventName: EVENTS.ADD_CLINICAL_ADMIN,
                        }}
                        disabled={hasInsufficientLicenses}
                        mr={[0, 3]}
                        onClick={() => {
                          setShowAddEditClinicianModal(true);
                        }}
                        borderRadius={8}
                        pt={2}
                        pb={2}
                        height={40}
                        mb={[1, 0]}
                        minWidth={150}
                      >
                        {t('Add clinical user')}
                      </PrimaryButton>
                    </span>
                  </Tooltip>
                ) : (
                  <PrimaryButton
                    logEventProps={{
                      page: 'Clinincal Admin List',
                      eventName: EVENTS.ADD_CLINICAL_ADMIN,
                    }}
                    disabled={hasInsufficientLicenses}
                    mr={[0, 3]}
                    onClick={() => {
                      setShowAddEditClinicianModal(true);
                    }}
                    borderRadius={8}
                    pt={2}
                    pb={2}
                    height={40}
                    mb={[1, 0]}
                    minWidth={150}
                  >
                    {t('Add clinical user')}
                  </PrimaryButton>
                ))}
              <SecondaryOutlinedButton
                onClick={confirmCliniciansDownload}
                borderRadius={8}
                mr={3}
              >
                {t('Download user list')}
              </SecondaryOutlinedButton>
            </Flex>
          </Box>
        </Flex>

        {hasPermission && (
          <Box display={['initial', 'none', 'none']}>
            {hasInsufficientLicenses ? (
              <Tooltip
                label={disableButtonToolTip}
                placement="bottom"
                borderRadius="8px"
                backgroundColor="#000000CC"
                width={240}
                padding="8px 16px 8px 16px"
                sx={{
                  boxShadow: '0px 2px 2px 0px #98A2B326',
                  color: '#FFFFFF',
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: 'center',
                }}
              >
                <span>
                  <PrimaryButton
                    logEventProps={{
                      page: 'Clinincal Admin List',
                      eventName: EVENTS.ADD_CLINICAL_ADMIN,
                    }}
                    mt={2}
                    width="100%"
                    disabled={hasInsufficientLicenses}
                    onClick={() => {
                      setShowAddEditClinicianModal(true);
                    }}
                    borderRadius={8}
                  >
                    {t('Add clinical user')}
                  </PrimaryButton>
                </span>
              </Tooltip>
            ) : (
              <PrimaryButton
                logEventProps={{
                  page: 'Clinincal Admin List',
                  eventName: EVENTS.ADD_CLINICAL_ADMIN,
                }}
                mt={2}
                width="100%"
                disabled={hasInsufficientLicenses}
                onClick={() => {
                  setShowAddEditClinicianModal(true);
                }}
                borderRadius={8}
              >
                {t('Add clinical user')}
              </PrimaryButton>
            )}
          </Box>
        )}

        <Box mt={4}>
          <TabWithFilter
            // @ts-ignore
            clinicData={clinic}
            cliniciansOnly={cliniciansOnly}
            closeModal={closeModal}
            currentUser={currentUser}
            dataType={DataType.Clinician}
            defaultGQLVariable={DEFAULT_PARAMS}
            hasPermission={hasPermission}
            hasTableConfig={false}
            isFetched={isCliniciansFetched}
            onEditUser={onEditClinicalUser}
            openModal={openModal}
          >
            <ClinicalAdmins
              t={t}
              hasInsufficientLicenses={hasInsufficientLicenses}
              maxUsers={clinic?.billingSettings?.maximum_number_of_users}
              clinicianCount={
                clinic?.isClinicBilling
                  ? clinic?.clinicianCount
                  : clinicGroup?.clinicianCount
              }
              hasBillingSettings={hasBillingInfo}
            />
          </TabWithFilter>
        </Box>
      </Box>

      {modalProps && (
        <Modal
          isCentered
          isOpen
          onClose={() => {
            setModalProps(undefined);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{modalProps.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{modalProps.body}</ModalBody>

            <ModalFooter>
              {modalProps?.onClose && (
                <Button
                  mr={3}
                  onClick={modalProps?.onClose}
                  variant="outline"
                  backgroundColor="transparent"
                  color={theme.colors.darkestShade}
                >
                  {t(modalProps.closeLabel || 'Close')}
                </Button>
              )}
              {modalProps?.onConfirm && (
                <Button
                  onClick={modalProps?.onConfirm}
                  color={theme.colors.emptyShade}
                  background={
                    modalProps.isDanger
                      ? theme.colors.danger
                      : theme.colors.primary
                  }
                  colorScheme={
                    modalProps.isDanger
                      ? theme.colors.danger
                      : theme.colors.primary
                  }
                  variant="solid"
                >
                  {t(modalProps.confirmLabel || 'Ok')}
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
});

export default ClinicalList;
