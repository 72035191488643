/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

export const GRAPHQL_PATIENT_FORMS_EXPORTED_FORMATS = gql`
  query cleoPatientFormsExportedFormats {
    cleoPatientForms {
      exportFormats {
        label
        outputFormat
        allowEmail
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_PATIENT_FORMS_EXPORTED_FORMATS,
  queryType: 'query',
  baseQueryKey: 'patientFormsExportedFormats',
  select: (data) =>
    get(data, 'cleoPatientForms.exportFormats')?.map((o) => ({
      value: o.outputFormat?.toLowerCase(),
      label: o.label,
      allowEmail: o.allowEmail,
    })),
  keepPreviousData: true,
});
