import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useTranslation, withTranslation } from 'react-i18next';
import { get, isArray, isEmpty } from 'lodash';
import {
  sub,
  add,
  isAfter,
  startOfDay,
  endOfDay,
  parseISO,
  differenceInDays,
  isSameDay,
} from 'date-fns';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import {
  Text,
  Box,
  Flex,
  FAIcon,
  useApi,
  Radio,
  Label,
  Dropdown,
} from '@fivehealth/botero';

import Config from 'Config';
import { getPatientSubmissions, isDate, tweakPageName } from 'AppUtils';

import DateRangeNavigation from 'components/Charts/ChartDateRangeNavigation';
import ExportData, {
  defaultExportDataSettings,
} from '../../components/ExportData';
import { EXPORT_DATA_TYPE } from '../../constants';
import EVENTS from '../../constants/events';

import Charts from './Graphs/Charts';
import { dateRangeOptions } from './Graphs/PatientCharts';
import PatientChartsDropdown from './Graphs/PatientChartsDropdown';

const graphViewDropdownOptions = [
  { label: 'View as graph', showTableView: false },
  { label: 'View as table', showTableView: true },
];

const PatientGraphs = ({
  clinic,
  patient,
  patientForms,
  isPatientFacing,
  clinician,
  onRegisterExportButton,
}) => {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { client } = useApi();

  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];

  const defaultDateRange =
    dateRangeOptions.find(({ value }) => value === 6) ||
    dateRangeOptions[0].value;

  const [currentDateRange, setCurrentDateRange] = useState(
    defaultDateRange.value
  );
  const [dateRangeEndDate, setDateRangeEndDate] = useState(
    endOfDay(new Date())
  );
  const [dateRangeStartDate, setDateRangeStartDate] = useState(
    startOfDay(sub(dateRangeEndDate, { days: currentDateRange }))
  );
  const [showDateRangeDropdown, setShowDateRangeDropdown] = useState(false);
  const [showTableView, setShowTableView] = useState(false);

  const [exportDataType, setExportDataType] = useState(EXPORT_DATA_TYPE.graphs);

  const updatedNextEndDate = add(dateRangeEndDate, {
    days: currentDateRange > 1 ? currentDateRange : null,
    hours: currentDateRange > 1 ? null : 24 * currentDateRange,
  });
  const nextEndDate =
    currentDateRange > 1 ? endOfDay(updatedNextEndDate) : updatedNextEndDate;

  const grandAverageStartDate = useRef(new Date(null));
  const grandAverageEndDate = useRef(new Date());

  const defExportDataSettings = defaultExportDataSettings(t, patient);

  const defExportDataModalCfg = {
    modaltTitle: defExportDataSettings.modalTitle.downloadData,
    options: defExportDataSettings.dataOptionsGraph,
    formats: defExportDataSettings.dataFormats,
  };

  let canNavigateNext = !isAfter(nextEndDate, endOfDay(new Date()));

  if (currentDateRange === 1) {
    canNavigateNext = !isSameDay(dateRangeStartDate, endOfDay(new Date()));
  }
  const {
    queries: { useClinicAsPatient },
  } = useApi({
    queries: ['useClinicAsPatient'],
  });

  const { data: clinicAsPatient } = useClinicAsPatient({
    enabled: isPatientFacing,
    staleTime: Infinity,
  });

  const onChangeDateRange = (option) => {
    setCurrentDateRange(option?.value);
    setShowDateRangeDropdown(false);
    const date = new Date();
    const endDate = endOfDay(date);
    setDateRangeEndDate(endDate);
    const startDate =
      option.value > 1
        ? sub(endDate, {
            days: option?.value,
          })
        : date;
    setDateRangeStartDate(startOfDay(startDate));
    setShowDateRangeDropdown(false);
  };

  const onChangeCustomDateRange = (startDate, endDate) => {
    setCurrentDateRange(differenceInDays(endDate, startDate));
    setDateRangeStartDate(startOfDay(startDate));
    setDateRangeEndDate(endOfDay(endDate));
  };

  const onChangeAllData = () => {
    const endDate = endOfDay(parseISO(new Date().toISOString()));
    const startDate = startOfDay(
      parseISO(
        sub(endDate, {
          days: 364,
        }).toISOString()
      )
    );

    setDateRangeStartDate(startDate);
    setDateRangeEndDate(endDate);
    setCurrentDateRange(differenceInDays(endDate, startDate));
  };

  const onNavigateNextDateRange = () => {
    if (!canNavigateNext) {
      return;
    }
    const endDate =
      currentDateRange > 1
        ? add(dateRangeEndDate, {
            days: currentDateRange + 1,
          })
        : add(dateRangeStartDate, {
            days: currentDateRange,
          });

    setDateRangeStartDate(
      currentDateRange > 1
        ? startOfDay(add(dateRangeEndDate, { days: 1 }))
        : startOfDay(endDate)
    );
    setDateRangeEndDate(endOfDay(endDate));
  };

  const onNavigatePrevDateRange = () => {
    const startDate = sub(dateRangeStartDate, {
      days: currentDateRange > 1 ? currentDateRange + 1 : 1,
    });

    if (differenceInDays(startDate, new Date()) >= 364) {
      return;
    }
    setDateRangeStartDate(startOfDay(startDate));
    setDateRangeEndDate(
      endOfDay(
        currentDateRange > 1 ? sub(dateRangeStartDate, { days: 1 }) : startDate
      )
    );
  };

  const onSubmitExporDataGraphPDF = ({ outputType, dataType }) => {
    if (dataType && outputType) {
      setShowTableView(dataType.toLowerCase() === EXPORT_DATA_TYPE.tables);
      if (/pdf/i.test(outputType)) {
        setTimeout(window.print, 1000);
      }
    }
  };

  const invokePatientSubmissionsExport = useCallback(
    async ({ patient: patientParam, outputType, exportDestination }) => {
      const { cleoSubmissions } = await getPatientSubmissions(
        queryClient,
        client,
        [patientParam.uid],
        exportDestination?.email,
        outputType
      );

      if (!exportDestination?.email) {
        /* eslint no-template-curly-in-string: 0 */
        window.open(
          cleoSubmissions?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    },
    [client, queryClient, token]
  );

  const onRenderExportButton = () =>
    clinician ? (
      <ExportData
        modalParams={{
          title: defExportDataModalCfg.modaltTitle,
          showBody: true,
          showExportDataFormats: true,
          showExportDestination: true,
          patient,
          onSubmitCallback: invokePatientSubmissionsExport,
          onSubmitCallbackClient: onSubmitExporDataGraphPDF,
        }}
        dataFormats={defExportDataModalCfg.formats}
        dataType={exportDataType}
        dataOptions={defExportDataModalCfg.options}
        logEventProps={{
          page: 'Patient Profile - Graphs',
          eventName: `${EVENTS.SHOW_PATIENT_PROFILE} - ${exportDataType} - tab : ${defExportDataModalCfg.modaltTitle}`,
        }}
      />
    ) : null;

  useEffect(() => {
    onRegisterExportButton(onRenderExportButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDataType]);

  const isGraphConfigValid = isArray(
    (isPatientFacing ? clinicAsPatient : clinic)?.patientChartsSettings
  );

  const onChangeShowTableView = (value) => {
    setShowTableView(value);
    if (value) {
      setExportDataType(EXPORT_DATA_TYPE.tables);
    } else {
      setExportDataType(EXPORT_DATA_TYPE.graphs);
    }
  };

  return (
    <Box>
      <Box pb={[0, 4]}>
        <Box display={['initial', 'initial', 'none']}>
          <Flex
            id="graphViewDropdown"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Box
              style={{ width: '100%' }}
              onClick={() => {
                setShowDateRangeDropdown(false);
              }}
            >
              <GraphViewDropdown
                options={graphViewDropdownOptions}
                selectedIndex={graphViewDropdownOptions.findIndex(
                  (option) => option?.showTableView === showTableView
                )}
                onChange={(index) => {
                  const option = graphViewDropdownOptions[index];
                  if (option) {
                    onChangeShowTableView(option?.showTableView);
                  }
                }}
                onOpen={(val) => {
                  if (showDateRangeDropdown && val) {
                    setShowDateRangeDropdown(false);
                  }
                }}
              />
            </Box>
            <PatientChartsDropdown
              options={dateRangeOptions}
              startDate={dateRangeStartDate}
              endDate={dateRangeEndDate}
              value={currentDateRange}
              onChange={onChangeDateRange}
              onChangeAllData={onChangeAllData}
              onChangeCustomDateRange={onChangeCustomDateRange}
            />
          </Flex>
        </Box>

        <Flex justifyContent={['center', 'space-between']} alignItems="center">
          <Box display={['none', 'none', 'initial']}>
            <Flex flex="0.2">
              <PatientChartsDropdown
                options={dateRangeOptions}
                startDate={dateRangeStartDate}
                endDate={dateRangeEndDate}
                value={currentDateRange}
                onChange={onChangeDateRange}
                onChangeAllData={onChangeAllData}
                onChangeCustomDateRange={onChangeCustomDateRange}
              />
            </Flex>
          </Box>
          <Flex flex={['1,', '1', '0.6']} justifyContent="center">
            <DateRangeNavigation
              language={i18n?.language || 'en'}
              onPrev={onNavigatePrevDateRange}
              onNext={onNavigateNextDateRange}
              startDate={
                !isDate(dateRangeStartDate) ? new Date() : dateRangeStartDate
              }
              endDate={
                !isDate(dateRangeEndDate) ? new Date() : dateRangeEndDate
              }
              currentDateRange={currentDateRange}
              canNavigateNext={canNavigateNext}
            />
          </Flex>
          <Box display={['none', 'none', 'initial']}>
            <Flex flex="0.2" justifyContent="flex-end">
              <Flex justifyContent="space-between" alignItems="center">
                <Label mr={3}>
                  <Radio
                    logEventProps={{
                      page: tweakPageName(
                        window.location.pathname.split('/')[1]
                      ),
                      subSource: 'Patient Profile Graph',
                      eventName: `Patient Profile Graph - Graph View Checked`,
                    }}
                    testid="patient_graph_view_radio_btn"
                    checked={!showTableView}
                    onChange={() => onChangeShowTableView(false)}
                  />
                  <Text fontSize={14}>{t('Graph')}</Text>
                </Label>
                <Label>
                  <Radio
                    logEventProps={{
                      page: tweakPageName(
                        window.location.pathname.split('/')[1]
                      ),
                      subSource: 'Patient Profile Graph',
                      eventName: `Patient Profile Graph - Table View Checked`,
                    }}
                    testid="patient_table_view_radio_btn"
                    checked={showTableView}
                    onChange={() => onChangeShowTableView(true)}
                  />
                  <Text fontSize={14}>{t('Table')}</Text>
                </Label>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>

      {isGraphConfigValid && (
        <Box>
          {(isPatientFacing
            ? clinicAsPatient
            : clinic
          )?.patientChartsSettings?.map((chartSetting, i) => (
            <Charts
              clinicAsPatient={clinicAsPatient}
              clinic={clinic}
              canNavigateNext={canNavigateNext}
              currentDateRange={currentDateRange}
              dateRangeStartDate={dateRangeStartDate}
              dateRangeEndDate={dateRangeEndDate}
              grandAverageEndDate={grandAverageEndDate}
              grandAverageStartDate={grandAverageStartDate}
              isPatientFacing={isPatientFacing}
              patientForms={patientForms}
              queriesEnabled={!isEmpty(patientForms)}
              showTableView={showTableView}
              key={i}
              chartSetting={chartSetting}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PatientGraphs;

const GraphViewDropdown = withTranslation()(
  ({
    // eslint-disable-next-line no-shadow
    options = [],
    selectedIndex,
    onChange,
    t,
    onOpen,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Dropdown
        isContentCentered
        containerStyle={{
          width: '100%',
        }}
        label={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderRadius={8}
            borderWidth={1}
            borderStyle="solid"
            borderColor="mediumShade"
            bg="white"
            height={40}
            px={2}
          >
            <Text mr={2}>{get(options, `[${selectedIndex}].label`, '')}</Text>
            <FAIcon icon={faChevronDown} fontSize={14} color="darkShade" />
          </Flex>
        }
        open={isOpen}
        onOpenChange={(val) => {
          onOpen(val);
          setIsOpen(val);
        }}
      >
        <Box
          as="ul"
          py={1}
          px={0}
          m={0}
          borderRadius={8}
          borderWidth={1}
          borderStyle="solid"
          borderColor="mediumShade"
          bg="white"
        >
          {options.map(({ label }, index) => (
            <Box
              as="li"
              key={label}
              py={1}
              px={2}
              style={{ listStyle: 'none' }}
            >
              <Text
                cursor="pointer"
                color={selectedIndex === index ? '#256BF6' : 'fullShade'}
                onClick={() => {
                  onChange(index);
                  setIsOpen(false);
                }}
              >
                {t(label)}
              </Text>
            </Box>
          ))}
        </Box>
      </Dropdown>
    );
  }
);
