/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useApi } from '@fivehealth/botero';
import LoadingSpinner from 'components/LoadingOverlay/LoadingSpinner';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Colors from 'constants/colors';

export interface ClinicalSettingsProgramsProps {
  patientId: string;
}

export const ClinicalSettingsPrograms = ({
  patientId,
}: ClinicalSettingsProgramsProps): ReactNode => {
  const { t } = useTranslation();

  const {
    queries: { usePatientPrograms },
  } = useApi({
    queries: ['usePatientPrograms'],
  });

  const { data, isLoading } = usePatientPrograms({
    variables: {
      patientIds: [patientId],
    },
    enabled: !!patientId,
  });

  const patientPrograms = get(data, 'pages', [])
    ?.flatMap((node: any) => node)
    .map((node: any) => node);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderProgram = (program: any) => {
    return (
      <Box
        id="clinical-settings-programs"
        p={4}
        width="30%"
        key={program.uid}
        borderRadius={8}
        borderWidth={1}
        borderColor={Colors.lightGrey}
      >
        <Text fontWeight={600} fontSize="md">
          {program.clinicProgram?.name}
        </Text>

        <Divider my={2} />

        <Text fontWeight={500} fontSize="sm">
          {t('Reminders')}
        </Text>
        <Box mt={2}>
          {program?.effectiveReminders?.map((reminder: any) => (
            <Box my={2} key={reminder.uid}>
              <Text fontSize="sm">{reminder?.name}</Text>
              <Text fontSize="sm">{reminder.description}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  if (!patientPrograms || patientPrograms.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection={['column', 'row']} width="100%">
      <Flex minWidth="280px">
        <Text fontWeight={600} fontSize="md" my={5}>
          {t('Clinic Programs')}
        </Text>
      </Flex>
      <Flex ml="56px" flex={1} gap={10} pt={5} flexWrap="wrap">
        {patientPrograms?.map((program: any) => renderProgram(program))}
      </Flex>
    </Flex>
  );
};
