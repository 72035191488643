import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { parseISO, compareDesc } from 'date-fns';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { useParams, useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Appointments from 'views/Appointments/Appointments';
import {
  get,
  // isEqual,
  // first,
  mapValues,
  isEmpty,
  // find,
  map,
  omit,
} from 'lodash';

import {
  Badge,
  Box,
  Drawer,
  FAIcon,
  Flex,
  H1,
  PrimaryButton,
  Tab,
  TabPanel,
  Tabs,
  Text,
  useApi,
} from '@fivehealth/botero';

import {
  getBaseUrl,
  getMessageDeliveriesExport,
  getPatientSubmissions,
} from 'AppUtils';
import { DataType } from 'constants';
import { useModal } from 'context/ModalContext';
import { useAppData } from 'context/AppDataContext';

import TabWithFilter from 'components/Tab/TabWithFilter';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import ExportData, {
  defaultExportDataSettings,
} from '../../../components/ExportData';
import Config from '../../../Config';
import { EXPORT_DATA_TYPE } from '../../../constants';
import EVENTS from '../../../constants/events';

import PatientGraphs from '../PatientGraphs';
import ClinicalSettings from '../ClinicalSettings';
import PatientShowSubmissions from '../PatientShowSubmissions';

import Messages from './Messages';
import InfoSection from './InfoSection';
import AddFormModal from './AddForm.modal';
import { ClinicalSettingsPrograms } from '../ClinicalSettingsPrograms/ClinicalSettingsPrograms';

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`;

const PatientLoader = (props) => (
  <Box mt={6}>
    <ContentLoader
      speed={2}
      width={1000}
      height={600}
      viewBox="0 0 1000 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="3" y="46" rx="2" ry="2" width="1000" height="51" />
      <rect x="4" y="13" rx="2" ry="2" width="224" height="16" />
      <rect x="231" y="396" rx="0" ry="0" width="4" height="14" />
      <rect x="190" y="357" rx="0" ry="0" width="6" height="6" />
      <rect x="283" y="363" rx="0" ry="0" width="1" height="0" />
      <rect x="177" y="197" rx="0" ry="0" width="4" height="8" />
      <rect x="149" y="606" rx="0" ry="0" width="7" height="4" />
      <rect x="121" y="617" rx="0" ry="0" width="8" height="3" />
      <rect x="7" y="740" rx="0" ry="0" width="399" height="181" />
      <rect x="206" y="812" rx="0" ry="0" width="1" height="15" />
      <rect x="3" y="116" rx="0" ry="0" width="1000" height="7" />
      <rect x="166" y="154" rx="0" ry="0" width="19" height="2" />
      <rect x="3" y="178" rx="0" ry="0" width="450" height="11" />
      <rect x="4" y="203" rx="0" ry="0" width="450" height="11" />
      <rect x="4" y="228" rx="0" ry="0" width="450" height="11" />
      <rect x="3" y="151" rx="0" ry="0" width="450" height="11" />
    </ContentLoader>
  </Box>
);

const PatientProfile = ({
  showEditReminder,
  activeTab: defaultActiveTab = 0,
  showPatientProfile,
}) => {
  const params = useParams();
  const history = useHistory();
  const { user } = useAppData();
  const { t } = useTranslation();
  const queryClientHook = useQueryClient();
  const { openModal, closeModal } = useModal();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [exportDataType, setExportDataType] = useState('');
  const [exportModalParams, setExportModalParams] = useState({});
  const [exportDataFormats, setExportDataFormats] = useState([]);
  const [exportDataOptions, setExportDataOptions] = useState(null);
  const [isDisableExport, setIsDisableExport] = useState(false);
  const [showExportButton, setShowExportButton] = useState(false);
  const [onBeforeRenderExport, setOnBeforeRenderExport] = useState();
  const [selectedSubmissionId, setSelectedSubmissionId] = useState();
  const [deliveryMessageCount, setDeliveryMessageCount] = useState(0);

  const tabRef = useRef();
  const aboutTabRef = useRef(null);
  const submissionsTabRef = useRef(null);
  const graphsTabRef = useRef(null);
  const settingsTabRef = useRef(null);
  const messagesTabRef = useRef(null);
  const appointmentsTabRef = useRef(null);

  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];

  const { sendMessage } = useSendMessageAsync();

  const {
    queries: {
      usePatientForms,
      usePatientFormsAsPatient,
      useAlertRuleCreate,
      useAlertRuleUpdate,
      usePatientFormUpdate,
      useCurrentUser,
      useClinic,
      usePatientFormEnrollDischarge,
      useSubmissionsCount,
      useSubmissionsExportedFormats,
      useDeliveriesExportedFormats,
      usePatient,
      useClinicCalendars,
      useClinicProgramsCount,
    },
  } = useApi({
    queries: [
      'usePatientForms',
      'usePatientFormsAsPatient',
      'useAlertRuleCreate',
      'useAlertRuleUpdate',
      'usePatientFormUpdate',
      'useCurrentUser',
      'useClinic',
      'usePatientFormEnrollDischarge',
      'useSubmissionsCount',
      'useSubmissionsExportedFormats',
      'useDeliveriesExportedFormats',
      'usePatient',
      'useClinicCalendars',
      'useClinicProgramsCount',
    ],
  });

  const { data: clinicProgramsCount } = useClinicProgramsCount();

  const { data: clinicCalendars } = useClinicCalendars();

  const { data: clinic } = useClinic({
    enabled: true,
    staleTime: Infinity,
  });

  const { data: clinician } = useCurrentUser({
    enabled: !user?.isPatientFacing,
  });

  const patientId = params.uid;

  const { data: patient } = usePatient({
    enabled: !!patientId,
    variables: {
      uid: patientId,
    },
  });

  const { data: patientFormDataAsPatient } = usePatientFormsAsPatient({
    enabled: !!patientId && !!user?.isPatientFacing,
    variables: {
      patientIds: [patientId],
    },
  });

  const {
    isLoading: isLoadingPatientForms,
    data: patientFormData,
    refetch: refetchPatientForm,
  } = usePatientForms({
    enabled: !user?.isPatientFacing && !!patientId,
    variables: {
      patientIds: [patientId],
    },
  });

  const {
    isLoading: isDischargePatientLoading,
    mutateAsync: enrollOrDischargePatientForm,
  } = usePatientFormEnrollDischarge({
    variables: {},
  });

  const { data: submissionsExportedFormats } = useSubmissionsExportedFormats({
    enabled: true,
  });

  const { data: deliveriesExportedFormats } = useDeliveriesExportedFormats({
    enabled: true,
  });

  const onSendMessageTemplate = async (sendMessageParams) => {
    const {
      template,
      patientForm,
      userVariables,
      patientUids,
      patientEventUid,
    } = sendMessageParams;
    const templateId = get(template, 'uid');
    const payload = {
      patientFormUids: [patientForm?.uid],
      messageTemplateSetUid: templateId,
      userVariables,
    };

    if (patientUids) {
      payload.patientUids = patientUids;
    }

    if (patientEventUid) {
      payload.patientEventUid = patientEventUid;
    }

    const result = await sendMessage(payload);

    tabRef?.current?.onRefresh();

    return result;
  };

  const onRegisterExportButton = (handler) => {
    setOnBeforeRenderExport(handler);
  };

  const onSubmitDischargePatientForm = (patientForm) =>
    enrollOrDischargePatientForm({
      input: {
        patientFormUids: [patientForm?.uid],
        operation: patientForm?.isDischarged ? 'ENROLL' : 'DISCHARGE',
      },
    }).then(() => refetchPatientForm());

  const patientForms =
    user?.isPatientFacing && patientFormDataAsPatient
      ? patientFormDataAsPatient
      : get(patientFormData, 'pages', [])
          .flatMap((page) => page)
          .sort((p1, p2) =>
            compareDesc(parseISO(p1?.createdOn), parseISO(p2?.createdOn))
          );

  const patientFormUids = useMemo(
    () => map(patientForms, ({ uid }) => uid),
    [patientForms]
  );

  // const showPatientFormTabs = useMemo(
  //   () => patientFormUids.length > 0,
  //   [patientFormUids]
  // );

  // const patientForm = patientFormUid
  //   ? find(patientForms, ({ uid }) => isEqual(uid, patientFormUid))
  //   : first(patientForms);

  // const patient = get(patientForm, 'patient', patientQueryData);

  // console.log({ patientFromF });

  const { data: submissionsCount } = useSubmissionsCount({
    enabled: !isEmpty(patientForms),
    variables: { patientFormUids: map(patientForms, ({ uid }) => uid) },
  });

  const onInvalidateQueries = ({ queryClient }) => {
    if (!queryClient) {
      return;
    }
    queryClient.invalidateQueries('alertRules').then(() => {
      queryClient.invalidateQueries('alertReminder');
      queryClient.invalidateQueries('patient');
      queryClient.invalidateQueries('patientForms');
    });
  };

  const { mutateAsync: createAlertRule, isLoading: isAlertRuleCreateLoading } =
    useAlertRuleCreate({
      variables: {},
      onSuccess: onInvalidateQueries,
    });

  const { mutateAsync: updateAlertRule, isLoading: isAlertRuleUpdateLoading } =
    useAlertRuleUpdate({
      variables: {},
      onSuccess: ({ queryClient }) => {
        queryClient.invalidateQueries('alertRules').then(() => {
          queryClient.invalidateQueries('patient');
          queryClient.invalidateQueries('patientForms');
        });
      },
    });

  const { mutateAsync: updatePatientForm } = usePatientFormUpdate({
    variables: {},
    onSuccess: onInvalidateQueries,
  });
  const { client } = useApi();

  const invokePatientSubmissionsExport = useCallback(
    async ({ patient: patientParam, outputType, exportDestination }) => {
      const { cleoSubmissions } = await getPatientSubmissions(
        queryClientHook,
        client,
        [patientParam?.uid],
        exportDestination?.email,
        outputType
      );

      if (!exportDestination?.email) {
        /* eslint no-template-curly-in-string: 0 */
        window.open(
          cleoSubmissions?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    },
    [client, queryClientHook, token]
  );

  const invokeMessageDeliveriesExport = useCallback(
    async ({ outputType, exportDestination }) => {
      const { cleoDeliveries } = await getMessageDeliveriesExport(
        queryClientHook,
        client,
        exportDestination?.email,
        outputType,
        {
          patientUid: patient?.uid,
        }
      );
      if (!exportDestination?.email) {
        window.open(
          // eslint-disable-next-line no-template-curly-in-string
          cleoDeliveries?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    },
    [client, patient?.uid, queryClientHook, token]
  );

  const onSetTabRef = (ref) => {
    tabRef.current = ref;
  };

  useEffect(() => {
    if (isMobile) {
      const transitionDelay = (refObj) => {
        setTimeout(() => {
          if (refObj?.current?.scrollIntoView) {
            refObj.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 1000);
      };
      if (messagesTabRef && activeTab === 4) {
        transitionDelay(messagesTabRef);
      } else if (settingsTabRef && activeTab === 3) {
        transitionDelay(settingsTabRef);
      } else if (graphsTabRef && activeTab === 2) {
        transitionDelay(graphsTabRef);
      } else if (submissionsTabRef && activeTab === 1) {
        transitionDelay(submissionsTabRef);
      } else if (aboutTabRef && activeTab === 0) {
        transitionDelay(aboutTabRef);
      } else if (appointmentsTabRef && activeTab === 5) {
        transitionDelay(appointmentsTabRef);
      }
    }
  }, [activeTab, isMobile]);

  const onChangeTab = (index) => {
    const mapping = {
      0: `/patient-list/${params?.uid}`,
      1: `/patient-list/${params?.uid}/submissions`,
      2: `/patient-list/${params?.uid}/graphs`,
      3: `/patient-list/${params?.uid}/clinical-settings`,
      4: `/patient-list/${params?.uid}/messages`,
      5: `/patient-list/${params?.uid}/appointments`,
    };
    history.replace(mapping[index] || '/patient-list');
    // The back button is for page. And the tabs refer to in page navigation. Browser history doesn't need to know about this.

    setActiveTab(index);
  };

  const onGoBack = () => {
    history.push(new URL(getBaseUrl()).pathname);
  };

  const onShowSubmission = (uid) => {
    setSelectedSubmissionId(uid);
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setSelectedSubmissionId(null);
    setDrawerOpen(false);
  };

  const onEditThreshold = (threshold, originalData) =>
    new Promise((resolve) => {
      const { rule, patientForm: form } = originalData;
      const settings = { ...rule?.parserSettings };
      const updatedParserSettings = mapValues(settings, (v) =>
        v.map((paramData, index) => {
          if (
            paramData?.clinical_parameter === threshold?.clinical_parameter &&
            index === threshold?.parserIndex
          ) {
            return omit(threshold, ['parserIndex']); // NOTE: Remove temporary index flag
          }
          return paramData;
        })
      );

      // NOTE: Currently we only support single effective alert rule.
      const alertRule = {
        name: rule?.name,
        parserImportPath: rule?.parserImportPath,
        parserSettings: updatedParserSettings,
        isForClinic: false,
      };

      if (rule.isForClinic) {
        return createAlertRule({ input: alertRule }).then(
          ({ cleoAlertRuleCreate }) => {
            const alertRuleUid = get(cleoAlertRuleCreate, 'cleoAlertRule.uid');
            if (alertRuleUid) {
              return updatePatientForm({
                input: {
                  uid: form?.uid,
                  alertRules: [{ uid: alertRuleUid }],
                },
              }).finally(resolve);
            }
            return resolve();
          }
        );
      }
      return updateAlertRule({
        input: { ...alertRule, uid: rule.uid },
      })
        .then(() => refetchPatientForm())
        .finally(resolve);
    });

  const onToggleReminder = (data) => {
    const form = get(data, 'patientForm');
    const reminderId = get(data, 'reminderUid');
    const currReminders = map(form?.effectiveReminders, ({ uid }) => ({
      uid,
    }));

    if (form && form.uid) {
      return updatePatientForm({
        input: {
          uid: form.uid,
          reminders: currReminders?.filter(({ uid }) => uid !== reminderId),
        },
      });
    }
    queryClientHook.invalidateQueries('patientForms');
    return showPatientProfile();
  };

  const onCloseAddForm = async (updated = false) => {
    if (updated) {
      await refetchPatientForm();
    }
    closeModal();
  };

  const onAddForm = () => {
    openModal(
      <AddFormModal
        t={t}
        onClose={onCloseAddForm}
        patients={[patient]}
        enrolledMonitoringFormUidsParam={map(
          patientForms,
          ({ monitoringForm }) => monitoringForm?.uid
        )}
      />,
      {
        style: {
          padding: 24,
          overflow: 'scroll',
        },
      }
    );
  };

  const isFormSubmitting =
    isAlertRuleUpdateLoading ||
    isAlertRuleCreateLoading ||
    isDischargePatientLoading;

  const exportModalTitle = defaultExportDataSettings(t, patient).modalTitle;

  const handleSubmissionsActiveTab = useCallback(() => {
    setOnBeforeRenderExport(null);
    setExportDataType(EXPORT_DATA_TYPE.submissions);
    setShowExportButton(true);
    setExportModalParams({
      title: exportModalTitle.submissions,
      showBody: true,
      showExportDataFormats: true,
      showExportDestination: true,
      prompt: true,
      patient,
      onSubmitCallback: invokePatientSubmissionsExport,
    });
    setIsDisableExport(submissionsCount === 0);
    setExportDataOptions(null);
    setExportDataFormats(submissionsExportedFormats);
  }, [
    exportModalTitle.submissions,
    invokePatientSubmissionsExport,
    patient,
    submissionsCount,
    submissionsExportedFormats,
  ]);

  const handleMessagesActiveTab = useCallback(() => {
    setOnBeforeRenderExport(null);
    setExportDataType(EXPORT_DATA_TYPE.messages);
    setShowExportButton(true);
    setExportModalParams({
      title: exportModalTitle.messages,
      patient,
      // defaultDestination: EXPORT_DATA_DESTINATION.email,
      showEmailDestination: true,
      // showDownloadDestination: false,
      onSubmitCallback: invokeMessageDeliveriesExport,
    });
    setExportDataOptions(null);
    setExportDataFormats(deliveriesExportedFormats);

    if (deliveryMessageCount >= 1) {
      setIsDisableExport(
        !deliveriesExportedFormats || deliveryMessageCount === 0
      );
    }
  }, [
    exportModalTitle.messages,
    patient,
    invokeMessageDeliveriesExport,
    deliveryMessageCount,
    deliveriesExportedFormats,
  ]);

  const initTab = (tab) => {
    onChangeTab(tab);
    switch (tab) {
      case 0:
        setShowExportButton(false);
        setOnBeforeRenderExport(null);
        break;
      case 1:
        handleSubmissionsActiveTab();
        break;
      case 2:
        setShowExportButton(false);
        break;
      case 3:
        setShowExportButton(false);
        setOnBeforeRenderExport(null);
        break;
      default:
        handleMessagesActiveTab();
    }
  };

  useEffect(() => {
    initTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.name, submissionsExportedFormats]);

  if ((!patient?.name || isLoadingPatientForms) && !user?.isPatientFacing) {
    return <PatientLoader />;
  }

  return (
    <Box id="patientProfileContainer" py={4}>
      {!user?.isPatientFacing && (
        <BackButton
          id="backBtn"
          alignItems="center"
          mb={1}
          hover={{ opacity: 0.6 }}
          cursor="pointer"
          onClick={onGoBack}
        >
          <FAIcon
            icon={faChevronLeft}
            color="darkestShade"
            fontWeight="900"
            style={{ fontSize: 12, fontWeight: 900, marginRight: 4 }}
          />
          <Text fontSize={13} fontWeight="900" color="darkestShade">
            {t('Back')}
          </Text>
        </BackButton>
      )}

      <Flex
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-between"
      >
        <Flex flex={1}>
          <H1>{patient?.name}</H1>
        </Flex>

        {clinician &&
          !onBeforeRenderExport &&
          showExportButton &&
          exportDataType &&
          !isEmpty(patient) && (
            <ExportData
              modalParams={exportModalParams}
              dataFormats={exportDataFormats}
              dataType={exportDataType}
              dataOptions={exportDataOptions}
              isDisabled={isDisableExport}
              logEventProps={{
                page: 'Patient Profile',
                eventName: `${EVENTS.SHOW_PATIENT_PROFILE} - ${exportDataType} - tab:${exportModalTitle.patientList}`,
              }}
            />
          )}
        <Flex>
          {onBeforeRenderExport}
          {!user?.isPatientFacing && (
            <PrimaryButton
              width={isMobile ? '100%' : 'auto'}
              borderRadius={8}
              ml={[0, 2]}
              mt={[2, 0, 0]}
              onClick={onAddForm}
            >
              {t('Add Program')}
            </PrimaryButton>
          )}
        </Flex>
      </Flex>

      <Box id="graphContainer" mt={[1, 3, 3]}>
        {(!isEmpty(patient) || user?.isPatientFacing) && (
          <Tabs
            headerProps={{
              borderBottom: '1px solid #ccc',
              overflow: 'auto',
              className: 'scrollbar-invisible',
              style: {
                whiteSpace: 'nowrap',
              },
              id: 'patientShowTabs',
            }}
            activeTabIndex={activeTab}
          >
            <Tab
              onClick={() => {
                initTab(0);
              }}
              data-testid="patient-profile-tab-about"
              display={user?.isPatientFacing ? 'none' : 'block'}
              style={{ flexShrink: 0 }}
            >
              <div ref={aboutTabRef}>
                <span>{t('About')}</span>
              </div>
            </Tab>

            <Tab
              onClick={() => {
                initTab(1);
              }}
              data-testid="patient-profile-tab-submissions"
              // display={user?.isPatientFacing}
              style={{ flexShrink: 0 }}
            >
              <div ref={submissionsTabRef}>
                <span>{t('Submissions')}</span>
                <Badge
                  fontWeight={600}
                  fontSize={12}
                  color="fullShade"
                  ml={1}
                  borderRadius={8}
                  backgroundColor="#E8EAED"
                  height={24}
                  alignItems="center"
                  justifyContent="center"
                >
                  {submissionsCount}
                </Badge>
              </div>
            </Tab>

            <Tab
              onClick={() => {
                initTab(2);
              }}
              data-testid="patient-profile-tab-graph"
              style={{ flexShrink: 0 }}
            >
              <div ref={graphsTabRef}>
                <span>{t('Graphs')}</span>
              </div>
            </Tab>

            <Tab
              onClick={() => {
                initTab(3);
              }}
              data-testid="patient-profile-tab-clinical-settings"
              display={user?.isPatientFacing ? 'none' : 'block'}
              style={{ flexShrink: 0 }}
            >
              <div ref={settingsTabRef}>
                <span>{t('Clinical Settings')}</span>
              </div>
            </Tab>

            <Tab
              onClick={() => {
                initTab(4);
              }}
              data-testid="patient-profile-tab-messages"
              display={user?.isPatientFacing ? 'none' : 'block'}
              style={{ flexShrink: 0 }}
            >
              <div ref={messagesTabRef}>
                <span>{t('Messages')}</span>
              </div>
            </Tab>

            <Tab
              onClick={() => {
                initTab(5);
              }}
              data-testid="patient-profile-appointments"
              display={user?.isPatientFacing ? 'none' : 'block'}
              style={{
                flexShrink: 0,
                display: clinicCalendars?.length ? 'block' : 'none',
              }}
            >
              <div ref={appointmentsTabRef}>
                <span>{t('Appointments')}</span>
              </div>
            </Tab>

            <TabPanel testid="patient_profile_about_tab">
              <InfoSection
                title={t('About')}
                patientForm={{ patient }}
                patientForms={patientForms}
                clinic={clinic}
                clinician={clinician}
                refetchPatientForm={refetchPatientForm}
              />
            </TabPanel>

            <TabPanel testid="patient_profile_submissions_tab">
              <TabWithFilter
                clinic={clinic}
                clinician={clinician}
                dataType={DataType.Submission}
                defaultGQLVariable={{
                  patientFormUids,
                }}
                isPatientProfile
                hideEmptySubmissions
                onCloseDrawer={onCloseDrawer}
                // onSetExportMenu={setExportMenu}
                onShowSubmission={onShowSubmission}
                openDrawer={() => {}}
                patient={patient}
                tabRef={onSetTabRef}
                tableSettings={clinic?.patientSubmissionsTableSettings}
                tableSettingsSinglePatient={
                  clinic?.patientSubmissionsTableSettings
                }
              />
            </TabPanel>

            <TabPanel testid="patient_profile_graphs_tab">
              <PatientGraphs
                clinic={clinic}
                isPatientFacing={user?.isPatientFacing ?? false}
                patient={patient}
                patientForms={patientForms}
                onRegisterExportButton={onRegisterExportButton}
                clinician={clinician}
              />
            </TabPanel>

            <TabPanel testid="patient_profile_clinical_settings_tab">
              {patientForms?.length > 0 && (
                <ClinicalSettings
                  patientUid={patient?.uid}
                  loading={isLoadingPatientForms}
                  clinician={clinician}
                  patientForms={patientForms}
                  onEditThreshold={onEditThreshold}
                  onToggleReminder={onToggleReminder}
                  isSubmitting={isFormSubmitting}
                  clinic={clinic}
                  refetchPatientForm={refetchPatientForm}
                  onSendMessageTemplate={onSendMessageTemplate}
                  onSubmitDischargePatientForm={onSubmitDischargePatientForm}
                  isMobile={isMobile}
                  showEditReminder={showEditReminder}
                />
              )}
              {clinicProgramsCount > 0 && (
                <ClinicalSettingsPrograms patientId={patientId} />
              )}
            </TabPanel>

            <TabPanel testid="patient_profile_messages_tab">
              <TabWithFilter
                dataType={DataType.Null}
                defaultGQLVariable={{
                  patientUid: patient?.uid,
                  clinicianUid: clinician?.uid,
                  orderField: 'DATE',
                  orderDesc: false,
                }}
              >
                <Messages
                  patientUid={patient?.uid}
                  onCount={setDeliveryMessageCount}
                />
              </TabWithFilter>
            </TabPanel>

            <TabPanel testid="patient_profile_appointments_tab">
              <Box>
                <Appointments showTitle={false} patientUidIn={[patient?.uid]} />
              </Box>
            </TabPanel>
          </Tabs>
        )}
      </Box>
      <PatientShowSubmissionSidePanelModal
        key={selectedSubmissionId}
        submissionId={selectedSubmissionId}
        open={drawerOpen}
        onClose={onCloseDrawer}
        clinician={clinician}
      />
    </Box>
  );
};

export default PatientProfile;

export const PatientShowSubmissionSidePanelModal = ({
  open,
  onClose,
  submissionId,
  clinician,
}) => (
  <Drawer
    width={['100%', 456]}
    open={open}
    onClose={onClose}
    style={{
      boxSizing: 'border-box',
    }}
  >
    <PatientShowSubmissions
      {...{ submissionId, onClose, open }}
      clinician={clinician}
    />
  </Drawer>
);
