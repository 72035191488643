import { Box, Flex, useApi, Text, FAIcon } from '@fivehealth/botero';
import { faChevronUp, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import CommentSection, {
  RepliesTitle,
} from 'views/PatientMonitoring/CommentSection';
import {
  SidePanelLoading,
  SubmissionDetail,
} from 'views/PatientMonitoring/Sidepanel';

const PatientShowSubmissions = ({ submissionId, open, onClose, clinician }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const queryClient = useQueryClient();
  const {
    queries: { useSubmission },
  } = useApi({ queries: ['useSubmission'] });

  const {
    loading,
    data: submission,
    refetch: refetchSubmission,
  } = useSubmission({
    enabled: open,
    variables: { uid: submissionId },
  });

  const onCommentCreated = () => {
    queryClient.invalidateQueries('submissions', { uid: submissionId });
    queryClient.invalidateQueries('submissions', {
      patientIds: [submission.patient.uid],
    });
    queryClient.invalidateQueries('patientFormsCount');
    refetchSubmission();
  };

  return loading || !submission ? (
    <SidePanelLoading />
  ) : (
    <Box display="contents" mb={4} width={isMobile ? 'fit-content' : 'inherit'}>
      <Box height={6} bg={submission?.statusColor} />
      <Box mt={[0, '12px']} px={[0, 2]}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={[0, 2]}
          py={3}
          mb={1}
        >
          <Flex>
            <Text fontSize={['14px', '24px']} fontWeight={[600, 500]}>
              {submission?.patient?.name}
              {submission?.patientForm?.isDischarged && ` (${t('Discharged')})`}
            </Text>
          </Flex>
          <Box cursor="pointer" onClick={onClose}>
            <FAIcon
              icon={isMobile ? faChevronUp : faTimes}
              hover={{ opacity: 0.6 }}
            />
          </Box>
        </Flex>
        <Box px={[0, 2]}>
          <SubmissionDetail isMobile={isMobile} submission={submission} />
          <Box
            maxWidth="100%"
            px="16px"
            mt={3}
            borderRadius={10}
            style={{ border: '1px solid #d5d7de' }}
          >
            <Box mt="16px">
              <RepliesTitle
                repliesCount={get(submission, 'comments', []).length}
                uid={submissionId}
              />
            </Box>
            <Box pb={2}>
              <CommentSection
                submission={submission}
                onCommentCreated={onCommentCreated}
                comments={submission?.comments}
                withAccordion={false}
                clinician={clinician}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientShowSubmissions;
