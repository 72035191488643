import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoClinicianPatientFormRelationsUpdate(
    $input: CleoClinicianPatientFormRelationsUpdateInput!
  ) {
    cleoClinicianPatientFormRelationsUpdate(input: $input) {
      patientForm {
        uid
        patient {
          uid
          name
        }

        ics {
          uid
          name
        }

        alertees {
          uid
          name
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientFormRelationsUpdate',
  keepPreviousData: false,
});
