import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT = gql`
  query cleoPatientFormsExport(
    $outputFormat: ExportOutputFormat!
    $patientGroupUidIn: [String!]
    $createdOnGte: DateTime
    $createdOnLte: DateTime
  ) {
    cleoPatientForms(
      patientGroupUidIn: $patientGroupUidIn
      createdOn_Gte: $createdOnGte
      createdOn_Lte: $createdOnLte
    ) {
      totalCount
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`;

export const GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT_EMAIL = gql`
  query cleoPatientFormsExport(
    $outputFormat: ExportOutputFormat!
    $email: String
    $patientGroupUidIn: [String!]
    $createdOnGte: DateTime
    $createdOnLte: DateTime
  ) {
    cleoPatientForms(
      patientGroupUidIn: $patientGroupUidIn
      createdOn_Gte: $createdOnGte
      createdOn_Lte: $createdOnLte
    ) {
      totalCount
      exportByEmail(outputFormat: $outputFormat, email: $email)
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT,
  queryType: 'query',
  baseQueryKey: 'patientFormsExport',
  select: (data) => get(data, 'cleoPatientForms'),
});
