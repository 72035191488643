// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const Trash = memo<IconProps>(({ color, width = 16, height = 16, viewBox }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox={viewBox || '0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.25C12.2812 0.25 15.75 3.71875 15.75 8C15.75 12.2812 12.2812 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25ZM8 14.25C11.4375 14.25 14.25 11.4688 14.25 8C14.25 4.5625 11.4375 1.75 8 1.75C4.53125 1.75 1.75 4.5625 1.75 8C1.75 11.4688 4.53125 14.25 8 14.25ZM11.1562 6.0625L9.21875 8L11.1562 9.96875C11.3125 10.0938 11.3125 10.3438 11.1562 10.5L10.4688 11.1875C10.3125 11.3438 10.0625 11.3438 9.9375 11.1875L8 9.25L6.03125 11.1875C5.90625 11.3438 5.65625 11.3438 5.5 11.1875L4.8125 10.5C4.65625 10.3438 4.65625 10.0938 4.8125 9.96875L6.75 8L4.8125 6.0625C4.65625 5.9375 4.65625 5.6875 4.8125 5.53125L5.5 4.84375C5.65625 4.6875 5.90625 4.6875 6.03125 4.84375L8 6.78125L9.9375 4.84375C10.0625 4.6875 10.3125 4.6875 10.4688 4.84375L11.1562 5.53125C11.3125 5.6875 11.3125 5.9375 11.1562 6.0625Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default Trash;
