import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';
import { formatISO, startOfDay, endOfDay, sub } from 'date-fns';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { cleoPatientFormFragment, today } from './usePatientForms';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientForm($uid: String) {
    cleoPatientForm(uid: $uid) {
      ${cleoPatientFormFragment}
    }
  }
`;

export const alerts = [
  {
    id: 'unresolved',
    key: 'alertUnresolved',
    label: 'Alert (Unresolved)',
    icon: <StatusIcon status="warning" />,
    toParams: {
      isAlertsResolved: false,
    },
  },
  {
    id: 'resolved',
    key: 'alertResolved',

    label: 'Alert (Resolved)',
    icon: <StatusIcon status="danger" />,
    toParams: {
      isAlertsResolved: true,
    },
  },
  {
    id: 'normal',
    key: 'normal',
    label: 'Normal',
    icon: <StatusIcon status="success" />,
    toParams: {
      isAlertsTriggered: false,
    },
  },
];

export const dateRange = [
  {
    id: 'today',
    key: 'today',
    label: 'Today',
    toParams: (checked) => {
      if (checked) {
        return {
          afterDateTime: formatISO(endOfDay(sub(today, { days: 1 }))),
        };
      }
      return {};
    },
  },
  // {
  //   id: 'yesterday',
  //   key: 'yesterday',
  //   label: 'Yesterday',
  //   toParams: (checked) => {
  //     if (checked) {
  //       return {
  //         afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 1 }))),
  //       };
  //     }
  //     return {};
  //   },
  // },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    key: 'last7Days',
    toParams: (checked) => {
      if (checked) {
        return {
          afterDateTime: formatISO(startOfDay(sub(today, { days: 6 }))),
        };
      }
      return {};
    },
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    key: 'last14Days',
    toParams: (checked) => {
      if (checked) {
        return {
          afterDateTime: formatISO(startOfDay(sub(today, { days: 13 }))),
        };
      }
      return {};
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    key: 'last30Days',
    toParams: (checked) => {
      if (checked) {
        return {
          afterDateTime: formatISO(startOfDay(sub(today, { days: 29 }))),
        };
      }
      return {};
    },
  },
];

export const lastReports = [
  ...[2, 4, 12, 24, 48, 72].map((num) => ({
    id: `last_${num}_hours`,
    key: `last${num}Hours`,
    label: `Last ${num} hours`,
    toParams: {
      nonCompliantSince: formatISO(sub(today, { hours: num })),
    },
  })),
  {
    id: 'last_7_days',
    key: 'last7Days',
    label: 'Last 7 days',
    toParams: {
      nonCompliantSince: sub(today, { days: 7 }),
    },
  },
];

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientForm',
  filters: {
    alerts: { id: 'alerts', title: 'Alerts', data: alerts },
    lastReport: { id: 'lastReport', title: 'No reports', data: lastReports },
    dateRange: { id: 'dateRange', title: 'Date range', data: dateRange },
  },
  select: (data) => get(data, 'cleoPatientForm'),
  keepPreviousData: true,
});
