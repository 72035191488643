import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { parseISO, format } from 'date-fns';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  chain,
  difference,
  first,
  get,
  isEmpty,
  isEqual,
  isNull,
  map,
  values,
} from 'lodash';

import {
  faChevronDown,
  faGlobeAsia,
  faLockAlt,
} from '@fortawesome/pro-regular-svg-icons';

import {
  Box,
  Body,
  InputField,
  Flex,
  Checkbox,
  PrimaryButton,
  Accordion,
  AccordionHeader,
  AccordionBody,
  H6,
  H5,
  FAIcon,
  useApi,
  ActionMenuItem,
  ActionMenuText,
  ActionMenu,
  SecondaryOutlinedButton,
  Text,
  TextLink,
  theme,
} from '@fivehealth/botero';

import { getReadSubmissionComment, tweakPageName } from 'AppUtils';
import Comment from 'components/Comment/Comment';
import UnreadCommentIconIndicator from 'components/Comment/UnreadCommentIconIndicator';

export const RepliesTitle = withTranslation()(
  ({ t, repliesCount, hasUnreadComment = false, uid }) => (
    <H5>
      {t('Comments')}{' '}
      <H6 as="span" bg="lightShade" px={1} py="4px" borderRadius={8}>
        {repliesCount}
      </H6>
      {hasUnreadComment ? (
        <UnreadCommentIconIndicator
          style={{
            width: '10px',
            height: '10px',
            borderRadius: 8,
            color: theme.colors.primary,
            border: '2px solid white',
            position: 'relative',
            top: '-5px',
            left: '-7px',
          }}
          data-testid={`side_panel_comment_section_unread_icon:${uid}`}
          id={`side_panel_comment_section_unread_icon:${uid}`}
        />
      ) : null}
    </H5>
  )
);

const RepliesHeader = ({ open, repliesCount, uid, hasUnreadComment }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    data-testid={`comment_section_header-${uid}`}
  >
    <RepliesTitle
      repliesCount={repliesCount}
      hasUnreadComment={hasUnreadComment}
      uid={uid}
    />

    <FAIcon
      icon={faChevronDown}
      style={{ height: 16, transform: `rotate(${open ? '180' : '0'}deg)` }}
    />
  </Flex>
);

const visibilityOptions = [
  {
    value: 'ICS_AND_ALERTEES',
    label: 'Private',
    description: `Log a note that's only visible to clinical users.`,
    icon: <FAIcon icon={faLockAlt} fontSize="14px" color="darkestShade" />,
  },
  {
    value: 'EVERYBODY',
    label: 'Public',
    description: `Log a comment that is visible to patient.`,
    icon: <FAIcon icon={faGlobeAsia} fontSize="14px" color="darkestShade" />,
  },
];

const StyledInputBox = styled(Box)`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d5d7de;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  &:focus {
    outline: 0;
    outline: none;
    border-color: #256bf7;
    transition: border-color 0.3s ease-in-out;
  }
`;

const VisibilityMenuBtnLabel = ({ t, location, selectedVisibility }) => (
  <SecondaryOutlinedButton
    logEventProps={{
      page: tweakPageName(location.pathname.split('/')[1]),
      subSource: 'Overview',
      eventName: `List Comment Visibility Section`,
    }}
    border={0}
    fontSize={12}
    fontWeight={400}
    startIcon={selectedVisibility?.icon}
    endIcon={
      <FAIcon icon={faChevronDown} fontSize="14px" color="darkestShade" />
    }
  >
    <Body
      color="fullShade"
      fontFamily="Inter"
      small
      medium
      fontSize={12}
      fontWeight={400}
    >
      {t(selectedVisibility?.label)}
    </Body>
  </SecondaryOutlinedButton>
);

const VisbilityActionMenu = ({
  t,
  location,
  onSelect,
  submission,
  selectedVisibility,
  dropdownRef,
  onSetDropDownRef,
  visibilityOptions: visibilityOptionsParam,
}) => (
  <Box
    style={{ marginTop: -5, marginRight: -12 }}
    data-testid={`comment_action_menu_box:${submission.uid}`}
  >
    <ActionMenu
      minWidth={60}
      dropdownRef={onSetDropDownRef}
      testid={`comment_action_menu_visibility:${submission.uid}`}
      label={
        <VisibilityMenuBtnLabel
          t={t}
          location={location}
          selectedVisibility={selectedVisibility}
        />
      }
    >
      {map(visibilityOptionsParam, (option, index) => (
        <ActionMenuItem
          data-testid={`comment_action_menu_visibility:${submission.uid}:item-${index}`}
          key={`comment_action_menu_visibility-${submission.uid}-${index}`}
          onClick={() => {
            onSelect(option);
            dropdownRef.current.setOpen(false);
          }}
        >
          <ActionMenuText fontSize={12}>
            <Box width={175} p={1}>
              <Body
                small
                medium
                fontSize={14}
                fontWeight={500}
                color={
                  isEqual(selectedVisibility?.value, option.value)
                    ? 'primary'
                    : 'fullshade'
                }
              >
                {option.icon}
                <span style={{ marginLeft: 10 }}>{t(option?.label)}</span>
              </Body>
              <Body
                color={
                  isEqual(selectedVisibility?.value, option.value)
                    ? 'primary'
                    : 'fullshade'
                }
                small
                medium
                fontSize={14}
                fontWeight={400}
                style={{ marginLeft: 24 }}
              >
                {t(option.description)}
              </Body>
            </Box>
          </ActionMenuText>
        </ActionMenuItem>
      ))}
    </ActionMenu>
  </Box>
);

// NOTE: We use this global object holder instead of a state to resolve the react eslint warnings
let avatarColorsMapping = {};

// TODO: Consider making this funciton part of utils
const getAvatarColor = (key) => {
  const colorMapping = get(avatarColorsMapping, key, null);
  if (!isNull(colorMapping)) {
    return colorMapping;
  }

  const staticColors = [
    theme.colors.primary,
    theme.colors.success,
    theme.colors.warning,
    theme.colors.danger,
  ];

  const avatarColors =
    difference(staticColors, values(avatarColorsMapping)) || staticColors;
  const randomColor =
    avatarColors[Math.floor(Math.random() * avatarColors.length)];

  avatarColorsMapping = { ...avatarColorsMapping, [key]: randomColor };
  return randomColor;
};

const CommentSectionBody = ({
  t,
  submission,
  comment,
  mappedCommentObj,
  onMarkResolved,
  onSaveComment,
  onEditComment,
  isAlertsUnresolved,
  isUserClinician,
  isCommentDisabled,
  withAccordion,
  visibilityOptions: visibilityOptionsParam,
  selectedVisibility,
  setSelectedVisibility,
  isLoading,
}) => {
  const dropdownRef = useRef(null);
  const inputBoxRef = useRef(null);
  const [text, setText] = useState('');
  const [showCommentsPage, setShowCommentsPage] = useState(1);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const location = useLocation();
  const onSetDropDownRef = (ref) => {
    dropdownRef.current = ref;
  };

  const onChangeComment = (e) => {
    if (text.length === 0) {
      setTextAreaHeight('41px');
    } else {
      setTextAreaHeight(`${e.target.scrollHeight}px`);
    }
    setText(e.target.value);
    onEditComment({ content: e.target.value });
  };

  return (
    <Box
      data-testid={`submission_input_body-${submission.uid}-${!isEmpty(
        comment.content
      )}`}
    >
      {/*
        Temporary fix for paginating comments
        TODO: Seperate comments from useSubmissions and comments pagination should be done on backend
      */}
      {mappedCommentObj?.length > showCommentsPage * 5 && (
        <Flex
          mt={2}
          alignItems="center"
          justifyContent="left"
          flexDirection="column"
        >
          <Text
            textAlign="center"
            color={theme.colors.darkestShade}
            fontSize={12}
            fontWeight={400}
          >
            {t('There are older comments to load.')}
          </Text>
          <TextLink
            text={t('Load More')}
            style={{
              color: theme.colors.primary,
            }}
            fontSize={12}
            onClick={() => setShowCommentsPage(showCommentsPage + 1)}
          />
        </Flex>
      )}
      {/*
        Temporary fix for paginating comments
        TODO: Seperate comments from useSubmissions and comments pagination should be done on backend
      */}
      {mappedCommentObj &&
        chain(mappedCommentObj)
          .map((cObj, idx) => {
            const commentProps = {
              ...cObj,
              isLastComment: idx === cObj.length - 1,
              avatarColor: getAvatarColor(cObj?.name),
            };

            return (
              <Comment
                {...commentProps}
                visibleTo={cObj?.visibleTo}
                t={t}
                key={`comment_content-${cObj?.uid}-${idx}`}
                submissionUID={submission?.uid}
              />
            );
          })
          .chunk(5)
          .takeRight(showCommentsPage)
          .union()
          .value()}
      <Box mt={2}>
        <Flex mt="10px" mb="8px" justifyContent="space-between">
          <H5 id={`comment_section_add_label:${submission.uid}`}>
            {`${t('Add a comment')}`}
          </H5>
          {isUserClinician && (
            <VisbilityActionMenu
              t={t}
              location={location}
              onSelect={setSelectedVisibility}
              submission={submission}
              selectedVisibility={selectedVisibility}
              dropdownRef={dropdownRef}
              onSetDropDownRef={onSetDropDownRef}
              visibilityOptions={visibilityOptionsParam}
            />
          )}
        </Flex>
        <StyledInputBox
          ref={inputBoxRef}
          onFocus={() => {
            inputBoxRef.current.style.borderColor = '#256bf7';
          }}
          onBlur={() => {
            inputBoxRef.current.style.borderColor = '#d7d7d7';
          }}
          width={['auto', !withAccordion ? '100%' : 'auto']}
          data-testid={`submission_input_box-${submission.uid}`}
        >
          <InputField
            id={`submission_input_txt_area-${submission.uid}`}
            data-testid={`submission_input_txt_area-${submission.uid}`}
            as="textarea"
            rows={1}
            fontSize={14}
            style={{
              outline: 'none',
              border: '0px',
              borderBottom: '1px solid #D5D7DE',
              height: `${textAreaHeight}`,
              resize: 'none',
            }}
            placeholder={t('Type here')}
            width="100%"
            disabled={isLoading}
            value={comment.content || ''}
            onChange={onChangeComment}
            borderRadius="0px"
            className={`submission_input_txt_area-${submission.uid}`}
          />
          <Flex
            mt="8px"
            justifyContent={isAlertsUnresolved ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {isUserClinician && isAlertsUnresolved && (
              <Box>
                <Checkbox
                  name="mark-resolved"
                  label=""
                  renderLabel={() => (
                    <Body small opacity={isCommentDisabled ? 0.6 : 1}>
                      {t('Mark as resolved')}
                    </Body>
                  )}
                  color={isCommentDisabled ? '#d2d2d2' : 'primary'}
                  onChange={onMarkResolved}
                  disabled={isCommentDisabled}
                  value={comment.resolved}
                  data-testid={`submission_comment_section_mark_resolve-${
                    submission.uid
                  }-${!!comment.resolved}`}
                />
              </Box>
            )}
            <PrimaryButton
              minWidth="auto"
              disabled={isLoading || isEmpty(comment.content)}
              onClick={() => {
                onSaveComment();
              }}
              data-testid={`submission_comment_submit_btn-${submission.uid}`}
            >
              {t('Submit')}
            </PrimaryButton>
          </Flex>
        </StyledInputBox>
      </Box>
    </Box>
  );
};

const CommentSection = ({
  submission,
  onCommentCreated,
  onRefresh,
  comments = [],
  withAccordion = true,
  clinician,
  isResolved = false,
}) => {
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState({});
  const [selectedVisibility, setSelectedVisibility] = useState(null);
  const [hasUnreadComment, setHasUnreadComment] = useState(null);
  const [hasSeenComment, setHasSeenComment] = useState(false);
  // const [avatarColorsMapping, setAvatarColorsMapping] = useState({});

  const isUserClinician =
    clinician && Boolean(clinician?.isCaregiver) === false;

  // let avatarColorsMapping = {};
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!selectedVisibility && isUserClinician) {
      setSelectedVisibility({ ...first(visibilityOptions) });
    } else {
      setSelectedVisibility({ ...visibilityOptions[1] });
    }

    if (comments.length === 0) {
      setShowComments(true);
    } else {
      setShowComments(false);
    }
  }, []);

  const {
    queries: { useCreateComment, useUpdateAlert, useSubmissionLastSeen },
  } = useApi({
    queries: ['useCreateComment', 'useUpdateAlert', 'useSubmissionLastSeen'],
  });

  const onEditComment = (data = {}) => {
    setComment({
      ...comment,
      ...data,
    });
  };

  const { mutateAsync: updateAlert } = useUpdateAlert({
    variables: {},
  });

  const { mutateAsync: updateSubmissionLastSeen } = useSubmissionLastSeen({
    variables: {},
    onSuccess: async ({ queryClient, data }) => {
      if (queryClient) {
        if (onRefresh) {
          await onRefresh();
        }
        setHasUnreadComment(false);
      }
      return data;
    },
  });

  const { isLoading, mutateAsync: createComment } = useCreateComment({
    variables: {},
    onSuccess: ({ queryClient, data }) => {
      if (queryClient) {
        queryClient.invalidateQueries(
          ['submissions', { uid: submission.uid }],
          {
            refetchActive: true,
            refetchInactive: false,
          }
        );
        queryClient.invalidateQueries('submissionsUnreadCommentCount');
      }
      const commentData = get(
        data,
        'cleoSubmissionCommentCreate.cleoSubmissionComment'
      );
      onEditComment({ ...commentData, content: '', resolved: false });

      if (comment?.resolved) {
        const triggeredAlertUid = chain(submission)
          .get('triggeredAlerts')
          .first()
          .get('uid')
          .value();

        return (
          triggeredAlertUid &&
          updateAlert({
            input: {
              uid: triggeredAlertUid,
              resolvingComment: { uid: commentData.uid },
            },
          }).finally(onCommentCreated)
        );
      }
      return onCommentCreated();
    },
  });

  const updateCommentSeen = useCallback(
    async (uid) => {
      updateSubmissionLastSeen({
        input: {
          submissionUid: uid,
        },
      });
      if (!hasSeenComment) {
        setHasSeenComment(true);
      }
    },
    [hasSeenComment, updateSubmissionLastSeen]
  );

  useEffect(() => {
    const readComment = getReadSubmissionComment(
      comments,
      submission,
      submission?.patient,
      clinician
    );
    const isSingleSubmissionPatient =
      !showComments && !withAccordion && !clinician;

    const isSingleSubmissionClinician =
      (clinician && !withAccordion) || showComments;

    const isSectionOpened =
      isSingleSubmissionClinician || isSingleSubmissionPatient;

    const isUnreadComment = !readComment.isSeen && comments?.length > 0;

    if (!hasSeenComment) {
      setHasUnreadComment(isUnreadComment);
      if (isUnreadComment && isSectionOpened) {
        updateCommentSeen(submission.uid);
      }
    }
  }, [
    clinician,
    comments,
    hasSeenComment,
    showComments,
    submission,
    updateCommentSeen,
    withAccordion,
  ]);

  const resolvedCommentUid = get(
    submission,
    'triggeredAlerts[0].resolvingComment.uid'
  );

  const isCommentDisabled = isLoading || isEmpty(comment.content);
  const mappedCommentObj = comments?.map((cObj) => ({
    uid: cObj?.uid,
    name: cObj?.clinician?.name || submission?.patient?.name,
    postedOn: cObj.createdOn
      ? format(parseISO(cObj?.createdOn), 'dd MMM yyyy, HH:mm')
      : '',
    comment: cObj?.content,
    resolved: cObj?.uid === resolvedCommentUid,
    designation: cObj?.clinician?.designation,
    visibleTo: cObj?.visibleTo,
  }));

  const onMarkResolved = () => {
    onEditComment({ resolved: !comment.resolved });
  };

  const onSaveComment = () => {
    if (isEmpty(comment.content)) {
      return;
    }

    createComment({
      input: {
        submission: { uid: submission.uid },
        content: comment.content,
        visibleTo: selectedVisibility?.value,
      },
    });
    setShowComments(true);
  };

  const isAlertsUnresolved = useMemo(() => {
    if ('isAlertsResolved' in submission) {
      return submission?.triggeredAlerts && !submission?.isAlertsResolved;
    }
    return !isResolved;
  }, [isResolved, submission]);

  const commentSectionBodyProps = {
    t,
    submission,
    comment,
    mappedCommentObj,
    onMarkResolved,
    onSaveComment,
    onEditComment,
    isAlertsUnresolved,
    isUserClinician,
    isCommentDisabled,
    withAccordion,
    visibilityOptions,
    selectedVisibility,
    setSelectedVisibility,
    isLoading,
  };

  if (!withAccordion) {
    return <CommentSectionBody {...commentSectionBodyProps} />;
  }

  return (
    <Accordion
      mt={3}
      px="16px"
      py="16px"
      borderRadius="8px"
      border="1px solid #D5D7DE"
      open={showComments}
      // onChange={setShowComments}
      data-testid={`comment_section-${submission.uid}`}
      id={`${submission.uid}-${showComments}`}
    >
      <AccordionHeader
        onToggle={() => setShowComments(!showComments)}
        render={(open) => (
          <RepliesHeader
            open={open}
            repliesCount={comments.length}
            uid={submission.uid}
            hasUnreadComment={hasUnreadComment}
          />
        )}
      />
      <AccordionBody pb={2}>
        <CommentSectionBody {...commentSectionBodyProps} />
      </AccordionBody>
    </Accordion>
  );
};

export default React.memo(CommentSection);
