import React, {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { get, isEmpty } from 'lodash';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box as ChBox,
  Flex as ChFlex,
  Button,
  Text,
} from '@chakra-ui/react';

import {
  Box,
  H1,
  H3,
  Tabs,
  Tab,
  Body,
  TabPanel,
  Flex,
  useApi,
  useAuth,
  Badge,
  FAIcon,
  PrimaryButton,
} from '@fivehealth/botero';

import Config from 'Config';
import { DataType } from 'constants';
import EVENTS from 'constants/events';
import { useModal } from 'context/ModalContext';
import { useAppData } from 'context/AppDataContext';
import {
  getMessageDeliveriesExport,
  getPatientFormsExport,
  getSubmissionsExport,
} from 'AppUtils';

import TabWithFilter from 'components/Tab/TabWithFilter';
import PatientsBulkUpload from 'views/PatientsBulkUpload/PatientsBulkUpload';
import { faUserPlus, faUsers } from '@fortawesome/pro-solid-svg-icons';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import BotCryingAvatar from '../../assets/crying-avatar.svg';
import AllGoodAvatar from '../../assets/all-good-avatar.svg';
import ExportData, {
  defaultExportDataSettings,
} from '../../components/ExportData';
import { EXPORT_DATA_TYPE } from '../../constants';

import EditPatientModal from './PatientEdit.modal';
import AllPatientsSection from './AllPatientsSection';
import MessageTemplateModal from './PatientMessageTemplate.modal';
import MonitoringFormSelectModal from './MonitoringFormSelect.modal';

/* eslint no-template-curly-in-string: 0 */
const PatientList = forwardRef(
  (
    {
      activeTab: defaultTab,
      patientGroupSelection = false,
      onSavePatientsGroup = () => {},
      onRowSelectionCallback,
      onCancelCallback,
      filterOutUids = [],
      defaultGQLVariable = {},
      isViewMode = false,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { client } = useApi();
    const { openModal, closeModal } = useModal();
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    const { sendMessage } = useSendMessageAsync();

    const [
      bulkImportSelectedMonitoringFormAndReminder,
      setBulkImportSelectedMonitoringFormAndReminder,
    ] = useState();

    const history = useHistory();

    const [cookies] = useCookies([Config.cookie.name]);
    const token = cookies && cookies[Config.cookie.name];
    const { user } = useAppData();

    const {
      queries: {
        useClinic,
        useCurrentUser,
        usePatientFormsExportedFormats,
        useSubmissionsExportedFormats,
        usePatientsCount,
        useDeliveriesExportedFormats,
      },
    } = useApi({
      queries: [
        'usePatientFormsCount',
        'useClinic',
        'useCurrentUser',
        'usePatientFormsExportedFormats',
        'useSubmissionsExportedFormats',
        'usePatientFormsPatientsCount',
        'usePatientsCount',
        'useDeliveriesExportedFormats',
      ],
    });

    const { authState } = useAuth();

    const [showBulkImport, setShowBulkImport] = useState(false);

    const allPatientsSectionRef = useRef();

    const { data: clinician } = useCurrentUser({
      enabled: !user?.isPatientFacing,
    });

    const { data: clinic } = useClinic({
      enabled: authState.authenticated,
      staleTime: Infinity,
    });

    const { data: activePatientCount, refetch: refetchActivePatientCount } =
      usePatientsCount({
        enabled: true,
        variables: { activated: true },
      });

    const {
      data: dischargedPatientCount,
      refetch: refetchDischargedPatientCount,
    } = usePatientsCount({
      enabled: true,
      variables: { activated: false },
    });

    const { data: exportDataFormatsPatientForms } =
      usePatientFormsExportedFormats({
        enabled: true,
      });

    const { data: deliveriesExportedFormats } = useDeliveriesExportedFormats({
      enabled: true,
    });

    const { data: exportDataFormatsSubmissions } =
      useSubmissionsExportedFormats({
        enabled: true,
      });

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const [enableRowSelect, setEnableRowSelect] = useState(false);

    const [autoResetSelectedRows, setAutoResetSelectedRows] = useState(false);

    useImperativeHandle(ref, () => ({
      async getSelectedPatients() {
        return allPatientsSectionRef.current.getSelectedPatients();
      },
    }));

    const onRefresh = useCallback(async () => {
      await refetchActivePatientCount();
      await refetchDischargedPatientCount();
      queryClient.invalidateQueries('patients');
    }, [refetchActivePatientCount, refetchDischargedPatientCount, queryClient]);

    useEffect(() => {
      onRefresh();
      if (user?.isPatientFacing) {
        window.history.replaceState(null, '', '/');
      }
    }, [user?.isPatientFacing, onRefresh]);

    const addNewPatientUrl = get(clinic, 'enrollmentForm.url');

    const onSubmitMessageCreate = async ({
      template,
      patientForm,
      userVariables,
      patientUids,
      patientEventUid,
    }) => {
      const templateId = get(template, 'uid');
      const payload = {
        patientUids: !isEmpty(patientUids)
          ? patientUids
          : [patientForm?.patient?.uid],
        messageTemplateSetUid: templateId,
        userVariables,
      };

      if (patientEventUid) {
        payload.patientEventUid = patientEventUid;
      }

      const result = await sendMessage(payload);
      return result;
    };

    const onEditPatientForm = (patientForm, refetchPatientForm) => {
      openModal(
        <EditPatientModal
          patientForm={patientForm}
          closeModal={closeModal}
          clinicData={clinic}
          refetchPatientForm={refetchPatientForm}
          isMobile={isMobile}
          section="patientDetails"
        />,
        {
          style: {
            padding: 24,
            overflow: 'scroll',
          },
        }
      );
    };

    const onSendMessage = (patient, selectedPatients) => {
      openModal(
        <MessageTemplateModal
          patientForm={patient ? { patient } : {}}
          selectedPatients={selectedPatients}
          closeModal={closeModal}
          onSubmit={onSubmitMessageCreate}
        />,
        {
          style: {
            padding: 24,
            overflow: 'scroll',
          },
        }
      );
    };

    const onShowPatientForm = (patientForm, path) => {
      if (!patientForm?.patient || !patientForm?.patient?.uid) {
        return;
      }
      history.push(
        `/patient-list/${patientForm?.patient?.uid}${path ? '/' : ''}${
          path || ''
        }`
      );
    };

    const onDownloadComplete = useCallback(
      (success) => {
        openModal(
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <H3>
              {t(success ? 'Download successful' : 'Download unsuccessful')}
            </H3>
            <Body my={4}>
              {t(
                success
                  ? 'Your download was successful'
                  : 'Error downloading file, please try again.'
              )}
            </Body>
            <Box
              height="120px"
              as="img"
              src={success ? AllGoodAvatar : BotCryingAvatar}
            />
            <PrimaryButton mt={4} onClick={closeModal}>
              {t('Close')}
            </PrimaryButton>
          </Flex>,
          {
            style: {
              width: isMobile ? '90%' : '30%',
              overflow: 'visible',
            },
          }
        );
      },
      [openModal, t, isMobile, closeModal]
    );

    const invokePatientFormsExport = async ({
      outputType,
      exportDestination,
    }) => {
      const { cleoPatientForms } = await getPatientFormsExport(
        queryClient,
        client,
        exportDestination?.email,
        outputType
      );

      onDownloadComplete(
        !isEmpty(
          cleoPatientForms?.exportByEmail || cleoPatientForms?.exportByUrl
        )
      );

      if (!exportDestination?.email) {
        window.open(
          cleoPatientForms?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    };

    const invokeMessageDeliveriesExport = async ({
      outputType,
      exportDestination,
    }) => {
      const { cleoDeliveries } = await getMessageDeliveriesExport(
        queryClient,
        client,
        exportDestination?.email,
        outputType
      );

      onDownloadComplete(
        !isEmpty(cleoDeliveries?.exportByEmail || cleoDeliveries?.exportByUrl)
      );

      if (!exportDestination?.email) {
        window.open(
          cleoDeliveries?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    };

    const invokeSubmissionsExport = async ({
      outputType,
      exportDestination,
    }) => {
      const { cleoSubmissions } = await getSubmissionsExport(
        queryClient,
        client,
        exportDestination?.email,
        outputType
      );

      onDownloadComplete(
        !isEmpty(cleoSubmissions?.exportByEmail || cleoSubmissions?.exportByUrl)
      );

      if (!exportDestination?.email) {
        window.open(
          cleoSubmissions?.exportByUrl?.replace('${session}', token),
          '_blank'
        );
      }
    };

    const exportDataFormats = {
      submissions: {
        formats: exportDataFormatsSubmissions,
        onSubmitCallback: invokeSubmissionsExport,
      },
      patients: {
        formats: exportDataFormatsPatientForms,
        onSubmitCallback: invokePatientFormsExport,
      },
      messages: {
        formats: deliveriesExportedFormats,
        onSubmitCallback: invokeMessageDeliveriesExport,
      },
    };

    const onChange = (index) => {
      setEnableRowSelect(false);
      setActiveTab(index);
      history.push(`/patient-list${index === 1 ? '/discharged' : ''}`);
      queryClient.invalidateQueries('patients');
      queryClient.invalidateQueries('patientForms');
    };

    const renderBadge = (text) => (
      <Badge
        fontWeight={600}
        fontSize={12}
        color="fullShade"
        ml={1}
        borderRadius={8}
        backgroundColor="#E8EAED"
        height={24}
        alignItems="center"
        justifyContent="center"
      >
        {text?.toLocaleString()}
      </Badge>
    );

    const openProgramSelectionModal = useCallback(() => {
      openModal(
        <MonitoringFormSelectModal
          onClose={() => {
            closeModal();
          }}
          callback={(formAndReminder) => {
            setBulkImportSelectedMonitoringFormAndReminder(formAndReminder);
            setShowBulkImport(true);
            closeModal();
          }}
        />,
        {
          style: {
            width: isMobile ? '90%' : '30%',
            overflow: 'visible',
          },
        }
      );
    }, [closeModal, openModal, isMobile]);

    const onFilterApplied = () => {
      setEnableRowSelect(false);
    };

    return (
      <>
        {showBulkImport && (
          <PatientsBulkUpload
            monitoringForm={
              bulkImportSelectedMonitoringFormAndReminder?.selectedForm
            }
            reminder={
              bulkImportSelectedMonitoringFormAndReminder?.selectedReminder
            }
            goBack={() => {
              setShowBulkImport(false);
              setBulkImportSelectedMonitoringFormAndReminder();
              setTimeout(() => {
                onRefresh();
              }, 1500);
            }}
          />
        )}

        {!showBulkImport && (
          <Box py={4}>
            <Flex justifyContent="space-between">
              {!patientGroupSelection && (
                <>
                  <Box>
                    <H1 data-testid="patient_list_header_title_page">
                      {t('Patient List')}
                    </H1>
                    <Body pt={2}>
                      {t(
                        'View and manage all patients and their individual settings'
                      )}
                      .
                    </Body>
                  </Box>

                  <Box display={['none', 'initial', 'initial']}>
                    {clinician?.isCaregiver === false && (
                      <Flex pt={[2, 0]} pl={2}>
                        <Menu>
                          <MenuButton
                            mr={4}
                            borderRadius={8}
                            _hover={{
                              backgroundColor: '#0041C2',
                            }}
                            bg="#256BF6"
                            data-testid="patient_list_add_new_patient_btn"
                            as={Button}
                            fontSize={14}
                            colorScheme="messenger"
                          >
                            {t('Add patient')}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              bg="transparent !important"
                              _hover={{
                                backgroundColor: 'transparent',
                              }}
                            >
                              <ChBox width={400} p={3}>
                                <ChFlex
                                  onClick={() => {
                                    window.open(addNewPatientUrl);
                                  }}
                                  p={3}
                                  _hover={{
                                    backgroundColor: '#F4F6F8',
                                    borderRadius: 8,
                                  }}
                                >
                                  <FAIcon icon={faUserPlus} />
                                  <ChBox ml={2} mt={-1}>
                                    <Text mb={1} fontWeight="600" fontSize={14}>
                                      Add Patient
                                    </Text>
                                    <Text color="#697481" fontSize={14}>
                                      Add a single patient manually
                                    </Text>
                                  </ChBox>
                                </ChFlex>
                                <ChBox
                                  mt={3}
                                  height={1 / 2}
                                  borderBottom="1px solid #E8EAED"
                                />

                                <ChBox pl={2}>
                                  <ChFlex mt={3}>
                                    <FAIcon icon={faUsers} />
                                    <Text ml={2} fontWeight="600" fontSize={14}>
                                      Add multiple patients (Max 500 at a time)
                                    </Text>
                                  </ChFlex>
                                  <ChBox
                                    onClick={() => {
                                      setShowBulkImport(true);
                                    }}
                                    p={3}
                                    _hover={{
                                      backgroundColor: '#F4F6F8',
                                      borderRadius: 8,
                                    }}
                                    ml={8}
                                    mt={2}
                                  >
                                    <Text mb={1} fontWeight="600" fontSize={12}>
                                      Import patient list only
                                    </Text>
                                    <Text color="#697481" fontSize={14}>
                                      Upload an Excel/CSV list of patients
                                    </Text>
                                  </ChBox>

                                  <ChBox
                                    onClick={openProgramSelectionModal}
                                    p={3}
                                    _hover={{
                                      backgroundColor: '#F4F6F8',
                                      borderRadius: 8,
                                    }}
                                    ml={8}
                                    mt={2}
                                  >
                                    <Text mb={1} fontWeight="600" fontSize={12}>
                                      Import & enroll patients
                                    </Text>
                                    <Text color="#697481" fontSize={14}>
                                      Upload an Excel/CSV list of patients and
                                      enroll them into a specific program
                                    </Text>
                                  </ChBox>
                                </ChBox>
                              </ChBox>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        {clinician && (
                          <ExportData
                            logEventProps={{
                              page: 'Patient List Export Data',
                              eventName: EVENTS.EXPORT_PATIENT_LIST,
                            }}
                            triggerElementLabel={t('Download data')}
                            modalParams={{
                              title: t('Download patient data'),
                              showBody: true,
                              showExportDataFormats: true,
                              showExportDestination: true,
                            }}
                            dataFormats={exportDataFormats}
                            dataOptions={
                              defaultExportDataSettings(t).dataOptions
                            }
                            dataType={EXPORT_DATA_TYPE.patients}
                            isDisabled={
                              isEmpty(exportDataFormatsSubmissions) ||
                              isEmpty(exportDataFormatsPatientForms)
                            }
                          />
                        )}
                      </Flex>
                    )}
                  </Box>
                </>
              )}
            </Flex>
            <Box mt={4}>
              {!patientGroupSelection && (
                <Tabs
                  activeTabIndex={activeTab}
                  onChange={onChange}
                  headerProps={{ borderBottom: '1px solid #ccc' }}
                >
                  <Tab
                    style={{
                      visibility: patientGroupSelection ? 'hidden' : 'visible',
                    }}
                    data-testid="patient_list_tab_active"
                  >
                    <Flex fontSize={[14, 'inherit']}>
                      {t(
                        clinic?.activePatientListTableSettings?.title ||
                          'Active'
                      )}
                      {renderBadge(activePatientCount)}
                    </Flex>
                  </Tab>
                  <Tab data-testid="patient_list_tab_discharge">
                    <Flex fontSize={[14, 'inherit']}>
                      {t(
                        clinic?.dischargePatientListTableSettings?.title ||
                          'Deactivated'
                      )}
                      {renderBadge(dischargedPatientCount)}
                    </Flex>
                  </Tab>
                  <TabPanel>
                    <TabWithFilter
                      onFilterApplied={onFilterApplied}
                      onRefresh={onRefresh}
                      openModal={openModal}
                      closeModal={closeModal}
                      showFilterBtn={clinician?.isCaregiver === false}
                      clinic={clinic}
                      deactivated={false}
                      onShowPatientForm={onShowPatientForm}
                      onEditPatientForm={onEditPatientForm}
                      onSendMessage={onSendMessage}
                      exportDataFormats={exportDataFormats}
                      dataType={DataType.Patient}
                      defaultGQLVariable={{
                        deactivatedOn_Isnull: true,
                        orderField:
                          clinic?.activePatientListTableSettings
                            ?.defaultSortField ?? 'ENROLLMENT_DATE',
                        orderDesc:
                          clinic?.activePatientListTableSettings?.orderDesc ??
                          true,
                        first: 10,
                      }}
                      hasTableConfig
                      tableSettings={clinic?.activePatientListTableSettings}
                      enableRowSelect={enableRowSelect}
                      autoResetSelectedRows={autoResetSelectedRows}
                      setAutoResetSelectedRows={setAutoResetSelectedRows}
                      setEnableRowSelect={setEnableRowSelect}
                      totalCount={activePatientCount}
                    >
                      <AllPatientsSection isViewMode={isViewMode} />
                    </TabWithFilter>
                  </TabPanel>
                  <TabPanel>
                    <TabWithFilter
                      onFilterApplied={onFilterApplied}
                      onRefresh={onRefresh}
                      openModal={openModal}
                      closeModal={closeModal}
                      showFilterBtn={clinician?.isCaregiver === false}
                      deactivated
                      clinic={clinic}
                      onShowPatientForm={onShowPatientForm}
                      onSendMessage={onSendMessage}
                      exportDataFormats={exportDataFormats}
                      dataType={DataType.Patient}
                      defaultGQLVariable={{
                        deactivatedOn_Isnull: false,
                        orderField:
                          clinic?.dischargedPatientListTableSettings
                            ?.defaultSortField ?? 'ENROLLMENT_DATE',
                        orderDesc:
                          clinic?.dischargedPatientListTableSettings
                            ?.orderDesc ?? true,
                        first: 10,
                      }}
                      hasTableConfig
                      tableSettings={clinic?.dischargedPatientListTableSettings}
                      enableRowSelect={enableRowSelect}
                      setEnableRowSelect={setEnableRowSelect}
                      autoResetSelectedRows={autoResetSelectedRows}
                      setAutoResetSelectedRows={setAutoResetSelectedRows}
                      totalCount={dischargedPatientCount}
                    >
                      <AllPatientsSection isViewMode={isViewMode} />
                    </TabWithFilter>
                  </TabPanel>
                </Tabs>
              )}
              {patientGroupSelection && (
                <TabWithFilter
                  onFilterApplied={onFilterApplied}
                  onRefresh={onRefresh}
                  openModal={openModal}
                  closeModal={closeModal}
                  showFilterBtn={clinician?.isCaregiver === false}
                  clinic={clinic}
                  deactivated={false}
                  onShowPatientForm={onShowPatientForm}
                  onEditPatientForm={onEditPatientForm}
                  onSendMessage={onSendMessage}
                  exportDataFormats={exportDataFormats}
                  dataType={DataType.Patient}
                  defaultGQLVariable={{
                    deactivatedOn_Isnull: true,
                    orderField: 'ENROLLMENT_DATE',
                    orderDesc: true,
                    first: 10,
                    ...defaultGQLVariable,
                  }}
                  hasTableConfig
                  enableRowSelect={patientGroupSelection && !isViewMode}
                  setEnableRowSelect={setEnableRowSelect}
                  patientGroupSelection={patientGroupSelection}
                  onSavePatientsGroup={onSavePatientsGroup}
                  onRowSelectionCallback={onRowSelectionCallback}
                  onCancelCallback={onCancelCallback}
                  filterOutUids={filterOutUids}
                  autoResetSelectedRows={autoResetSelectedRows}
                  setAutoResetSelectedRows={setAutoResetSelectedRows}
                  totalCount={activePatientCount}
                >
                  <AllPatientsSection
                    isViewMode={isViewMode}
                    ref={allPatientsSectionRef}
                  />
                </TabWithFilter>
              )}
            </Box>
          </Box>
        )}
      </>
    );
  }
);

export default PatientList;
