import React, { useCallback, useMemo, useState } from 'react';
import { chain, includes } from 'lodash';
import {
  Box,
  Flex,
  PrimaryButton,
  Select,
  SecondaryOutlinedButton,
  Text,
  TextLink,
  theme,
  useApi,
} from '@fivehealth/botero';

import { customerSupportEmail } from 'AppUtils';
import DialogTitle from 'components/Dialog/DialogTitle';

import CryingAvatar from '../../../assets/crying-avatar.svg';
import AllGoodAvatar from '../../../assets/all-good-avatar.svg';

const DischargeFormModal = ({
  t,
  onClose,
  patients,
  enrolledMonitoringFormUidsParam,
}) => {
  const [selectedForm, setSelectedForm] = useState();
  const [showExisted, setShowExisted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    queries: { useMonitoringFormsets, usePatientFormEnrollDischarge },
  } = useApi({
    queries: ['useMonitoringFormsets', 'usePatientFormEnrollDischarge'],
  });

  const patientNames =
    patients?.length <= 10
      ? patients?.map((p) => p?.name).join(', ')
      : `${patients?.length} patients selected`;

  const patientUids = patients?.map((p) => p.uid);

  const { mutateAsync: enrollPatientAndMonitoringForm } =
    usePatientFormEnrollDischarge({
      variables: {},
    });

  const { data: monitoringForms, isLoading } = useMonitoringFormsets();

  const enrolledMonitoringFormUids = enrolledMonitoringFormUidsParam;

  const monitoringFormsOptions = useMemo(
    () =>
      chain(monitoringForms)
        .filter((form) => !form?.isEnrollmentForm)
        .filter((form) => includes(enrolledMonitoringFormUids, form?.id))
        .map((form) => ({
          label: form?.label,
          value: form,
        }))
        .value(),
    [enrolledMonitoringFormUids, monitoringForms]
  );

  const handleSubmit = useCallback(async () => {
    await enrollPatientAndMonitoringForm({
      input: {
        patientUids,
        monitoringFormUid: selectedForm?.value?.id,
        operation: 'DISCHARGE',
      },
    }).then(({ cleoPatientFormEnrollDischarge: { results } }) => {
      const discharged = results
        .map((r) => r.isPreviouslyEnrolled === true)
        .reduce((acc, curr) => acc && curr);

      if (discharged) {
        setShowSuccess(true);
        setShowExisted(false);
      } else {
        setShowSuccess(false);
        setShowExisted(true);
      }
    });
  }, [enrollPatientAndMonitoringForm, patientUids, selectedForm?.value?.id]);

  const dialogTitle = useMemo(() => t('Remove program'), [t]);

  return (
    <Box
      p={1}
      width={['100%', '100%', 600]}
      style={{ boxSizing: 'border-box' }}
      data-testid="add-form-modal"
    >
      {showExisted && patients.length === 1 && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            as="img"
            src={CryingAvatar}
            height={120}
            width={120}
            mb={2}
            alt="Bot Avatar"
          />
          <Text
            mt={2}
            color={theme.colors.fullShade}
            fontSize={2}
            fontWeight={600}
          >
            {t('Duplicate Enrollment!')}
          </Text>
          <Text
            mt={2}
            textAlign="center"
            color={theme.colors.darkestShade}
            fontSize={14}
            fontWeight={400}
          >
            {t('Patient has been removed from program.')}
          </Text>
          <Text
            mt={1}
            textAlign="center"
            color={theme.colors.darkestShade}
            fontSize={14}
            fontWeight={400}
          >
            {t(
              'Please refresh this page. If the issue persists, please contact '
            )}
          </Text>
          <TextLink
            text={t('customer support')}
            mt={1}
            style={{
              textDecoration: 'underline',
              color: theme.colors.primary,
            }}
            fontSize={16}
            onClick={() => {
              customerSupportEmail();
            }}
          />
          <PrimaryButton
            mt={3}
            type="submit"
            disabled={isLoading}
            onClick={() => onClose(true)}
          >
            {t('Close')}
          </PrimaryButton>
        </Flex>
      )}
      {showSuccess && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            as="img"
            src={AllGoodAvatar}
            height={120}
            width={120}
            mb={2}
            alt="Bot Avatar"
          />
          <Text
            mt={2}
            color={theme.colors.fullShade}
            fontSize={2}
            fontWeight={600}
          >
            {t('Patient removed successfully!')}
          </Text>
          <Text
            mt={2}
            textAlign="center"
            color={theme.colors.darkestShade}
            fontSize={14}
            fontWeight={400}
          >
            {t('Patient has been removed from program.')}
          </Text>
          <PrimaryButton
            mt={3}
            type="submit"
            disabled={isLoading}
            onClick={() => onClose(true)}
          >
            {t('Close')}
          </PrimaryButton>
        </Flex>
      )}
      {!showSuccess && !showExisted && (
        <>
          <DialogTitle
            label={dialogTitle}
            onClick={() => onClose(false)}
            textProps={{
              fontSize: '24px',
            }}
          />

          <Flex mb={1} mt={6}>
            <Text color={theme.colors.fullShade} mr={1} fontWeight={600}>
              {patients?.length > 1
                ? t('Selected Patients')
                : t('Selected Patient')}
              :
            </Text>
            <Text color="fullShade" fontWeight={400}>
              <Text>{patientNames}</Text>
            </Text>
          </Flex>

          <Box mt={6}>
            <Text
              mb={2}
              color={theme.colors.fullShade}
              fontSize={2}
              fontWeight={600}
            >
              {t('Program')}
            </Text>
            <Text
              mb={1}
              color={theme.colors.darkestShade}
              fontSize="12px"
              fontWeight={600}
            >
              {t('Select program')}
            </Text>
            <Select
              value={selectedForm}
              options={monitoringFormsOptions}
              onChange={(form) => setSelectedForm(form)}
              maxMenuHeight={180}
              menuPlacement="top"
            />
          </Box>
          <Flex mt={6} justifyContent="flex-end">
            <SecondaryOutlinedButton
              data-testid="add-form-modal-close"
              onClick={() => onClose(true)}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              ml={3}
              type="submit"
              disabled={isLoading || !selectedForm}
              onClick={handleSubmit}
            >
              {t('Remove program')}
            </PrimaryButton>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DischargeFormModal;
