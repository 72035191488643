import { Box, Flex, Text } from '@chakra-ui/react';
import Colors from 'constants/colors';
import React, { memo } from 'react';

import { Badge, FAIcon } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export interface FilterButtonProps {
  onClick?: () => void;
  badgeCount?: number;
  label: string;
  textStyle?: object;
  containerStyle?: object;
}

export const FilterButton = memo<FilterButtonProps>(
  ({ badgeCount = 0, onClick, label, textStyle = {}, containerStyle = {} }) => {
    const { t } = useTranslation();

    return (
      <Flex
        style={containerStyle}
        onClick={onClick}
        p="6px 8px"
        mr={3}
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
        borderRadius={8}
        background={
          badgeCount > 0 ? Colors.primaryBlueLight : Colors.lightestShade
        }
      >
        <Text
          style={textStyle}
          fontSize={14}
          color={badgeCount > 0 ? Colors.primary : Colors.fullShade}
        >
          {t(label)}
        </Text>

        {badgeCount > 0 && (
          <Box px={1}>
            <Badge
              bg={Colors.primaryBlueDark}
              color="white"
              fontSize={12}
              fontWeight="600"
            >
              {badgeCount}
            </Badge>
          </Box>
        )}
        <Box ml={1}>
          <FAIcon
            fontWeight="600"
            icon={faChevronDown}
            fontSize="12px"
            color="darkestShade"
          />
        </Box>
      </Flex>
    );
  }
);
