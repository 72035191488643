// import liraries
import { useApi, useAuth, Box } from '@fivehealth/botero';
import { getPermanentSession } from 'AppUtils';
import Config from 'Config';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Lottie from 'react-lottie-player';
import { useEffectOnceConditionally } from 'hooks/useEffectOnceConditionally';
import PartyBot from '../../assets/party-bot.json';

// create a component
const AuthCallback = () => {
  const { login } = useAuth();
  const { client } = useApi();
  const history = useHistory();
  /* eslint-disable-next-line */
  const [_, setCookie] = useCookies([Config.cookie.name]);

  const getSession = useCallback(async () => {
    try {
      const result = await getPermanentSession(client).then((r) => r);
      if (result) {
        const {
          cleoPermanentSession: {
            session: { uid: token },
          },
        } = result;
        login({ token });
        setCookie(Config.cookie.name, token, { path: '/' });
        history.push('/login');
      } else {
        history.push('/');
      }
    } catch (error) {
      throw new Error('Invalid session id, try again...');
    }
  }, [client, history, login, setCookie]);

  useEffectOnceConditionally(() => {
    getSession();
  }, true);

  return (
    <Box
      style={{
        margin: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        width: '50%',
      }}
    >
      <Box>
        <Lottie play animationData={PartyBot} />
      </Box>
    </Box>
  );
};

export default AuthCallback;
