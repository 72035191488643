import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { clinicianFieldsFragment } from './useClinicians';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinician {
    cleoClinician {
      ${clinicianFieldsFragment}
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'currentUser',
  keepPreviousData: true,
  select: ({ cleoClinician }) => cleoClinician,
});
