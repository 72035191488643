import React from 'react';
import formatPhoneNumber from 'lib/formatPhoneNumber';
import { Body, Box, H5, Text, theme } from '@fivehealth/botero';

import { BoxContent } from './Table';

export const ProfileCell = ({ row: { original: rowData } }) => (
  <BoxContent py={2}>
    <Box display={['none', 'none', 'block']}>
      <H5>{rowData.name}</H5>
      <Body small color={theme.colors.darkestShade}>
        {formatPhoneNumber(rowData.phone)}
      </Body>
    </Box>

    <Box display={['block', 'block', 'none']}>
      <Text fontWeight={600} color={theme.colors.fullShade} fontSize={14}>
        {rowData.name}
      </Text>
      <Text color="darkestShade" fontSize={14}>
        {formatPhoneNumber(rowData.phone)}
      </Text>
    </Box>
  </BoxContent>
);

export const ContactsCell = ({ row: { original: rowData } }) => (
  <BoxContent py={2}>
    <Box display={['none', 'none', 'block']}>
      <Body small fontWeight={400} color={theme.colors.fullShade}>
        {formatPhoneNumber(rowData?.phone)}
      </Body>
      <H5 fontWeight={400} color={theme.colors.fullShade}>
        {rowData?.email ?? '-'}
      </H5>
    </Box>
  </BoxContent>
);

export const TableCell = ({ t, value }) => (
  <BoxContent py={2}>
    <Body small>{t(value)}</Body>
  </BoxContent>
);
