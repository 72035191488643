import React from 'react';
import _ from 'lodash';
import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import BotCryingAvatar from '../../assets/crying-avatar.svg';
import BotSearchAvatar from '../../assets/bot-search-avatar.svg';

const PatientsBulkUploadLoadingStates = ({
  action,
  goBack,
  closeModal,
  templateUrl,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    closeModal();
    goBack();
  };

  return (
    <>
      {_.isEqual(action, 'error') && (
        <Box p={4}>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotCryingAvatar} />
          </Flex>
          <Text fontWeight="bold" m={2} textAlign="center">
            Oh no, no users found!
          </Text>
          <Text textAlign="center" m={3}>
            Please ensure that your sheet is formatted correctly. You can
            download our template to ensure that the format is correct.
          </Text>
          <Flex alignItems="center" justifyContent="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={3}
              onClick={handleClose}
            >
              Back to User List
            </SecondaryOutlinedButton>
            <a
              style={{ textDecoration: 'none' }}
              href={templateUrl}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton onClick={closeModal} borderRadius="8px">
                Download Template
              </PrimaryButton>
            </a>
          </Flex>
        </Box>
      )}
      {_.isEqual(action, 'pending') && (
        <Box p={4}>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotSearchAvatar} />
          </Flex>
          <Text textAlign="center" m={3}>
            {t('Searching for patients and instructions')}
          </Text>
          <ProgressBar ml={10} mr={10} />
        </Box>
      )}
    </>
  );
};

export default PatientsBulkUploadLoadingStates;
