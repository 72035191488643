import { useApi } from '@fivehealth/botero';
import useClinic from 'api/queries/useClinic';
import asyncJobTypes from 'constants/asyncJobTypes';
import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';

const useSendMessageAsync = () => {
  const {
    queries: { usePatientMessageCreateAsync },
  } = useApi({
    queries: ['usePatientMessageCreateAsync'],
  });

  const { data: clinic } = useClinic();

  // eslint-disable-next-line no-unused-vars
  const [_, setAsyncJobData] = useRecoilState(asyncJobState);

  const { mutateAsync: createMessageAsync } = usePatientMessageCreateAsync({
    variables: {},
  });

  const sendMessage = async (input) => {
    const result = await createMessageAsync({
      input,
    });

    const asyncJob = get(
      result,
      'cleoMessageTemplateDeliverToPatientAsync.asyncJob',
      null
    );

    if (asyncJob && asyncJob?.status !== 'SUCCESS') {
      const { type } = asyncJobTypes.CleoMessageTemplate;
      const info = asyncJobTypes.CleoMessageTemplate.infoText[asyncJob.status];
      setAsyncJobData({
        type,
        ...info,
        ...asyncJob,
        clinic: clinic?.domain,
      });
    }

    return result;
  };

  return {
    sendMessage,
  };
};

export default useSendMessageAsync;
