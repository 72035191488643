import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query {
    cleoClinicPrograms {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'useClinicProgramsCount',
  keepPreviousData: true,
  select: ({ cleoClinicPrograms }) => get(cleoClinicPrograms, 'totalCount', 0),
});
