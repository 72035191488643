import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber from 'libphonenumber-js';
import { chain, get, pickBy, some, isEmpty } from 'lodash';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  faTimes,
  faPlus,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';

import {
  Box,
  H2,
  Flex,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  useApi,
} from '@fivehealth/botero';

import { getFirstAndLastName } from 'AppUtils';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import CaregiverForm from './CaregiverForm';

const AddCaregiverUserModal = ({
  closeModal,
  user: caregiver,
  caregiverRoleUid,
  showBackButton = false,
  backButtonText = 'Back to Edit Patient Details',
  onBackPress = () => {},
  addCaregiverOnly = false,
}) => {
  const [showServerError, setShowServerError] = useState();
  const { t } = useTranslation();
  const [removedPatients, setRemovedPatients] = useState([]);

  const {
    queries: {
      usePatientForms,
      useCreateClinician,
      useUpdateClinician,
      useCleoClinicianPatientFormRelationsUpdate,
    },
  } = useApi({
    queries: [
      'usePatientForms',
      'useCreateClinician',
      'useUpdateClinician',
      'useCleoClinicianPatientFormRelationsUpdate',
    ],
  });

  const { data: patientFormsData, refetch: refetchPatientForms } =
    usePatientForms({
      variables: {
        activated: true,
      },
    });

  const patientsForms = get(patientFormsData, 'pages', [])
    .flatMap((page) => page || [])
    .filter(Boolean);

  const patientsOpts = patientsForms.map((patientform) => {
    const {
      patient,
      uid,
      monitoringForm: { effectiveName },
    } = patientform;
    return {
      label: `${patient.name} (${effectiveName})`,
      value: uid,
    };
  });

  const isEditView = caregiver && caregiver.uid;

  const details = isEditView && getFirstAndLastName(caregiver.name);

  const caregiverUserData = useMemo(
    () =>
      isEditView
        ? {
            ...caregiver,
            firstName: details?.firstName,
            lastName: details?.lastName,
            communicationMethod: {
              gql: caregiver.communicationMethod,
              label: caregiver.communicationMethod,
              value: caregiver.communicationMethod,
            },
          }
        : {},
    [caregiver, details?.firstName, details?.lastName, isEditView]
  );

  const { mutateAsync: createClinician, isLoading: isCreating } =
    useCreateClinician({
      variables: {},
      // onSuccess: (result) => {
      //   console.log('on create success', result);
      // },
    });

  const { mutateAsync: updateClinician, isLoading: isUpdating } =
    useUpdateClinician({
      variables: {},
    });

  const { mutateAsync: updatePatientRelations } =
    useCleoClinicianPatientFormRelationsUpdate({
      variables: {},
      onSuccess: () => {},
    });

  const isLoading = isUpdating || isCreating;

  const defaultValues = useMemo(
    () => ({
      caregiverUsers: [isEditView ? caregiverUserData : {}],
    }),
    [caregiverUserData, isEditView]
  );

  const { handleSubmit, control, setError, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (isEditView) {
      reset(defaultValues);
    }
  }, [defaultValues, isEditView, reset]);

  useEffect(() => {
    if (isEditView) {
      // get patients assigned to this caregiver
      const updatedCaregiverUserData = {
        ...caregiverUserData,
        patients: chain(caregiver)
          .get('caregiverRelations', [])
          .map(({ patientForm }) => ({
            label: `${patientForm?.patient?.name} (${patientForm?.name})`,
            value: patientForm?.uid,
          }))
          .value(),
      };

      const updatedDefaultValues = {
        caregiverUsers: [updatedCaregiverUserData],
      };
      reset(updatedDefaultValues);
    }
  }, [caregiver, caregiverUserData, isEditView, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'caregiverUsers',
  });

  const updatePatientCaregiverRelations = (
    caregiverUid,
    patients,
    unlink = false
  ) =>
    patients.map((selectedPatient) => {
      // get patient form
      const patientform = patientsForms.find(
        ({ uid }) => uid === selectedPatient.value
      );

      const currentCaregiversUid =
        patientform?.caregivers?.map(({ uid }) => uid) || [];

      const caregiverUids = [
        ...new Set([...currentCaregiversUid, caregiverUid]),
      ];
      const payload = {
        input: {
          caregiverUids: unlink
            ? caregiverUids.filter((x) => x !== caregiverUid)
            : caregiverUids,
          patientFormUid: selectedPatient.value,
          alerteeUids: patientform.alertees.map((alt) => alt.uid),
          icUids: patientform.ics.map((ics) => ics.uid),
        },
      };
      return updatePatientRelations(payload);
    });

  const onSubmit = (data) => {
    const caregiverUsers = data.caregiverUsers.map(
      ({
        phone,
        communicationMethod,
        firstName,
        lastName,
        patients,
        email,
      }) => {
        const formattedPhone = /^\+/.test(phone) ? phone : `+${phone}`;
        const parsedPhone = parsePhoneNumber(formattedPhone);

        const payload = {
          input: {
            name: `${firstName} ${lastName}`.trim(),
            email,
            communicationMethod: get(communicationMethod, 'gql'),
            phone: parsedPhone ? parsedPhone.number : phone,
            role: { uid: caregiverRoleUid },
          },
          patients,
        };

        if (isEditView) {
          payload.input.uid = caregiver.uid;
        }
        return pickBy(payload);
      }
    );

    const promise = isEditView
      ? Promise.all([updateClinician({ input: caregiverUsers[0].input })])
      : Promise.all(
          caregiverUsers.map(({ input }) => createClinician({ input }))
        );

    return promise.then((res) => {
      if (
        some(
          res.map(
            ({ cleoClinicianCreate, cleoClinicianUpdate }) =>
              cleoClinicianCreate || cleoClinicianUpdate
          ),
          (clinician) => isEmpty(clinician)
        )
      ) {
        return setShowServerError(true);
      }

      // Update the caregiver patients relations
      const updateRelationsPromise = isEditView
        ? Promise.all([
            updatePatientCaregiverRelations(
              caregiverUsers[0].input.uid,
              caregiverUsers[0]?.patients || []
            ),
            updatePatientCaregiverRelations(
              caregiverUsers[0].input.uid,
              removedPatients,
              true
            ),
          ])
        : Promise.all([
            res.map(({ cleoClinicianCreate: { cleoClinician } }) => {
              const currentCaregiver = caregiverUsers.find(
                (cg) => cg.input.phone === cleoClinician.phone
              );
              currentCaregiver.input.uid = cleoClinician.uid;
              return updatePatientCaregiverRelations(
                currentCaregiver.input.uid,
                currentCaregiver.patients || []
              );
            }),
          ]);

      return updateRelationsPromise.then(async () => {
        // console.log('Finished updating relations........====>');
        setShowServerError(false);
        await refetchPatientForms();
        return closeModal();
      });
    });
  };

  return (
    <Box width={['100%', 'auto']} style={{ boxSizing: 'border-box' }}>
      {showBackButton && (
        <Flex cursor="pointer" onClick={onBackPress} alignItems="center" pb={2}>
          <Box cursor="pointer" mr="5px" fontSize={12} onClick={closeModal}>
            <FAIcon fontSize={12} icon={faChevronLeft} />
          </Box>
          <H5 color="darkestShade">{t(backButtonText)}</H5>
        </Flex>
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <H2 color="fullShade">
          {isEditView ? t('Edit') : t('Add New')} {t('Family Member')}
        </H2>
        <Box cursor="pointer" color="darkestShade" onClick={closeModal}>
          <FAIcon color="" icon={faTimes} />
        </Box>
      </Flex>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <CaregiverForm
            addCaregiverOnly={addCaregiverOnly}
            patientsOpts={patientsOpts}
            key={field.id}
            isLoading={isLoading}
            control={control}
            field={field}
            index={index}
            setError={setError}
            showServerError={showServerError}
            isEditView={isEditView}
            onRemove={remove}
            removedPatients={removedPatients}
            setRemovedPatients={setRemovedPatients}
            {...formState}
          />
        ))}
        <Flex
          mt={4}
          alignItems="center"
          cursor="pointer"
          onClick={() => (formState.isValid ? append({}) : null)}
          opacity={formState.isValid ? 1 : 0.5}
        >
          {!isEditView && (
            <>
              <FAIcon icon={faPlus} fontSize="auto" color="primary" />
              <H5 ml={1} color="primary">
                {t('Add another family member')}
              </H5>
            </>
          )}
        </Flex>

        {showServerError && (
          <ErrorBanner
            text={t(
              'An error has occured. Please check your input and try submitting again.'
            )}
            mt={3}
          />
        )}
        <Flex mt={2} justifyContent="flex-end">
          <SecondaryOutlinedButton onClick={closeModal}>
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            ml={3}
            type="submit"
            disabled={!formState.isValid || isLoading}
          >
            {t('Save')}
          </PrimaryButton>
        </Flex>
      </form>
    </Box>
  );
};

export default AddCaregiverUserModal;
