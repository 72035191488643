import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoMessageTemplateDeliverToPatientAsync(
    $input: CleoMessageTemplateDeliverToPatientAsyncInput!
  ) {
    cleoMessageTemplateDeliverToPatientAsync(input: $input) {
      asyncJob {
        uid
        status
        isReady
      }
      results {
        patientForm {
          patient {
            uid
            name
          }
          monitoringForm {
            uid
            effectiveName
          }
        }
        messageTemplate {
          communicationMethod
          language
        }
        isDelivered
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientMessageCreateAsync',
  keepPreviousData: false,
});
