import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientChartByPatient($before: DateTime, $after: DateTime) {
    cleoPatientChart(before: $before, after: $after) {
      statistics {
        clinicalParameter {
          uid
          name
          unit
          groupName
          valueType
          sortOrder
          numberPrecision
        }
        count
        average
        values
        strings
        numbers
        submissionTimes
        variables
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientChart',
  keepPreviousData: true,
  select: ({ cleoPatientChart }) => cleoPatientChart,
});
