import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicCalendarEventType(
    $eventTypeDeactivatedOnIsnull: Boolean
    $clinicCalendarUidIn: [String]
  ) {
    cleoClinicCalendarEventTypes(
      eventType_DeactivatedOn_Isnull: $eventTypeDeactivatedOnIsnull
      clinicCalendar_Uid_In: $clinicCalendarUidIn
    ) {
      edges {
        node {
          effectiveEventUrl
          eventType {
            uid
            name
            metadata
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinicCalendarEventTypes',
  keepPreviousData: true,
  select: ({ cleoClinicCalendarEventTypes }) =>
    cleoClinicCalendarEventTypes?.edges?.map(({ node }) => node),
});
