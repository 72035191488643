import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientWithSession {
    cleoPatient {
      uid
      name
      phone
      identifier
      communicationMethod
      deactivatedOn
      isActivated
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientWithSession',
  keepPreviousData: true,
});
