import React, { memo } from 'react';
import { Box } from '@fivehealth/botero';
import Header from 'components/Header/Header';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { domainConfigsUtils } from 'AppUtils';
import Colors from '../../constants/colors';

const Content = memo(
  ({ clinic, clinics, currentUser, refetchUser, ...props }) => (
    <Box
      id="appContent"
      pl={[3, 5]}
      pr={[3, 5]}
      flex={1}
      overflowY="hidden"
      overflowX="hidden"
      {...props}
    >
      <Box maxWidth={1920} m="auto">
        {clinic && (
          <Header
            clinic={clinic}
            clinics={clinics}
            currentUser={currentUser}
            refetchUser={refetchUser}
          />
        )}
        {domainConfigsUtils.getDomainConfigs()?.hippocrates?.region === 'id' &&
          moment().isBefore(moment('9-18-2024').endOf('D')) && (
            <ErrorBanner
              text={
                <Trans
                  i18nKey="Maintenance"
                  defaults="BotMD Care will be unavailable for scheduled maintenance from {{startDateTime}} until approximately {{endDateTime}}. The web portal will not be
              available, and patient queries will be processed after the
              scheduled maintenance. We apologize for any inconvenience. Please
              contact <0>support</0> with any questions."
                  values={{
                    startDateTime: 'September 18th, 2024 9:00pm',
                    endDateTime: 'September 19th, 2024 12:00am',
                  }}
                  components={[
                    <a
                      style={{ color: Colors.primary }}
                      href="https://help.botmdcare.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      support
                    </a>,
                  ]}
                />
              }
            />
          )}
        {props.children}
      </Box>
    </Box>
  )
);
export default Content;
