import React, { useCallback } from 'react';
import { Flex, Box, Text, SearchInput } from '@fivehealth/botero';
import { find, get, isEmpty } from 'lodash';

import { EventSubSource } from 'constants';
import { useMediaQuery } from 'react-responsive';
import Sort from 'components/Sort/Sort';
// import Filter from 'components/Filter/Filter';
import { LoadMoreButton } from 'components/Table/Table';

import { Filters } from 'components/Filters/Filters';
import { RetryStatusCodes } from 'constants/retryStatusCodes';
import { RetryView } from 'views/RetryView/RetryView';
import SubmissionTable from './SubmissionTable';
import { SubmissionBoxWidget } from './Sidepanel';

const NonCompliantSection = ({
  // props
  submissions,
  openDrawer,
  onShowPatientForm,
  onSendMessage,
  onShowSubmission,
  onCommentCreated,
  onDownloadPatientSubmissions,
  tableSettings,
  tableSettingsSinglePatient,
  clinic,
  clinician,

  // tab with filter
  t,
  lastUpdated,
  patientForms,
  requestParams,
  fetchNextPage,
  hasNextPage,
  isFetched,
  isLoading,
  isRefetching,
  isFetchingNextPage,
  // showFilters,
  lastReportFilters,
  statusesFilters,
  clinicalUserFilters,
  monitoringFormsetsFilters,
  onSearch,
  onRefresh,
  onFetchData,
  // onFilterOpen,
  // onFilterCancel,
  onApplyFilters,
  onResetFilters,
  searchText,
  setSearchText,
  totalFetchDataCount,
  pageCount,
  statusCode,
}) => {
  const filterOptions = [
    clinicalUserFilters,
    lastReportFilters,
    monitoringFormsetsFilters,
    statusesFilters,
  ].filter(Boolean);

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const lastReportFilterOptions = filterOptions
    .filter(({ id }) => /lastReport/i.test(id))
    .flatMap(({ data: options }) => options)
    .map(({ id, label, ...props }) => ({
      id,
      title: label,
      desc: id === 'last_24_hours',
      ...props,
    }));

  /* const handleApplyFilter = (filter) => {
    // TODO: Figure out why the lastReportFilter param wasn's set correctly in gql
    const id = chain(filter)
      .get('lastReport')
      .findKey((x) => x)
      .value();

    const filterOption = chain(lastReportFilters)
      .get('data')
      .find({ id })
      .value();

    if (filterOption?.toParams) {
      return onApplyFilters({ ...filter, ...filterOption?.toParams });
    }
    return onApplyFilters(filter);
  }; */

  const onFilterChange = useCallback(
    (filters) => {
      onApplyFilters?.(filters);
      if (isEmpty(filters)) {
        setTimeout(() => {
          onResetFilters?.();
        }, 400);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (statusCode && RetryStatusCodes.includes(statusCode)) {
    return (
      <RetryView onRefresh={onRefresh} isLoading={isLoading || isRefetching} />
    );
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <SearchInput
          inputStyle={{
            paddingBottom: '6px',
            paddingTop: '8px',
          }}
          hideRefresh={isMobile}
          data-testid="patient_monitoring_non_compliant_input"
          placeholder={t('Search patients')}
          inputMinWidth={isMobile ? '100%' : '400px'}
          onSearch={onSearch}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onRefresh={onRefresh}
          isRefreshing={isRefetching}
          lastUpdated={lastUpdated.current}
          totalCount={totalFetchDataCount}
          pageCount={submissions?.length || pageCount}
          refreshButtonProps={{
            style: {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
          }}
        />

        <Box display={['block', 'block', 'none']}>
          <Sort
            title={t('No Report')}
            onChange={(filter) => onFetchData(filter?.toParams)}
            options={lastReportFilterOptions}
            onChecked
            selectedOption={find(lastReportFilterOptions, {
              toParams: { nonCompliantSince: requestParams?.nonCompliantSince },
            })}
          />
        </Box>
        {/* {clinician && !clinician.isCaregiver && (
          <Box display={['none', 'none', 'block']}>
            <Filter
              activeFilters={requestParams}
              filterOptions={filterOptions}
              open={showFilters}
              onOpen={onFilterOpen}
              onResetFilters={onResetFilters}
              onSave={handleApplyFilter}
              onCancel={onFilterCancel}
              showPatientFilter
            />
          </Box>
        )} */}
      </Flex>

      {clinician && !clinician.isCaregiver && (
        <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
          <Filters
            requestParams={{
              orderField: get(requestParams, 'orderField'),
              orderDesc: get(requestParams, 'orderDesc'),
              activated: true,
              nonCompliantSince: get(requestParams, 'nonCompliantSince'),
            }}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        </Box>
      )}

      <Box display={['none', 'none', 'block']}>
        <SubmissionTable
          clinic={clinic}
          clinician={clinician}
          tableSettings={tableSettings}
          tableSettingsSinglePatient={tableSettingsSinglePatient}
          data={patientForms}
          showLoading={isLoading}
          submissionAccessor="lastSubmission"
          onShowSubmission={onShowSubmission}
          onShowPatientForm={onShowPatientForm}
          onSendMessage={onSendMessage}
          onOpenDrawer={openDrawer}
          onFetchData={onFetchData}
          onFetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetched={isFetched}
          isFetchingNextPage={isFetchingNextPage}
          onDownloadSubmissions={onDownloadPatientSubmissions}
          sortMapping={{
            PATIENT_NAME: 'NAME',
            SUBMITTED_ON: 'LAST_SUBMITTED_DATE',
          }}
          initialSortBy={{
            orderField: requestParams?.orderField,
            orderDesc: requestParams?.orderDesc,
          }}
          showDischargedLabel
          eventSubSource={EventSubSource.NonCompliant}
        />
      </Box>
      <Box display={['block', 'block', 'none']}>
        {patientForms?.map((patientForm, index) => (
          <SubmissionBoxWidget
            clinician={clinician}
            key={
              patientForm.lastSubmission
                ? `submission_${index}_${patientForm?.lastSubmission?.uid}`
                : `submission_${index}_${patientForm?.uid}`
            }
            cellData={patientForm}
            submission={patientForm?.lastSubmission || {}}
            patient={patientForm?.patient}
            variant={
              patientForm?.lastSubmission
                ? patientForm?.lastSubmission?.statusColor
                : patientForm?.statusColor
            }
            showSidePanel
            isMobile
            onCommentCreated={onCommentCreated}
            onShowPatientForm={onShowPatientForm}
            onShowSubmission={onShowSubmission}
            onDownloadSubmissions={onDownloadPatientSubmissions}
            onSendMessage={onSendMessage}
            showDischargedLabel={!!patientForm?.isDischarged}
            showActionMenu
          />
        ))}
        {isEmpty(patientForms) && isFetched && (
          <Text fontSize={14} color="darkestSHade" textAlign="center">
            {t('No data available')}.
          </Text>
        )}
        {hasNextPage && (
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            mt={3}
            mb={3}
          >
            <LoadMoreButton
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
              flex={1}
              style={{ height: 50 }}
            >
              <Text color="darkestShade" fontSize={16} fontWeight={600}>
                {!isFetchingNextPage && t('Load more')}
                {isFetchingNextPage && `${t('Loading')}...`}
              </Text>
            </LoadMoreButton>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default React.memo(NonCompliantSection);
