// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const NoShow = memo<IconProps>(({ color, width = 12, height = 15 }) => (
  <Icon>
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.15039 0.75C9.04883 0.75 11.4004 3.10156 11.4004 6V13.4375C11.4004 13.7109 11.1816 13.875 10.9355 13.875C10.8262 13.875 10.7168 13.8477 10.6348 13.7656L9.95117 13.2461C9.89648 13.1914 9.78711 13.1641 9.70508 13.1641C9.56836 13.1641 9.45898 13.2188 9.37695 13.3008L8.20117 14.6406C8.11914 14.7227 8.00977 14.7773 7.87305 14.7773C7.76367 14.7773 7.6543 14.7227 7.57227 14.6406L6.45117 13.3828C6.36914 13.2734 6.25977 13.2461 6.15039 13.2461C6.01367 13.2461 5.9043 13.2734 5.82227 13.3828L4.70117 14.6406C4.61914 14.7227 4.50977 14.7773 4.40039 14.7773C4.26367 14.7773 4.1543 14.7227 4.07227 14.6406L2.89648 13.3008C2.81445 13.2188 2.70508 13.1641 2.56836 13.1641C2.48633 13.1641 2.4043 13.1914 2.32227 13.2461L1.63867 13.7656C1.55664 13.8477 1.44727 13.875 1.33789 13.875C1.0918 13.875 0.900391 13.7109 0.900391 13.4375V6.24609C0.900391 3.34766 3.11523 0.859375 5.98633 0.777344C6.04102 0.777344 6.0957 0.75 6.15039 0.75ZM10.0879 11.9062V6C10.0879 3.83984 8.31055 2.0625 6.15039 2.0625L6.01367 2.08984C3.9082 2.14453 2.18555 4.00391 2.18555 6.24609V11.9062C2.32227 11.8789 2.45898 11.8516 2.56836 11.8516C3.08789 11.8516 3.55273 12.0703 3.88086 12.4531L4.40039 13L4.83789 12.5078C5.16602 12.125 5.63086 11.9336 6.15039 11.9336C6.64258 11.9336 7.10742 12.125 7.43555 12.5078L7.90039 13L8.39258 12.4531C8.7207 12.0703 9.18555 11.8516 9.70508 11.8516C9.81445 11.8516 9.95117 11.8789 10.0879 11.9062ZM4.40039 5.125C4.86523 5.125 5.27539 5.53516 5.27539 6C5.27539 6.49219 4.86523 6.875 4.40039 6.875C3.9082 6.875 3.52539 6.49219 3.52539 6C3.52539 5.53516 3.9082 5.125 4.40039 5.125ZM7.90039 5.125C8.36523 5.125 8.77539 5.53516 8.77539 6C8.77539 6.49219 8.36523 6.875 7.90039 6.875C7.4082 6.875 7.02539 6.49219 7.02539 6C7.02539 5.53516 7.4082 5.125 7.90039 5.125Z"
        fill={color}
      />
    </svg>
  </Icon>
));

export default NoShow;
