import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientMetadataEntryCreate(
    $input: CreateCleoPatientMetadataEntryInput!
  ) {
    cleoPatientMetadataEntryCreate(input: $input) {
      cleoPatientMetadataEntry {
        uid
        parameter {
          name
        }
        extractedForDisplay
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientMetadataEntry',
  keepPreviousData: false,
});
