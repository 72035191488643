import React from 'react';
import { secondsToHours } from 'date-fns';
import { chain, get, isEqual, pickBy, range } from 'lodash';
import styled from 'styled-components';
import {
  Box,
  FAIcon,
  Flex,
  InputField,
  Label,
  Radio,
  Select,
  Text,
  Tooltip,
  theme,
} from '@fivehealth/botero';

import { BoxContentReminder } from '../shared';

const InputTitle = ({ children, ...props }) => (
  <Text
    mb={1}
    color={theme.colors.darkestShade}
    fontSize="12px"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
);

const InputTitleWithTooltip = ({ label, icon, tooltipText, ...props }) => (
  <Flex alignItems="center" {...props}>
    <InputTitle mt={1}>{label}</InputTitle>
    <Tooltip
      tooltip={
        <Text color="white" p={1}>
          {tooltipText}
        </Text>
      }
      toolTipElementProps={{
        maxWidth: 275,
        px: 2,
      }}
      toolTipOptions={{
        placement: 'top',
      }}
    >
      <FAIcon
        icon={icon}
        hover={{ opacity: 0.6 }}
        style={{
          fontSize: 12,
          fontWeight: 900,
          marginLeft: 4,
          color: theme.colors.darkestShade,
        }}
      />
    </Tooltip>
  </Flex>
);

const InputWithSelect = ({
  inputValue,
  inputPlaceholder,
  onInputChange,
  inputProps,
  selectValue,
  selectOptions,
  onSelectChange,
  selectProps = {},
  ...props
}) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    flexDirection={['column', 'column', 'row']}
    {...props}
  >
    <InputField
      mr={1}
      width={['100%', '100%', 200]}
      placeholder={inputPlaceholder}
      value={inputValue}
      onChange={onInputChange}
      {...inputProps}
    />
    <Box ml={[0, 0, 2]} mt={[1, 1, 0]} width="100%">
      <Select
        value={selectValue}
        options={selectOptions}
        onChange={onSelectChange}
        {...selectProps}
      />
    </Box>
  </Flex>
);

const RadioBox = ({ t, onChange, checked, option, disabled = false }) => (
  <Label m={1} width="unset">
    <Flex
      p={1}
      bg={disabled ? theme.colors.lightestShade : 'transparent'}
      alignItems="top"
      justifyContent="left"
      border="1px solid #D5D7DE"
      borderRadius={8}
      maxWidth="333px"
    >
      <Box width="16px" mr={1}>
        <Radio
          id={option.key}
          name={t(option.display)}
          value={option.key}
          width="16px"
          height="16px"
          checked={checked}
          disabled={disabled}
          onChange={() => onChange(option)}
        />
      </Box>
      <Box>
        <Text
          mb={1}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.fullShade}
        >
          {t(option.display)}
        </Text>
        <Text fontSize="14px" color={theme.colors.darkestShade}>
          {t(option.description)}
        </Text>
      </Box>
    </Flex>
  </Label>
);

const TextButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`;

const ReminderBox = ({ t, active, label, reminder, ...props }) => (
  <Box {...props}>
    <Text
      p="5px"
      fontSize="14px"
      fontWeight={500}
      color={active ? theme.colors.primary : theme.colors.fullShade}
    >
      {label}
    </Text>
    <BoxContentReminder
      t={t}
      width="auto"
      data={reminder}
      iconProps={{
        style: {
          paddingRight: 10,
          color: active ? theme.colors.primary : theme.colors.fullShade,
        },
      }}
      textProps={{
        color: active ? theme.colors.primary : theme.colors.fullShade,
      }}
    />
  </Box>
);

const isRecurring = (reminder) =>
  !chain(reminder)
    .get('settings.schedule.recurring_frequencies')
    .isUndefined()
    .value();

const getSeconds = (duration, unit) => {
  switch (unit) {
    case 'hour':
      return duration * 3600;
    case 'day':
      return duration * 3600 * 24;
    case 'week':
      return duration * 3600 * 24 * 7;
    case 'month':
      return duration * 3600 * 24 * 7 * 4;
    default:
      return duration;
  }
};

const getSubmissionCompliance = (seconds) => {
  const hour = secondsToHours(seconds);
  const day = hour / 24;
  const week = day / 7;
  const month = week / 4;

  return pickBy({ month, week, day, hour }, (v) => Number.isInteger(v));
};

const defaultDays = [
  { id: 'mon', label: 'Mon' },
  { id: 'tue', label: 'Tue' },
  { id: 'wed', label: 'Wed' },
  { id: 'thu', label: 'Thu' },
  { id: 'fri', label: 'Fri' },
  { id: 'sat', label: 'Sat' },
  { id: 'sun', label: 'Sun' },
];

const daysInMonth = range(1, 32).map((day) => ({
  id: day,
  value: day,
}));

const ReminderFrequencyUnitOptions = [
  { label: 'Day(s)', value: 'day' },
  { label: 'Week(s)', value: 'week' },
  { label: 'Month(s)', value: 'month' },
];

const ReminderTriggerEventOptions = [
  {
    label: 'Immediately upon enrollment',
    value: 'immediateEnrollment',
  },
  {
    label: 'After patient enrollment',
    value: 'enrollment',
  },
  {
    label: 'Upon patient discharge',
    value: 'immediateDischarge',
  },
  {
    label: 'After patient discharge',
    value: 'discharge',
  },
  {
    label: 'Select exact date & time',
    value: 'datetime',
  },
];

const TriggerEventUnitOptions = [
  { label: 'Minute(s) after trigger event', value: 'minute' },
  { label: 'Hour(s) after trigger event', value: 'hour' },
  { label: 'Day(s) after trigger event', value: 'day' },
  { label: 'Week(s) after trigger event', value: 'week' },
  { label: 'Month(s) after trigger event', value: 'month' },
];

const StartTriggerOptions = [
  { label: '---', value: 'noStart' },
  { label: 'Immediately upon enrollment', value: 'immediateEnrollment' },
  { label: 'After patient enrollment', value: 'enrollment' },
  { label: 'Upon patient discharge', value: 'immediateDischarge' },
  { label: 'After patient discharge', value: 'discharge' },
];

const EndTriggerOptions = [
  { label: '---', value: 'noEnd' },
  { label: 'After patient enrollment', value: 'enrollment' },
  { label: 'Upon patient discharge', value: 'immediateDischarge' },
  { label: 'After patient discharge', value: 'discharge' },
  {
    label: 'After a fixed number of reminders have been sent',
    value: 'count',
  },
];

const PreComplianceUnitOptions = [
  {
    label: 'Hour(s) before reminder',
    value: 'hour',
  },
  {
    label: 'Day(s) before reminder',
    value: 'day',
  },
  {
    label: 'Week(s) before reminder',
    value: 'week',
  },
  {
    label: 'Month(s) before reminder',
    value: 'month',
  },
];

export const PostComplianceUnitOptions = [
  {
    label: 'Hour(s) after reminder',
    value: 'hour',
  },
  {
    label: 'Day(s) after reminder',
    value: 'day',
  },
  {
    label: 'Week(s) after reminder',
    value: 'week',
  },
  {
    label: 'Month(s) after reminder',
    value: 'month',
  },
];

const ReminderTypeOptions = [
  {
    key: 'oneTime',
    display: 'One-time',
    description:
      'Send patients a reminder based on a trigger event like enrollment or discharge',
  },
  {
    key: 'recurring',
    display: 'Recurring',
    description: 'Send patients reminders based on a repeating schedule',
  },
];

const getCustomizeReminderOptions = (clinicReminders, t) => [
  {
    key: 'global',
    label: t('Global defaults'),
    options: clinicReminders,
  },
  {
    key: 'custom',
    label: t('Customize'),
    options: [
      {
        label: t('Customize for this individual'),
        value: 'customize',
        isForClinic: false,
      },
    ],
  },
];

const isValidReminder = (reminder) => {
  const startTrigger = get(reminder.settings, 'schedule.start_trigger');
  const endTrigger = get(reminder.settings, 'schedule.end_trigger');

  if (startTrigger && endTrigger) {
    // same event
    if (isEqual(startTrigger.event, endTrigger.event)) {
      const startTriggerTime = getSeconds(
        startTrigger.duration,
        startTrigger.unit
      );
      const endTriggerTime = getSeconds(endTrigger.duration, endTrigger.unit);

      if (startTriggerTime >= endTriggerTime) {
        return false;
      }
    }

    // start discharge, end enrollment
    if (
      isEqual(startTrigger.event, 'discharge') &&
      isEqual(endTrigger.event, 'enrollment')
    ) {
      return false;
    }
  }

  // backend does not support 0
  if (startTrigger && isEqual(startTrigger.duration, 0)) {
    return false;
  }

  if (endTrigger && isEqual(endTrigger.duration, 0)) {
    return false;
  }

  return true;
};

export {
  getSeconds,
  getSubmissionCompliance,
  InputTitle,
  InputTitleWithTooltip,
  InputWithSelect,
  isRecurring,
  RadioBox,
  ReminderBox,
  TextButton,
  defaultDays,
  daysInMonth,
  EndTriggerOptions,
  ReminderTypeOptions,
  StartTriggerOptions,
  PreComplianceUnitOptions,
  TriggerEventUnitOptions,
  ReminderTriggerEventOptions,
  ReminderFrequencyUnitOptions,
  getCustomizeReminderOptions,
  isValidReminder,
};
