import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';
import { sub, formatISO, startOfDay, endOfDay } from 'date-fns';
import StatusIcon from 'components/StatusIcon/StatusIcon';

import { buildPaginatedResponse } from 'lib/pagination';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { patientFragment } from './usePatientUpdate';
import { mapSubmission } from './useSubmissions';
import useMonitoringFormsets from './useMonitoringFormsets';

export const cleoPatientFormFragment = `
  uid
  createdOn
  lastSubmission {
    uid
    submittedOn
    lastSeenByPatientOn
    lastSeenOn
    triggeredAlerts {
      uid
      alertReasons {
        reason
        submissionValue {
          uid
        }
      }
      resolvingComment {
        uid
      }
    }
    comments {
      uid
      clinician {
        name
        designation
      }
      content
      createdOn
      isSeen
      seenByClinicians {
        uid
      }
      taggedClinicians{
        uid
        name
        isCaregiver
      }
      visibleTo
    }
    values {
      uid
      parameter {
        uid
        sortOrder
        unit
        valueType
        numberPrecision
        name
        groupName
      }
      value
      valueType
      extracted
      extractedForDisplay
    }
  }
  isActivated
  isDischarged
  isEnrolled
  isDeactivated
  deactivatedOn
  caregivers {
    uid
    name
  }
  doNotRemind
  alertees {
    uid
    name
  }
  ics {
    uid
    name
  }
  patient {
   ${patientFragment}
  }
  monitoringForm {
    uid
    name
    url
    settings
    effectiveName
    effectiveSettings
    monitoringFormset {
      uid
      reminders {
        uid
        name
        isForClinic
        settings
        description
      }
    }
    clinicalParameters {
      uid
      name
      valueType
      groupName
      name
      unit
    }
  }
  effectiveReminders {
    uid
    name
    effectiveName
    isForClinic
    settings
    description
  }
  effectiveAlertRules {
    uid
    name
    isForClinic
    parserSettings
    parserImportPath
  }
  variables
  variablesAll
  metadataEntries {
    uid
    parameter {
      uid
      name
      valueType
    }
    value
    extractedForDisplay
  }
`;

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientForms(
    $orderField: PatientFormOrderBy
    $orderDesc: Boolean
    $afterDateTime: DateTime
    $offset: Int
    $patientIds: [String]
    $nonCompliantSince: DateTime
    $activated: Boolean
    $patientName: String
    $clinicianIcUid: [String!]
    $monitoringFormUidIn: [String]
    $hasSubmission: Boolean
		$isAlertsResolved: Boolean
		$isAlertsTriggered: Boolean
    $isLastSubmissionAlertsResolved: Boolean
		$isLastSubmissionAlertsTriggered: Boolean
  ) {
    cleoPatientForms(
      sortBy: $orderField
      sortDesc: $orderDesc
      first: 10
      lastSubmittedAfter: $afterDateTime
      offset: $offset
      patient_Uid_In: $patientIds
      nonCompliantSince: $nonCompliantSince
      deactivatedOn_Isnull: $activated
      patientSearch: $patientName
      icUidIn: $clinicianIcUid
      alerteeUidIn: $clinicianIcUid
      monitoringForm_Uid_In: $monitoringFormUidIn
      hasSubmission: $hasSubmission
      isAlertsResolved: $isAlertsResolved
      isAlertsTriggered: $isAlertsTriggered
      isLastSubmissionAlertsResolved: $isLastSubmissionAlertsResolved
      isLastSubmissionAlertsTriggered: $isLastSubmissionAlertsTriggered
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${cleoPatientFormFragment}
        }
      }
    }
  }
`;

export const today = moment(moment().format('YYYY-MM-DDTHH:00:00')).toDate();

export const dateRange = [
  {
    id: 'today',
    label: 'Today',
    toParams: {
      afterDateTime: formatISO(endOfDay(sub(today, { days: 1 }))),
    },
  },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(today, { days: 6 }))),
    },
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(today, { days: 13 }))),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(today, { days: 29 }))),
    },
  },
];

export const lastReports = [
  ...[2, 4, 12, 24, 48, 72].map((num) => ({
    id: `last_${num}_hours`,
    label: `Last ${num} hours`,
    toParams: {
      nonCompliantSince: formatISO(sub(today, { hours: num })),
    },
  })),
  {
    id: 'last_7_days',
    label: 'Last 7 days',
    toParams: {
      nonCompliantSince: formatISO(
        sub(today, {
          days: 7,
        })
      ),
    },
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    toParams: {
      nonCompliantSince: formatISO(sub(today, { days: 14 })),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      nonCompliantSince: formatISO(sub(today, { days: 30 })),
    },
  },
  {
    id: 'last_60_days',
    label: 'Last 60 days',
    toParams: {
      nonCompliantSince: formatISO(sub(today, { days: 60 })),
    },
  },
  {
    id: 'last_90_days',
    label: 'Last 90 days',
    toParams: {
      nonCompliantSince: formatISO(sub(today, { days: 90 })),
    },
  },
  {
    id: 'last_year',
    label: 'Last year',
    toParams: {
      nonCompliantSince: formatISO(sub(today, { days: 365 })),
    },
  },
];

const usePatientForms = () => (args) => {
  // TODO: React Hook "useMonitoringFormsets" cannot be called inside a callback.
  /* eslint-disable react-hooks/rules-of-hooks */
  const { data: monitoringFormsets } = useMonitoringFormsets({
    variables: {
      isEnrollmentForm: false,
    },
  });

  const { t } = useTranslation();

  const alerts = [
    {
      id: 'normal',
      label: t('Normal'),
      icon: <StatusIcon status="success" />,
      toParams: {
        isLastSubmissionAlertsTriggered: false,
        hasSubmission: true,
      },
    },
    {
      id: 'resolved',
      label: t('Resolved'),
      icon: <StatusIcon status="danger" />,
      toParams: {
        isLastSubmissionAlertsResolved: true,
        isLastSubmissionAlertsTriggered: true,
      },
    },
    {
      id: 'unresolved',
      label: t('Unresolved'),
      icon: <StatusIcon status="warning" />,
      toParams: {
        // isAlertsTriggered: true,
        isLastSubmissionAlertsResolved: false,
        isLastSubmissionAlertsTriggered: true,
      },
    },
    {
      id: 'null',
      label: t('Null'),
      toParams: {
        hasSubmission: false,
      },
    },
  ];

  const onMapNode = (node) => ({
    ...node,
    hasSubmission: !!get(node, 'lastSubmission'),
    lastSubmission: get(node, 'lastSubmission')
      ? mapSubmission(get(node, 'lastSubmission', {}))
      : {},
  });

  const patientForms = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientForms',
    paginate: 'offset',
    filters: {
      alerts: { id: 'alerts', title: 'Alerts', data: alerts },
      lastReport: {
        id: 'lastReport',
        title: 'Select timeframe',
        data: lastReports,
        allowEmpty: false,
      },
      dateRange: { id: 'dateRange', title: 'Date range', data: dateRange },
      monitoringFormsets: {
        id: 'monitoringFormsets',
        title: 'Programs',
        data: monitoringFormsets,
        multiSelect: true,
      },
    },
    getNextPageParam: ({ cleoPatientForms: data } = {}, allPages) => {
      const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
      if (!hasNextPage) {
        return undefined;
      }

      const currentOffset = allPages.flatMap(({ cleoPatientForms }) =>
        get(cleoPatientForms, 'edges', [])
      ).length;
      return currentOffset;
    },
    select: (data) =>
      buildPaginatedResponse(data, 'cleoPatientForms', onMapNode),
    keepPreviousData: true,
    staleTime: 1000 * 30 * 5,
    cacheTime: 1000 * 60 * 5,
  });
  return patientForms(args);
};
export default usePatientForms();
