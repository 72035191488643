import React from 'react';
import { Flex, Label, Text, Select } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';

export default ({ label, optional, children, ...props }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" mb={2}>
      <Flex>
        {label && (
          <Label fontSize={12} color="darkestShade" fontWeight={600} mb={2}>
            {label}
          </Label>
        )}
        {optional && (
          <Text fontSize={12} color="darkestShade">
            {t('Optional')}
          </Text>
        )}
      </Flex>
      <Select {...props}>{children}</Select>
    </Flex>
  );
};
