import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoClinicianDelete($input: DeleteCleoClinicianInput!) {
    cleoClinicianDelete(input: $input) {
      deleteMutationResult {
        uid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'clinicianDelete',
  keepPreviousData: true,
});
