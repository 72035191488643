import { Flex, Select } from '@fivehealth/botero';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputTitle } from './utils';

interface OptionValue {
  label: string;
  value: string;
}

export interface HourMinsSelectorProps {
  defaultHour?: OptionValue | undefined;
  defaultMins?: OptionValue | undefined;
  startingHour?: number;
  startingMins?: number;
  callback?: (time: string) => void;
  showLabels?: boolean;
  allowMultiEntry?: boolean;
}

export interface HourMinsSelectorHandles {
  resetFields: () => void;
}

export const HourMinsSelector = forwardRef<
  HourMinsSelectorHandles,
  HourMinsSelectorProps
>(
  (
    {
      defaultHour,
      defaultMins,
      startingHour = 0,
      callback = (time) => time,
      showLabels = true,
      allowMultiEntry = true,
      startingMins = 0,
    },
    ref
  ) => {
    const previousHour = useRef<string>();
    const [selectedReminderHour, setSelectedReminderHour] = useState<
      OptionValue | undefined
    >(defaultHour);
    const [selectedReminderMins, setSelectedReminderMins] = useState<
      OptionValue | undefined
    >(defaultMins);
    const { t } = useTranslation();

    useImperativeHandle(
      ref,
      () => ({
        resetFields() {
          setSelectedReminderHour(undefined);
          setSelectedReminderMins(undefined);
        },
      }),
      []
    );

    useEffect(() => {
      if (selectedReminderHour && selectedReminderMins) {
        const time = `${selectedReminderHour.value}:${selectedReminderMins.value}`;

        if (previousHour.current !== time) {
          previousHour.current = time;
          callback(time);
          if (allowMultiEntry) {
            setSelectedReminderHour(undefined);
            setSelectedReminderMins(undefined);
          }
        }
      }
    }, [selectedReminderHour, selectedReminderMins, callback, allowMultiEntry]);

    return (
      <Flex flex={1}>
        <Flex flex={1} mt={showLabels ? -3 : 0}>
          {showLabels && <InputTitle>{t('Hour')}</InputTitle>}
          <Select
            className="react-select-container"
            placeholder={t('Hour')}
            value={selectedReminderHour}
            options={[...Array(24).keys()].slice(startingHour, 24).map((h) => ({
              label: `${h}`.length === 1 ? `0${h}` : h,
              value: `${h}`.length === 1 ? `0${h}` : h,
            }))}
            onChange={(hour: OptionValue) => setSelectedReminderHour(hour)}
            width="100%"
          />
        </Flex>

        <Flex flex={1} mt={showLabels ? -3 : 0} ml={2}>
          {showLabels && <InputTitle>{t('Mins')}</InputTitle>}
          <Select
            placeholder={t('Mins')}
            value={selectedReminderMins}
            options={[...Array(60).keys()]
              .slice(startingMins, 60)
              .map((_, index) => ({
                label: `${index}`.length === 1 ? `0${index}` : index,
                value: `${index}`.length === 1 ? `0${index}` : index,
              }))}
            onChange={(mins: OptionValue) => setSelectedReminderMins(mins)}
            width="100%"
          />
        </Flex>
      </Flex>
    );
  }
);
