// import liraries
import {
  Flex,
  Box,
  FAIcon,
  Text,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { PrimaryButton } from '@fivehealth/botero/build/components/Button/PrimaryButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import botGroupSuccess from '../../assets/avatar-group-success.svg';

// create a component
const CreateGroupSuccessModal = ({
  closeModal,
  groupName,
  membersCount,
  title,
  content,
  onViewGroup,
  primaryButtonLabel = 'View group',
  secondaryButtonLabel = 'Back to Patient Groups',
}) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box
        style={{
          alignSelf: 'end',
        }}
        cursor="pointer"
        onClick={closeModal}
      >
        <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
      </Box>
      <Box as="img" src={botGroupSuccess} alt="Bot Avatar" />
      <Box
        mt={2}
        style={{
          textAlign: 'center',
        }}
      >
        <Text fontSize={14} fontWeight={600} mb={2} color="fullShade">
          {t(title || 'Patient group created!')}
        </Text>
        <Text fontSize={14} fontWeight={400} color="fullShade">
          {t(
            content ||
              `Group “${groupName}” with ${membersCount} patients has been created successfully.`
          )}
        </Text>
      </Box>

      <Flex mt={4} alignItems="center">
        <SecondaryOutlinedButton onClick={closeModal}>
          {t(secondaryButtonLabel)}
        </SecondaryOutlinedButton>

        {onViewGroup && (
          <PrimaryButton onClick={onViewGroup} ml={4}>
            {t(primaryButtonLabel)}
          </PrimaryButton>
        )}
      </Flex>
    </Flex>
  );
};

export default CreateGroupSuccessModal;
