/* eslint-disable @typescript-eslint/naming-convention */

export type AsyncStatus =
  | 'WAITING'
  | 'STARTED'
  | 'RUNNING'
  | 'FAILED'
  | 'SUCCESS';

const asyncJobTypes = {
  CleoPatientFormEnrollDischarge: {
    type: 'CleoPatientFormEnrollDischarge',
    infoText: {
      WAITING: {
        info: '{{operation}} patients in progress, This may take a few minutes.',
        title: '{{operation}} Patients',
      },
      STARTED: {
        info: '{{operation}} patients started, This may take a few minutes.',
        title: '{{operation}} Patients',
      },
      RUNNING: {
        info: '{{operation}} patients in progress, This may take a few minutes.',
        title: '{{operation}} patients job started',
      },
      FAILED: {
        info: '{{operation}} patients failed, Please try again.',
        title: '{{operation}} Patients',
      },
      SUCCESS: {
        info: '{{operation}} patients successful. Refresh the page to see the changes.',
        title: '{{operation}} Patients',
      },
    },
  },
  CleoPatientEventDelete: {
    type: 'CleoPatientEventDelete',
    onSuccessCallback: (): void => {
      window.location.reload();
    },
    infoText: {
      WAITING: {
        info: 'Cancelling appointments, This may take a few minutes.',
        title: 'Cancelling appointments Pending',
      },
      STARTED: {
        info: 'Cancelling appointments started, This may take a few minutes.',
        title: 'Cancelling appointments Started',
      },
      RUNNING: {
        info: 'Cancelling appointments in progress, This may take a few minutes.',
        title: 'Cancelling appointments Running',
      },
      FAILED: {
        info: 'Cancelling appointments failed, Please try again.',
        title: 'Cancelling appointments Failed',
      },
      SUCCESS: {
        info: 'Appointments cancelled successfully.',
        title: 'Cancelling appointments Success',
      },
    },
  },
  CleoPatientEventsUpdate: {
    type: 'CleoPatientEventsUpdate',
    onSuccessCallback: (): void => {
      window.location.reload();
    },
    infoText: {
      WAITING: {
        info: 'Updating appointments status pending, This may take a few minutes.',
        title: 'Updating Appointments Pending',
      },
      STARTED: {
        info: 'Updating appointments status started, This may take a few minutes.',
        title: 'Updating Appointments Started',
      },
      RUNNING: {
        info: 'Updating appointments status in progress, This may take a few minutes.',
        title: 'Updating Appointments Running',
      },
      FAILED: {
        info: 'Updating appointments status failed, Please try again.',
        title: 'Updating Appointments Failed',
      },
      SUCCESS: {
        info: 'Appointments status updated successfully.',
        title:
          'Updating Appointments Success. Refresh the page to see the changes.',
      },
    },
  },
  CleoPatientImport: {
    type: 'CleoPatientImport',
    infoText: {
      WAITING: {
        info: 'Import in progress, This may take a few minutes.',
        title: 'Importing Patients',
      },
      STARTED: {
        info: 'Import in progress, This may take a few minutes.',
        title: 'Importing Patients',
      },
      RUNNING: {
        info: 'Import in progress, This may take a few minutes.',
        title: 'Importing Patients',
      },
      FAILED: {
        info: 'Import failed, Please try again.',
        title: 'Import Failed',
      },
      SUCCESS: {
        info: 'Patients added successfully.',
        title: 'Import Completed',
      },
    },
  },
  CleoMessageTemplate: {
    type: 'CleoMessageTemplate',
    infoText: {
      WAITING: {
        info: 'Sending message, This may take a few minutes.',
        title: 'Sending Message',
      },
      STARTED: {
        info: 'Sending message in progress, This may take a few minutes.',
        title: 'Sending Message',
      },
      RUNNING: {
        info: 'Sending message in progress, This may take a few minutes.',
        title: 'Sending Message',
      },
      FAILED: {
        info: 'Sending message failed, Please try again.',
        title: 'Sending Message',
      },
      SUCCESS: {
        info: 'Message sent successfully.',
        title: 'Sending Message',
      },
    },
  },
};

export default asyncJobTypes;
