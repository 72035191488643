import React, { useEffect } from 'react';
import { find, get, has, includes, isEqual, isUndefined, map } from 'lodash';
import { Box, InputField, Select, Text, theme } from '@fivehealth/botero';
import {
  InputTitle,
  InputWithSelect,
  StartTriggerOptions,
  EndTriggerOptions,
  TriggerEventUnitOptions,
} from './utils';

export const getDefaultTriggerUnit = (reminder, triggerType) => {
  const unit = isEqual(triggerType, 'start_trigger')
    ? get(reminder, 'settings.schedule.start_trigger.unit')
    : get(reminder, 'settings.schedule.end_trigger.unit');

  if (!isUndefined(unit)) {
    return find(TriggerEventUnitOptions, { value: unit });
  }

  return find(TriggerEventUnitOptions, { value: 'minute' });
};

export const getDefaultTrigger = (reminder, triggerKey) => {
  const trigger = get(reminder?.settings?.schedule, triggerKey);

  const reminderOptions = isEqual(triggerKey, 'start_trigger')
    ? StartTriggerOptions
    : EndTriggerOptions;

  if (isEqual(triggerKey, 'end_trigger')) {
    if (
      !isUndefined(
        get(reminder, 'settings.schedule.recurring_frequencies[0].count')
      )
    ) {
      return find(reminderOptions, { value: 'count' });
    }
  }

  if (!isUndefined(trigger)) {
    if (isEqual(trigger?.event, 'enrollment')) {
      if (!has(trigger, 'duration')) {
        return find(reminderOptions, { value: 'immediateEnrollment' });
      }
      return find(reminderOptions, { value: 'enrollment' });
    }

    if (isEqual(trigger?.event, 'discharge')) {
      if (!has(trigger, 'duration')) {
        return find(reminderOptions, { value: 'immediateDischarge' });
      }
      return find(reminderOptions, { value: 'discharge' });
    }
  }

  return find(reminderOptions, {
    value: isEqual(triggerKey, 'start_trigger') ? 'noStart' : 'noEnd',
  });
};

export const convertReminderToFormState = (reminder, additionalState = {}) => ({
  uid: reminder?.uid,
  edit: false,
  reminderStart: getDefaultTrigger(reminder, 'start_trigger'),
  reminderStartUnit: getDefaultTriggerUnit(reminder, 'start_trigger'),
  reminderStartDuration: get(
    reminder,
    'settings.schedule.start_trigger.duration',
    '1'
  ),
  reminderRecurringLimit: get(
    reminder,
    'settings.schedule.recurring_frequencies[0].count',
    '1'
  ),
  reminderEnd: getDefaultTrigger(reminder, 'end_trigger'),
  reminderEndUnit: getDefaultTriggerUnit(reminder, 'end_trigger'),
  reminderEndDuration: get(
    reminder,
    'settings.schedule.end_trigger.duration',
    '1'
  ),
  ...additionalState,
});

const ReminderDuration = ({
  t,
  isMobile,
  reminder,
  formState,
  setFormState,
}) => {
  const updateFormState = (key, option) => {
    setFormState({
      ...formState,
      duration: {
        ...formState.duration,
        edit: true,
        [key]: option,
      },
    });
  };

  const onChangeReminderStart = (option) => {
    // fixed number of reminder cannot have empty start_trigger
    if (
      isEqual(option.value, 'noStart') &&
      isEqual(formState.duration.reminderEnd.value, 'count')
    ) {
      setFormState({
        ...formState,
        duration: {
          ...formState.duration,
          edit: true,
          reminderEnd: find(EndTriggerOptions, {
            value: 'noEnd',
          }),
          reminderStart: option,
        },
      });
    } else {
      updateFormState('reminderStart', option);
    }
  };

  const onChangeReminderEnd = (option) => {
    // fixed number of reminder cannot have empty start_trigger
    if (
      isEqual(option.value, 'count') &&
      isEqual(formState.duration.reminderStart.value, 'noStart')
    ) {
      setFormState({
        ...formState,
        duration: {
          ...formState.duration,
          edit: true,
          reminderStart: find(StartTriggerOptions, {
            value: 'immediateEnrollment',
          }),
          reminderEnd: option,
        },
      });
    } else {
      updateFormState('reminderEnd', option);
    }
  };

  const showReminderNumberSettings = (option) =>
    !isUndefined(option) && isEqual(option, 'count');

  const showSendReminderSettings = (option) =>
    !isUndefined(option) &&
    !includes(
      [
        'count',
        'immediateDischarge',
        'immediateEnrollment',
        'noStart',
        'noEnd',
      ],
      option
    );

  useEffect(() => {
    if (
      isUndefined(formState.duration) ||
      !isEqual(get(formState, 'duration.uid'), reminder?.uid)
    ) {
      setFormState({
        ...formState,
        duration: convertReminderToFormState(reminder),
      });
    }
  }, [formState, reminder, setFormState]);

  const showDuration = () => {
    // one time reminder
    if (isUndefined(reminder?.settings?.schedule?.recurring_frequencies)) {
      return false;
    }

    return true;
  };

  return (
    <>
      {showDuration() && (
        <Box mb={2}>
          <Text mb={2} mt={2} fontSize="14px" fontWeight={500}>
            {t(`Reminder start`)}
          </Text>

          <InputTitle>{t('Reminders will start')}</InputTitle>
          <Select
            value={get(formState.duration, 'reminderStart')}
            options={map(StartTriggerOptions, (option) => ({
              ...option,
              label: t(option.label),
            }))}
            onChange={onChangeReminderStart}
          />
          {reminder.isReminderSent && (
            <Text
              mt={1}
              mb={3}
              fontSize="12px"
              fontWeight={500}
              color={theme.colors.darkestShade}
            >
              {t(`This reminder has been sent and can no longer be edited.`)}
            </Text>
          )}
          {showSendReminderSettings(
            get(formState.duration, 'reminderStart.value')
          ) && (
            <>
              <InputTitle>{t('Send reminder')}</InputTitle>
              <InputWithSelect
                inputValue={get(
                  formState.duration,
                  'reminderStartDuration',
                  ''
                )}
                inputPlaceholder="Input duration"
                onInputChange={(e) =>
                  updateFormState('reminderStartDuration', e.target.value)
                }
                inputProps={{
                  type: 'number',
                  min: '1',
                  step: '1',
                  disabled: reminder.isReminderSent,
                }}
                selectValue={get(formState.duration, 'reminderStartUnit')}
                selectOptions={map(TriggerEventUnitOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onSelectChange={(reminderStartUnit) =>
                  updateFormState('reminderStartUnit', reminderStartUnit)
                }
              />
              {reminder.isReminderSent && (
                <Text
                  mt={1}
                  mb={3}
                  fontSize="12px"
                  fontWeight={500}
                  color={theme.colors.darkestShade}
                >
                  {t(
                    `This reminder has been sent and can no longer be edited.`
                  )}
                </Text>
              )}
            </>
          )}

          <Text mb={2} mt={6} fontSize="14px" fontWeight={500}>
            {t(`Reminder end`)}
          </Text>

          <InputTitle>{t('Reminders will end')}</InputTitle>
          <Select
            value={get(formState.duration, 'reminderEnd')}
            options={map(EndTriggerOptions, (option) => ({
              ...option,
              label: t(option.label),
            }))}
            onChange={onChangeReminderEnd}
          />

          {showReminderNumberSettings(
            get(formState.duration, 'reminderEnd.value')
          ) && (
            <>
              <InputTitle mt={2}>{t('Number of reminders sent')}</InputTitle>
              <InputField
                width="100%"
                type="number"
                min="1"
                step="1"
                placeholder={t('Input number')}
                value={get(formState.duration, 'reminderRecurringLimit', '')}
                onChange={(e) =>
                  updateFormState('reminderRecurringLimit', e.target.value)
                }
              />
            </>
          )}

          {showSendReminderSettings(
            get(formState.duration, 'reminderEnd.value')
          ) && (
            <>
              <InputTitle mt={2}>{t('Send reminder')}</InputTitle>
              <InputWithSelect
                inputValue={get(formState.duration, 'reminderEndDuration', '')}
                inputPlaceholder="Input duration"
                onInputChange={(e) =>
                  updateFormState('reminderEndDuration', e.target.value)
                }
                inputProps={{
                  type: 'number',
                  min: '1',
                  step: '1',
                }}
                selectValue={get(formState.duration, 'reminderEndUnit')}
                selectOptions={map(TriggerEventUnitOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onSelectChange={(reminderEndUnit) =>
                  updateFormState('reminderEndUnit', reminderEndUnit)
                }
              />
            </>
          )}
        </Box>
      )}
      {!showDuration() && (
        <Text
          textAlign="center"
          fontSize="14px"
          fontWeight={400}
          color={theme.colors.darkestShade}
          width="100%"
          mt={isMobile ? 0 : 4}
          mb={2}
        >
          {t('One-time reminders do not require a duration to be set.')}
        </Text>
      )}
    </>
  );
};

export default ReminderDuration;
