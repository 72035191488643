import React from 'react';
import {
  Flex,
  Text,
  SecondaryOutlinedButton,
  FAIcon,
} from '@fivehealth/botero';
import { format, isSameMonth, isSameYear } from 'date-fns';
import { es, id, enUS } from 'date-fns/locale';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

const LanguageMapping = {
  es,
  id,
  en: enUS,
};

const DateRangeNavigation = ({
  startDate,
  endDate,
  onPrev,
  onNext,
  currentDateRange,
  language,
  canNavigateNext,
}) => (
  <Flex alignItems="center" minWidth={200}>
    <SecondaryOutlinedButton
      testid="date_range_prev"
      id="dateRangeNavLeft"
      borderColor="#D5D9DE"
      borderRadius="4px"
      p={0}
      height={32}
      width={32}
      onClick={onPrev}
    >
      <FAIcon icon={faChevronLeft} fontSize="14px" color="darkestShade" />
    </SecondaryOutlinedButton>
    {/* <Box as="span">

    </Box> */}
    <Text
      fontSize={18}
      fontWeight={600}
      ml={2}
      mr={2}
      display={['none', 'none', 'initial']}
      width="310px"
      textAlign="center"
    >
      {currentDateRange > 1 ? (
        <>
          {format(
            startDate,
            isSameYear(startDate, endDate) ? 'MMMM dd' : 'MMMM dd, yyyy',
            { locale: LanguageMapping[language] }
          )}{' '}
          -{' '}
          {format(
            endDate,
            isSameMonth(startDate, endDate) ? 'dd, yyyy' : 'MMMM dd, yyyy',
            { locale: LanguageMapping[language] }
          )}
        </>
      ) : (
        <>
          {format(startDate, 'MMMM dd, yyyy', {
            locale: LanguageMapping[language],
          })}
        </>
      )}
    </Text>
    <Text
      testid="date_range_output"
      style={{
        textAlign: 'center',
      }}
      fontSize={18}
      fontWeight={600}
      ml={2}
      mr={2}
      display={['initial', 'initial', 'none']}
      width="200px"
    >
      {currentDateRange > 1 ? (
        <>
          {format(startDate, 'MMM dd', { locale: LanguageMapping[language] })} -{' '}
          {format(
            endDate,
            isSameMonth(startDate, endDate) ? 'dd, yyyy' : 'MMM dd, yyyy',
            { locale: LanguageMapping[language] }
          )}
        </>
      ) : (
        <>
          {format(startDate, 'MMM dd, yyyy', {
            locale: LanguageMapping[language],
          })}
        </>
      )}
    </Text>

    <SecondaryOutlinedButton
      testid="date_range_next"
      id="dateRangeNavRight"
      borderColor="#D5D9DE"
      borderRadius="4px"
      height={32}
      width={32}
      onClick={onNext}
      disabled={!canNavigateNext}
    >
      <FAIcon icon={faChevronRight} fontSize="14px" color="darkestShade" />
    </SecondaryOutlinedButton>
  </Flex>
);

export default DateRangeNavigation;
