import { useState, useEffect, useCallback } from 'react';
import { validateEmail } from 'AppUtils';
import { Flex, Box, H6, InputField } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EXPORT_DATA_DESTINATION } from '../../constants';
import { RadioOption } from '../Select';

const Title = (props) => (
  <H6 pb="10px" fontSize="12px" color="#697481" {...props} />
);

const CustomEmailInput = styled(InputField)`
  color: #111824;
  &:focus {
    outline: none !important;
    border-color: ${(props) => props.color};
    box-shadow: 0 0 0 1px ${(props) => props.color};
  }
`;

const ExportDataDestination = ({
  outputType,
  onChange,
  defaultDestination = null,
  showEmailDestination = true,
  showDownloadDestination = true,
  showEmailRecipient,
}) => {
  const { t } = useTranslation();
  const [downloadFile, setDownloadFile] = useState(true);
  const [email, setEmail] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(false);

  // TODO: Figure out an alternative solution to avoid react-hooks/exhaustive-deps warnings
  const onChangeCallbackProps = useCallback(
    (param) => {
      onChange(param);
    },
    [onChange]
  );

  const onChangeCallback = useCallback(
    () => onChangeCallbackProps,
    [onChangeCallbackProps]
  );

  useEffect(() => {
    if (!outputType) {
      setDownloadFile(true);
      onChangeCallback({ downloadFile: true, email: '' });
      setShowEmailInput(false);
      setEmail('');
    }

    if (outputType === 'pdf' && showEmailRecipient === false) {
      onChangeCallback({ downloadFile: true, email: '' });
      setDownloadFile(true);
      setShowEmailInput(false);
      setEmail('');
    }

    if (
      showEmailDestination === true &&
      showEmailRecipient === undefined &&
      showEmailInput === true
    ) {
      setDownloadFile(false);
      onChangeCallback({ downloadFile: false, email });
      setShowEmailInput(true);
    }

    if (
      showEmailDestination &&
      defaultDestination === EXPORT_DATA_DESTINATION.email
    ) {
      setDownloadFile(false);
      onChangeCallback({ downloadFile: false, email });
      setShowEmailInput(true);
    }
  }, [
    onChangeCallback,
    defaultDestination,
    outputType,
    showEmailDestination,
    showEmailRecipient,
    email,
    showEmailInput,
  ]);

  return (
    <Box pt={2}>
      <Box>
        <Title>{t('Download method')}</Title>
      </Box>

      <Flex alignItems="center" width={['100%', '70%']}>
        {showDownloadDestination && (
          <RadioOption
            data-testid="export_destination_radio_opt_download_file"
            checked={downloadFile}
            onChange={() => {
              setDownloadFile(true);
              onChange({ downloadFile: true, email: '' });
              setEmail('');
              setShowEmailInput(false);
            }}
            id="download"
            label={t('Download file')}
            value="download"
            disabled={!outputType}
          />
        )}
        {showEmailDestination && (
          <RadioOption
            data-testid="export_destination_radio_opt_send_to_email"
            checked={showEmailInput}
            onChange={() => {
              setDownloadFile(false);
              setShowEmailInput(true);
              setEmail('');
              onChange({ downloadFile: false, email: '' });
            }}
            id="email"
            label={t('Send to email')}
            value="email"
            disabled={!outputType}
          />
        )}
      </Flex>

      {showEmailInput && showEmailRecipient && (
        <Box mt="24px">
          <Title>{t('Recipient email address')}</Title>
          <CustomEmailInput
            data-testid="export_destination_custom_email_input"
            id="export_destination_custom_email_input_id"
            color={validateEmail(email) ? '#256BF6' : '#E05138'}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setDownloadFile(false);
              if (validateEmail(e.target.value)) {
                onChange({ downloadFile: false, email: e.target.value });
              } else {
                onChange({ downloadFile: false, email: '' });
              }
            }}
            width="100%"
            placeholder={t('Recipient email address')}
            rules={{ required: true }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ExportDataDestination;
