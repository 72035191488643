import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoSubmissionLastSeenUpdateAll(
    $input: CleoSubmissionLastSeenUpdateAllInput!
  ) {
    cleoSubmissionLastSeenUpdateAll(input: $input) {
      submissionUids
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'updateAllCommentsSeen',
  keepPreviousData: true,
});
