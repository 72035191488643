/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';
import { alerts, lastReports, dateRange } from './useSubmissions';
// import createQuery from '../createQuery';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissions(
    $orderField: SubmissionOrderBy
    $orderDesc: Boolean
    $first: Int
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
    $patientFormUids: [String]
    $isAlertsTriggered: Boolean
    $isAlertsResolved: Boolean
    $hasUnseenCommentsAndVisibleToEverybody: Boolean
  ) {
    cleoSubmissions(
      sortBy: $orderField
      sortDesc: $orderDesc
      first: $first
      beforeDateTime: $beforeDateTime
      afterDateTime: $afterDateTime
      patientForm_Uid_In: $patientFormUids
      isAlertsTriggered: $isAlertsTriggered
      isAlertsResolved: $isAlertsResolved
      hasUnseenCommentsAndVisibleToEverybody: $hasUnseenCommentsAndVisibleToEverybody
    ) {
      activePatientCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'submissionsCount',
  filters: {
    alerts: { id: 'alerts', title: 'Alerts', data: alerts },
    lastReport: { id: 'lastReport', title: 'No reports', data: lastReports },
    dateRange: { id: 'dateRange', title: 'Date range', data: dateRange },
  },
  select: (data) => get(data, 'cleoSubmissions.activePatientCount', 0),
  // keepPreviousData: true,
});
