import {
  Text,
  H1,
  DataTable,
  FAIcon,
  Flex,
  ActionMenu,
  ActionMenuText,
  ActionMenuItem,
  useApi,
  Body,
  theme,
  SearchInput,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { components, OptionProps } from 'react-select';
import { useDebounce } from 'ahooks';
import QuestionMarkSquare from 'components/icons/questionMarkSquare';
import _, {
  capitalize,
  clone,
  get,
  isEmpty,
  isUndefined,
  toLower,
} from 'lodash';
import moment from 'moment';
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Drawer,
  Box,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import Colors from 'constants/colors';
import { useModal } from 'context/ModalContext';
import MessageTemplateModal from 'views/PatientsList/PatientMessageTemplate.modal';
import useSendMessageAsync from 'hooks/useSendMessageAsync';
import LoadingSpinner from 'components/LoadingOverlay/LoadingSpinner';
import { getAppointmentStatus, getStatusColor } from 'AppUtils';
import { faCog, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// import Filter from 'components/Filter/Filter';
import DropdownMenu, {
  DropdownAction,
} from 'components/DropdownMenu/DropdownMenu';
import { Controller, useForm } from 'react-hook-form';
import {
  Filters,
  FiltersHandles,
  FiltersProps,
} from 'components/Filters/Filters';
import { SelectWithLabel } from 'components/Select';
import {
  PatientUpdateEventInput,
  useUpdateEventsAsync,
} from 'hooks/useUpdateEventsAsync';
import { useMediaQuery } from 'react-responsive';
import { BULK_ACTIONS_SELECTION_OPTION } from 'Config';
import {
  PatientCancelEventInput,
  useCancelEventsAsync,
} from 'hooks/useCancelEventsAsync';
import Check from '../../components/icons/check';
import ChevronDown from '../../components/icons/chevronDown';
import Person from '../../components/icons/person';
import Cancel from '../../components/icons/Cancel';
import AllGoodAvatar from '../../assets/all-good-avatar.svg';
import { Appointment, AppointmentDetails, Patient } from './AppointmentDetails';
import { AddAppointment } from './AddAppointment';
import { AppointmentProgressStatus } from './AppointmentProgressStatus';

interface FiltersOptions {
  statusIn?: string[];
  statusInCon?: string[];
}

interface MessageCreateParams {
  template: {
    uid: string;
  };
  patientForm: {
    patient: {
      uid: string;
    };
  };
  userVariables: {
    [key: string]: string;
  };
  patientUids: string[];
  patientEventUid: string;
}

interface Option {
  value: unknown;
  label: string;
  icon?: ReactNode;
  color?: string;
  bgColor?: string;
  params?: {
    patientShowed?: boolean;
    confirmed?: boolean;
    patientEventUids?: string[];
  };
  variant?: string;
}

interface SelectOptionProps {
  option: Option;
  event?: Appointment;
  onMenuOpen?: () => void;
  onClick?: (item: Option) => void;
  onHover?: () => void;
  onLeave?: () => void;
}

interface AppointmentsProps {
  patientUidIn?: string[];
  showTitle?: boolean;
  selectedTab?: number;
}

export interface ModalProps {
  title?: string | ReactNode;
  body: string | ReactNode;
  closeLabel?: string;
  confirmLabel?: string;
  isDanger?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

const CustomOption = (
  props: {
    children: ReactNode;
    data: { label: string; value: object; icon: ReactNode };
  } & OptionProps
) => {
  const { children, ...rest } = props;
  return (
    <>
      <components.Option {...rest}>
        <Flex alignItems="center">
          <Box mr={2}>{props.data.icon}</Box>
          <Box>{children}</Box>
        </Flex>
      </components.Option>
      {props.data.label === 'No-show' && <Divider width="120%" />}
    </>
  );
};

const SelectOption = memo<SelectOptionProps>(
  ({ option, event, onMenuOpen, onClick, onHover, onLeave }) => {
    const { t } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(option);

    const dropdownRef = useRef<{ setOpen: (val: boolean) => void }>();

    const options: Option[] = useMemo(() => {
      if (toLower(option.value as string) === 'cancelled') {
        return [
          {
            value: 'no_show',
            label: t('No-show'),
            icon: <Cancel color="#E05138" width={24} height={24} />,
            color: Colors.grey,
            bgColor: '#FBEBE9',
            params: {
              patientShowed: false,
              patientEventUids: [event?.uid as string],
            },
          },
        ];
      }

      return [
        {
          value: 'show',
          label: t('Show'),
          icon: <Person color="#27AE60" width={25} height={25} />,
          color: Colors.grey,
          bgColor: '#EBFAF1',
          params: {
            patientShowed: true,
            patientEventUids: [event?.uid as string],
          },
        },
        {
          value: 'no_show',
          label: t('No-show'),
          icon: <Cancel color="#E05138" width={24} height={24} />,
          color: Colors.grey,
          bgColor: '#FBEBE9',
          params: {
            patientShowed: false,
            patientEventUids: [event?.uid as string],
          },
        },
        {
          value: 'confirmed',
          label: t('Confirmed'),
          icon: <Check color="#0154B6" width={32} height={32} />,
          color: Colors.grey,
          bgColor: '#EAF4FF',
          params: {
            confirmed: true,
            patientEventUids: [event?.uid as string],
          },
        },
        {
          value: 'not_confirmed',
          label: t('Not confirmed'),
          icon: <QuestionMarkSquare color="#DD8700" width={24} height={24} />,
          color: Colors.grey,
          bgColor: '#FEF7ED',
          params: {
            confirmed: false,
            patientEventUids: [event?.uid as string],
          },
        },
      ];
    }, [t, option.value, event?.uid]);

    return (
      <Box onMouseOver={onHover} onMouseLeave={onLeave}>
        <ActionMenu
          dropdownRef={(r: { setOpen: (val: boolean) => void }) => {
            if (r) {
              dropdownRef.current = r;
            }
          }}
          label={
            <Flex
              onClick={() => {
                dropdownRef.current?.setOpen(true);
                if (onMenuOpen) {
                  onMenuOpen();
                }
              }}
              minWidth="150px"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="4px"
              overflow="visible"
              bg={selectedOption.bgColor}
              height="30px"
              style={{
                cursor: 'pointer',
                overflow: 'visible',
              }}
              p={1}
            >
              {selectedOption?.icon && (
                <Box mt={-0.5}>{selectedOption.icon}</Box>
              )}
              <Text color="darkestShade" fontWeight="600">
                {selectedOption.label}
              </Text>
              <Box>
                <ChevronDown width={20} height={20} color="#697481" />
              </Box>
            </Flex>
          }
        >
          {options.map((op, index) => (
            <Box key={`${op.value}-${index}`}>
              <ActionMenuItem
                height="30px"
                onClick={() => {
                  setSelectedOption(op);
                  if (onClick) {
                    onClick(op);
                  }
                  dropdownRef.current?.setOpen(false);
                  if (onMenuOpen) {
                    onMenuOpen();
                  }
                }}
              >
                <ActionMenuText>
                  <Flex
                    style={{
                      cursor: 'pointer',
                    }}
                    p={2}
                  >
                    {op?.icon && <Box>{op.icon}</Box>}
                    <Text mt={0.5} ml={2} fontSize={14} fontWeight="400">
                      {op.label}
                    </Text>
                  </Flex>
                </ActionMenuText>
              </ActionMenuItem>
              {index === 1 && <Divider />}
            </Box>
          ))}
        </ActionMenu>
      </Box>
    );
  }
);

const Appointments = memo<AppointmentsProps>(
  ({ patientUidIn = undefined, showTitle = true, selectedTab = 0 }) => {
    const { t } = useTranslation();

    const { control } = useForm({
      mode: 'onChange',
    });

    const filtersRef = useRef<FiltersHandles>();

    const [canOpenFlyout, setCanOpenFlyout] = useState(true);

    const [schedulingData, setSchedulingData] = useState<{
      patientUid: string;
    }>();

    // const errors = useMemo(() => formState.errors, [formState.errors]);

    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const selectedRows = useRef<Appointment[]>([]);
    const dropdownRef = useRef<typeof ActionMenu>();
    const toggleAllRowsSelectedRef = useRef<{
      toggleAllRowsSelected: (val: boolean) => void;
      toggleAllPageRowsSelected: (val: boolean) => void;
    }>();

    const [selectionOptionChosen, setSelectionOptionChosen] =
      useState<string>();

    const [autoResetSelectedRows, setAutoResetSelectedRows] =
      useState<boolean>(false);
    const [enableRowSelect, setEnableRowSelect] = useState<boolean>(false);
    const [rowsSelectedCount, setRowsSelectedCount] = useState<number>(0);

    const [modalProps, setModalProps] = useState<ModalProps>();

    const [bulkStatusChangeSelectedOption, setBulkStatusChangeSelectedOption] =
      useState<Option>();

    const [activeTabIndex, setActiveTabIndex] = useState<number>(selectedTab);

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
    const lastUpdated = useRef(new Date());

    const [searchText, setSearchText] = useState('');

    const [removedSelection, setRemovedSelection] = useState<Appointment[]>([]);

    const [patientEventSelected, setPatientEventSelected] = useState<
      Appointment | undefined
    >(undefined);
    // const [showFilters, setShowFilters] = useState(false);

    const statusOptionsOpened = useRef(false);

    const history = useHistory();

    const {
      queries: {
        usePatientsEvents,
        usePatientEventUpdate,
        usePatientEventDelete,
        usePatientEventsGetCount,
        useClinicCalendarEventTypes,
        useClinicCalendars,
      },
    } = useApi({
      queries: [
        'usePatientsEvents',
        'usePatientEventUpdate',
        'usePatientEventDelete',
        'usePatientEventsGetCount',
        'useClinicCalendarEventTypes',
        'useClinicCalendars',
      ],
    });

    const [sortBy, setSortBy] = useState({
      id: 'CREATED_ON',
      desc: true,
    });

    const [gqlVariables, setGqlVariables] = useState<{
      first: number;
      patientUidIn?: string[];
      searchText?: string;
      sortBy?: string;
      sortDesc?: boolean;
      isUpcoming?: boolean;
      isCanceled?: boolean;
      eventStartOnLt?: string;
    }>({
      first: 10,
      patientUidIn,
    });

    const [filters, setFilters] = useState<FiltersOptions>({});
    const filterRef = useRef<{
      getFilters: () => {
        statusIn?: string[];
        statusInCon?: string[];
        eventEventTypeUidIn?: string[];
        calendarUidIn?: string[];
      };
    }>();

    const statusIn = useMemo(() => {
      return (filters?.statusIn || [])?.concat(filters?.statusInCon || []);
    }, [filters]);

    const queryEnabled = useMemo(() => {
      if (searchText.length > 0 && searchText.length < 3) {
        return false;
      }
      return true;
    }, [searchText.length]);

    const debouncedSearchText = useDebounce(searchText, {
      wait: 500,
    });

    const {
      data: patientEvents,
      refetch: refreshEvents,
      hasNextPage,
      isRefetching: isRefetchingEvents,
      fetchNextPage,
      isFetchingNextPage,
      isFetching: isLoading,
      filters: { statusFilters, confirmationFilters },
    } = usePatientsEvents({
      enabled: queryEnabled,
      variables: {
        ...gqlVariables,
        searchText: debouncedSearchText,
        sortBy: sortBy.id,
        sortDesc: sortBy.desc,
        ...filters,
        statusIn,
      },
    });

    const tabStatusFilters = useMemo(() => {
      if (activeTabIndex === 1) {
        return {
          ...statusFilters,
          data: statusFilters.data.filter(
            ({ id }: { id: string }) => id !== 'canceled'
          ),
        };
      }
      return statusFilters;
    }, [activeTabIndex, statusFilters]);

    const tabConfirmationFilters = useMemo(() => {
      if (activeTabIndex === 1) {
        return {
          ...confirmationFilters,
          data: confirmationFilters.data.filter(
            ({ id }: { id: string }) => id !== 'noShow' && id !== 'showed'
          ),
        };
      }
      return confirmationFilters;
    }, [activeTabIndex, confirmationFilters]);

    const { data: clinicCalendarEventTypes } = useClinicCalendarEventTypes({
      variables: {
        eventTypeDeactivatedOnIsnull: true,
      },
    });

    const { data: clinicCalendars } = useClinicCalendars();

    const eventTypesFilters = useMemo(() => {
      const eventTypes = (clinicCalendarEventTypes ?? []).map(
        ({ uid, name }: { uid: string; name: string }) => ({
          id: uid,
          label: name,
          toParams: {
            eventEventTypeUidIn: [uid],
          },
        })
      );

      return {
        id: 'eventTypes',
        title: t('Event name'),
        data: eventTypes,
        multiSelect: true,
      };
    }, [clinicCalendarEventTypes, t]);

    const calendarsFilters = useMemo(() => {
      const eventTypes = (clinicCalendars ?? []).map(
        ({ uid, name }: { uid: string; name: string }) => ({
          id: uid,
          label: name,
          toParams: {
            calendarUidIn: [uid],
          },
        })
      );

      return {
        id: 'calendars',
        title: t('Calendars'),
        data: eventTypes,
        multiSelect: true,
      };
    }, [clinicCalendars, t]);

    const filterOptions = useMemo(
      () => [
        tabStatusFilters,
        tabConfirmationFilters,
        eventTypesFilters,
        calendarsFilters,
      ],
      [
        tabStatusFilters,
        tabConfirmationFilters,
        eventTypesFilters,
        calendarsFilters,
      ]
    );

    const { data: allPatientEventsCount, refetch: refetchAllEventsCount } =
      usePatientEventsGetCount({
        variables: {
          patientUidIn,
        },
      });

    const { data: upComingEventsCount, refetch: refetchUpcomingEventsCount } =
      usePatientEventsGetCount({
        variables: {
          isUpcoming: true,
          isCanceled: false,
          patientUidIn,
        },
      });

    const now = useMemo(
      () => moment().toISOString().replace('Z', '+00:00'),
      []
    );
    const { data: pastPatientEventsCount, refetch: refetchPastEventsCount } =
      usePatientEventsGetCount({
        variables: {
          eventStartOnLt: now,
          patientUidIn,
        },
      });

    const totalCount = useMemo(
      () => get(patientEvents, 'totalCount', 0),
      [patientEvents]
    );

    const pageCount = useMemo(() => {
      const count = get(patientEvents, 'pages.length', 0);
      const pageSize = get(patientEvents, 'pages[0].length', 0);
      return count * pageSize;
    }, [patientEvents]);

    const { mutateAsync: updatePatientEvent } = usePatientEventUpdate();
    const { mutateAsync: cancelAppointment } = usePatientEventDelete({});

    const { updateEventsStatus } = useUpdateEventsAsync();

    const { cancelEvents } = useCancelEventsAsync();

    const {
      openModal,
      closeModal,
    }: {
      openModal: (body: ReactNode, style: object) => void;
      closeModal: () => void;
    } = useModal();

    const { sendMessage } = useSendMessageAsync();

    const renderName = ({ row }: { row: unknown }) => {
      const patient = get(row, 'original.patient', {}) as Patient;

      return (
        <Box>
          <Text fontWeight="600">{patient.name}</Text>
          <Text fontWeight="400" color="darkestShade">
            {patient.phone}
          </Text>
        </Box>
      );
    };

    const toggleStatusOptionsState = () => {
      if (!statusOptionsOpened.current) {
        statusOptionsOpened.current = true;
      } else {
        setTimeout(() => {
          statusOptionsOpened.current = false;
        }, 300);
      }
    };

    const updateConfirmationStatus = async (event: Option) => {
      toggleStatusOptionsState();
      if (event) {
        await updatePatientEvent({
          input: {
            ...event.params,
          },
        })
          .then(() => {
            refreshEvents();
          })
          .catch(() => {
            setModalProps({
              title: t('Error'),
              body: t('Something went wrong. Please try again.'),
              closeLabel: 'Close',
              onClose: () => {
                setModalProps(undefined);
              },
            });
          });
      }
    };

    const renderStatusDropdown = ({
      value,
      dropdownOption,
      event,
    }: {
      value: string;
      dropdownOption: Option;
      event: Appointment;
    }) => (
      <SelectOption
        onHover={() => {
          setCanOpenFlyout(false);
        }}
        onLeave={() => {
          // Make sure flyout does not open after clicking on the dropdown option
          setTimeout(() => {
            setCanOpenFlyout(true);
          }, 1000);
        }}
        onMenuOpen={toggleStatusOptionsState}
        onClick={updateConfirmationStatus}
        event={event}
        option={{
          value,
          label: dropdownOption.label,
          icon: dropdownOption?.icon,
          color: dropdownOption.color,
          bgColor: Colors.lightestShade,
        }}
      />
    );

    const renderEvent = ({ value }: { value: string }) => <Text>{value}</Text>;
    const renderDate = ({ value }: { value: string }) => {
      const stillUtc = moment.utc(value).toDate();
      const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
      return <Text>{local}</Text>;
    };

    const renderStatus = (value: string, showIcon = true, bgColor?: string) => {
      const colors = getStatusColor(toLower(value), t);
      const status = value.split('_').join(' ');
      return (
        <Flex
          width="fit-content"
          height={30}
          justifyContent="center"
          alignItems="center"
          borderRadius="6px"
          p="8px"
          bg={bgColor ?? colors.bgColor}
        >
          {colors?.icon && showIcon && <Box mr={2}>{colors.icon}</Box>}
          <Box>
            <Text
              fontSize={14}
              textAlign="center"
              color={colors.color}
              fontWeight="600"
            >
              {capitalize(status)}
            </Text>
          </Box>
        </Flex>
      );
    };

    /* eslint-disable */
    const columns = [
      isUndefined(patientUidIn) && {
        id: 'PATIENT_NAME',
        Header: 'Patient',
        Cell: renderName,
        disableSortBy: true,
      },
      {
        id: 'EVENT_START_ON',
        accessor: 'startOn',
        Header: 'Appointment Time',
        Cell: renderDate,
      },

      {
        id: 'status',
        accessor: 'status',
        Header: (
          <div
            style={{
              width: '150px',
            }}
          >
            Status
          </div>
        ),
        Cell: ({ value }: { value: string }) => renderStatus(value, false),
        disableSortBy: true,
      },
      {
        id: 'confirmed',
        accessor: 'isConfirmed',
        Header: 'Confirmation',
        Cell: ({ row }: { row: { original: Appointment } }) => {
          const event = row.original;

          const eventConfirmedOn = event.confirmedOn
            ? moment(event.confirmedOn)
            : undefined;
          const eventPatientShowOn = event.patientShowedOn
            ? moment(event.patientShowedOn)
            : undefined;

          let status = getAppointmentStatus(
            event,
            eventConfirmedOn,
            eventPatientShowOn
          );

          if (status && toLower(event.status) === 'cancelled') {
            return renderStatus(status, true, 'lightestShade');
          }

          const dropdownOption = getStatusColor(
            toLower(status).split(' ').join('_'),
            t
          ) as Option;

          return renderStatusDropdown({
            value: status,
            dropdownOption,
            event,
          });
        },
        disableSortBy: true,
      },
      {
        id: 'event',
        accessor: 'event.eventType.name',
        Header: 'Event name',
        Cell: renderEvent,
        disableSortBy: true,
      },
      {
        id: 'calendarName',
        accessor: 'calendar.name',
        Header: 'Calendar',
        Cell: renderEvent,
        disableSortBy: true,
      },
      {
        id: 'CREATED_ON',
        accessor: 'createdOn',
        Header: 'Booking Created On',
        Cell: renderDate,
      },
    ].filter(Boolean);

    // const data = useRef<Array<Appointment>>(
    //   get(patientEvents, 'pages', [])
    //     .flatMap((page: Appointment) => page)
    //     .filter(Boolean)
    // );

    const gotoAppointments = (patientUid: string) => {
      window.location.href = `/patient-list/${patientUid}/appointments`;
    };

    const gotoReschedule = (eventUrl: string) => {
      window.open(eventUrl, '_blank');
    };

    const onSubmitMessageCreate = useCallback(
      async ({
        template,
        patientForm,
        userVariables,
        patientUids,
        patientEventUid,
      }: MessageCreateParams) => {
        const templateId = get(template, 'uid');

        const payload = {
          patientUids: !isEmpty(patientUids)
            ? patientUids
            : [patientForm?.patient?.uid],
          messageTemplateSetUid: templateId,
          userVariables,
        };

        if (patientEventUid) {
          // @ts-ignore
          payload.patientEventUid = patientEventUid;
        }

        const result = await sendMessage(payload);
        return result;
      },
      [sendMessage]
    );

    const onSendMessage = useCallback(
      (patient?: Patient, selectedPatients?: unknown[]) => {
        openModal(
          // @ts-ignore
          <MessageTemplateModal
            patientForm={patient ? { patient } : {}}
            selectedPatients={selectedPatients}
            closeModal={closeModal}
            isPatientsView={!isEmpty(patient)}
            onSubmit={onSubmitMessageCreate}
          />,
          {
            style: {
              padding: 24,
              overflow: 'scroll',
            },
          }
        );
      },
      [closeModal, openModal, onSubmitMessageCreate]
    );

    const onCancelSuccess = useCallback(
      (isBulk: boolean) => {
        setEnableRowSelect(false);
        setModalProps({
          body: (
            <Flex flexDirection="column" alignItems="center">
              <Flex justifyContent="center" mb={4}>
                <Box height="120px" as="img" src={AllGoodAvatar} />
              </Flex>

              <Text fontWeight="600" fontSize={16} mb={2}>
                {t(`Appointment${isBulk ? 's' : ''} have been cancelled`)}
              </Text>
              <Body mt={1} mb={2} color="darkestShade" fontSize={14} small>
                {moment().format('YYYY-MM-DD HH:MM')}
              </Body>

              <Button
                onClick={() => {
                  setModalProps(undefined);
                }}
                color={theme.colors.emptyShade}
                background={theme.colors.primary}
                colorScheme={theme.colors.primary}
                variant="solid"
              >
                {t('Close')}
              </Button>
            </Flex>
          ),
        });
      },
      [t]
    );

    const confirmCancel = useCallback(
      async (eventUids: string[]) => {
        // @ts-ignore
        const filters = filterRef?.current?.getFilters();
        let payload: PatientUpdateEventInput = {};

        if (eventUids.length === 0) {
          // Bulk cancel
          payload = {
            patientEventFilter: {
              statusIn,
              event_EventType_Uid_In: filters?.eventEventTypeUidIn || [],
              calendar_Uid_In: filters?.calendarUidIn || [],
            },
          };
        } else {
          payload = {
            patientEventUids: eventUids,
          };
        }

        const { cleoPatientEventDelete } = await cancelAppointment(payload);

        if (!isEmpty(cleoPatientEventDelete?.results)) {
          setModalProps(undefined);
          onCancelSuccess(eventUids.length > 1);
        }

        setTimeout(() => {
          refreshEvents();
          refetchUpcomingEventsCount();
        }, 4000);
      },
      [
        cancelAppointment,
        statusIn,
        onCancelSuccess,
        refreshEvents,
        refetchUpcomingEventsCount,
      ]
    );

    const onCancel = useCallback(
      async (eventUid: string) => {
        setModalProps({
          title: t('Are you sure?'),
          body: t(
            'You are about to cancel this appointment. This action cannot be undone.'
          ),
          closeLabel: 'Discard',
          confirmLabel: 'Cancel appointment',
          onConfirm: () => {
            confirmCancel([eventUid]);
          },
          isDanger: true,
          onClose: () => {
            setModalProps(undefined);
          },
        });
      },
      [
        cancelAppointment,
        onCancelSuccess,
        isMobile,
        refetchUpcomingEventsCount,
        refreshEvents,
        t,
      ]
    );

    const renderRowEdit = useCallback(
      (row: { original: Appointment }) => {
        const isCancelled = toLower(row.original.status) === 'cancelled';
        const isPast = toLower(row.original.status) === 'past';

        return (
          <ActionMenu
            onMouseEnter={() => {
              setCanOpenFlyout(false);
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setCanOpenFlyout(true);
              }, 400);
            }}
          >
            {isEmpty(patientUidIn) && (
              <ActionMenuItem
                onClick={() => {
                  toggleStatusOptionsState();
                  gotoAppointments(row.original.patient.uid as string);
                  setTimeout(() => {
                    setCanOpenFlyout(true);
                  }, 400);
                }}
              >
                <ActionMenuText>{t('View appointments')}</ActionMenuText>
              </ActionMenuItem>
            )}
            {!isCancelled && !isPast && (
              <ActionMenuItem
                onClick={() => {
                  toggleStatusOptionsState();
                  gotoReschedule(row.original.rescheduleUrl as string);
                }}
              >
                <ActionMenuText>{t('Reschedule')}</ActionMenuText>
              </ActionMenuItem>
            )}
            <ActionMenuItem
              onClick={() => {
                toggleStatusOptionsState();
                onSendMessage(row.original?.patient as Patient);
              }}
            >
              <ActionMenuText>{t('Send message')}</ActionMenuText>
            </ActionMenuItem>
            {!isCancelled && !isPast && (
              <ActionMenuItem
                divider
                onClick={() => {
                  toggleStatusOptionsState();
                  onCancel(row.original.uid);
                }}
              >
                <ActionMenuText color={Colors.red}>
                  {t('Cancel appointment')}
                </ActionMenuText>
              </ActionMenuItem>
            )}
          </ActionMenu>
        );
      },
      [onSendMessage, patientUidIn, onCancel]
    );

    const renderTab = (title: string, count: string): ReactNode => (
      <Flex>
        <Text fontWeight="500" fontSize={16} color={theme.colors.fullShade}>
          {title}
        </Text>
        <Flex
          alignItems="center"
          ml={1}
          borderRadius={8}
          pl={1}
          pr={1}
          bg={theme.colors.lightShade}
        >
          <Text fontWeight="600" fontSize={12} color={theme.colors.fullShade}>
            {count}
          </Text>
        </Flex>
      </Flex>
    );

    const onSearch = useCallback((search: string) => {
      setSearchText(search);
    }, []);

    const onApplyFilters = (selectedFilters: FiltersProps['requestParams']) => {
      setFilters({
        ...selectedFilters,
      });
      setEnableRowSelect(false);
    };

    const onResetFilters = () => {
      filtersRef?.current?.clearFilters();

      // @ts-ignore
      // filterRef.current?.resetFiltersCount();
      setFilters({});
    };

    const isCancelledOnlyEvents = useMemo(() => {
      // @ts-ignore
      if (!filterRef.current?.getFilters) {
        return false;
      }
      // @ts-ignore
      const filters = filterRef?.current?.getFilters();
      if (filters?.statusIn?.length === 1) {
        return toLower(filters?.statusIn[0]) === 'cancelled';
      }
      return false;
    }, [filterRef.current]);

    const filterAppliedCounts = useCallback(() => {
      return filtersRef?.current?.getFiltersCount() || 0;
    }, []);

    const onChangeTab = (index: number): void => {
      const variables = gqlVariables;
      setEnableRowSelect(false);

      const mapping: { [key: number]: string } = {
        0: '/appointments',
        1: '/appointments/upcoming',
        2: '/appointments/past',
      };

      switch (index) {
        case 0:
          delete variables.eventStartOnLt;
          delete variables.isUpcoming;
          delete variables.isCanceled;
          onResetFilters();
          setGqlVariables({
            ...variables,
          });
          setSortBy({ id: 'CREATED_ON', desc: true });
          refetchAllEventsCount();
          break;
        case 1: {
          delete variables.eventStartOnLt;
          onResetFilters();
          setGqlVariables({
            ...variables,
            isUpcoming: true,
            isCanceled: false,
          });
          setSortBy({ id: 'EVENT_START_ON', desc: true });
          refetchUpcomingEventsCount();
          break;
        }
        case 2:
          delete variables.isUpcoming;
          delete variables.isCanceled;
          onResetFilters();
          setGqlVariables({
            ...variables,
            eventStartOnLt: now,
          });
          setSortBy({ id: 'EVENT_START_ON', desc: true });
          refetchPastEventsCount();
          break;
        default: {
          break;
        }
      }

      // setSortBy({ id: 'EVENT_START_ON', desc: true });

      if (!patientUidIn) {
        history.push(mapping?.[index] || '/appointments');
      }

      setActiveTabIndex(index);
    };

    useEffect(() => {
      onChangeTab(activeTabIndex);
    }, []);

    const data = useMemo(() => {
      return get(patientEvents, 'pages', [])
        .flatMap((page: Appointment) => page)
        .filter(Boolean) as Appointment[];
    }, [patientEvents]);

    const selectAllPagesRows = useCallback(() => {
      selectedRows.current = clone(data);
      setAutoResetSelectedRows(false);
      setEnableRowSelect(true);
      setRowsSelectedCount(totalCount);
      setSelectionOptionChosen(BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS);
      setTimeout(() => {
        toggleAllRowsSelectedRef.current?.toggleAllPageRowsSelected(true);
      }, 200);
    }, [patientEvents]);

    const selectAllVisibleRows = useCallback(
      (visibleData: Appointment[]) => {
        selectedRows.current = clone(visibleData);

        setAutoResetSelectedRows(false);
        setEnableRowSelect(true);
        setRowsSelectedCount(visibleData.length);
        setSelectionOptionChosen(BULK_ACTIONS_SELECTION_OPTION.VISIBLE_ROWS);
        setTimeout(() => {
          toggleAllRowsSelectedRef.current?.toggleAllRowsSelected(true);
        }, 200);
      },
      [patientEvents]
    );

    const triggerCustomSelect = useCallback(() => {
      setAutoResetSelectedRows(false);
      setSelectionOptionChosen(BULK_ACTIONS_SELECTION_OPTION.CUSTOM);
      setEnableRowSelect(true);
      setRowsSelectedCount(0);
      setTimeout(() => {
        toggleAllRowsSelectedRef.current?.toggleAllRowsSelected(false);
      }, 200);
    }, []);

    const dropdownActions = useMemo(
      () =>
        [
          selectedTab === 1 && {
            id: 'selectVisible',
            label: t('Select visible rows'),
            onClick: () => {
              selectAllVisibleRows(data);
            },
          },
          selectedTab === 0 &&
            !isCancelledOnlyEvents &&
            filterAppliedCounts() > 0 && {
              id: 'selectAll',
              label: t('Select all rows'),
              onClick: () => {
                selectAllPagesRows();
              },
            },
          {
            id: 'customSelections',
            divider: selectedTab < 2 ? true : false,
            label: t('Custom selection'),
            onClick: () => {
              triggerCustomSelect();
            },
          },
        ].filter(Boolean) as DropdownAction[],
      [t, data, isCancelledOnlyEvents, filterAppliedCounts]
    );

    const cancelSelectedRows = useCallback((deselect = false) => {
      setAutoResetSelectedRows(true);
      setRowsSelectedCount(0);
      toggleAllRowsSelectedRef.current?.toggleAllRowsSelected(false);
      selectedRows.current = [];
      if (!deselect) {
        setEnableRowSelect(false);
        onSearch('');
      }
    }, []);

    const onCancelConfirm = useCallback(() => {
      if (selectionOptionChosen === BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS) {
        confirmCancel([]);
      } else {
        const uids = selectedRows.current.map((event) => event.uid);
        confirmCancel(uids);
      }
    }, [selectedRows.current, confirmCancel, selectionOptionChosen]);

    const onDeleteAppointmentsConfirm = useCallback(() => {
      setModalProps({
        title: t('Are you sure?'),
        body: (
          <Text>
            {t(
              'You are about to cancel {{count}} appointments. This action cannot be undone.',
              {
                count:
                  selectionOptionChosen ===
                  BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS
                    ? totalCount
                    : selectedRows.current.length,
              }
            )}
          </Text>
        ),
        closeLabel: 'Discard',
        confirmLabel: 'Cancel appointments',
        onConfirm: onBulkCancel,
        isDanger: true,
        onClose: () => {
          setModalProps(undefined);
        },
      });
    }, [
      selectedRows.current,
      t,
      onCancel,
      selectionOptionChosen,
      onCancelConfirm,
    ]);

    const renderRowSelectInfo = useCallback(
      ({
        selectedFlatRows,
        toggleAllRowsSelected,
        toggleAllPageRowsSelected,
      }: {
        selectedFlatRows: Appointment[];
        toggleAllRowsSelected: (val: boolean) => void;
        toggleAllPageRowsSelected: (val: boolean) => void;
      }): ReactNode => {
        toggleAllRowsSelectedRef.current = {
          toggleAllRowsSelected,
          toggleAllPageRowsSelected,
        };

        return (
          <Flex
            mr={3}
            style={{
              borderRadius: 5,
              position: 'fixed',
              bottom: '24px',
              width: '-webkit-fill-available',
            }}
            p={2}
            justifyContent="space-between"
            alignItems="center"
            bg="lightBlue"
          >
            <Flex alignItems="center">
              <Text color="primary" fontWeight="bold" mr={1}>
                {rowsSelectedCount} {t('selected')}
              </Text>

              <SecondaryOutlinedButton
                onClick={() => cancelSelectedRows(true)}
                mr={2}
                bg="white"
              >
                {t('Deselect all')}
              </SecondaryOutlinedButton>
            </Flex>

            <Flex alignItems="center">
              <SecondaryOutlinedButton
                onClick={() => cancelSelectedRows()}
                mr={2}
                bg="white"
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>

              <Box>
                <ActionMenu
                  disabled={rowsSelectedCount === 0}
                  minWidth={150}
                  dropdownRef={(r: unknown) => {
                    dropdownRef.current = r as typeof ActionMenu;
                  }}
                  label={
                    <SecondaryOutlinedButton
                      hover="green"
                      selectedPatients={selectedFlatRows.length > 0}
                      borderColor="transparent"
                      bgHovered={
                        rowsSelectedCount > 0 ? 'primary' : Colors.lightBlue
                      }
                      bg={rowsSelectedCount > 0 ? 'primary' : Colors.lightBlue}
                      borderRadius={8}
                      endIcon={
                        <FAIcon
                          icon={faChevronDown}
                          fontSize="14px"
                          style={{ color: 'white' }}
                        />
                      }
                    >
                      <Body
                        width="100%"
                        color="white"
                        fontFamily="Inter"
                        small
                        medium
                      >
                        {t('Bulk actions')}
                      </Body>
                    </SecondaryOutlinedButton>
                  }
                >
                  <Box>
                    <ActionMenuItem
                      onClick={() => {
                        setShowStatusChangeModal(true);
                      }}
                    >
                      <ActionMenuText>
                        {t('Change confirmation status')}
                      </ActionMenuText>
                    </ActionMenuItem>
                  </Box>
                  <ActionMenuItem divider onClick={onDeleteAppointmentsConfirm}>
                    <ActionMenuText color={Colors.red}>
                      {t('Cancel appointments')}
                    </ActionMenuText>
                  </ActionMenuItem>
                </ActionMenu>
              </Box>
            </Flex>
          </Flex>
        );
      },
      [
        rowsSelectedCount,
        onDeleteAppointmentsConfirm,
        t,
        // sidebarOpened,
        cancelSelectedRows,
      ]
    );

    const handleTableRowClick = (row: {
      original: Appointment;
      isSelected: boolean;
      toggleRowSelected: () => void;
    }) => {
      if (!canOpenFlyout) return;

      if (!enableRowSelect) {
        return setPatientEventSelected(row.original);
      }

      if (toLower(row.original.status) === 'cancelled') {
        alert('This appointment is canceled');
        return;
      }

      if (autoResetSelectedRows) {
        setAutoResetSelectedRows(false);
      }

      const index = removedSelection
        ? removedSelection.findIndex(
            (selectedRow) => selectedRow.uid === row.original.uid
          )
        : -1;

      const selectedRowIndex = selectedRows.current
        ? selectedRows.current.findIndex(
            (selectedRow) => selectedRow.uid === row.original.uid
          )
        : -1;

      if (row.isSelected) {
        setRemovedSelection([...removedSelection, row.original]);
        setRowsSelectedCount(rowsSelectedCount - 1);

        selectedRows.current.splice(selectedRowIndex, 1);
        selectedRows.current = clone(selectedRows.current);
      } else {
        removedSelection.splice(index, 1);
        setRemovedSelection([...removedSelection]);
        setRowsSelectedCount(rowsSelectedCount + 1);

        selectedRows.current = clone([...selectedRows.current, row.original]);
      }

      row.toggleRowSelected();
    };

    const onBulkStatusChange = async (): Promise<void> => {
      if (bulkStatusChangeSelectedOption) {
        setShowStatusChangeModal(false);
        setEnableRowSelect(false);
        const patientEventUids = selectedRows.current.map(
          (row: Appointment) => row.uid
        );

        const filters = filterRef?.current?.getFilters();
        let payload: PatientUpdateEventInput = {};

        if (selectionOptionChosen === BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS) {
          payload = {
            patientEventFilter: {
              statusIn,
            },
            ...(bulkStatusChangeSelectedOption.value as object),
          };

          if (filters?.eventEventTypeUidIn) {
            payload = {
              ...payload,
              patientEventFilter: {
                ...payload.patientEventFilter,
                event_EventType_Uid_In: filters!.eventEventTypeUidIn,
              },
            };
          }
          if (filters?.calendarUidIn) {
            payload = {
              ...payload,
              patientEventFilter: {
                ...payload.patientEventFilter,
                calendar_Uid_In: filters!.calendarUidIn,
              },
            };
          }
        } else {
          payload = {
            patientEventUids,
            ...(bulkStatusChangeSelectedOption.value as object),
          };
        }

        const asyncJob = await updateEventsStatus(payload).then((r) => r);

        if (asyncJob.status === 'SUCCESS') {
          refreshEvents();
        }
      }
    };

    const onBulkCancel = async (): Promise<void> => {
      setEnableRowSelect(false);

      const patientEventUids = selectedRows.current.map(
        (row: Appointment) => row.uid
      );

      const filters = filterRef?.current?.getFilters();
      let payload: PatientCancelEventInput = {};

      if (selectionOptionChosen === BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS) {
        payload = {
          patientEventFilter: {
            statusIn,
          },
        };

        if (filters?.eventEventTypeUidIn) {
          payload = {
            patientEventFilter: {
              ...payload.patientEventFilter,
              event_EventType_Uid_In: filters!.eventEventTypeUidIn,
            },
          };
        }
        if (filters?.calendarUidIn) {
          payload = {
            patientEventFilter: {
              ...payload.patientEventFilter,
              calendar_Uid_In: filters!.calendarUidIn,
            },
          };
        }
      } else {
        payload = {
          patientEventUids,
        };
      }

      const asyncJob = await cancelEvents(payload).then((r) => r);

      if (asyncJob.status === 'SUCCESS') {
        refreshEvents();
      }
    };

    const onFilterChange = useCallback(
      (filters: FiltersProps['requestParams']) => {
        onApplyFilters?.(filters);
        // if (isEmpty(filters)) {
        //   onResetFilters?.();
        // }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <Box
        onMouseLeave={() => {
          statusOptionsOpened.current = false;
          setPatientEventSelected(undefined);
        }}
      >
        <Drawer
          trapFocus={false}
          onClose={() => {
            setPatientEventSelected(undefined);
          }}
          isOpen={!!patientEventSelected}
          size={isMobile ? 'lg' : 'md'}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              {patientEventSelected && (
                <AppointmentDetails
                  refreshEvents={() => {
                    setTimeout(() => {
                      refetchUpcomingEventsCount();
                    }, 2000);
                  }}
                  renderStatus={renderStatus}
                  {...patientEventSelected}
                  onClose={() => {
                    setPatientEventSelected(undefined);
                    refreshEvents();
                  }}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {schedulingData?.patientUid && (
          <AppointmentProgressStatus
            patientUid={schedulingData?.patientUid}
            onClose={() => {
              setSchedulingData(undefined);
              refetchAllEventsCount();
              refreshEvents();
            }}
          />
        )}

        {showTitle && (
          <Flex justifyContent="space-between" mb={6} mt={7}>
            <Box>
              <H1 mb={2}>{t('Appointments')}</H1>
              <Body fontSize={14}>
                {t('View and manage patients appointments here.')}
              </Body>
            </Box>
            <AddAppointment
              onClose={(patientUid: string) => {
                setSchedulingData({
                  patientUid,
                });
              }}
            />
          </Flex>
        )}

        {!patientUidIn && (
          <Tabs
            index={activeTabIndex}
            onChange={onChangeTab}
            mb={6}
            position="relative"
            variant="unstyled"
          >
            <TabList>
              <Tab
                _focus={{
                  outline: 'none',
                }}
              >
                {renderTab('All', allPatientEventsCount)}
              </Tab>
              <Tab
                _focus={{
                  outline: 'none',
                }}
              >
                {renderTab('Upcoming', upComingEventsCount)}
              </Tab>
              <Tab
                _focus={{
                  outline: 'none',
                }}
              >
                {renderTab('Past', pastPatientEventsCount)}
              </Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg={theme.colors.primary as string}
              borderRadius="1px"
            />
            <Divider />
          </Tabs>
        )}

        <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
          <Flex flex={1}>
            {!patientUidIn && (
              <SearchInput
                inputStyle={{
                  paddingBottom: '6px',
                  paddingTop: '8px',
                }}
                hideRefresh={isMobile}
                placeholder={t('Search patients by name')}
                inputMinWidth={isMobile ? '100%' : '400px'}
                onSearch={onSearch}
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onSearch(e.target.value);
                }}
                onRefresh={refreshEvents}
                isRefreshing={isRefetchingEvents}
                lastUpdated={lastUpdated.current}
                totalCount={totalCount}
                pageCount={pageCount}
                refreshButtonProps={{
                  style: {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                  },
                }}
              />
            )}
          </Flex>

          {selectedTab !== 2 && (
            <Flex>
              <DropdownMenu
                disabled={isCancelledOnlyEvents}
                fontWeight="500"
                label={
                  <SecondaryOutlinedButton
                    bgHovered={
                      isCancelledOnlyEvents
                        ? Colors.mediumShade
                        : Colors.emptyShade
                    }
                    onClick={() => {}}
                    startIcon={
                      <FAIcon icon={faCog} fontSize={14} color="darkestShade" />
                    }
                    borderColor={theme.colors.mediumShade}
                    borderRadius="8px"
                  >
                    <Body
                      fontSize="14px"
                      fontFamily="Inter,sans-serif"
                      fontWeight="500"
                    >
                      {t('Actions')}
                    </Body>
                  </SecondaryOutlinedButton>
                }
                actions={dropdownActions}
                width="200px"
              />
            </Flex>
          )}
        </Flex>

        <Box mt={-2} mb={2} display={['none', 'none', 'block']}>
          <Filters
            ref={(ref) => {
              if (ref) {
                filtersRef.current = ref;
              }
            }}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        </Box>

        {isLoading && <LoadingSpinner />}
        <Box
          style={{
            display: isLoading ? 'none' : 'block',
          }}
        >
          <DataTable
            hideHeaderSelectionCheckBox
            renderRowSelectInfo={renderRowSelectInfo}
            columns={columns}
            onRowClick={handleTableRowClick}
            t={t}
            showRowSelectedBar={enableRowSelect}
            enableRowSelect={enableRowSelect}
            autoResetSelectedRows={autoResetSelectedRows}
            hasNextPage={hasNextPage}
            showLoadMore={
              hasNextPage &&
              selectionOptionChosen !==
                BULK_ACTIONS_SELECTION_OPTION.ALL_ROWS &&
              selectionOptionChosen !==
                BULK_ACTIONS_SELECTION_OPTION.VISIBLE_ROWS
            }
            isFetchingNextPage={isFetchingNextPage}
            onFetchNextPage={fetchNextPage}
            data={data}
            initialSortBy={sortBy}
            enableRowEdit
            renderRowEdit={(d: { original: Appointment }) => renderRowEdit(d)}
            onFetchData={({
              sortBy: sortByVal,
            }: {
              sortBy: [{ id: string; desc: boolean }];
            }) => {
              setSortBy(sortByVal[0]);
            }}
          />
        </Box>

        {modalProps && (
          <Modal
            isCentered
            isOpen
            onClose={() => {
              setModalProps(undefined);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{modalProps.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{modalProps.body}</ModalBody>

              <ModalFooter>
                {modalProps?.onClose && (
                  <Button
                    bgColor="brand"
                    mr={3}
                    onClick={() => {
                      modalProps?.onClose && modalProps?.onClose();
                      setModalProps(undefined);
                    }}
                    variant="outline"
                    backgroundColor="transparent"
                    color={theme.colors.darkestShade}
                  >
                    {t(modalProps.closeLabel || 'Close')}
                  </Button>
                )}
                {modalProps?.onConfirm && (
                  <Button
                    onClick={() => {
                      modalProps?.onConfirm && modalProps?.onConfirm();
                      setModalProps(undefined);
                    }}
                    color={theme.colors.emptyShade}
                    background={
                      modalProps.isDanger
                        ? theme.colors.danger
                        : theme.colors.primary
                    }
                    colorScheme={
                      modalProps.isDanger
                        ? theme.colors.danger
                        : theme.colors.primary
                    }
                    variant="solid"
                  >
                    {t(modalProps.confirmLabel || 'Ok')}
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        <Modal
          isCentered
          isOpen={showStatusChangeModal}
          onClose={() => {
            setShowStatusChangeModal(false);
            setBulkStatusChangeSelectedOption(undefined);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('Change confirmation status')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text
                mb={2}
                color={theme.colors.fullShade}
                fontWeight="600"
                fontSize="16px"
              >
                {t('Selected appointments')}
              </Text>
              <Text
                color={theme.colors.fullShade}
                fontWeight="400"
                fontSize="16px"
              >
                {rowsSelectedCount}
              </Text>

              <Controller
                control={control}
                name={`appointmentStatuses`}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SelectWithLabel
                    children={undefined}
                    elementName={'appointmentStatuses'}
                    components={{ Option: CustomOption }}
                    isDisabled={false}
                    errorMessage={t('Required')}
                    options={[
                      {
                        value: { patientShowed: true },
                        label: t('Show'),
                        icon: <Person color="#27AE60" width={25} height={25} />,
                      },
                      {
                        value: { patientShowed: false },
                        label: t('No-show'),
                        icon: <Cancel color="#E05138" width={24} height={24} />,
                      },

                      {
                        value: { confirmed: true },
                        label: t('Confirmed'),
                        icon: <Check color="#0154B6" width={32} height={32} />,
                      },
                      {
                        value: { confirmed: false },
                        label: t('Not confirmed'),
                        icon: (
                          <QuestionMarkSquare
                            color="#DD8700"
                            width={24}
                            height={24}
                          />
                        ),
                      },
                    ]}
                    label={t('Change status to')}
                    placeholder={t('Change status to')}
                    onChange={(value: Option) => {
                      setBulkStatusChangeSelectedOption(value);
                    }}
                    {...field}
                  />
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                onClick={() => {
                  setShowStatusChangeModal(false);
                  setBulkStatusChangeSelectedOption(undefined);
                }}
                variant="outline"
                backgroundColor="transparent"
                color={theme.colors.darkestShade}
              >
                {t('Cancel')}
              </Button>

              <Button
                isDisabled={!bulkStatusChangeSelectedOption}
                onClick={onBulkStatusChange}
                color={theme.colors.emptyShade}
                background={
                  !bulkStatusChangeSelectedOption
                    ? theme.colors.primaryLight
                    : theme.colors.primary
                }
                colorScheme={
                  !bulkStatusChangeSelectedOption
                    ? theme.colors.primaryLight
                    : theme.colors.primary
                }
                variant="solid"
              >
                {t('Change')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  }
);

export default Appointments;
