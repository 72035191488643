import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import {
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
} from 'victory';
import { isEmpty, get, camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { addDays, parseISO, subDays, format } from 'date-fns';
import { theme, Flex, Text, Box } from '@fivehealth/botero';
import useWindowResize from 'lib/useWindowResize';
import useChartPrintView from './useChartPrintView';
import { DataLabel, OverlayLabel } from './ChartLegend';
import ChartTheme from './theme';
import ChartTooltip, {
  createContainerComponent,
  Container,
  DataContainer,
  DiamondChartIcon,
  CircleChartIcon,
  SquareChartIcon,
} from './ChartTooltip';

export const FlyoutLabel = ({ icon, valueText, description }) => (
  <Flex justifyContent="space-between" mt={1}>
    {icon && <Flex flex={0.15}>{icon}</Flex>}
    <Flex flex={icon ? 0.85 : 1}>
      <Box>
        {description && (
          <Text fontSize={12} fontWeight={500} color="darkestShade">
            {description}
          </Text>
        )}
        <Text mt="4px" fontSize={12} fontWeight={600} color="fullShade">
          {valueText}
        </Text>
      </Box>
    </Flex>
  </Flex>
);

const iconMap = {
  square: SquareChartIcon,
  diamond: DiamondChartIcon,
  circle: CircleChartIcon,
};

export const FlyOut = ({ x, y, datum }) => {
  const IconComponent = iconMap[datum.symbol];
  const xPosition = x - 60 + (x > 500 ? -100 : -100);
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject x={xPosition} y={y - 30} width="160" height="100%">
        <Container xmlns="http://www.w3.org/1999/xhtml" style={{ width: 160 }}>
          <DataContainer>
            <Text
              textAlign="center"
              color="darkestShade"
              fontSize={12}
              fontWeight={600}
            >
              {format(datum.timestamp, 'MMM d yyyy HH:mm')}
            </Text>
            <FlyoutLabel {...datum} icon={<IconComponent />} />
          </DataContainer>
        </Container>
      </foreignObject>
    </g>
  );
};

const ScatterChart = ({
  data,
  graphData,
  serieConfig,
  startDate,
  endDate,
  minYvalue = 0,
  maxYvalue = 14,
  minimumLineY = 4,
  maxLineY: userMedianYValue,
  numYAxisTicks = 4,
  width,
  height,
  containerStyles = {},
  showDataLabels = false,
  legend = {},
  unit,
  chartSetting,
}) => {
  const showNullState = isEmpty(data.flatMap((d) => d));

  const [windowWidth] = useWindowResize();
  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useRef();
  const { t } = useTranslation();

  const chartData = (graphData ?? {})?.submissionTimes?.map(
    (submissionTime, i) => {
      const timestamp = parseISO(submissionTime);
      const defaultDescription = format(parseISO(submissionTime), 'HH:mm');

      const label = get(data[0], `values[${i}]`)?.label;
      const value = parseFloat(graphData.submissionValues[i]);

      const {
        symbolStyle: { symbol: sampleLegendSymbol, color: sampleLegendColor },
      } = serieConfig;

      const defaultTooltip = {
        symbol: sampleLegendSymbol,
        description: defaultDescription,
        color: sampleLegendColor,
      };

      const {
        color: fill,
        symbol,
        description,
      } = get(legend, camelCase(label), defaultTooltip);

      return {
        value,
        description: t(description),
        valueText: `${
          chartSetting?.decimalPlaces
            ? value.toFixed(chartSetting?.decimalPlaces)
            : value
        } ${unit}`,
        timestamp,
        symbol,
        fill,
        y: value,
        x: timestamp,
      };
    }
  );

  const yAxisTickSpacing = Math.round(maxYvalue / numYAxisTicks);
  const yAxisTickValues = [
    ...Array.from(Array(numYAxisTicks).keys()).map((i) => i * yAxisTickSpacing),
    maxYvalue,
  ];

  const medianYValue = userMedianYValue || maxYvalue / 2;

  const onSetBoundingRect = useCallback((node) => {
    if (node !== null) {
      graphRef.current = node;
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const {
    styles: {
      height: chartHeight,
      width: chartWidth,
      containerStyles: chartContainerStyles,
    },
  } = useChartPrintView({ height, width, containerStyles });

  useEffect(() => {
    if (graphRef.current) {
      onSetBoundingRect(graphRef.current);
    }
  }, [onSetBoundingRect, windowWidth]);

  const containerComponent = useMemo(() => {
    if (showNullState) {
      return {};
    }
    return {
      containerComponent: createContainerComponent({
        showDataLabels,
        ChartTooltip,
      }),
    };
  }, [showDataLabels, showNullState]);

  return (
    <div
      style={{ width: '100%', height: '100%', ...chartContainerStyles }}
      ref={graphRef}
    >
      {isEmpty(containerComponent) ? null : (
        <VictoryChart
          domain={{
            x: showNullState ? null : [startDate, endDate],
            y: [minYvalue, maxYvalue],
          }}
          domainPadding={{ x: 20 }}
          scale={{ x: 'time', y: 'linear' }}
          height={chartHeight || 500}
          width={chartWidth || boundingRect.width}
          theme={ChartTheme}
          padding={40}
          {...containerComponent}
        >
          <VictoryAxis
            dependentAxis
            tickValues={yAxisTickValues}
            tickFormat={(y) => y.toFixed(1)}
            style={{
              axis: { stroke: null },
              tickLabels: {
                fill: '#A3A9B1',
              },
            }}
          />
          {!showNullState && (
            <VictoryAxis
              tickFormat={(y) => format(y, 'MMM dd')}
              style={{
                axis: { stroke: theme.colors.mediumShade },
              }}
            />
          )}

          {minimumLineY && (
            <VictoryLine
              name="minLine"
              style={{
                data: { stroke: 'red', strokeWidth: 0.6, strokeDasharray: '3' },
              }}
              data={[
                { x: subDays(new Date(), 364), y: minimumLineY },
                { x: addDays(new Date(), 3), y: minimumLineY },
              ]}
            />
          )}
          {minimumLineY && (
            <DataLabel
              x={endDate || new Date()}
              dx={-10}
              dy={-12}
              y={minimumLineY}
              text={showNullState ? '' : minimumLineY}
              style={{ fill: 'red' }}
            />
          )}
          {medianYValue && (
            <DataLabel
              x={endDate || new Date()}
              dx={-10}
              dy={-12}
              y={medianYValue}
              text={showNullState ? '' : medianYValue}
              style={{ fill: '#256BF6' }}
            />
          )}

          {medianYValue && (
            <VictoryLine
              name="medianLine"
              style={{
                data: {
                  stroke: '#256BF6',
                  strokeWidth: 0.6,
                  strokeDasharray: '3',
                },
              }}
              data={[
                { x: subDays(new Date(), 364), y: medianYValue },
                { x: addDays(new Date(), 3), y: medianYValue },
              ]}
            />
          )}
          {chartData && (
            <VictoryScatter
              data={chartData?.flatMap((group) => group)}
              size={7}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                },
              }}
              labels={({ datum: { value } }) => (showDataLabels ? value : ' ')}
              labelComponent={showDataLabels ? <OverlayLabel /> : undefined}
            />
          )}
        </VictoryChart>
      )}
    </div>
  );
};

export default ScatterChart;
