import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoTriggeredAlertUpdate($input: UpdateCleoTriggeredAlertInput!) {
    cleoTriggeredAlertUpdate(input: $input) {
      cleoTriggeredAlert {
        uid
        rule {
          name
          parserImportPath
          parserSettings
        }
        createdOn
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'alerts',
  keepPreviousData: true,
});
