/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

export const GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT = gql`
  query cleoSubmissionsExport(
    $outputFormat: ExportOutputFormat!
    $patientGroupUidIn: [String!]
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
  ) {
    cleoSubmissions(
      patientGroupUidIn: $patientGroupUidIn
      afterDateTime: $afterDateTime
      beforeDateTime: $beforeDateTime
    ) {
      totalCount
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`;

export const GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT_EMAIL = gql`
  query cleoSubmissionsExport(
    $email: String
    $outputFormat: ExportOutputFormat!
    $patientGroupUidIn: [String!]
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
  ) {
    cleoSubmissions(
      patientGroupUidIn: $patientGroupUidIn
      afterDateTime: $afterDateTime
      beforeDateTime: $beforeDateTime
    ) {
      totalCount
      exportByEmail(outputFormat: $outputFormat, email: $email)
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT,
  queryType: 'query',
  baseQueryKey: 'submissionsExport',
  select: (data) => get(data, 'cleoSubmissions'),
  keepPreviousData: true,
});
