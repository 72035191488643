import React, { useEffect, useState } from 'react';
import {
  Box,
  H2,
  Body,
  DangerButton,
  PrimaryButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  Text,
  Label,
  Select,
} from '@fivehealth/botero';
import { get } from 'lodash';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

const SelectWithLabel = ({ label, children, ...props }) => (
  <Flex flexDirection="column" flex="0 0 50%" px={1} mt={[1, 0]}>
    <Label fontSize="12px" color="darkestShade">
      {label}
    </Label>
    <Select menuPlacement="auto" {...props}>
      {children}
    </Select>
  </Flex>
);

const DischargeModal = withTranslation()(
  ({
    closeModal,
    enroll = false,
    isSubmitting,
    onSubmit,
    patientForm,
    t,
    deactivate,
  }) => {
    const SubmitButton = enroll ? PrimaryButton : DangerButton;
    const submitText = enroll ? t('Enroll') : t('Discharge');
    // const [onDischargeOptionSelected, setOnDischargeOptionSelected] =
    //   useState(1);
    const [onModalSubmit, setOnModalSubmit] = useState(false);

    const { control } = useForm({
      mode: 'onChange',
      defaultValues: {
        dischargeWithOption: {
          label: t('Remove Clinical User(s) from Alert'),
          value: 1,
        },
      },
    });

    useEffect(() => {
      if (onModalSubmit) {
        setTimeout(() => {
          closeModal();
        }, 1000);
      }
    }, [closeModal, onModalSubmit]);

    const onHandleSubmit = () => {
      const key = enroll ? 'reactivateUser' : 'deactivateUser';
      onSubmit({ ...patientForm, deactivate }, { [key]: true });
      setOnModalSubmit(true);
    };

    return (
      <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 720]}>
        <Flex justifyContent="space-between" alignItems="center">
          <H2>
            {t(enroll ? 'Enroll' : 'Discharge')} {patientForm.patient.name}?
          </H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>

        <Box my={4}>
          <Text fontSize={2}>
            {`${t('Monitoring form')}: ${get(
              patientForm,
              'monitoringForm.effectiveName',
              ''
            )}`}
          </Text>
        </Box>
        <Body mt={3}>
          {!enroll && (
            <Box mb={4} ml="-8px">
              <Controller
                name="dischargeWithOption"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <SelectWithLabel
                    label={t('Remove Clinical User(s) to Alert')}
                    defaultValue={field.value}
                    options={[
                      {
                        label: t('Remove Clinical User(s) from Alert'),
                        value: 1,
                      },
                      {
                        label: t(
                          'Continue to receive alerts for all Clinical User(s)'
                        ),
                        value: 2,
                      },
                    ]}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      // setOnDischargeOptionSelected(e.value);
                    }}
                  />
                )}
              />
            </Box>
          )}
          {t(
            `What happens when a patient is ${
              enroll ? 'enrolled' : 'discharged'
            }?`
          )}
          <ul>
            {enroll ? (
              <>
                <li>
                  {t(
                    'The patient will receive a message informing them that they have been enrolled into the monitoring program(s)'
                  )}
                </li>
                <li>{t('The patient will start receiving reminders')}</li>
              </>
            ) : (
              <>
                <li>
                  {t(
                    'The patient will receive a message informing them that they have been removed from the monitoring program'
                  )}
                </li>
                <li>{t('The patient will no longer receive reminders')}</li>
                <li>
                  {t(
                    "The patient's graphs and submissions will still be available for download under Patient List"
                  )}
                </li>
              </>
            )}
          </ul>
        </Body>
        <Flex mt={6} justifyContent="flex-end" alignItems="center">
          <SecondaryOutlinedButton
            mr={3}
            onClick={() => {
              setOnModalSubmit(false);
              closeModal();
            }}
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <SubmitButton disabled={onModalSubmit} onClick={onHandleSubmit}>
            {onModalSubmit || isSubmitting ? t('Submitting...') : submitText}
          </SubmitButton>
        </Flex>
      </Box>
    );
  }
);

export default DischargeModal;
