import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientCount($activated: Boolean) {
    cleoPatientCount(activated: $activated) {
      totalCount
    }
  }
`;

const usePatientsCount = () => (args) => {
  const patients = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientsCount',
    select: (response) => {
      return response.cleoPatientCount?.totalCount;
    },
  });
  return patients(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientsCount();
