import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Flex,
  H3,
  Text,
  SecondaryOutlinedButton,
  PrimaryButton,
} from '@fivehealth/botero';
import BotCryingAvatar from '../../assets/crying-avatar.svg';
import Config from '../../Config';

const TextButton = styled.span`
  &:hover {
    opacity: 0.6;
  }
`;
const env = window.location.href.includes('localhost')
  ? `Localhost (${process.env?.NODE_ENV})`
  : window.location.hostname;

const ErrorDesc = ({ onClick, textLink }) => {
  const descText = `Something went wrong. Please refresh this page or re-login. If the issue persists, please reach out to our `;
  return (
    <Text>
      {descText}
      <TextButton
        alignItems="center"
        onClick={onClick}
        hover={{ opacity: 0.6 }}
        style={{ color: '#256BF6', cursor: 'pointer' }}
      >
        {textLink}
      </TextButton>
      .
    </Text>
  );
};

const ErrorPage = ({ headerTitle, clinic = null, user = null, ...props }) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);
  const ERR_TITLE = 'Oh no, there was an error!';

  // NOTE: This would reset error boundary and make route available
  useEffect(() => {
    if (pathname !== originalPathname.current) {
      props?.resetErrorBoundary();
    }
  }, [pathname, props, props?.resetErrorBoundary]);

  /**
   * Call and open customer support email (support@botmd.io).
   * TODO: Might consider putting this to utils.
   * @param clinicName
   * @param userName
   */
  const customerSupportEmail = (clinicName = null, userName = null) => {
    const CUSTOMER_SUPPORT_EMAIL = 'support@botmd.io';
    const url = new URL(`mailto:${CUSTOMER_SUPPORT_EMAIL}`);
    url.searchParams.set(
      'subject',
      encodeURIComponent(
        `${clinicName || 'BotMD Care'} - ${userName || t('Support')}`
      )
    );
    window.open(decodeURI(url.toString()));
  };

  const handleOnClickSupport = () => {
    customerSupportEmail(clinic?.name, user?.name);
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      borderRadius="8px"
      px={2}
      width="100%"
      height="100%"
      style={{
        verticalAlign: 'middle',
        margin: 'auto',
        marginTop: 75,
      }}
    >
      <Box
        as="img"
        src={BotCryingAvatar}
        height={152}
        width={170}
        mb={2}
        alt="Bot Avatar"
      />
      <Box m={2}>
        <H3
          fontSize={[20, 24]}
          lineHeight="36px"
          fontWeight={600}
          color="#111824"
          mb={2}
          style={{ textAlign: 'center' }}
        >
          {t(headerTitle || ERR_TITLE)}
        </H3>
      </Box>
      <Box mx={2} mt={1} mb={1} width={[330, 500]} px={2}>
        <Text
          color="#697481"
          fontSize={[14, 16]}
          fontWeight={400}
          style={{ textAlign: 'center' }}
        >
          <ErrorDesc
            onClick={handleOnClickSupport}
            textLink={t('customer support')}
          />
        </Text>
      </Box>
      {!Config.IS_PRODUCTION() && (
        <>
          <TextButton
            alignItems="center"
            onClick={() => {
              setShowError(!showError);
            }}
            hover={{ opacity: 0.6 }}
            style={{
              color: 'gray',
              fontSize: 10,
              border: '1px solid rgb(211, 211, 211)',
              borderRadius: 14,
              padding: '4px 7px',
              cursor: 'pointer',
              marginTop: 10,
            }}
          >
            {showError ? t('Hide details') : t('Show details')}
          </TextButton>
          {showError && (
            <Box>
              <Text
                color="gray"
                fontSize={[10, 12]}
                fontWeight1={400}
                style={{ textAlign: 'center', marginTop: 10 }}
              >
                Build environment: {env}
              </Text>
              <Box
                my={2}
                width={[330, 500]}
                height={[220, 300]}
                px={2}
                overflow="auto"
                style={{ border: '1px solid rgb(219 219 219)' }}
              >
                <Text
                  color="red"
                  fontSize={[10, 12]}
                  fontWeight1={400}
                  style={{ textAlign: 'left' }}
                >
                  {props?.details?.error?.stack ||
                    props?.details?.error?.message}
                </Text>
              </Box>
            </Box>
          )}
        </>
      )}
      <Flex
        p={2}
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        fontSize={14}
        fontWeight={500}
      >
        <SecondaryOutlinedButton
          onClick={() => {
            window.location.href = '/';
          }}
          m={1}
          style={{ fontSize: 14, fontWeight: 500, minWidth: 150 }}
        >
          {t('Back to home')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          onClick={() => {
            // window.location.reload();
            props?.resetErrorBoundary();
          }}
          m={1}
          style={{ fontSize: 14, fontWeight: 500, minWidth: 150 }}
        >
          {t('Refresh page')}
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default ErrorPage;
