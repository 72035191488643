import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoTriggeredAlertsResolveAll(
    $input: CleoTriggeredAlertsResolveAllInput!
  ) {
    cleoTriggeredAlertsResolveAll(input: $input) {
      resolvedTriggeredAlertsCount
      #      resolvedTriggeredAlerts {
      #        uid
      #        resolvingComment {
      #          uid
      #          createdOn
      #          content
      #        }
      #        createdOn
      #      }
      #      clientMutationId
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'triggeredAlertsResolveAll',
  keepPreviousData: true,
});
