import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import _, { get, replace } from 'lodash';
import { read } from 'xlsx/xlsx.mjs';

import {
  Box,
  H6,
  Dialog,
  Flex,
  FAIcon,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  useApi,
  FileDropInputField,
  theme,
  Body,
  Radio,
} from '@fivehealth/botero';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useModal } from 'context/ModalContext';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';
import Config from 'Config';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import BotExcelAvatar from '../../assets/bot-excel-avatar.svg';

import PatientsUploadLoadingStates from './PatientsBulkUploadLoadingStates';
import PatientsBulkUploadStats from './PatientsBulkUploadStats';

const DialogTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={600} fontSize={3}>
      {label}
    </Text>
  </Flex>
);

const DialogSubTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={400} fontSize={2}>
      {label}
    </Text>
  </Flex>
);

const BackButton = styled(Flex)`
  width: 50px;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;

const PatientsBulkUpload = ({ label, goBack, monitoringForm, reminder }) => {
  let selectedSheet = {};
  const { openModal, closeModal } = useModal();
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFEError, setUploadFEError] = useState('');
  const [uploadedData, setUploadedData] = useState({ uploadID: '' });
  const [openExcelOption, setOpenExcelOption] = useState(false);
  const [stitchUploadId, setStitchUploadId] = useState();

  const [showUploadStatus, setShowUploadStatus] = useState(false);

  const [previewResults, setPreviewResults] = useState();

  const [cookies] = useCookies([Config.cookie.name]);

  // eslint-disable-next-line no-shadow, no-unused-vars
  const [asyncJobData, setAsyncJobData] = useRecoilState(asyncJobState);

  const [excelOptions, setExcelOptions] = useState({
    sheetNames: [],
  });

  const {
    queries: {
      useCreateStitchUpload,
      usePatientsImportAsync,
      usePatientImportTemplate,
    },
  } = useApi({
    queries: [
      'useCreateStitchUpload',
      'usePatientsImport',
      'usePatientsImportAsync',
      'usePatientImportTemplate',
    ],
  });

  const { data: importTemplateData } = usePatientImportTemplate({});

  const importTemplateUrl = useMemo(() => {
    const url = get(
      importTemplateData?.cleoPatients,
      'importTemplateByUrl',
      ''
    );

    return replace(
      url,
      // eslint-disable-next-line no-template-curly-in-string
      '${session}',
      cookies[Config.cookie.name]
    );
  }, [importTemplateData, cookies]);

  const { mutateAsync: updateAsyncPatientsUsingStitchId } =
    usePatientsImportAsync({
      variables: {},
    });

  const { mutateAsync: uploadFileToStitch } = useCreateStitchUpload({
    variables: {},
  });

  const readExcelFile = (file, options) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const wb = read(event.target.result, {
          type: 'binary',
          ...options,
        });
        resolve(wb);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsBinaryString(file);
    });

  const handleLoading = (action) => {
    openModal(
      <PatientsUploadLoadingStates
        closeModal={closeModal}
        goBack={goBack}
        action={action}
        templateUrl={importTemplateUrl}
      />,
      {
        style: {
          width: '30%',
        },
      }
    );
  };

  const showResults = (result) => {
    if (result) {
      const { previewResults: pr } = result?.cleoPatientImportAsync || {};

      if (pr) {
        setPreviewResults(pr);
        setShowUploadStatus(true);
      } else {
        handleLoading('error');
      }
    }

    closeModal();
  };

  // This is to show preview results, before actually kicking off the job to import patients
  const startPoolingForResults = (asyncJobId) => {
    const interval = setInterval(async () => {
      const result = await updateAsyncPatientsUsingStitchId({
        input: {
          asyncJobUid: asyncJobId,
        },
      }).then((r) => r);

      const asyncJob = get(result, 'cleoPatientImportAsync.asyncJob', null);
      if (asyncJob?.status === 'SUCCESS') {
        showResults(result);
        clearInterval(interval);
      }

      if (asyncJob?.status === 'FAILED') {
        clearInterval(interval);
        handleLoading('error');
      }
    }, 2000);
  };

  const uploadPatientsUsingStitchId = async (uploadId) => {
    setStitchUploadId(uploadId);
    setAsyncJobData('');
    try {
      const input = {
        uploadId,
      };

      if (monitoringForm) {
        input.overrides = {
          monitoringForms: monitoringForm.map((form) => form.value.id),
        };
      }

      const result = await updateAsyncPatientsUsingStitchId({
        input,
      }).then((r) => r);

      const asyncJob = get(result, 'cleoPatientImportAsync.asyncJob', null);

      if (asyncJob?.status === 'STARTED') {
        startPoolingForResults(asyncJob?.uid);
        return;
      }

      showResults(result);
    } catch (error) {
      setShowUploadStatus(false);
      handleLoading('error');
    }
  };

  const handleUpload = () => {
    if (!_.isNull(uploadFile)) {
      handleLoading('pending');
      uploadFileToStitch({
        input: {
          key: 'cleo',
          mimeType: uploadFile.type,
        },
      })
        .then(async ({ stitchCreateUploadUrl }) => {
          const body = new FormData();
          const uploadedFile = uploadFile;
          _.map(stitchCreateUploadUrl.fields, (value, key) => {
            body.append(key, value);
          });
          body.append('file', uploadedFile);
          setUploadedData({
            ...uploadedData,
            uploadID: stitchCreateUploadUrl.uploadId,
          });
          await fetch(stitchCreateUploadUrl.url, {
            method: 'post',
            body,
          });
          return stitchCreateUploadUrl.uploadId;
        })
        .then((res) => {
          uploadPatientsUsingStitchId(res);
        })
        .catch(() => {
          handleLoading('error');
        });
    }
  };

  const getSheetNames = useCallback(
    async (file) => {
      if (file) {
        let sheets = [];
        const excelFile = await readExcelFile(file, {
          // bookSheets: true,
          bookFiles: true,
        }).then((result) => {
          if (file.type === 'text/csv') {
            const { Sheets } = result;
            sheets = Sheets;
          } else {
            const {
              Workbook: { Sheets },
            } = result;
            sheets = Sheets;
          }

          const data = {
            ...excelOptions,
            sheetNames: _.map(result.SheetNames, (name) => ({
              name,
              selected: false,
            })),
          };
          return data;
        });

        const activeSheets = _.filter(sheets, (sheet) => sheet.Hidden !== 1);

        if (activeSheets.length > 1) {
          return false;
        }
        setExcelOptions(excelFile);
        return true;
      }
      return false;
    },
    [excelOptions]
  );

  const onDrop = useCallback(
    async (acceptedFile) => {
      if (
        !asyncJobData ||
        asyncJobData?.status === 'SUCCESS' ||
        asyncJobData?.status === 'FAILED'
      ) {
        const result = await getSheetNames(acceptedFile).then((res) => res);
        if (result) {
          setUploadFEError(null);
          setUploadFile(acceptedFile);
        } else {
          setUploadFile(null);
          setUploadFEError(t('Multiple sheets are not allowed'));
        }
      } else {
        setUploadFEError(
          t('Please wait for the current upload to finish and try again')
        );
      }
    },
    [getSheetNames, asyncJobData]
  );

  const getDescription = () => {
    if (monitoringForm) {
      return (
        <Box
          p={3}
          mt={1}
          borderRadius="8px"
          border="solid"
          width={['100%', '55%']}
          margin="0 auto"
          borderWidth={1}
          borderColor="mediumShade"
          alignItems="center"
          justifyContent="center"
        >
          <Flex flexDirection="column">
            <Flex mb={3} alignItems="center" justifyContent="space-between">
              <Box>
                <H5>{t('Instructions (Read me first)')}</H5>
                <Flex alignItems="center">
                  <Body fontWeight="500" mr={1} small color="darkestShade">
                    {t(`Selected Programs:`)}
                  </Body>
                  <Body fontWeight="600" small color="primary">
                    {monitoringForm?.map((form) => t(form.label)).join(', ')}
                  </Body>
                </Flex>
              </Box>

              <PrimaryButton
                borderRadius="8px"
                onClick={() => {
                  window.open(importTemplateUrl, '_blank');
                }}
              >
                {t('Download template')}
              </PrimaryButton>
            </Flex>

            <Box>
              <Box as="ol" ml={3}>
                <Body pb={2} as="li" small color="fullShade">
                  {t('Download the Excel template. ')}
                </Body>
                <Body pb={2} as="li" small color="fullShade">
                  {t(
                    'Fill in all columns in the template. Required fields must be filled for import to work.'
                  )}
                </Body>
                <Body pb={2} as="li" small color="fullShade">
                  {t(
                    'We will only import 500 patients at a time. Adding more rows and columns into the template will not result in additional data being imported.'
                  )}
                </Body>
                <Body pb={2} as="li" small color="fullShade">
                  {t(
                    'Upload the filled template to import and enroll your patients into Bot MD Care'
                  )}
                </Body>
              </Box>
            </Box>
          </Flex>
        </Box>
      );
    }

    return (
      <Text>
        {t('Download & fill in')}&nbsp;
        <a
          style={{
            color: theme.colors.primary,
          }}
          target="_blank"
          href={importTemplateUrl}
          rel="noreferrer"
        >
          {t('this template')}
        </a>
        .{' '}
        {t(
          'Users will be activated, deactivated or updated based on the instructions in the sheet'
        )}
        .
      </Text>
    );
  };

  const onBackPress = () => {
    setShowUploadStatus(false);
  };

  const onComplete = () => {
    onBackPress();
    goBack();
  };

  return (
    <>
      {showUploadStatus && previewResults && (
        <PatientsBulkUploadStats
          data={previewResults}
          onBackPress={onBackPress}
          onComplete={onComplete}
          stitchUploadId={stitchUploadId}
          monitoringForm={monitoringForm}
          reminder={reminder}
        />
      )}
      {!showUploadStatus && (
        <Box>
          <BackButton
            id="backBtn"
            ml="16px"
            hover={{ opacity: 0.6 }}
            cursor="pointer"
            onClick={() => goBack()}
          >
            <FAIcon
              icon={faChevronLeft}
              color="darkestShade"
              fontWeight="500"
              style={{ fontSize: 12, marginRight: 4 }}
            />
            <H5 mt={0.9} fontSize={13} fontWeight="500" color="darkestShade">
              {t('Back')}
            </H5>
          </BackButton>
          <Flex alignItems="center" justifyContent="space-between">
            <Text m={2} mt={0} fontSize={5} fontWeight="600">
              {_.startCase(label)}
            </Text>

            <Flex justifyContent="right">
              <SecondaryOutlinedButton borderRadius={8} onClick={goBack}>
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                ml={2}
                borderRadius={8}
                disabled={!uploadFile}
                onClick={() => {
                  // if (uploadFile) setOpenExcelOption(true);
                  handleUpload();
                }}
              >
                {t('Continue')}
              </PrimaryButton>
            </Flex>
          </Flex>

          <Box ml={2} mr={2} mb={-6} mt={3}>
            {getDescription()}
          </Box>

          <Flex m={2} mt={10} alignItems="center" justifyContent="center">
            <Box>
              <H6 fontSize={1}>{t('Upload file')}</H6>
              <FileDropInputField
                image={BotExcelAvatar}
                acceptedFileTypes={[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'text/csv',
                ]}
                onChange={onDrop}
              />
              {uploadFEError && (
                <Text
                  textAlign="center"
                  color="danger"
                  fontSize={18}
                  mt={1}
                  mb={1}
                >
                  {uploadFEError}
                </Text>
              )}
            </Box>
          </Flex>

          <Dialog type="basic" open={openExcelOption} onClose={() => {}}>
            <Box width="440px" p="8px">
              <DialogTitle
                label={`${
                  ((excelOptions || {}).sheetNames || [])?.[0]?.name
                } selected in ${(uploadFile || {}).name || ''}`}
                onClick={() => setOpenExcelOption(!openExcelOption)}
              />
              <DialogSubTitle
                label="Click Continue if you would like to sync:"
                onClick={() => setOpenExcelOption(!openExcelOption)}
              />
              {uploadFEError && (
                <Text color="danger" fontSize={12} mt={1} mb={1}>
                  {uploadFEError}
                </Text>
              )}
              <Flex mb={2}>
                <Radio
                  name={((excelOptions || {}).sheetNames || [])?.[0]?.name}
                  checked
                />
                <Text pb="10px" fontSize="14px" color="#111824">
                  {((excelOptions || {}).sheetNames || [])?.[0]?.name}
                </Text>
              </Flex>
              <Flex justifyContent="right" mt={2}>
                <SecondaryOutlinedButton
                  borderRadius="8px"
                  mr={2}
                  onClick={() => {
                    setUploadFEError(null);
                    setOpenExcelOption(false);
                  }}
                >
                  {t('Cancel')}
                </SecondaryOutlinedButton>
                <PrimaryButton
                  borderRadius="8px"
                  onClick={() => {
                    if (excelOptions && excelOptions.sheetNames) {
                      selectedSheet = {
                        ...excelOptions.sheetNames[0],
                        selected: true,
                      };
                      if (!_.isEmpty(selectedSheet)) {
                        setOpenExcelOption(!openExcelOption);
                        handleUpload();
                      } else {
                        setUploadFEError(
                          'Please choose at least one sheet to continue'
                        );
                      }
                    } else {
                      setUploadFEError(
                        'Please upload excel sheet sheet to continue'
                      );
                    }
                  }}
                >
                  {t('Continue')}
                </PrimaryButton>
              </Flex>
            </Box>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default PatientsBulkUpload;
