import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientGroups {
    cleoPatientGroups(isSystemGroup: false) {
      totalCount
      edges {
        node {
          uid
        }
      }
    }
  }
`;

const usePatientGroupsUserCreated = () => (args) => {
  const patientGroups = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientsGroupsUserCreated',
    select: (data) => {
      const totalCount = get(data, 'cleoPatientGroups.totalCount');
      const groups = get(data, 'cleoPatientGroups.edges');
      return { totalCount, groups };
    },
    keepPreviousData: true,
  });
  return patientGroups(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientGroupsUserCreated();
