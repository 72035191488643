import {
  Box,
  Flex,
  SecondaryOutlinedButton,
  DangerButton,
  FAIcon,
  H2,
  Body,
  Text,
} from '@fivehealth/botero';
import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const DischargeCaregiverModal = ({
  isSubmitting,
  caregiverName,
  closeModal,
  onDischargeSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Box p={1} style={{ boxSizing: 'border-box' }} width={['100%', 720]}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>
          {t('Deactivate')} {caregiverName}?
        </H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>

      <Body mt={6}>
        {t('What happens when an account is deactivated?')}
        <ul>
          <li>
            <Text>
              {t(
                'The user will no longer be able to sign in to the Dashboard.'
              )}
            </Text>
          </li>
          <li>
            <Text>
              {t(
                "The user's comments will still be accessible in the Dashboard."
              )}
            </Text>
          </li>
        </ul>
      </Body>

      <Flex
        mt={6}
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent={['', 'right']}
      >
        <SecondaryOutlinedButton
          mr={[0, 3]}
          mb={[2, 0]}
          p="0px 24px"
          width={['100%', 94]}
          onClick={closeModal}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <DangerButton onClick={onDischargeSubmit} width={['100%', 150]}>
          {isSubmitting ? t('Submitting...') : t('Discharge')}
        </DangerButton>
      </Flex>
    </Box>
  );
};

export default DischargeCaregiverModal;
