import { theme } from '@fivehealth/botero';

const Colors = {
  red: '#E05138',
  lightBlue: '#B3E7FF',
  white: 'white',
  primaryBlueDark: '#0041C2',
  ...theme.colors,
  primaryBlueLight: 'rgba(233, 240, 254, 1)',
};

export default Colors;
