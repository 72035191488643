import { useApi } from '@fivehealth/botero';
import { map } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const useClinicalParametersQuery = (authenticated) => {
  const [clinicalParameters, setClinicalParameters] = useState([]);

  const {
    queries: { useClinicalParameters },
  } = useApi({
    queries: ['useClinicalParameters'],
  });

  useClinicalParameters({
    enabled: authenticated,
    onSuccess: ({ data }) => {
      setClinicalParameters(data);
    },
  });

  const getClinicalParamUidsByNameWithGroups = useCallback(
    (nameWithGroup = []) => {
      const parametersUids = map(nameWithGroup, (name) => {
        const result = clinicalParameters.find(
          ({ node }) => node.nameWithGroup === name
        );
        if (result) {
          return result.node.uid;
        }

        return name;
      });

      return parametersUids;
    },
    [clinicalParameters]
  );

  return useMemo(
    () => ({
      clinicalParameters,
      getClinicalParamUidsByNameWithGroups,
    }),
    [clinicalParameters, getClinicalParamUidsByNameWithGroups]
  );
};

export default useClinicalParametersQuery;
