import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientsExport {
    cleoPatients {
      importTemplateByUrl(importTemplateFormat: XLSX)
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientsImportTemplate',
});
