import { EffectCallback, useEffect, useRef } from 'react';

/**
 * Custom hook to execute effect only once when enabled is true
 * @param effect - effect to be executed
 * @param enabled - condition to enable the effect
 */
export const useEffectOnceConditionally = (
  effect: EffectCallback,
  enabled: boolean
): void => {
  const triggered = useRef(false);

  useEffect(() => {
    if (triggered.current || !enabled) {
      return;
    }
    triggered.current = true;
    effect();
  }, [effect, enabled]);
};
