import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HEIMDALL_DISCOVERY = gql`
  query heimdallDiscovery($url: String!) {
    heimdallDiscovery(url: $url) {
      hippocrates
      login
      care
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HEIMDALL_DISCOVERY,
  queryType: 'query',
  baseQueryKey: 'heimdallDiscovery',
  keepPreviousData: true,
});
