import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_EVENTS_UPDATE_STATUS = gql`
  mutation cleoPatientEventUpdateAsync(
    $input: CleoPatientEventUpdateAsyncInput!
  ) {
    cleoPatientEventUpdateAsync(input: $input) {
      asyncJob {
        uid
        status
      }
      results {
        status
        isConfirmed
        confirmedOn
        isPatientShowed
        patientShowedOn
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_EVENTS_UPDATE_STATUS,
  queryType: 'mutation',
  baseQueryKey: 'patientEventUpdateAsync',
});
