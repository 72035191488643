import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Box,
  useAuth,
  useApi,
  theme,
  Body,
  H1,
  H3,
  H5,
  Select,
  InputField,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { get, map, isNull, isEqual, toLower } from 'lodash';
import Config, { LOGIN_TYPES, LOGIN_TYPE_SESSION_KEY } from 'Config';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import { useAppData } from 'context/AppDataContext';
import { useTranslation, Trans } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { isValidJwt } from 'lib/isValidJwt/isValidJwt';
import Colors from 'constants/colors';
import LoadingSpinner from '../../assets/bot-md-spinner.json';
import BotLogoHeart from '../../assets/logo-heart.svg';
import Bg from '../../assets/Bg.svg';
import Logo from '../../assets/Logo.svg';
import { CleoAuthE2EInput } from '../../constants';
import {
  domainConfigsUtils,
  doesClinicExist,
  getLoginProvider,
  isCaregiverLogin,
  localStorageUtil,
} from '../../AppUtils';

const paramClinic = new URLSearchParams(document.location.search).get('clinic');
const clientJwt = new URLSearchParams(document.location.search).get('jwt');
const isE2E = new URLSearchParams(window.location.search).get('e2e');

const Login = () => {
  const { login, authState } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { org, clinicname } = useParams();
  const [clinicProps, setClinicProps] = React.useState(null);
  const [failedLogin, setFailedLogin] = React.useState(false);
  const [, setCookie] = useCookies([Config.cookie.name]);
  const { setUser } = useAppData();
  const [clinic, setClinic] = useState(paramClinic);

  const [domainName, setDomainName] = useState(org);
  const [loadingDomainLogin, setLoadingDomainLogin] = useState(!!domainName);

  const domainConfigs = domainConfigsUtils.getDomainConfigs();

  const getDomain = () => {
    if (clinicname) return clinicname;
    if (org) return org;
    return null;
  };

  const domain = getDomain();
  const enabledRequests = domain?.length > 2;

  const {
    queries: {
      useClinicPublicSettings,
      useHeimdallAuthorizationFlow,
      useClinicsWithPatientJWT,
    },
  } = useApi({
    queries: [
      'useClinicPublicSettings',
      'useHeimdallAuthorizationFlow',
      'useClinicsWithPatientJWT',
    ],
  });

  const { data: loginableClinics, isLoading: isLoadingLoginableClinics } =
    useClinicsWithPatientJWT({
      enabled: !isNull(clientJwt) && isValidJwt(clientJwt),
      variables: {
        providerApplicationUid:
          Config.CLEO_JWT_PROVIDER_UID[domainConfigs?.hippocrates?.region],
        token: clientJwt,
      },
    });

  const { mutateAsync: getHeimdallAuthFlowJWT } = useHeimdallAuthorizationFlow({
    enabled: !isLoadingLoginableClinics,
    variables: {},
    onSuccess: ({ data }) => {
      const { heimdallAuthorizationFlow } = data;
      if (heimdallAuthorizationFlow) {
        const sessionId = get(heimdallAuthorizationFlow, 'session.uid');
        setFailedLogin(false);
        if (clientJwt) {
          setUser({
            isPatientFacing: true,
          });

          setCookie(
            Config.patientCookie.name,
            {
              isPatientFacing: true,
            },
            { path: '/' }
          );
        }
        setCookie(Config.cookie.name, sessionId, { path: '/' });
        history.push(`/auth/callback`);
      } else {
        setFailedLogin(true);
      }
    },
  });

  useEffect(() => {
    if (isE2E) {
      getHeimdallAuthFlowJWT({ input: CleoAuthE2EInput });
    }
  }, [getHeimdallAuthFlowJWT]);

  const { isLoading: isLoadingPublicSettings } = useClinicPublicSettings({
    enabled:
      !authState.authenticated &&
      !isNull(clientJwt) &&
      isValidJwt(clientJwt) &&
      !isNull(clinic) &&
      !isLoadingLoginableClinics,
    variables: {
      domain: clinic,
    },
    onSuccess: async ({ data: { cleoClinicPublicSettings } }) => {
      const { patientDashboardVerification } = cleoClinicPublicSettings;

      const input = {
        providerApplicationUid:
          Config.CLEO_JWT_PROVIDER_UID[domainConfigs?.hippocrates?.region],
        providerInput: {
          token: clientJwt,
        },
        applicationInput: {
          patient_verification_fields: [],
        },
      };

      const caregiverLogin = await isCaregiverLogin(input);

      if (caregiverLogin?.isCaregiver) {
        const loginUrl = `${window.location.origin}/${clinic}`;
        window.location.replace(loginUrl);
      }
      // direct login if only one clinic and no dashboard verification setup
      else if (
        isNull(patientDashboardVerification) &&
        loginableClinics.length === 1
      ) {
        getHeimdallAuthFlowJWT({ input });
      } else {
        setClinicProps(cleoClinicPublicSettings);
      }
    },
  });

  const getSelectWorkspaceDomain = useCallback(
    () => JSON.stringify({ domain }),
    [domain]
  );

  useClinicPublicSettings({
    enabled:
      !authState.authenticated &&
      !isNull(domain) &&
      !domain.match(/callback/i) &&
      enabledRequests &&
      isNull(clinic),
    variables: { domain },
    onSuccess: ({ data }) => {
      const logo = get(data, 'cleoClinicPublicSettings.logo');
      const applicationInput = getSelectWorkspaceDomain();
      const themeConfig = JSON.stringify({
        logo: encodeURIComponent(logo),
        primaryColor: encodeURIComponent(theme.colors.primary),
      });

      const path = new URLSearchParams(document.location.search).get('path');
      const redirectUrl = isNull(path)
        ? `${window.location.origin}${Config.REDIRECT_PATH}`
        : `${window.location.origin}${Config.REDIRECT_PATH}?path=${path}`;
      const loginUrl = `${
        domainConfigs?.login?.url
      }?uid=${getLoginProvider()}&redirectTo=${redirectUrl}&applicationInput=${applicationInput}&theme=${themeConfig}&gql_uri=${
        domainConfigs?.hippocrates?.gql_endpoint
      }&universalLogin=true`;
      if (domain) {
        window.location.replace(loginUrl);
      }
    },
  });

  useEffect(() => {
    if (authState.authenticated) {
      const path = new URLSearchParams(search).get('path') || '/';
      history.push(path);
    }
  }, [authState, history, search]);

  useEffect(() => {
    const loginPath = '/login';
    const applicationInput = getSelectWorkspaceDomain(!authState.token);
    if (
      !authState.authenticated &&
      window.location.pathname.toLowerCase() === loginPath &&
      JSON.parse(applicationInput).domain &&
      !clientJwt
    ) {
      history.push(`/${JSON.parse(applicationInput).domain}`);
    }
  }, [
    authState.authenticated,
    authState.token,
    getSelectWorkspaceDomain,
    history,
  ]);

  const onClinicLogin = () => {
    setLoadingDomainLogin(true);
    history.push(domainName);
  };

  return (
    <Flex
      minHeight="100vh"
      width="100%"
      height="100%"
      flexDirection={['column', 'column', 'row']}
    >
      {loadingDomainLogin && (
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Lottie
            play
            animationData={LoadingSpinner}
            style={{ width: 150, height: 150 }}
          />
        </Flex>
      )}
      {!loadingDomainLogin && (
        <>
          <LoginSection
            onClinicLogin={onClinicLogin}
            onDomainNameChange={setDomainName}
            domainName={domainName}
            onLogin={login}
            clinic={clinic}
            clinicProps={clinicProps}
            getHeimdallAuthFlowJWT={getHeimdallAuthFlowJWT}
            failedLogin={failedLogin}
            loginableClinics={loginableClinics}
            setClinic={setClinic}
            isLoadingPublicSettings={isLoadingPublicSettings}
          />
          <InformationSection t={t} />
        </>
      )}
    </Flex>
  );
};

const LoginSection = (props) => {
  const domainConfigs = domainConfigsUtils.getDomainConfigs();
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [authValues, setAuthValues] = useState({});
  const { t } = useTranslation();
  const [selectedDropdownClinic, setSelectedDropdownClinic] = useState(null);

  const { client } = useApi();

  const [clinicInvalid, setClinicInvalid] = useState(false);

  const clinicOptions = map(props.loginableClinics, (loginableClinic) => ({
    label: loginableClinic.name,
    value: loginableClinic.domain,
  }));

  const setAuthFields = (key, value) => {
    setAuthValues({
      ...authValues,
      [`${key}`]: value,
    });
  };

  const renderAuthenticationComponent = () => {
    const { patientDashboardVerification } = props.clinicProps;

    return patientDashboardVerification.verification_fields.map((field) => (
      <>
        <H5 alignSelf="flex-start" my={1} color="#697481">
          {field.key_label}
        </H5>
        <InputField
          data-testid={`patient-login-input-${field.key_label}`}
          style={{
            borderColor: props.failedLogin ? theme.colors.danger : 'inherit',
          }}
          onChange={(e) => setAuthFields(field.key, e.target.value)}
          placeholder={field.key_label}
        />
        {props.failedLogin && (
          <ErrorBanner mt={2} text={t('There was an error logging you in')} />
        )}
      </>
    ));
  };

  const onLoginPress = async () => {
    const applicationInputKeyValue = Object.keys(authValues).map((key) => ({
      key,
      value: authValues[key],
    }));

    const input = {
      providerApplicationUid:
        Config.CLEO_JWT_PROVIDER_UID[domainConfigs?.hippocrates?.region],
      providerInput: {
        token: clientJwt,
      },
      applicationInput: {
        domain: selectedClinic,
        patient_verification_fields: [...applicationInputKeyValue],
      },
    };

    props.getHeimdallAuthFlowJWT({ input });
  };

  const onContinuePress = () => {
    props.setClinic(selectedDropdownClinic.value);
    setSelectedClinic(selectedDropdownClinic.value);
  };

  const onClinicLogin = useCallback(
    async (type) => {
      sessionStorage.setItem('clinicDomain', props.domainName);
      const exist = await doesClinicExist(props.domainName, client);
      if (exist) {
        localStorageUtil.save(LOGIN_TYPE_SESSION_KEY, type);
        props.onClinicLogin();
      } else {
        setClinicInvalid(true);
      }
    },
    [client, props]
  );

  const renderLoginTypeSelection = (
    <Box>
      <Box width="100%">
        <H1 fontSize={32} textAlign="left">
          {t('Log in to Bot MD Care')}
        </H1>
      </Box>

      <Body textAlign="left" small mb={3} mt={2}>
        Enter your organization name
      </Body>

      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="row"
        mb={3}
      >
        <Body small mr={1}>
          https://{window.location.hostname}/
        </Body>
        <Flex flex={1} flexDirection="column" alignItems="flex-start">
          <InputField
            autoFocus
            value={props.domainName}
            onChange={(e) => {
              props.onDomainNameChange(toLower(e.target.value));
              setClinicInvalid(false);
            }}
            placeholder="your-org-url"
            width="100%"
            style={{
              borderColor: props.domainName && clinicInvalid ? Colors.red : '',
            }}
          />
          {props.domainName && clinicInvalid && (
            <Text
              color={Colors.red}
              as="span"
              style={{ fontSize: 12, marginBottom: -12 }}
            >
              Institution not found
            </Text>
          )}
        </Flex>
      </Flex>

      <Box mt={4} mb={2} width={['auto', 400]}>
        <PrimaryButton
          disabled={!props.domainName}
          onClick={() => {
            onClinicLogin(LOGIN_TYPES.SMS);
          }}
          width="100%"
        >
          {t('Sign in via Mobile number')}
        </PrimaryButton>
      </Box>
      <Box width={['auto', 400]}>
        <SecondaryOutlinedButton
          disabled={!props.domainName}
          onClick={() => {
            onClinicLogin(LOGIN_TYPES.EMAIL);
          }}
          width="100%"
        >
          {t('Sign in via Email address')}
        </SecondaryOutlinedButton>
      </Box>
      <Body extraSmall textAlign="center" mt={2}>
        {t("Don't have an account?")}{' '}
        <Body style={{ color: '#256BF6' }} extraSmall as="span">
          <a href="mailto:support@botmd.io?subject=Bot MD Care Registration">
            {t('Contact sales')}
          </a>
        </Body>
        .
      </Body>
    </Box>
  );

  return (
    <Flex p={[0, 8]} flex={1} width="100%" height="100%">
      <Flex
        width="100%"
        flex={1}
        flexDirection="column"
        justifyContent="space-evenly"
      >
        <Box
          textAlign={['center', 'inherit']}
          mt={['5px', 0]}
          p={['10px', 0]}
          alignItems={['center', '1']}
          borderBottom={['1px solid #DDE1E6', 'inherit']}
          borderTop={['1px solid #DDE1E6', 'inherit']}
        >
          <Box as="img" pl={3} src={Logo} />
        </Box>
        {domainConfigsUtils.getDomainConfigs()?.hippocrates?.region ===
          'id' && (
          <ErrorBanner
            mt={4}
            text={
              <Trans
                i18nKey="SMSLoginWarning"
                defaults="We are experiencing some issues with sending SMS OTPs at the moment. Please use email to login to the dashboard. We apologise for the inconvenience."
              />
            }
          />
        )}
        <Flex
          flex={1}
          height="100%"
          flexDirection="column"
          justifyContent={clientJwt ? 'flex-start' : 'center'}
          textAlign="center"
          alignItems="center"
          maxWidth={400}
          margin="auto"
        >
          {isNull(props.clinicProps) && !clientJwt && renderLoginTypeSelection}

          {/* Patient login */}
          {!isNull(props.clinicProps) && (
            <Box flex={[0, 1]}>
              <Box textAlign={['center', 'left']}>
                {!isNull(selectedClinic) && (
                  <Box
                    alignSelf={['center', 'flex-start']}
                    as="img"
                    style={{ width: 'auto', height: 100, paddingTop: '50px' }}
                    src={props.clinicProps.logo}
                  />
                )}
                <Body
                  mt={2}
                  alignSelf={['center', 'flex-start']}
                  color="FullShade"
                  fontSize="24px"
                  fontWeight="600"
                  pt="20px"
                >
                  {`${t('Welcome back')}!`}
                </Body>
                <Body fontSize="14px" color="#697481" mb="30px">
                  {t('Log in to view your data.')}
                </Body>
              </Box>

              <Box>
                {isNull(selectedClinic) && (
                  <Box width={350}>
                    <H5 textAlign="left" color="#697481">
                      {t('Clinic')}
                    </H5>
                    <Box data-testid="patient-login-select-clinic">
                      <Select
                        placeholder="Select a Clinic"
                        value={selectedDropdownClinic}
                        options={clinicOptions}
                        onChange={setSelectedDropdownClinic}
                      />
                    </Box>
                    <PrimaryButton
                      data-testid="patient-login-continue-button"
                      width="100%"
                      onClick={onContinuePress}
                      mt={3}
                      mb={3}
                    >
                      {t('Continue')}
                    </PrimaryButton>
                  </Box>
                )}

                {selectedClinic &&
                  isEqual(selectedClinic, props.clinic) &&
                  !props.isLoadingPublicSettings &&
                  props.clinicProps.patientDashboardVerification && (
                    <>
                      <Flex
                        maxWidth={500}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        {renderAuthenticationComponent()}
                      </Flex>
                      <PrimaryButton
                        data-testid="patient-login-button"
                        width="100%"
                        onClick={onLoginPress}
                        mt={3}
                        mb={3}
                      >
                        {t('Log in')}
                      </PrimaryButton>
                    </>
                  )}

                {selectedClinic &&
                  isEqual(selectedClinic, props.clinic) &&
                  !props.clinicProps.patientDashboardVerification && (
                    <Box width={350}>
                      <Body>{t('Please Contact Botmd Care')}</Body>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
        </Flex>

        <Box>
          <Body extraSmall textAlign="center">
            {t('By logging into Bot MD Care, you are agreeing to our')}{' '}
            <Body style={{ color: '#256BF6' }} extraSmall as="span">
              <a
                target="_blank"
                href="https://www.botmd.com/en/legal.html#terms-and-conditions"
                rel="noreferrer"
              >
                {t('Terms of Service')}
              </a>
            </Body>{' '}
            {t('and')}{' '}
            <Body style={{ color: '#256BF6' }} extraSmall as="span">
              <a
                target="_blank"
                href="https://www.botmd.com/en/legal.html#privacy-policy"
                rel="noreferrer"
              >
                {t('Privacy Policy')}
              </a>
            </Body>
          </Body>
        </Box>
      </Flex>
    </Flex>
  );
};

const InformationSection = ({ t }) => (
  <Box display={['none', 'none', 'block']} flex={1}>
    <div
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Flex
        minHeight="100vh"
        p={9}
        pb={6}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box as="img" py={3} src={BotLogoHeart} />
        <H3 fontSize="24px" lineHeight="36px" color="white" mb={3}>
          {t("There's a better way for you to monitor your patients!")}
        </H3>
        <Body color="white" fontSize="16px" lineHeight="36px">
          {t(
            'Bot MD Care improves patient compliance to monitoring by powering chat platforms like WhatsApp.'
          )}{' '}
          <br />
          <br />
          {t(
            'We have created the most efficient way for you to educate and monitor your chronic disease patients at scale.'
          )}
        </Body>
      </Flex>
    </div>
  </Box>
);

export default Login;
