import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoSubmissionCommentCreate(
    $input: CreateCleoSubmissionCommentInput!
  ) {
    cleoSubmissionCommentCreate(input: $input) {
      cleoSubmissionComment {
        uid
        content
        isSeen
        taggedClinicians {
          uid
          name
          isCaregiver
        }
        seenByClinicians {
          uid
        }
        clinician {
          uid
          name
        }
        visibleTo
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'comments',
  keepPreviousData: true,
});
