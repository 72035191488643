import {
  Box,
  H3,
  H6,
  Select,
  SecondaryOutlinedButton,
  PrimaryButton,
  Flex,
} from '@fivehealth/botero';
import { Body } from '@fivehealth/botero/build/components/Text/Text';
import useAlertRemindersData from 'hooks/useAlertRemindersData';
import useMonitoringFormsData from 'hooks/useMonitoringFormsData';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MonitoringFormSelectModal = ({ callback = () => {}, onClose }) => {
  const { t } = useTranslation();

  const [selectedForm, setSelectedForm] = useState();
  const [selectedReminder, setSelectedReminder] = useState();

  const { clinicRemindersSelectOptions } = useAlertRemindersData(true);

  const { monitoringFormsSelectOptions, isLoading } = useMonitoringFormsData();

  return (
    <Box overflow="visible">
      <H3 mb={4}>{t('Select Program template')}</H3>

      <Body small mb={4}>
        {t(
          'Select the program you want to bulk enroll your patients into. You will need to do this for each Program.'
        )}
      </Body>

      <H6 mb={1}>{t('Select program*')}</H6>

      <Box mb={4}>
        <Select
          isMulti
          hideSelectedOptions
          value={selectedForm}
          options={monitoringFormsSelectOptions}
          onChange={(form) => {
            setSelectedForm(form);
          }}
          maxMenuHeight={180}
          menuPlacement="top"
        />
      </Box>

      {clinicRemindersSelectOptions && (
        <Box mb={6}>
          <H6 mb={1}>{t('Select Default Reminder')}</H6>

          <Select
            value={selectedReminder}
            options={[{ label: 'No Reminders', value: null }].concat(
              clinicRemindersSelectOptions
            )}
            onChange={(form) => setSelectedReminder(form)}
            maxMenuHeight={180}
            menuPlacement="top"
          />
        </Box>
      )}

      <Flex justifyContent="flex-end">
        <SecondaryOutlinedButton
          data-testid="add-form-modal-bulk-import-close"
          onClick={onClose}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          ml={3}
          type="submit"
          disabled={isLoading || !selectedForm}
          onClick={() => {
            callback({
              selectedForm,
              selectedReminder,
            });
          }}
        >
          {t('Continue')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default MonitoringFormSelectModal;
