// import liraries
import TabWithFilter from 'components/Tab/TabWithFilter';
import { DataType } from 'constants';
import React from 'react';
import Caregiver from './Caregiver';

const CaregiverList = () => (
  <TabWithFilter
    dataType={DataType.Clinician}
    hasTableConfig={false}
    defaultGQLVariable={{
      isCaregiver: true,
    }}
  >
    <Caregiver />
  </TabWithFilter>
);

export default CaregiverList;
