import React, { useMemo } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  H1,
  H4,
  H5,
  Body,
  theme,
  Flex,
  useApi,
  FAIcon,
} from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { parseISO, format } from 'date-fns';

interface LabelValue {
  label: string;
  value: string | string[] | null;
  toolTip: boolean;
  message?: string;
}

interface BoxPairLabelValuesProps {
  data: LabelValue[];
}

const CustomBoxPairRightPanel: React.FC = ({ children }) => (
  <Box
    mr={10}
    mt={[2, 3]}
    border={`1px solid ${theme.colors.mediumShade}`}
    borderRadius={8}
    p={1}
  >
    {children}
  </Box>
);

const BoxPairLabelValues = ({ data }: BoxPairLabelValuesProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  // function creates a row for each label-value pair
  const renderValues = (rowVal: LabelValue) => {
    // destructuring, take out value
    const { value } = rowVal;
    // check if value is an array or an object
    return isArray(value) ? (
      <Flex flexDirection={['column']}>
        {value.map((val, ind) => (
          <Text key={ind} wordBreak="break-word" textAlign="end">
            {val}
          </Text>
        ))}
      </Flex>
    ) : (
      <Box>
        <Text
          overflowWrap="break-word"
          textAlign={isMobile ? 'start' : 'end'}
          width={isMobile ? '100%' : 'auto'}
          fontSize={14}
        >
          {value}
        </Text>
      </Box>
    );
  };

  return (
    // all the children will be in a column
    <Flex flexDirection="column">
      {data &&
        data.map((row: LabelValue, index: number) => (
          // each label value pair will be row in desktop view
          <Flex
            key={`row-${index}`}
            p={1}
            flexDirection={['column', 'row']}
            alignItems={['unset', 'center']}
          >
            <Flex position="relative" flex={[0, 1]} flexWrap="wrap">
              <H5> {row.label}</H5>
              {row.toolTip && (
                <Tooltip
                  label={row.message}
                  placement="top"
                  borderRadius="8px"
                  backgroundColor="#000000CC"
                  width={240}
                  padding="8px 16px 8px 16px"
                  sx={{
                    boxShadow: '0px 2px 2px 0px #98A2B326',
                    color: '#FFFFFF',
                    fontSize: 14,
                    fontWeight: 400,
                    textAlign: 'center',
                  }}
                >
                  <span>
                    <FAIcon
                      icon={faQuestionCircle}
                      hover={{ opacity: 0.6 }}
                      style={{
                        fontSize: 12,
                        fontWeight: 900,
                        marginLeft: 4,
                        color: theme.colors.darkestShade,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </Flex>
            <Flex>{renderValues(row)}</Flex>
          </Flex>
        ))}
    </Flex>
  );
};

export const BillingDashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const paidUserMessage = t('Total number of user licenses you are paying for');
  const activatedUserMessage = t(
    'Total number of activated user accounts as of today'
  );

  const {
    queries: { useClinic, useClinicGroup },
  } = useApi({
    queries: ['useClinic', 'useClinicGroup'],
  });

  const { data: clinic, isLoading: isLoadingClinicData } = useClinic();

  const { data: clinicGroup } = useClinicGroup();

  const clinicianCountDisplay = useMemo(() => {
    if (isLoadingClinicData) {
      return '...';
    }
    if (clinic?.isClinicBilling) {
      return clinic?.clinicianCount;
    }
    return clinicGroup?.clinicianCount;
  }, [
    clinic?.clinicianCount,
    clinic?.isClinicBilling,
    clinicGroup?.clinicianCount,
    isLoadingClinicData,
  ]);

  const billingDetails = useMemo(() => {
    if (isLoadingClinicData) {
      return {
        planPackage: '...',
        planType: '...',
        maximumNumberOfUsers: '... licenses',
        billingStartDate: '...',
        billingEndDate: '...',
      };
    }
    return {
      planPackage: clinic?.billingSettings?.plan_package || 'N/A',
      planType: clinic?.billingSettings?.plan_type || 'N/A',
      maximumNumberOfUsers: clinic?.clinicianCount
        ? `${clinic?.clinicianCount} licenses`
        : 'N/A',
      billingStartDate: clinic?.billingSettings?.billing_start_date
        ? format(
            parseISO(clinic?.billingSettings?.billing_start_date),
            'dd MMM yyyy'
          )
        : 'N/A',
      billingEndDate: clinic?.billingSettings?.billing_end_date
        ? format(
            parseISO(clinic?.billingSettings?.billing_end_date),
            'dd MMM yyyy'
          )
        : 'N/A',
    };
  }, [
    clinic?.billingSettings?.billing_end_date,
    clinic?.billingSettings?.billing_start_date,
    clinic?.billingSettings?.plan_package,
    clinic?.billingSettings?.plan_type,
    clinic?.clinicianCount,
    isLoadingClinicData,
  ]);

  const details = useMemo(
    () =>
      [
        {
          label: t('Bot MD Care Plan'),
          value: billingDetails.planPackage,
          toolTip: false,
        },
        {
          label: t('Payment Frequency'),
          value: billingDetails.planType,
          toolTip: false,
        },
        {
          label: t('Workspace licenses'),
          value: billingDetails.maximumNumberOfUsers,
          toolTip: true,
          message: paidUserMessage,
        },
        {
          label: t('Activated licenses'),
          value: `${
            // eslint-disable-next-line no-nested-ternary
            clinicianCountDisplay
          } licenses`,
          toolTip: true,
          message: activatedUserMessage,
        },
        {
          label: t('Subscription start date'),
          value: billingDetails.billingStartDate,
          toolTip: false,
        },
        {
          label: t('Auto renewal on'),
          value: billingDetails.billingEndDate,
          toolTip: false,
        },
      ] as LabelValue[],
    [
      activatedUserMessage,
      billingDetails.billingEndDate,
      billingDetails.billingStartDate,
      billingDetails.maximumNumberOfUsers,
      billingDetails.planPackage,
      billingDetails.planType,
      clinicianCountDisplay,
      paidUserMessage,
      t,
    ]
  );

  return (
    <>
      <Box py={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <H1>{t('Billing')}</H1>
            <Body pt={2}>{t('Manage licenses for clincial users.')}</Body>
          </Box>
        </Flex>
      </Box>
      <Box mt={[0, 2]} fontSize={14} flexWrap="wrap">
        <Flex mb={[4, 3]} flexDirection={['column', 'row']} fontSize={14}>
          <Flex width={[300, 280]} pt={4} pr={1} pb={2}>
            <Flex mt={5}>
              <Box width="256px" mr={6} p={1}>
                <H4>Clinical Users</H4>
                <Body color="darkestShade" mt={1}>
                  Contact{' '}
                  <a href="mailto:sales@botmd.io" style={{ color: '#256BF6' }}>
                    sales@botmd.io
                  </a>{' '}
                  to purchase more licenses
                </Body>
              </Box>
            </Flex>
          </Flex>
          <Flex
            flexDirection={['column']}
            minWidth={300}
            maxWidth={720}
            ml={[0, 7]}
            flex={1}
            mt={[0, 0]}
            py={6}
          >
            <CustomBoxPairRightPanel>
              <BoxPairLabelValues data={details} />
            </CustomBoxPairRightPanel>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
