import { gql } from 'graphql-request';
import { get, isEmpty } from 'lodash';
import { createQuery } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { buildPaginatedResponse } from 'lib/pagination';
import { formatISO, startOfDay, sub } from 'date-fns';
import useMonitoringFormsets from './useMonitoringFormsets';

const patientDataFullFragment = (excludeVariablesAll = false) => `
edges {
  node {
    phone
    email
    gender
    age
    dateOfBirth
    identifier
    language
    communicationMethod
    deactivatedOn
    uid
    name
    id
    createdOn
    ics {
      uid
      name
    }
    alertees {
      uid
      name
    }
    ratatoskrDevices {
      communicationMethod
      device {
        uid
        isSubscribed
      }
    }
    isDeviceCreated
    isDeviceSubscribed
    isFollower
    patientPrograms {
      clinicProgram {
        name
      }
    }
    ${excludeVariablesAll ? '' : 'variablesAll'}
    dischargedMonitoringForms {
      uid
      url
      effectiveName
    }
    enrolledMonitoringForms {
      uid
      url
      effectiveName
    }
  }
}
`;

const patientDataSubsetFragment = `
edges {
  node {
    phone
    email
    identifier
    language
    uid
    name
    id
  }
}
`;

export const PATIENTS_GRAPHQL_DOCUMENT = (fragment) => gql`
  query cleoPatients(
    $orderField: PatientOrderBy
    $orderDesc: Boolean
    $deactivatedOn_Isnull: Boolean
    $first: Int
    $offset: Int
    $search: String
    $clinicianIcUid: [String]
    $monitoringFormUidIn: [String]
    $genderIn: [PatientGender]
    $enrolledAfter: DateTime
    $communicationMethod: [PatientCommunicationMethod]
    $enrolledMonitoringFormIn: [String!]
    $groupsIn: [String!]
    $isDeviceCreated: Boolean
    $isDeviceSubscribed: Boolean
    $isFollower: Boolean
  ) {
    cleoPatients(
      sortBy: $orderField
      deactivatedOn_Isnull: $deactivatedOn_Isnull
      first: $first
      offset: $offset
      sortDesc: $orderDesc
      search: $search
      patientForms_ClinicianRelations_Clinician_Uid_In: $clinicianIcUid
      patientForms_MonitoringForm_Uid_In: $monitoringFormUidIn
      enrolledMonitoringFormIn: $enrolledMonitoringFormIn
      gender_In: $genderIn
      enrolledAfter: $enrolledAfter
      communicationMethod_In: $communicationMethod
      groupsUidIn: $groupsIn
      isDeviceCreated: $isDeviceCreated
      isDeviceSubscribed: $isDeviceSubscribed
      isFollower: $isFollower
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      ${fragment}
    }
  }
`;

export const onMapNodePatients = (node) => {
  const getGender = () => (node.gender === 'M' ? 'Male' : 'Female');
  const gender = isEmpty(node.gender) ? '' : getGender();

  return {
    ...node,
    gender,
    id: node.uid,
    original: {
      name: node.name,
      ...node,
      id: node.uid,
    },
    variablesAll: {
      ...get(node, 'variablesAll', {}),
      dischargedMonitoringForms: node.dischargedMonitoringForms ?? [],
      monitoringForms: node.enrolledMonitoringForms ?? [],
    },
  };
};

export const dateRange = [
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    toParams: {
      enrolledAfter: formatISO(startOfDay(sub(new Date(), { days: 6 }))),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      enrolledAfter: formatISO(startOfDay(sub(new Date(), { days: 29 }))),
    },
  },
  {
    id: 'last_60_days',
    label: 'Last 60 days',
    toParams: {
      enrolledAfter: formatISO(startOfDay(sub(new Date(), { days: 59 }))),
    },
  },
  {
    id: 'last_90_days',
    label: 'Last 90 days',
    toParams: {
      enrolledAfter: formatISO(startOfDay(sub(new Date(), { days: 89 }))),
    },
  },
];

export const genders = [
  {
    id: 'male',
    label: 'Male',
    toParams: {
      genderIn: ['M'],
    },
  },
  {
    id: 'female',
    label: 'Female',
    toParams: {
      genderIn: ['F'],
    },
  },
];

const domainsToExcludeVariablesAll = ['onecare'];

const usePatients = () => (args) => {
  /* eslint-disable react-hooks/rules-of-hooks */

  const requestForDropdown = args?.variables?.isForDropdown;

  // eslint-disable-next-line no-param-reassign
  delete args?.variables?.isForDropdown;

  const { t } = useTranslation();

  const clinicDomain = sessionStorage.getItem('clinicDomain');

  const excludeVariablesAll =
    domainsToExcludeVariablesAll.includes(clinicDomain);

  const { data: monitoringFormsets } = useMonitoringFormsets({
    enrolledOnly: args.variables.deactivatedOn_Isnull,
    variables: {
      isEnrollmentForm: false,
    },
  });

  const patients = createQuery({
    gqlDocument: PATIENTS_GRAPHQL_DOCUMENT(
      requestForDropdown
        ? patientDataSubsetFragment
        : patientDataFullFragment(excludeVariablesAll)
    ),
    queryType: 'query',
    baseQueryKey: 'patients',
    paginate: 'offset',
    filters: {
      genderFilters: {
        id: 'gender',
        title: t('Gender'),
        data: genders,
      },
      dateRangeFilters: {
        id: 'enrollmentDate',
        title: t('Enrollment date'),
        data: dateRange,
      },
      monitoringFormsets: {
        id: 'monitoringFormsets',
        title: t('Programs'),
        data: monitoringFormsets,
        multiSelect: true,
      },
      devicesFilters: {
        id: 'devices',
        title: t('Device status'),
        data: [
          {
            id: 'device_created',
            label: t('Device connected, subscribed'),
            toParams: {
              isDeviceCreated: true,
              isDeviceSubscribed: true,
              isFollower: true,
            },
          },
          {
            id: 'device_subscribed',
            label: t('Device connected, unsubscribed'),
            toParams: {
              isDeviceCreated: true,
              isDeviceSubscribed: false,
            },
          },
          {
            id: 'not_connected',
            label: t('No device connected'),
            toParams: {
              isDeviceCreated: false,
            },
          },
        ],
      },
    },

    getNextPageParam: ({ cleoPatients: data } = {}, allPages) => {
      const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
      if (!hasNextPage) {
        return undefined;
      }

      const currentOffset = allPages.flatMap(({ cleoPatients }) =>
        get(cleoPatients, 'edges', [])
      ).length;
      return currentOffset;
    },
    select: (data) =>
      buildPaginatedResponse(data, 'cleoPatients', onMapNodePatients),
    keepPreviousData: true,
  });
  return patients(args);
};
export default usePatients();
