import React from 'react';
import { Flex, H5, Body, Box } from '@fivehealth/botero';
import { withTranslation } from 'react-i18next';
import BotAvatar from '../../assets/bot-table-avatar.svg';

const NoResults = React.memo(
  withTranslation()(
    ({
      avatar = BotAvatar,
      title = 'No results found',
      avatarProps = {},
      t,
    }) => (
      <Flex pt={30} justifyContent="center" borderTop="1px solid #d5d9de">
        <Flex alignItems="center" flexDirection="column">
          <Box
            as="img"
            src={avatar}
            height={96}
            width={96}
            alt="Bot Avatar"
            {...avatarProps}
          />
          <H5 mt={20}>{t(title)}</H5>
          <Body small textAlign="center">
            {t(
              "Try adjusting your search or filter to find what you're looking for."
            )}
          </Body>
        </Flex>
      </Flex>
    )
  )
);

export default NoResults;
