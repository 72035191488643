import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import _, { isEmpty, isNull } from 'lodash';
import {
  Box,
  Flex,
  FAIcon,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  theme,
} from '@fivehealth/botero';

import {
  faChevronLeft,
  faExclamationTriangle,
  faPlusCircle,
  faUser,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/pro-regular-svg-icons';

import { useModal } from 'context/ModalContext';
import { useTranslation } from 'react-i18next';
import { charPluralize } from 'AppUtils';
import moment from 'moment';
import PatientsBulkUploadConfirmation from './PatientsBulkUploadConfirmation';

const fields = [
  { key: 'name', title: 'name' },
  { key: 'identifier', title: 'identifier' },
  { key: 'phone', title: 'phone' },
  { key: 'gender', title: 'gender' },
  { key: 'dateOfBirth', title: 'Date of birth' },
  { key: 'errors', title: 'Errors' },
];

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`;

const PatientsBulkUploadStats = ({
  onBackPress,
  onComplete,
  data,
  monitoringForm,
  reminder,
  stitchUploadId,
}) => {
  const { openModal, closeModal } = useModal();
  const [expand, setExpand] = useState({});

  const { t } = useTranslation();

  if (monitoringForm && fields.filter((f) => f.key === 'mf').length === 0) {
    fields.splice(2, 0, {
      key: 'mf',
      title: monitoringForm.length > 1 ? 'Programs' : 'Program',
    });
  }

  const tabs = useMemo(
    () => [
      {
        id: 'errorEntries',
        title: t('Error with formatting'),
        icon: faExclamationTriangle,
        fields,
        description: 'patients containing errors will not be updated.',
      },
      {
        id: 'newEntries',
        title: t('To be added'),
        icon: faPlusCircle,
        fields,
      },
      {
        id: 'existingEntries',
        title: t('No Changes'),
        icon: faUser,
        fields,
      },
    ],
    [t]
  );

  const uploadResponse = data;
  const label = monitoringForm
    ? t('Bulk import & enrollment')
    : t('Bulk import');

  const subLabel = monitoringForm
    ? t(
        `${data?.newEntries.length} ${charPluralize(
          data?.newEntries.length,
          'patient'
        )} enrolled in ${monitoringForm
          ?.map((form) => t(form.label))
          .join(', ')}`
      )
    : t(
        `${data?.newEntries.length} ${charPluralize(
          data?.newEntries.length,
          'patient'
        )} added`
      );

  const getUsers = (state) => {
    if (_.isEqual(state, 'errorEntries')) {
      return _.get(data, 'errorEntries', []);
    }
    return _.get(data, `${state}`, []);
  };

  const handleContinue = (action) => {
    openModal(
      <PatientsBulkUploadConfirmation
        stitchUploadId={stitchUploadId}
        monitoringForm={monitoringForm}
        reminder={reminder}
        action={action}
        label={label}
        subLabel={subLabel}
        onBackPress={onBackPress}
        closeModal={closeModal}
        duplicateEntries={data?.duplicateEntries}
        onCancel={() => {
          closeModal();
          onComplete();
        }}
        onComplete={() => {
          closeModal();
          onBackPress();
          onComplete();
        }}
      />,
      {
        style: {
          width: '30%',
        },
      }
    );
  };

  const getValue = (patient, key) => {
    if (key === 'dateOfBirth') {
      if (isEmpty(_.get(patient, key))) {
        return '-';
      }
      return moment(_.get(patient, key)).format('YYYY-MM-DD');
    }

    if (key === 'mf') {
      return monitoringForm?.map((form) => t(form.label)).join(', ');
    }
    if (key === 'errors') {
      return _.get(patient, key)?.join(', ') ?? '';
    }
    return _.get(patient, key, '-');
  };

  const existingEntriesChangesDetected = useMemo(() => {
    const existingEntries = _.get(data, 'existingEntries', []).filter(
      (patient) =>
        (!isNull(patient.isPatientActivated) &&
          patient.isPatientActivated === false) ||
        (!isNull(patient.isPatientFormActivated) &&
          patient.isPatientFormActivated === false)
    );

    return existingEntries.length;
  }, [data]);

  const checkHasPatientBeingUpdated = (patient) => {
    if (
      (!isNull(patient.isPatientActivated) &&
        patient.isPatientActivated === false) ||
      (!isNull(patient.isPatientFormActivated) &&
        patient.isPatientFormActivated === false)
    ) {
      return true;
    }
    return false;
  };

  const patientsCount = useMemo(
    () => _.get(data, 'newEntries.length', 0) + existingEntriesChangesDetected,
    [data, existingEntriesChangesDetected]
  );

  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={onBackPress}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={13} fontWeight="500" color="darkestShade">
          {t(`Back to bulk import`)}
        </H5>
      </BackButton>

      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Text m={2} fontSize={5} fontWeight="700">
          {`${data?.newEntries.length} new patients found`}
        </Text>
        <Flex m={2} mr={5} alignItems="center" justifyContent="right">
          <SecondaryOutlinedButton
            mr={3}
            onClick={() => handleContinue('discard')}
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>

          <PrimaryButton
            disabled={patientsCount === 0}
            onClick={() => handleContinue('confirmation')}
          >
            {t('Continue')}
          </PrimaryButton>
        </Flex>
      </Flex>

      {_.isNull(data) ? null : (
        <Box mr={2}>
          {_.map(tabs, (tab) => {
            let tabTitle = tab.title;
            let desc = '';

            if (
              _.isEqual(tab.id, 'existingEntries') &&
              existingEntriesChangesDetected > 0
            ) {
              tabTitle = 'Existing patients: Change detected';
              desc =
                'Patients with changes will be reactivated and start receiving notifications from the program';
            }

            return data[tab.id]?.length > 0 ? (
              <Box
                key={tab.id}
                ml={2}
                mr={3}
                mt={3}
                p={2}
                borderRadius="10px"
                borderStyle="solid"
                borderWidth={1}
                borderColor={
                  _.isEqual(tab.id, 'errorEntries') &&
                  !_.isEmpty(uploadResponse.invalid_rows)
                    ? 'red'
                    : 'darkestShade'
                }
                onClick={() =>
                  setExpand({
                    [tab.id]: !_.get(expand, tab.id, false),
                  })
                }
              >
                {/* Title */}
                <Flex justifyContent="space-between">
                  <Flex alignItems="center">
                    <FAIcon
                      icon={tab.icon}
                      color={
                        _.isEqual(tab.id, 'errorEntries') &&
                        !_.isEmpty(data.errorEntries)
                          ? '#E05138'
                          : 'darkestShade'
                      }
                    />
                    <Text
                      ml={4}
                      fontWeight="600"
                      fontSize="18px"
                      color={
                        _.isEqual(tab.id, 'errorEntries') &&
                        !_.isEmpty(data.errorEntries)
                          ? '#E05138'
                          : 'fullShade'
                      }
                    >
                      {tabTitle}
                    </Text>
                  </Flex>
                  <Flex justifyContent="right" alignItems="center">
                    <FAIcon
                      icon={
                        _.get(expand, tab.id, false) ? faAngleUp : faAngleDown
                      }
                    />
                  </Flex>
                </Flex>

                {/* Tab */}
                <Box ml={6} mt={2}>
                  {/* Stats */}
                  <Text
                    m={1}
                    color={
                      _.isEqual(tab.id, 'errorEntries') &&
                      !_.isEmpty(data.errorEntries)
                        ? '#E05138'
                        : 'darkestShade'
                    }
                  >
                    {`${getUsers(tab.id).length} patients`}
                  </Text>

                  {/* Table */}
                  {_.get(expand, tab.id, false) && (
                    <>
                      <Flex m={1} mt={3}>
                        {_.map(tab.fields, (field) => (
                          <Text
                            key={field.key}
                            fontWeight="600"
                            width="200px"
                            color="fullShade"
                          >
                            {_.startCase(field.title)}
                          </Text>
                        ))}
                      </Flex>
                      <Box
                        border="1px solid"
                        borderWidth={2}
                        borderColor={theme.colors.lightestShade}
                      >
                        {_.map(getUsers(tab.id), (patient, index) => (
                          <Flex
                            p={1}
                            style={{
                              border: checkHasPatientBeingUpdated(patient)
                                ? `1px solid ${theme.colors.primary}`
                                : 'none',
                              background:
                                (index + 1) % 2 === 0
                                  ? theme.colors.white
                                  : theme.colors.lightestShade,
                            }}
                            key={index}
                          >
                            {_.map(tab.fields, (field) => (
                              <Text
                                key={`${index}-${field.key}`}
                                width="200px"
                                color="darkestShade"
                              >
                                {getValue(patient, field.key)}
                              </Text>
                            ))}
                          </Flex>
                        ))}
                      </Box>
                    </>
                  )}
                  {desc && (
                    <Text
                      fontWeight="bold"
                      fontSize={14}
                      mt={2}
                      color="primary"
                    >
                      {desc}
                    </Text>
                  )}
                </Box>
              </Box>
            ) : null;
          }).filter(Boolean)}
        </Box>
      )}
    </Box>
  );
};

export default PatientsBulkUploadStats;
