// import liraries
import {
  Box,
  FAIcon,
  Flex,
  H2,
  SecondaryOutlinedButton,
  PrimaryButton,
  useApi,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupInfoInputs from './GroupInfoInputs';

// create a component
const EditGroupInfo = ({ selectedGroup, closeModal }) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState(selectedGroup?.name);
  const [groupDescription, setGroupDescription] = useState(
    selectedGroup?.description
  );

  const {
    queries: { usePatientGroupsUpdate },
  } = useApi({
    queries: ['usePatientGroupsUpdate'],
  });

  const { mutateAsync: updateGroupInfo, isLoading } = usePatientGroupsUpdate({
    variables: {},
  });

  const onSave = async () => {
    await updateGroupInfo({
      input: {
        name: groupName,
        description: groupDescription,
        uid: selectedGroup.uid,
      },
    });
    closeModal();
  };

  return (
    <Box>
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <H2>
          {t('Edit')}: {selectedGroup.name}
        </H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>

      <GroupInfoInputs
        width="100%"
        groupDescription={groupDescription}
        groupName={groupName}
        setGroupDescription={setGroupDescription}
        setGroupName={setGroupName}
      />

      <Flex
        mt={6}
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent={['', 'right']}
      >
        <SecondaryOutlinedButton
          mr={[0, 3]}
          mb={[2, 0]}
          p="0px 24px"
          width={['100%', 94]}
          onClick={closeModal}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton onClick={onSave} width={['100%', 150]}>
          {isLoading ? t('Updating...') : t('Save changes')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default EditGroupInfo;
