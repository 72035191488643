import { Box, Flex } from '@chakra-ui/react';
import React, { memo, ReactNode } from 'react';
import { FAIcon, Body } from '@fivehealth/botero';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import Colors from 'constants/colors';
import { useTranslation } from 'react-i18next';

interface InfoContentProps {
  icon?: ReactNode;
  message: string;
  bg?: string;
  iconColor?: string;
  textColor?: string;
  showIcon?: boolean;
}

export const InfoText = memo<InfoContentProps>(
  ({
    icon = faExclamationTriangle,
    message,
    bg,
    iconColor,
    textColor,
    showIcon = true,
  }) => {
    const { t } = useTranslation();

    return (
      <Flex
        borderRadius="5px"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        bg={bg || Colors.lightBlue}
      >
        {showIcon && (
          <Box mr={2}>
            <FAIcon fontSize={15} icon={icon} color={iconColor || 'primary'} />
          </Box>
        )}
        <Body
          style={{ textAlign: 'left', flex: 1 }}
          small
          color={textColor ?? Colors.primary}
        >
          {t(message)}
        </Body>
      </Flex>
    );
  }
);
