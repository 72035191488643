/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/order */
import moment, { Moment } from 'moment';
import DOMPurify from 'dompurify';
import request from 'graphql-request';
import { format, isEqual, isAfter } from 'date-fns';
import {
  get,
  chain,
  map,
  noop,
  isUndefined,
  escapeRegExp,
  isArray,
  isObject,
  toLower,
  isEmpty,
} from 'lodash';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import {
  parseMessageJSON,
  Body,
  theme,
  Flex,
  FAIcon,
  Text,
} from '@fivehealth/botero';

import TableCell from 'views/PatientMonitoring/TableCell';
import StatusCell from 'views/PatientMonitoring/StatusCell';
import { chartColors } from 'views/PatientsList/Graphs/PatientCharts';
import { SubmissionCell } from 'views/PatientMonitoring/SubmissionTable';

import BGTableCell from 'components/BGTableCell';
import Markdown from 'components/Markdown/Markdown';
import TableSectionHeader from 'components/TableSectionHeader';
import TableSubSectionHeader from 'components/TableSubSectionHeader';
import UnreadCommentIconIndicator from 'components/Comment/UnreadCommentIconIndicator';
import {
  CircleChartIcon,
  DiamondChartIcon,
  SquareChartIcon,
} from 'components/Charts/ChartTooltip';

import {
  GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT,
  GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT_EMAIL,
} from 'api/queries/usePatientFormsExport';
import {
  GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT,
  GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT_EMAIL,
} from 'api/queries/useSubmissionsExport';
import {
  EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT,
  EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT_EMAIL,
} from 'api/queries/useSubmissionsPatientExport';
import { GRAPHQL_DOCUMENT_HEIMDALL_DISCOVERY } from 'api/queries/useHeimdallDiscovery';
import { GRAPHQL_DOCUMENT_HEIMDALL_JWT } from 'api/queries/useHeimdallAuthorizationFlow';

import { BoxContent } from 'components/Table/Table';
import { GRAPHQL_DOCUMENT_CLINIC_PUBLIC_SETTINGS } from 'api/queries/useClinicPublicSettings';
import { GRAPHQL_DOCUMENT_PATIENT_IMPORT_STATUS } from 'api/queries/usePatientsImportAsyncStatus';
import { MESSAGE_TEMPLATE_SEND_GRAPHQL_DOCUMENT } from 'api/queries/usePatientMessageCreateAsyncStatus';

import NoShow from 'components/icons/NoShow';
import Check from 'components/icons/check';
import Person from 'components/icons/person';
import QuestionMarkSquare from 'components/icons/questionMarkSquare';
import { GRAPHQL_DOCUMENT_EVENTS_UPDATE_STATUS } from 'api/queries/usePatientEventUpdateAsync';
import Config, {
  LOGIN_PROVIDER_UID_EMAIL,
  LOGIN_PROVIDER_UID,
  LOGIN_TYPE_SESSION_KEY,
  LOGIN_TYPES,
} from './Config';
import {
  GRAPHQL_DOCUMENT_DELIVERIES_EXPORT,
  GRAPHQL_DOCUMENT_DELIVERIES_EXPORT_EMAIL,
} from './api/queries/useDeliveriesExport';
import React, { ReactNode } from 'react';
import { QueryClient } from 'react-query';
import { Appointment } from 'views/Appointments/AppointmentDetails';
import { COMMENT_VISIBILITY } from './constants';
import { GRAPHQL_DOCUMENT_ENROLL_DISCHARGE } from 'api/queries/usePatientFormEnrollDischargeAsync';
import { GRAPHQL_DOCUMENT_PATIENT_EVENTS_DELETE } from 'api/queries/usePatientEventDeleteAsync';

export const getFirstAndLastName = (fullName: string) => {
  const firstName = fullName.split(' ').slice(0, -1).join(' ');
  const lastName = fullName.split(' ').slice(-1).join(' ');

  return {
    firstName,
    lastName,
  };
};

export const hasTimeInString = (dateString: string) => {
  const regex = /([0-1]?[0-9]|2[0-3]):[0-5][0-9]/i;
  return regex.test(dateString);
};

export const doesClinicExist = async (
  domain: string,
  client: {
    request: (
      arg0: string,
      arg1: { domain: string }
    ) =>
      | PromiseLike<{ cleoClinicPublicSettings: any }>
      | { cleoClinicPublicSettings: any };
  }
) => {
  try {
    const { cleoClinicPublicSettings } = await client.request(
      GRAPHQL_DOCUMENT_CLINIC_PUBLIC_SETTINGS,
      {
        domain,
      }
    );

    return !!cleoClinicPublicSettings;
  } catch (error) {
    return false;
  }
};

export const getClinicalDesignationOptions = (designations: any[]) =>
  designations?.map((item) => ({
    label: item.designation.trim(),
    value: item.designation.trim(),
  }));

export const checkMessageTemplateSendStatus = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { input: { asyncJobUid: any } }) => any;
  },
  asyncJobUid: never
) => {
  try {
    const data = await queryClient.fetchQuery(
      'patientMessageCreateAsyncStatus',
      () =>
        client.request(MESSAGE_TEMPLATE_SEND_GRAPHQL_DOCUMENT, {
          input: {
            asyncJobUid,
          },
        })
    );

    if (!data) {
      return {
        cleoPatientImportAsync: {
          asyncJob: {
            status: 'FAILED',
          },
        },
      };
    }
    return data;
  } catch (error) {
    return {
      cleoPatientImportAsync: {
        asyncJob: {
          status: 'FAILED',
        },
      },
    };
  }
};

export const checkEnrollDischargeAsyncStatus = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { input: { asyncJobUid: any } }) => any;
  },
  asyncJobUid: never
) => {
  try {
    const data = await queryClient.fetchQuery(
      'patientFormEnrollDischargeAsync',
      () =>
        client.request(GRAPHQL_DOCUMENT_ENROLL_DISCHARGE, {
          input: {
            asyncJobUid,
          },
        })
    );
    if (!data) {
      return {
        cleoPatientFormEnrollDischargeAsync: {
          asyncJob: {
            status: 'FAILED',
          },
        },
      };
    }
    return data;
  } catch (error) {
    return {
      cleoPatientFormEnrollDischargeAsync: {
        asyncJob: {
          status: 'FAILED',
        },
      },
    };
  } finally {
    setTimeout(() => {
      queryClient.resetQueries();
    }, 2000);
  }
};

export const checkEventsDeleteStatus = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { input: { asyncJobUid: any } }) => any;
  },
  asyncJobUid: never
) => {
  try {
    const data = await queryClient.fetchQuery('patientEventDeleteAsync', () =>
      client.request(GRAPHQL_DOCUMENT_PATIENT_EVENTS_DELETE, {
        input: {
          asyncJobUid,
        },
      })
    );
    if (!data) {
      return {
        cleoPatientEventDeleteAsync: {
          asyncJob: {
            status: 'FAILED',
          },
        },
      };
    }
    return data;
  } catch (error) {
    return {
      cleoPatientEventDeleteAsync: {
        asyncJob: {
          status: 'FAILED',
        },
      },
    };
  }
};

export const checkEventsUpdateStatus = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { input: { asyncJobUid: any } }) => any;
  },
  asyncJobUid: never
) => {
  try {
    const data = await queryClient.fetchQuery('patientEventUpdateAsync', () =>
      client.request(GRAPHQL_DOCUMENT_EVENTS_UPDATE_STATUS, {
        input: {
          asyncJobUid,
        },
      })
    );
    if (!data) {
      return {
        cleoPatientEventUpdateAsync: {
          asyncJob: {
            status: 'FAILED',
          },
        },
      };
    }
    return data;
  } catch (error) {
    return {
      cleoPatientEventUpdateAsync: {
        asyncJob: {
          status: 'FAILED',
        },
      },
    };
  }
};

export const checkPatientImportStatus = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { input: { asyncJobUid: any } }) => any;
  },
  asyncJobUid: never
) => {
  try {
    const data = await queryClient.fetchQuery('patientsImportAsyncStatus', () =>
      client.request(GRAPHQL_DOCUMENT_PATIENT_IMPORT_STATUS, {
        input: {
          asyncJobUid,
        },
      })
    );
    if (!data) {
      return {
        cleoPatientImportAsync: {
          asyncJob: {
            status: 'FAILED',
          },
        },
      };
    }
    return data;
  } catch (error) {
    return {
      cleoPatientImportAsync: {
        asyncJob: {
          status: 'FAILED',
        },
      },
    };
  }
};

export const getPatientSubmissions = async (
  queryClient: QueryClient,
  client: {
    request: (
      arg0: string,
      arg1: {
        patientForm_Patient_Uid_In: any;
        outputFormat: any;
        email?: any;
      }
    ) => any;
  },
  patientUids: any[],
  email: any,
  outputFormat: string
) => {
  let data;
  if (!email) {
    data = await queryClient.fetchQuery('submissionsPatientExport', () =>
      client.request(EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT, {
        patientForm_Patient_Uid_In: patientUids,
        outputFormat: outputFormat?.toUpperCase() || '',
      })
    );
  } else {
    data = await queryClient.fetchQuery('submissionsPatientExport', () =>
      client.request(EXPORT_PATIENT_SUBMISSIONS_GRAPHQL_DOCUMENT_EMAIL, {
        patientForm_Patient_Uid_In: patientUids,
        email,
        outputFormat: outputFormat?.toUpperCase() || '',
      })
    );
  }
  queryClient.resetQueries('submissionsPatientExport', { exact: true });

  return data;
};

export const getSubmissionsExport = async (
  queryClient: QueryClient,
  client: {
    request: (
      arg0: string,
      arg1: {
        outputFormat: any;
        email?: any;
      }
    ) => any;
  },
  email: any,
  outputFormat: string,
  restParams = {}
) => {
  let data;
  if (!email) {
    data = await queryClient.fetchQuery('submissionsExport', () =>
      client.request(GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT, {
        outputFormat: outputFormat?.toUpperCase() || '',
        ...restParams,
      })
    );
  } else {
    data = await queryClient.fetchQuery(
      'submissionsExport',
      () =>
        client.request(GRAPHQL_DOCUMENT_SUBMISSIONS_EXPORT_EMAIL, {
          email,
          outputFormat: outputFormat?.toUpperCase() || '',
          ...restParams,
        }),
      { staleTime: 99999, cacheTime: 99999 }
    );
  }
  queryClient.resetQueries('submissionsExport', { exact: true });

  return data;
};

export const getMessageDeliveriesExport = async (
  queryClient: QueryClient,
  client: {
    request: (arg0: string, arg1: { outputFormat: any; email?: any }) => any;
  },
  email: any,
  outputFormat: string,
  restParams = {}
) => {
  let data;
  if (!email) {
    data = await queryClient.fetchQuery('deliveriesExport', () =>
      client.request(GRAPHQL_DOCUMENT_DELIVERIES_EXPORT, {
        outputFormat: outputFormat?.toUpperCase() || '',
        ...restParams,
      })
    );
  } else {
    data = await queryClient.fetchQuery(
      'deliveriesExport',
      () =>
        client.request(GRAPHQL_DOCUMENT_DELIVERIES_EXPORT_EMAIL, {
          email,
          outputFormat: outputFormat?.toUpperCase() || '',
          ...restParams,
        }),
      { staleTime: 99999, cacheTime: 99999 }
    );
  }
  queryClient.resetQueries('deliveriesExport', { exact: true });

  return data;
};

export const getPatientFormsExport = async (
  queryClient: QueryClient,
  client: {
    request: (
      arg0: string,
      arg1: {
        outputFormat: any;
        email?: any;
      }
    ) => any;
  },
  email: any,
  outputFormat: string,
  restParams = {}
) => {
  let data;

  if (!email) {
    data = await queryClient.fetchQuery('patientFormsExport', () =>
      client.request(GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT, {
        outputFormat: outputFormat?.toUpperCase() || '',
        ...restParams,
      })
    );
  } else {
    data = await queryClient.fetchQuery('patientFormsExport', () =>
      client.request(GRAPHQL_DOCUMENT_PATIENT_FORMS_EXPORT_EMAIL, {
        email,
        outputFormat: outputFormat?.toUpperCase() || '',
        ...restParams,
      })
    );
  }
  queryClient.resetQueries('patientFormsExport', { exact: true });

  return data;
};

export const getHeimdalDiscoveryConfigs = async (url: string) => {
  const { heimdallDiscovery } = (await request(
    Config.GQL_ENDPOINT,
    GRAPHQL_DOCUMENT_HEIMDALL_DISCOVERY,
    { url }
  ).then((data) => data)) as { heimdallDiscovery: any };
  return heimdallDiscovery;
};

const isCaregiver = (heimdallAuthorizationFlow: {
  session: { scopes: any };
}) => {
  const {
    session: { scopes },
  } = heimdallAuthorizationFlow;

  return (
    scopes?.filter((item: string | string[]) => item.includes('clinician'))
      ?.length > 0 || false
  );
};

export const isCaregiverLogin = async (input: {
  providerApplicationUid: any;
  providerInput: { token: string | null };
  applicationInput: { patient_verification_fields: never[] };
}) => {
  try {
    const { heimdallAuthorizationFlow } = (await request(
      Config.GQL_ENDPOINT,
      GRAPHQL_DOCUMENT_HEIMDALL_JWT,
      { input }
    ).then((data) => data)) as { heimdallAuthorizationFlow: any };
    return {
      isCaregiver: isCaregiver(heimdallAuthorizationFlow),
      heimdallAuthorizationFlow,
    };
  } catch (e) {
    return null;
  }
};

export const applyLineBreak = (str: string) => {
  const split = str.split('<br />');
  return (
    <div>
      {split.map(
        (
          s:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <div key={index}>{s}</div>
        )
      )}
    </div>
  );
};

export const isExportBtnDisable = (
  outputType: any,
  dataType: any,
  exportDestination: { downloadFile: any; email: any }
): boolean => {
  if (outputType && dataType) {
    if (get(exportDestination, 'downloadFile', true)) {
      return false;
    }

    if (!exportDestination?.downloadFile && !exportDestination?.email) {
      return true;
    }
    return false;
  }
  return true;
};

export const validateEmail = (email: string) => {
  /* eslint no-useless-escape: 0 */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getChartIcon = (symbol: string): ReactNode => {
  const Icons: Record<string, ReactNode> = {
    circle: CircleChartIcon,
    diamond: DiamondChartIcon,
    square: SquareChartIcon,
  };

  return Icons[symbol] ?? CircleChartIcon;
};

export const getChartLegendMapping = (
  chartSetting: { legend: object },
  t: (arg0: string) => string
) => {
  const alexandraChartMapping = {
    beforeMeal: {
      id: 'beforeMeal',
      symbol: 'square',
      symbolComponent: getChartIcon('square'),
      color: chartColors.primaryOrange,
      description: t('Before meal'),
    },
    '2HoursAfterMeal': {
      id: '2HoursAfterMeal',
      symbol: 'diamond',
      symbolComponent: getChartIcon('diamond'),
      color: chartColors.danger,
      description: t('2h after meal'),
    },
    beforeBedtime: {
      id: 'beforeBedtime',
      symbol: 'circle',
      symbolComponent: getChartIcon('circle'),
      color: chartColors.primaryBlue,
      description: t('Before Bedtime'),
    },
  };

  let chartLegendMapping = alexandraChartMapping;

  if (chartSetting.legend) {
    const tempLegendMapping = chartSetting.legend;
    Object.keys(chartSetting.legend).forEach((legendKey) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tempLegendMapping[legendKey].symbolComponent = getChartIcon(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tempLegendMapping[legendKey]?.symbol ?? 'circle'
      );
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    chartLegendMapping = tempLegendMapping;
  }
  return chartLegendMapping;
};

export const getChartLegendOptions = (
  chartSetting: any,
  t: (arg0: string) => string
) => {
  const chartLegendMapping = getChartLegendMapping(chartSetting, t);
  return Object.values(chartLegendMapping).map(
    ({
      id,
      symbol,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      symbolComponent: IconComponent,
      description: label,
      color,
    }) => ({
      icon: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <IconComponent
          color={color}
          top={symbol === 'diamond' ? '-4px !important' : null}
          left={symbol === 'diamond' ? '-1px !important' : null}
        />
      ),
      label: t(label),
      id,
    })
  );
};

export const getBaseUrl = () =>
  window.location.href.match(
    /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)(:[0-9]+)?((\/\w)*)([\w\-\.]+)/
  )?.[0];

export const checkPermissions = (clinician: any, permissions: string[]) =>
  !chain(clinician)
    .get('role.permissions')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .intersection(permissions)
    .isEmpty()
    .value();

export const checkIsMyPatient = (
  clinician: { uid: string },
  patientForm: any
) => {
  const checkIntersection = (key: string) => {
    return (
      !isEmpty(chain(patientForm).get(key)) &&
      chain(patientForm)
        .get(key)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .some((item: { uid: string }) => item.uid === clinician?.uid)
    );
  };
  return checkIntersection('ics') || checkIntersection('alertees');
};

/* eslint radix:0 */
/* eslint eqeqeq: 0 */
export const isDate = (sDate: string) => {
  const formats = [moment.ISO_8601];
  return moment(sDate, formats, true).isValid();
};

export const isJson = (item?: string) => {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  return typeof value === 'object' && value !== null;
};

export const localStorageUtil = {
  save: (key: string, value: any) => {
    localStorage.setItem(
      key,
      isObject(value) ? JSON.stringify(value) : value.toString()
    );
  },
  get: (key: string) =>
    isJson(localStorage.getItem(key) as string)
      ? JSON.parse(localStorage.getItem(key) as string)
      : localStorage.getItem(key),
  clearItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clearAll: () => {
    localStorage.clear();
  },
};

export const sessionStorageUtil = {
  save: (key: string, value: any) => {
    sessionStorage.setItem(
      key,
      isObject(value) ? JSON.stringify(value) : value.toString()
    );
  },
  get: (key: string) => {
    return isJson(sessionStorage.getItem(key) as string)
      ? JSON.parse(sessionStorage.getItem(key) as string)
      : sessionStorage.getItem(key);
  },
  clearItem: (key: string) => {
    sessionStorage.removeItem(key);
  },
  clearAll: () => {
    sessionStorage.clear();
  },
};

export const tweakPageName = (str: string) => {
  const pageName = str.replace(/-/g, ' ');
  return pageName.charAt(0).toUpperCase() + pageName.slice(1);
};

/**
 * Using MarkDown lib to render the links
 * <Markdown>{convertUrlsIntoMarkDownString(text)}</Markdown>
 * @param {*} text
 * @returns
 */
export const convertUrlsIntoMarkDownString = (text: string) => {
  let parsedValue = text;
  let urls = parsedValue.match(/(https?:\/\/[^ ]*)/);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  urls = [...new Set(urls?.map((item: string) => item))];
  urls?.forEach((url: string) => {
    const domain = new URL(url);
    parsedValue = parsedValue.replace(url, `[${domain.hostname}](${url})`);
  });
  return parsedValue;
};

/**
 * Pluralize text characters.
 * TODO: Maybe externalize the file as separate util script for string manipulation
 * @param numVal
 * @param pluralTxt
 * @returns {string|*}
 */
export const charPluralize = (numVal: any, pluralTxt: string) =>
  Number(numVal) > 1 ? `${pluralTxt}s` : pluralTxt;

/**
 * Get the ALL sorted (from latest to oldest) comments, get recent comment and if comment is the most recent.
 * @param comments
 * @param dateLastSeen
 * @returns {{all: (function(): *), isRecent: (function(): boolean), getRecent: (function(): *)}}
 */
export const getSortedSubmissionComments = (
  comments: string | any[],
  dateLastSeen: string | number | Date
) => {
  if (!comments || comments?.length <= 0) {
    return {
      getAll: () => comments,
      getRecent: () => undefined,
      isRecent: () => false,
    };
  }

  const sorted = [...comments]?.sort(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (a, b) => new Date(b?.createdOn) - new Date(a?.createdOn)
  );

  const recentComment = sorted[0];
  const isRecentCommentCreatedLatest =
    isAfter(new Date(recentComment?.createdOn), new Date(dateLastSeen)) ||
    isEqual(new Date(recentComment?.createdOn), new Date(dateLastSeen));

  return {
    getAll: () => sorted,
    getRecent: () => recentComment,
    isRecent: () => isRecentCommentCreatedLatest,
  };
};

/**
 * Get and check if the submission has unread/unseen comment from the current logged user.
 * @param comments
 * @param submissionParam
 * @param patientData
 * @param clinicianData
 * @returns {*}
 */
export const getReadSubmissionComment = (
  comments: any[],
  submissionParam: { lastSeens: string | any[]; lastSubmission: any },
  patientData: any,
  clinicianData: { uid: any }
) => {
  let latestSeenDate: Date | undefined;
  let lastSeenByUser;
  const patientUser = patientData;
  const submission =
    submissionParam?.lastSeens && submissionParam?.lastSeens?.length > 0
      ? submissionParam
      : submissionParam?.lastSubmission;
  const userLastSeensList = submission?.lastSeens;

  if (userLastSeensList) {
    const userId = clinicianData ? clinicianData?.uid : patientUser?.uid;
    lastSeenByUser = userLastSeensList?.find(
      (o: { clinician: { uid: string } }) => o.clinician.uid === userId
    );
    const sortedSeenDates: Date[] = userLastSeensList
      ?.map((o: { seenOn: string | number }) => new Date(o.seenOn))
      ?.sort(
        (a: string | number, b: string | number) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new Date(b) - new Date(a)
      );
    // eslint-disable-next-line prefer-destructuring
    latestSeenDate = sortedSeenDates[0];
    if (lastSeenByUser) {
      const userLastSeenDate = new Date(lastSeenByUser.seenOn);
      if (
        !isEqual(userLastSeenDate, latestSeenDate as Date) ||
        isAfter(userLastSeenDate, new Date(latestSeenDate as Date))
      ) {
        lastSeenByUser = userLastSeensList.find((o: { seenOn: string }) =>
          isEqual(new Date(o.seenOn), latestSeenDate as Date)
        );
      }
    } else {
      lastSeenByUser = userLastSeensList.find(
        (o: { seenOn: string | number | Date }) =>
          isEqual(new Date(o.seenOn), latestSeenDate as Date)
      );
    }
  }

  const patientLastSeenOn = submission?.lastSeenByPatientOn;
  const isPatientLastSeenOnEQafter =
    isEqual(latestSeenDate as Date, new Date(patientLastSeenOn)) ||
    isAfter(latestSeenDate as Date, new Date(patientLastSeenOn));
  if (patientLastSeenOn && isPatientLastSeenOnEQafter) {
    lastSeenByUser = null;
  }

  const dateLastSeen = lastSeenByUser ? latestSeenDate : patientLastSeenOn;
  const sortedComments = getSortedSubmissionComments(comments, dateLastSeen);
  const isPublicCommentSeen =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !sortedComments
      .getAll()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ?.filter(
        (f: { visibleTo: string; isSeen: any }) =>
          f.visibleTo === COMMENT_VISIBILITY.public && !f.isSeen
      )?.length >= 1;

  return {
    user: lastSeenByUser || patientData,
    isSeen: Boolean(isPublicCommentSeen),
    dateLastSeen,
    post: sortedComments.getRecent(),
  };
};

/**
 * Call and open customer support email (support@botmd.io).
 * TODO: Might consider putting this to utils.
 * @param clinicName
 * @param userName
 */
export const customerSupportEmail = (clinicName = null, userName = null) => {
  const CUSTOMER_SUPPORT_EMAIL = 'support@botmd.io';
  const url = new URL(`mailto:${CUSTOMER_SUPPORT_EMAIL}`);
  url.searchParams.set(
    'subject',
    encodeURIComponent(
      `${clinicName || 'BotMD Care'} - ${userName || 'Support'}`
    )
  );
  window.open(decodeURI(url.toString()));
};

const markdownRegex = /(?:__|[*#])|\[[^\]]+\]\([\)]+\)/;

export const containsMarkDownLink = (val: string) =>
  markdownRegex.test(DOMPurify.sanitize(val?.substring(0, 3000)));

export const removeNonParsedConfig = (value: any) => {
  const regex = /\${[^}]*\}/gi;
  return (value ?? '').toString().replaceAll(regex, '-');
};

export const MAX_SELECTION_THRESHOLD = Config.IS_PRODUCTION() ? 15 : 200;

export const checkMaxSelectionThreshold = (selectedCount: number) =>
  selectedCount > MAX_SELECTION_THRESHOLD;

export const showMaxSelectionThresholdMessage = () => {
  // eslint-disable-next-line no-alert
  alert(
    `Maximum number of patients that can be selected is ${MAX_SELECTION_THRESHOLD}`
  );
};

export const tableConfigUtils = {
  /* eslint react/no-danger: 0 */
  renderCell: (
    props:
      | (React.JSX.IntrinsicAttributes & {
          period: any;
          cell: any;
          chartSetting: any;
          t: any;
        })
      | (React.JSX.IntrinsicAttributes & { [x: string]: any; children: any })
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { row, column, t, cell, showDischargedLabel, clinician } = props;

    if (!column) return null;

    const removeSpecialChars = (str: string) =>
      str.replace(/[^\w\s.|(|)]/gi, '');
    const { original: rowData } = row;
    const componentType = column?.component || 'Text';
    const componentProps = column?.componentProps ?? {};
    const patientVariables = rowData.variablesAll;

    const isSubmissionParams = (column?.data?.valueTemplate ?? '').includes(
      'submissionParameters'
    );

    // Filter out undefined variables
    const filteredRowDataArray = Object.keys(rowData)
      .filter(
        (x) =>
          !isUndefined(rowData[x]) &&
          (typeof rowData[x] === 'string' ||
            typeof rowData[x] === 'number' ||
            typeof rowData[x] === 'boolean')
      )
      .map((k) => ({
        k,
        [k]: rowData[k].toString(),
      }));

    // Parse result into dictionary
    const filteredRowDataDictionary = Object.assign(
      {},
      ...filteredRowDataArray.filter((x) => x).map((x) => ({ [x.k]: x[x.k] }))
    );

    let parsedValueTemplate = parseMessageJSON(
      {
        node: column.data?.valueTemplate,
      },
      { ...patientVariables, ...filteredRowDataDictionary },
      false
    ).node;

    if (!isUndefined(showDischargedLabel)) {
      const isDeactivated = get(cell, 'row.original.patient.deactivatedOn');
      const isDischarge =
        get(cell, 'row.original.patientForm.isDischarged', false) ||
        get(cell, 'row.original.isDischarged', false);

      if (
        typeof parsedValueTemplate === 'string' &&
        parsedValueTemplate.includes('(showDischarged)')
      ) {
        if (showDischargedLabel && (isDeactivated || isDischarge)) {
          parsedValueTemplate = parsedValueTemplate.replace(
            '(showDischarged)',
            ` (${t('Discharged')})`
          );
        } else {
          parsedValueTemplate = parsedValueTemplate.replace(
            '(showDischarged)',
            ''
          );
        }
      }
    }

    const parsedHrefTemplate = parseMessageJSON(
      {
        node: column.data?.hrefTemplate,
      },
      { ...patientVariables, ...filteredRowDataDictionary }
    ).node;

    if (componentType === 'Comment') {
      let commentsList;
      if (parsedValueTemplate === column.data.valueTemplate) {
        commentsList = get(rowData, removeSpecialChars(parsedValueTemplate));
        parsedValueTemplate = commentsList?.length || 0;
      }

      const renderUnreadIcon = (unreadIconProps: {
        'data-testid': string;
        id: string;
      }) =>
        parsedValueTemplate !== 0 && (
          <UnreadCommentIconIndicator {...unreadIconProps} />
        );

      return (
        <TableCell {...column} {...props}>
          <Flex data-testid={`comment_cell-${row.original.uid}`}>
            <FAIcon
              data-testid={`comment_msg_icon_comment_cell:${row.original.uid}`}
              icon={faComment}
              style={{ fontSize: 18 }}
            />
            <Text mr="5px" position="relative">
              {!getReadSubmissionComment(
                commentsList,
                rowData,
                rowData?.patient,
                clinician
              ).isSeen &&
                renderUnreadIcon({
                  'data-testid': `unread_icon_comment_cell:${row.original.uid}`,
                  id: `unread_icon_comment_cell:${row.original.uid}`,
                })}
            </Text>

            <Text>{parsedValueTemplate}</Text>
          </Flex>
        </TableCell>
      );
    }

    if (componentType === 'BGTableCell') {
      return (
        <BGTableCell
          {...componentProps}
          {...props}
          chartSetting={column?.parent?.chartSetting}
          t={column?.parent?.t}
        />
      );
    }

    if (componentType === 'Alert') {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <BoxContent style={column?.style}>
          <StatusCell
            // @ts-ignore
            onShowSubmission={props?.onShowSubmission}
            cell={{ row }}
            // @ts-ignore
            isSinglePatientView={props.isSinglePatientView}
          />
        </BoxContent>
      );
    }

    if (componentType === 'Link') {
      return (
        // @ts-ignore
        <BoxContent py={2} style={column?.style}>
          <Body small key={patientVariables?.['monitoring_form[uid]']}>
            {parsedHrefTemplate !== column.data.hrefTemplate ? (
              <a
                style={{
                  color: theme.colors.primary,
                }}
                rel="noreferrer"
                href={parsedHrefTemplate}
                target="_blank"
              >
                {parsedValueTemplate}
              </a>
            ) : (
              parsedValueTemplate
            )}
          </Body>
        </BoxContent>
      );
    }

    if (componentType === 'Markdown') {
      // replace invalid url to -
      map(
        parsedValueTemplate?.substring(0, 3000).match(markdownRegex),
        (url) => {
          if (!url?.match(/(https?:\/\/[^ ]*)/) && url !== '*') {
            parsedValueTemplate = parsedValueTemplate.replace(url, '-');
          }
        }
      );

      // Remove all non-parsed strings
      parsedValueTemplate = removeNonParsedConfig(parsedValueTemplate);

      const regex = /\${[^}]*\}/gi;
      parsedValueTemplate = (parsedValueTemplate ?? '')
        .toString()
        .replaceAll(regex, '-');

      return (
        // @ts-ignore
        <BoxContent style={column?.style}>
          <Markdown {...props}>{parsedValueTemplate}</Markdown>
        </BoxContent>
      );
    }

    if (componentType === 'Boolean') {
      parsedValueTemplate = parsedValueTemplate.includes('true')
        ? parsedValueTemplate.replace('true', t('Yes'))
        : parsedValueTemplate.replace('false', t('No'));
    }

    // TODO - Serge: This is a temp fix for care birth date format. Rethink this.
    if (
      column.data?.valueTemplate.includes('dateOfBirth') &&
      parsedValueTemplate !== column.data?.valueTemplate
    ) {
      const temp = parsedValueTemplate.split('<br />');
      if (temp.length > 1) {
        parsedValueTemplate = `${temp?.[0]}<br />${
          temp?.[1].includes('dateOfBirth')
            ? '-'
            : moment(temp?.[1]).format('YYYY-MM-DD')
        }`;
      }
    }

    if (componentType === 'MonitoringFormsArray') {
      return (
        // @ts-ignore
        <BoxContent py={2} style={column?.style}>
          {isArray(parsedValueTemplate) && parsedValueTemplate.length > 0
            ? parsedValueTemplate.map((mf, i) => {
                const urlConstruct = parseMessageJSON(
                  {
                    node: column.data?.hrefTemplate,
                  },
                  { ...patientVariables, ...mf, ...filteredRowDataDictionary },
                  false
                ).node;

                return (
                  <Body small key={mf.uid}>
                    <a
                      style={{
                        color: theme.colors.primary,
                      }}
                      rel="noreferrer"
                      href={urlConstruct}
                      target="_blank"
                    >
                      {mf.effectiveName}
                    </a>
                    {i < parsedValueTemplate.length - 1 ? ', ' : ''}
                  </Body>
                );
              })
            : '-'}
        </BoxContent>
      );
    }

    if (parsedValueTemplate === column.data.valueTemplate) {
      parsedValueTemplate = get(
        rowData,
        removeSpecialChars(parsedValueTemplate),
        '-'
      );

      if (
        typeof parsedValueTemplate === 'string' &&
        // @ts-ignore
        parsedValueTemplate.match(/(https?:\/\/[^ ]*)/)?.length > 0
      ) {
        parsedValueTemplate = !containsMarkDownLink(parsedValueTemplate)
          ? convertUrlsIntoMarkDownString(parsedValueTemplate)
          : parsedValueTemplate;
      }
    }

    if (isSubmissionParams) {
      return SubmissionCell({ parsedValueTemplate, ...props });
    }

    if (
      parsedValueTemplate &&
      isDate(parsedValueTemplate) &&
      parsedValueTemplate.toString().length >= 6
    ) {
      try {
        parsedValueTemplate = moment(parsedValueTemplate.toString()).format(
          'DD MMM YYYY HH:mm'
        );
      } catch (e: any) {
        if (e.message === 'Invalid time value') {
          parsedValueTemplate = format(
            parsedValueTemplate,
            'dd MMM yyyy HH:mm'
          );
        }
        noop();
      }
    }

    // Remove all non-parsed strings
    parsedValueTemplate = removeNonParsedConfig(parsedValueTemplate);

    // @ts-ignore
    return props?.onShowSubmission ? (
      // @ts-ignore
      <TableCell style={column?.style} {...props}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parsedValueTemplate),
          }}
        />
      </TableCell>
    ) : (
      // @ts-ignore
      <BoxContent py={2} style={column?.style}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parsedValueTemplate),
          }}
        />
      </BoxContent>
    );
  },

  mapRenderColumnHeader: (
    t: (arg0: string) => any,
    header: {
      component: any;
      componentProps: any;
    }
  ) => {
    if (typeof header === 'string') {
      return t(header);
    }
    switch (header.component) {
      case 'TableSectionHeader':
        // @ts-ignore
        return <TableSectionHeader {...header?.componentProps} />;
      case 'TableSubSectionHeader':
        // @ts-ignore
        return <TableSubSectionHeader {...header?.componentProps} />;
      default:
        return <div>Compoment not found</div>;
    }
  },
  mapCellRenderToConfig: (
    tableConfigSettings: { columns: any[] },
    clinic: any,
    t: any
  ) => {
    if (!clinic || !tableConfigSettings?.columns) return [];

    const tableConfig = tableConfigSettings?.columns?.map(
      (config: {
        header: any;
        subcolumns: any;
        disableSortBy: any;
        accessor: any;
      }) => {
        const columnConfig = {
          ...config,
          Header: tableConfigUtils.mapRenderColumnHeader(t, config.header),
          Cell: tableConfigUtils.renderCell,
        };

        // delete config property not needed anymore
        delete columnConfig.header;

        // Create sub columns if any
        if (config.subcolumns) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columnConfig.columns = [
            ...tableConfigUtils.mapCellRenderToConfig(
              {
                columns: config.subcolumns,
              },
              clinic,
              t
            ),
          ];
          delete columnConfig.subcolumns;
        }

        if (
          (isUndefined(config.disableSortBy) || !config.disableSortBy) &&
          !config.accessor
        ) {
          columnConfig.accessor = 'patientForms';
        }

        return columnConfig;
      }
    );

    return tableConfig;
  },
};

export const domainConfigsUtils = {
  addDomainConfigs: (config: { DOMAIN_CONFIG_STORAGE_KEY: string }) => {
    sessionStorage.setItem(
      Config.DOMAIN_CONFIG_STORAGE_KEY,
      JSON.stringify(config)
    );
  },
  getDomainConfigs: () =>
    JSON.parse(
      sessionStorage.getItem(Config.DOMAIN_CONFIG_STORAGE_KEY) as string
    ),
};

export const getPermanentSession = async (client: {
  request: (arg0: string, arg1: object) => any;
}) => {
  try {
    const QUERY = `
    query cleoPermanentSession {
      cleoPermanentSession {
        session {
          uid
          scopes
        }
      }
    }
  `;
    const response = await client.request(QUERY, {});
    return response;
  } catch (error) {
    return null;
  }
};

export const separateIntosubList = (arr: string | any[], size: number) => {
  const newArr = [];
  for (let i = 0; i < arr.length; i += size) {
    const sliceIt = arr.slice(i, i + size);
    newArr.push(sliceIt);
  }
  return newArr;
};

export const getAllPatients = async (
  client: {
    request: (
      arg0: string,
      arg1: { deactivatedOn_Isnull: boolean; first: number }
    ) => any;
  },
  deactivatedOnIsNull = true,
  first = 15
) => {
  try {
    const QUERY = `
      query cleoPatients(
        $deactivatedOn_Isnull: Boolean
        $first: Int
      ) {
        cleoPatients(
          deactivatedOn_Isnull: $deactivatedOn_Isnull
          first: $first
        ) {
          totalCount
          edges {
            node {
              phone
              identifier
              deactivatedOn
              uid
              name
              id
            }
          }
        }
      }
    `;
    const response = await client.request(QUERY, {
      deactivatedOn_Isnull: deactivatedOnIsNull,
      first,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const getLoginProvider = () => {
  if (localStorageUtil.get(LOGIN_TYPE_SESSION_KEY) === LOGIN_TYPES.EMAIL) {
    return LOGIN_PROVIDER_UID_EMAIL;
  }
  return LOGIN_PROVIDER_UID;
};

export const replaceAll = (
  str: string,
  fi: string | undefined,
  replace: string
) => str.replace(new RegExp(escapeRegExp(fi), 'g'), replace);

export const getStatusColor = (value: string, t: (arg0: string) => string) => {
  switch (value) {
    case 'scheduled':
    case 'rescheduled':
      return {
        color: '#27AE60',
        bgColor: '#EBFAF1',
        icon: <QuestionMarkSquare color="#DD8700" width={24} height={24} />,
        label: t('Not confirmed'),
        variant: 'warning',
      };

    case 'confirmed':
      return {
        color: '#0154B6',
        bgColor: '#EAF4FF',
        icon: <Check color="#0154B6" width={25} height={25} />,
        label: t('Confirmed'),
        variant: 'primary',
      };

    case 'completed':
    case 'patient_showed':
      return {
        color: '#27AE60',
        bgColor: '#EBFAF1',
        icon:
          value !== 'patient_showed' ? (
            <Check color="#27AE60" width={25} height={25} />
          ) : (
            <Person color="#27AE60" width={25} height={25} />
          ),
        label: t('Show'),
        variant: 'success',
      };

    case 'patient_not_showed':
    case 'cancelled':
    case 'no_show':
      return {
        color: '#E05138',
        bgColor: '#FBEBE9',
        icon: <NoShow color="#E05138" width={24} height={24} />,
        label: t('No show'),
        variant: 'danger',
      };

    case 'in_progress':
    case 'past':
      return {
        color: '#0154B6',
        bgColor: '#EAF4FF',
        icon: <QuestionMarkSquare color="#DD8700" width={24} height={24} />,
        label: t('Not confirmed'),
        variant: 'warning',
      };

    case 'not_confirmed':
      return {
        color: '#DD8700',
        bgColor: '#FEF7ED',
        icon: <QuestionMarkSquare color="#DD8700" width={24} height={24} />,
        label: t('Not confirmed'),
        variant: 'warning',
      };

    default:
      return {
        color: '#0154B6',
        bgColor: '#FFFFFF',
        icon: <QuestionMarkSquare color="#DD8700" width={24} height={24} />,
        label: t('Not confirmed'),
        variant: 'primary',
      };
  }
};

export const getAppointmentStatus = (
  event: Appointment,
  eventConfirmedOn?: Moment,
  eventPatientShowOn?: Moment
) => {
  let status = '';
  // #reference: https://i.imgur.com/aAPVlIe.png
  if (toLower(event.status) === 'cancelled') {
    status = 'no_show';
  } else if (
    //#1
    event.isConfirmed &&
    eventConfirmedOn &&
    event.isPatientShowed &&
    !eventPatientShowOn
  ) {
    status = 'confirmed';
  } else if (
    //#2
    event.isConfirmed &&
    eventConfirmedOn &&
    !event.isPatientShowed &&
    !eventPatientShowOn
  ) {
    status = 'confirmed';
  } else if (
    //#3
    event.isConfirmed &&
    eventConfirmedOn &&
    event.isPatientShowed &&
    eventPatientShowOn
  ) {
    if (eventConfirmedOn.isAfter(eventPatientShowOn)) {
      status = 'confirmed';
    } else {
      status = 'patient_showed';
    }
  } else if (
    //#4
    !event.isConfirmed &&
    eventConfirmedOn &&
    event.isPatientShowed &&
    eventPatientShowOn
  ) {
    if (eventConfirmedOn.isAfter(eventPatientShowOn)) {
      status = 'not_confirmed';
    } else {
      status = 'patient_showed';
    }
  } else if (
    //#5
    event.isConfirmed &&
    eventConfirmedOn &&
    !event.isPatientShowed &&
    eventPatientShowOn
  ) {
    if (eventConfirmedOn.isAfter(eventPatientShowOn)) {
      status = 'confirmed';
    } else {
      status = 'no_show';
    }
  } else if (
    //#6
    !event.isConfirmed &&
    eventConfirmedOn &&
    !event.isPatientShowed &&
    eventPatientShowOn
  ) {
    if (eventConfirmedOn.isAfter(eventPatientShowOn)) {
      status = 'not_confirmed';
    } else {
      status = 'no_show';
    }
  } else if (
    //#7
    event.isConfirmed &&
    !eventConfirmedOn &&
    !event.isPatientShowed &&
    eventPatientShowOn
  ) {
    status = 'no_show';
  } else if (
    //#8
    !event.isConfirmed &&
    !eventConfirmedOn &&
    !event.isPatientShowed &&
    eventPatientShowOn
  ) {
    status = 'no_show';
  } else if (
    //#9
    event.isConfirmed &&
    !eventConfirmedOn &&
    event.isPatientShowed &&
    eventPatientShowOn
  ) {
    status = 'patient_showed';
  } else if (
    //#10
    !event.isConfirmed &&
    !eventConfirmedOn &&
    event.isPatientShowed &&
    eventPatientShowOn
  ) {
    status = 'patient_showed';
  } else if (
    //#11
    !event.isConfirmed &&
    eventConfirmedOn &&
    !event.isPatientShowed &&
    !eventPatientShowOn
  ) {
    status = 'not_confirmed';
  }

  return status;
};

export const getMetadataFiledType = (metadata: {
  uid?: string;
  parameter: any;
  value?: { file: { url: string } };
  extractedForDisplay: any;
}) => {
  switch (metadata?.parameter?.valueType) {
    case 'DATETIME':
      return hasTimeInString(metadata?.extractedForDisplay)
        ? 'datetime'
        : 'date';
    case 'TIME':
      return 'time';
    case 'FILE':
      return 'file';
    default:
      return 'input';
  }
};

export const numberFormatter = (value: number): string | undefined => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default {
  hasTimeInString,
  getFirstAndLastName,
  getClinicalDesignationOptions,
  getMessageDeliveriesExport,
  getPatientSubmissions,
  getSubmissionsExport,
  getPatientFormsExport,
  isExportBtnDisable,
  validateEmail,
  getBaseUrl,
  checkPermissions,
  getPermanentSession,
};
