import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
  ActionMenu,
  SecondaryOutlinedButton,
  ActionMenuItem,
  ActionMenuText,
  FAIcon,
  Flex,
} from '@fivehealth/botero';

const DefaultActionMenuButton = ({
  t,
  disabled,
  isMobile,
  buttonProps,
  buttonLabel,
  buttonLabelIcon,
  buttonLabelIconEnd,
}) => {
  const renderDefButtonIconLabel = (icon, styleOverride) =>
    icon && (
      <FAIcon icon={buttonLabelIcon} fontSize={16} style={styleOverride} />
    );

  return (
    <SecondaryOutlinedButton
      borderColor="rgb(213, 217, 222)"
      borderRadius={8}
      pt={2}
      pb={2}
      mr={[0, 3]}
      height={42}
      {...buttonProps}
      disabled={disabled}
      data-testid="settings_action_menu_btn"
    >
      <Flex color="fullShade" fontSize="14px" fontWeight={500} pt={['5px', 0]}>
        {isMobile ? (
          buttonLabelIcon && <FAIcon icon={buttonLabelIcon} fontSize={24} />
        ) : (
          <>
            {renderDefButtonIconLabel(buttonLabelIcon, {
              marginRight: buttonLabel ? 8 : 0,
              marginTop: 2,
            })}
            {t(buttonLabel)}
            {renderDefButtonIconLabel(buttonLabelIconEnd, {
              marginLeft: 8,
              marginTop: 2,
            })}
          </>
        )}
      </Flex>
    </SecondaryOutlinedButton>
  );
};

const SettingsActionMenu = ({
  options,
  isMobile = true,
  disabled = false,
  buttonProps,
  renderButtonLabel = null,
  buttonLabel,
  buttonLabelIcon = null,
  buttonLabelIconEnd = null,
  actionMenuProps,
  actionMenuItemProps,
  actionMenuTextProps,
}) => {
  const [optionList, setOptionList] = useState([]);
  const dropdownRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (options) {
      setOptionList([...options]);
    }
  }, [options]);

  const onSetDropDownRef = (ref) => {
    dropdownRef.current = ref;
  };

  const handleClickItem = (item) => {
    item.action();
    if (dropdownRef) dropdownRef?.current?.setOpen(false);
  };

  return (
    <ActionMenu
      dropdownRef={onSetDropDownRef}
      label={
        renderButtonLabel ? (
          renderButtonLabel()
        ) : (
          <DefaultActionMenuButton
            t={t}
            disabled={disabled}
            isMobile={isMobile}
            buttonProps={buttonProps}
            buttonLabel={buttonLabel}
            buttonLabelIcon={buttonLabelIcon}
            buttonLabelIconEnd={buttonLabelIconEnd}
          />
        )
      }
      {...actionMenuProps}
    >
      {map(optionList, (item, index) => (
        <React.Fragment key={`settings_action_menu_item_key-${index}`}>
          {!item?.hidden && (
            <ActionMenuItem
              data-testid={`settings_action_menu_key:item-${index}`}
              onClick={() => {
                if (!item.disabled) handleClickItem(item);
              }}
              divider={item?.divider}
              {...actionMenuItemProps}
            >
              <ActionMenuText
                {...actionMenuTextProps}
                color={item.disabled ? 'darkShade' : 'fullShade'}
                fontSize={14}
              >
                {item?.icon && (
                  <div style={{ marginRight: 10 }}>{item?.icon}</div>
                )}
                <div>{item?.label}</div>
              </ActionMenuText>
            </ActionMenuItem>
          )}
        </React.Fragment>
      ))}
    </ActionMenu>
  );
};

export default SettingsActionMenu;
