import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { buildPaginatedResponse } from 'lib/pagination';
import { get } from 'lodash';

export const cleoAlertReminderFragment = `
   uid
   name
   isForClinic
   settings
`;

const GRAPHQL_DOCUMENT = gql`
  query cleoReminders($first: Int, $offset: Int, $isForClinic: Boolean) {
    cleoReminders(first: $first, offset: $offset, isForClinic: $isForClinic) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${cleoAlertReminderFragment}
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'alertReminders',
  select: (data) => buildPaginatedResponse(data, 'cleoReminders'),
  paginate: 'offset',
  getNextPageParam: ({ cleoReminders: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
    if (!hasNextPage) {
      return undefined;
    }
    const currentOffset = allPages.flatMap(({ cleoReminders }) =>
      get(cleoReminders, 'edges', [])
    ).length;
    return currentOffset;
  },
  keepPreviousData: true,
});
