// import liraries
import React, { memo } from 'react';
import { Icon } from '@chakra-ui/react';
import { IconProps } from './IconProps';

const LineMessenger = memo<IconProps>(
  ({ color, width = 15, height = 15, viewBox }) => (
    <Icon>
      <svg
        width={width}
        height={height}
        viewBox={viewBox || '0 0 15 15'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 5.40625V7.625C9.5 7.6875 9.4375 7.71875 9.375 7.71875H9.03125C9 7.71875 8.96875 7.6875 8.9375 7.6875L7.9375 6.3125V7.625C7.9375 7.6875 7.875 7.71875 7.84375 7.71875H7.46875C7.40625 7.71875 7.375 7.6875 7.375 7.625V5.40625C7.375 5.34375 7.40625 5.3125 7.46875 5.3125H7.84375C7.875 5.3125 7.90625 5.3125 7.90625 5.34375L8.9375 6.71875V5.40625C8.9375 5.34375 8.96875 5.3125 9.03125 5.3125H9.375C9.4375 5.28125 9.5 5.34375 9.5 5.40625ZM6.9375 5.28125C6.96875 5.28125 7.03125 5.34375 7.03125 5.40625V7.625C7.03125 7.6875 6.96875 7.71875 6.9375 7.71875H6.5625C6.5 7.71875 6.46875 7.6875 6.46875 7.625V5.40625C6.46875 5.34375 6.5 5.28125 6.5625 5.28125H6.9375ZM6.0625 7.15625C6.125 7.15625 6.15625 7.21875 6.15625 7.25V7.625C6.15625 7.65625 6.125 7.71875 6.0625 7.71875H4.625C4.59375 7.71875 4.59375 7.6875 4.5625 7.6875C4.5625 7.65625 4.53125 7.65625 4.53125 7.625V5.40625C4.53125 5.34375 4.59375 5.28125 4.625 5.28125H5C5.0625 5.28125 5.09375 5.34375 5.09375 5.40625V7.15625H6.0625ZM11.375 5.28125C11.4062 5.28125 11.4688 5.34375 11.4688 5.40625V5.75C11.4688 5.8125 11.4062 5.84375 11.375 5.84375H10.4062V6.21875H11.375C11.4062 6.21875 11.4688 6.28125 11.4688 6.3125V6.6875C11.4688 6.75 11.4062 6.78125 11.375 6.78125H10.4062V7.15625H11.375C11.4062 7.15625 11.4688 7.21875 11.4688 7.25V7.625C11.4688 7.6875 11.4062 7.71875 11.375 7.71875H9.9375C9.875 7.71875 9.84375 7.65625 9.84375 7.625V5.40625C9.84375 5.34375 9.875 5.28125 9.9375 5.28125H11.375ZM15 2.5625V11.4688C14.9688 12.875 13.8438 14.0312 12.4375 14H3.53125C2.125 14 0.96875 12.875 1 11.4688V2.53125C1 1.15625 2.125 0 3.53125 0H12.4688C13.8438 0.03125 15 1.15625 15 2.5625ZM13.0625 6.40625C13.0625 4.125 10.7812 2.25 7.96875 2.25C5.15625 2.25 2.875 4.125 2.875 6.40625C2.875 8.4375 4.6875 10.1562 7.125 10.4688C7.71875 10.5938 7.65625 10.8125 7.53125 11.625C7.5 11.75 7.40625 12.125 7.96875 11.9062C8.5 11.6875 10.9062 10.1875 11.9688 8.9375C12.7188 8.125 13.0625 7.3125 13.0625 6.40625Z"
          fill={color}
        />
      </svg>
    </Icon>
  )
);

export default LineMessenger;
