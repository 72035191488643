import React, { useState, useEffect, useCallback, useRef } from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  isMatch,
  parseISO,
  format,
  isAfter,
  isValid,
  setHours,
  setMinutes,
  addMinutes,
  startOfMonth,
  startOfYear,
  startOfToday,
} from 'date-fns';

import {
  chain,
  differenceBy,
  find,
  filter,
  first,
  flatMap,
  get,
  keys,
  map,
  includes,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
  noop,
  values,
  last,
} from 'lodash';

import {
  Box,
  CreatableSelect,
  DateInputField,
  Flex,
  Select,
  Text,
  useApi,
  WeekSelector,
  TextLink,
  H5,
} from '@fivehealth/botero';

import useAlertRemindersData from 'hooks/useAlertRemindersData';
import { InfoText } from 'components/InfoText/InfoText';
import {
  getSubmissionCompliance,
  InputTitle,
  InputWithSelect,
  isRecurring,
  RadioBox,
  defaultDays,
  daysInMonth,
  ReminderTypeOptions,
  PreComplianceUnitOptions,
  PostComplianceUnitOptions,
  TriggerEventUnitOptions,
  getCustomizeReminderOptions,
  ReminderTriggerEventOptions,
  ReminderFrequencyUnitOptions,
} from './utils';
import { FollowUpReminder } from './FollowUpReminder';
import { HourMinsSelector } from './HourMinsSelector';

export const getDefaultReminderFrequencyTimes = (reminder) => {
  const defaultTime = { label: '09:00', value: '09:00' };
  const recurringFreq = get(
    reminder,
    'settings.schedule.recurring_frequencies'
  );

  if (!isUndefined(recurringFreq) && !isEmpty(recurringFreq)) {
    return chain(recurringFreq)
      .map((freq) => freq.time_of_day)
      .uniq()
      .map((time) => (time ? { label: time, value: time } : defaultTime))
      .value();
  }

  return [];
};

export const getDefaultReminderFrequencyInterval = (reminder) => {
  const defaultInterval = 1;
  const recurringFreq = get(
    reminder,
    'settings.schedule.recurring_frequencies'
  );

  if (!isUndefined(recurringFreq) && !isEmpty(recurringFreq)) {
    return first(recurringFreq).every || defaultInterval;
  }

  return defaultInterval;
};

export const getDefaultReminerFrequencyDays = (reminder, type) => {
  const recurringFreq = get(
    reminder,
    'settings.schedule.recurring_frequencies'
  );

  if (
    !isUndefined(recurringFreq) &&
    !isEmpty(recurringFreq) &&
    !isEqual(first(recurringFreq)?.unit, 'day')
  ) {
    if (isEqual(type, 'week')) {
      return filter(
        defaultDays,
        (day) =>
          !chain(recurringFreq)
            .find({ day_of_week: day.id })
            .isUndefined()
            .value()
      );
    }
    if (isEqual(type, 'month')) {
      return filter(
        daysInMonth,
        (day) =>
          !chain(recurringFreq)
            .find((freqDay) =>
              isEqual(parseInt(freqDay.day_of_month, 10), day.value)
            )
            .isUndefined()
            .value()
      );
    }
  }

  if (isEqual(type, 'week')) {
    return [first(defaultDays)];
  }

  if (isEqual(type, 'month')) {
    return [first(daysInMonth)];
  }

  return [];
};

export const getDefaultReminerFrequencyUnit = (reminder) => {
  const recurringFreq = get(
    reminder,
    'settings.schedule.recurring_frequencies'
  );

  if (!isUndefined(recurringFreq) && !isEmpty(recurringFreq)) {
    return find(ReminderFrequencyUnitOptions, {
      value: first(recurringFreq).unit,
    });
  }

  return first(ReminderFrequencyUnitOptions);
};

export const getDefaultTrigger = (reminder) => {
  const trigger = get(reminder, 'settings.schedule.start_trigger');
  switch (trigger?.event) {
    case 'enrollment':
      if (trigger.duration < 2 && isEqual(trigger.unit, 'minute')) {
        return find(ReminderTriggerEventOptions, {
          value: 'immediateEnrollment',
        });
      }
      return find(ReminderTriggerEventOptions, { value: 'enrollment' });

    case 'discharge':
      if (trigger.duration < 2 && isEqual(trigger.unit, 'minute')) {
        return find(ReminderTriggerEventOptions, {
          value: 'immediateDischarge',
        });
      }
      return find(ReminderTriggerEventOptions, { value: 'discharge' });

    case 'exact':
      return find(ReminderTriggerEventOptions, { value: 'datetime' });

    default:
      return find(ReminderTriggerEventOptions, {
        value: 'immediateEnrollment',
      });
  }
};

export const getDefaultReminderOption = (reminder, clinicReminders, t) => {
  if (reminder.isNewReminder && !reminder.isEdited) {
    return {
      label: t('Select reminder'),
      value: '',
    };
  }

  const clinicReminder = find(clinicReminders, {
    uid: reminder?.uid,
  });

  if (!isUndefined(clinicReminder) && !reminder.toCreate) {
    return clinicReminder;
  }

  return find(getCustomizeReminderOptions(clinicReminders, t), {
    key: 'custom',
  })?.options[0];
};

export const getDefaultMessageTemplate = (reminder, messageTemplates) => {
  const action = get(reminder, 'settings.schedule.action');
  if (action && isEqual(action?.type, 'send_template')) {
    return find(messageTemplates, { uid: action?.send_template?.template_set });
  }
  return null;
};

export const getDefaultReminderDate = (reminder) => {
  const trigger = get(reminder, 'settings.schedule.start_trigger');

  if (isEqual(trigger?.event, 'exact')) {
    return parseISO(trigger?.exact_trigger_on);
  }

  return null;
};

export const getDefaultReminderTime = (reminder) => {
  const trigger = get(reminder, 'settings.schedule.start_trigger');

  if (
    trigger?.event === 'exact' ||
    trigger?.event === 'discharge' ||
    trigger?.event === 'enrollment'
  ) {
    const time =
      trigger.event === 'exact'
        ? format(parseISO(trigger?.exact_trigger_on), 'HH:mm')
        : trigger?.time_of_day;
    return [{ label: time, value: time }];
  }
  return null;
};

export const getDefaultFollowUpReminder = (reminder) =>
  chain(reminder?.settings)
    .get('follow_up_intervals', [])
    .map((interval) => {
      return interval;
    })
    .value();

export const convertReminderToFormState = (
  reminder,
  clinicReminders,
  messageTemplates,
  t,
  additionalState = {}
) => {
  const preSubmissionCompliance = getSubmissionCompliance(
    get(reminder, 'settings.recent_submission.before')
  );

  const postSubmissionCompliance = getSubmissionCompliance(
    get(reminder, 'settings.recent_submission.after')
  );

  return {
    uid: reminder.uid,
    edit: reminder.isEdited || false,
    selectReminder: getDefaultReminderOption(reminder, clinicReminders, t),
    messageTemplate: getDefaultMessageTemplate(reminder, messageTemplates),
    reminderType: isRecurring(reminder)
      ? find(ReminderTypeOptions, { key: 'recurring' })
      : find(ReminderTypeOptions, { key: 'oneTime' }),
    reminderFrequencyUnit: getDefaultReminerFrequencyUnit(reminder),
    reminderFrequencyDaysOfWeek: getDefaultReminerFrequencyDays(
      reminder,
      'week'
    ),
    reminderFrequencyDaysOfMonth: getDefaultReminerFrequencyDays(
      reminder,
      'month'
    ),
    reminderFrequencyTimes: getDefaultReminderFrequencyTimes(reminder),
    reminderFrequencyInterval: getDefaultReminderFrequencyInterval(reminder),
    sendReminderDate: getDefaultReminderDate(reminder),
    sendReminderTime: getDefaultReminderTime(reminder),
    followUpReminder: getDefaultFollowUpReminder(reminder),
    preSubmissionComplianceDuration: first(values(preSubmissionCompliance)),
    preSubmissionComplianceUnit: find(PreComplianceUnitOptions, {
      value: first(keys(preSubmissionCompliance)),
    }),
    postSubmissionComplianceDuration: first(values(postSubmissionCompliance)),
    postSubmissionComplianceUnit: find(PostComplianceUnitOptions, {
      value: first(keys(postSubmissionCompliance)),
    }),
    triggerEvent: !isRecurring(reminder)
      ? getDefaultTrigger(reminder)
      : find(ReminderTriggerEventOptions, {
          value: 'immediateEnrollment',
        }),
    reminderOneTimeDuration: !isRecurring(reminder)
      ? get(reminder.settings, 'schedule.start_trigger.duration', '1')
      : '1',
    reminderOneTimeUnit: !isRecurring(reminder)
      ? find(TriggerEventUnitOptions, {
          value: get(
            reminder.settings,
            'schedule.start_trigger.unit',
            'minute'
          ),
        })
      : find(TriggerEventUnitOptions, {
          value: 'minute',
        }),
    ...additionalState,
  };
};

const ReminderFrequency = ({
  t,
  isMobile,
  reminder,
  formState,
  patientForm,
  setFormState,
  openInvalidDateDialog,
  openInvalidMultipleDateDialog,
}) => {
  const [showAdvance, setShowAdvance] = useState(false);
  const [sendReminderTimeInput, setSendReminderTimeInput] = useState('');
  const [reminderTimeInputValue, setReminderTimeInputValue] = useState('');
  const [followUpReminderTimeInput, setFollowUpReminderTimeInput] =
    useState('');

  const [followUpRemindersCount, setFollowUpRemindersCount] = useState(0);

  const singleReminderHourRef = useRef(null);

  const {
    queries: { usePatientMessageTemplates },
  } = useApi({
    queries: ['usePatientMessageTemplates'],
  });

  const { data: messageTemplateData, isLoading: isLoadingMessageTemplates } =
    usePatientMessageTemplates({
      variables: {
        communicationMethods: patientForm.patient.communicationMethod,
      },
    });

  const messageTemplates = get(messageTemplateData, 'pages', [])
    .flatMap((page) => page)
    .filter(
      (item) =>
        flatMap(flatMap(item?.templates).map(({ messages }) => messages))
          .length > 0
    )
    .map((template) => ({
      ...template,
      label: template.name,
      value: template.uid,
    }));

  const {
    reminderData,
    isLoadingReminderOptions,
    alertReminderFetchNextPage,
    clinicRemindersSelectOptions: clinicReminders,
  } = useAlertRemindersData();

  const customizeReminderOptions = [
    {
      label: 'Global defaults',
      options: differenceBy(
        clinicReminders,
        patientForm?.effectiveReminders,
        ({ uid }) => uid
      ),
    },
    {
      label: 'Customize',
      options: [
        {
          label: t('Customize for this individual'),
          value: 'customize',
          isForClinic: false,
        },
      ],
    },
  ];

  const shouldShowSendReminder = () => {
    const event = get(formState.frequency, 'triggerEvent.value');

    if (isUndefined(event)) {
      return false;
    }

    return chain([event])
      .intersection(['immediateEnrollment', 'immediateDischarge', 'datetime'])
      .isEmpty()
      .value();
  };

  const shouldShowDateTimeField = () => {
    const event = get(formState.frequency, 'triggerEvent.value');

    return isEqual(event, 'datetime');
  };

  const updateFormState = useCallback(
    (key, option) =>
      setFormState({
        ...formState,
        frequency: {
          ...formState.frequency,
          edit: true,
          [key]: option,
        },
      }),
    [formState, setFormState]
  );

  const onFollowUpReminderChange = useCallback(
    (index, option) => {
      const followUpReminders = get(
        formState.frequency,
        'followUpReminder',
        []
      );

      followUpReminders[index - 1] = option;
      updateFormState('followUpReminder', followUpReminders);
    },
    [formState.frequency, updateFormState]
  );

  const checkValidDateOrTime = useCallback(
    (date, time) => {
      const reminderTime =
        time || get(formState?.frequency, 'sendReminderTime');

      let reminderDate = date || get(formState?.frequency, 'sendReminderDate');

      if (!isEmpty(reminderTime) && isValid(reminderDate)) {
        // Meaning there is no reminder date previously set
        if (isNull(get(formState?.frequency, 'sendReminderDate'))) {
          return true;
        }

        const parsedTime = chain(reminderTime)
          .map(({ value }) => value)
          .first()
          .split(':')
          .value();

        reminderDate = setHours(reminderDate, first(parsedTime));
        reminderDate = setMinutes(reminderDate, last(parsedTime));

        return isAfter(reminderDate, addMinutes(new Date(), 1));
      }

      if (date) {
        return isAfter(date, startOfToday());
      }

      if (isValid(reminderDate)) {
        return isAfter(reminderDate, addMinutes(new Date(), 1));
      }

      return false;
    },
    [formState?.frequency]
  );

  const onChangeCreatableSelect = useCallback(
    (times, key, validate = false, multiple = false) => {
      const validTimes = times.filter((time) => isMatch(time.value, 'HH:mm'));
      if (
        isEqual(formState?.frequency?.reminderType?.key, 'recurring') ||
        shouldShowSendReminder()
      ) {
        return updateFormState(key, validTimes);
      }

      // multiple creatable is not allowed
      if (validTimes.length > 1 && !multiple) {
        return openInvalidMultipleDateDialog();
      }

      // expired datetime
      if (validate && !checkValidDateOrTime(null, validTimes)) {
        return openInvalidDateDialog();
      }

      return updateFormState(key, validTimes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkValidDateOrTime,
      formState?.frequency?.reminderType?.key,
      openInvalidDateDialog,
      openInvalidMultipleDateDialog,
      updateFormState,
    ]
  );

  const onChangeReminderDate = (newDate) => {
    if (checkValidDateOrTime(newDate)) {
      updateFormState('sendReminderDate', newDate);
    } else {
      openInvalidDateDialog();
    }
  };

  const handleKeyDown = useCallback(
    (event, key) => {
      let inputValue = '';
      if (isEqual(key, 'reminderFrequencyTimes')) {
        if (!reminderTimeInputValue) return;
        inputValue = reminderTimeInputValue;
      }

      if (isEqual(key, 'sendReminderTime')) {
        if (!sendReminderTimeInput) return;
        inputValue = sendReminderTimeInput;
      }

      if (isEqual(key, 'followUpReminder')) {
        if (!followUpReminderTimeInput) return;
        inputValue = followUpReminderTimeInput;
      }

      switch (event.key) {
        case 'Enter':
        case 'Tab':
          {
            const newReminderFrequencyTimes = [
              ...(get(formState.frequency, key, []) || []),
              {
                label: inputValue,
                value: inputValue,
              },
            ];

            if (isEqual(key, 'reminderFrequencyTimes')) {
              setReminderTimeInputValue('');
              onChangeCreatableSelect(
                newReminderFrequencyTimes,
                key,
                false,
                true
              );
            }

            if (isEqual(key, 'followUpReminder')) {
              setFollowUpReminderTimeInput('');
              onChangeCreatableSelect(
                newReminderFrequencyTimes,
                key,
                false,
                true
              );
            }
            if (event.preventDefault) {
              event.preventDefault();
            }
          }
          break;
        default:
          noop();
      }
    },
    [
      followUpReminderTimeInput,
      formState?.frequency,
      onChangeCreatableSelect,
      reminderTimeInputValue,
      sendReminderTimeInput,
    ]
  );

  const shouldShowUnitComponent = (units) =>
    includes(units, get(formState?.frequency, 'reminderFrequencyUnit.value'));

  useEffect(() => {
    if (
      (isUndefined(get(formState, 'frequency')) ||
        !isEqual(get(formState, 'frequency.uid'), reminder?.uid)) &&
      !isLoadingReminderOptions &&
      !isLoadingMessageTemplates
    ) {
      setFormState({
        ...formState,
        frequency: convertReminderToFormState(
          reminder,
          clinicReminders,
          messageTemplates,
          t
        ),
      });
    }
  }, [
    clinicReminders,
    formState,
    isLoadingMessageTemplates,
    isLoadingReminderOptions,
    messageTemplates,
    reminder,
    setFormState,
    t,
  ]);

  useEffect(() => {
    if (
      isNull(get(reminderData, 'pageInfo.hasNextPage')) ||
      get(reminderData, 'pageInfo.hasNextPage')
    ) {
      alertReminderFetchNextPage();
    }
  }, [alertReminderFetchNextPage, reminderData]);

  useEffect(() => {
    if (reminderTimeInputValue.length === 5) {
      handleKeyDown({ key: 'Tab' }, 'reminderFrequencyTimes');
    }
    if (sendReminderTimeInput?.length === 5) {
      handleKeyDown({ key: 'Tab' }, 'sendReminderTime');
    }
    if (followUpReminderTimeInput?.length === 5) {
      handleKeyDown({ key: 'Tab' }, 'followUpReminder');
    }
  }, [
    handleKeyDown,
    followUpReminderTimeInput,
    reminderTimeInputValue,
    sendReminderTimeInput,
  ]);

  return (
    <Box mb={2}>
      <InputTitle>{t('Select Reminder')}</InputTitle>
      <Select
        id="create_reminder_selected_frequency"
        maxMenuHeight={180}
        value={get(formState.frequency, 'selectReminder')}
        options={customizeReminderOptions}
        onChange={(selectReminder) =>
          updateFormState('selectReminder', selectReminder)
        }
      />
      {isEqual(
        get(formState.frequency, 'selectReminder.value'),
        'customize'
      ) && (
        <>
          <Text mt={6} mb={2} fontSize="14px" fontWeight={500}>
            {t('Reminder Type')}
          </Text>
          <Flex alignItems="center" flexDirection={['column', 'column', 'row']}>
            {map(ReminderTypeOptions, (option, index) => (
              <RadioBox
                t={t}
                key={index}
                option={option}
                checked={isEqual(
                  option.key,
                  get(formState.frequency, 'reminderType.key')
                )}
                onChange={(reminderType) => {
                  updateFormState('reminderType', reminderType);
                }}
              />
            ))}
          </Flex>

          {isEqual(get(formState.frequency, 'reminderType.key'), 'oneTime') && (
            <>
              <Text mb={2} mt={6} fontSize="14px" fontWeight={500}>
                {t(`Customize one-time reminder`)}
              </Text>

              <InputTitle>{t('Trigger event')}</InputTitle>
              <Select
                value={get(formState.frequency, 'triggerEvent')}
                options={map(ReminderTriggerEventOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onChange={(triggerEvent) =>
                  updateFormState('triggerEvent', triggerEvent)
                }
              />

              {shouldShowSendReminder() && (
                <>
                  <InputTitle mt={2}>{t('Send reminder')}</InputTitle>
                  <InputWithSelect
                    inputValue={get(
                      formState?.frequency,
                      'reminderOneTimeDuration',
                      ''
                    )}
                    inputPlaceholder="Input duration"
                    onInputChange={(e) =>
                      updateFormState('reminderOneTimeDuration', e.target.value)
                    }
                    inputProps={{
                      type: 'number',
                      min: '2',
                      step: '1',
                    }}
                    selectValue={get(
                      formState?.frequency,
                      'reminderOneTimeUnit'
                    )}
                    selectOptions={map(TriggerEventUnitOptions, (option) => ({
                      ...option,
                      label: t(option.label),
                    }))}
                    onSelectChange={(unit) => {
                      updateFormState('reminderOneTimeUnit', unit);
                    }}
                  />

                  {get(formState?.frequency, 'reminderOneTimeUnit').value !==
                    'minute' &&
                    get(formState?.frequency, 'reminderOneTimeUnit').value !==
                      'hour' && (
                      <Flex
                        mt={2}
                        flexDirection="column"
                        width={['100%', '50%']}
                      >
                        <InputTitle>{`${t(
                          'Select time(s) to send reminder'
                        )}`}</InputTitle>
                        <Box mr={[0, 2]}>
                          <HourMinsSelector
                            defaultHour={{
                              label:
                                chain(formState)
                                  .get('frequency.sendReminderTime')
                                  .first()
                                  .get('value')
                                  .split(':')
                                  .value()?.[0] || '',
                              value:
                                chain(formState)
                                  .get('frequency.sendReminderTime')
                                  .first()
                                  .get('value')
                                  .split(':')
                                  .value()?.[0] || '',
                            }}
                            defaultMins={{
                              label: chain(formState)
                                .get('frequency.sendReminderTime')
                                .first()
                                .get('value')
                                .split(':')
                                .value()?.[1],
                              value: chain(formState)
                                .get('frequency.sendReminderTime')
                                .first()
                                .get('value')
                                .split(':')
                                .value()?.[1],
                            }}
                            ref={singleReminderHourRef}
                            showLabels={false}
                            callback={(time) => {
                              setSendReminderTimeInput(time);

                              onChangeCreatableSelect(
                                [{ label: time, value: time }],
                                'sendReminderTime',
                                true,
                                false
                              );
                            }}
                            allowMultiEntry={false}
                          />
                        </Box>
                      </Flex>
                    )}
                </>
              )}
              {shouldShowDateTimeField() && (
                <Flex
                  mt={2}
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={['column', 'row']}
                >
                  <Box mr={isMobile ? 0 : 2} mb={isMobile ? 2 : 0} width="100%">
                    <InputTitle>{t('Date')}</InputTitle>
                    <DateInputField
                      style={{ minHeight: '40px' }}
                      onlyDate
                      defaultDate={
                        get(formState?.frequency, 'sendReminderDate') || ''
                      }
                      onChange={onChangeReminderDate}
                      calendarProps={{
                        checkValidDay: (date) => checkValidDateOrTime(date),
                        checkValidMonth: (date) =>
                          isAfter(
                            startOfMonth(startOfToday()),
                            startOfMonth(date)
                          ),
                        checkValidYear: (date) =>
                          isAfter(
                            startOfYear(startOfToday()),
                            startOfYear(date)
                          ),
                      }}
                    />
                  </Box>
                  <Flex flexDirection="column" width="100%">
                    <InputTitle>{`${t(
                      'Select time(s) to send reminder'
                    )}`}</InputTitle>
                    <Box mr={[0, 2]}>
                      <HourMinsSelector
                        ref={singleReminderHourRef}
                        showLabels={false}
                        callback={(time) => {
                          setSendReminderTimeInput(time);

                          onChangeCreatableSelect(
                            [{ label: time, value: time }],
                            'sendReminderTime',
                            true,
                            false
                          );
                        }}
                        allowMultiEntry={false}
                      />
                    </Box>
                  </Flex>
                </Flex>
              )}

              <InputTitle mt={2}>{t('Select message template')}</InputTitle>
              <Select
                id="create_reminder_message_template"
                maxMenuHeight={180}
                isClearable
                value={get(formState.frequency, 'messageTemplate')}
                options={messageTemplates}
                onChange={(messageTemplate) =>
                  updateFormState('messageTemplate', messageTemplate)
                }
              />
            </>
          )}

          {isEqual(
            get(formState.frequency, 'reminderType.key'),
            'recurring'
          ) && (
            <>
              <Text mt={6} mb={2} fontSize="14px" fontWeight={500}>
                {t('Customize reminder frequency')}
              </Text>

              <InputTitle>{t('Set reminders every')}</InputTitle>
              <InputWithSelect
                inputValue={get(
                  formState.frequency,
                  'reminderFrequencyInterval'
                )}
                onInputChange={(e) =>
                  updateFormState('reminderFrequencyInterval', e.target.value)
                }
                inputProps={{
                  type: 'number',
                  min: '1',
                  step: '1',
                }}
                selectValue={get(formState.frequency, 'reminderFrequencyUnit')}
                selectOptions={map(ReminderFrequencyUnitOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onSelectChange={(unit) =>
                  updateFormState('reminderFrequencyUnit', unit)
                }
              />

              {shouldShowUnitComponent(['week', 'month']) && (
                <>
                  <InputTitle mt={2}>{t('Repeats on')}</InputTitle>
                  {shouldShowUnitComponent(['week']) && (
                    <WeekSelector
                      allDays={map(defaultDays, (day) => ({
                        ...day,
                        label: t(day.label),
                      }))}
                      selectedDays={get(
                        formState.frequency,
                        'reminderFrequencyDaysOfWeek',
                        []
                      )}
                      onChange={(days) => {
                        if (!isEmpty(days)) {
                          updateFormState('reminderFrequencyDaysOfWeek', days);
                        }
                      }}
                      itemProps={{
                        mr: [1, 2],
                        width: isMobile ? '32px' : '48px',
                        height: isMobile ? '32px' : '48px',
                      }}
                      itemTextProps={{
                        medium: !isMobile,
                        small: isMobile,
                      }}
                    />
                  )}

                  {shouldShowUnitComponent(['month']) && (
                    <Select
                      isMulti
                      maxMenuHeight={180}
                      options={map(daysInMonth, (day) => ({
                        ...day,
                        label: `${t('Day')} ${day.value}`,
                      }))}
                      defaultValue={map(
                        get(
                          formState.frequency,
                          'reminderFrequencyDaysOfMonth',
                          []
                        ),
                        (freq) => ({
                          ...freq,
                          label: `${t('Day')} ${freq.value}`,
                        })
                      )}
                      onChange={(days) => {
                        if (!isEmpty(days)) {
                          updateFormState('reminderFrequencyDaysOfMonth', days);
                        }
                      }}
                    />
                  )}
                </>
              )}

              <InputTitle mt={2}>
                {`${t('Select time(s) to send reminder')}`}
              </InputTitle>
              <Flex
                flexDirection={['column', 'row']}
                mb={[2, 0]}
                justifyContent="space-between"
              >
                <Flex width={['100%', '30%']} mr={[0, 2]} mb={[2, 0]}>
                  <HourMinsSelector
                    showLabels={false}
                    callback={(time) => setReminderTimeInputValue(time)}
                  />
                </Flex>
                <CreatableSelect
                  containerProps={{
                    width: ['100%', '70%'],
                  }}
                  classNamePrefix="botmd"
                  className="creatable-select-input"
                  placeholder={t(
                    'Choose the hour and minute from the dropdown menu'
                  )}
                  value={get(
                    formState?.frequency,
                    'reminderFrequencyTimes',
                    []
                  )}
                  inputValue={reminderTimeInputValue}
                  onChange={(times) => {
                    onChangeCreatableSelect(
                      times,
                      'reminderFrequencyTimes',
                      false,
                      true
                    );
                  }}
                />
              </Flex>

              <InputTitle mt={2}>{t('Select message template')}</InputTitle>
              <Select
                id="create_reminder_message_template"
                maxMenuHeight={180}
                isClearable
                value={get(formState.frequency, 'messageTemplate')}
                options={messageTemplates}
                onChange={(messageTemplate) =>
                  updateFormState('messageTemplate', messageTemplate)
                }
              />
            </>
          )}

          <TextLink
            onClick={() => setShowAdvance(!showAdvance)}
            text={showAdvance ? t('Hide settings') : t('Advanced settings')}
            icon={showAdvance ? faMinus : faPlus}
            mt={3}
            mb={3}
          />

          {showAdvance && (
            <>
              <InfoText
                message={t(
                  'Follow-up reminders can only be sent up to 12 hours after the original reminder. A reminder will only be sent if patient does not respond to the first reminder. To send another reminder days or months after the original reminder, please create a new reminder.'
                )}
              />

              <H5 mt={2} mb={2}>
                {t('Customize follow-up reminders')}
              </H5>
              {reminder?.settings?.follow_up_intervals?.map((interval, i) => {
                return (
                  <FollowUpReminder
                    onChange={onFollowUpReminderChange}
                    defaultValue={interval}
                    index={i + 1}
                  />
                );
              })}

              {map(new Array(followUpRemindersCount), (item, index) => (
                <FollowUpReminder
                  onChange={onFollowUpReminderChange}
                  index={
                    (reminder?.settings?.follow_up_intervals?.length > 0
                      ? reminder.settings.follow_up_intervals.length
                      : 0) +
                    index +
                    1
                  }
                />
              ))}
              <TextLink
                onClick={() => {
                  setFollowUpRemindersCount(followUpRemindersCount + 1);
                }}
                text={t('Add follow-up reminder')}
                icon={faPlus}
                mb={2}
                mt={2}
              />

              <H5 mt={4} mb={2}>
                {t('Submission compliance window')}
              </H5>

              <Box mb={2}>
                <InfoText
                  message={t(
                    'Duration before and after a reminder in which a patient’s submission is considered to be compliant.'
                  )}
                />
              </Box>

              <InputWithSelect
                inputValue={get(
                  formState?.frequency,
                  'preSubmissionComplianceDuration',
                  ''
                )}
                inputPlaceholder="Input duration"
                onInputChange={(e) =>
                  updateFormState(
                    'preSubmissionComplianceDuration',
                    e.target.value
                  )
                }
                inputProps={{
                  type: 'number',
                  min: '1',
                  step: '1',
                }}
                selectValue={get(
                  formState?.frequency,
                  'preSubmissionComplianceUnit'
                )}
                selectOptions={map(PreComplianceUnitOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onSelectChange={(preSubmissionComplianceUnit) =>
                  updateFormState(
                    'preSubmissionComplianceUnit',
                    preSubmissionComplianceUnit
                  )
                }
              />
              <InputWithSelect
                mt={2}
                inputValue={get(
                  formState?.frequency,
                  'postSubmissionComplianceDuration',
                  ''
                )}
                inputPlaceholder="Input duration"
                onInputChange={(e) =>
                  updateFormState(
                    'postSubmissionComplianceDuration',
                    e.target.value
                  )
                }
                inputProps={{
                  type: 'number',
                  min: '1',
                  step: '1',
                }}
                selectValue={get(
                  formState.frequency,
                  'postSubmissionComplianceUnit'
                )}
                selectOptions={map(PostComplianceUnitOptions, (option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                onSelectChange={(postSubmissionComplianceUnit) =>
                  updateFormState(
                    'postSubmissionComplianceUnit',
                    postSubmissionComplianceUnit
                  )
                }
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ReminderFrequency;
