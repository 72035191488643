import { useApi } from '@fivehealth/botero';
import useClinic from 'api/queries/useClinic';
import asyncJobTypes, { AsyncStatus } from 'constants/asyncJobTypes';
import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import {
  AsyncJob,
  AsyncJobData,
  PatientEventFilter,
} from './useUpdateEventsAsync';

export interface PatientCancelEventInput {
  asyncJobUid?: string;
  patientEventUids?: string[];
  patientEventFilter?: PatientEventFilter;
}

interface UseCancelEventsAsyncResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancelEvents: (input: PatientCancelEventInput) => Promise<AsyncJob>;
}

export const useCancelEventsAsync = (): UseCancelEventsAsyncResult => {
  const {
    queries: { usePatientEventDeleteAsync },
  } = useApi({
    queries: ['usePatientEventDeleteAsync'],
  });

  const { data: clinic }: { data: { domain?: string } } = useClinic();

  // eslint-disable-next-line no-unused-vars
  const [_, setAsyncJobData] = useRecoilState<AsyncJobData>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncJobState as any
  );

  const { mutateAsync: cancelPatientsEvents } = usePatientEventDeleteAsync({
    variables: {},
  });

  const cancelEvents = async (input: PatientCancelEventInput) => {
    const result = await cancelPatientsEvents({
      input,
    });

    const asyncJob = get(
      result,
      'cleoPatientEventDeleteAsync.asyncJob',
      null
    ) as AsyncJob;

    if (asyncJob && asyncJob?.status !== 'SUCCESS') {
      const { type } = asyncJobTypes.CleoPatientEventDelete;
      const info =
        asyncJobTypes.CleoPatientEventDelete.infoText[
          asyncJob.status as AsyncStatus
        ];

      const data = {
        type,
        ...info,
        ...asyncJob,
        clinic: clinic?.domain as string,
      } as unknown as AsyncJobData;
      setAsyncJobData(data);
    }

    return asyncJob;
  };

  return {
    cancelEvents,
  };
};
