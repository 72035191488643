import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { buildPaginatedResponse } from 'lib/pagination';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientEvents(
    $searchText: String
    $patientUidIn: [String]
    $sortBy: PatientEventOrderBy
    $sortDesc: Boolean
    $first: Int
    $offset: Int
    $isUpcoming: Boolean
    $eventStartOnLt: DateTime
    $statusIn: [PatientEventStatus!]
    $isCanceled: Boolean
    $eventEventTypeUidIn: [String]
    $calendarUidIn: [String]
  ) {
    cleoPatientEvents(
      sortBy: $sortBy
      sortDesc: $sortDesc
      patient_Uid_In: $patientUidIn
      first: $first
      offset: $offset
      patientSearch: $searchText
      isUpcoming: $isUpcoming
      event_StartOn_Lt: $eventStartOnLt
      statusIn: $statusIn
      isCanceled: $isCanceled
      event_EventType_Uid_In: $eventEventTypeUidIn
      calendar_Uid_In: $calendarUidIn
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          status
          eventUrl
          cancelUrl
          rescheduleUrl
          eventLogs {
            status
            datetime
            clinician {
              name
            }
          }
          patient {
            uid
            name
            phone
            email
            communicationMethod
            gender
            dateOfBirth
            identifier
            clinic {
              name
            }
          }
          calendar {
            name
          }
          event {
            uid
            name
            providerEventType
            startOn
            createdOn
            endOn
            reminders
            rescheduledFrom {
              uid
              name
            }
            eventType {
              uid
              name
            }
          }
          startOn
          createdOn
          confirmedOn
          patientShowedOn
          isPatientShowed
          isConfirmed
          metadataEntries {
            uid
            parameter {
              uid
              name
              valueType
            }
            value
            extractedForDisplay
          }
        }
      }
    }
  }
`;

export const onMapNodeAppointment = (node) => ({
  disableCheckbox: node.status === 'Cancelled',
  ...node,
});

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  paginate: 'offset',
  keepPreviousData: true,
  filters: {
    confirmationFilters: {
      id: 'confirmation',
      title: 'Confirmation',
      data: [
        {
          id: 'confirmed',
          key: 'confirmed',
          label: 'Confirmed',
          toParams: {
            statusInCon: ['CONFIRMED'],
          },
        },
        {
          id: 'not_confirmed',
          label: 'Not confirmed',
          toParams: {
            statusInCon: ['NOT_CONFIRMED'],
          },
        },
        {
          id: 'showed',
          label: 'Show',
          toParams: {
            statusInCon: ['PATIENT_SHOWED'],
          },
        },
        {
          id: 'noShow',
          label: 'No-show',
          toParams: {
            statusInCon: ['PATIENT_NOT_SHOWED'],
          },
        },
      ],
      multiSelect: true,
    },
    statusFilters: {
      id: 'status',
      title: 'Status',
      data: [
        {
          id: 'scheduled',
          label: 'Scheduled',
          toParams: {
            statusIn: ['SCHEDULED'],
          },
        },
        {
          id: 'rescheduled',
          label: 'Rescheduled',
          toParams: {
            statusIn: ['RESCHEDULED'],
          },
        },
        {
          id: 'canceled',
          label: 'Canceled',
          toParams: {
            statusIn: ['CANCELLED'],
          },
        },
      ],
      multiSelect: true,
    },
  },
  baseQueryKey: 'patientsEvents',
  getNextPageParam: ({ cleoPatientEvents: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
    if (!hasNextPage) {
      return undefined;
    }

    const currentOffset = allPages.flatMap(({ cleoPatientEvents }) =>
      get(cleoPatientEvents, 'edges', [])
    ).length;
    return currentOffset;
  },
  select: (data) =>
    buildPaginatedResponse(data, 'cleoPatientEvents', onMapNodeAppointment),
});
