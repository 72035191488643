import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryGroup,
} from 'victory';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parseISO, addDays, subDays, format } from 'date-fns';
import { theme } from '@fivehealth/botero';
import useWindowResize from 'lib/useWindowResize';

import ChartTheme from './theme';
import useChartPrintView from './useChartPrintView';
import { DataLabel, OverlayLabel } from './ChartLegend';
import { createContainerComponent } from './ChartTooltip';
import { CustomTooltip } from './utils';

const LineChart = ({
  data,
  startDate,
  endDate,
  width,
  minYvalue = 0,
  maxYvalue = 200,
  minimumLineY = 50.0,
  maxLineY = 180.0,
  numYAxisTicks = 4,
  height,
  containerStyles = {},
  showDataLabels = false,
  unit = 'mmHg',
  chartSetting,
}) => {
  const showNullState = isEmpty(data.flatMap((d) => d));
  const [windowWidth] = useWindowResize();
  const { t } = useTranslation();

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useRef();

  let chartData = data.map((groupData) =>
    get(groupData, 'submissionTimes', []).map((ts, i) => {
      const timestamp = parseISO(ts);
      const {
        symbol,
        description,
        theme: { color: fill },
        lineStyle,
      } = groupData;

      const value = get(groupData, `values[${i}]`, 0);

      return {
        value,
        description: `${t(description)}, ${format(timestamp, 'HH:mm')}`,
        valueText: `${
          chartSetting?.decimalPlaces
            ? value.toFixed(chartSetting?.decimalPlaces)
            : value
        } ${unit}`,
        timestamp,
        symbol,
        fill,
        lineStyle,
        y: value,
        x: timestamp,
      };
    })
  );

  chartData = chartData.map((groupData, groupIdx) =>
    groupData.map((group, i) => ({
      ...group,
      sibling: chartData[groupIdx === 0 ? 1 : 0][i],
    }))
  );

  const yAxisTickSpacing = Math.round(maxYvalue / numYAxisTicks);
  const yAxisTickValues = [
    ...Array.from(Array(numYAxisTicks).keys()).map((i) => i * yAxisTickSpacing),
    maxYvalue,
  ];

  const onSetBoundingRect = useCallback((node) => {
    if (node !== null) {
      graphRef.current = node;
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const {
    styles: {
      height: chartHeight,
      width: chartWidth,
      containerStyles: chartContainerStyles,
    },
  } = useChartPrintView({ height, width, containerStyles });

  useEffect(() => {
    if (graphRef.current) {
      onSetBoundingRect(graphRef.current);
    }
  }, [onSetBoundingRect, windowWidth]);

  const containerComponent = useMemo(() => {
    if (showNullState) {
      return {};
    }
    return {
      containerComponent: createContainerComponent({
        showDataLabels,
        labelComponent: <CustomTooltip />,
        mouseFollowTooltips: true,
        voronoiDimension: 'x',
      }),
    };
  }, [showDataLabels, showNullState]);

  return (
    <div
      style={{ width: '100%', height: '100%', ...chartContainerStyles }}
      ref={graphRef}
    >
      <VictoryChart
        domain={{
          x: showNullState ? null : [startDate, endDate],
          y: [minYvalue, maxYvalue],
        }}
        scale={{ x: 'time', y: 'linear' }}
        height={chartHeight || 500}
        width={chartWidth || boundingRect.width}
        domainPadding={{ x: 20 }}
        padding={40}
        theme={ChartTheme}
        {...containerComponent}
      >
        <VictoryAxis
          dependentAxis
          tickValues={yAxisTickValues}
          tickFormat={(y) => y}
          style={{
            axis: { stroke: null },
            tickLabels: {
              fill: '#A3A9B1',
            },
          }}
        />
        {!showNullState && (
          <VictoryAxis
            tickFormat={(y) => format(y, 'MMM dd')}
            style={{
              axis: { stroke: theme.colors.mediumShade },
            }}
          />
        )}

        {minimumLineY && (
          <VictoryLine
            name="minLine"
            style={{
              data: { stroke: 'red', strokeWidth: 0.6, strokeDasharray: '3' },
            }}
            data={[
              { x: subDays(new Date(), 364), y: minimumLineY },
              { x: addDays(new Date(), 3), y: minimumLineY },
            ]}
          />
        )}
        {minimumLineY && (
          <DataLabel
            x={endDate || new Date()}
            dx={-10}
            dy={-12}
            y={minimumLineY}
            text={showNullState ? '' : minimumLineY}
            style={{ fill: 'red' }}
          />
        )}
        {maxLineY && (
          <VictoryLine
            name="maxLine"
            style={{
              data: {
                stroke: '#256BF6',
                strokeWidth: 0.6,
                strokeDasharray: '3',
              },
            }}
            data={[
              { x: subDays(new Date(), 364), y: maxLineY },
              { x: addDays(new Date(), 3), y: maxLineY },
            ]}
          />
        )}
        {maxLineY && (
          <DataLabel
            x={endDate || new Date()}
            dx={-10}
            dy={-12}
            y={maxLineY}
            text={showNullState ? '' : maxLineY}
            style={{ fill: '#256BF6' }}
          />
        )}

        {chartData.map((lineData, i) => (
          <VictoryGroup key={i}>
            <VictoryLine
              name={`line${i}`}
              data={lineData}
              style={{ data: lineData[0].lineStyle }}
            />
            <VictoryScatter
              data={lineData}
              size={6}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                },
              }}
              labels={({ datum: { value } }) => (showDataLabels ? value : ' ')}
              labelComponent={showDataLabels ? <OverlayLabel /> : undefined}
            />
          </VictoryGroup>
        ))}
      </VictoryChart>
    </div>
  );
};

export default LineChart;
