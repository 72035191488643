/* eslint-disable @typescript-eslint/naming-convention */
export const EventSubSource = {
  // Patient List
  Active: 'Active',
  Discharge: 'Discharge',

  // Patient Monitoring
  All: 'All',
  Alerts: 'Alerts',
  NonCompliant: 'Non-Compliant',
  Unread: 'Unread',

  // Clinical Admin
  ClinicalAdmin: 'ClinicalAdmin',

  // Caregiver
  Caregiver: 'Caregiver',
};
