import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryGroup,
} from 'victory';
import { get, isEmpty } from 'lodash';
import { parseISO, addDays, subDays, format } from 'date-fns';
import { theme } from '@fivehealth/botero';
import useWindowResize from 'lib/useWindowResize';
import useChartPrintView from './useChartPrintView';
import { DataLabel, OverlayLabel } from './ChartLegend';
import { createContainerComponent } from './ChartTooltip';
import ChartTheme from './theme';
import { CustomTooltip } from './utils';

const chartColors = {
  green: '#27AE60',
  ...theme.colors,
};

const LineChart = ({
  data,
  startDate,
  endDate,
  minYvalue = 0,
  maxYvalue = 100,
  minimumLineY,
  maxLineY,
  // numYAxisTicks = 5,
  unit = 'bpm',
  containerStyles = {},
  showDataLabels = false,
  height,
  width,
  chartSetting,
}) => {
  const showNullState = isEmpty(data.flatMap((d) => d));
  const [windowWidth] = useWindowResize();

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useRef();

  const chartData = data.map((groupData) =>
    get(groupData, 'submissionTimes', []).map((ts, i) => {
      const timestamp = parseISO(ts);
      const {
        symbol,
        description,
        theme: { color: fill },
        lineStyle,
      } = groupData;

      const value = get(groupData, `values[${i}]`, 0);

      return {
        value,
        description: `${description}, ${format(timestamp, 'HH:mm')}`,
        valueText: `${
          chartSetting?.decimalPlaces
            ? value.toFixed(chartSetting?.decimalPlaces)
            : value
        } ${unit}`,
        timestamp,
        symbol,
        fill,
        lineStyle,
        y: value,
        x: timestamp,
      };
    })
  );

  // Remove tickvalues for now to allow component to automatically detect based on axis provided.
  /* const yAxisTickSpacing = Math.round(maxYvalue / numYAxisTicks);
  const yAxisTickValues = [
    ...Array.from(Array(numYAxisTicks).keys()).map((i) => i * yAxisTickSpacing),
    maxYvalue,
  ]; */

  const onSetBoundingRect = useCallback((node) => {
    if (node !== null) {
      graphRef.current = node;
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const {
    styles: {
      height: chartHeight,
      width: chartWidth,
      containerStyles: chartContainerStyles,
    },
  } = useChartPrintView({ height, width, containerStyles });

  useEffect(() => {
    if (graphRef.current) {
      onSetBoundingRect(graphRef.current);
    }
  }, [onSetBoundingRect, windowWidth]);

  const containerComponent = useMemo(() => {
    if (showNullState) {
      return {};
    }
    return {
      containerComponent: createContainerComponent({
        showDataLabels,
        labelComponent: <CustomTooltip />,
      }),
    };
  }, [showDataLabels, showNullState]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        ...chartContainerStyles,
      }}
      ref={graphRef}
    >
      <VictoryChart
        domain={{
          x: showNullState ? null : [startDate, endDate],
          y: [minYvalue, maxYvalue],
        }}
        domainPadding={{ x: 20 }}
        scale={{ x: 'time', y: 'linear' }}
        height={chartHeight || 500}
        padding={40}
        width={chartWidth || boundingRect.width}
        theme={ChartTheme}
        {...containerComponent}
      >
        <VictoryAxis
          dependentAxis
          fixLabelOverlap
          // tickValues={yAxisTickValues}
          tickFormat={(y) => y}
          style={{
            axis: { stroke: null },
            tickLabels: {
              fill: '#A3A9B1',
            },
          }}
        />
        {!showNullState && (
          <VictoryAxis
            tickFormat={(y) => format(y, 'MMM dd')}
            style={{
              axis: { stroke: theme.colors.mediumShade },
            }}
          />
        )}

        {minimumLineY && (
          <VictoryLine
            name="minLine"
            style={{
              data: {
                stroke: chartColors.green,
                strokeWidth: 0.6,
                strokeDasharray: '3',
              },
            }}
            data={[
              { x: subDays(new Date(), 364), y: minimumLineY },
              { x: addDays(new Date(), 3), y: minimumLineY },
            ]}
          />
        )}
        {minimumLineY && (
          <DataLabel
            x={endDate || new Date()}
            dx={-10}
            dy={-12}
            y={minimumLineY}
            text={showNullState ? '' : minimumLineY}
            style={{ fill: chartColors.green }}
          />
        )}
        {maxLineY && (
          <DataLabel
            x={endDate || new Date()}
            dx={-10}
            dy={-12}
            y={maxLineY}
            text={showNullState ? '' : maxLineY}
            style={{ fill: chartColors.green }}
          />
        )}
        {maxLineY && (
          <VictoryLine
            name="maxLine"
            style={{
              data: {
                stroke: chartColors.green,
                strokeWidth: 0.6,
                strokeDasharray: '3',
              },
            }}
            data={[
              { x: subDays(new Date(), 364), y: maxLineY },
              { x: addDays(new Date(), 3), y: maxLineY },
            ]}
          />
        )}
        <VictoryGroup>
          {!isEmpty(chartData) && (
            <VictoryLine
              name="line1"
              data={chartData[0]}
              style={{
                data: {
                  stroke: 'rgba(224, 81, 56, 0.4)',
                  strokeWidth: 3,
                },
              }}
            />
          )}
          <VictoryScatter
            data={isEmpty(chartData[0]) ? [] : chartData[0]}
            size={5}
            padding={0}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            labels={({ datum: { value } }) => (showDataLabels ? value : ' ')}
            labelComponent={showDataLabels ? <OverlayLabel /> : undefined}
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
};

export default LineChart;
