import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoCliniciansExportedFormats {
    cleoClinicians {
      exportByUrl(outputFormat: CSV)
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'cliniciansExportedFormats',
  keepPreviousData: true,
  select: ({ cleoClinicians }) => cleoClinicians?.exportByUrl,
});
