import React, { useRef } from 'react';
import { chain, isEmpty } from 'lodash';
import { ActionMenu, ActionMenuItem, ActionMenuText } from '@fivehealth/botero';

const ActionDropdown = ({ dropdownOptions, ...props }) => {
  const dropdownRef = useRef();
  const onSetDropDownRef = (ref) => {
    dropdownRef.current = ref;
  };

  const handleOnClick = () => {
    dropdownRef.current.setOpen(false);
  };

  const preventOpen = isEmpty(dropdownOptions) ? { open: false } : {};

  return (
    <ActionMenu dropdownRef={onSetDropDownRef} {...props} {...preventOpen}>
      {chain(dropdownOptions)
        .filter(({ visible = true }) => visible)
        .map(({ label, labelProps, onClick, ...menuProps }) => (
          <ActionMenuItem
            onClick={() => {
              handleOnClick();
              if (onClick) {
                onClick();
              }
            }}
            {...menuProps}
          >
            <ActionMenuText {...labelProps}>{label}</ActionMenuText>
          </ActionMenuItem>
        ))
        .value()}
    </ActionMenu>
  );
};

export default ActionDropdown;
