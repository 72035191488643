/* eslint-disable @typescript-eslint/naming-convention */
import { useApi } from '@fivehealth/botero';
import useClinic from 'api/queries/useClinic';
import asyncJobTypes, { AsyncStatus } from 'constants/asyncJobTypes';
import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import { AsyncJob, AsyncJobData } from './useUpdateEventsAsync';

export type PatientFormOperation = 'DISCHARGE' | 'ENROLL';

export interface PatientFilter {
  communicationMethod_In: string[];
  createdOn_Gte: string;
  createdOn_Lte: string;
  deactivatedOn_Isnull: boolean;
  enrolledMonitoringForm_In: string[];
  gender_In: string[];
  patientForms_ClinicianRelations_Clinician_Uid_In: string[];
  patientForms_MonitoringForm_Uid_In: string[];
}

export interface EnrollDischargeInput {
  deactivatePatient?: boolean;
  clinicianAlertees?: string[];
  clinicianIcs: string[];
  monitoringFormsetUid: string;
  monitoringFormUid: string;
  operation: PatientFormOperation;
  patientFilter: PatientFilter;
  patientFormUids: string[];
  patientGroupUids: string[];
  patientUids: string[];
  reminders: string[];
  asyncJobUid?: string;
}

interface UseEnrollDischargeAsyncResult {
  dischargeOrEnrollPatients: (
    input: EnrollDischargeInput,
    operation: PatientFormOperation
  ) => Promise<AsyncJob>;
}

export const useEnrollDischargeAsync = (): UseEnrollDischargeAsyncResult => {
  const {
    queries: { usePatientFormEnrollDischargeAsync },
  } = useApi({
    queries: ['usePatientFormEnrollDischargeAsync'],
  });

  const { data: clinic }: { data: { domain?: string } } = useClinic();

  // eslint-disable-next-line no-unused-vars
  const [_, setAsyncJobData] = useRecoilState<AsyncJobData>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncJobState as any
  );

  const { mutateAsync: enrollDischarge } = usePatientFormEnrollDischargeAsync({
    variables: {},
  });

  const dischargeOrEnrollPatients = async (
    input: EnrollDischargeInput,
    operation: PatientFormOperation
  ) => {
    const result = await enrollDischarge({
      input,
    });

    const asyncJob = get(
      result,
      'cleoPatientFormEnrollDischargeAsync.asyncJob',
      null
    ) as AsyncJob;

    if (asyncJob && asyncJob?.status !== 'SUCCESS') {
      const { type } = asyncJobTypes.CleoPatientFormEnrollDischarge;
      const info =
        asyncJobTypes.CleoPatientFormEnrollDischarge.infoText[
          asyncJob.status as AsyncStatus
        ];
      info.info = info.info.replace(
        '{{operation}}',
        operation === 'DISCHARGE' ? 'Discharging' : 'Enrolling'
      );
      info.title = info.title.replace(
        '{{operation}}',
        operation === 'DISCHARGE' ? 'Discharging' : 'Enrolling'
      );
      const data = {
        type,
        ...info,
        ...asyncJob,
        operation,
        clinic: clinic?.domain as string,
      } as unknown as AsyncJobData;
      setAsyncJobData(data);
    }

    return asyncJob;
  };

  return {
    dischargeOrEnrollPatients,
  };
};
