import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Flex,
  Body,
  Box,
  BoxWidget,
  Drawer,
  H2,
  Text,
  useApi,
  FAIcon,
  H5,
  theme,
  Badge,
  AccordionWidget,
} from '@fivehealth/botero';
import { convertUrlsIntoMarkDownString, tableConfigUtils } from 'AppUtils';
import { chain, get, isEmpty, has, partition } from 'lodash';
import { format, parseISO, compareDesc } from 'date-fns';
import ContentLoader from 'react-content-loader';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  faChartBar,
  faFileAlt,
  faTimes,
  faEllipsisV,
  faChevronDown,
  faEllipsisH,
  faExclamationTriangle,
  faCommentAlt,
} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import formatPhoneNumber from 'lib/formatPhoneNumber';
import { LoadMoreButton } from 'components/Table/Table';
import moment from 'moment';
import EmptyAlertsBotAvatar from '../../assets/empty-alerts-bot-avatar.svg';
import CommentSection from './CommentSection';
import { ActionsCell } from './SubmissionTable';
import Markdown from '../../components/Markdown/Markdown';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import SettingsActionMenu from '../../components/ActionMenuPopper/SettingsActionMenu';

export const MarkdownStyled = styled(Markdown)`
  & > p {
    margin-top: 0px;
  }
`;

const Container = styled(Box)`
  overflow-wrap: anywhere;
`;

export const SubmissionDetail = withTranslation()(
  ({
    t,
    submission,
    selectedSubmissionUid,
    patient,
    onShowSubmission,
    showDischargedLabel,
    cellData,
    tableSettings,
  }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
    const [showSubmission, setShowSubmission] = useState(false);
    const [mappedSubmissionValues, setMappedSubmissionValues] = useState([]);

    const dateColumnConfig = tableSettings?.columns.find(
      (c) => c.header === 'Time reported'
    );

    const tableConfigSubmissionDate = useMemo(() => {
      if (dateColumnConfig) {
        return tableConfigUtils.renderCell({
          column: dateColumnConfig,
          row: {
            original: cellData,
          },
        });
      }

      return submission?.submittedOn
        ? moment(submission?.submittedOn).format('DD MMM YYYY, HH:mm')
        : '';
    }, [dateColumnConfig, submission?.submittedOn, cellData]);

    useEffect(() => {
      if (
        submission?.values ||
        (submission?.triggeredAlerts &&
          get(submission, 'triggeredAlerts[0].alertReasons'))
      ) {
        const result = submission?.submissionGroupedParams?.map((o) => ({
          ...o,
          isOpen: true,
          type: o?.isTriggeredAlert ? ['danger'] : ['plain'],
        }));

        setMappedSubmissionValues(result);
      }
    }, [submission]);

    useEffect(() => {
      if (selectedSubmissionUid) {
        setShowSubmission(
          mappedSubmissionValues?.length > 0 &&
            selectedSubmissionUid === submission?.uid
        );
      } else {
        setShowSubmission(true);
      }
    }, [mappedSubmissionValues, selectedSubmissionUid, submission?.uid]);

    const assertAlertCountEq = () => {
      const alertsCount =
        get(submission, 'triggeredAlerts[0].alertReasons.length') || 0;

      const mappedTriggers = mappedSubmissionValues.filter(
        (o) => o?.isTriggeredAlert
      );

      if (alertsCount === mappedTriggers.length) return true;

      let mCount = 0;
      mappedTriggers?.forEach((m) => {
        if (m?.groupDetails) {
          const details = m?.groupDetails.filter((o) =>
            chain(submission)
              .get('triggeredAlerts')
              .first()
              .get('alertReasons')
              .find((f) => f?.submissionValue?.uid === o?.submissionValueUID)
              .value()
          );
          if (details.length >= 1) {
            mCount += 1;
          }
        }
      });
      const total = alertsCount - (mappedTriggers || []).length;
      return mCount === total;
    };

    const SubmissionAccordionHeader = ({ ...props }) => (
      <Box
        style={{ userSelect: 'none' }}
        onClick={() => {
          setShowSubmission(!showSubmission);
        }}
        id={submission.uid}
        data-testid={`submission_accordion_header_icon-${submission?.uid}-${showSubmission}`}
        {...props}
      >
        <FAIcon
          icon={faChevronDown}
          style={{
            height: 16,
            transform: `rotate(${showSubmission ? '180' : '0'}deg)`,
          }}
        />
      </Box>
    );

    const SubmissionHeaderIcon = ({ status }) => (
      <>
        {status === 'warning' && (
          <FAIcon
            fontSize="14px"
            fontWeight={400}
            icon={faExclamationTriangle}
            style={{ color: theme.colors.danger, paddingLeft: 10 }}
          />
        )}
        {status === 'danger' && (
          <StatusIcon
            status="danger"
            style={{ display: 'inline-block', marginLeft: 10 }}
          />
        )}
      </>
    );

    const renderDischargedLabel = (isDischarged) =>
      isDischarged && isMobile ? ` (${t('Discharged')})` : '';

    const renderSubHeaderMonitoringMobile = () => (
      <Flex
        width="auto"
        alignItems="baseline"
        mt={-2}
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          <Text
            fontSize={14}
            mb={1}
            fontWeight={600}
            onClick={onShowSubmission}
            mt={-1}
          >
            {`${patient?.name}${renderDischargedLabel(
              showDischargedLabel || !!submission?.patientForm?.isDischarged
            )}`}
          </Text>

          <Text
            fontSize={14}
            mb={3}
            color="darkestShade"
            onClick={onShowSubmission}
          >
            {formatPhoneNumber(patient?.phone)}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize={14}>{tableConfigSubmissionDate}</Text>
        </Flex>
      </Flex>
    );

    const renderSubHeaderListMobile = () =>
      submission?.patient &&
      submission?.patient?.phone && (
        <Text
          fontSize={14}
          mt={-3}
          mb={3}
          color="darkestShade"
          onClick={onShowSubmission}
        >
          {formatPhoneNumber(submission?.patient?.phone)}
        </Text>
      );

    return (
      <>
        {isMobile ? (
          <Box width={['100%']}>
            {patient
              ? renderSubHeaderMonitoringMobile()
              : renderSubHeaderListMobile()}
          </Box>
        ) : (
          <Flex
            mb={2}
            flexDirection="row"
            justifyContent={['space-between', '']}
            style={{ marginTop: '-19px' }}
          >
            {submission?.submittedOn && (
              <Flex alignItems="center" flex={1} justifyContent="space-between">
                <Flex>
                  <Text fontSize={14} fontWeight={600}>
                    {format(
                      parseISO(submission?.submittedOn),
                      'dd MMM yyyy, HH:mm'
                    )}
                    {patient &&
                      renderDischargedLabel(
                        submission?.patientForm?.isDischarged
                      )}
                  </Text>
                </Flex>
                <Flex flex="1">
                  <SubmissionHeaderIcon status={submission?.status} />
                </Flex>
              </Flex>
            )}
            {selectedSubmissionUid && (
              <Flex>
                <SubmissionAccordionHeader />
              </Flex>
            )}
          </Flex>
        )}
        <Container
          p={0}
          m={0}
          minWidth={isMobile ? 'auto' : 'fit-content'}
          onClick={onShowSubmission}
          data-testid={`submission_flyout_valid_count--${assertAlertCountEq()}`}
        >
          {isEmpty(submission?.submissionParameters) ? (
            <Text fontSize={14}>-</Text>
          ) : (
            <>
              {mappedSubmissionValues?.filter((o) => o.type[0] === 'danger')
                .length > 0 && (
                <Flex
                  justifyContent="start"
                  style={{
                    width: 'inherit',
                    flexWrap: 'wrap',
                    marginTop: '7px',
                    marginBottom: `${
                      !showSubmission || !isMobile ? '10px' : '15px'
                    }`,
                  }}
                >
                  {mappedSubmissionValues
                    ?.filter((o) => o.type[0] === 'danger')
                    .map((v) => (
                      <Badge
                        type="alert"
                        fontSize={12}
                        marginLeft={0}
                        marginRight={1}
                        marginBottom={1}
                        fontWeight={500}
                        key={v.uid}
                        px={1}
                        py="4px"
                        borderRadius={6}
                      >
                        {`${v?.name}${
                          v.valueType === 'FILE'
                            ? ''
                            : `: ${v?.extractedForDisplay}`
                        }`}
                      </Badge>
                    ))}
                </Flex>
              )}

              {(showSubmission || isMobile) && (
                <AccordionWidget
                  data={mappedSubmissionValues}
                  width="100%"
                  renderBody={(
                    { name, unit, extractedForDisplay, valueType, type },
                    index
                  ) => (
                    <Flex
                      justifyContent="space-between"
                      fontSize={14}
                      data-testid={`submission_accordion_widget-${submission?.uid}-${showSubmission}`}
                    >
                      <Box
                        p={1}
                        width={120}
                        borderLeft={`4px solid ${theme.colors[type[0]]}`}
                        borderTopLeftRadius={index === 0 ? 5 : 0}
                        borderBottomLeftRadius={
                          (mappedSubmissionValues || []).length - 1 === index
                            ? 6
                            : 0
                        }
                      >
                        {name}
                      </Box>
                      <Box
                        p={1}
                        style={{
                          textAlign: 'end',
                        }}
                        width={200}
                      >
                        {valueType?.toUpperCase() === 'FILE' ? (
                          <MarkdownStyled>{extractedForDisplay}</MarkdownStyled>
                        ) : (
                          <Text
                            style={{
                              marginBottom: -5,
                              color: theme.colors.fullShade,
                              fontWeight: 500,
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            <MarkdownStyled>
                              {convertUrlsIntoMarkDownString(
                                extractedForDisplay
                              )}
                            </MarkdownStyled>
                            <span
                              style={{
                                color: theme.colors.darkShade,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                paddingLeft: 3,
                              }}
                            >
                              {unit}
                            </span>
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  )}
                />
              )}
            </>
          )}
        </Container>
      </>
    );
  }
);

export const SubmissionBoxWidget = forwardRef(
  (
    {
      isMobile,
      isSinglePatientView,
      onCommentCreated,
      onShowPatientForm,
      onShowSubmission,
      onSendMessage,
      onDownloadSubmissions,
      onRefresh,
      patient,
      cellData,
      showActionMenu,
      showSidePanel,
      showDischargedLabel,
      submission,
      selectedSubmissionUid,
      clinician,
      tableSettings,
      ...props
    },
    ref
  ) => {
    const resolvedCommentUid = get(
      submission,
      'triggeredAlerts[0].resolvingComment.uid'
    );
    const comments = useMemo(
      () => get(submission, 'comments', []),
      [submission]
    );

    const isResolved = useMemo(
      () => !!comments.find((comment) => comment?.uid === resolvedCommentUid),
      [comments, resolvedCommentUid]
    );

    const { t } = useTranslation();
    const showMoreLabel = (
      <Flex height={30} alignItems="center">
        <FAIcon
          icon={faEllipsisV}
          hover={{ opacity: 0.6 }}
          color="darkestShade"
        />
        <Text ml={1} fontSize={15} fontWeight={600} color="darkestShade">
          More
        </Text>
      </Flex>
    );
    return submission ? (
      <BoxWidget
        mt={3}
        mx={0}
        borderColor="#D5D9DE"
        maxWidth="100%"
        {...props}
        ref={ref}
      >
        <SubmissionDetail
          selectedSubmissionUid={selectedSubmissionUid}
          submission={submission}
          patient={patient}
          showDischargedLabel={showDischargedLabel}
          onShowSubmission={() =>
            showSidePanel
              ? onShowSubmission(
                  submission?.uid ? submission?.uid : null,
                  cellData
                )
              : null
          }
          onCommentCreated={onCommentCreated}
          isMobile={isMobile}
          tableSettings={tableSettings}
          cellData={cellData}
        />
        {!isEmpty(submission) && props?.variant && (
          <CommentSection
            submission={submission}
            onCommentCreated={onCommentCreated}
            onRefresh={onRefresh}
            comments={comments}
            clinician={clinician}
            isResolved={isResolved}
          />
        )}

        {showActionMenu && (
          <>
            <Box height={1} mt={3} width="100%" backgroundColor="mediumShade" />
            <Flex
              pt={2}
              pb={[0, 2]}
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex
                onClick={() =>
                  onShowPatientForm(
                    {
                      ...submission,
                      patient,
                    },
                    'graphs'
                  )
                }
              >
                <FAIcon
                  icon={faChartBar}
                  style={{ fontSize: 20 }}
                  color="darkestShade"
                  cursor="pointer"
                />
                <Text
                  ml={1}
                  fontSize={15}
                  fontWeight={600}
                  color="darkestShade"
                >
                  Graphs
                </Text>
              </Flex>
              <ActionsCell
                t={t}
                isSinglePatientView={isSinglePatientView}
                onShowPatientForm={(data, path) =>
                  onShowPatientForm(
                    {
                      ...submission,
                      patient,
                    },
                    path
                  )
                }
                onSendMessage={() => {
                  if (
                    cellData &&
                    !!cellData.patientForm &&
                    has(cellData, 'patientForm')
                  ) {
                    onSendMessage(cellData?.patientForm);
                  } else {
                    onSendMessage(cellData);
                  }
                }}
                onDownloadSubmissions={() => onDownloadSubmissions(cellData)}
                label={showMoreLabel}
              />
            </Flex>
          </>
        )}
      </BoxWidget>
    ) : null;
  }
);

export const SidePanelLoading = (props) => (
  <ContentLoader
    speed={2}
    width={450}
    height={900}
    viewBox="0 0 450 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="7" y="9" rx="2" ry="2" width="328" height="36" />
    <rect x="7" y="53" rx="2" ry="2" width="224" height="16" />
    <rect x="7" y="86" rx="2" ry="2" width="400" height="196" />
    <rect x="7" y="301" rx="0" ry="0" width="399" height="196" />
    <rect x="231" y="396" rx="0" ry="0" width="4" height="14" />
    <rect x="190" y="357" rx="0" ry="0" width="6" height="6" />
    <rect x="283" y="363" rx="0" ry="0" width="1" height="0" />
    <rect x="177" y="197" rx="0" ry="0" width="4" height="8" />
    <rect x="7" y="523" rx="0" ry="0" width="398" height="196" />
    <rect x="149" y="606" rx="0" ry="0" width="7" height="4" />
    <rect x="121" y="617" rx="0" ry="0" width="8" height="3" />
    <rect x="7" y="740" rx="0" ry="0" width="399" height="181" />
    <rect x="206" y="812" rx="0" ry="0" width="1" height="15" />
  </ContentLoader>
);

const SidePanel = ({
  submissionUid,
  selectedSubmission,
  patientFormUid,
  showEmpty,
  open,
  onClose,
  onShowPatientForm,
  onSendMessage,
  clinician,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const {
    queries: { useSubmissions, usePatientForm },
  } = useApi({
    queries: ['useSubmissions', 'usePatientForm'],
  });
  const isResponsive = useMediaQuery({ query: '(max-width: 1002px)' });

  const [showLoader, setShowLoader] = useState(true);

  const { data: patientForm } = usePatientForm({
    enabled: open && !!patientFormUid && !showEmpty,
    variables: {
      uid: patientFormUid,
    },
  });

  const patient = get(patientForm, 'patient');

  const {
    data: patientSubmissionsData = [],
    refetch: refetchSubmissions,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isPreviousData,
    isFetched,
  } = useSubmissions({
    cacheTime: 0,
    enabled: open && !!patientFormUid && !showEmpty,
    variables: {
      enabled: !!patientFormUid,
      patientFormUids: [patientFormUid],
      orderField: 'SUBMITTED_ON',
      orderDesc: true,
      first: 10,
    },
  });

  const patientSubmissions = get(patientSubmissionsData, 'pages', []).flatMap(
    (page) => page
  );

  const patientSubmissionsUids = patientSubmissions.map(({ uid }) => uid);
  if (!patientSubmissionsUids.includes(selectedSubmission.uid)) {
    patientSubmissions.push(selectedSubmission);
  }

  const [submissionWithAlerts, submissionWithoutAlerts] = partition(
    patientSubmissions,
    (item) => {
      const { isAlertsTriggered, isAlertsResolved } = item;
      return isAlertsTriggered && !isAlertsResolved;
    }
  );

  const submissionRefs = useMemo(
    () =>
      patientSubmissions.reduce((acc, item) => {
        acc[item.uid] = React.createRef();
        return acc;
      }, {}),
    [patientSubmissions]
  );

  const scrollToSubmission = useCallback(
    (timeout) => {
      setTimeout(() => {
        const ref = get(submissionRefs, `[${submissionUid}].current`, null);
        if (ref) {
          ref.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, timeout);
    },
    [submissionRefs, submissionUid]
  );

  const sortSubmissions = (data) =>
    data.sort((p1, p2) =>
      compareDesc(parseISO(p1.submittedOn), parseISO(p2.submittedOn))
    );

  useEffect(() => {
    const fetchPage = async () => {
      try {
        if (!isFetched && patientFormUid && fetchNextPage) {
          await fetchNextPage();
        }
      } finally {
        setShowLoader(false);
      }
    };

    // fetch first page
    if (isFetched) {
      setShowLoader(false);
      return;
    }
    fetchPage();
  }, [fetchNextPage, isFetched, patientFormUid, submissionUid]);

  const onCommentCreated = () =>
    refetchSubmissions().then(async () => {
      if (onRefresh) {
        await onRefresh();
      }
    });

  const showSidePanelLoading = showLoader || isPreviousData || !open;

  useEffect(() => {
    if (patientFormUid && !showSidePanelLoading) {
      scrollToSubmission(300);
    }
  }, [patientFormUid, scrollToSubmission, showSidePanelLoading]);

  const close = () => {
    onClose();
    setShowLoader(true);
  };

  const renderBadge = (num) => (
    <Badge borderRadius={8} minWidth={23} backgroundColor="#E8EAED" height={24}>
      <Text color="fullShade" fontWeight={600} fontSize={12} textAlign="center">
        {num?.toLocaleString()}
      </Text>
    </Badge>
  );

  const settingsMenuOptions = [
    {
      label: 'View graphs',
      icon: <FAIcon icon={faChartBar} fontSize="14px" color="darkestShade" />,
      action: () => {
        onShowPatientForm(patientForm, 'graphs');
      },
      hidden: false,
    },
    {
      label: 'View submissions',
      icon: <FAIcon icon={faFileAlt} fontSize="14px" color="darkestShade" />,
      action: () => {
        onShowPatientForm(patientForm, 'submissions');
      },
      hidden: false,
    },
    {
      label: 'Send message',
      icon: <FAIcon icon={faCommentAlt} fontSize="14px" color="darkestShade" />,
      action: () => {
        onSendMessage(patientForm);
      },
      hidden: false,
    },
  ];

  const filterOutEmptyAlerts = (submissions) => {
    return submissions.filter((submission) => submission?.parameters?.length);
  };

  return (
    <Drawer
      width={['100%', 520]}
      p={[2, 4]}
      open={open}
      onClose={close}
      style={{
        boxSizing: 'border-box',
      }}
      data-testid="side_panel_drawer"
    >
      {showSidePanelLoading ? (
        <SidePanelLoading />
      ) : (
        <>
          <Box data-testid="side_panel_box_header">
            <Box width="100%">
              <Flex justifyContent="space-between">
                {patient && (
                  <H2
                    width="70%"
                    pr="8px"
                    data-testid="side_panel_box_header_patient_name"
                  >
                    {patient?.name}
                    {patientForm?.isDischarged && ` (${t('Discharged')})`}
                  </H2>
                )}

                <Flex width="30%" justifyContent="flex-end" cursor="pointer">
                  <Flex
                    height={36}
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    ml={['auto', -50]}
                  >
                    <SettingsActionMenu
                      options={settingsMenuOptions}
                      isMobile={isResponsive}
                      buttonLabelIcon={faEllipsisH}
                      actionMenuTextProps={{
                        display: 'flex',
                      }}
                      buttonProps={{
                        width: 30,
                        height: 35,
                        mr: 1,
                        border: 0,
                      }}
                    />

                    <FAIcon
                      icon={faTimes}
                      style={{ fontSize: 20 }}
                      color="fullShade"
                      data-testid="side_panel_box_header_icon_close"
                      onClick={close}
                    />
                  </Flex>
                </Flex>
              </Flex>

              {patient && (
                <Body
                  mt={2}
                  color="darkestShade"
                  data-testid="side_panel_box_header_patient_phone"
                >
                  {formatPhoneNumber(patient?.phone)}
                </Body>
              )}
            </Box>
          </Box>
          <Box mt={4}>
            <Flex>
              <Text fontSize="18px" mr={1} fontWeight={600} color="fullShade">
                {t('Unresolved Alerts')}
              </Text>
              {renderBadge(
                submissionWithAlerts ? submissionWithAlerts?.length : 0
              )}
            </Flex>

            {get(submissionWithAlerts, 'length', 0) === 0 ? (
              <AlertsEmptyView />
            ) : (
              sortSubmissions(submissionWithAlerts).map((item) => (
                <SubmissionBoxWidget
                  selectedSubmissionUid={submissionUid}
                  ref={submissionRefs[item.uid]}
                  t={t}
                  key={item.uid}
                  patient={patient}
                  submission={item}
                  variant={item.statusColor}
                  onCommentCreated={onCommentCreated}
                  onShowPatientForm={onShowPatientForm}
                  onSendMessage={onSendMessage}
                  onRefresh={onRefresh}
                  clinician={clinician}
                />
              ))
            )}
          </Box>
          <Box my={4}>
            <Flex>
              <Text fontSize="18px" mr={1} fontWeight={600} color="fullShade">
                {t('History')}
              </Text>
              {renderBadge(
                submissionWithoutAlerts
                  ? filterOutEmptyAlerts(submissionWithoutAlerts)?.length
                  : 0
              )}
            </Flex>

            {sortSubmissions(filterOutEmptyAlerts(submissionWithoutAlerts)).map(
              (item) => (
                <SubmissionBoxWidget
                  selectedSubmissionUid={submissionUid}
                  t={t}
                  ref={submissionRefs[item?.uid]}
                  key={item?.uid}
                  submission={item}
                  variant={item?.statusColor}
                  onCommentCreated={onCommentCreated}
                  onRefresh={onRefresh}
                  clinician={clinician}
                />
              )
            )}

            {hasNextPage && (
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="center"
                mt={2}
                mb={3}
              >
                <LoadMoreButton
                  onClick={fetchNextPage}
                  disabled={isFetchingNextPage}
                >
                  <Text color="darkestShade" fontSize={14} fontWeight={600}>
                    {!isFetchingNextPage && t('Load more')}
                    {isFetchingNextPage && `${t('Loading')}...`}
                  </Text>
                </LoadMoreButton>
              </Flex>
            )}
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default SidePanel;

const AlertsEmptyView = withTranslation()(
  ({
    avatar = EmptyAlertsBotAvatar,
    t,
    title = 'Well done!',
    description = 'You have no unresolved alerts!',
    avatarProps = {},
  }) => (
    <Flex
      mt={30}
      p={3}
      alignItems="center"
      border={`1px solid ${theme.colors.mediumShade}`}
      borderRadius={8}
    >
      <Box
        as="img"
        mr={2}
        src={avatar}
        height={48}
        width={48}
        alt="Not Alerts Bot Avatar"
        {...avatarProps}
      />
      <Flex flexDirection="column" justifyContent="center">
        <H5>{t(title)}</H5>
        <Body small>{t(description)}</Body>
      </Flex>
    </Flex>
  )
);
