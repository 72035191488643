import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { capitalize, get, truncate } from 'lodash';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Flex,
  useApi,
  H5,
  H3,
  Body,
  theme,
  FAIcon,
  SecondaryOutlinedButton,
  BoxWidget,
  Drawer,
  LogEvent,
  Text,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Tooltip,
} from '@fivehealth/botero';

import { replaceAll } from 'AppUtils';
import Table, {
  BoxContent,
  defaultHeaderProps,
  HeaderColumn,
  Styles,
  TableCell,
  TableHeadCellCustom,
} from 'components/Table/Table';
// import Filter from 'components/Filter/Filter';
import TableLoader from 'components/Table/TableLoader';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import NoResults from 'components/NoResults/NoResults';
import { Filters } from 'components/Filters/Filters';

export const DEFAULT_PARAMS = {
  orderField: 'DATE',
  orderDesc: false,
  first: 15,
};

const PreStyle = {
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
};

const StatusCell = ({ variant }) => {
  const [show, setShow] = useState(false);
  return (
    <Tooltip
      show={show}
      tooltip={<Text color="white">{variant.tooltipContent}</Text>}
    >
      <Box
        pl="30px"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <StatusBadge
          text={capitalize(variant.status)}
          color={variant.statusColor}
        />
      </Box>
    </Tooltip>
  );
};

const Messages = ({
  // tab with filter
  t,
  requestParams,
  // showFilters,
  onFetchData,
  // onFilterOpen,
  // onFilterCancel,
  onApplyFilters,
  // onResetFilters,
  onCount,
  patientUid,
}) => {
  const history = useHistory();

  const {
    queries: { useCurrentUser, useClinic, usePatientMessages },
  } = useApi({
    queries: ['useCurrentUser', 'useClinic', 'usePatientMessages'],
  });

  const { data: clinic } = useClinic();
  const { data: currentUser } = useCurrentUser();

  const variables = {
    ...requestParams,
    patientUid,
  };

  // Not needed in request params
  delete variables?.clinicianUid;
  delete variables?.patientFormUids;

  const {
    data: patientMessages,
    isLoading: loadingPatientMessages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetched,
    filters: { directions },
  } = usePatientMessages({
    variables,
  });

  const tableData = get(patientMessages, 'pages', [])
    .flatMap((page) => page || [])
    .filter(Boolean);

  const onCallBack = useCallback(() => {
    onCount(patientMessages?.totalCount || 0);
  }, [onCount, patientMessages]);

  useEffect(() => {
    onCallBack();
  }, [onCallBack]);

  const filterOptions = [directions].filter(Boolean);
  const [sidePanelData, setSidePanelData] = useState();

  const getSender = (rowData) => {
    if (rowData.sender.isBot === true) {
      return 'Bot';
    }

    if (rowData.sender?.patient?.uid) {
      return 'Patient';
    }

    if (rowData.sender?.clinician?.uid) {
      return `Manually triggered by Dr\n${rowData.sender?.clinician?.name}`;
    }

    return 'N/A';
  };

  const getMessageSummary = (rowData) => {
    const trucLimit = 100;
    const {
      message: { message },
    } = rowData;

    if (message?.type === 'text') {
      return message?.text || '-';
    }

    return truncate(
      replaceAll(
        replaceAll(
          replaceAll(JSON.stringify(message, null, 2), '"', ''),
          '{',
          ''
        ),
        '}',
        ''
      ),
      {
        length: trucLimit,
      }
    );
  };

  const getVariant = (status) => {
    switch (status) {
      case 'delivered':
      case 'read':
      case 'seen':
        return {
          status: capitalize(status.split('_').join(' ')),
          statusColor: 'success',
          tooltipContent: 'Chat app has delivered the message to the patient',
        };
      case 'post_failed':
      case 'failed':
        return {
          status: capitalize(status.split('_').join(' ')),
          statusColor: 'danger',
          tooltipContent: 'Message delivery has failed',
        };
      case 'received':
        return {
          status: 'Received',
          statusColor: 'success',
          tooltipContent: 'Patient has received the message',
        };
      default:
        return {
          status: capitalize((status || 'Unknown').split('_').join(' ')),
          statusColor: 'default',
          tooltipContent:
            'Chat platform provider has received the message from Bot MD',
        };
    }
  };

  const columns = [
    {
      id: 'DATE',
      width: 80,
      Header: t('Date'),
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ row: { original: rowData } }) => (
        <BoxContent>
          <Body small>
            {moment(rowData.createdOn).format('DD MMM YYYY HH:mm')}
          </Body>
        </BoxContent>
      ),
    },
    {
      id: 'CHANNEL',
      Header: t('Channel'),
      width: 80,
      disableSortBy: true,
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ row: { original: rowData } }) => (
        <BoxContent>
          <Body small>{capitalize(rowData.communicationMethod)}</Body>
        </BoxContent>
      ),
    },
    {
      id: 'DIRECTION',
      Header: t('Direction'),
      width: 40,
      disableSortBy: true,
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ row: { original: rowData } }) => (
        <BoxContent>
          <Body small>{capitalize(rowData.direction)}</Body>
        </BoxContent>
      ),
    },
    {
      id: 'SENDER',
      Header: t('Sender'),
      maxWidth: 50,
      disableSortBy: true,
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ row: { original: rowData } }) => (
        <BoxContent>
          <Body small>{getSender(rowData)}</Body>
        </BoxContent>
      ),
    },
    {
      id: 'SUMMARY',
      Header: t('Message summary'),
      width: 300,
      disableSortBy: true,
      // TODO: Refactor this to avoid react/no-unstable-nested-components error
      /* eslint-disable react/no-unstable-nested-components */
      Cell: ({ row: { original: rowData } }) => (
        <BoxContent>
          <Body small>
            <pre style={PreStyle}>{getMessageSummary(rowData)}</pre>
          </Body>
        </BoxContent>
      ),
    },
    {
      id: 'STATUS',
      width: 50,
      Header: t('Status'),
      Cell: ({ row: { original: rowData } }) => {
        const variant = getVariant(rowData?.status);
        return <StatusCell variant={variant} />;
      },
    },
  ];

  const showSidePanel = (data) => {
    setSidePanelData(data);
  };

  const renderMobileViews = useCallback((node) => {
    const variant = getVariant(node.status);

    return (
      <BoxWidget
        key={node?.uid}
        width="100%"
        mx={0}
        mb={16}
        borderColor="#D5D9DE"
        cursor="pointer"
        onClick={() => {
          showSidePanel(node);
        }}
        variant={variant.statusColor}
        titleComponent={
          <H5>{moment(node.createdOn).format('DD MMM YYYY HH:mm')}</H5>
        }
      >
        <Flex mt={1} mb={1}>
          <Body pr={1} color="fullShade">
            Channel:
          </Body>
          <Body small color="fullShade">
            {capitalize(node?.communicationMethod)}
          </Body>
        </Flex>
        {getMessageSummary(node)}
      </BoxWidget>
    );
  }, []);

  const sidePanel = useCallback(() => {
    const variant = getVariant(sidePanelData?.status);
    return (
      <Drawer
        width={['100%', 456]}
        open
        pb={2}
        onClose={() => setSidePanelData()}
        style={{
          boxSizing: 'border-box',
        }}
      >
        <Box height={6} bg={variant.statusColor} />

        <Flex
          mt={2}
          pl={3}
          pr={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <H3 color="fullShade">Message</H3>
          <Body
            small
            cursor="pointer"
            color="fullShade"
            onClick={() => {
              setSidePanelData();
            }}
          >
            <FAIcon icon={faTimes} color="#000" />
          </Body>
        </Flex>

        <Box pl={3} mt={3}>
          <Flex alignItems="flex-start">
            <Box width={100}>
              <H5 color="darkestShade" mb={3} mr={3}>
                Date
              </H5>
            </Box>
            <Body
              display="flex"
              alignItems="flex-start"
              small
              color="fullShade"
            >
              {moment(sidePanelData?.createdOn).format('DD MMM YYYY, HH:mm')}
            </Body>
          </Flex>

          <Flex alignItems="flex-start">
            <Box width={100}>
              <H5 color="darkestShade" mb={3} mr={3}>
                Channel
              </H5>
            </Box>
            <Body small color="fullShade">
              {capitalize(sidePanelData?.communicationMethod)}
            </Body>
          </Flex>

          <Flex alignItems="flex-start">
            <Box width={100}>
              <H5 color="darkestShade" mb={3} mr={3}>
                Direction
              </H5>
            </Box>
            <Body small color="fullShade">
              {sidePanelData?.direction}
            </Body>
          </Flex>

          <Flex alignItems="flex-start">
            <Box width={100}>
              <H5 color="darkestShade" mb={3} mr={3}>
                Sender
              </H5>
            </Box>
            <Body small color="fullShade">
              {getSender(sidePanelData)}
            </Body>
          </Flex>

          <Flex alignItems="flex-start">
            <Box width={100}>
              <H5 color="darkestShade" mb={3} mr={3}>
                status
              </H5>
            </Box>
            <Body small color="fullShade">
              <Box ml={-1}>
                <StatusBadge
                  text={capitalize(variant.status)}
                  color={variant.statusColor}
                />
              </Box>
            </Body>
          </Flex>

          <H5 mt={2} color="darkestShade" mb={2}>
            Message content
          </H5>
          <Box
            width="90%"
            pl={1}
            mb={2}
            border={`2px solid ${theme.colors.mediumShade}`}
            borderRadius={5}
            fontSize={12}
          >
            <pre style={PreStyle}>
              {replaceAll(
                JSON.stringify(sidePanelData?.message?.message, null, 2),
                '"',
                ''
              )}
            </pre>
          </Box>

          <H5 mt={2} color="darkestShade" mb={2}>
            Metadata
          </H5>
          <Box
            width="90%"
            pl={1}
            border={`2px solid ${theme.colors.mediumShade}`}
            borderRadius={5}
            fontSize={12}
          >
            <pre style={PreStyle}>
              {replaceAll(
                JSON.stringify(sidePanelData.metadata, null, 2),
                '"',
                ''
              )}
            </pre>
          </Box>
        </Box>
      </Drawer>
    );
  }, [sidePanelData]);

  const renderTable = ({
    rows,
    prepareRow,
    tableStyle,
    // onFetchNextPage,
    getTableProps,
    getTableBodyProps,
    getHeaderProps,
    getHeaderGroupProps = () => {},
    getRowProps,
    getColumnProps,
    getCellProps,
    headerGroups,
    logEventProps,
    headerCellProps,
    containerStyle = {},
    // onRowClick,
    hasStickyColumn,
  }) => (
    <Box style={containerStyle}>
      <LogEvent
        logEventProps={logEventProps}
        actionProps={{
          onLoad: { action: 'load' },
          onClick: { action: 'click' },
        }}
        elementType="table"
      >
        <Styles>
          <TableContainer
            width="100%"
            minWidth={[null, theme.breakpoints[2]]}
            textAlign="left"
            {...getTableProps()}
            style={tableStyle}
          >
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRow
                  hasStickyColumn={hasStickyColumn}
                  {...headerGroup.getHeaderGroupProps()}
                  {...getHeaderGroupProps()}
                  key={`table_row_${index}`}
                >
                  {headerGroup.headers.map((column, cIndex) => (
                    <TableHeadCellCustom
                      py={2}
                      {...column.getHeaderProps([
                        defaultHeaderProps(column, getHeaderProps(column)),
                        getColumnProps(column),
                      ])}
                      key={`table_cell_${cIndex}`}
                    >
                      <HeaderColumn {...headerCellProps}>
                        <Box>
                          <Text
                            color="darkestShade"
                            fontWeight={600}
                            fontSize={14}
                            cursor={column.canSort ? 'pointer' : 'default'}
                          >
                            {column.render('Header')}
                          </Text>
                        </Box>
                      </HeaderColumn>
                    </TableHeadCellCustom>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <TableRow
                    style={{
                      cursor: 'pointer',
                    }}
                    {...row.getRowProps(getRowProps(row))}
                    isSelected={row.isSelected}
                    onClick={() => {
                      showSidePanel(row.original);
                    }}
                    key={`table_row_${index}`}
                    data-testid={`table_row_data_${index}`}
                    id={`table_row_data-${row.original.uid}`}
                  >
                    {row.cells.map((cell, cIndex) => (
                      <TableCell
                        key={`table_cell_${cIndex}`}
                        id={`uid:${row.original.uid}`}
                        data-testid={`table_cell_data_${cIndex}`}
                        {...cell.getCellProps([
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                        py={2}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableContainer>
        </Styles>
      </LogEvent>
    </Box>
  );

  const onFilterChange = useCallback(
    (filters) => {
      onApplyFilters?.(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box>
      <Filters
        requestParams={requestParams}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
      />

      {(!clinic || loadingPatientMessages) && <TableLoader />}

      {!loadingPatientMessages && (
        <Box py={[4]} display="flex" flexDirection="column" alignItems="center">
          <Box width="100%" display={['none', 'none', 'initial']}>
            <Table
              isFetched={isFetched}
              onFetchData={onFetchData}
              data={tableData}
              currentUser={currentUser}
              history={history}
              columns={columns}
              initialSortBy={DEFAULT_PARAMS}
              renderTable={renderTable}
            />
          </Box>
          <Box display={['block', 'block', 'none']} width="100%">
            {tableData.length > 0 &&
              tableData.map((node) => renderMobileViews(node))}
            {tableData.length === 0 && <NoResults />}
          </Box>

          {hasNextPage && (
            <Box textAlign="center" pt={2}>
              <SecondaryOutlinedButton onClick={fetchNextPage}>
                {isFetchingNextPage ? 'Loading...' : 'Load more'}
              </SecondaryOutlinedButton>
            </Box>
          )}
          {sidePanelData && sidePanel()}
        </Box>
      )}
    </Box>
  );
};

export default Messages;
