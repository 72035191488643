import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation stitchCreateUploadUrl($input: StitchCreateUploadUrlInput!) {
    stitchCreateUploadUrl(input: $input) {
      fields
      uploadId
      url
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'stitchCreateUploadUrl',
  keepPreviousData: true,
});
