import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientChart(
    $patientFormUidIn: [String!]
    $before: DateTime
    $after: DateTime
  ) {
    cleoPatientChart(
      patientFormUidIn: $patientFormUidIn
      before: $before
      after: $after
    ) {
      statistics {
        submissionTimes
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientCharts',
  keepPreviousData: true,
  select: ({ cleoPatientChart }) => cleoPatientChart,
});
