/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

export const GRAPHQL_DOCUMENT_DELIVERIES_EXPORT = gql`
  query cleoDeliveriesExport(
    $outputFormat: ExportOutputFormat!
    $clinicianUid: String
    $patientGroupUidIn: [String!]
    $patientUid: String
  ) {
    cleoDeliveries(
      clinicianUid: $clinicianUid
      patientGroupUidIn: $patientGroupUidIn
      patientUid: $patientUid
    ) {
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`;

export const GRAPHQL_DOCUMENT_DELIVERIES_EXPORT_EMAIL = gql`
  query cleoDeliveriesExport(
    $email: String
    $outputFormat: ExportOutputFormat!
    $patientGroupUidIn: [String!]
    $patientUid: String
  ) {
    cleoDeliveries(
      patientGroupUidIn: $patientGroupUidIn
      patientUid: $patientUid
    ) {
      exportByEmail(outputFormat: $outputFormat, email: $email)
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_DELIVERIES_EXPORT,
  queryType: 'query',
  baseQueryKey: 'deliveriesExport',
  select: (data) => get(data, 'cleoDeliveries'),
  keepPreviousData: true,
});
