import {
  Box,
  Flex,
  H2,
  SecondaryOutlinedButton,
  PrimaryButton,
  FAIcon,
  Text,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { customerSupportEmail } from 'AppUtils';
import { Colors } from 'constants';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useModal } from './ModalContext';

export const ErrorModalContext = React.createContext({
  updateErrorState: (content = null) => content,
});

export const useErrorModal = () => {
  const context = React.useContext(ErrorModalContext);
  if (!context) {
    throw new Error('useErrorModal is outside ErrorModalProvider');
  }
  return context;
};

export const ErrorModalProvider = memo(({ children }) => {
  const [error, setError] = useState(false);
  const [content, setContent] = useState();

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const { openModal, closeModal } = useModal();

  const { t } = useTranslation();

  const updateErrorState = useCallback(async (contentNode = null) => {
    setError(true);
    setContent(contentNode);
  }, []);

  const value = useMemo(() => ({ updateErrorState }), [updateErrorState]);

  useEffect(() => {
    if (error) {
      openModal(
        <Box>
          <Flex justifyContent="space-between" alignItems="center" mb={6}>
            <H2 color={Colors.red}>{t('Error')}</H2>
            <Box cursor="pointer" onClick={closeModal}>
              <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
            </Box>
          </Flex>
          {!content && (
            <>
              <Text color={Colors.red} fontWeight={400} fontSize={2} mb={2}>
                {t(
                  'An error occured while removing patients, no patients were removed.'
                )}
              </Text>

              <Text color={Colors.red} mb={4}>
                {t('Please try again, if the issue persist contact us')}
              </Text>
            </>
          )}

          {content && content}
          <Flex justifyContent="end">
            <SecondaryOutlinedButton
              mr={2}
              onClick={() => {
                setError(false);
                closeModal();
              }}
            >
              {t('OK')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              onClick={() => {
                closeModal();
                customerSupportEmail();
              }}
            >
              {t('Yes, Contact us')}
            </PrimaryButton>
          </Flex>
        </Box>,
        {
          style: {
            width: isMobile ? '100%' : '30%',
          },
        }
      );
    }
  }, [error, closeModal, content, isMobile, openModal, t]);

  return (
    <ErrorModalContext.Provider value={value}>
      {children}
    </ErrorModalContext.Provider>
  );
});
