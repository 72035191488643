import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientImport($input: CleoPatientImportInput!) {
    cleoPatientImport(input: $input) {
      importResults {
        rowNo
        patient {
          uid
          name
          phone
          email
          communicationMethod
          identifier
          gender
          dateOfBirth
        }
        isNewPatient
      }
      previewResults {
        id
        duplicateEntries {
          id
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        newEntries {
          id
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        existingEntries {
          id
          identifier
          isPatientActivated
          isPatientFormActivated
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        errorEntries {
          id
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientsImport',
  keepPreviousData: false,
});
