import React, { memo } from 'react';
import { FAIcon } from '@fivehealth/botero';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import { IconProps } from './IconProps';

const Email = memo<IconProps>(({ color, width = 15, height = 15 }) => (
  <FAIcon icon={faEnvelope} style={{ width, height, color }} />
));

export default Email;
