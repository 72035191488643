import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientFormEnrollDischarge(
    $input: CleoPatientFormEnrollDischargeInput!
  ) {
    cleoPatientFormEnrollDischarge(input: $input) {
      results {
        patientForm {
          uid
        }
        isPreviouslyEnrolled
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientFormEnrollDischarge',
  keepPreviousData: false,
});
