import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';

export const PATIENT_GRAPHQL_DOCUMENT = gql`
  query cleoPatientWithUid($uid: String, $createdOnGte: DateTime) {
    cleoPatient(uid: $uid) {
      patientEvents(createdOn_Gte: $createdOnGte) {
        totalCount
        edges {
          node {
            uid
          }
        }
      }
    }
  }
`;

const useGetPatientEventsCount = () => (args) => {
  const patients = createQuery({
    gqlDocument: PATIENT_GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientEventsCount',
    select: (data) => get(data, 'cleoPatient'),
    keepPreviousData: false,
  });
  return patients(args);
};
// eslint-disable-next-line react-hooks/rules-of-hooks
export default useGetPatientEventsCount();
