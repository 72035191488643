import React from 'react';
import { Box, Text, H3 } from '@fivehealth/botero';

const ChartHeaderStat = ({ value, subtitle, unit }) => (
  <Box
    flex={0.22}
    p={2}
    mr={[2, 0]}
    borderRadius={8}
    style={{
      border: '1px solid #D5D9DE',
      boxSizing: 'border-box',
    }}
  >
    <H3 fontSize={[18, 24]} style={{ lineHeight: '18px' }}>
      {value || '—'}{' '}
      {unit && (
        <span>
          <Text fontSize={[14, 16]} style={{ display: 'inline' }}>
            {unit}
          </Text>
        </span>
      )}
    </H3>
    <Text fontSize={[11, 14]} color="darkestShade" mt={1}>
      {subtitle}
    </Text>
  </Box>
);

export default ChartHeaderStat;
