import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoPatientImportAsync($input: CleoPatientImportAsyncInput!) {
    cleoPatientImportAsync(input: $input) {
      asyncJob {
        uid
        status
      }

      importResults {
        rowNo
        isNewPatient
        isNewPatientForm

        patient {
          uid
          name
          identifier
        }

        patientForm {
          uid
          monitoringForm {
            name
          }
        }
      }

      previewResults {
        id
        duplicateEntries {
          id
          rowNo
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        newEntries {
          id
          rowNo
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        existingEntries {
          id
          rowNo
          identifier
          isPatientActivated
          isPatientFormActivated
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
        errorEntries {
          id
          rowNo
          identifier
          name
          phone
          email
          dateOfBirth
          gender
          errors
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'patientsImportAsync',
  keepPreviousData: false,
});
