import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissionsUnresolvedCount {
    cleoSubmissionCount(isAlertsResolved: false) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'submissionsUnresolvedCount',
  select: (data) => get(data, 'cleoSubmissionCount.totalCount', 0),
  keepPreviousData: true,
  cacheTime: 1000 * 60 * 10,
  Staletime: 1000 * 60 * 10,
});
