import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { clinicianFieldsFragment } from './useClinicians';

const GRAPHQL_DOCUMENT = gql`
  mutation cleoClinicianUpdate(
    $input: UpdateCleoClinicianInput!
  ) {
    cleoClinicianUpdate(input: $input) {
      cleoClinician {
        ${clinicianFieldsFragment}
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'clinician',
  keepPreviousData: true,
});
