import React, { useEffect, useRef, useState } from 'react';
import {
  ActionMenu,
  Flex,
  Box,
  FAIcon,
  theme,
  Radio,
  Label,
  H5,
} from '@fivehealth/botero';
import { faSortAmountDown } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

// TODO: Fix and revamp this whole component to improve performance.
const Sort = ({
  options,
  selectedOption,
  onChange: onOptionChange,
  onChecked,
  onClose,
  title,
  value,
}) => {
  const [selection, setSelection] = useState({});
  const [optionList, setOptionList] = useState([]);
  const dropdownRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOption) setSelection(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    // NOTE: For faster perfomance use a local state copy of the options
    if (options) setOptionList([...options]);
  }, [options]);

  const defaultValue = value || {};
  const onSetDropDownRef = (ref) => {
    dropdownRef.current = ref;
  };

  const handleOnClose = (option) => {
    if (onClose) {
      onClose(option);
    }
    setTimeout(() => {
      // TODO: Closing of popup should be async or manually called.
      if (dropdownRef) dropdownRef?.current?.setOpen(false);
    }, 100);
  };

  const onChange = (option) => {
    setSelection({ ...option });
    handleOnClose(option);
    // NOTE: Add some delay in persisting the state and api invocation.
    onOptionChange(option);
  };

  return (
    <ActionMenu
      dropdownRef={onSetDropDownRef}
      label={
        <Flex
          width={43}
          height={43}
          justifyContent="center"
          alignItems="center"
          ml={1}
          style={{
            border: `1px solid ${theme.colors.mediumShade}`,
            borderRadius: theme.space[1],
          }}
        >
          <FAIcon icon={faSortAmountDown} fontSize={24} />
        </Flex>
      }
    >
      <Box py={2}>
        <H5 mb={1} px={2}>
          {t(title) || t('Sort patients by')}
        </H5>
        {optionList &&
          optionList?.map((option, index) => (
            <Box key={`radio_btn_list_key-${option.id}-${index}`} py={1} px={2}>
              <Label fontSize={14}>
                <Radio
                  name="sort"
                  id={option.id}
                  checked={
                    onChecked
                      ? selection.id === option.id
                      : defaultValue.orderField === option.id &&
                        defaultValue.orderDesc === option.desc
                  }
                  onChange={() => {
                    onChange(option);
                  }}
                />
                {t(option.title)}
              </Label>
            </Box>
          ))}
      </Box>
    </ActionMenu>
  );
};

Sort.defaultProps = {
  options: [
    { title: 'Patient name A-Z', id: 'PATIENT_NAME', desc: false },
    { title: 'Patient name Z-A', id: 'PATIENT_NAME', desc: true },
    {
      title: 'Submitted on (newest to oldest)',
      id: 'SUBMITTED_ON',
      desc: true,
    },
    {
      title: 'Submitted on (oldest to newest)',
      id: 'SUBMITTED_ON',
      desc: false,
    },
  ],
};

export default Sort;
