import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  H2,
  Body,
  PrimaryButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  Text,
  H6,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useCookies } from 'react-cookie';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ExportDataDestination from 'components/ExportData/ExportDataDestination';
import exportAnimation from '../../assets/file-export-success.json';
import { validateEmail } from '../../AppUtils';
import Config from '../../Config';
import { EXPORT_DATA_TYPE } from '../../constants';
import { SelectWithLabel } from '../Select';

const SubTitle = (props) => (
  <H6 pt="48px" fontSize="16px" color="FullShade" {...props} />
);

const ExportDataModal = ({
  closeModal,
  title,
  text,
  onSubmit,
  data,
  prompt = true,
  showBody = true,
  showExportDataFormats = true,
  showExportDestination = true,
  dataFormats,
  dataType: dataTypeProp,
  dataOptions,
  defaultDestination,
  showEmailDestination,
  showDownloadDestination,
  ...props
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [animationSegments, setAnimationSegments] = useState([0, 17]);
  const [exportDataType, setExportDataType] = useState('');
  const [outputFormat, setOutputFormat] = useState(data?.outputType || '');
  const [exportDestination, setExportDestination] = useState({});
  const [isReadyToExport, setIsReadyToExport] = useState(false);
  const [visibleEmailDestination, setVisibleEmailDestination] =
    useState(showEmailDestination);
  const [exportDataFormats, setExportDataFormats] = useState(null);
  const { t } = useTranslation();
  const [cookies] = useCookies([Config.cookie.name]);
  const sessionToken = cookies && cookies[Config.cookie.name];
  const defaultValues = {};

  const isExportDataGraphs = useMemo(
    () =>
      dataTypeProp === EXPORT_DATA_TYPE.tables ||
      dataTypeProp === EXPORT_DATA_TYPE.graphs,
    [dataTypeProp]
  );

  const isExportDataPatients = useMemo(
    () => dataTypeProp === EXPORT_DATA_TYPE.patients,
    [dataTypeProp]
  );

  const handleExportDataGraph = useCallback(() => {
    // NOTE: This is only for patient graph/table expors
    if (isExportDataGraphs) {
      setIsReadyToExport(false);
      if (exportDataType === EXPORT_DATA_TYPE.graphs) {
        const filteredFormats = dataFormats.filter((o) => o.value === 'pdf');
        setExportDataFormats(filteredFormats);
        setVisibleEmailDestination(false);
        setIsReadyToExport(outputFormat === 'pdf');
      }
      if (exportDataType === EXPORT_DATA_TYPE.tables) {
        setExportDataFormats(dataFormats);
        const isOutputFormatCsv = outputFormat === 'csv';
        setVisibleEmailDestination(isOutputFormatCsv);

        if (isOutputFormatCsv) {
          if (exportDestination?.email) {
            setIsReadyToExport(validateEmail(exportDestination?.email));
          }
          if (exportDestination?.download) {
            setIsReadyToExport(true);
          }
        } else {
          setVisibleEmailDestination(false);
        }
      }
    }
  }, [
    dataFormats,
    exportDataType,
    isExportDataGraphs,
    outputFormat,
    exportDestination,
  ]);

  const hasOutputFormat = useCallback(
    () => outputFormat && showExportDataFormats,
    [outputFormat, showExportDataFormats]
  );

  const hasEmailValue = useCallback(
    () =>
      !isEmpty(exportDestination) &&
      !exportDestination?.downloadFile &&
      exportDestination.email !== '',
    [exportDestination]
  );

  const isModalCSVOnly = useMemo(() => text && text?.length > 0, [text]);

  useEffect(() => {
    setIsReadyToExport(false);
    setVisibleEmailDestination(true);
    setIsReadyToExport(hasOutputFormat() || isModalCSVOnly);
    if (isExportDataPatients && exportDataType) {
      const selectedDataFormats = dataFormats[exportDataType];
      setExportDataFormats(selectedDataFormats.formats);
    }
    handleExportDataGraph();
  }, [
    dataFormats,
    exportDataType,
    handleExportDataGraph,
    hasOutputFormat,
    isExportDataPatients,
    isModalCSVOnly,
  ]);

  useEffect(() => {
    if (dataFormats && dataTypeProp) {
      setExportDataFormats(dataFormats);
      setExportDataType(dataTypeProp);
      if (isExportDataPatients) {
        setExportDataFormats(dataFormats[dataTypeProp].formats);
      }
    }
  }, [dataFormats, dataTypeProp, isExportDataPatients]);

  useEffect(() => {
    if (
      exportDestination?.downloadFile === false &&
      exportDestination?.email === ''
    ) {
      setIsReadyToExport(hasEmailValue());
    }

    if (exportDestination?.downloadFile === true) {
      setIsReadyToExport(hasOutputFormat());
    }

    if (dataOptions === undefined && showEmailDestination) {
      setIsReadyToExport(hasEmailValue());
    }
  }, [
    dataOptions,
    exportDestination,
    hasEmailValue,
    hasOutputFormat,
    showEmailDestination,
  ]);

  const { control } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onExportComplete = () => {
    setAnimationSegments([18, 100]);
  };

  const getPatientsSubmitCallback = useCallback(
    () =>
      isExportDataPatients ? dataFormats[exportDataType].onSubmitCallback : {},
    [dataFormats, exportDataType, isExportDataPatients]
  );

  const onHandleExport = useCallback(async () => {
    setIsExporting(true);
    try {
      // NOTE: Only for Patient Profile Graph Export Data
      if (
        isExportDataGraphs &&
        outputFormat === 'pdf' &&
        data?.onSubmitCallbackClient
      ) {
        data?.onSubmitCallbackClient({
          outputType: outputFormat,
          dataType: exportDataType,
        });
        closeModal();
      } else {
        await onSubmit({
          onSubmitCallback: getPatientsSubmitCallback(),
          exportDestination,
          ...data,
          outputType: outputFormat,
          dataType: exportDataType,
          sessionToken,
          closeModal,
        });
      }
    } finally {
      onExportComplete();
    }
  }, [
    closeModal,
    data,
    exportDataType,
    exportDestination,
    isExportDataGraphs,
    onSubmit,
    outputFormat,
    sessionToken,
    getPatientsSubmitCallback,
  ]);

  const onCloseExport = () => {
    setIsExporting(false);
    closeModal();
  };

  useEffect(() => {
    if (!prompt) {
      onHandleExport();
    }
  }, [onHandleExport, prompt]);

  const isShowEmailRecipient = () =>
    isExportDataGraphs
      ? exportDataType === EXPORT_DATA_TYPE.tables &&
        outputFormat === 'csv' &&
        exportDestination?.downloadFile === false
      : true;

  return (
    <Box
      p={1}
      style={{ boxSizing: 'border-box' }}
      width={['100%', 720]}
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{title}</H2>
        <Box cursor="pointer" ml={6} onClick={onCloseExport}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>

      {showBody && (
        <Body mt={3} textAlign="left">
          {text}
        </Body>
      )}

      {!isExporting && dataOptions && (
        <>
          <SubTitle data-testid="expor_data_modal_define_lbl" py={0}>
            {t('Export your patient data')}
          </SubTitle>
          <Controller
            name="exportDataOptionsControllerName"
            data-testid="export_data_options_modal_ctrl_select"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectWithLabel
                data-testid="export_data_options_select_list"
                id="export_data_modal_select_with_lbl_select-exportDataOptionsSelect"
                elementName="exportDataOptionsSelect"
                label={t('Select data to export')}
                placeholder={t('Select data to export')}
                options={dataOptions}
                {...field}
                onChange={(e) => {
                  setOutputFormat(null);
                  setExportDataType(e.value);
                  handleExportDataGraph();
                  setExportDestination({ downloadFile: true, email: '' });
                }}
                isDisabled={false}
                value={dataOptions?.filter((o) => o.value === exportDataType)}
              />
            )}
          />
        </>
      )}

      {!isExporting && showExportDataFormats && exportDataFormats && (
        <Box mt={-3}>
          <Controller
            name="exportDataFormatsControllerName"
            data-testid="export_data_formats_modal_ctrl_select"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectWithLabel
                data-testid="export_data_formats_select_list"
                elementName="exportDataFormatsSelect"
                label={t('Select file format')}
                options={exportDataFormats}
                placeholder={t('Select file format')}
                {...field}
                onChange={(e) => {
                  setOutputFormat(e.value);
                  setExportDestination({ downloadFile: true, email: '' });
                  setVisibleEmailDestination(false);
                }}
                isDisabled={!!outputFormat && dataOptions && !exportDataType}
                value={exportDataFormats?.filter(
                  (o) => o.value === outputFormat
                )}
              />
            )}
          />
        </Box>
      )}

      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mt={2}
      >
        <Lottie
          animationData={exportAnimation}
          speed={animationSegments[0] === 0 ? 0.09 : 1}
          play={isExporting}
          segments={animationSegments}
          style={
            isExporting ? { width: 100, height: 100 } : { width: 0, height: 0 }
          }
        />
        {isExporting && (
          <Text mt={2} fontSize={14} color="darkestShade">
            {t(
              'Exporting in progress. Your download will begin automatically.'
            )}
          </Text>
        )}
      </Flex>

      {showExportDestination && (
        <ExportDataDestination
          outputType={outputFormat}
          onChange={(destination) => {
            setExportDestination(destination);
          }}
          defaultDestination={defaultDestination}
          showEmailDestination={visibleEmailDestination}
          showDownloadDestination={showDownloadDestination}
          showEmailRecipient={isShowEmailRecipient()}
        />
      )}

      {!isExporting && (
        <Flex mt={3} justifyContent="flex-end" alignItems="center">
          <SecondaryOutlinedButton
            mr={3}
            disabled={isExporting}
            onClick={onCloseExport}
            borderRadius={8}
            style={{ minHeight: 44 }}
            logEventProps={{
              page: 'Export Data Modal',
              eventName: `${props?.logEventProps.page} - ${outputFormat} - close`,
            }}
            data-testid="export_data_modal_cancel_btn"
          >
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <PrimaryButton
            disabled={!isReadyToExport}
            onClick={onHandleExport}
            borderRadius={8}
            style={{ minHeight: 44 }}
            logEventProps={{
              page: 'Export Data Modal',
              eventName: `${props?.logEventProps.page} - ${outputFormat} | ${exportDataType} | ${exportDestination}`,
            }}
            data-testid="export_data_modal_submit_btn"
          >
            {isExporting ? t('Exporting...') : t('Download')}
          </PrimaryButton>
        </Flex>
      )}
    </Box>
  );
};

export default ExportDataModal;
